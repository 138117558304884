export const onDownloadCSVTemplateFile = () => {
  const csvHeaders = 'companyName,contactEmail,phone_number,firstName,lastName\n';
  const csvRows = ['Jane LLC,admin@jane.io,8654631529,Jane,Corp', 'John Doe,john.doe@gmail.com,8654636743,John,Doe'];
  const csvContent = csvHeaders + csvRows.join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', 'customers_template.csv');
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};
