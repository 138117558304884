import { CheckAccountFormModel, useCheckValidationSchema } from '@melio/ap-widgets';
import { useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PaperCheckDeliveryMethod, useDeliveryMethods, Vendor } from '@melio/platform-api';

import { useConfirmationModal } from '../useConfirmationModal';
import { useShowPaymentsMightBeAffectedBanner } from '../useShowPaymentsMightBeAffectedBanner';

export function usePaperCheckFormModal({
  vendor,
  onDone,
}: {
  vendor?: Vendor;
  onDone: (deliveryMethod: PaperCheckDeliveryMethod) => void;
}) {
  const { track } = useAnalytics();
  const {
    create,
    isMutating,
    _mutations: {
      create: { error: createError },
    },
  } = useDeliveryMethods({ enabled: false, vendorId: vendor?.id });

  const paperCheck = vendor?.deliveryMethods.find((method) => method.type === 'paper-check') as
    | PaperCheckDeliveryMethod
    | undefined;

  const confirmationModal = useConfirmationModal({
    deliveryMethod: paperCheck,
  });

  const { showPaymentsMightBeAffectedBanner } = useShowPaymentsMightBeAffectedBanner({ vendorId: vendor?.id });

  const paperCheckForm = useMelioForm<CheckAccountFormModel>({
    schema: useCheckValidationSchema(),
    onSubmit: async ({ printName, ...address }) => {
      const deliveryMethod = await create({
        type: 'paper-check',
        details: { printName, address },
        requireConfirmationForPayment: false,
      });
      track('DeliveryMethod', 'Click', {
        PageName: 'edit-check-address',
        Intent: 'edit-check-address',
        Cta: 'save',
        DeliveryMethodId: deliveryMethod.id,
      });
      onDone(deliveryMethod as PaperCheckDeliveryMethod);
      confirmationModal.close();
    },
    defaultValues: {
      printName: paperCheck?.details.printName || '',
      line1: paperCheck?.details.address.line1 || '',
      line2: paperCheck?.details.address.line2 || '',
      city: paperCheck?.details.address.city || '',
      state: paperCheck?.details.address.state || '',
      postalCode: paperCheck?.details.address.postalCode || '',
    },
    subscribeToDefaultValuesChanges: true,
  });

  return {
    ...confirmationModal,
    ...paperCheckForm,
    showPaymentsMightBeAffectedBanner,
    vendor,
    paperCheck,
    isSaving: isMutating,
    isError: !!createError,
  };
}
