import { BaseModal, Button, Container, Group, Illustration, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { FundingSourceType, useFundingSources, useUserPreference } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { useEffect, useState } from 'react';

const WAS_MODAL_SHOWN_PREFERENCE_ID = 'wasCardPromotionPopupShown';
const SHOWN_VALUE = 'true';

export const useCardPaymentPromotionPopup = (
  isModalAvailable: boolean
): { isOpen: boolean; onDismiss: () => void; isLoading: boolean } => {
  const [isFeatureEnabled] = usePartnerFeature('PromoteCardFundingSource', false);
  const isActive = isFeatureEnabled && isModalAvailable;

  const [isOpen, setIsOpen] = useState(false);

  const { data: fundingSources, isLoading: isFundingSourceLoading } = useFundingSources({
    enabled: isActive,
  });

  const {
    data: { value } = {},
    isLoading: isLoadingPreference,
    create,
  } = useUserPreference({
    id: WAS_MODAL_SHOWN_PREFERENCE_ID,
    enabled: isActive,
  });

  const wasBannerShown = value === SHOWN_VALUE;

  const onDismiss = () => {
    setIsOpen(false);
    void create({ userPreference: { key: WAS_MODAL_SHOWN_PREFERENCE_ID, value: SHOWN_VALUE } });
  };

  const isLoading = isFundingSourceLoading || isLoadingPreference;

  useEffect(() => {
    if (wasBannerShown || isLoading || !isActive) {
      return;
    }

    const hasCardFundingSource = fundingSources?.some(({ type }) => type === FundingSourceType.Card);

    if (!hasCardFundingSource) {
      setIsOpen(true);
    }
  }, [isActive, wasBannerShown, isLoading, fundingSources]);

  return {
    isOpen,
    onDismiss,
    isLoading,
  };
};

export const CardPaymentPromotionModal = ({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }) => {
  const { formatMessage } = useMelioIntl();

  useAnalyticsView('PaymentMethod', isOpen, true, {
    PageName: 'pay-by-card-and-ach-transfers',
    Intent: 'new-payment-method',
  });

  return (
    <BaseModal isOpen={isOpen} onClose={onDismiss}>
      <BaseModal.Header>
        <Container textAlign="start">
          <Text textStyle="heading2Semi">
            {formatMessage('widgets.fundingSources.addFundingSourceCard.credit.promotion.title')}
          </Text>
        </Container>
      </BaseModal.Header>
      <BaseModal.Body>
        <Group variant="vertical">
          <Container justifyContent="center">
            <Illustration size="large" type="celebration" />
          </Container>
          <Text textStyle="body2">
            {formatMessage('widgets.fundingSources.addFundingSourceCard.credit.promotion.description')}
          </Text>
        </Group>
      </BaseModal.Body>
      <BaseModal.Footer>
        <Group variant="horizontal" justifyContent="flex-end">
          <Button
            label={formatMessage('widgets.fundingSources.addFundingSourceCard.credit.promotion.cta')}
            onClick={onDismiss}
          />
        </Group>
      </BaseModal.Footer>
    </BaseModal>
  );
};
