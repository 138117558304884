import {
  FormDialog,
  FormDialogWidgetProps,
  FormInputs,
  InvoiceDiscount,
  InvoiceDiscountTypeEnum,
  maxEntityNameLength,
  maxPercentageValue,
  maxTotalAmount,
  minAmountValue,
  minPercentageValue,
  useMelioIntl,
} from '@melio/ar-domain';
import { Form, SelectOption, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { number, object, SchemaOf, string } from 'yup';

import { DiscountSelectWidget } from '../components';
import { DiscountFormFields } from '../types';

export type CreateDiscountModalScreenProps = FormDialogWidgetProps<
  DiscountFormFields,
  {
    onCreateDiscount: (name: string) => void;
    onChangeDiscount: (event: ChangeEvent<HTMLInputElement>) => void;
    selectOptions: SelectOption[];
    selectedDiscount?: InvoiceDiscount;
  }
>;

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    discountId: string()
      .nullable()
      .required(formatMessage('ar.modals.activities.discounts.formDialog.fields.discountId.required.label')),
    type: string()
      .default(InvoiceDiscountTypeEnum.Percentage)
      .oneOf(Object.values(InvoiceDiscountTypeEnum))
      .required(formatMessage('ar.modals.activities.discounts.formDialog.fields.type.required.label')),
    value: number()
      .nullable()
      .typeError(formatMessage('ar.modals.activities.discounts.formDialog.fields.value.required.label'))
      .required(formatMessage('ar.modals.activities.discounts.formDialog.fields.value.required.label'))
      .when('type', {
        is: 'percentage',
        then: (schema) =>
          schema
            .lessThan(
              maxPercentageValue,
              formatMessage('ar.modals.activities.discounts.formDialog.fields.value.max.percentage.label')
            )
            .moreThan(
              minPercentageValue,
              formatMessage('ar.modals.activities.discounts.formDialog.fields.value.validation.percentage.min')
            ),
      })
      .when('type', {
        is: 'amount',
        then: (schema) =>
          schema
            .lessThan(
              maxTotalAmount,
              formatMessage('ar.modals.activities.discounts.formDialog.fields.value.max.amount.label')
            )
            .moreThan(
              minAmountValue,
              formatMessage('ar.modals.activities.discounts.formDialog.fields.value.validation.amount.min')
            ),
      }),
  }) as SchemaOf<DiscountFormFields>;
};

export const CreateDiscountModalScreen = forwardRef<CreateDiscountModalScreenProps>(
  ({ onChangeDiscount, onCreateDiscount, selectOptions, selectedDiscount, onSubmit, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const defaultValues = useMemo(
      () => ({
        discountId: selectedDiscount?.id || '',
        type: selectedDiscount?.type || InvoiceDiscountTypeEnum.Percentage,
        value: selectedDiscount?.value || ('' as never),
      }),
      [selectedDiscount]
    );

    const { registerField, watch, setValue, clearErrors, setError, ...useFormResults } = useMelioForm({
      schema: useSchema(),
      onSubmit,
      defaultValues,
      isSaving,
      subscribeToDefaultValuesChanges: true,
    });

    const handleCreateDiscount = (name: string) => {
      if (name.length > maxEntityNameLength) {
        setError('discountId', {
          message: formatMessage('ar.modals.activities.discounts.formDialog.fields.discountId.maxLength.label', {
            maxLength: maxEntityNameLength,
          }),
        });
      } else {
        clearErrors('discountId');
        onCreateDiscount(name);
      }
    };

    const [currentDiscountId, type, value] = watch(['discountId', 'type', 'value']);

    const isTypePercentage = type === InvoiceDiscountTypeEnum.Percentage;
    const isTypeChanged = type !== selectedDiscount?.type;
    const isValueChanged = isTypePercentage
      ? value !== selectedDiscount?.value
      : Number(value) !== selectedDiscount?.value;
    const isUpdated = isTypeChanged || isValueChanged;

    useEffect(() => {
      if (!isUpdated) {
        clearErrors('value');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
      if (type !== selectedDiscount?.type) {
        setValue('value', '' as never);
      }

      if (selectedDiscount && currentDiscountId === selectedDiscount.id && type === selectedDiscount.type) {
        setValue('value', selectedDiscount.value);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, currentDiscountId]);

    return (
      <FormDialog
        header={formatMessage('ar.modals.activities.discounts.formDialog.header.text')}
        primaryButton={{
          label: formatMessage('ar.modals.activities.discounts.formDialog.buttons.confirm.update.label'),
        }}
        secondaryButton={{
          label: formatMessage('ar.modals.activities.discounts.formDialog.buttons.close.label'),
        }}
        data-testid="discount-modal"
        data-component={CreateDiscountModalScreen.displayName}
        size="large"
        useFormResults={useFormResults}
        {...props}
        ref={ref}
      >
        <DiscountSelectWidget
          onCreateDiscount={handleCreateDiscount}
          onChangeDiscount={onChangeDiscount}
          selectOptions={selectOptions}
          labelProps={{
            label: formatMessage('ar.modals.activities.discounts.formDialog.fields.name.title.label'),
          }}
          {...registerField('discountId')}
          colSpan={2}
        />
        <Form.RadioGroup
          colSpan={2}
          labelProps={{
            label: formatMessage('ar.modals.activities.discounts.formDialog.fields.type.title.label'),
          }}
          {...registerField('type')}
          variant="horizontal"
          options={[
            {
              mainLabelProps: {
                label: formatMessage('ar.modals.activities.discounts.formDialog.fields.type.options.percentage.label'),
              },
              value: InvoiceDiscountTypeEnum.Percentage,
            },
            {
              mainLabelProps: {
                label: formatMessage('ar.modals.activities.discounts.formDialog.fields.type.options.fixedAmount.label'),
              },
              value: InvoiceDiscountTypeEnum.Amount,
            },
          ]}
        />
        {isTypePercentage ? (
          <FormInputs.PercentageField
            {...registerField('value')}
            labelProps={{
              label: formatMessage('ar.modals.activities.discounts.formDialog.fields.amount.title.label'),
            }}
            placeholder="0%"
            colSpan={2}
          />
        ) : (
          <FormInputs.AmountField
            {...registerField('value')}
            labelProps={{
              label: formatMessage('ar.modals.activities.discounts.formDialog.fields.discountAmount.title.label'),
            }}
            placeholder="$0.00"
            colSpan={2}
          />
        )}
      </FormDialog>
    );
  }
);
CreateDiscountModalScreen.displayName = 'CreateDiscountModalScreen';
