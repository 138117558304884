import { useLocation } from 'react-router-dom';
import { useSendMessage } from '@melio/partner-bridge';
import { useConfig } from '@melio/platform-provider';

import { ErrorPage } from '@/cl/components/ErrorPage/ErrorPage.component';
import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';

export const ExpiredSessionScreen = () => {
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const sendMessage = useSendMessage();
  const config = useConfig();
  const { goToStart } = useRouter();
  const { state } = useLocation<{ redirectUrl: string; organization?: string }>();

  const onPrimaryButtonClick = () => {
    if (config.settings.isEmbeddedExperience) {
      sendMessage('SESSION_EXPIRED', {});
    } else {
      goToStart({ redirectUrl: state?.redirectUrl, organization: state?.organization });
    }
  };

  return (
    <FullScreenContainer centered>
      <ErrorPage
        icon={partnerConfig.icons.ExpiredSessionIcon?.small}
        title={formatMessage('screens.expiredSession.title')}
        message={formatMessage('screens.expiredSession.message', { partner: partnerConfig.displayName })}
        primaryButton={{
          text: formatMessage('screens.expiredSession.button', { partner: partnerConfig.displayName }),
          onClick: onPrimaryButtonClick,
        }}
      />
    </FullScreenContainer>
  );
};
