import { useAccounts } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useMemo } from 'react';

export const useShowMultiAccountBanner = () => {
  const [isFeatureEnabled] = usePartnerFeature('MultiAccountBanner', false);

  const { data: accounts } = useAccounts({ enabled: isFeatureEnabled });

  const showBanner = useMemo(
    () =>
      !!isFeatureEnabled &&
      accounts &&
      accounts?.length === 1 &&
      accounts?.some((account) => !!account.hasUnsyncedAccounts),
    [accounts, isFeatureEnabled]
  );

  return showBanner;
};
