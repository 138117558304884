import { EditBillSubscriptionActivity, EditRecurringActivity } from '@melio/ap-activities';
import { usePartnerFeature } from '@melio/platform-provider';
import { useLocation, useNavigate } from '@melio/platform-utils';

import { useRouter } from '../../hooks/router.hooks';
import { PayDashboardTabs } from '../../types/payDashboard.types';

export const EditBillSubscriptionScreen = ({ billSubscriptionId }: { billSubscriptionId: string }) => {
  const navigate = useNavigate();
  const { state } = useLocation<{ returnUrl?: string }>();
  const { replaceToPayDashboardTab } = useRouter();
  const [isNewPaymentFlowEnabled] = usePartnerFeature('payment.newPaymentFlow', false);

  const { returnUrl } = state || {};
  const handleDone = () => {
    handleClose();
  };

  const handleClose = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }

    replaceToPayDashboardTab(PayDashboardTabs.Scheduled);
  };

  if (isNewPaymentFlowEnabled) {
    return <EditRecurringActivity billSubscriptionId={billSubscriptionId} onDone={handleDone} onClose={handleClose} />;
  }

  return <EditBillSubscriptionActivity billSubscriptionId={billSubscriptionId} onDone={handleClose} />;
};
