import { merge } from 'lodash';

import { fiservConfigBase } from './fiserv.config';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

export const fiservXDDemoConfigBase: PartnerConfigByEnv = merge<PartnerConfigByEnv>({}, fiservConfigBase, {
  default: {},
  dev: {},
  prod: {},
});

export const fiservXDDemoConfig = extendDefaultConfig(fiservXDDemoConfigBase);
