import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { routes as ACCOUNTANTS_ROUTES } from '@melio/accountants';
import { useIsAccountingFirm } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

import { useHasAccessToFirm } from '@/hooks/useHasAccessToFirm.hooks';

export const DemoRoutesAccess = () => {
  const config = useConfig();
  if (config.production) {
    return <></>;
  }

  return <Outlet />;
};

export const WithAccessToFirm = () => {
  const hasAccessToFirm = useHasAccessToFirm();
  if (!hasAccessToFirm) {
    return null;
  }

  return <Outlet />;
};

export const RestrictedToAccountingFirmOnly = () => {
  const isAccountingFirm = useIsAccountingFirm();
  if (!isAccountingFirm) {
    return null;
  }

  return <Outlet />;
};

export const RestrictAccountingFirmWithFallback = ({
  fallbackRoute = ACCOUNTANTS_ROUTES.DASHBOARD,
}: {
  fallbackRoute?: string;
}) => {
  const isAccountingFirm = useIsAccountingFirm();

  if (isAccountingFirm) {
    return <Navigate replace to={fallbackRoute} />;
  }

  return <Outlet />;
};
