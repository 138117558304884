import { useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import {
  BankAccount,
  useAccountingPlatformBankAccounts,
  useDeliveryMethod,
  useDeliveryMethods,
} from '@melio/platform-api';
import { isEmpty, throttle } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import { ErrorLinkBankAccount, FirstBankAccount, LinkBankAccount } from './LinkBankAccount/screens';
import { LinkBankAccountActivityProps } from './types';
import { useLinkBankAccountSteps } from './useLinkBankAccountSteps';

const analyticsProperties = {
  PageName: 'match-your-bank-accounts',
  Flow: 'link-bank-account',
  Intent: 'link-bank-account',
};

export const LinkBankAccountActivity = withAnalyticsContext<LinkBankAccountActivityProps>(
  ({ onBack: onFirstStepBack, onClose, onError, onDone, deliveryMethodId }) => {
    const { currentStep, goToStep } = useLinkBankAccountSteps({ onFirstStepBack });

    useAnalyticsView('LinkBankAccount', true, true, analyticsProperties);

    const {
      isLoading: isDeliveryMethodsLoading,
      data: deliveryMethod,
      refetch: refetchDeliveryMethod,
    } = useDeliveryMethod({ id: deliveryMethodId });

    const { refetch: refetchDeliveryMethods } = useDeliveryMethods({ isGetOwn: true });

    const {
      data: loadBankAccounts,
      create: createAndLinkBankAccount,
      isFetched,
      isMutating: isSaving,
      error: isBankAccountsFetchError,
      refetch: refetchAccountingPlatformBankAccount,
    } = useAccountingPlatformBankAccounts();

    const bankAccount = deliveryMethod?.details as BankAccount;

    const handleFail = useCallback(
      (error: PlatformError) => {
        onError?.(error);
      },
      [onError]
    );

    const loadAccountsAndLink = useCallback(() => {
      if (
        isFetched &&
        loadBankAccounts?.length === 0 &&
        !isEmpty(bankAccount?.routingNumber) &&
        !isEmpty(bankAccount?.accountNumber)
      ) {
        createAndLinkBankAccount({
          routingNumber: bankAccount?.routingNumber,
          accountNumber: bankAccount?.accountNumber,
          deliveryMethodId: String(deliveryMethodId),
        })
          .then(() => {
            goToStep('IS_FIRST_BANK_ACCOUNT');
            refetchAccountingPlatformBankAccount();
          })
          .catch((err) => {
            handleFail(err);
          });
      }
    }, [
      bankAccount,
      refetchAccountingPlatformBankAccount,
      deliveryMethodId,
      createAndLinkBankAccount,
      goToStep,
      loadBankAccounts,
      handleFail,
      isFetched,
    ]);

    const onCreateNewBankAccount = () =>
      createAndLinkBankAccount({
        routingNumber: bankAccount.routingNumber,
        accountNumber: bankAccount.accountNumber,
        deliveryMethodId: String(deliveryMethodId),
      })
        .then(() => {
          Promise.all([refetchDeliveryMethod(), refetchDeliveryMethods()])
            .then(() => {
              onDone();
            })
            .catch((err) => {
              handleFail(err);
            });
        })
        .catch((err) => {
          handleFail(err);
        });

    const onLinkReceivingMethod = (accountingPlatformBankAccountId: string) =>
      createAndLinkBankAccount({
        routingNumber: bankAccount.routingNumber,
        accountNumber: bankAccount.accountNumber,
        deliveryMethodId: String(deliveryMethodId),
        accountId: accountingPlatformBankAccountId,
      })
        .then(() => {
          onDone();
        })
        .catch((err) => {
          handleFail(err);
        });

    const throttledLoadAccountsAndLink = useMemo(() => throttle(loadAccountsAndLink, 1000), [loadAccountsAndLink]);

    useEffect(() => {
      throttledLoadAccountsAndLink();
      return () => {
        throttledLoadAccountsAndLink.cancel();
      };
    }, [throttledLoadAccountsAndLink]);

    useEffect(() => {
      if (isBankAccountsFetchError) {
        goToStep('LINK_BANK_ACCOUNT_ERROR');
      }
    }, [isBankAccountsFetchError, goToStep]);

    switch (currentStep) {
      case 'LINK_BANK_ACCOUNT':
      default:
        return (
          <LinkBankAccount
            onClose={onClose}
            onCreateNewBankAccount={onCreateNewBankAccount}
            onLinkReceivingMethod={onLinkReceivingMethod}
            isLoading={isDeliveryMethodsLoading}
            bankDetails={bankAccount}
            isSaving={isSaving}
          />
        );

      case 'IS_FIRST_BANK_ACCOUNT':
        return <FirstBankAccount onClose={onClose} onDone={onDone} />;

      case 'LINK_BANK_ACCOUNT_ERROR':
        return <ErrorLinkBankAccount onClose={onClose} />;
    }
  }
);

LinkBankAccountActivity.displayName = 'LinkBankAccountActivity';
