import { useBoolean } from '@chakra-ui/react';
import { InvoiceDiscount, InvoiceTaxRate, useFiles, useInvoiceExpanded, useInvoiceTimeline } from '@melio/ar-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useMemo, useState } from 'react';

import { ServiceChargeParams } from '../../types';
import { EditInvoiceExitModal } from './components';
import { EditInvoiceScreen, PaymentOptionsConfirmationScreen } from './screens';
import { EditInvoiceSubmitTarget, InvoiceFormValuesLineItem } from './types';
import { submitForm, useEditInvoiceActions, useInvoiceSummary, usePrefetch } from './utils';

export type EditInvoiceActivityProps = {
  invoiceId: string;
  onDone: (target?: EditInvoiceSubmitTarget) => unknown;
  onError?: ARErrorFunction;
  taxRate?: InvoiceTaxRate;
  discount?: InvoiceDiscount;
  serviceCharges?: ServiceChargeParams[];
  onSetDiscount?: VoidFunction;
  onRemoveDiscount?: VoidFunction;
  onSetTaxRate?: VoidFunction;
  onAddServiceCharge?: VoidFunction;
  onRemoveServiceCharge?: (index: number) => void;
  onClose: (id?: string) => void;
  onCreateCustomer: (name: string) => void;
  onUpdateCustomer: (customerId: string) => void;
  customerId?: string;
  isMissingKycDetails?: boolean;
  handleMissingKycDetails?: VoidFunction;
  isDisabled?: boolean;
};

export const EditInvoiceActivity = forwardRef<EditInvoiceActivityProps>(
  (
    {
      invoiceId,
      discount,
      onClose,
      onRemoveDiscount,
      onSetDiscount,
      onSetTaxRate,
      onAddServiceCharge,
      onRemoveServiceCharge,
      onError,
      taxRate,
      serviceCharges,
      onDone,
      onCreateCustomer,
      onUpdateCustomer,
      customerId,
      isMissingKycDetails,
      handleMissingKycDetails,
      isDisabled,
      ...props
    },
    ref
  ) => {
    const { data: invoice, isLoading: isLoadingInvoice } = useInvoiceExpanded({ id: invoiceId, onError });
    const [lineItems, setLineItems] = useState<InvoiceFormValuesLineItem[]>(invoice?.invoiceLineItems ?? []);
    useEffect(() => invoice?.invoiceLineItems && setLineItems(invoice.invoiceLineItems), [invoice?.invoiceLineItems]);

    const { data: invoiceSummary, isFetching: isFetchingInvoiceSummary } = useInvoiceSummary({
      discount,
      taxRate,
      serviceCharges,
      lineItems: lineItems.length > 0 ? lineItems : invoice?.invoiceLineItems,
      onError,
    });

    const { data: timeline } = useInvoiceTimeline({ id: invoiceId, onError });
    const invoiceEmail = useMemo(() => [...(timeline?.emails ?? [])].pop(), [timeline?.emails]);

    const [isExitDialogOpen, exitDialogOpen] = useBoolean(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isPaymentOptionsModalOpen, setIsPaymentOptionsModalOpen] = useState(false);
    const { updateInvoice, isSaving } = useEditInvoiceActions({
      invoiceId,
      discount,
      serviceCharges,
      taxRate,
      onError,
    });

    const totalAmountLimits = { min: 100, max: 10000000 }; // in dollars minimum: 1, max: 100000

    const [submitTarget, setSubmitTarget] = useState<EditInvoiceSubmitTarget>();

    const { isLoading: isPreLoading } = usePrefetch();
    const { isMutating: isLoadingAttachment } = useFiles({ enabled: false });

    const { track } = useAnalytics();

    return (
      <>
        <EditInvoiceExitModal
          isOpen={isExitDialogOpen}
          onApprove={() => {
            track('Invoice', 'Click', {
              Cta: 'discard',
              Intent: 'exit-flow',
            });
            exitDialogOpen.off();
            onClose();
          }}
          onClose={() => {
            track('Invoice', 'Click', {
              Cta: 'continue-editing',
              Intent: 'exit-flow',
            });
            exitDialogOpen.off();
          }}
        />
        <PaymentOptionsConfirmationScreen
          isOpen={isPaymentOptionsModalOpen}
          onSubmit={submitForm}
          onCloseModal={() => !isSaving && setIsPaymentOptionsModalOpen(false)}
          onClose={() => setIsPaymentOptionsModalOpen(false)}
          isSaving={isSaving}
        />
        <EditInvoiceScreen
          invoice={invoice}
          invoiceEmail={invoiceEmail}
          isLoading={isLoadingInvoice || isPreLoading}
          onClose={() => (isDirty ? exitDialogOpen.on() : onClose())}
          discount={{ id: discount?.id, onRemove: onRemoveDiscount, onEdit: onSetDiscount }}
          taxRate={{ id: taxRate?.id, onEdit: onSetTaxRate }}
          onSubmit={async (data, _, target = 'save-and-continue') => {
            track('Invoice', 'Click', {
              Cta: 'save-and-share',
              Intent: 'save-changes',
            });

            if (target === 'save-and-continue') {
              if (isMissingKycDetails && handleMissingKycDetails) {
                handleMissingKycDetails();
                return;
              }
              const isPaymentOptionsSelected = data.isCardAllowed || data.isAchAllowed;
              if (!isPaymentOptionsSelected && !isPaymentOptionsModalOpen) {
                setIsPaymentOptionsModalOpen(true);
                return;
              }
            }

            setSubmitTarget(target);
            await updateInvoice(data, target);
            onDone(target);
          }}
          defaultValues={useMemo(
            () => ({
              ...invoice,
              lineItems: invoice?.invoiceLineItems ?? [],
              customPayInstructions: invoice?.customPayInstructions ?? '',
              customerId: customerId || invoice?.customerId,
            }),
            [invoice, customerId]
          )}
          serviceCharges={{
            onAdd: onAddServiceCharge,
            onRemove: onRemoveServiceCharge,
          }}
          invoiceSummary={invoiceSummary}
          onFormStateChange={({ isDirty }) => setIsDirty(isDirty)}
          onLineItemsChange={setLineItems}
          isLoadingSummary={isFetchingInvoiceSummary}
          isSaving={isSaving ? submitTarget : undefined}
          totalAmountLimits={totalAmountLimits}
          onCreateCustomer={onCreateCustomer}
          onUpdateCustomer={onUpdateCustomer}
          customerId={customerId}
          isDisabled={isDisabled || isLoadingAttachment}
          {...props}
          ref={ref}
        />
      </>
    );
  }
);

EditInvoiceActivity.displayName = 'EditInvoiceActivity';
