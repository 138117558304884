import { InvoiceDiscount, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenu, Group, Icon, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { useDiscountActionsItems } from '../hooks';

export type DiscountsTableListItemProps = {
  discount: InvoiceDiscount;
  onEdit: (discountId: string) => unknown;
  onDelete: (discountId: string) => unknown;
};

export const DiscountsTableListItem = forwardRef<DiscountsTableListItemProps>(
  ({ discount, onEdit, onDelete, ...props }, ref) => {
    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
    const { formatMessage } = useMelioIntl();
    const actions = useDiscountActionsItems({ discount, onEdit, onDelete });

    return (
      <ActionsDropdownMenu
        isOpen={isActionsMenuOpen}
        onOpenChange={setIsActionsMenuOpen}
        trigger={<DiscountsTableListItemContent discount={discount} ref={ref} {...props} />}
        label={formatMessage('ar.settings.activities.discountsTable.listItem.actionsMenu.label')}
        items={actions}
        ref={ref}
        {...props}
      />
    );
  }
);
DiscountsTableListItem.displayName = 'DiscountsTableListItem';

const DiscountsTableListItemContent = forwardRef<{ discount: InvoiceDiscount }>(({ discount, ...props }, ref) => {
  const { formatCurrency, formatPercents } = useMelioIntl();
  return (
    <Group
      variant="horizontal"
      justifyContent="space-between"
      alignItems="center"
      spacing="xxs"
      {...props}
      data-component={DiscountsTableListItemContent.displayName}
      ref={ref}
      data-testid={`discount-list-item-${discount.id}`}
    >
      <Group justifyContent="space-between" width="full">
        <Text textStyle="body2Semi" data-testid="discount-name">
          {discount.name}
        </Text>
        <Text textStyle="body2Semi" data-testid="discount-value">
          {discount.type === 'percentage' ? formatPercents(discount.value) : formatCurrency(discount.value)}
        </Text>
      </Group>
      <Icon type="chevron-right" size="small" />
    </Group>
  );
});
DiscountsTableListItemContent.displayName = 'DiscountsTableListItemContent';
