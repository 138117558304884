import { useBreakpointValue } from '@chakra-ui/react';
import { useMelioIntl } from '@melio/i18n-tools/src/useMelioIntl';
import { Container, Divider, Group, IconButton, Layout } from '@melio/penny';
import { forwardRef, PageTitle } from '@melio/platform-utils';
import React from 'react';

type CaratLayoutFormProps = {
  onClose: VoidFunction;
  title: React.ReactNode;
  footerContent: React.ReactNode;
  children: React.ReactNode;
};

export const CaratLayout = forwardRef<CaratLayoutFormProps, 'div'>(
  ({ onClose, title, footerContent, children, ...props }, ref) => {
    const isMobile = useBreakpointValue({ xs: true, s: false });
    const { formatMessage } = useMelioIntl();

    return (
      <Layout
        data-testid="carat-layout"
        {...props}
        maxWidth="600px"
        ref={ref}
        header={
          isMobile
            ? {
                isSticky: true,
                content: (
                  <Group variant="vertical" spacing="none" data-testid="carat-layout-header">
                    <Container width="full" paddingX="s" paddingY="s">
                      <Group variant="horizontal" spacing="s" alignItems="center">
                        <IconButton
                          data-testid="layout-close-button"
                          icon="arrow-left"
                          size="medium"
                          variant="naked"
                          aria-label={formatMessage('activities.paymentFlow.form.header.back')}
                          onClick={onClose}
                        />
                        <PageTitle textStyle="heading2Semi" data-testid="carat-layout-header-title">
                          {title}
                        </PageTitle>
                      </Group>
                    </Container>
                    <Divider variant="horizontal" />
                  </Group>
                ),
              }
            : undefined
        }
        footer={{
          isSticky: true,
          content: (
            <Group variant="vertical" spacing="none" data-testid="carat-layout-footer">
              <Divider variant="horizontal" />
              <Container width="full" paddingX="s" paddingY="s">
                <Group variant="horizontal" spacing="s" justifyContent="space-between">
                  {footerContent}
                </Group>
              </Container>
            </Group>
          ),
        }}
      >
        {children}
      </Layout>
    );
  }
);
