import {
  CountryData,
  Currency,
  FieldName,
  FieldValidationData,
  getCountries,
  getCurrencyRequiredFields,
  RequiredFields,
} from '@melio/international-payments-utils';
import { FlagIcon, FlagKey, Group, Text } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { sortBy } from 'lodash';

export const useCurrenciesOptions = (currencies: string[]) => {
  const { formatMessage, getCurrencyName } = useMelioIntl();
  return currencies.map((currency) => ({
    value: currency,
    label:
      formatMessage(`activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.foreignCurrency` as MessageKey, {
        currency,
        currencyName: getCurrencyName(currency),
      }) || currency,
    testId: `${currency}`,
  }));
};

export const useGetCountryOption = (countries: CountryData[], isCountryDisabled: boolean) => {
  const { formatMessage } = useMelioIntl();
  return sortBy(countries, (x) => x.name).map(({ code, name }) => {
    const countryName = (
      <Group alignItems="center" justifyContent="space-between">
        <Group data-testid={`fx-country-flag-option-${code}`}>
          <FlagIcon countryCode={code as FlagKey} size="small" />
        </Group>
        <Text
          color={isCountryDisabled ? 'global.neutral.700' : undefined}
          data-testid={`fx-country-name-option-${code}`}
        >
          {name}
        </Text>
      </Group>
    );
    return {
      value: code,
      label: formatMessage('activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.countryOption', {
        countryName,
      }),
      testId: `${code}`,
    };
  });
};

export const getRequireFieldsByCurrency = (currency: Currency) => {
  const requiredFields = getCurrencyRequiredFields(currency).alternatives;
  return requiredFields;
};

export const getCountriesOptions = (currency?: Currency) =>
  getCountries(currency ? [currency] : []).map(countryDetailsToOptions);

export const countryDetailsToOptions = (countriesDetailsOptions: CountryData): { value: string; label: string } => ({
  value: countriesDetailsOptions.code,
  label: countriesDetailsOptions.name,
});

export const getRequireFieldsForBankDetails = (requiredFields: RequiredFields[]) =>
  requiredFields.find((x) => x.fields.some((w: FieldValidationData) => w.key === 'iban')) ||
  (requiredFields[0] as RequiredFields);

export const getRequireFieldByKey = (requiredFields: RequiredFields[], key: FieldName) =>
  getRequireFieldsForBankDetails(requiredFields)?.fields.find((x) => x.key === key);
