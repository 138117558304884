import { UnilateralFormFields } from '@melio/ap-widgets';
import { Traits, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useDeliveryMethods, useFundingSource, useVendor, VirtualAccountDeliveryMethod } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';
import { isEmpty } from 'lodash';

import { AddVendorUnilateralDetailsScreen } from './screens';
import { AddVendorUnilateralDetailsActivityProps } from './types';

export const AddVendorUnilateralDetailsActivity = withAnalyticsContext<AddVendorUnilateralDetailsActivityProps>(
  ({ vendorId, fundingSourceId, onBack: onFirstStepBack, onClose, onError, onDone }) => {
    const collection = useDeliveryMethods({ vendorId, enabled: false });
    const { data: vendor, update: updateVendor, isLoading: isVendorLoading } = useVendor({ id: vendorId });
    const { data: fundingSource, isLoading: isFundingSourceLoading } = useFundingSource({ id: fundingSourceId });
    const vendorName = vendor?.name || '';

    const defaultValues = { email: vendor?.contact?.email || '', phoneNumber: vendor?.contact?.phoneNumber || '' };
    const { showMessage } = useSystemMessage();

    const handleFail = (error: PlatformError) => {
      showMessage({ type: 'error', title: error.message });
      onError?.(error);
    };

    const { createTrackHandler, setTraits } = useAnalytics();
    const submitHandler = createTrackHandler<{ Status: 'failed' | 'succeeded' }>('AskVendorDeliveryDetails', 'Saved');
    const setDeliveryMethodTraits = () => {
      const traits: Traits = { added_delivery_method: true };

      setTraits(traits);
    };

    const createDeliveryMethod = (data: UnilateralFormFields) => {
      const newVendorContactData = {
        ...(!vendor?.contact?.email ? { email: data.email } : null),
        ...(!vendor?.contact?.phoneNumber && data.phoneNumber ? { phoneNumber: data.phoneNumber } : null),
      };

      if (!isEmpty(newVendorContactData)) {
        void updateVendor({
          contact: newVendorContactData,
        });
      }

      collection
        .create({ type: 'virtual-account', details: data })
        .then((data) => {
          submitHandler({ Status: 'succeeded' });
          setDeliveryMethodTraits();
          onDone(data as VirtualAccountDeliveryMethod);
        })
        .catch((error) => submitHandler({ Status: 'failed' }, handleFail(error)));
    };

    return (
      <AddVendorUnilateralDetailsScreen
        isLoading={isFundingSourceLoading || isVendorLoading}
        onBack={onFirstStepBack}
        onClose={onClose}
        onDone={createDeliveryMethod}
        isSaving={collection.isMutating}
        defaultValues={defaultValues}
        vendorName={vendorName}
        fundingSourceType={fundingSource?.type}
      />
    );
  }
);

AddVendorUnilateralDetailsActivity.displayName = 'AddVendorUnilateralDetailsActivity';
