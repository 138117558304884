import { FloatingMenu, Group, Radio } from '@melio/penny';
import { Ref } from 'react';

import { FilterOption } from '../components/Filters/types';

type Props = {
  options: FilterOption[];
  selected: string[];
  onChange: (values?: string) => void;
  firstOptionRef?: Ref<HTMLDivElement>;
};

export const FilterMenuOptionsSingle = ({ options, selected, onChange, firstOptionRef }: Props) => {
  const isNothingSelected = selected.length === 0;
  return (
    <Group data-component="RadioGroup" variant="vertical" role="radiogroup" spacing="xxs">
      {options.map((option, index) => {
        const onClick = () => (option.selectWhenNothingSelected ? onChange(undefined) : onChange(option.value));
        return (
          <FloatingMenu.Item
            key={option.value}
            onClick={onClick}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            paddingY="none"
            {...(index === 0 && { ref: firstOptionRef })}
          >
            <Radio
              label={option.label}
              ariaLabel={option.value}
              key={option.value}
              value={option.value}
              onChange={onClick}
              isChecked={isNothingSelected && option.selectWhenNothingSelected ? true : selected.includes(option.value)}
              id={option.value}
              data-testid={`radio-group-${option.value}`}
            />
          </FloatingMenu.Item>
        );
      })}
    </Group>
  );
};
