import { useEffect } from 'react';
import Axios from 'axios';
import { isString } from 'lodash';
import { useSendMessage } from '@melio/partner-bridge';
import { MessageKey } from '@melio/platform-i18n';

export enum OnboardingErrorReason {
  DID_NOT_CONSENT = 'DID_NOT_CONSENT',
  INVALID_TOKEN = 'INVALID_TOKEN',
  TENANT_NOT_FOUND = 'TENANT_NOT_FOUND',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  MISSING_TOKEN = 'MISSING_TOKEN',
  SERVICE_UNREACHABLE = 'SERVICE_UNREACHABLE',
  BAD_RESPONSE = 'BAD_RESPONSE',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}
export enum OnboardingErrorType {
  Unknown = 'unknown',
  Forbidden = 'forbidden',
  BadRequest = 'badRequest',
  NonCompliance = 'missingOrInvalidInformation',
}

export type OnboardingError = {
  type: OnboardingErrorType;
  code?: string;
  missingFields?: string[];
  reason?: OnboardingErrorReason;
};

export const errorToOnboardingError = (error: unknown): OnboardingError => {
  if (isString(error) && error in OnboardingErrorReason) {
    return { type: OnboardingErrorType.Unknown, reason: error as OnboardingErrorReason };
  }
  if (!error || typeof error !== 'object' || !Axios.isAxiosError(error)) {
    return { type: OnboardingErrorType.Unknown, reason: OnboardingErrorReason.UNEXPECTED_ERROR };
  }
  const errorCode = error.response?.data?.error?.errorCode;

  const missingFields = error.response?.data?.error?.data?.map((item: { field: string }) => item.field);

  const getErrorType = () => {
    if (errorCode === 'NonCompliance') {
      return { type: OnboardingErrorType.NonCompliance, reason: OnboardingErrorReason.BAD_RESPONSE };
    }
    switch (error.response?.status) {
      case 400:
      case 401:
      case 404:
        return {
          type: OnboardingErrorType.BadRequest,
          reason:
            errorCode === 'InvalidToken'
              ? OnboardingErrorReason.INVALID_TOKEN
              : errorCode == 'ExpiredToken'
              ? OnboardingErrorReason.EXPIRED_TOKEN
              : errorCode == 'BadResponse'
              ? OnboardingErrorReason.BAD_RESPONSE
              : !missingFields || missingFields.length === 0
              ? OnboardingErrorReason.BAD_RESPONSE
              : OnboardingErrorReason.UNEXPECTED_ERROR,
        };
      case 403:
        return { type: OnboardingErrorType.Forbidden };
      case 504:
        return { type: OnboardingErrorType.Unknown, reason: OnboardingErrorReason.SERVICE_UNREACHABLE };
      default:
        return { type: OnboardingErrorType.Unknown };
    }
  };

  const { type, reason } = getErrorType();
  return {
    type: type,
    code: errorCode,
    missingFields,
    reason,
  };
};

export const onboardingErrorTypeToPathValues = (
  errorType: OnboardingErrorType,
): { title: MessageKey; message: MessageKey } => {
  switch (errorType) {
    case OnboardingErrorType.Forbidden:
      return {
        title: 'widgets.onboarding.errorPage.forbidden.title',
        message: 'widgets.onboarding.errorPage.forbidden.message',
      };
    case OnboardingErrorType.BadRequest:
      return {
        title: 'widgets.onboarding.errorPage.badRequest.title',
        message: 'widgets.onboarding.errorPage.badRequest.message',
      };
    case OnboardingErrorType.NonCompliance:
      return {
        title: 'widgets.onboarding.errorPage.nonCompliance.title',
        message: 'widgets.onboarding.errorPage.nonCompliance.message',
      };
    case OnboardingErrorType.Unknown:
    default:
      return {
        title: 'widgets.onboarding.errorPage.unknown.title',
        message: 'widgets.onboarding.errorPage.unknown.message',
      };
  }
};

export const joinWith = (array: string[], delimiter: string, lastDelimiter: string = delimiter) => {
  if (array.length === 0) return '';
  if (array.length === 1) return array[0];
  if (array.length === 2) return array.join(` ${lastDelimiter} `);

  const lastItem = array.pop();
  return `${array.join(`${delimiter} `)} ${lastDelimiter} ${lastItem}`;
};

export const validationKeyToFormatKey: Record<string, MessageKey> = {
  dateOfBirth: 'widgets.onboarding.errorPage.missingFields.keys.dateOfBirth',
  firstName: 'widgets.onboarding.errorPage.missingFields.keys.firstName',
  lastName: 'widgets.onboarding.errorPage.missingFields.keys.lastName',
  phone: 'widgets.onboarding.errorPage.missingFields.keys.phone',
  businessPhone: 'widgets.onboarding.errorPage.missingFields.keys.businessPhone',
  email: 'widgets.onboarding.errorPage.missingFields.keys.email',
  companyName: 'widgets.onboarding.errorPage.missingFields.keys.companyName',
  legalCompanyName: 'widgets.onboarding.errorPage.missingFields.keys.legalCompanyName',
  address: 'widgets.onboarding.errorPage.missingFields.keys.address',
  legalAddress: 'widgets.onboarding.errorPage.missingFields.keys.legalAddress',
  taxIdType: 'widgets.onboarding.errorPage.missingFields.keys.taxIdType',
  taxId: 'widgets.onboarding.errorPage.missingFields.keys.taxId',
  businessType: 'widgets.onboarding.errorPage.missingFields.keys.businessType',
  industry: 'widgets.onboarding.errorPage.missingFields.keys.industry',
};

export function useNotifyOnMissingPartner(partnerName: string) {
  const sendMessage = useSendMessage();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tenant = queryParams.get('tenant');

    if (!partnerName && tenant) {
      sendMessage('AUTHENTICATION_ERROR', { reason: OnboardingErrorReason.TENANT_NOT_FOUND, tenant });
    }
  }, [partnerName, sendMessage]);
}
