// TODO: cleanup when moving vendor to v2
import { Vendor } from '@melio/javascript-sdk';
import type {
  Bill as BaseBill,
  BillAllOfExpandableFields as BaseBillExpandableFields,
  BillLineItem,
  BillsApiGetBillsRequest as BaseBillsApiGetBillsRequest,
} from '@melio/platform-api-axios-client';

export type {
  BillLineItemResponse,
  CategoryBasedBillLineItem,
  CategoryBasedBillLineItemResponse,
  ItemBasedBillLineItem,
  ItemBasedBillLineItemResponse,
  PatchBillsBillIdRequest,
  PatchBillsBillIdRequestLineItem,
  PatchBillsBillIdRequestLineItemLabel,
  PostBillsRequest,
} from '@melio/platform-api-axios-client';
import { DSLSearchQuery } from '@melio/api-client/src/core/types';
import { CategoryBasedBillLineItemTypeEnum, ItemBasedBillLineItemTypeEnum } from '@melio/platform-api-axios-client';

const BillLineItemType = {
  Category: CategoryBasedBillLineItemTypeEnum.Category,
  Item: ItemBasedBillLineItemTypeEnum.Item,
};
type BillLineItemType = (typeof BillLineItemType)[keyof typeof BillLineItemType];
type BillExpandableFields =
  | BaseBillExpandableFields
  | 'file'
  | 'files'
  | 'payments'
  | 'payments.timeline'
  | 'payments.loan'
  | 'none';

type BillsApiSearchParams = {
  'bill.status'?: string | Record<string, string | string[]>;
  'bill.balance'?: number | number[] | Record<string | number, number>;
  'bill.id'?: string | string[];
  'bill.origin'?: string | string[];
  'bill.originId'?: string | string[];
  'vendor.id'?: string | string[];
  'payment.id'?: string;
};

export type Bill = Override<BaseBill, { vendor?: Vendor }>;

export type BillsApiGetBillsRequest = Override<
  BaseBillsApiGetBillsRequest,
  {
    searchTerm?: string;
    search?: DSLSearchQuery<BillsApiSearchParams>;
    expand?: BillExpandableFields | Exclude<BillExpandableFields[], 'none'>;
  }
>;

export { BillLineItemType };
export type { BillLineItem };
