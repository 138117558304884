import { Container, Group, SectionBanner } from '@melio/penny';
import { PaymentFullyExpanded } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { isPaymentCompletedVoidAndRefund } from '../../../../../utils/pay-flow/Payment.utils';
import { usePaymentDescription } from '../../../hooks/usePaymentDescription';
import { PaymentDescription } from '../PaymentDescription';
import { PaymentDetailsTop } from '../PaymentDetailsTop';
import { MarkedAsPaidSection } from '../sections/MarkedAsPaidSection';
import { PayFromSectionRefund } from '../sections/PayFromSectionRefund';
import { SectionWrapper } from '../sections/SectionWrapper';

export const RefundCompletedSectionBanner = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage } = useMelioIntl();

  if (!payment.tracingNumber) {
    return null;
  }

  const isCompletedVoidAndRefund = isPaymentCompletedVoidAndRefund(payment);
  const description = isCompletedVoidAndRefund
    ? formatMessage('widgets.paymentDetails.failed.reason.voidAndRefundCompleted.description', {
        tracingNumber: payment.tracingNumber,
      })
    : formatMessage('widgets.paymentDetails.failed.reason.refundCompleted.description', {
        tracingNumber: payment.tracingNumber,
      });

  return <SectionBanner description={description} variant="neutral" data-testid="refund-completed-alert" />;
};

export const RefundCompleted = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const description = usePaymentDescription(payment);

  return (
    <>
      {description ? <PaymentDescription description={description} /> : null}
      <Container data-testid="pay-dashboard-payment-completed">
        <Group variant="vertical" spacing="m">
          <SectionWrapper>
            <PaymentDetailsTop payment={payment} />
          </SectionWrapper>

          <SectionWrapper>
            {payment.markedAsPaid ? (
              <MarkedAsPaidSection markedAsPayBy={payment.createdBy} scheduledDate={payment.scheduledDate} />
            ) : (
              <PayFromSectionRefund
                fundingSource={payment.fundingSource}
                initiatedDate={payment.processedTimeline?.refundInitiated}
                scheduledDate={payment.scheduledDate}
              />
            )}
          </SectionWrapper>
        </Group>
      </Container>
    </>
  );
};
