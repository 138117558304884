import { Box } from '@chakra-ui/react';
import { IconButton, Text, useTheme } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { containerStyle } from './FilterChip.style';
import { FilterChipProps } from './FilterChip.types';

const MAX_VISIBLE_VALUES_NUM = 2;

const getTextFromValues = (values: string[]) => {
  if (values.length <= MAX_VISIBLE_VALUES_NUM) {
    return values.join(', ');
  }
  return `${values.slice(0, MAX_VISIBLE_VALUES_NUM).join(', ')} +${values.length - MAX_VISIBLE_VALUES_NUM}`;
};

export const FilterChip = ({ label, values, onDeleteFilter, deleteAriaLabel, id }: FilterChipProps) => {
  const theme = useTheme();
  const { formatMessage } = useMelioIntl();
  const value = getTextFromValues(values);

  return (
    <Box
      data-component="FilterChip"
      data-testid={`filter-chip-item-${id}`}
      as="li"
      __css={containerStyle(theme)}
      aria-label={formatMessage('activities.payDashboard.filterChip.text.ariaLabelPrefix', {
        label,
        filters: values.join(', '),
      })}
    >
      <Text textStyle="body4" aria-hidden>
        {label}:
      </Text>
      <Text textStyle="body4Semi" aria-hidden>
        {value}
      </Text>
      <IconButton
        aria-label={
          deleteAriaLabel ?? formatMessage('activities.payDashboard.filterChip.delete.ariaLabel', { filterName: label })
        }
        size="small"
        variant="naked"
        icon="close"
        onClick={onDeleteFilter}
        data-testid="filter-chip-delete"
      />
    </Box>
  );
};
