import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useLocation } from '@melio/platform-utils';

import {
  useFreeTrialEligibility,
  useIsSubscriptionsEnabled,
  useRedirects,
  useSubscribeToFreePlan,
  useSubscription,
} from '../../../hooks';
import { isRouteAllowedForNotSubscribedOrg } from '../../utils';
import { SubscriptionPlansActivity, SubscriptionPlansVariant } from '../SubscriptionPlans';
import { OnboardingPaywallModalBase } from './components/OnboardingPaywallModalBase';

export const OnboardingPaywallModalActivity = () => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { pathname } = useLocation();
  const { subscribeToFreePlan, isSubscribingToFreePlan } = useSubscribeToFreePlan();
  const { goToReturnUrlOrDashboard } = useRedirects();
  const { isEligibleForFreeTrial, isFetching: isFetchingFreeTrialEligibilityData } = useFreeTrialEligibility();
  const { track } = useAnalytics();

  const [onboardingPlansExperimentFeatureFlag] = useDevFeature<'test' | 'control'>(
    FeatureFlags.GrowthExperimentOnboardingPaywallXRemoval,
    'control',
    {
      shouldTrack: true,
    }
  );

  const handleOnClose = (exitMethod: 'esc' | 'background-click' | 'X') => {
    if (isSubscribingToFreePlan) {
      return;
    }

    if (!subscription) {
      track('Organization', 'Click', {
        PageName: 'plan-selection',
        Flow: 'subscription',
        ExitMethod: exitMethod,
        Intent: 'exit',
        Cta: 'exit',
        PlanChosen: 'free',
      });

      void subscribeToFreePlan();
    } else {
      goToReturnUrlOrDashboard();
    }
  };

  if (!isSubscriptionsEnabled) {
    return null;
  }

  const isOpen = !subscription && !isRouteAllowedForNotSubscribedOrg(pathname);

  const header = formatMessage(
    isEligibleForFreeTrial
      ? 'activities.subscription.plans.titleWhenEligibleForFreeTrial'
      : 'activities.subscription.plans.title'
  );

  return (
    <OnboardingPaywallModalBase
      isOpen={isOpen}
      header={header}
      onClose={handleOnClose}
      isLoading={isFetchingFreeTrialEligibilityData}
      hasXClose={onboardingPlansExperimentFeatureFlag === 'control'}
    >
      <SubscriptionPlansActivity
        returnUrl={pathname}
        variant={SubscriptionPlansVariant.MODAL}
        isSubscribingToFreePlan={isSubscribingToFreePlan}
        withOnboardingPlansExperimentOn={onboardingPlansExperimentFeatureFlag === 'test'}
      />
    </OnboardingPaywallModalBase>
  );
};
