import { useNavigationWithQueryParams, useVendorNotPermitted } from '@melio/ap-domain';
import {
  EbillCellBadge,
  LockedVendorIcon,
  useVendorDirectoryInfoComplete,
  VendorAvatar,
  VendorMissingInfoIcon,
} from '@melio/ap-widgets';
import { Container, Group, NakedButton, Table, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { InboxItemBillTypeEnum, VendorEBillStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { identity } from 'lodash';
import { useRef, useState } from 'react';

import { useActivitiesNavigate } from '../../../../../utils';
import { FocusSkipToComponent } from '../../../FocusSkipToComponent';
import { displayAccountNumber } from '../../VendorsTab/utils';
import {
  getBillsTabItemVendor,
  getBillsTabItemVendorId,
  getInboxItemExternalVendorLogo,
  getInboxItemVendorName,
  getIsManagedVendor,
} from '../BillsTab.utils';
import { BILL_SELECTION_FOOTER_REVIEW_AND_PAY_BUTTON_ID } from '../consts';
import { BillsTabItem } from '../types';
import { getFooterHeight } from '../utils';

export type BillsTabVendorNameCellProps = {
  billsTabItem: BillsTabItem;
  rowSelectedCount?: number;
};

export const BillsTabVendorNameCell = ({ billsTabItem, rowSelectedCount = 0 }: BillsTabVendorNameCellProps) => {
  const { formatMessage } = useMelioIntl();
  const { navigateToEbillActivationForm, navigateToAutopayForm } = useActivitiesNavigate();
  const { isExtraSmallScreen } = useBreakpoint();
  const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();
  const { track } = useAnalytics();
  const billsTabItemVendor = getBillsTabItemVendor(billsTabItem);
  const vendorName = getInboxItemVendorName(billsTabItem);
  const vendorExternalLogoUrl = getInboxItemExternalVendorLogo(billsTabItem);
  const vendorId = getBillsTabItemVendorId(billsTabItem);
  const isVendorNotPermitted = useVendorNotPermitted(billsTabItemVendor);
  const ref = useRef<HTMLDivElement>(null);
  const [mouseDown, setMouseDown] = useState(false);

  const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(billsTabItemVendor);

  const onVendorNameClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!vendorId) {
      return;
    }

    track('Dashboard', 'Click', {
      Intent: 'vendor-details',
      Cta: 'vendor-name-click',
    });
    handleNavigationWithQueryParams({ newPath: `vendor/${vendorId}` });
  };

  const onEditVendorClick = () => {
    if (!vendorId) {
      return;
    }

    handleNavigationWithQueryParams({ newPath: `vendor/${vendorId}`, newUrlQueryParam: { edit: 'true' } });
  };

  const isReadOnly = billsTabItem.type === InboxItemBillTypeEnum.Bill && isVendorNotPermitted;
  const label = vendorName || formatMessage('activities.payDashboard.billsTab.table.cells.vendorName.missing');
  const testId = `bills-tab-row-${billsTabItem.payload.id}-vendor-name`;
  const isVendorManaged = getIsManagedVendor(billsTabItem);
  const shouldShowMissingVendorInfoBanner = !isVendorDirectoryInfoCompleted && billsTabItemVendor;
  const description = [billsTabItemVendor?.nickname, displayAccountNumber(billsTabItemVendor?.accountNumber ?? '')]
    .filter(identity)
    .join(' | ');

  return (
    <Table.Cell data-testid={`bills-tab-row-${billsTabItem.payload.id}-vendor-name-cell`} ref={ref}>
      <Group alignItems="center">
        {!isExtraSmallScreen && (
          <VendorAvatar name={vendorName || '?'} logoUrl={vendorExternalLogoUrl} isManaged={isVendorManaged} />
        )}
        <Group variant="vertical" spacing="xxs">
          <Group alignItems="center" spacing="xxs">
            {isReadOnly ? <LockedVendorIcon /> : null}
            {vendorId ? (
              <Container>
                <NakedButton
                  shouldSupportEllipsis
                  variant="secondary"
                  label={label}
                  data-testid={testId}
                  onClick={onVendorNameClick}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  onMouseDown={() => {
                    setMouseDown(true);
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  onMouseUp={() => {
                    setMouseDown(false);
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  onFocus={() => {
                    if (mouseDown) {
                      setMouseDown(false);
                      return;
                    }
                    if (ref.current && rowSelectedCount > 0) {
                      const bottom = ref.current.getBoundingClientRect().bottom;
                      if (bottom > window.innerHeight - getFooterHeight()) {
                        ref.current.scrollIntoView({ block: 'center' });
                      }
                    }
                  }}
                  aria-haspopup="dialog"
                />
              </Container>
            ) : (
              <Container width="full">
                <Text textStyle="inline" color="inherit" shouldSupportEllipsis data-testid={testId}>
                  {label}
                </Text>
              </Container>
            )}

            {shouldShowMissingVendorInfoBanner ? (
              <VendorMissingInfoIcon onEditClick={onEditVendorClick} vendorName={billsTabItemVendor.name} />
            ) : null}
            {billsTabItemVendor && billsTabItemVendor.eBillStatus !== VendorEBillStatusEnum.NotCapable && (
              <EbillCellBadge
                vendor={billsTabItemVendor}
                onEBillSubscribeClick={(vendorId) => navigateToEbillActivationForm({ vendorId })}
                onAutoPayActivationClick={(vendorId) => navigateToAutopayForm({ vendorId })}
                isReadOnly={isReadOnly}
              />
            )}
          </Group>
          <Text textStyle="body4" color="global.neutral.800" shouldSupportEllipsis>
            {description}
          </Text>
          <FocusSkipToComponent
            disable={rowSelectedCount === 0}
            text="activities.payDashboard.billsTab.selectionFooter.reviewAndPay.skipTo"
            href={`#${BILL_SELECTION_FOOTER_REVIEW_AND_PAY_BUTTON_ID}`}
            dataTestid="bills-pay-and-review"
          />
        </Group>
      </Group>
    </Table.Cell>
  );
};
