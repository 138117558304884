import { useModelApi, UseModelApiProps } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { ApiKeys, OffersApiClient } from '@melio/platform-api-axios-client';

import { Offer, OffersApiGetOfferRequest } from './types';

type QueryFn = typeof OffersApiClient.getOffer;

export type UseGetOfferProps = UseModelApiProps<QueryFn, Offer> & {
  id: string;
  params?: OffersApiGetOfferRequest;
};

export const useGetOffer = ({ id, enabled, params, ...props }: UseGetOfferProps) =>
  useModelApi({
    ...props,
    id,
    enabled: !!id && enabled,
    queryKey: [ApiKeys.OffersApi, id],
    queryFn: () => OffersApiClient.getOffer(id),
  });
