import { Button, Divider, Group, GroupProps, Text, useBreakpointValue } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePlanInfo, usePlansTiers, useSubscription } from '../../../../hooks';
import { useSubscriptionRouter } from '../../../utils';
import { SectionSettingCard } from '../../SectionSettingCard';
import { CurrentPlanCell } from '../CurrentPlanCell';
import { PendingSubscriptionPlanChange } from '../PendingSubscriptionPlanChange';
import { CurrentPlanExtendedDetails } from './CurrentPlanExtendedDetails';

export const EditablePlanCardFiserv = () => {
  const { goToSubscriptionPlans } = useSubscriptionRouter();
  const { getIsFreePlan } = usePlansTiers();
  const subscription = useSubscription();
  const { planName } = usePlanInfo(subscription?.planId);
  const { formatMessage, formatCurrency } = useMelioIntl();
  const groupVariant: GroupProps['variant'] = useBreakpointValue({ xs: 'vertical', s: 'horizontal' });
  const isFreePlan = getIsFreePlan(subscription?.planId || '');

  const { track } = useAnalytics();
  const onManagePlan = (e: React.MouseEvent) => {
    e.preventDefault();
    track('Settings', 'Click', {
      Intent: 'manage-subscription',
      Cta: 'manage-subscription',
    });
    goToSubscriptionPlans({ returnUrl: '/settings/subscription-plans' });
  };

  return (
    <SectionSettingCard data-testid="subscription-current-plan">
      <Group width="full" variant="vertical" spacing="m" alignItems="flex-start">
        <Group width="full" alignItems="center" justifyContent="space-between">
          <Group spacing="xxs" variant="vertical">
            <Text as="h4" textStyle="heading3Semi" data-testid="current-plan-name">
              {planName}
            </Text>
            <PendingSubscriptionPlanChange />
          </Group>

          <Button
            variant={isFreePlan ? 'primary' : 'secondary'}
            data-testid="subscription-plans-manage-fiserv"
            label={formatMessage('activities.settings.editablePlanCard.action.managePlan')}
            onClick={onManagePlan}
            link={{
              href: '#',
            }}
          />
        </Group>

        <Group hasDivider spacing="m" width="full" variant={groupVariant}>
          <CurrentPlanCell
            label={formatMessage('activities.settings.editablePlanCardFiserv.title.subscriptionFee')}
            tooltipLabel={formatMessage('activities.settings.editablePlanCard.tooltip.subscriptionFee')}
            tooltipPreTitle={formatMessage(
              'activities.settings.editablePlanCard.tooltip.subscriptionFee.ariaLabelPrefix'
            )}
          >
            {subscription?.nextBillingAmount && (
              <Text textStyle="body2">{formatCurrency(subscription.nextBillingAmount)}</Text>
            )}
            {subscription?.planCyclePeriod && (
              <Text textStyle="body3" color="global.neutral.800">
                {formatMessage('activities.settings.editablePlanCardFiserv.title.nextPayment.description')}
              </Text>
            )}
          </CurrentPlanCell>
        </Group>

        <Divider />
        <CurrentPlanExtendedDetails />
      </Group>
    </SectionSettingCard>
  );
};
