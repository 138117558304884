import { IconKey } from '@melio/penny';
import {
  DeliveryMethod,
  formatAddress,
  useGetDeliveryMethodUnmaskedAccountNumberQuery,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

export const useDeliveryMethodCardProps = ({
  vendor,
  deliveryMethod,
}: {
  vendor: Vendor | undefined;
  deliveryMethod: DeliveryMethod | undefined;
}): { title: string; description: string; icon: IconKey; isLoading: boolean } => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: { unmaskVendorAccountNumber: shouldUnmaskAccountNumber },
  } = useConfig();

  const { data: unmaskedAccountNumber, isLoading } = useGetDeliveryMethodUnmaskedAccountNumberQuery({
    enabled: deliveryMethod?.type === 'bank-account' && shouldUnmaskAccountNumber,
    deliveryMethodId: deliveryMethod?.id,
  });

  if (!vendor || !deliveryMethod) {
    return {
      title: '',
      isLoading: false,
      icon: 'error',
      description: '',
    };
  }

  switch (deliveryMethod.type) {
    case 'managed-account':
      if (deliveryMethod.details.deliveryType === 'check') {
        return {
          title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.managed-account.check.title'),
          description: formatMessage(
            'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.check.description',
            { vendorName: vendor.name }
          ),
          icon: 'paper-check',
          isLoading: false,
        };
      }
      return {
        title: formatMessage(
          'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.bank-account.title'
        ),
        description: formatMessage(
          'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.bank-account.description',
          { vendorName: vendor.name }
        ),
        icon: 'bank-out',
        isLoading: false,
      };
    case 'bank-account':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.bank-account.title'),
        description: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.bank-account.description', {
          account: (shouldUnmaskAccountNumber ? unmaskedAccountNumber : deliveryMethod.details.accountNumber)?.slice(
            -4
          ),
        }),
        icon: 'bank-out',
        isLoading: shouldUnmaskAccountNumber ? isLoading : false,
      };
    case 'paper-check':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paper-check.title'),
        description: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paper-check.description', {
          vendorName: vendor.name,
          address: formatAddress(deliveryMethod.details.address),
        }),
        icon: 'paper-check',
        isLoading: false,
      };
    case 'paypal-balance':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paypal-balance.title'),
        description: deliveryMethod.details.paypalAccountEmail,
        icon: 'paypal',
        isLoading: false,
      };

    default:
      return {
        title: 'not implemented',
        description: 'not implemented',
        icon: 'error',
        isLoading: false,
      };
  }
};
