import { useEffect, useState } from 'react';

export const useArrowsStateMachine = (totalElements: number) => {
  const [currentElementIndex, setCurrentElementIndex] = useState(0);

  const lastItemIndex = totalElements > 0 ? totalElements - 1 : 0;

  useEffect(() => {
    if (currentElementIndex > lastItemIndex) {
      setCurrentElementIndex(lastItemIndex);
    }
  }, [currentElementIndex, lastItemIndex]);

  const handlePrevClick = () => {
    if (totalElements < 1) {
      return;
    }
    setCurrentElementIndex(currentElementIndex === 0 ? lastItemIndex : currentElementIndex - 1);
  };

  const handleNextClick = () => {
    if (totalElements < 1) {
      return;
    }
    setCurrentElementIndex(currentElementIndex === lastItemIndex ? 0 : currentElementIndex + 1);
  };

  return {
    handleNextClick,
    handlePrevClick,
    currentElementIndex,
  };
};
