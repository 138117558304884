import { Fee, MethodCard } from '@melio/ap-widgets';
import { BankAccount, DeliveryMethod, DeliveryMethodType, FeeType, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { AddDeliveryMethodActionButton, DeliveryMethodActionsMenu } from '../components/DeliveryMethodActions';
import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';
import { DeliveryMethodIcon } from '../components/DeliveryMethodIcon';

type DomesticWireMethodProps = {
  vendor: Vendor;
  domesticWire: DeliveryMethod | null;
  onClick: VoidFunction;
};

export const DomesticWireDeliveryMethodCard = ({ vendor, domesticWire, onClick }: DomesticWireMethodProps) => {
  const { formatMessage, formatDate } = useMelioIntl();

  const caption = (() => {
    if (!domesticWire) {
      return {
        secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.domesticWire.comment', {
          domesticWireFee: <Fee feeType={FeeType.DomesticWire} />,
        }),
      };
    }

    return {
      primary: formatMessage('widgets.addOrUpdateDeliveryMethod.domesticWire.info.info1', {
        companyName: vendor.name,
        bankAccount: (domesticWire.details as BankAccount).accountNumber.slice(-4),
      }),
      secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.domesticWire.info.info2', {
        updatedDate: formatDate(domesticWire.history.updatedAt),
      }),
    };
  })();

  return (
    <MethodCard
      testIds={{ root: 'MethodCard-domestic-wire-account' }}
      displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.domesticWire.title')}
      icon={<DeliveryMethodIcon iconType="bank-out" />}
      helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.domesticWire.feeText', {
        domesticWireFee: <Fee feeType={FeeType.DomesticWire} />,
      })}
      actionElement={
        domesticWire ? (
          <DeliveryMethodActionsMenu
            actions={[
              {
                label: formatMessage('widgets.addOrUpdateDeliveryMethod.edit'),
                onClick,
                dataTestId: 'edit-delivery-method-item',
              },
            ]}
          />
        ) : (
          <AddDeliveryMethodActionButton
            onClick={onClick}
            data-testid={`add-delivery-method-card-${DeliveryMethodType.DomesticWireAccount}`}
          />
        )
      }
    >
      <DeliveryMethodCaption primary={caption.primary} secondary={caption.secondary} />
    </MethodCard>
  );
};
