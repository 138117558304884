import { PreSeason1099SectionBanner, Season1099SectionBanner } from '@melio/ap-widgets';
import {
  CompleteMandatoryDetailsWarningBanner,
  ComplianceVerificationFailedBanner,
  OrgOwesUsMoneyBanner,
} from '@melio/platform-kyc';

import { PayDashboardActivityBanner, PayDashboardActivityProps } from '../payDashboardActivityPropType';

export const usePayDashboardBanner = ({
  bannerToDisplay,
  onCloseCompleteDetailsDialog,
  openSync1099ContractorsModal,
}: Pick<
  PayDashboardActivityProps,
  'bannerToDisplay' | 'onCloseCompleteDetailsDialog' | 'openSync1099ContractorsModal'
>) => {
  if (!bannerToDisplay) {
    return;
  }
  const bannerPropsMap: Record<
    PayDashboardActivityBanner,
    { Banner: React.FC; analyticsProps: { BannerCta: string | undefined } }
  > = {
    'kyc-complete-details': {
      Banner: () => (
        <CompleteMandatoryDetailsWarningBanner onOpenCompleteRequiredDetailsDialog={onCloseCompleteDetailsDialog} />
      ),
      analyticsProps: {
        BannerCta: 'complete-details',
      },
    },
    'kyc-verification-failed': {
      Banner: () => <ComplianceVerificationFailedBanner />,
      analyticsProps: {
        BannerCta: undefined,
      },
    },
    'pre-season-1099-banner': {
      Banner: () => <PreSeason1099SectionBanner />,
      analyticsProps: {
        BannerCta: undefined,
      },
    },
    'season-1099-banner': {
      Banner: () => <Season1099SectionBanner openSync1099ContractorsModal={openSync1099ContractorsModal} />,
      analyticsProps: {
        BannerCta: undefined,
      },
    },
    'org-owes-us-money-banner': {
      Banner: () => <OrgOwesUsMoneyBanner />,
      analyticsProps: {
        BannerCta: undefined,
      },
    },
  };

  return bannerPropsMap[bannerToDisplay];
};
