import {
  AccountingPlatform,
  AccountingPlatformSlug,
  QuickBooksDesktopAccountingPlatform,
  QuickBooksOnlineAccountingPlatform,
  XeroAccountingPlatform,
} from '@melio/platform-api';

type AccountingSlugProp = (
  | XeroAccountingPlatform
  | QuickBooksOnlineAccountingPlatform
  | QuickBooksDesktopAccountingPlatform
)['accountingSlug'];

export const getAccountingPlatformBrandSymbolType = (accountingSlug: AccountingSlugProp) => {
  switch (accountingSlug) {
    case AccountingPlatformSlug.QuickBooksOnline:
    case AccountingPlatformSlug.QuickBooksDesktop:
      return 'quickbooks';
    case AccountingPlatformSlug.Xero:
      return 'xero-logo';
  }
};

export const getAccountingPlatformNameForAnalytics = (
  accountingSlug?: AccountingPlatform['accountingSlug']
): string => {
  if (!accountingSlug) {
    return '';
  }

  switch (accountingSlug) {
    case AccountingPlatformSlug.QuickBooksOnline:
      return 'qbo';
    case AccountingPlatformSlug.Xero:
      return 'xero';
    case AccountingPlatformSlug.QuickBooksDesktop:
      return 'qbdt';
    default:
      return '';
  }
};

export const getSyncMonitoringActionName = (accountingSlug?: AccountingPlatformSlug, isFirstSync?: boolean): string => {
  switch (accountingSlug) {
    case AccountingPlatformSlug.QuickBooksOnline:
      return isFirstSync ? 'first_accounting_software_sync_quickbooks' : 'accounting_software_sync_quickbooks';
    case AccountingPlatformSlug.QuickBooksDesktop:
      return isFirstSync
        ? 'first_accounting_software_sync_quickbooks_desktop'
        : 'accounting_software_sync_quickbooks_desktop';
    case AccountingPlatformSlug.Xero:
      return isFirstSync ? 'first_accounting_software_sync_xero' : 'accounting_software_sync_xero';
    default:
      return '';
  }
};
