import { useAnalytics } from '@melio/platform-analytics';
import { useMonitoring } from '@melio/platform-monitoring';

import { MonitoredAction } from '../../../monitoring';
import { TaxPayerInfoForm, TaxPayerInfoFormProps } from './TaxPayerInfoForm';

export const TaxPayerInfoFormWidget = (props: TaxPayerInfoFormProps) => {
  const { track } = useAnalytics();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();

  const handleSuccessUploadW9File = () => {
    track('Vendor', 'Status', {
      Intent: 'upload-w9-file',
      TaxId: 'file',
      Status: 'success',
    });

    endAction('w9_upload_at_vendor_edit');
  };

  const handleSelectAndUploadFile = () => {
    track('Vendor', 'Click', {
      Intent: 'upload-w9-file',
      TaxId: 'file',
      Cta: 'upload-w9-file',
    });

    startAction('w9_upload_at_vendor_edit');
  };

  return (
    <TaxPayerInfoForm
      onSuccessUploadW9File={handleSuccessUploadW9File}
      onSelectAndUploadFile={handleSelectAndUploadFile}
      {...props}
    />
  );
};
