import { useOneTimeEffect } from '@melio/platform-utils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { AccountingPlatformSyncConnectionErrorEnum, SyncAppStateType, SyncFlowEnum } from '../types';

type ConnectAppStateType = SyncAppStateType & {
  authError?: AccountingPlatformSyncConnectionErrorEnum;
  authParams: Record<string, unknown>;
};

export const useParseAuthSearchParams = () => {
  const [searchParams] = useSearchParams();
  const [connectAppState, setConnectAppState] = React.useState<ConnectAppStateType>();

  useOneTimeEffect(
    function parseAuthSearchParams() {
      const { error, state = '', ...rest } = Object.fromEntries(searchParams);
      const orgId = searchParams.get('orgId');
      const authToken = searchParams.get('authToken');
      let authError;
      let flow = SyncFlowEnum.Setting;
      let accountingPlatformId;
      let isQuickBooksOnline = false;
      let isQuickBooksDesktop = false;

      if (error) {
        authError =
          error === 'access_denied'
            ? AccountingPlatformSyncConnectionErrorEnum.AccessDenied
            : AccountingPlatformSyncConnectionErrorEnum.GeneralError;
      }

      try {
        // Handle the case when the user is redirected from the QuickBooks Online
        if (orgId && authToken) {
          const appStateString = searchParams.get('state');
          const appState = (appStateString && JSON.parse(decodeURIComponent(appStateString))) as SyncAppStateType;
          flow = appState ? appState.flow : SyncFlowEnum.Setting;
          isQuickBooksOnline = appState?.isQuickBooksOnline;
          accountingPlatformId = appState?.accountingPlatformId;
        } else {
          const parsedState = JSON.parse(window.atob(state)) as SyncAppStateType;
          flow = parsedState?.flow || SyncFlowEnum.Setting;
          isQuickBooksDesktop = parsedState?.isQuickBooksDesktop || false;
          accountingPlatformId = parsedState?.accountingPlatformId;

          if (!accountingPlatformId) {
            authError = AccountingPlatformSyncConnectionErrorEnum.InvalidState;
          }
        }
      } catch (error) {
        authError = AccountingPlatformSyncConnectionErrorEnum.InvalidState;
      }

      setConnectAppState({
        flow,
        orgId,
        authToken,
        isQuickBooksDesktop,
        isQuickBooksOnline,
        accountingPlatformId,
        authError,
        authParams: { ...rest },
      });
    },
    () => true,
    [searchParams]
  );

  return connectAppState;
};
