import { Container, Drawer, Group, Text, useBreakpoint, useFormSubmissionController } from '@melio/penny';
import { EventProperties, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  CreateVendorAnalyticsMetadataFlow,
  CreateVendorParams,
  isVendorBankAccountNotCreated,
  ModelError,
  useVendors,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay, useSystemMessage, withSystemMessageProvider } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { getErrorsByType, getInlineApiErrorsFields } from '../../apiErrorsUtils';
import { AddVendorOnDone, ErrorTypeToErrorCodesMap, VendorFormBannerApiErrorCode } from '../../types';
import {
  AddVendorFormFields,
  AddVendorFormWidget,
  AddVendorFormWidgetProps,
  useSwitchToUnmanagedForm,
} from '../AddVendorForm';
import { AddVendorDrawerFooter } from './AddVendorDrawerFooter.widget';

export type AddVendorDrawerWidgetProps = {
  managed?: AddVendorFormWidgetProps['managed'];
  isOpen: boolean;
  onClose: VoidFunction;
  onDone: AddVendorOnDone;
  defaultFormValues?: Override<
    Partial<AddVendorFormFields>,
    {
      companyName: string;
    }
  >;
  analyticsProperties?: EventProperties;
  createVendorFlow?: CreateVendorAnalyticsMetadataFlow;
  onSelectCompanyName?: AddVendorFormWidgetProps['onSelectCompanyName'];
  onSwitchToUnmanagedForm: (
    formData: Pick<AddVendorFormFields, 'companyName' | 'accountNumber' | 'postalCode'>
  ) => void;
  pageEntryPoint: 'bill-details' | 'pay';
};

export const AddVendorDrawerWidget = withSystemMessageProvider(
  withAnalyticsContext<AddVendorDrawerWidgetProps>(
    ({
      setAnalyticsProperties,
      managed,
      isOpen,
      onClose,
      onDone,
      defaultFormValues,
      analyticsProperties,
      createVendorFlow,
      onSelectCompanyName,
      onSwitchToUnmanagedForm,
      pageEntryPoint,
    }) => {
      const { onSubmissionStateChange, submitButtonProps, cancelButtonProps, handleSubmit } =
        useFormSubmissionController<AddVendorFormFields>();
      const { formatMessage } = useMelioIntl();
      const { isExtraSmallScreen } = useBreakpoint();

      const [apiErrorsCodes, setApiErrorsCodes] = useState<Pick<ErrorTypeToErrorCodesMap, 'inline' | 'banner'>>();

      const { create: createVendor, isMutating: isCreatingVendor } = useVendors({ enabled: false });

      const { showMessage } = useSystemMessage();

      const { track, trackMarketing } = useAnalytics();

      setAnalyticsProperties({
        PageName: 'add-a-vendor',
        PageEntryPoint: pageEntryPoint,
        Intent: 'add-vendor-on-the-go',
      });

      useEffect(() => {
        track('Vendor', 'View', { VendorType: managed ? 'directory' : 'local' });
      }, [track, managed]);

      const { onSwitchToUnmanaged, shouldSwitchToUnmanaged } = useSwitchToUnmanagedForm({
        onSwitch: ({ companyName, accountNumber, postalCode }) => {
          onSwitchToUnmanagedForm({
            companyName,
            accountNumber,
            postalCode,
          });
          setApiErrorsCodes(undefined);
        },
      });

      type TrackCreateVendorStatusParams = {
        vendorType: 'directory' | 'local';
        status: 'success' | 'failure';
        vendorId?: string;
        inlineErrorsFields?: (keyof AddVendorFormFields)[];
        bannerErrorsTypes?: VendorFormBannerApiErrorCode[];
      };

      const trackCreateVendorStatus = ({
        vendorType,
        status,
        vendorId,
        inlineErrorsFields,
        bannerErrorsTypes,
      }: TrackCreateVendorStatusParams) => {
        const properties = {
          PageName: 'add-a-vendor',
          Intent: 'add-a-vendor',
          VendorType: vendorType,
          Status: status,
          InlineErrorsFields: inlineErrorsFields,
          BannerErrorsTypes: bannerErrorsTypes,
          ...(analyticsProperties ?? {}),
          ...(vendorId ? { VendorId: vendorId } : {}),
        };

        track('Vendor', 'Status', properties);
        status === 'success' && trackMarketing('bill-create_create-vendor', properties);
      };

      const handleCreateVendor = (data: CreateVendorParams) => {
        setApiErrorsCodes(undefined);

        const analyticsMetadata = createVendorFlow
          ? {
              flow: createVendorFlow,
            }
          : undefined;
        createVendor({ ...data, analyticsMetadata })
          .then((vendor) => {
            trackCreateVendorStatus({
              vendorType: managed ? 'directory' : 'local',
              status: 'success',
              vendorId: vendor.id,
            });
            const isBankAccountNotCreated = isVendorBankAccountNotCreated((vendor as Vendor).warnings);

            onDone({
              vendor: vendor as Vendor,
              isBankAccountNotCreated,
              bankAccountNumber: data.bankAccount?.accountNumber,
            });
          })
          .catch((error: ModelError) => handleCreateVendorFail(data, error));
      };

      const handleCreateVendorFail = (submittedData: CreateVendorParams, error: ModelError) => {
        const { inline, banner } = getErrorsByType(error, !!managed?.isZipCodeNeeded);

        if (inline?.length || banner?.length) {
          trackCreateVendorStatus({
            vendorType: managed ? 'directory' : 'local',
            status: 'failure',
            ...(inline?.length ? { inlineErrorsFields: getInlineApiErrorsFields(inline) } : {}),
            ...(banner?.length ? { bannerErrorsTypes: banner } : {}),
          });
          setApiErrorsCodes({ inline, banner });
        } else {
          // TODO: add monitor for a case where getErrorsByType return unknow key.length https://meliorisk.atlassian.net/browse/ME-41490
          showMessage({
            type: 'error',
            title: formatMessage('addVendor.createVendorToast.error', {
              companyName: submittedData.name,
            }),
            action: {
              text: formatMessage('addVendor.createVendorToast.error.action'),
              onAction: (close) => {
                close();
                handleCreateVendor(submittedData);
              },
              type: 'button',
            },
          });
        }

        if (shouldSwitchToUnmanaged(error)) {
          onSwitchToUnmanaged({
            companyName: submittedData.name,
            accountNumber: submittedData.accountNumber,
            postalCode: submittedData.managed?.zipCode,
          });
        }
      };

      const onSubmit = () => {
        track(`Vendor`, 'Click', {
          VendorType: managed ? 'directory' : 'local',
          Cta: 'save',
        });
        handleSubmit?.()();
      };

      const handleCloseClick = () => {
        track('Vendor', 'Click', {
          Cta: 'exit',
        });

        onClose();
      };

      const handleCancelClick = () => {
        track('Vendor', 'Click', {
          Cta: 'cancel',
        });

        onClose();
      };

      return (
        <Drawer
          isOpen={isOpen}
          onClose={handleCloseClick}
          data-testid="add-vendor-drawer"
          closeButtonAriaLabel={formatMessage('widgets.vendorDrawer.closeButtonAriaLabel')}
          header={
            <Text as="h2" textStyle="heading2Semi">
              {formatMessage('widgets.addVendorDrawer.title', { companyName: defaultFormValues?.companyName })}
            </Text>
          }
          body={
            <Group variant="vertical" spacing="m">
              <SystemMessageDisplay />
              <Container overflow="visible">
                <Container paddingBottom="xs" paddingTop={isExtraSmallScreen ? 'xs' : undefined}>
                  <Text color="global.neutral.900" textStyle="body4">
                    {formatMessage('widgets.addVendorDrawer.requiredFields')}
                  </Text>
                </Container>
                <AddVendorFormWidget
                  onSubmit={handleCreateVendor}
                  onSubmissionStateChange={onSubmissionStateChange}
                  isSaving={isCreatingVendor}
                  managed={managed}
                  defaultValues={defaultFormValues}
                  inlineApiErrorCodes={apiErrorsCodes?.inline}
                  bannerApiErrorCodes={apiErrorsCodes?.banner}
                  onSelectCompanyName={onSelectCompanyName}
                  titlesAs="h3"
                />
              </Container>
            </Group>
          }
          footer={
            <AddVendorDrawerFooter
              onClose={handleCancelClick}
              cancelButtonProps={cancelButtonProps}
              submitButtonProps={
                submitButtonProps
                  ? {
                      ...submitButtonProps,
                      onClick: onSubmit,
                    }
                  : undefined
              }
            />
          }
        />
      );
    }
  )
);
