import { useLocation } from '@melio/platform-utils';
import { useNavigate } from 'react-router-dom';

import {
  DEFAULT_DASHBOARD_ROUTE,
  SETTINGS_COLLABORATORS_ROUTE,
  SETTINGS_SUBSCRIPTION_PLANS_ROUTE,
  SETTINGS_SUPPORT_ROUTE,
  SUBSCRIPTION_SUCCESS_ROUTE,
} from '../router/routes.consts';

export const useRedirects = () => {
  const navigate = useNavigate();
  const { state } = useLocation<{ returnUrl?: string } | null>();

  const { returnUrl } = (state as { returnUrl?: string }) || {};

  const goToReturnUrlOrDashboard = () => navigate(returnUrl || DEFAULT_DASHBOARD_ROUTE);

  const goToDashboard = (state = {}) => navigate(DEFAULT_DASHBOARD_ROUTE, state);
  const goToSettingsPage = () => navigate(SETTINGS_SUBSCRIPTION_PLANS_ROUTE);
  const goToSuccessPage = () => navigate(SUBSCRIPTION_SUCCESS_ROUTE, { state: { returnUrl } });
  const goToSettingsCollaboratorsPage = () => navigate(SETTINGS_COLLABORATORS_ROUTE);
  const goToSettingsSupportPage = () => navigate(SETTINGS_SUPPORT_ROUTE);

  return {
    goToReturnUrlOrDashboard,
    goToDashboard,
    goToSettingsPage,
    goToSuccessPage,
    goToSettingsCollaboratorsPage,
    goToSettingsSupportPage,
  };
};
