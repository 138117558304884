import { OriginFlow } from '@melio/platform-analytics';
import { usePartnerFeature } from '@melio/platform-provider';
import { useNavigate } from 'react-router-dom';

import { AccountingPlatformSyncConnectionErrorEnum } from '../components';
import { PayDashboardTabs } from '../components/PayDashboard/types';

type UrlParams = { key: string; value: string }[];

const buildQueryString = (urlParams?: UrlParams) =>
  urlParams ? `?${urlParams.map((param) => param.key + '=' + param.value).join('&')}` : '';

const generateNPEDashboardLink = (tab?: PayDashboardTabs, options: { entityId?: string; urlParams?: UrlParams } = {}) =>
  `/pay-dashboard${tab ? `/${tab}` : ''}${options.entityId ? `/${options.entityId}` : ''}${buildQueryString(
    options.urlParams
  )}`;

const getSchedulePaymentLink = (isNewPaymentFlowEnabled: boolean, billId?: string) => {
  if (billId) {
    return isNewPaymentFlowEnabled
      ? `/payment/new?${new URLSearchParams({ billId }).toString()}`
      : `/schedule-payment/${billId}`;
  } else {
    return isNewPaymentFlowEnabled ? '/payment/new' : '/schedule-payment/new';
  }
};

export const useActivitiesNavigate = () => {
  const navigate = useNavigate();
  const [isNewPaymentFlowEnabled] = usePartnerFeature('payment.newPaymentFlow', false);

  return {
    navigateToTab: (
      tab: PayDashboardTabs,
      options?: { keepToast?: boolean; entityId?: string; urlParams?: UrlParams }
    ) => navigate(generateNPEDashboardLink(tab, options), { state: { keepToast: options?.keepToast } }),
    generateNPEDashboardLink,
    navigateToScheduleNewPayment: ({
      vendorId,
      deliveryMethodId,
      amount,
      origin,
      returnUrl,
      keepToast,
    }: {
      vendorId?: string;
      amount?: string;
      deliveryMethodId?: string;
      origin?: OriginFlow;
      returnUrl?: string;
      keepToast?: boolean;
    }) =>
      navigate(getSchedulePaymentLink(isNewPaymentFlowEnabled), {
        state: { vendorId, deliveryMethodId, amount, origin, returnUrl, keepToast },
      }),
    navigateToSchedulePayment: ({
      billId,
      returnUrl,
      deliveryMethodId,
      keepToast,
      vendorId,
    }: {
      billId?: string;
      vendorId?: string;
      returnUrl?: string;
      deliveryMethodId?: string;
      keepToast?: boolean;
    }) =>
      billId
        ? navigate(getSchedulePaymentLink(isNewPaymentFlowEnabled, billId), {
            state: { returnUrl, deliveryMethodId, keepToast },
          })
        : navigate(getSchedulePaymentLink(isNewPaymentFlowEnabled), {
            state: { vendorId, deliveryMethodId, returnUrl, keepToast },
          }),
    navigateToBatchPayment: ({ billIds, returnUrl }: { billIds: string[]; returnUrl?: string }) =>
      navigate(`/batch-payments/${billIds.join(',')}`, { state: { returnUrl } }),
    navigateToSettingsPage: () => navigate('/settings'),
    navigateToSettingsSupportPage: () => navigate('/settings/support'),
    navigateToSettingsCompanyPage: () => navigate('/settings/company'),
    navigateToSettingsCollaborators: () => navigate('/settings/collaborators'),
    navigateToSettingsAccountingPlatform: ({
      syncError,
      accountingPlatformId,
    }: {
      syncError?: AccountingPlatformSyncConnectionErrorEnum;
      accountingPlatformId?: string;
    } = {}) =>
      navigate('/settings/accounting-software', {
        state: {
          syncError,
          accountingPlatformId,
        },
      }),
    getSchedulePaymentLink: (billId?: string) => getSchedulePaymentLink(isNewPaymentFlowEnabled, billId),
    getBatchPaymentsLink: (billIds: string[]) => `/batch-payments/${billIds.join(',')}`,
    navigateToEbillActivationForm: ({ vendorId, returnUrl }: { vendorId: string; returnUrl?: string }) =>
      navigate(`/vendors/${vendorId}/ebills-subscription`, { state: { returnUrl } }),
    navigateToAutopayForm: ({ vendorId, returnUrl }: { vendorId: string; returnUrl?: string }) =>
      navigate(`/vendors/${vendorId}/auto-payment-activation/new`, { state: { returnUrl } }),
    navigateToImportVendors: () => navigate('/vendors/import'),
    navigateToNewVendor: ({ returnUrl, originFlow }: { returnUrl?: string; originFlow?: OriginFlow } = {}) =>
      navigate('/vendors/new-vendor', { state: { returnUrl, origin: originFlow } }),
  };
};
