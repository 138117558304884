import { useDisclosure } from '@chakra-ui/react';
import { _createFormFieldInput, Container, TextFieldProps, useTheme } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { ColorPickerModal } from './ColorPickerModal';
import { ColorPickerTextField } from './ColorPickerTextField';
import { isHexColorValid } from './utils';

export type ColorPickerProps = Omit<TextFieldProps, 'value'> & {
  value?: string;
  modalText: { header: string; description: string; inputLabel: string };
  onEditClick?: () => void;
};

export const ColorPickerInput = _createFormFieldInput(
  forwardRef<ColorPickerProps, 'input'>(
    ({ value, isLoading, modalText, onChange, onEditClick: _onEditClick, ...inputProps }, ref) => {
      const modal = useDisclosure();
      const theme = useTheme();
      const grayColor = theme.colors.semantic.surface.secondary.rest.replace('#', '').toUpperCase();
      const hexColorEmptyState = isHexColorValid(grayColor) ? grayColor : 'F8FAFC';

      const onEditClick = () => {
        _onEditClick?.();
        modal.onOpen();
      };

      return (
        <Container ref={ref} data-testid="color-picker-input">
          <ColorPickerTextField
            {...inputProps}
            isLoading={isLoading}
            onEditClick={onEditClick}
            value={value?.replace('#', '') || hexColorEmptyState}
          />
          <ColorPickerModal
            isOpen={modal.isOpen}
            onClose={modal.onClose}
            hexColor={value?.replace('#', '')}
            hexColorEmptyState={hexColorEmptyState}
            text={{ ...modalText, inputPlaceHolder: hexColorEmptyState }}
            onSubmit={(color) => {
              onChange?.({ target: { value: color || '' } } as never);
              modal.onClose();
            }}
          />
        </Container>
      );
    }
  )
);
