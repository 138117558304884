import { useInvoices, UseInvoicesProps, useSearchParams } from '@melio/ar-domain';
import { useDebounceCallback } from '@melio/platform-utils';
import { isArray, isNil } from 'lodash';
import { useMemo } from 'react';

import { DatePreset, DateRange, Filter, Order, SortFields, SortParams, UseSortableInvoicesParams } from '../types';

const DEBOUNCE_TIME = 200;

type QueryParams = {
  sortOrder: Order;
  sortBy: SortFields;
  pageNumber: number;
  invoiceStatus?: Filter[] | Filter;
  searchTerm: string;
  customerId: string;
  dueDate?: DatePreset;
  updatedAt?: DatePreset;
  dueDateRange?: DateRange;
  updatedAtRange?: DateRange;
};

type InvoiceParams = Omit<NonNullable<UseInvoicesProps['params']>, 'sort'> & {
  sort: SortParams;
};

const useQueryParams = () => {
  const [searchParams, _setSearchParams] = useSearchParams<QueryParams>();

  const setSearchParams = useDebounceCallback<typeof _setSearchParams>(
    (params, options) =>
      _setSearchParams(
        (prev) =>
          Object.entries({ ...prev, ...params }).reduce(
            (acc, [key, value]) => (value === '' ? acc : { ...acc, [key]: value }),
            {} as Partial<QueryParams>
          ),
        options
      ),
    10
  );

  const {
    invoiceStatus,
    pageNumber = 1,
    searchTerm,
    sortBy = 'updatedAt',
    sortOrder = 'Desc',
    customerId,
    dueDate,
    updatedAt,
    dueDateRange,
    updatedAtRange,
  } = searchParams;

  return {
    searchParams: useMemo(
      () => ({
        sortOrder,
        sortBy,
        invoiceStatus: invoiceStatus || undefined,
        searchTerm,
        pageNumber,
        customerId,
        dueDate,
        updatedAt,
        dueDateRange,
        updatedAtRange,
      }),
      [
        invoiceStatus,
        pageNumber,
        searchTerm,
        sortBy,
        sortOrder,
        customerId,
        dueDateRange,
        updatedAtRange,
        dueDate,
        updatedAt,
      ]
    ),
    setSearchParams,
  };
};

export const useInvoicesFiltering = () => {
  const { searchParams, setSearchParams } = useQueryParams();

  const setSearchParamsDebounced = useDebounceCallback(setSearchParams, DEBOUNCE_TIME);

  const { searchTerm, customerId } = searchParams;
  const invoiceStatus =
    isArray(searchParams.invoiceStatus) || !searchParams.invoiceStatus
      ? searchParams.invoiceStatus
      : [searchParams.invoiceStatus];

  const params: InvoiceParams = {
    searchTerm: customerId ? undefined : searchTerm,
    invoiceStatus,
    sort: { field: searchParams.sortBy, order: searchParams.sortOrder },
    updatedAtRange: searchParams.updatedAtRange,
    dueDateRange: searchParams.dueDateRange,
    customerId: customerId || undefined,
  };

  const { data, isFetching, isLoading, error, pagination } = useInvoices({
    params,
    pageNumber: searchParams.pageNumber,
    onPageChange: (pageNumber: number) => setSearchParams({ pageNumber }),
  });

  return {
    invoices: useMemo(() => data ?? [], [data]),
    pagination,
    isFetching: isFetching || isLoading,
    invoiceStatus,
    updatedAt: searchParams.updatedAt,
    dueDate: searchParams.dueDate,
    dueDateRange: searchParams.dueDateRange,
    updatedAtRange: searchParams.updatedAtRange,
    search: searchParams.searchTerm || '',
    error,
    sortParams: params.sort,
    setParams: (params: UseSortableInvoicesParams) => {
      const { searchTerm, ...rest } = params;
      if (Object.keys(rest).length > 0) setSearchParams(toQueryParams(rest));
      if (!isNil(searchTerm) && searchTerm != searchParams.searchTerm) {
        setSearchParamsDebounced({ searchTerm, customerId: '' });
      }
    },
    clearFilterParams: () => {
      const { invoiceStatus, updatedAtRange, dueDateRange, dueDate, updatedAt, ...rest } = searchParams;
      setSearchParams({
        invoiceStatus: undefined,
        updatedAtRange: undefined,
        dueDateRange: undefined,
        dueDate: undefined,
        updatedAt: undefined,
        ...rest,
      });
    },
  };
};

const toQueryParams = ({ sort, order, ...rest }: UseSortableInvoicesParams) => ({
  ...rest,
  ...(sort && { sortBy: sort }),
  ...(order && { sortOrder: order }),
});
