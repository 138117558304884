import { parseColor } from 'react-aria-components';

export const isHexColorValid = (hexColorNumber: string) => {
  try {
    parseColor(hexColorNumber);
    return true;
  } catch {
    return false;
  }
};
