import { Theme } from '@melio/penny';

export const containerStyle = (theme: Theme) => ({
  height: '32px',
  backgroundColor: theme.colors.global.neutral[400],
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '8px',
  padding: 'xs-s',
  flexShrink: 0,
});
