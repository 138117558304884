import { PaymentCreateMethodEnum, usePayment } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

export const usePaymentDrawerData = (paymentId?: string) =>
  usePayment({
    id: paymentId,
    refetchOnMount: 'always',
    params: {
      expand: [
        'vendor',
        'createdBy',
        'deliveryMethod',
        'fundingSource',
        'timeline',
        'fees',
        'subscriptionOccurrence.billSubscription',
        'paymentActions',
        'loan',
        'paymentPending',
        'bills',
        'paymentFailure',
        'bills.file',
        'bills.files',
      ],
    },
  });

export const useShowBillDetailsInPaymentDrawer = (createMethod: PaymentCreateMethodEnum | string | undefined) => {
  const {
    settings: {
      payment: { showBillForJustPayPayments },
    },
  } = useConfig();

  if (showBillForJustPayPayments || !createMethod) {
    return { showBillDetailsInPaymentDrawer: true };
  }

  switch (createMethod) {
    case PaymentCreateMethodEnum.JustPay:
    case PaymentCreateMethodEnum.JustPayBatch:
    case PaymentCreateMethodEnum.JustPaySync:
    case PaymentCreateMethodEnum.SyncRecurring:
      return { showBillDetailsInPaymentDrawer: false };
    case PaymentCreateMethodEnum.Single:
    case PaymentCreateMethodEnum.SingleSync:
    case PaymentCreateMethodEnum.Batch:
    case PaymentCreateMethodEnum.Recurring:
    case PaymentCreateMethodEnum.AutoPayment:
    case PaymentCreateMethodEnum.PaymentFee:
    case PaymentCreateMethodEnum.SubscriptionFee:
      return { showBillDetailsInPaymentDrawer: true };
  }

  return { showBillDetailsInPaymentDrawer: true };
};
