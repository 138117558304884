import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MonitoredAction } from '@melio/ap-activities';
import { LocalStorageKeys } from '@melio/local-storage';
import { Container, Group, Loader, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useVendorsImport1099Contractors } from '@melio/platform-api';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';

import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';

const commonAnalyticEvents = {
  PageName: 'sync-with-tax1099',
  Intent: 'sync-1099',
  Flow: '1099',
};

export const Import1099ContractorsScreen = withAnalyticsContext<{ authorizationCode: string | null }>(
  ({ authorizationCode, setAnalyticsProperties }) => {
    const navigate = useNavigate();
    const { formatMessage } = usePlatformIntl();
    useAnalyticsView('Vendor', true, true, {
      ...commonAnalyticEvents,
      PageName: 'syncing-your-data-to-tax-1099',
    });

    const {
      services: { zenwork },
    } = useConfig();
    const { track } = useAnalytics();
    const { startAction, endAction } = useMonitoring<MonitoredAction>();
    const { showMessage } = useSystemMessage();
    const { goToSettingsTaxAndReports } = useRouter();
    const localStorage = usePartnerLocalStorage();
    const codeVerifier = localStorage.getItem(LocalStorageKeys.zenworkCodeVerifier);

    const { importContractors } = useVendorsImport1099Contractors();

    useEffect(() => {
      if (authorizationCode) {
        startAction('1099_import_contractors');
        const makeRequest = async () => {
          try {
            await importContractors({ authorizationCode, codeVerifier });
            goToSettingsTaxAndReports();
            endAction('1099_import_contractors');
            showMessage({
              type: 'success',
              title: formatMessage('widgets.taxAndReportsSettings.taxFormSection.eFilling.sync.success'),
              dataTestId: '1099-contractors-sync-success',
            });
            track('Vendor', 'Status', {
              ...commonAnalyticEvents,
              Status: 'success',
            });
            if (zenwork?.formsDashboardUrl) {
              window.open(zenwork?.formsDashboardUrl, '_blank');
            }
          } catch (error) {
            track('Vendor', 'Status', {
              ...commonAnalyticEvents,
              Status: 'failure',
              ErrorType: 'sync-tax1099',
            });
            if ((error as { code?: string })?.code === '404') {
              goToSettingsTaxAndReports('noVendorsToReport=true');
            } else {
              navigate('error');
            }
          }
        };

        makeRequest();
      } else {
        navigate('error');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authorizationCode, importContractors, codeVerifier]);

    setAnalyticsProperties({ Flow: 'import-1099-contractors' });

    return (
      <Container height="full" justifyContent="center">
        <Group variant="vertical" justifyContent="center" alignItems="center">
          <Loader />
          <Group spacing="xxs" variant="vertical" alignItems="center" textAlign="center" aria-live="assertive">
            <Text textStyle="body3Semi">
              {formatMessage('activities.Import1099Contractors.screens.importContractors.title')}
            </Text>
            <Text textStyle="body3" color="global.neutral.700">
              {formatMessage('activities.Import1099Contractors.screens.importContractors.description')}
            </Text>
          </Group>
        </Group>
      </Container>
    );
  },
);

Import1099ContractorsScreen.displayName = 'Import1099ContractorsScreen';
