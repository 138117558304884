import { PayorPaymentFee, sumPaymentFees } from '@melio/ap-domain';
import { Container, Group, Text } from '@melio/penny';
import { useMelioIntl, usePartnerFeature } from '@melio/platform-provider';

import { FeeItem } from './FeeItem';

export const PaymentFees = ({ fees, paymentAmount }: { fees: PayorPaymentFee[]; paymentAmount: number }) => {
  const { formatMessage } = useMelioIntl();
  const [shouldShowTotalPaymentsInculdingFees] = usePartnerFeature('ShowTotalPaymentIncludingFees', false);

  const totalFeesAmount = sumPaymentFees(fees);
  const formatFeeValue = (fee: PayorPaymentFee) => {
    const { type } = fee;

    return formatMessage(`widgets.paymentDrawer.body.paymentFees.feeTypes.${type}`);
  };

  return (
    <Container data-component="PaymentFees">
      <Group variant="vertical" spacing="s">
        <Text textStyle="heading3Semi">{formatMessage('widgets.paymentDrawer.body.paymentFees.title')}</Text>
        <Group variant="vertical" spacing="xxs">
          {fees.map((fee, index) => (
            <FeeItem key={index} label={formatFeeValue(fee)} amount={fee.amount} />
          ))}
        </Group>
        <Group variant="vertical" spacing="xs">
          <Group variant="vertical" spacing="xxs">
            <FeeItem
              isBold
              data-testid="payment-fees-total"
              label={formatMessage('widgets.paymentDrawer.body.paymentFees.totalFees')}
              amount={totalFeesAmount}
            />
            <Text textStyle="body4" color="global.neutral.800">
              {formatMessage('widgets.paymentDrawer.body.paymentFees.description')}
            </Text>
          </Group>
          {shouldShowTotalPaymentsInculdingFees ? (
            <FeeItem
              data-testid="total-payment-including-fees"
              label={formatMessage('widgets.paymentDrawer.body.paymentFees.totalPaymentIncludingFees')}
              amount={totalFeesAmount + paymentAmount}
            />
          ) : null}
        </Group>
      </Group>
    </Container>
  );
};
