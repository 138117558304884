import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LocalStorageKeys } from '@melio/local-storage';

import { removePartnerTokens } from '@/api/utilities';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';
import { useOrganizationFromQuery } from '@/hooks/useDeepLinks';

export const UnauthorizedScreen = () => {
  const { goToAuth, goToSessionExpired } = useRouter();
  const localStorage = usePartnerLocalStorage();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl') || '';
  const organization = useOrganizationFromQuery();

  useEffect(() => {
    const hadAccessToken = !!localStorage.getItem(LocalStorageKeys.accessToken);
    if (hadAccessToken) {
      goToSessionExpired({ redirectUrl, organization });
    } else {
      goToAuth({ redirectUrl, organization });
    }
    return removePartnerTokens();
  }, [goToAuth, goToSessionExpired, localStorage, organization, redirectUrl]);

  return null;
};
