import { Traits, useAnalytics } from '@melio/platform-analytics';
import { useDeliveryMethods, useUnilateralRequests, VirtualAccountDeliveryMethod } from '@melio/platform-api';
import { useEffect, useState } from 'react';

export const useUnilateralRequestDetails = ({ vendorId }: { vendorId: string }) => {
  const [enableSendRequest, setEnableSendRequest] = useState(false);

  const vendorDeliveryMethodsCollection = useDeliveryMethods({ vendorId });
  const unilateralRequestsCollection = useUnilateralRequests({ vendorId });

  const { setTraits } = useAnalytics();

  useEffect(() => {
    void unilateralRequestsCollection
      .getValidationForResend()
      .then(({ isValidForResend }) => setEnableSendRequest(isValidForResend));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unilateralRequestsCollection.data]);

  const sendUnilateralRequest = (originalDeliveryMethodId: string) =>
    unilateralRequestsCollection.create({ originalDeliveryMethodId }).then(() => setEnableSendRequest(false));

  const createVirtualAccountAndSendUnilateralRequest = async ({ email }: VirtualAccountDeliveryMethod['details']) => {
    const dm = await vendorDeliveryMethodsCollection.create({ type: 'virtual-account', details: { email } });
    const traits: Traits = { added_delivery_method: true };
    setTraits(traits);
    await sendUnilateralRequest(dm.id);
    setEnableSendRequest(false);
  };

  const vendorVirtualDeliveryMethod = vendorDeliveryMethodsCollection.data?.find(
    ({ type }) => type === 'virtual-account'
  );

  return {
    sendUnilateralRequest,
    createVirtualAccountAndSendUnilateralRequest,
    enableSendRequest,
    isSaving: vendorDeliveryMethodsCollection.isMutating || unilateralRequestsCollection.isMutating,
    vendorVirtualDeliveryMethod,
  };
};
