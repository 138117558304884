/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccountingPlatformSyncStatus,
  useAccountingPlatform,
  useAccountingPlatformSync,
  useMelioQueryClient,
} from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { differenceInMilliseconds } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { isAccountingPlatformConnected } from './utils';

export type AccountingPlatformsSyncWithPollingProps = {
  accountingPlatformId: string;
  isQuickBooksDesktopAccountingPlatform?: boolean;
  onSyncDone?: ({
    isLastSyncTriggeredByUser,
    lastSyncDate,
    analyticsProps,
  }: {
    isLastSyncTriggeredByUser: boolean;
    lastSyncDate: Date;
    analyticsProps: Record<string, string | boolean>;
  }) => void;
  onSyncError?: VoidFunction;
  onCommunicateError?: VoidFunction;
  enabled?: boolean;
};

export const useAccountingPlatformPollingSync = ({
  accountingPlatformId,
  isQuickBooksDesktopAccountingPlatform,
  onSyncDone,
  onSyncError,
  onCommunicateError,
  enabled,
}: AccountingPlatformsSyncWithPollingProps) => {
  const [isLastSyncTriggeredByUser, setIsLastSyncTriggeredByUser] = useState<boolean>(false);

  const {
    data,
    sync,
    isError: isSyncError,
    isFetched: isSyncFetched,
  } = useAccountingPlatformSync({
    id: accountingPlatformId,
    refetchInterval: () => (isLastSyncTriggeredByUser ? 2000 : 10000),
    enabled,
  });
  const {
    refetch: refetchQuickBooksDesktopAccountingPlatformData,
    isFetching: isFetchingQuickBooksDesktopAccountingPlatformData,
    data: quickBooksDesktopAccountingPlatformData,
  } = useAccountingPlatform({
    id: accountingPlatformId,
    enabled: isQuickBooksDesktopAccountingPlatform,
  });
  const isQuickBooksDesktopNotConnected =
    isQuickBooksDesktopAccountingPlatform &&
    !isAccountingPlatformConnected(quickBooksDesktopAccountingPlatformData?.connectionStatus);
  const { createDate } = useDateUtils();
  const lastSyncOrMountDateRef = useRef<Date>(createDate());
  const mountDateRef = useRef<Date>(createDate());
  const queryClient = useMelioQueryClient();

  useEffect(() => {
    if (!data?.lastCompletionTime) {
      return;
    }

    const currentSyncDate = createDate(data.lastCompletionTime.toISOString());
    const currentToLastSyncDiff = differenceInMilliseconds(currentSyncDate, lastSyncOrMountDateRef.current);
    // If diff=0, it means that the current sync is the same as the last sync so we should ignore it.
    // If 0<diff, it means that the current sync was done after the last sync, so we should consider it as a new sync.
    // If -100<diff<0, it means that the current sync was done before the component mounted,
    // meaning it's the first sync after the user connected their accounting platform, so we should consider it as a new sync.
    const isNewSync =
      currentToLastSyncDiff !== 0 &&
      currentToLastSyncDiff > -100 &&
      data.syncStatus !== AccountingPlatformSyncStatus.Running;
    if (isNewSync) {
      void queryClient.invalidateQueries('AccountingPlatformsApi');
      lastSyncOrMountDateRef.current = currentSyncDate;

      if (data.syncStatus === AccountingPlatformSyncStatus.Done) {
        void queryClient.invalidateQueries('InboxItemsApi');
        void queryClient.invalidateQueries('VendorsApi');
        void queryClient.invalidateQueries('PaymentsApi');
        setIsLastSyncTriggeredByUser(false);

        const analyticsProps = {
          mountDate: mountDateRef.current.toISOString(),
          lastSyncDate: data.lastCompletionTime.toISOString(),
          lastSyncOrMountDate: lastSyncOrMountDateRef.current.toISOString(),
          isLastSyncTriggeredByUser,
        };

        onSyncDone?.({
          isLastSyncTriggeredByUser,
          lastSyncDate: data.lastCompletionTime,
          analyticsProps,
        });
      } else if (data.syncStatus === AccountingPlatformSyncStatus.Error) {
        setIsLastSyncTriggeredByUser(false);
        isQuickBooksDesktopAccountingPlatform ? onCommunicateError?.() : onSyncError?.();
      }
    }
  }, [data]);

  useEffect(() => {
    if (isSyncError) {
      setIsLastSyncTriggeredByUser(false);
    }
  }, [isSyncError]);

  const triggerSync = useCallback(async () => {
    setIsLastSyncTriggeredByUser(true);

    if (isQuickBooksDesktopAccountingPlatform) {
      await refetchQuickBooksDesktopAccountingPlatformData();

      if (isQuickBooksDesktopNotConnected) {
        setIsLastSyncTriggeredByUser(false);
        onCommunicateError?.();
        return;
      }
    }

    await sync();
  }, [sync, isQuickBooksDesktopNotConnected, isQuickBooksDesktopAccountingPlatform]);

  const isError = useMemo(
    () =>
      !isLastSyncTriggeredByUser &&
      (isSyncError || data?.syncStatus === AccountingPlatformSyncStatus.Error || isQuickBooksDesktopNotConnected),
    [isLastSyncTriggeredByUser, isSyncError, data, isQuickBooksDesktopNotConnected]
  );

  return {
    triggerSync,
    isRunning:
      isLastSyncTriggeredByUser ||
      data?.syncStatus === AccountingPlatformSyncStatus.Running ||
      isFetchingQuickBooksDesktopAccountingPlatformData,
    isError,
    isSyncFetched,
  };
};
