/* eslint-disable react-hooks/exhaustive-deps */
import { Form, useMelioForm, VisuallyHidden } from '@melio/penny';
import { useTrackAnalyticsOnFailedFormSubmit } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { FileType, useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';
import { useEffect, useState } from 'react';

import { PaymentPurposeSchema, useGetPaymentPurposeOptions } from './paymentPurposeForm.utils';
import { InternationalPaymentPurposeFormFields, PaymentPurposeFormProps } from './types';

export const PaymentPurposeForm = forwardRef<PaymentPurposeFormProps, 'form'>(
  (
    {
      onSubmit,
      defaultValues: _defaultValues,
      isSaving,
      onSubmissionStateChange,
      shouldShowInvoice,
      shouldShowDescription = true,
      shouldShowPaymentPurpose = true,
      currency,
      identifierType,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { fileSizeLimit, fileAllowedFormats } = useConfig().settings;

    const [selectedPaymentPurpose, setSelectedPaymentPurpose] = useState<string | undefined>();

    const defaultValues = defaults(_defaultValues, {
      paymentPurpose: '',
      purposeDescription: '',
      invoice: null,
    });

    const { formProps, formState, registerField, setError, clearErrors, watch } =
      useMelioForm<InternationalPaymentPurposeFormFields>({
        onSubmit,
        schema: PaymentPurposeSchema(shouldShowInvoice, shouldShowPaymentPurpose, selectedPaymentPurpose),
        defaultValues,
        isSaving,
        onSubmissionStateChange,
        subscribeToDefaultValuesChanges: true,
      });

    useEffect(() => {
      const file = watch('invoice');
      let errorMessage = '';
      if (file) {
        const fileType = file.type?.split('/')[1] as FileType;
        if (fileType && !fileAllowedFormats.includes(fileType)) {
          errorMessage = formatMessage('widgets.billDetails.file.validation.fileFormat');
        } else if (file.size > fileSizeLimit) {
          errorMessage = formatMessage('widgets.billDetails.file.validation.fileSize');
        }
      }

      if (errorMessage) {
        setError('invoice', { message: errorMessage });
      } else {
        clearErrors('invoice');
      }
    }, [watch('invoice')]);

    useEffect(() => {
      setSelectedPaymentPurpose(watch('paymentPurpose'));
    }, [watch('paymentPurpose')]);

    useTrackAnalyticsOnFailedFormSubmit(formState, 'Payment', 'Status');

    const { options: paymentPurposeOptions } = useGetPaymentPurposeOptions(currency, identifierType);
    return (
      <Form data-component="international-payment-purpose.PaymentPurposeForm" ref={ref} {...props} {...formProps}>
        {shouldShowPaymentPurpose && (
          <Form.SelectNew
            options={paymentPurposeOptions}
            {...registerField('paymentPurpose')}
            labelProps={{
              label: formatMessage(
                'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.paymentPurpose.label'
              ),
            }}
            placeholder={formatMessage(
              'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.paymentPurpose.placeholder'
            )}
          />
        )}
        {shouldShowDescription && (
          <Form.TextField
            labelProps={{
              label: formatMessage(
                'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.purposeDescription.label'
              ),
            }}
            placeholder={formatMessage(
              'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.purposeDescription.placeholder'
            )}
            {...registerField('purposeDescription')}
          />
        )}
        {shouldShowInvoice && (
          <Form.FileUpload
            labelProps={{
              label: formatMessage(
                'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.invoice.label'
              ),
              tooltipProps: {
                content: (
                  <>
                    <VisuallyHidden>
                      {formatMessage(
                        'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.invoice.tooltip'
                      )}{' '}
                      tooltip
                    </VisuallyHidden>
                    {formatMessage(
                      'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.invoice.tooltip'
                    )}
                  </>
                ),
              },
            }}
            placeholder={formatMessage(
              'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.paymentPurposeForm.invoice.placeholder'
            )}
            acceptTypes={fileAllowedFormats}
            {...registerField('invoice')}
          />
        )}
      </Form>
    );
  }
);

PaymentPurposeForm.displayName = 'international-payment-purpose.PaymentPurposeForm';
