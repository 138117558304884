import { PartnerIcons } from '../../../partnersConfig.types';

export const CapOneIcons: PartnerIcons = {
  Favicon: 'https://platform-static.meliopayments.com/assets/capital-one/favicon.svg',
  MelioIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/melio-small.icon.svg',
    extraSmall: 'https://platform-static.meliopayments.com/assets/system/melio-extra-small.icon.svg',
  },
  LogoIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/capOne.icon.svg',
  },
  ErrorIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/capOneError.icon.svg',
  },
  EmptyBillsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/emptyBills.icon.svg',
  },
  EmptyVendorsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/emptyVendors.icon.svg',
  },
  NoBillsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/noBillFound.icon.svg',
  },
  NoVendorsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/noVendorFound.icon.svg',
  },
  VerifyEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/verifyEmail.icon.svg',
  },
  TaxSummaryIcon: {
    small: 'https://platform-static.meliopayments.com/assets/default/taxSummary.icon.svg',
  },
  ExpiredSessionIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/expiredSession.icon.svg',
  },
  BackToPartnerIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/back-small.icon.svg',
    extraSmall: 'https://platform-static.meliopayments.com/assets/capital-one/back-extra-small.icon.svg',
  },
  StartNewExperienceIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/StartNewExperienceIcon.icon.svg',
  },
  TryDifferentEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/capital-one/TryDifferentEmail.icon.svg',
  },
  AddBillIcon: {
    small: 'https://platform-static.meliopayments.com/assets/default/addBill.icon.svg',
  },
};
