import { Container, Group } from '@melio/penny';
import { FileInfo } from '@melio/platform-api';
import { XOR } from '@melio/platform-utils';
import { isEmpty, isNil, some } from 'lodash';
import { ReactNode } from 'react';

import { useIsMobile } from '../../../../utils/viewport-utils/useIsMobile';
import { PreviewInvoice } from './PreviewInvoice';

type Props = {
  children: ReactNode;
  isIncludeMultiInvoices?: boolean;
  onNextFileClick?: () => void;
  onPreviousFileClick?: () => void;
} & XOR<{ filesIds?: (string | null | undefined)[] }, { files?: FileInfo[] }>;
export const DrawerPreviewInvoice = ({
  children,
  isIncludeMultiInvoices = false,
  filesIds,
  files,
  onNextFileClick,
  onPreviousFileClick,
}: Props) => {
  const isMobile = useIsMobile();
  const hasFiles = !isEmpty(files);
  const hasFilesIds = !isEmpty(filesIds) && !some(filesIds, isNil);
  const shouldShowPreviewInvoice = hasFiles || hasFilesIds || isIncludeMultiInvoices;

  const renderPreviewInvoice = () =>
    files ? (
      <PreviewInvoice files={files} onNextFileClick={onNextFileClick} onPreviousFileClick={onPreviousFileClick} />
    ) : (
      <PreviewInvoice filesIds={filesIds} onNextFileClick={onNextFileClick} onPreviousFileClick={onPreviousFileClick} />
    );

  return isMobile ? (
    <Group variant="vertical" hasDivider spacing="none" data-testid="mobile-preview-invoice">
      <Container paddingBottom="l" paddingTop="s" paddingX="s">
        {children}
      </Container>
      {shouldShowPreviewInvoice ? (
        <Container paddingX="s" paddingTop="l" backgroundColor="light">
          {renderPreviewInvoice()}
        </Container>
      ) : null}
    </Group>
  ) : shouldShowPreviewInvoice ? (
    <Group variant="horizontal" hasDivider spacing="none" height="full">
      <Container
        overflow="auto"
        backgroundColor="light"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        marginLeft="-32px"
        marginTop="-32px"
        marginBottom="-32px"
        paddingX="l"
        paddingY="l"
      >
        {renderPreviewInvoice()}
      </Container>
      <Container
        maxWidth="480px"
        overflow="auto"
        data-testid="drawer-details"
        paddingX="l"
        paddingY="l"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        marginRight="-32px"
        marginTop="-32px"
        marginBottom="-32px"
      >
        {children}
      </Container>
    </Group>
  ) : (
    <Container maxWidth="480px" data-testid="drawer-details">
      {children}
    </Container>
  );
};
