import { Card, Group, Icon, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  onClick: VoidFunction;
};

export const EmptyState = ({ onClick }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Card
      data-testid="payment-flow-form-delivery-method-card-empty"
      variant="flat"
      paddingX="s"
      paddingY="s"
      onClick={onClick}
    >
      <Group width="full" variant="horizontal" alignItems="center" justifyContent="space-between">
        <Group variant="horizontal" alignItems="center">
          <Icon type="vendor" />
          <Group width="full" variant="vertical" spacing="xxxs">
            <Text textStyle="body2Semi" data-testid="payment-flow-form-delivery-method-card-title">
              {formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.empty.title')}
            </Text>
            <Text
              data-testid="payment-flow-form-delivery-method-card-description"
              textStyle="body3"
              color="global.neutral.800"
            >
              {formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.empty.description')}
            </Text>
          </Group>
        </Group>
        <Icon type="chevron-right" />
      </Group>
    </Card>
  );
};
