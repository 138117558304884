import { CreateVendorErrorCode, ModelError } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { groupBy, has, includes, uniq } from 'lodash';

import {
  ErrorTypesDictionary,
  ErrorTypeToErrorCodesMap,
  VendorFormBannerApiErrorCode,
  VendorFormFields,
  VendorFormInlineApiErrorCode,
} from './types';

export const inlineApiErrorsCodesToFieldsMap: Record<VendorFormInlineApiErrorCode, (keyof VendorFormFields)[]> = {
  payee_address_invalid_1207: ['line1', 'line2', 'city', 'state', 'postalCode'],
  invalid_zip_code_1208: ['postalCode'],
  city_name_invalid_1212: ['city'],
  state_code_invalid_1213: ['state'],
  unit_number_missing_1221: ['line2'],
  house_or_street_number_missing_1222: ['line1'],
  directional_missing_1224: ['line1'],
  suffix_missing_1226: ['line1'],
  zipcode_address_mismatch_1229: ['postalCode'],
  account_scheming_failed_1218_zip_code_not_required: ['accountNumber'],
  invalid_validation_error_payee_account_number_zip_code_not_required: ['accountNumber'],
  invalid_validation_error_zipcode_for_merchant_uri: ['postalCode'],
  invalid_validation_error_zip_code: ['postalCode'],
  invalid_validation_error_city_name: ['city'],
  invalid_validation_error_address1: ['line1'],
  zip_code_address_mismatch: ['postalCode'],
  invalid_payee_account_number_1205: ['accountNumber'],
  CompanyNameNotUnique: ['companyName'],
  UniqueNameNotUnique: ['uniqueName'],
};

export const getInlineApiErrorsFields = (inlineErrorCodes?: VendorFormInlineApiErrorCode[]) =>
  uniq(inlineErrorCodes?.flatMap((errorCode) => inlineApiErrorsCodesToFieldsMap[errorCode])) || [];

export const useInlineApiError = <T extends VendorFormFields>(inlineErrorCodes?: VendorFormInlineApiErrorCode[]) => {
  const { formatMessage } = useMelioIntl();

  const fieldToApiErrorMessageMap = (inlineErrorCodes || []).reduce((acc, code) => {
    const fields = inlineApiErrorsCodesToFieldsMap[code];
    fields.forEach((field) => {
      acc[field] = formatMessage(`widgets.vendors.apiErrors.${code}`);
    });
    return acc;
  }, {} as Record<keyof T, string>);

  return { fieldToApiErrorMessageMap };
};

const zipCodeDependentErrorsCode: CreateVendorErrorCode[] = [
  'account_scheming_failed_1218',
  'invalid_validation_error_payee_account_number',
];

const bannerApiErrorsCodes: VendorFormBannerApiErrorCode[] = [
  'invalid_payee_status_1230',
  'invalid_vru_name_code_1231',
  'payee_inactive_1214',
  'active_payee_exists_1215',
  'merchant_number_invalid_1216',
  'subscriber_invalid_status',
  'active_payee_already_exists',
  'invalid_payee_status',
  'account_scheming_failed_1218_zip_code_required',
  'invalid_validation_error_payee_account_number_zip_code_required',
  'non_modifiable_fields_1253',
];

export const useBannerApiError = (bannerErrorCodes?: VendorFormBannerApiErrorCode[]) => {
  const { formatMessage } = useMelioIntl();

  if (bannerErrorCodes?.[0]) {
    return {
      error: {
        description: formatMessage(`widgets.vendors.apiErrors.${bannerErrorCodes[0]}`),
      },
    };
  }

  return { error: undefined };
};

const isInlineErrorCode = (errorCode: string): errorCode is VendorFormInlineApiErrorCode =>
  has(inlineApiErrorsCodesToFieldsMap, errorCode);

const isBlockErrorCode = (errorCode: string): errorCode is VendorFormBannerApiErrorCode =>
  includes(bannerApiErrorsCodes, errorCode);

const enum ErrorType {
  Inline = 'inline',
  Banner = 'banner',
  Unknown = 'unknown',
}

export const VENDOR_INVALID_ZIP_CODE_ERROR_CODE = 'invalid_validation_error_zipcode_for_merchant_uri';
export const VENDOR_INVALID_ACCOUNT_NUMBER_ERROR_CODE = 'invalid_validation_error_payee_account_number';

const getErrorType = (errorCode: string) => {
  if (isInlineErrorCode(errorCode)) {
    return ErrorType.Inline;
  }
  if (isBlockErrorCode(errorCode)) {
    return ErrorType.Banner;
  }
  return ErrorType.Unknown;
};

const mapServerErrorCodeToClientErrorCode = (errorCode: CreateVendorErrorCode, isZipCodeNeeded: boolean) => {
  if (zipCodeDependentErrorsCode.includes(errorCode)) {
    return isZipCodeNeeded ? `${errorCode}_zip_code_required` : `${errorCode}_zip_code_not_required`;
  }

  return errorCode;
};

export const getErrorsByType = (error: ModelError, isZipCodeNeeded: boolean): ErrorTypeToErrorCodesMap => {
  if (!error.data && !error.errorCode) {
    return {};
  }

  const errorCodes = (error.data || [{ errorCode: error.errorCode }]).map(({ errorCode }) =>
    mapServerErrorCodeToClientErrorCode(errorCode as CreateVendorErrorCode, isZipCodeNeeded)
  );

  return groupBy(errorCodes, getErrorType);
};

export const getErrorTypesByApiError = (error: ModelError): ErrorTypesDictionary =>
  (error.data?.reduce(
    (acc, { errorCode }) => ({
      ...acc,
      [errorCode]: true,
    }),
    {}
  ) as ErrorTypesDictionary) ?? {};
