import { IndustryTypeSelectWidget } from '@melio/form-controls';
import { Button, Form, Group, Link, SectionBanner, SelectOption, Text, useMelioForm } from '@melio/penny';
import { Address, Industry, TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults, isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';

import { VendorBusinessDetailsFormFields } from '../../types';
import { AddressSearchWidget, AddressSearchWidgetProps } from '../AddressSearch';
import { allBusinessTypes, taxIdMasks } from './consts';
import { useTaxTypeOptions } from './hooks/useTaxTypeOptions';
import { useVendorBusinessDetailsSchema } from './hooks/useVendorBusinessDetailsSchema';
import { VendorBusinessAdditionalProps, VendorBusinessDetailsFormBaseProps } from './types';
import { isEinOnlyBusinessType } from './utils';

export const VendorBusinessDetailsFormWidget = forwardRef<
  VendorBusinessDetailsFormBaseProps & VendorBusinessAdditionalProps,
  'form'
>(
  (
    {
      onSubmit,
      defaultValues: _defaultValues,
      isSaving,
      isDisabled,
      isLoadingOrg,
      isFilled,
      organizations,
      contactUrl,
      isMultiOrg = true,
      setDirtyStatus,
      onValidationError,
      onOrgSelection,
      ...props
    },
    ref
  ) => {
    const defaultValues = defaults(_defaultValues, {
      legalBusinessName: '',
      businessName: '',
      legalBusinessAddress: null,
      businessAddress: null,
      businessType: null,
      taxIdType: null,
      taxIdNumber: '',
      industry: undefined,
    });

    const [applyMask, setApplyMask] = useState<boolean>(false);
    const [isBusinessDetailsError, setIsBusinessDetailsError] = useState(false);
    const [isTaxIdNumberFocused, setIsTaxIdNumberFocused] = useState<boolean>(false);

    const onSubmitBusinessDetails = async (formValues: VendorBusinessDetailsFormFields) => {
      try {
        setIsBusinessDetailsError(false);
        await onSubmit(formValues);
      } catch (error) {
        setIsBusinessDetailsError(true);
      } finally {
        setApplyMask(false);
      }
    };

    const {
      formProps,
      registerField,
      submitButtonProps: { isDisabled: isSubmitDisabled, ...submitButtonProps },
      trigger,
      setValue,
      watch,
      formState: { isDirty, isValid, errors },
    } = useMelioForm<VendorBusinessDetailsFormFields>({
      onSubmit: onSubmitBusinessDetails,
      schema: useVendorBusinessDetailsSchema({ isTaxIdNumberFocused }),
      defaultValues,
      isSaving,
      subscribeToDefaultValuesChanges: true,
    });

    const { formatMessage } = useMelioIntl();
    const businessTypeOptions: SelectOption[] = allBusinessTypes.map((value) => ({
      label: value ? formatMessage(`app.mtl.labels.businessType.option.${value}`) : '',
      value,
      testId: value,
    }));
    const selectedTaxIdType = watch('taxIdType');
    const selectedBusinessType = watch('businessType');
    const taxIdTypeOptions = useTaxTypeOptions(selectedBusinessType);
    const handleAddressChange: (
      fieldName: keyof VendorBusinessDetailsFormFields
    ) => AddressSearchWidgetProps['onChange'] = (fieldName) => (event: ChangeEvent<HTMLInputElement>) => {
      const address = event.target.value as unknown as Address;
      setValue(fieldName, address, {
        shouldValidate: true,
      });
    };
    const handleIndustryCreatable = (option: SelectOption) => {
      setValue('industry', option.value as unknown as Industry, { shouldValidate: true });
    };
    const handleTaxIdNumberFocus = () => {
      if (!isTaxIdNumberFocused) {
        setValue('taxIdNumber', '');
      }

      setIsTaxIdNumberFocused(true);
      setApplyMask(true);
    };
    const onCompanyChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const selectedOrgId = organizations?.find((org) => org.name === value)?.id;
      if (selectedOrgId) {
        setIsBusinessDetailsError(false);
        setValue('businessName', value);
        setValue('businessAddress', null, { shouldValidate: false });
        setValue('businessType', null, { shouldValidate: false });
        setValue('legalBusinessAddress', null, { shouldValidate: false });
        setValue('industry', null as unknown as Industry, { shouldValidate: false });

        onOrgSelection(selectedOrgId).catch(() => {
          setIsBusinessDetailsError(true);
        });
      }
    };

    useEffect(() => {
      if (
        isEinOnlyBusinessType(selectedBusinessType) &&
        (!defaultValues.taxIdNumber || selectedBusinessType !== defaultValues.businessType)
      ) {
        setValue('taxIdType', TaxIdTypeEnum.Ein);
        setValue('taxIdNumber', '');
      }
    }, [selectedBusinessType, setValue, defaultValues.businessType, defaultValues.taxIdNumber]);

    useEffect(() => {
      if (onValidationError && !isEmpty(errors)) {
        const isRequiredError = !!Object.values(errors).some((error) => error?.type === 'required');
        onValidationError(isRequiredError);
      }
    }, [errors, onValidationError]);

    useEffect(() => {
      if (setDirtyStatus) {
        const { onClick } = submitButtonProps;
        setDirtyStatus(isDirty && !isValid, onClick);
      }
    }, [isDirty, isValid, trigger, setDirtyStatus, submitButtonProps]);

    const businessNameReadOnlyHelperLabel = formatMessage(
      'vex.widgets.vendorOnboarding.businessDetails.name.helperTextChange',
      {
        contactSupportLink: (
          <Text textStyle="body4" color="global.brand.700">
            <Link
              color="inherit"
              href={contactUrl}
              label={formatMessage('vex.widgets.vendorOnboarding.businessDetails.name.helperTextContactSupportLink')}
              newTab
            />
          </Text>
        ),
      }
    );

    const businessNameInputSharedProps = {
      ...registerField('businessName'),
      labelProps: { label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.name.label') },
      helperTextProps: isFilled
        ? { label: businessNameReadOnlyHelperLabel }
        : { label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.name.helperText') },
      isReadOnly: isFilled,
      isLoading: isLoadingOrg,
    };

    return (
      <Group spacing="s-m" variant="vertical" width="full">
        {isBusinessDetailsError && (
          <SectionBanner
            data-testid="business-details-form-error-banner"
            variant="critical"
            description={formatMessage('vex.widgets.vendorOnboarding.businessDetails.errorBanner.description')}
          />
        )}
        <Form
          size="small"
          {...formProps}
          columns={2}
          ref={ref}
          data-testid="VendorBusinessDetailsFormWidget"
          data-component="VendorBusinessDetailsFormWidget"
          {...props}
        >
          {isMultiOrg ? (
            <Form.ContentBox colSpan={2}>
              <Form.Select
                {...businessNameInputSharedProps}
                onChange={onCompanyChange}
                options={organizations?.map((org) => ({ label: org.name, value: org.name })) ?? []}
                emptyState={{
                  label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.name.emptyState'),
                }}
              />
            </Form.ContentBox>
          ) : (
            <Form.TextField {...businessNameInputSharedProps} colSpan={2} type="text" />
          )}
          <AddressSearchWidget
            isLoadingField={isLoadingOrg}
            colSpan={2}
            labelProps={{ label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.address.label') }}
            {...registerField('businessAddress')}
            onChange={handleAddressChange('businessAddress')}
            emptyState={{
              label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.address.emptyState'),
            }}
          />
          <IndustryTypeSelectWidget
            colSpan={2}
            isLoadingField={isLoadingOrg}
            {...registerField('industry')}
            labelProps={{ label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.industry.label') }}
            onCreateOption={handleIndustryCreatable}
          />
          <Form.TextField
            isLoading={isLoadingOrg}
            labelProps={{ label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.legalName.label') }}
            {...registerField('legalBusinessName')}
            colSpan={2}
          />
          <AddressSearchWidget
            isLoadingField={isLoadingOrg}
            colSpan={2}
            {...registerField('legalBusinessAddress')}
            labelProps={{
              label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.legalAddress.label'),
            }}
            onChange={handleAddressChange('legalBusinessAddress')}
            emptyState={{
              label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.legalAddress.emptyState'),
            }}
          />
          <Form.Select
            isLoading={isLoadingOrg}
            colSpan={2}
            {...registerField('businessType')}
            labelProps={{ label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.businessType.label') }}
            options={businessTypeOptions}
            emptyState={{
              label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.businessType.emptyState'),
            }}
          />
          <Form.RadioGroup
            colSpan={2}
            {...registerField('taxIdType')}
            labelProps={{ label: formatMessage('vex.widgets.vendorOnboarding.businessDetails.taxIdType.label') }}
            options={taxIdTypeOptions}
          />
          <Form.TextField
            data-private
            isLoading={isLoadingOrg}
            onFocus={handleTaxIdNumberFocus}
            colSpan={2}
            {...registerField('taxIdNumber')}
            type="text"
            labelProps={{
              label: formatMessage(
                selectedTaxIdType ? `app.mtl.labels.company.taxId.${selectedTaxIdType}` : 'app.mtl.labels.company.taxId'
              ),
            }}
            placeholder={
              selectedTaxIdType
                ? formatMessage(`app.mtl.placeholders.taxId.${selectedTaxIdType}`)
                : formatMessage(`app.mtl.placeholders.taxId`)
            }
            maskProps={{
              mask: applyMask ? taxIdMasks[selectedTaxIdType || TaxIdTypeEnum.Ein] ?? taxIdMasks.any : taxIdMasks.any,
            }}
          />
        </Form>
        <Group justifyContent="flex-end">
          <Button
            size="medium"
            variant="primary"
            isLoading={isSaving}
            isDisabled={isSubmitDisabled || isLoadingOrg}
            {...submitButtonProps}
            label={formatMessage('vex.widgets.vendorOnboarding.businessDetails.submit')}
            data-testid="business-details-submit-button"
          />
        </Group>
      </Group>
    );
  }
);

VendorBusinessDetailsFormWidget.displayName = 'VendorBusinessDetailsFormWidget';
