import { Route, Routes, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { ShiftVirtualCardToACHActivity, SuvcAcceptanceActivity, useSessionConfig } from '@melio/vex-activities';
import { BaseErrorScreen } from '@melio/vex-activities/src/components/base-screens';

const trackBaseError = { name: 'suvc-acceptance-error' };

const getAccessTokenClaims = (accessToken: string) =>
  jwtDecode<{ claims: { shouldUseTokenizedSUVCDetails: boolean; virtualCardId: string } }>(accessToken)?.claims || {};

export const SuvcAcceptanceRoute = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const { accessToken } = useSessionConfig();
  const { paymentId } = useParams<{ paymentId: string }>();
  const { shouldUseTokenizedSUVCDetails, virtualCardId } = accessToken ? getAccessTokenClaims(accessToken) : {};

  setAnalyticsProperties({ paymentId, virtualCardId, pageEntryPoint: 'suvc-delivered-email', flow: 'suvc-acceptance' });

  const GeneralServerErrorScreen = (
    <BaseErrorScreen trackBaseError={trackBaseError} description={formatMessage('vex.generalServerError')} />
  );

  if (!paymentId || !virtualCardId) return GeneralServerErrorScreen;

  return (
    <Routes>
      <Route
        path="/shift-suvc-to-ach/*"
        element={<ShiftVirtualCardToACHActivity paymentId={paymentId} virtualCardId={virtualCardId} />}
      />
      <Route path="error" element={GeneralServerErrorScreen} />
      <Route
        path="*"
        element={
          <SuvcAcceptanceActivity
            isTokenizedCardDetailsEnabledForCurrentCard={Boolean(shouldUseTokenizedSUVCDetails)}
            virtualCardId={virtualCardId}
          />
        }
      />
    </Routes>
  );
});

SuvcAcceptanceRoute.displayName = 'SuvcAcceptance';
