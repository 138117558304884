import {
  DeliveryMethod,
  DeliveryPreferenceType,
  FeesBreakdown,
  FeesBreakdownRequest,
  FundingSource,
  useDeliveryMethod,
  useFeesBreakdown,
  useFundingSource,
  useOrgBillingFeeSettings,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useEffect } from 'react';

import { usePaymentFee, useTotalFees } from './fees';

type PaymentData = {
  amount?: number;
  fundingSourceId?: FundingSource['id'];
  deliveryMethodId?: DeliveryMethod['id'];
  deliveryPreferenceType?: DeliveryPreferenceType;
  lastAmount?: number;
  billId?: string;
};

export const usePaymentFeesDetails = (
  payment: PaymentData,
  options?: {
    onFeesCalculated?: (feesBreakdown: FeesBreakdown | undefined) => void;
  }
) => {
  const { data: fundingSource, isLoading: isLoadingFundingSource } = useFundingSource({
    id: payment.fundingSourceId,
    enabled: !!payment.fundingSourceId,
  });

  const { data: deliveryMethod, isLoading: isLoadingDeliveryMethod } = useDeliveryMethod({
    id: payment.deliveryMethodId,
    enabled: !!payment.deliveryMethodId,
  });

  const { deliveryPreferenceType, lastAmount, amount } = payment;
  const [isBillingFeeSettingsEnabled] = useDevFeature(FeatureFlags.BillingFees, false);

  const { data: orgBillingFeeSettings = [], isLoading: isBillingFeeSettingsLoading } = useOrgBillingFeeSettings({
    enabled: isBillingFeeSettingsEnabled,
  });

  const shouldEnableFeesBreakdown = !!deliveryPreferenceType && !!fundingSource && !!deliveryMethod && !!amount;
  const billIds = payment.billId ? [payment.billId] : [];

  const {
    data: feesBreakdown,
    isLoading: isLoadingFeesBreakdown,
    error: feesBreakdownError,
  } = useFeesBreakdown({
    params: {
      feesBreakdownParams: shouldEnableFeesBreakdown
        ? [
            {
              deliveryMethodType: deliveryMethod.type,
              deliveryMethodId: deliveryMethod.id,
              fundingSourceType: fundingSource.type,
              fundingSourceId: fundingSource.id,
              deliveryPreference: deliveryPreferenceType,
              paymentAmount: payment.amount,
              billIds,
            },
          ]
        : [],
    } as FeesBreakdownRequest,
    enabled: shouldEnableFeesBreakdown,
  });

  useEffect(() => {
    if (feesBreakdown) {
      options?.onFeesCalculated?.(feesBreakdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feesBreakdown]);

  const shouldEnableLastAmountFeesBreakdown = shouldEnableFeesBreakdown && lastAmount !== undefined;

  const {
    data: feesBreakdownLastAmount,
    isLoading: isLoadingLastAmountFeesBreakdown,
    error: lastAmountFeesBreakdownError,
  } = useFeesBreakdown({
    params: {
      feesBreakdownParams: shouldEnableLastAmountFeesBreakdown
        ? [
            {
              deliveryMethodType: deliveryMethod.type,
              deliveryMethodId: deliveryMethod.id,
              fundingSourceType: fundingSource.type,
              fundingSourceId: fundingSource.id,
              deliveryPreference: deliveryPreferenceType,
              paymentAmount: lastAmount,
              billIds,
            },
          ]
        : [],
    },
    enabled: shouldEnableLastAmountFeesBreakdown,
  });

  const feeItems = usePaymentFee(feesBreakdown?.feesBreakdown || []);
  const total = useTotalFees({
    transactionFees: feeItems || [],
    orgBillingFeeSettings,
    recurringLastAmountFees: feesBreakdownLastAmount?.feesBreakdown,
  });
  return {
    error: feesBreakdownError || lastAmountFeesBreakdownError,
    feeItems,
    total,
    isLoading:
      isLoadingFundingSource ||
      isLoadingDeliveryMethod ||
      isBillingFeeSettingsLoading ||
      isLoadingFeesBreakdown ||
      (shouldEnableLastAmountFeesBreakdown && isLoadingLastAmountFeesBreakdown),
  };
};
