import { GetVirtualCardAcceptanceResponse } from '@melio/platform-api';

import { isPartnerMelio } from '../../shared';
import { useTokenizedSUVCDetails } from './hooks';
import { SuvcAcceptanceScreen } from './screens';

type SuvcDetailsActivityType = {
  isTokenizedCardDetailsEnabledForCurrentCard: boolean;
  virtualCardId: string;
  suvcData: GetVirtualCardAcceptanceResponse;
};

export const SuvcDetailsActivity: React.VFC<SuvcDetailsActivityType> = ({
  isTokenizedCardDetailsEnabledForCurrentCard,
  virtualCardId,
  suvcData,
}) => {
  const showPoweredByMelio = !isPartnerMelio();
  const { shouldUseTokenizedSUVCDetails, isInitializingTokenizedSUVC } = useTokenizedSUVCDetails({
    isTokenizedCardDetailsEnabledForCurrentCard,
    virtualCardId,
  });

  return (
    <SuvcAcceptanceScreen
      suvcData={suvcData}
      showPoweredByMelio={showPoweredByMelio}
      isInitializingTokenizedSUVC={isInitializingTokenizedSUVC}
      shouldUseTokenizedSUVCDetails={shouldUseTokenizedSUVCDetails}
    />
  );
};

SuvcDetailsActivity.displayName = 'SuvcDetailsActivity';
