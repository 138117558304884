import { getInternationalDmDetails } from '@melio/ap-domain';
import { useAnalyticsContext, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryMethod, FileInfo, PaymentIntent, useFiles } from '@melio/platform-api';

import { usePaymentPurposeShowFields } from './PaymentPurpose.utils';
import { InternationalPaymentPurposeFormFields, InternationalPaymentPurposeScreen } from './screens';
import { InternationalPaymentPurposeModalScreen } from './screens/InternationalPaymentPurposeModalScreen';

export type PaymentPurposeUpdateData = {
  paymentPurpose: string;
  billInfo?: {
    invoice: {
      fileId: string;
    };
  };
};

type InternationalPaymentPurposeCommonProps = {
  onClose: VoidFunction;
  onDone: (data: PaymentPurposeUpdateData) => void;
  defaultValues?: Partial<InternationalPaymentPurposeFormFields>;
  vendorId: PaymentIntent['billInfo']['vendorId'];
  step?: number;
  totalSteps?: number;
  hasInvoice: boolean;
  deliveryMethod?: DeliveryMethod | null;
};

type InternationalPaymentPurposeActivityProps = InternationalPaymentPurposeCommonProps & {
  onBack: VoidFunction;
  variation: 'screen';
};

type InternationalPaymentPurposeActivityModalProps = InternationalPaymentPurposeCommonProps & {
  isOpen: boolean;
  variation: 'modalScreen';
  isSaving: boolean;
};

type ActivityProps = InternationalPaymentPurposeActivityProps | InternationalPaymentPurposeActivityModalProps;

const isModal = (props: ActivityProps): props is InternationalPaymentPurposeActivityModalProps =>
  props.variation === 'modalScreen';

export const InternationalPaymentPurposeActivity = withAnalyticsContext<ActivityProps>((props: ActivityProps) => {
  const { defaultValues, vendorId, onDone, step, totalSteps, onClose, deliveryMethod, hasInvoice } = props;

  const { identifierType, internationalCountryCode, currency } = getInternationalDmDetails(deliveryMethod);
  const { create: createFile, isMutating } = useFiles({ enabled: false });
  const {
    shouldShowInvoice,
    shouldShowDescription,
    shouldShowPaymentPurpose,
    isLoading: isShowInvoiceLoading,
    selectedCountryDetails,
  } = usePaymentPurposeShowFields({
    internationalCountryCode,
    vendorId,
    hasInvoice,
    identifierType,
    currency,
  });

  const onFormSubmit = async ({
    invoice,
    paymentPurpose,
    purposeDescription,
  }: InternationalPaymentPurposeFormFields) => {
    let uploadedInvoice: FileInfo | undefined;
    if (invoice) {
      uploadedInvoice = await createFile(invoice);
    }

    const data = {
      paymentPurpose: purposeDescription ? `${paymentPurpose} ${purposeDescription}` : paymentPurpose,
      billInfo: uploadedInvoice?.id
        ? {
            invoice: {
              fileId: uploadedInvoice.id,
            },
          }
        : undefined,
    };

    onDone(data);
  };

  useAnalyticsContext({
    globalProperties: {
      PageName: 'whats-the-purpose-of-your-payment',
      Intent: 'add-delivery-method',
      DeliveryCountry: selectedCountryDetails?.name,
      CountryRiskScore: selectedCountryDetails?.risk,
      shouldShowInvoice,
      shouldShowDescription,
      shouldShowPaymentPurpose,
    },
  });

  useAnalyticsView('Payment', true);

  return isModal(props) ? (
    <InternationalPaymentPurposeModalScreen
      isOpen={props.isOpen}
      onClose={onClose}
      onSubmit={onFormSubmit}
      isLoading={isShowInvoiceLoading}
      isSaving={props.isSaving}
      shouldShowInvoice={shouldShowInvoice}
      shouldShowDescription={shouldShowDescription}
      shouldShowPaymentPurpose={shouldShowPaymentPurpose}
      defaultValues={defaultValues}
      identifierType={identifierType}
      currency={currency}
    />
  ) : (
    <InternationalPaymentPurposeScreen
      onBack={props.onBack}
      onClose={onClose}
      step={step}
      totalSteps={totalSteps}
      onDone={onFormSubmit}
      isLoading={isShowInvoiceLoading}
      isSaving={isMutating}
      shouldShowInvoice={shouldShowInvoice}
      defaultValues={defaultValues}
      shouldShowDescription={shouldShowDescription}
      shouldShowPaymentPurpose={shouldShowPaymentPurpose}
      identifierType={identifierType}
      currency={currency}
    />
  );
});
