import { SystemMessage, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Container, Group, Layout, SectionBanner } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { GuestPaymentFooter } from './components';

export type BaseLayoutProps = {
  isLoading?: boolean;
  isHidden?: boolean;
  onBack?: VoidFunction;
  onClose?: VoidFunction;
  isPayable?: boolean;
  backgroundColor: string | undefined;
};

export const BaseLayout = forwardRef<BaseLayoutProps>(
  ({ isLoading, isHidden, onBack, onClose, isPayable = true, backgroundColor, children, ...props }, ref) => {
    const isMobile = useIsMobile();
    const { formatMessage } = useMelioIntl();

    return (
      <Container
        backgroundColor="light"
        width="full"
        height="full"
        data-layout={BaseLayout.displayName}
        data-loading={isLoading}
        hidden={isHidden}
        style={backgroundColor ? { backgroundColor } : undefined}
        {...props}
        ref={ref}
      >
        <Layout
          backgroundColor="default"
          isLoading={isLoading}
          paddingContent={isMobile ? 'none' : 'xxl'}
          maxWidth="600px"
        >
          <Group variant="vertical" alignItems="center" spacing="l">
            {!isPayable && (
              <SectionBanner
                variant="warning"
                description={formatMessage('ar.guestPayment.notifications.invoiceNotPayable.description.text')}
                isCompact={isMobile}
              />
            )}
            <SystemMessage />
            <Container border="regular" backgroundColor="white" paddingX="l" paddingY="l">
              {children}
            </Container>
            <GuestPaymentFooter />
          </Group>
        </Layout>
      </Container>
    );
  }
);

BaseLayout.displayName = 'BaseLayout';
