import { Container, Link, SectionBanner, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useOrganizationPreference } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useDateUtils } from '@melio/platform-utils';

import { Season1099Icon } from './icons/season1099.icon';

export const Season1099SectionBanner = ({
  openSync1099ContractorsModal,
}: {
  openSync1099ContractorsModal: VoidFunction;
}) => {
  const { createDate } = useDateUtils();
  const { createTrackHandler } = useAnalytics();
  useAnalyticsView('Dashboard', true, true, {
    UserMessage: 'file-1099s-easily-and-on-time',
  });
  const trackAndHandleClick = createTrackHandler<{
    Cta: '1099-message-exit' | '1099-message-sync-with-tax1099';
    UserMessage: 'file-1099s-easily-and-on-time';
  }>('Dashboard', 'Click', {
    UserMessage: 'file-1099s-easily-and-on-time',
  });
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();

  const { create } = useOrganizationPreference({
    enabled: false,
  });

  const getNextPreSeasonDate = () => {
    const today = createDate();
    return `${today.getUTCFullYear() + 1}-01-01T00:00:00Z`;
  };

  const onCloseBanner = async () => {
    const date = getNextPreSeasonDate();
    trackAndHandleClick({ Cta: '1099-message-exit' });
    try {
      await create({
        key: 'nextSeason1099StartDate',
        value: date,
      });
    } catch (error) {
      Logger.handleException(error);
    }
  };

  return (
    <Container paddingTop={isExtraSmallScreen ? 'm' : 'none'} paddingBottom={isExtraSmallScreen ? 'xs' : 'l'}>
      <SectionBanner
        showCloseIcon
        hideIcon={isExtraSmallScreen}
        data-testid="season-contractors-1099-dashboard-section-banner"
        onClose={onCloseBanner as VoidFunction}
        title={formatMessage('widgets.Season1099SectionBanner.title')}
        variant="brand"
        leftElement={isExtraSmallScreen ? undefined : <Season1099Icon />}
        action={
          <Container>
            <Text textStyle="body3">
              <Link
                href="#"
                data-testid="season-contractors-1099-dashboard-cta"
                onClick={() =>
                  trackAndHandleClick({ Cta: '1099-message-sync-with-tax1099' }, openSync1099ContractorsModal)
                }
                size="medium"
                label={formatMessage('widgets.Season1099SectionBanner.cta')}
              />
            </Text>
          </Container>
        }
        description={formatMessage('widgets.Season1099SectionBanner.description')}
      />
    </Container>
  );
};
