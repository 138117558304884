import { useSupportedFXData } from '@melio/ap-domain';
import { CountryCode, Currency } from '@melio/international-payments-utils';
import { Form, FormField, Group, SectionBanner, Text, UseMelioFormResults, useWatch } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { useTrackFormError } from '../../add-fx-business-details/hooks/useTrackFormError';
import { useRequireFields } from '../useRequireFields';
import { getRequireFieldsForBankDetails, useCurrenciesOptions, useGetCountryOption } from './fxBankDetailsForm.utils';
import { RequiredFieldsComponent } from './RequiredFieldsComponent';
import { FxVendorAccountFieldsFormFields } from './types';

export type InternationalRequireFieldsFormProps = {
  form: UseMelioFormResults<FxVendorAccountFieldsFormFields>;
  defaultValues?: Partial<FxVendorAccountFieldsFormFields>;
};

export const FxBankDetailsForm = ({ form, defaultValues }: InternationalRequireFieldsFormProps) => {
  const { formatMessage } = useMelioIntl();

  const { formProps, registerField, control, setValue } = form;

  const [foreignCurrency, fxCountrySelection] = useWatch({ control, name: ['foreignCurrency', 'fxCountrySelection'] });
  const { requiredFields } = useRequireFields({ currency: foreignCurrency });
  const { track } = useAnalytics();
  const { countries, currencies, isLoading } = useSupportedFXData({
    currency: !defaultValues?.foreignCurrency ? undefined : foreignCurrency,
    countryCode: fxCountrySelection,
  });
  const isCountryReadOnly = !!defaultValues?.fxCountrySelection;
  const isCurrencyReadOnly = !!defaultValues?.foreignCurrency || !fxCountrySelection;
  const currenciesOptions = useCurrenciesOptions(currencies);
  const countriesOptions = useGetCountryOption(countries, isCountryReadOnly);
  const getKeysList = (list: { label: string }[]) => list.map((x) => x.label);

  useEffect(() => {
    if (!fxCountrySelection || isCurrencyReadOnly) {
      return;
    }
    const foreignCurrency = currencies.length === 1 ? currencies[0] : currencies.find((c) => c !== 'USD');

    if (foreignCurrency) {
      setValue('foreignCurrency', foreignCurrency as Currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fxCountrySelection]);

  useTrackFormError({
    form,
    pageName: 'add-international-account-details',
    intent: 'set-vendor-country',
    viewContext: 'Vendor',
  });

  const resetRequireFields = () =>
    getRequireFieldsForBankDetails(requiredFields)?.fields.map((field) => setValue(field.key, ''));

  const onFxCountrySelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    track('DeliveryMethod', 'Click', {
      PageName: 'add-international-account-details',
      Intent: 'set-vendor-country',
      Cta: e.target.value,
      Flow: 'add-vendor',
      CountriesShown: getKeysList(countriesOptions),
    });
    resetRequireFields();
    setValue('fxCountrySelection', e.target.value as CountryCode);
  };

  const onForeignCurrencySelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    track('DeliveryMethod', 'Click', {
      PageName: 'add-international-account-details',
      Intent: 'set-vendor-currency',
      Cta: e.target.value,
      Flow: 'add-vendor',
      CurrenciesShown: getKeysList(currenciesOptions),
    });
    resetRequireFields();
    setValue('foreignCurrency', e.target.value as Currency);
  };

  const onClearCountry = () => {
    resetRequireFields();
    if (!isCurrencyReadOnly) {
      setValue('foreignCurrency', undefined);
    }
  };

  const shouldShowCountryTooltip = isCountryReadOnly && foreignCurrency !== 'USD';

  const shouldDisplayCurrencyCouldNotBeModifyBanner =
    !defaultValues?.fxCountrySelection && !defaultValues?.foreignCurrency && fxCountrySelection;
  return (
    <Group variant="vertical" width="full" spacing="m">
      <Text textStyle="body4Semi" color="global.neutral.900">
        {formatMessage('activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.title')}
      </Text>
      <Form {...formProps} columns={2}>
        <Form.SelectNew
          colSpan={1}
          options={countriesOptions}
          onClear={onClearCountry}
          isLoading={isLoading && !isCountryReadOnly}
          shouldHideClearButton
          {...registerField('fxCountrySelection')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCountrySelection.label'
            ),
            tooltipProps: shouldShowCountryTooltip
              ? {
                  content: formatMessage(
                    'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCountrySelection.tooltip'
                  ),
                }
              : undefined,
          }}
          isReadOnly={isCountryReadOnly}
          placeholder={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCountrySelection.placeHolder'
          )}
          onClick={() => {
            track('DeliveryMethod', 'View', {
              PageName: 'add-international-account-details',
              Intent: 'set-vendor-currency',
              Flow: 'add-vendor',
              CountriesShown: getKeysList(countriesOptions),
            });
          }}
          onChange={onFxCountrySelectionChange}
        />
        <Form.SelectNew
          colSpan={1}
          options={currenciesOptions}
          isLoading={isLoading && !isCurrencyReadOnly}
          {...registerField('foreignCurrency')}
          shouldHideClearButton
          onChange={onForeignCurrencySelectionChange}
          labelProps={{
            label: formatMessage('activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.foreignCurrency.label'),
            tooltipProps: defaultValues?.foreignCurrency
              ? {
                  content: formatMessage(
                    'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCurrencySelection.tooltip'
                  ),
                }
              : undefined,
          }}
          isReadOnly={isCurrencyReadOnly}
          placeholder={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.foreignCurrency.placeHolder'
          )}
          onClick={() => {
            track('DeliveryMethod', 'View', {
              PageName: 'add-international-account-details',
              Intent: 'set-vendor-currency',
              Flow: 'add-vendor',
              CurrenciesShown: getKeysList(currenciesOptions),
            });
          }}
        />
        {shouldDisplayCurrencyCouldNotBeModifyBanner && (
          <FormField
            labelProps={{ label: '', isHidden: true }}
            colSpan={2}
            render={() => (
              <SectionBanner
                data-testid="fxBankDetailsForm-currency-could-not-be-modify-banner"
                description={formatMessage(
                  'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.currencyCouldNotBeModify.banner'
                )}
                variant="informative"
              />
            )}
          ></FormField>
        )}
        <RequiredFieldsComponent requiredFields={requiredFields} form={form} />
      </Form>
    </Group>
  );
};
