import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody as ChakraDrawerBody,
  DrawerContent as ChakraDrawerContent,
  DrawerHeader as ChakraDrawerHeader,
  Modal as ChakraModal,
  ModalContent as ChakraModalContent,
} from '@chakra-ui/react';
import { BaseModal, Blanket, Container, Group, IconButton, LoadingContainer, Text, useBreakpoint } from '@melio/penny';
import { noop } from 'lodash';

import { MelioWrapper } from './MelioWrapper';
import { OnboardingPaywallModalBaseTheme as styles } from './OnboardingPaywallModalBase.theme';

type OnboardingPaywallModalBaseProps = {
  isOpen: boolean;
  hasXClose: boolean;
  onClose: (exitMethod: 'esc' | 'background-click' | 'X') => void;
  isLoading: boolean;
  header: string;
  children: React.ReactNode;
};

export const OnboardingPaywallModalBase = ({
  isOpen,
  onClose,
  isLoading,
  header,
  children,
  hasXClose,
}: OnboardingPaywallModalBaseProps) => {
  const { isExtraSmallScreen } = useBreakpoint();

  const dataTestId = 'onboarding-paywall-activity';

  const handleOnEsc = () => {
    onClose('esc');
  };

  const handleOverlayClick = () => {
    onClose('background-click');
  };

  const handleXCloseClick = () => {
    onClose('X');
  };

  if (isExtraSmallScreen) {
    return (
      <ChakraDrawer
        isOpen={isOpen}
        onClose={noop}
        onEsc={handleOnEsc}
        onOverlayClick={handleOverlayClick}
        placement="bottom"
      >
        <MelioWrapper>
          <Blanket isOpen={isOpen} isFullScreen data-testid={`${dataTestId}-overlay`} />
          <ChakraDrawerContent data-testid={dataTestId} sx={styles['drawerContentWrapper']} role="dialog">
            <Box __css={styles['drawerContent']}>
              <LoadingContainer isLoading={isLoading}>
                <ChakraDrawerHeader sx={styles['drawerHeader']}>
                  <Box paddingRight="l">
                    <Group>
                      {hasXClose ? (
                        <Box __css={styles['drawerCloseButton']}>
                          <IconButton
                            variant="naked"
                            size="medium"
                            onClick={handleXCloseClick}
                            icon="close"
                            data-testid="onboarding-paywall-close-button"
                          />
                        </Box>
                      ) : null}
                      <Container paddingTop="none">
                        <Text as="h2" textStyle="heading2Semi">
                          {header}
                        </Text>
                      </Container>
                    </Group>
                  </Box>
                </ChakraDrawerHeader>
                <ChakraDrawerBody>{children}</ChakraDrawerBody>
              </LoadingContainer>
            </Box>
          </ChakraDrawerContent>
        </MelioWrapper>
      </ChakraDrawer>
    );
  }

  return (
    <ChakraModal isOpen={isOpen} onClose={noop} onEsc={handleOnEsc} onOverlayClick={handleOverlayClick}>
      <MelioWrapper>
        <Blanket isOpen={isOpen} isFullScreen data-testid={`${dataTestId}-overlay`} />
        <ChakraModalContent data-testid={dataTestId} sx={styles['modalContentWrapper']} role="dialog">
          <Box __css={styles['modalContent']}>
            <LoadingContainer isLoading={isLoading}>
              <BaseModal.Header>
                <Container>
                  <Text as="h2" textStyle="heading2Semi">
                    {header}
                  </Text>
                </Container>
                {hasXClose ? (
                  <Box __css={styles['modalCloseButton']}>
                    <IconButton
                      data-testid="onboarding-paywall-close-button"
                      icon="close"
                      onClick={handleXCloseClick}
                    />
                  </Box>
                ) : null}
              </BaseModal.Header>
              <BaseModal.Body> {children}</BaseModal.Body>
            </LoadingContainer>
          </Box>
        </ChakraModalContent>
      </MelioWrapper>
    </ChakraModal>
  );
};
