/* eslint-disable max-lines */

import {
  getDefaultDeliveryPreferenceByDeliveryMethodType,
  usePaymentFeesDetails,
  usePaymentSchedulingPreference,
} from '@melio/ap-domain';
import { useVendorDirectoryInfoComplete } from '@melio/ap-widgets';
import { useMelioForm, useWatch } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import {
  ApiError,
  BillSubscription,
  FeesBreakdown,
  ModelError,
  Payment,
  PaymentRestrictions,
  useAccountingPlatforms,
  useCheckApprovalRequirement,
  useFeeCatalog,
  useFundingSource,
  useFundingSources,
  usePaymentCalendar,
  usePaymentSettings,
  useVendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useShouldCollectLegalInfoOnPaymentFlow } from '@melio/platform-kyc';
import { converDateToStringRepresentation } from '@melio/platform-utils';
import { useSystemMessage } from '@melio/platform-utils/system-message';
import { isSameDay } from 'date-fns';
import { isNil, omit } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AddBillV2Activity } from '../../add-bill/AddBillV2/AddBillV2.activity';
import { CompleteLegalInfoActivity } from '../../business-legal-info';
import { EditBillActivity } from '../../edit-bill';
import {
  CreateFundingSourceActivity,
  ReconciliationModalActivity,
  useReconciliationModal,
} from '../../funding-sources';
import { PaymentFlowLoader } from '../components/PaymentFlowLoader';
import { PaymentFlowFormFields, PaymentFlowOnChangeHandlers, PaymentSettingsFetchParams } from '../types';
import { DeliveryMethodChangedModal } from './components/DeliveryMethodChangedModal/DeliveryMethodChangedModal';
import { UpdatedDeliveryMethodInfo } from './components/DeliveryMethodChangedModal/types';
import { useDeliveryMethodChangedModal } from './components/DeliveryMethodChangedModal/useDeliveryMethodChangedModal/useDeliveryMethodChangedModal';
import { PaymentFlowForm } from './PaymentFlowForm/PaymentFlowForm';
import { useAmountSchemas } from './PaymentFlowForm/schema/useAmountSchemas';
import { usePaymentFlowFormSchema } from './PaymentFlowForm/schema/usePaymentFlowSchema';
import { useFrequencySelectorSettings } from './useFrequencySelectorSettings';
import { usePaymentCalendarChangeEffect } from './usePaymentCalendarChangeEffect';
import { usePaymentFlowBillDetails } from './usePaymentFlowBillDetails';
import { usePaymentFlowDefaultValues } from './usePaymentFlowDefaultValues';
import { usePaymentFlowNavigation } from './usePaymentFlowNavigation';

export type PaymentFlowActivityProps = {
  defaultValues: Partial<PaymentFlowFormFields>;
  billId?: string;
  payment?: Payment;
  billSubscription?: BillSubscription;
  isSubmitting: boolean;
  onSubmit: (data: PaymentFlowFormFields) => Promise<unknown>;
  onClose: VoidFunction;
  submitError?: ModelError | ApiError | null;
  title: string;
};

export const PaymentFlowActivity = withAnalyticsContext<PaymentFlowActivityProps>(
  ({
    defaultValues,
    billId,
    payment,
    isSubmitting,
    onSubmit,
    onClose,
    title,
    submitError,
    billSubscription,
    setAnalyticsProperties,
  }) => {
    const navigate = usePaymentFlowNavigation();
    const { track } = useAnalytics();
    const { showMessage } = useSystemMessage();
    const { formatMessage } = useMelioIntl();
    const { minDeliveryDate, maxDeliveryDate } = usePaymentSchedulingPreference();
    const [restrictions, setRestrictions] = useState<PaymentRestrictions>();

    const {
      shouldRunReconciliation,
      runReconciliationFlow,
      shouldShowReconciliationModal,
      onDoneReconciliationFlow,
      onCloseReconciliationFlow,
    } = useReconciliationModal();

    const [paymentSettingsFetchParams, setPaymentSettingsFetchParams] =
      useState<PaymentSettingsFetchParams>(defaultValues);

    const isRecurring = paymentSettingsFetchParams.recurrenceType === 'recurring';
    const { isLoading: isBillDetailsLoading, bill, file } = usePaymentFlowBillDetails({ billId });
    const { isLoading: isAccountingPlatformLoading, activeAccountingPlatform } = useAccountingPlatforms();
    const { isLoading: isFundingSourceLoading, data: fundingSource } = useFundingSource({
      enabled: Boolean(paymentSettingsFetchParams.fundingSourceId),
      id: paymentSettingsFetchParams.fundingSourceId,
    });

    const {
      isLoading: isVendorLoading,
      data: vendor,
      update: updateVendor,
    } = useVendor({
      enabled: Boolean(paymentSettingsFetchParams.vendorId),
      id: paymentSettingsFetchParams.vendorId,
      onUpdateError: () =>
        showMessage({
          type: 'error',
          title: formatMessage('activities.paymentFlow.errors.default'),
        }),
    });

    const { data: fundingSources = [], isLoading: isFundingSourcesLoading } = useFundingSources();

    const { data: feesCatalog, isLoading: isFeesLoading } = useFeeCatalog();

    const { isLoadingShouldCollectLegalInfoOnPaymentFlow, shouldCollectLegalInfoOnPaymentFlow } =
      useShouldCollectLegalInfoOnPaymentFlow({
        billIds: bill && [bill.id],
      });

    const { data: approvalRequirement } = useCheckApprovalRequirement({
      enabled: Boolean(paymentSettingsFetchParams.vendorId),
      amount: Number(paymentSettingsFetchParams.amountToPay) || 0,
      vendorId: paymentSettingsFetchParams.vendorId || '',
    });

    const { isValidAmount } = useAmountSchemas({ bill, payment, paymentRestrictions: restrictions });
    const { isLoading: isPaymentSettingsLoading, data: [paymentSettings] = [] } = usePaymentSettings({
      enabled: Boolean(paymentSettingsFetchParams.vendorId),
      params: {
        fillWithDefaults: true,
        expand: ['fundingSourceTypesOptions', 'deliveryMethodTypeOptions', 'restrictions'],
      },
      payload: paymentSettingsFetchParams.vendorId
        ? [
            {
              vendorId: paymentSettingsFetchParams.vendorId,
              deliveryMethodId: paymentSettingsFetchParams.deliveryMethodId,
              fundingSourceId: paymentSettingsFetchParams.fundingSourceId,
              amountToPay:
                paymentSettingsFetchParams.amountToPay && isValidAmount(paymentSettingsFetchParams.amountToPay)
                  ? Number(paymentSettingsFetchParams.amountToPay)
                  : 0,
              deliveryPreferenceType: paymentSettingsFetchParams.deliveryPreference,
              dueDate: converDateToStringRepresentation(paymentSettingsFetchParams.deliveryDate || minDeliveryDate),
              invoicesData: [],
              isRecurring,
              billId,
              paymentId: payment?.id,
            },
          ]
        : [],
      onError: () => {
        showMessage({
          type: 'error',
          title: formatMessage('activities.paymentFlow.errors.default'),
        });
      },
    });

    const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(vendor);
    const handleSubmit: PaymentFlowActivityProps['onSubmit'] = async (data) => {
      if (!isVendorDirectoryInfoCompleted) {
        return;
      }

      if (fundingSource && shouldRunReconciliation(fundingSource)) {
        return runReconciliationFlow();
      }

      if (vendor && shouldCollectLegalInfoOnPaymentFlow) {
        return navigate.toLegal();
      }
      if (data.vendorEmail && vendor?.contact?.email && data.vendorEmail !== vendor?.contact?.email) {
        await updateVendor({ contact: { email: data.vendorEmail } });
      }

      track('Payment', 'Click', {
        Intent: 'confirm-and-pay',
        Cta: 'confirm-and-pay',
        MemoToVendor: !!data.noteToVendor,
        VendorId: data.vendorId,
        PaymentAmount: data.amountToPay,
        PaymentMethodId: data.fundingSourceId,
        DeliveryMethodId: data.deliveryMethodId,
        DeliveryPreference: data.deliveryPreference,
        DeductionDate: data.scheduleDate?.toISOString(),
        ...(!isRecurring
          ? { DeliveryDate: data.deliveryDate?.toISOString() }
          : {
              StartDate: data.startDate?.toISOString(),
              PaymentFrequency: data.intervalType,
              PaymentDuration: data.endPolicy,
              EndDate: data.endDate?.toISOString(),
              NumOfOccurrences: data.numOfOccurrences,
            }),
      });

      return onSubmit(data);
    };

    const form = useMelioForm<PaymentFlowFormFields>({
      onSubmit: handleSubmit,
      defaultValues: usePaymentFlowDefaultValues({ defaultValues }),
      schema: usePaymentFlowFormSchema({
        bill,
        payment,
        billSubscription,
        paymentRestrictions: restrictions,
      }),
      isSaving: isSubmitting,
    });

    const { control, getValues, setValue, trigger } = form;
    const [vendorId, amountToPay, fundingSourceId, deliveryMethodId, deliveryPreference] = useWatch({
      control,
      name: ['vendorId', 'amountToPay', 'fundingSourceId', 'deliveryMethodId', 'deliveryPreference'],
    });

    usePaymentFeesDetails(
      {
        amount: Number(amountToPay),
        deliveryPreferenceType: deliveryPreference,
        deliveryMethodId,
        fundingSourceId,
        billId,
      },
      {
        onFeesCalculated: (feesBreakdown: FeesBreakdown | undefined) => {
          setValue('quoteId', feesBreakdown?.feesDetailsPerRequest?.[0]?.quoteId);
        },
      }
    );

    const paymentSubmitErrors = useMemo(() => {
      const { scheduleDate, startDate, deliveryDate, deliveryMethodId, recurrenceType, amountToPay } = getValues();
      const _deliveryDate = recurrenceType === 'recurring' ? startDate : deliveryDate;

      return [
        {
          error: submitError,
          paymentData: {
            deliveryDate: _deliveryDate,
            deliveryMethodId,
            scheduleDate,
            amountToPay,
            invoiceNumber: bill?.invoice.number,
            id: vendor?.id,
          },
          vendor,
        },
      ];
    }, [bill?.invoice.number, getValues, submitError, vendor]);

    const confirmDeliveryMethodChange = async (updatedDeliveryMethodInfo: UpdatedDeliveryMethodInfo[]) => {
      const updatedFields = omit({ ...updatedDeliveryMethodInfo[0] }, 'id');
      await handleSubmit({ ...getValues(), ...updatedFields });
    };

    const deliveryMethodChangedModal = useDeliveryMethodChangedModal({
      paymentSubmitErrors,
      onSubmit: confirmDeliveryMethodChange,
    });

    const setValues = useCallback(
      (formValues: PaymentFlowFormFields) => {
        Object.entries(formValues).forEach(([key, value]) => {
          setValue(key as keyof PaymentFlowFormFields, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        });
      },
      [setValue]
    );

    setAnalyticsProperties({
      PageName: 'pay',
      Flow: 'single-payment',
      ProductName: 'ap',
      PaymentId: payment?.id,
      BillSubscriptionId: billSubscription?.id,
      BillId: billId,
      VendorId: vendorId,
      PaymentAmount: amountToPay,
      PaymentMethodId: fundingSourceId,
      DeliveryMethodId: deliveryMethodId,
      IsPartialPayment: !!bill && !!amountToPay && Number(amountToPay) !== bill.amount,
      IsRecurring: isRecurring,
    });

    useAnalyticsView('Payment', true, true);

    const { isLoading: isPaymentCalendarLoading, data: paymentCalendar } = usePaymentCalendar({
      startDate: minDeliveryDate,
      endDate: maxDeliveryDate,
      ...(paymentSettingsFetchParams.amountToPay &&
        isValidAmount(paymentSettingsFetchParams.amountToPay) && {
          amount: Number(paymentSettingsFetchParams.amountToPay),
        }),
      fundingSourceId,
      vendorId,
      deliveryMethodId,
      deliveryPreference,
    });

    usePaymentCalendarChangeEffect({
      form,
      paymentCalendar,
      selectedFundingSource: fundingSources.find(({ id }) => id === fundingSourceId),
    });

    const selectedDeliveryMethod = vendor?.deliveryMethods.find(
      (deliveryMethod) => deliveryMethod.id === deliveryMethodId
    );

    const frequencySelectorSettings = useFrequencySelectorSettings({
      bill,
      payment,
      billSubscription,
      selectedVendor: vendor,
      selectedDeliveryMethod,
      paymentRestrictions: restrictions,
    });

    useEffect(() => {
      if (paymentSettings) {
        const { fundingSourceId, deliveryMethodId, deliveryPreferenceType, restrictions } = paymentSettings;

        setRestrictions(restrictions);
        setValues({
          fundingSourceId,
          deliveryMethodId,
          deliveryPreference: deliveryPreferenceType,
        });
        if (!isNil(amountToPay) && amountToPay !== '') {
          void trigger('amountToPay');
        }
      }
    }, [paymentSettings, setValues, trigger, amountToPay]);

    const onChangeCallback = (overrides?: Partial<PaymentSettingsFetchParams>) => {
      const [
        vendorId,
        amountToPay,
        fundingSourceId,
        deliveryMethodId,
        deliveryPreference,
        recurrenceType,
        deliveryDate,
        startDate,
      ] = getValues([
        'vendorId',
        'amountToPay',
        'fundingSourceId',
        'deliveryMethodId',
        'deliveryPreference',
        'recurrenceType',
        'deliveryDate',
        'startDate',
      ]);

      setPaymentSettingsFetchParams({
        vendorId,
        amountToPay,
        fundingSourceId,
        deliveryMethodId,
        deliveryPreference,
        recurrenceType,
        deliveryDate: isRecurring ? startDate : deliveryDate,
        ...overrides,
      });
    };

    const onVendorChange: PaymentFlowOnChangeHandlers['onVendorChange'] = (_vendorId) => {
      if (_vendorId === vendorId) {
        /**
         * This condition prevents unwanted calls to this function caused by an onChange event triggered on mount in VendorSelect.widget.tsx.
         * onChange triggered on mount is a result of an accessibility fix.
         */
        return;
      }

      setValues({
        vendorId: _vendorId,
        deliveryMethodId: undefined,
        deliveryPreference: undefined,
        scheduleDate: undefined,
        recurrenceType: defaultValues.recurrenceType || 'one_time',
      });

      onChangeCallback({ fundingSourceId: undefined });
    };
    const onAmountChange: PaymentFlowOnChangeHandlers['onAmountChange'] = (amountToPay) => {
      setValues({
        amountToPay,
      });
      onChangeCallback();
    };
    const onFundingSourceChange: PaymentFlowOnChangeHandlers['onFundingSourceChange'] = (fundingSourceId) => {
      setValues({
        fundingSourceId,
        scheduleDate: undefined,
      });
      onChangeCallback();
    };
    const onDeliveryMethodChange: PaymentFlowOnChangeHandlers['onDeliveryMethodChange'] = (deliveryMethod) => {
      setValues({
        deliveryPreference: getDefaultDeliveryPreferenceByDeliveryMethodType(deliveryMethod.type),
        deliveryMethodId: deliveryMethod.id,
        scheduleDate: undefined,
      });
      onChangeCallback();
    };
    const onDeliveryDateChange: PaymentFlowOnChangeHandlers['onDeliveryDateChange'] = (deliveryDate) => {
      const dates = deliveryDate
        ? paymentCalendar?.dates.find((dates) => isSameDay(dates.minDeliveryDate, deliveryDate))
        : null;

      if (dates) {
        setValues({
          deliveryDate: dates.minDeliveryDate,
          scheduleDate: dates.scheduleDate,
        });
        onChangeCallback();
      } else {
        setValues({
          deliveryDate,
          scheduleDate: undefined,
        });
      }
    };
    const onStartDateChange: PaymentFlowOnChangeHandlers['onStartDateChange'] = (startDate) => {
      const dates = startDate
        ? paymentCalendar?.dates.find((dates) => isSameDay(dates.minDeliveryDate, startDate))
        : null;

      if (dates) {
        setValues({
          startDate: dates.minDeliveryDate,
          scheduleDate: dates.scheduleDate,
        });
        onChangeCallback();
      } else {
        setValues({
          startDate,
          scheduleDate: undefined,
        });
      }
    };
    const onRecurrenceTypeChange: PaymentFlowOnChangeHandlers['onRecurrenceTypeChange'] = (recurrenceType) => {
      setValues({
        recurrenceType,
        scheduleDate: undefined,
      });
      onChangeCallback();
    };
    const onBillChange: PaymentFlowOnChangeHandlers['onBillChange'] = (bill) => {
      setValues({
        vendorId: bill.vendorId,
        amountToPay: bill.amount,
        noteToVendor: bill.invoiceNumber
          ? formatMessage('activities.paymentFlow.form.content.defaultMemo', { invoiceNumber: bill.invoiceNumber })
          : null,
      });
      onChangeCallback({
        fundingSourceId: undefined,
        deliveryMethodId: undefined,
        deliveryPreference: undefined,
      });
    };

    if (
      isLoadingShouldCollectLegalInfoOnPaymentFlow ||
      isAccountingPlatformLoading ||
      isBillDetailsLoading ||
      isFundingSourcesLoading ||
      isFeesLoading
    ) {
      return <PaymentFlowLoader />;
    }

    return (
      <Routes>
        <Route
          path="/"
          element={
            <>
              <PaymentFlowForm
                form={form}
                handlers={{
                  onFundingSourceChange,
                  onVendorChange,
                  onDeliveryMethodChange,
                  onAmountChange,
                  onDeliveryDateChange,
                  onBillChange,
                  onRecurrenceTypeChange,
                  onStartDateChange,
                }}
                onBillButtonClick={() => navigate.toBill(bill)}
                onNewFundingSourceClick={navigate.toFundingSource}
                onDeliveryMethodClick={navigate.toDeliveryMethod}
                fundingSources={fundingSources}
                selectedVendor={vendor}
                paymentCalendarDates={paymentCalendar?.dates}
                payment={payment}
                bill={bill}
                file={file}
                billSubscription={billSubscription}
                paymentSettings={paymentSettings}
                frequencySelectorSettings={frequencySelectorSettings}
                feeCatalog={feesCatalog}
                isLoading={
                  isPaymentCalendarLoading || isPaymentSettingsLoading || isFundingSourceLoading || isVendorLoading
                }
                isDatesLoading={isPaymentCalendarLoading}
                isSubmitting={isSubmitting}
                isSubmitButtonDisabled={isVendorLoading}
                onClose={onClose}
                title={title}
                shouldRunReconciliation={shouldRunReconciliation}
                runReconciliationFlow={runReconciliationFlow}
                activeAccountingPlatform={activeAccountingPlatform}
                approvalRequirementStatus={approvalRequirement?.approvalRequirementStatus}
              />
              {fundingSourceId && (
                <ReconciliationModalActivity
                  selectedFundingSourceId={fundingSourceId}
                  isOpen={shouldShowReconciliationModal}
                  onClose={onCloseReconciliationFlow()}
                  onDone={onDoneReconciliationFlow}
                />
              )}
              <DeliveryMethodChangedModal
                isOpen={deliveryMethodChangedModal.open}
                deliveryMethodChangeTableData={deliveryMethodChangedModal.deliveryMethodChangeTableData}
                headers={deliveryMethodChangedModal.headers}
                onSubmit={deliveryMethodChangedModal.confirm}
                isSubmitting={isSubmitting}
                onClose={deliveryMethodChangedModal.close}
              />
            </>
          }
        />
        <Route
          path="/funding-source"
          element={
            <CreateFundingSourceActivity
              fundingSourceTypesOptions={paymentSettings?.fundingSourceTypesOptions}
              onClose={navigate.toRoot}
              onDone={(fundingSource) => {
                onFundingSourceChange(fundingSource.id);
                navigate.toRoot();
              }}
            />
          }
        />
        <Route
          path="/bill/add"
          element={
            <AddBillV2Activity
              initialValues={{
                vendorId,
                amount: amountToPay,
              }}
              onDone={(data, billId, saveType, message) => {
                if (billId) {
                  if (saveType === 'close') {
                    return onClose();
                  }
                  onBillChange({
                    vendorId: data.vendorId,
                    amount: data.billAmount,
                    invoiceNumber: data.billNumber,
                  });
                  navigate.toRoot({ search: new URLSearchParams({ billId }) });
                  if (message) {
                    showMessage(message);
                  }
                }
              }}
              onClose={navigate.toRoot}
              onBack={navigate.toRoot}
              hideSecondaryButton
            />
          }
        />
        <Route
          path="/bill/edit"
          element={
            bill ? (
              <EditBillActivity
                id={bill.id}
                onClose={navigate.toRoot}
                onBack={navigate.toRoot}
                onDone={(data, saveType) => {
                  if (saveType === 'close') {
                    return onClose();
                  }
                  onBillChange({
                    vendorId: data.vendorId,
                    amount: data.balance?.toString(),
                    invoiceNumber: data.invoiceNumber,
                  });
                  navigate.toRoot();
                }}
                hideSecondaryButton
              />
            ) : (
              <Navigate to=".." />
            )
          }
        />
        <Route
          path="/legal"
          element={
            <CompleteLegalInfoActivity
              onBack={navigate.toRoot}
              onClose={navigate.toRoot}
              onDone={() => onSubmit(form.getValues())}
            />
          }
        />

        <Route path="*" element={<Navigate to=".." />} />
      </Routes>
    );
  }
);
