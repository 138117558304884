import { TaxRate, useMelioIntl } from '@melio/ar-domain';
import { Group, Pill, Table, TableColumnDef, useTable } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useMemo } from 'react';

import { TaxRateActionCell } from './TaxRateActionCell';
import { TaxRatesTableListItem } from './TaxRatesTableListItem.mobile';

export type TaxRatesTableProps = {
  isLoading?: boolean;
  taxRates: TaxRate[];
  onEdit: (itemId: string) => unknown;
  onDelete: (itemId: string) => unknown;
};

export const TaxRatesTable = forwardRef<TaxRatesTableProps>(
  ({ taxRates, isLoading, onEdit, onDelete, ...props }, ref) => {
    const { formatMessage, formatPercents } = useMelioIntl();
    const mobileRowRenderer = useCallback(
      (row: TaxRate) => <TaxRatesTableListItem taxRate={row} onEdit={onEdit} onDelete={onDelete} />,
      [onEdit, onDelete]
    );

    const columns = useMemo<TableColumnDef<TaxRate>[]>(
      () => [
        {
          id: 'name',
          size: 'l',
          header: formatMessage('ar.settings.activities.taxRatesTable.components.taxRatesTable.columns.name.header'),
          cell: ({ row }) => (
            <Table.Cell data-testid="tax-rate-name">
              <Group variant="horizontal" spacing="xs">
                {row.name}
                {row.isDefault && (
                  <Pill data-testid="is-default-badge" type="secondary" status="neutral" label="Default" />
                )}
              </Group>
            </Table.Cell>
          ),
        },
        {
          id: 'rate',
          size: 's',
          header: formatMessage('ar.settings.activities.taxRatesTable.components.taxRatesTable.columns.price.header'),
          cell: ({ row }) => <Table.Cell data-testid="tax-rate-value">{formatPercents(row.rate)}</Table.Cell>,
        },

        {
          id: 'actions',
          size: 'xs',
          cell: ({ row }) => <TaxRateActionCell taxRate={row} onEdit={onEdit} onDelete={onDelete} />,
        },
      ],
      [formatMessage, formatPercents, onEdit, onDelete]
    );

    const table = useTable<TaxRate>({
      data: taxRates,
      isLoading,
      columns,
      headerVariant: 'dark',
      mobileRowRenderer,
      hideHeaderWhileLoading: true,
    });
    return <Table {...table} {...props} ref={ref} />;
  }
);
TaxRatesTable.displayName = 'TaxRatesTable';
