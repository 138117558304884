import { CaratCardFormObject } from '@melio/ap-widgets/src/components/carat/CaratForm/types';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { CardHolderDetails, FundingSource, useCaratSession } from '@melio/platform-api';
import { useCaratVerification } from '@melio/platform-api/src/entities-v2/carat/useCarat';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean, useSystemMessage } from '@melio/platform-utils';
import { useCallback, useEffect } from 'react';

import { ReconciliationModalActivity } from '../Reconciliation';
import { CaratCardHolderDetailsScreen } from './screens/CarataCardHolderDetails/CaratCardHolderDetails.screen';
import { CaratCreateCardDetailsScreen } from './screens/CaratCreateCardDetails';
import { useAddCardFundingSourceActivityCallbacks } from './shared/useAddCardFundingSourceActivityCallbacks';
import { AddCardFundingSourceActivityProps } from './types';
import { useAddCardFundingSourceActivityStep } from './useAddCardFundingSourceActivityStep';

export const CaratAddCardFundingSourceActivity = withAnalyticsContext<AddCardFundingSourceActivityProps>(
  ({ onClose, onDone, onBack: onFirstStepBack, onError, cardType }) => {
    const { currentStep, goToPreviousStep, goToStep } = useAddCardFundingSourceActivityStep({ onFirstStepBack });
    const { data: sessionData, isFetching: isSessionLoading, isError: isSessionError } = useCaratSession();
    const { formatMessage } = useMelioIntl();
    const { mutateAsync: caratCardVerification, error: validationError } = useCaratVerification();
    const [isVerifying, verifying] = useBoolean(false);
    const [isSavingFs, savingFs] = useBoolean(false);
    const { showMessage } = useSystemMessage();
    const onErrorCallback = useCallback<ErrorFunction>(
      (error) => {
        showMessage({
          title: formatMessage('widgets.carat.form.cardDetails.error.general'),
          type: 'error',
        });

        onError?.(error);
        savingFs.off();
      },
      [onError, showMessage, formatMessage, savingFs]
    );

    const onDoneCallback = useCallback(
      (fundingSource: FundingSource, shouldRedirectToAssign?: boolean) => {
        showMessage({
          type: 'success',
          title: formatMessage('activities.addCardFundingSource.screens.cardHolderDetails.successMessageTitle', {
            card: fundingSource.displayName,
          }),
        });
        onDone(fundingSource, shouldRedirectToAssign);
      },
      [onDone, showMessage, formatMessage]
    );

    const { showVerificationErrorDialog, handleSubmit, reconciliation, fundingSourceId, setCardAssignRedirect } =
      useAddCardFundingSourceActivityCallbacks({
        vaulting: 'Carat',
        cardType,
        onError: onErrorCallback,
        onDone: onDoneCallback,
      });
    const { createTrackHandler } = useAnalytics();
    const trackCreateCardDetailsSubmit = createTrackHandler<{ Status?: 'succeeded' | 'failed' }>(
      'AddCCDetails',
      'Submitted'
    );

    useEffect(() => {
      if (isSessionError) {
        showMessage({
          title: formatMessage('widgets.carat.form.cardDetails.error.session'),
          type: 'error',
        });
        onError?.({
          message: formatMessage('widgets.carat.form.cardDetails.error.session'),
        });
      } else if (validationError) {
        onError?.({
          message: formatMessage('widgets.carat.form.cardHoldDetails.error.validation'),
        });
        showMessage({
          title: formatMessage('widgets.carat.form.cardHoldDetails.error.validation'),
          type: 'error',
        });
      }
      //adding showMessage to the dependency array will cause an infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationError, isSessionError, onError, formatMessage]);

    const {
      onCloseReconciliationFlow,
      onDoneReconciliationFlow,
      shouldShowReconciliationModal,
      isReconciliationNeeded,
    } = reconciliation;

    const onCreateCardDetailsDone = useCallback(
      async (formInputObject: CaratCardFormObject) => {
        try {
          verifying.on();
          if (!sessionData) {
            throw new Error('Session data not available');
          }
          await formInputObject.submit({
            apiKey: sessionData.apiKey,
            accessToken: sessionData.accessToken,
            createToken: true,
            publicKey: sessionData.publicKey,
            keyId: sessionData.keyId,
            merchantId: sessionData.merchantDetails.merchantId,
            terminalId: sessionData.merchantDetails.terminalId,
          });

          trackCreateCardDetailsSubmit({ Status: 'succeeded' });
          goToStep('ADD_CARD_HOLDER_DETAILS');
          verifying.off();
        } catch (error) {
          verifying.off();
          trackCreateCardDetailsSubmit({ Status: 'failed' });
          showVerificationErrorDialog(error as PlatformError);
          onError?.(error as PlatformError);
          return;
        }
      },

      [verifying, sessionData, showVerificationErrorDialog, trackCreateCardDetailsSubmit, goToStep, onError]
    );

    const onCardHolderDetailsDone = useCallback(
      async (data: CardHolderDetails) => {
        //step one is to verify the card details using the session id
        //step two is to save the card details

        try {
          if (!sessionData) {
            throw new Error('Session data not available');
          }
          savingFs.on();
          const { city, line1, postalCode, state } = data;
          const cardVerificationData = await caratCardVerification({
            billingAddress: {
              address1: line1,
              city,
              state,
              zipCode: postalCode,
            },
            accessToken: sessionData.accessToken,
          });

          const { token, cardDetails } = cardVerificationData;
          const { bin, expirationMonth, expirationYear, last4digits, network } = cardDetails;

          handleSubmit({
            ...data,
            CardParams: {
              cardBin: bin,
              expirationMonth,
              expirationYear,
              lastFourDigits: last4digits,
              tabapayToken: token,
              network,
            },
          });
        } catch (error) {
          onErrorCallback(error as PlatformError);
        }
      },
      [savingFs, caratCardVerification, handleSubmit, sessionData, onErrorCallback]
    );

    if (currentStep == 'ADD_CARD_HOLDER_DETAILS') {
      return (
        <>
          <CaratCardHolderDetailsScreen
            onDone={onCardHolderDetailsDone}
            onClose={onClose}
            onBack={goToPreviousStep}
            isSaving={isSavingFs}
            setCardAssignRedirect={setCardAssignRedirect}
          />
          {fundingSourceId && isReconciliationNeeded && (
            <ReconciliationModalActivity
              isOpen={shouldShowReconciliationModal}
              onClose={onCloseReconciliationFlow(onClose)}
              onDone={onDoneReconciliationFlow}
              selectedFundingSourceId={fundingSourceId}
              isNewFundingSource
            />
          )}
        </>
      );
    }

    return (
      <>
        <CaratCreateCardDetailsScreen
          onClose={onClose}
          onError={showVerificationErrorDialog}
          onDone={onCreateCardDetailsDone}
          isSaving={isVerifying}
          isLoading={isSessionLoading}
        />
      </>
    );
  }
);

CaratAddCardFundingSourceActivity.displayName = 'AddCardFundingSourceActivity';
