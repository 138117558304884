import { Container, IconButton, StatusIndicator, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TodosDrawerActivity } from '../../../todos-drawer';
import { useEnrichedTodos } from '../../../todos-drawer/hooks/useEnrichedTodos';
import { useTodosEnabled } from '../../../todos-drawer/hooks/useTodosEnabled';
import { usePayDashboardTabs } from '../../hooks/usePayDashboardTabs';

export const TodosWidgetButton = () => {
  const { isEnabled } = useTodosEnabled();

  if (isEnabled) {
    return <TodosButton />;
  }

  return null;
};

const TodosButton = () => {
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();
  const { newItemsAfterLastSeenCount, totalCount } = useEnrichedTodos();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [todosNewIndicationsEnabled] = useDevFeature(FeatureFlags.TodosNewIndications, false, {
    shouldTrack: true,
  });
  const { track } = useAnalytics();
  const { selectedTab } = usePayDashboardTabs();
  const {
    settings: {
      Todos: { icon: todosIcon },
    },
  } = useConfig();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    track('Dashboard', 'Click', {
      PageName: `dashboard-${selectedTab}`,
      Intent: 'view-todos',
      TodosIconNotification: status === 'critical' ? 'Yes' : 'No',
      Cta: 'todos',
    });
    setIsDrawerOpen(true);
  };
  const handleDrawerClose = useCallback(() => setIsDrawerOpen(false), []);

  const ariaLabel = todosNewIndicationsEnabled
    ? formatMessage('widgets.todosButton.ariaLabel', {
        count: newItemsAfterLastSeenCount,
      })
    : formatMessage('widgets.todosButton.totalCount.ariaLabel', {
        count: totalCount,
      });

  const status = useMemo(
    () =>
      todosNewIndicationsEnabled
        ? newItemsAfterLastSeenCount === 0
          ? undefined
          : 'critical'
        : totalCount === 0
        ? undefined
        : 'critical',
    [newItemsAfterLastSeenCount, todosNewIndicationsEnabled, totalCount]
  );

  useEffect(() => {
    track('Dashboard', 'View', {
      PageName: `dashboard-${selectedTab}`,
      TodosIconNotification: status === 'critical' ? 'Yes' : 'No',
    });
  }, [track, selectedTab, status]);

  const iconButton = (
    <IconButton
      icon={todosIcon}
      onClick={handleClick}
      variant={isExtraSmallScreen ? 'naked' : 'primary'}
      data-testid="todos-button"
      aria-label={ariaLabel}
    />
  );

  return (
    <>
      <Container data-testid="todos-button-container" overflow="initial" width="fit-content">
        {status ? <StatusIndicator status={status}>{iconButton}</StatusIndicator> : iconButton}
      </Container>
      <TodosDrawerActivity isOpen={isDrawerOpen} onClose={handleDrawerClose} />
    </>
  );
};
