export enum CompanyTypeEnum {
  'accounting-firm' = 'accounting-firm',
  'smb' = 'smb',
}

export enum MqlEnum {
  'undecided',
  'qualified',
  'unqualified',
}

export enum UserRegistrationFlowEnum {
  REGULAR = 'regular',
  BUILT_PARTNERSHIPS = 'built-partnership',
  MERCHANT_CHECKOUT = 'merchant-checkout',
  ACCOUNTS_PAYABLE_PLATFORM = 'accounts-payable-platform',
}
export enum UserRoleEnum {
  Owner = 'owner',
  Admin = 'admin',
  Contributor = 'contributor',
  Accountant = 'accountant',
}

export enum VendorActionEnum {
  AcceptPayment = 'acceptPayment',
}

export type UserPayloadType = {
  id?: string;
  email: string;
  isGuest?: boolean;
  organizationRole?: UserRoleEnum;
  registrationFlow?: string;
  createdAt: Date;
};

export type OrganizationPayloadType = {
  id: number;
  createOrigin?: string;
  companyType?: CompanyTypeEnum;
  contactFirstName?: string;
  contactLastName?: string;
  companyName?: string;
  mqlStatus?: MqlEnum;
};
export type ClaimsPayloadType = {
  vendorId?: string;
  fundingSourceIds?: number[];
};

export type AccessTokenPayloadType = {
  accountId: string;
  user: UserPayloadType;
  organization?: OrganizationPayloadType;
  partnerName: string;
  iat: number;
  claims?: ClaimsPayloadType;
  partnerId?: number;
};
