import { Route, Routes } from 'react-router-dom';
import { AccountantsRoutes } from '@melio/accountants';
import { SyncFlowEnum } from '@melio/ap-activities';

import { useIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { useSwitchOrganization } from '@/hooks/useSwitchOrganization.hooks';
import { DashboardLayout } from '@/router/layouts/Dashboard.layout';
import { ScreensEnum } from '@/store/app/app.types';

export const AccountantsRouter = () => {
  // Assume that "Clients" tab is the only tab we have under accounting contexts
  useActiveScreen(ScreensEnum.Clients);
  const { switchOrganization } = useSwitchOrganization();
  const { generateMelioClientsLink, goToSettingsSubscriptionPlans } = useRouter();

  const goToMelioClientsDashboard = () => {
    location.href = generateMelioClientsLink(true);
  };

  const intuitConnect = useIntuitConnect(SyncFlowEnum.AccountingFirmAddClient, undefined, {
    isAccountant: true,
  });

  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route
          path="dashboard/*"
          element={
            <AccountantsRoutes.DashboardRoutes
              goToSettingsSubscriptionPlans={goToSettingsSubscriptionPlans}
              onSwitchOrganization={({
                organizationId,
                skipPayDashboardRedirect,
                switchAccessTokenOnly,
                keepLoadingState,
              }: {
                organizationId: string;
                skipPayDashboardRedirect?: boolean;
                switchAccessTokenOnly?: boolean;
                keepLoadingState?: boolean;
              }) =>
                switchOrganization({
                  organizationId,
                  isAccountingFirm: false,
                  skipPayDashboardRedirect,
                  switchAccessTokenOnly,
                  loadingState: keepLoadingState ? 'always' : 'default',
                })
              }
            />
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <AccountantsRoutes.StandaloneRoutes
            goToMelioClientsDashboard={goToMelioClientsDashboard}
            switchOrganization={(organizationId) => switchOrganization({ organizationId, isAccountingFirm: false })}
            intuitConnect={intuitConnect}
          />
        }
      />
    </Routes>
  );
};
