import { FormWidgetProps, SharePaymentLink, useMelioIntl } from '@melio/ar-domain';
import { Container, Form, Group, NakedButton, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect } from 'react';
import { boolean, object, SchemaOf, string } from 'yup';

import { IssueInvoiceFormFields, SendEmailOptions } from '../types';

export type IssueInvoiceFormProps = FormWidgetProps<IssueInvoiceFormFields> & {
  error?: ARPlatformError;
  onSelectSendEmail: (isSendInvoice: boolean) => void;
  onCopyPaymentLink?: () => Promise<void>;
  paymentRequestLink?: string;
};

const useSchema = (): SchemaOf<IssueInvoiceFormFields> => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    email: string()
      .nullable()
      .email(formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.fields.email.validation.text')),
    emailMessage: string()
      .optional()
      .transform((value?: string | null) => (!value ? undefined : value)),
    shouldSendEmail: string()
      .oneOf(Object.values(SendEmailOptions))
      .default(SendEmailOptions.Yes)
      .required(formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.required.text')),
    isAutoRemindersEnabled: boolean(),
  }) as SchemaOf<IssueInvoiceFormFields>;
};

export const IssueInvoiceForm = forwardRef<IssueInvoiceFormProps, 'form'>(
  (
    { onSubmit, onSubmissionStateChange, onSelectSendEmail, onCopyPaymentLink, paymentRequestLink, defaultValues },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { registerField, watch, formProps } = useMelioForm({
      defaultValues: {
        shouldSendEmail: SendEmailOptions.Yes,
        isAutoRemindersEnabled: true,
        ...defaultValues,
      },
      onSubmit,
      schema: useSchema(),
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });
    const [isCustomizedEmailMessageExpanded, expandCustomizedEmailMessage] = useBoolean(false);
    const { track } = useAnalytics();

    const shouldSendEmail = watch('shouldSendEmail') === 'yes';

    useEffect(() => {
      onSelectSendEmail(shouldSendEmail);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSendEmail]);

    return (
      <Form {...formProps} ref={ref}>
        <Form.RadioGroup
          {...registerField('shouldSendEmail')}
          options={[
            {
              mainLabelProps: {
                label: formatMessage(
                  'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.yes.label.text'
                ),
              },
              value: SendEmailOptions.Yes,
              descriptionProps: {
                label: formatMessage(
                  'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.yes.description.text'
                ),
              },
            },
          ]}
          aria-label={formatMessage(
            'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.yes.aria-label.text'
          )}
        />
        {shouldSendEmail && (
          <Container paddingLeft="l" data-testid="send-email-info-container">
            <Group spacing="m" variant="vertical">
              <Form.TextField
                {...registerField('email')}
                labelProps={{
                  label: formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.fields.email.label.text'),
                }}
                placeholder={formatMessage(
                  'ar.invoiceLifecycle.activities.issueInvoice.form.fields.email.placeholder.text'
                )}
                isReadOnly={!!defaultValues?.email}
              />
              {!isCustomizedEmailMessageExpanded && (
                <NakedButton
                  data-testid="customize-email-button"
                  onClick={() => {
                    track('Invoice', 'Click', {
                      Intent: 'edit-customer-email',
                      Cta: 'customize-the-email',
                    });
                    expandCustomizedEmailMessage.on();
                  }}
                  label={formatMessage(
                    'ar.invoiceLifecycle.activities.issueInvoice.form.buttons.customizeEmailMessage.label'
                  )}
                  variant="secondary"
                />
              )}
              <Form.TextArea
                {...registerField('emailMessage')}
                isHidden={!isCustomizedEmailMessageExpanded}
                labelProps={{
                  label: formatMessage(
                    'ar.invoiceLifecycle.activities.issueInvoice.form.fields.emailMessage.label.text'
                  ),
                }}
                data-private
                numberOfRows={3}
                placeholder={formatMessage(
                  'ar.invoiceLifecycle.activities.issueInvoice.form.fields.emailMessage.placeholder.text'
                )}
                maxChars={250}
              />
            </Group>
          </Container>
        )}
        <Form.RadioGroup
          {...registerField('shouldSendEmail')}
          options={[
            {
              mainLabelProps: {
                label: formatMessage(
                  'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.no.label.text'
                ),
              },
              descriptionProps: {
                label: formatMessage(
                  'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.no.description.text'
                ),
              },
              value: SendEmailOptions.No,
            },
          ]}
          aria-label={formatMessage(
            'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.no.aria-label.text'
          )}
        />
        {!shouldSendEmail && paymentRequestLink && (
          <SharePaymentLink link={paymentRequestLink} onCopyPaymentLink={onCopyPaymentLink}></SharePaymentLink>
        )}
      </Form>
    );
  }
);
IssueInvoiceForm.displayName = 'IssueInvoiceForm';
