import { BillProvider } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useHref } from 'react-router-dom';

import AmazonLogo from '../assets/AmazonLogo.svg';
import GmailLogo from '../assets/GmailLogo.svg';
import { EBILLS_STATE_QUERY_PARAM_KEY } from '../consts';
import { EbillsImportRedirectState, EbillsImportRedirectStateEntryPoint } from '../types';

export const useRedirectURL = (state: EbillsImportRedirectState): string => {
  const {
    settings: { accountingSoftwareSuccessReturnUrl },
  } = useConfig();

  const url = `/bills-sync-redirect?${buildRedirectStateEncodedURLSearchParams(state)}`;
  const path = useHref(url, { relative: 'route' });
  return accountingSoftwareSuccessReturnUrl || `${window.location.origin}${path}`;
};

export const buildRedirectStateEncodedURLSearchParams = (state: EbillsImportRedirectState) =>
  new URLSearchParams({
    [EBILLS_STATE_QUERY_PARAM_KEY]: JSON.stringify(state),
  }).toString();
const names = {
  amazon: 'Amazon Business',
  gmail: 'Gmail',
};

const logos = {
  amazon: AmazonLogo,
  gmail: GmailLogo,
};

export const useProviderDisplayProps = (provider: BillProvider, entryPoint?: EbillsImportRedirectStateEntryPoint) => {
  const redirectURL = useRedirectURL({ provider: provider.name, entryPoint });
  const { name, isDisconnectLoading, isStatusLoading, isConnectLoading } = provider;

  return {
    redirectURL,
    displayName: names[name],
    logoSVG: logos[name],
    isLoading: isDisconnectLoading || isStatusLoading || isConnectLoading,
  };
};
