import { Box } from '@chakra-ui/react';
import { Container, Group, IconButton, Text, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const Arrows = ({
  handleNextClick,
  handlePrevClick,
  currentElementIndex,
  totalElements,
  isCyclical = true,
  tooltipLeftButtonLabel,
  tooltipRightButtonLabel,
  testId,
}: {
  handleNextClick: () => void;
  handlePrevClick: () => void;
  currentElementIndex: number;
  totalElements: number;
  isCyclical?: boolean;
  tooltipLeftButtonLabel?: string;
  tooltipRightButtonLabel?: string;
  testId: string;
}) => {
  const { formatMessage } = useMelioIntl();

  const lastItemIndex = totalElements > 0 ? totalElements - 1 : 0;

  const hasMultipleElements = totalElements > 1;

  const buttonVisibility = hasMultipleElements ? undefined : 'hidden';

  const prevButton = (
    <Box visibility={buttonVisibility}>
      <Tooltip content={tooltipLeftButtonLabel ?? ''} isEnabled={!!tooltipLeftButtonLabel}>
        <IconButton
          icon="chevron-left"
          variant="naked"
          size="medium"
          onClick={handlePrevClick}
          data-testid={`${testId}-arrows-prev-button`}
          isDisabled={!isCyclical && currentElementIndex === 0}
        />
      </Tooltip>
    </Box>
  );

  const nextButton = (
    <Box visibility={buttonVisibility}>
      <Tooltip content={tooltipRightButtonLabel ?? ''} isEnabled={!!tooltipLeftButtonLabel}>
        <IconButton
          icon="chevron-right"
          variant="naked"
          size="medium"
          onClick={handleNextClick}
          data-testid={`${testId}-arrows-next-button`}
          isDisabled={!isCyclical && currentElementIndex === lastItemIndex}
        />
      </Tooltip>
    </Box>
  );

  return (
    <Group variant="vertical" data-testid={`${testId}-arrows-buttons`}>
      <Group spacing="s" width="full" alignItems="center">
        {hasMultipleElements && (
          <Text textStyle="body3" data-testid={`${testId}-arrows-pagination`}>
            {formatMessage('widgets.billsDetailsCarousel.arrows.pagination', {
              index: currentElementIndex + 1,
              total: totalElements,
            })}
          </Text>
        )}
        {hasMultipleElements && (
          <Group.Item>
            <Container overflow="initial" paddingRight="xxs" width="fit-content" alignItems="center" height="full">
              {prevButton}
            </Container>
          </Group.Item>
        )}
        {hasMultipleElements && (
          <Group.Item>
            <Container overflow="initial" paddingLeft="xxs" width="fit-content" alignItems="center" height="full">
              {nextButton}
            </Container>
          </Group.Item>
        )}
      </Group>
    </Group>
  );
};
