/* eslint-disable react-hooks/exhaustive-deps */
import { ScannedInvoiceDetailsBody } from '@melio/ap-widgets';
import { Button, Drawer, Group, Loader, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useScannedInvoice } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { SystemMessageProvider } from '@melio/platform-utils';
import { useEffect, useRef, useState } from 'react';

import { DrawerPreviewInvoice } from './DrawerPreviewInvoice';

export type ScannedInvoiceDrawerProps = {
  id: string;
  onClose: () => void;
  onReviewScannedInvoice: (id: string) => void;
};

export const ScannedInvoiceDrawer = (props: ScannedInvoiceDrawerProps) => (
  <SystemMessageProvider>
    <ScannedInvoiceDrawerContent {...props} />
  </SystemMessageProvider>
);

const ScannedInvoiceDrawerContent = withAnalyticsContext(
  ({ id, onClose, onReviewScannedInvoice }: ScannedInvoiceDrawerProps) => {
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const [isClosing, setIsClosing] = useState(false);
    const [isPreviewInvoiceEnabled] = usePartnerFeature('PreviewInvoice', false);
    const shouldReturnFocus = useRef(!!document.activeElement && document.activeElement !== document.body);
    const { data: scannedInvoice, isLoading: isWaitingForScannedInvoice } = useScannedInvoice({
      id,
      refetchOnMount: 'always',
    });

    useAnalyticsContext({
      globalProperties: {
        PageName: 'scanned-invoice-view',
        ScannedInvoiceId: id,
        Amount: scannedInvoice?.amount,
      },
    });

    useEffect(() => {
      if (isWaitingForScannedInvoice) {
        return;
      }
      track('ScannedInvoice', 'View', {
        Intent: 'view-scanned-invoice',
        Amount: scannedInvoice?.amount,
      });
    }, [isWaitingForScannedInvoice]);

    const handleReviewScannedInvoice = () => {
      track('ScannedInvoice', 'Click', {
        Intent: 'review-bill',
      });
      onReviewScannedInvoice(id);
    };

    const handleCloseClick = () => {
      track('ScannedInvoice', 'Click', {
        Intent: 'exit',
        Cta: 'exit',
      });
      setIsClosing(true);
    };

    return (
      <Drawer
        size={isPreviewInvoiceEnabled ? 'l' : 'm'}
        isOpen={!isClosing && (!!scannedInvoice || isWaitingForScannedInvoice)}
        shouldReturnFocus={shouldReturnFocus.current}
        onClose={handleCloseClick}
        onCloseComplete={onClose}
        body={
          scannedInvoice ? (
            isPreviewInvoiceEnabled ? (
              <DrawerPreviewInvoice filesIds={[scannedInvoice.fileId]}>
                <ScannedInvoiceDetailsBody
                  scannedInvoice={scannedInvoice}
                  onReviewScannedInvoice={handleReviewScannedInvoice}
                />
              </DrawerPreviewInvoice>
            ) : (
              <ScannedInvoiceDetailsBody
                scannedInvoice={scannedInvoice}
                onReviewScannedInvoice={handleReviewScannedInvoice}
              />
            )
          ) : (
            <Loader aria-live="polite" />
          )
        }
        closeButtonAriaLabel={formatMessage(
          'activities.payDashboard.drawer.header.scannedInvoice.closeButtonAriaLabel'
        )}
        header={
          <Text as="h2" textStyle="heading2Semi">
            {formatMessage('activities.payDashboard.drawer.header.scannedInvoice.title')}
          </Text>
        }
        footer={
          scannedInvoice ? <ScannedInvoiceDrawerFooter onReviewScannedInvoice={handleReviewScannedInvoice} /> : null
        }
        data-testid="scanned-invoice-drawer"
      />
    );
  }
);

const ScannedInvoiceDrawerFooter = ({ onReviewScannedInvoice }: { onReviewScannedInvoice: () => void }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group spacing="s">
      <Button
        onClick={onReviewScannedInvoice}
        variant="primary"
        label={formatMessage('activities.payDashboard.drawer.footer.scannedInvoice.reviewBill')}
        data-testid="scanned-invoice-drawer-footer-review-bill-button"
      />
    </Group>
  );
};
