import {
  FieldValues,
  Form,
  FormProps,
  Group,
  Modal,
  ModalProps,
  SectionBanner,
  SectionBannerProps,
  Text,
  UseMelioFormResults,
} from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { FormattedMessage } from '../i18n';
import { FormWidgetProps } from '../types';

type TopLevelProps = {
  isLoading?: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
};

type AdditionalProps = {
  modalSize?: ModalProps['size'];
};

export type FormDialogWidgetProps<T extends FieldValues, TMore = NonNullable<unknown>> = Omit<
  FormWidgetProps<T>,
  'onSubmissionStateChange'
> &
  TopLevelProps &
  TMore;

type DisabledButtonProps = { isLoading?: never; onClick?: never; isDisabled?: never };

type SecondaryButtonProps = Override<
  NonNullable<ModalProps['secondaryButton']>,
  { variant?: NonNullable<ModalProps['secondaryButton']>['variant'] } & DisabledButtonProps
>;
type PrimaryButtonProps = Override<
  NonNullable<ModalProps['primaryButton']>,
  { variant?: NonNullable<ModalProps['primaryButton']>['variant'] } & DisabledButtonProps
>;

export type FormDialogProps<T extends FieldValues = FieldValues> = TopLevelProps &
  AdditionalProps &
  Pick<ModalProps, 'header' | 'isLoading'> &
  Pick<FormProps, 'size' | 'columns' | 'autoFocus'> & {
    useFormResults: Pick<UseMelioFormResults<T>, 'cancelButtonProps' | 'submitButtonProps' | 'formProps'>;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    description?: string;
    secondaryButton?: SecondaryButtonProps;
    primaryButton: PrimaryButtonProps;
    banner?: SectionBannerProps;
    closeButtonAriaLabel?: string;
  };

export const FormDialog = forwardRef<FormDialogProps>(
  (
    {
      onClose: _onClose,
      secondaryButton,
      primaryButton,
      useFormResults,
      size,
      modalSize,
      footer,
      columns,
      autoFocus = true,
      children,
      description,
      banner,
      ...props
    },
    ref
  ) => {
    const isSaving = useFormResults.submitButtonProps.isLoading;
    const onClose = isSaving ? () => null : _onClose;

    return (
      <Modal
        data-component={FormDialog.displayName}
        onClose={onClose}
        size={modalSize}
        // TODO: https://meliorisk.atlassian.net/browse/ME-57709
        // disable close icon when `isSaving`
        primaryButton={{ variant: 'primary', ...primaryButton, ...useFormResults.submitButtonProps }}
        secondaryButton={
          secondaryButton && {
            variant: 'tertiary',
            ...secondaryButton,
            ...useFormResults.cancelButtonProps,
            onClick: onClose,
          }
        }
        {...props}
        ref={ref}
      >
        <Group variant="vertical">
          {description ? <Text>{description}</Text> : null}
          <Group spacing={size == 'large' ? 'm' : 's'} variant="vertical">
            {banner && <SectionBanner {...banner} />}
            <Text as="p" color="global.neutral.800" textStyle="body3">
              <FormattedMessage id="ar.domain.components.formDialog.required.fields.info.text" />
            </Text>
            <Form {...useFormResults.formProps} size={size} columns={columns} autoFocus={autoFocus}>
              {children}
            </Form>
            {footer ? <>{footer}</> : null}
          </Group>
        </Group>
      </Modal>
    );
  }
);

FormDialog.displayName = 'FormDialog';
