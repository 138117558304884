import { CountryCode, Currency, getStateOrProvinceInfo } from '@melio/international-payments-utils';
import { InternationalAccountDeliveryMethod, Vendor } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useMemo } from 'react';

import { FxVendorAccountFieldsFormFields } from '../steps/fx-vendor-account/fxBankDetailsForm';

export const useGetFxBankDetailsDefaultValue = ({
  vendor,
  currencyCloudInternationalAccount,
}: {
  vendor?: Vendor;
  currencyCloudInternationalAccount?: InternationalAccountDeliveryMethod;
}): Partial<FxVendorAccountFieldsFormFields> => {
  const [enabledInternationalUSDWise] = usePartnerFeature('EnableInternationalUSDWise', false);

  const getAccountNumberAndBic = (internationalDeliveryMethod: InternationalAccountDeliveryMethod) => {
    if (internationalDeliveryMethod.details.identifierType === 'bicSwift') {
      return {
        bic: internationalDeliveryMethod.details.identifierDetails.bicSwift,
        accountNumber: internationalDeliveryMethod.details.identifierDetails.accountNumber,
      };
    } else if (internationalDeliveryMethod.details.identifierType === 'iban') {
      return {
        bic: internationalDeliveryMethod.details.identifierDetails.bicSwift,
        accountNumber: internationalDeliveryMethod.details.identifierDetails.iban,
      };
    }
    return { bic: undefined, accountNumber: undefined };
  };
  return useMemo(() => {
    if (enabledInternationalUSDWise && currencyCloudInternationalAccount) {
      const info = getStateOrProvinceInfo(currencyCloudInternationalAccount.details.address.countryCode as CountryCode);
      const stateCode = info.values?.find((s) => s.name === currencyCloudInternationalAccount.details.address.state);
      return {
        ...getAccountNumberAndBic(currencyCloudInternationalAccount),
        foreignCurrency: 'USD' as Currency,
        legalBusinessName: currencyCloudInternationalAccount.details.companyName,
        fxCountrySelection: currencyCloudInternationalAccount.details.identifierDetails.bankCountryCode as CountryCode,
        accountHolderName: currencyCloudInternationalAccount.details.identifierDetails.bankAccountHolderName,
        line1: currencyCloudInternationalAccount.details.address.line1,
        line2: currencyCloudInternationalAccount.details.address.line2,
        city: currencyCloudInternationalAccount.details.address.city,
        state: stateCode?.key,
        country: currencyCloudInternationalAccount.details.address.countryCode,
        postalCode: currencyCloudInternationalAccount.details.address.postalCode,
      };
    }
    return {
      foreignCurrency: vendor?.currency as Currency | undefined,
    };
  }, [enabledInternationalUSDWise, currencyCloudInternationalAccount, vendor?.currency]);
};
