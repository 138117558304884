import { Flex } from '@chakra-ui/react';
import { Group, Loader, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef, useOneTimeEffect } from '@melio/platform-utils';
import platform from 'platform';
import { useEffect, useRef } from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export const BillFileProcessingScreen = forwardRef<{ numberOfFilesUploaded?: number }, 'div'>(
  ({ numberOfFilesUploaded, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { createTrackHandler } = useAnalytics();
    const messageGroupRef = useRef<HTMLDivElement>(null);

    const track = createTrackHandler<{ os: string }>('ProcessUploadedBill', 'Viewed');

    useOneTimeEffect(
      () => {
        track({ os: platform.os?.toString() });
      },
      () => true,
      [track]
    );

    useEffect(() => {
      const timer = setTimeout(() => {
        if (messageGroupRef.current) {
          messageGroupRef.current.focus();
        }
      }, 2000);

      return () => clearTimeout(timer);
    }, []);

    return (
      <NewSinglePaymentStepLayout
        data-component="AddOcrBillActivity.BillFileProcessingScreen"
        data-testid="add-ocr-bill-activity-bill-file-processing-screen"
        {...props}
        ref={ref}
        isCentered
      >
        <Group variant="vertical" alignItems="center">
          <Loader />
          <Flex
            direction="column"
            alignItems="center"
            textAlign="center"
            aria-live="assertive"
            tabIndex={-1}
            ref={messageGroupRef}
          >
            <Text textStyle="body3Semi">
              {formatMessage('activities.addOcrBill.screens.billFileProcessing.title', {
                numberOfFilesUploaded,
              })}
            </Text>
            <Text textStyle="body3" color="global.neutral.900">
              {formatMessage('activities.addOcrBill.screens.billFileProcessing.description')}
            </Text>
          </Flex>
        </Group>
      </NewSinglePaymentStepLayout>
    );
  }
);

BillFileProcessingScreen.displayName = 'AddOcrBillActivity.BillFileProcessingScreen';
