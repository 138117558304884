import {
  addWildcardToRoutes,
  RouteElement,
  useMelioQueryClient,
  useNavigate,
  withLocalization,
} from '@melio/ar-domain';
import { PaymentRequestFlow } from '@melio/ar-guest-payment';
import { PartnerGroupEnum } from '@melio/partner-tools';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

type ResetAccessTokenFunction = (accessToken?: string, refreshToken?: string | null) => void;

type GuestPaymentRouterProps = {
  WithAnonymousInitialData: React.ComponentType;
  useResetAccessToken: () => ResetAccessTokenFunction;
  partnerName: string;
  partnerGroup?: PartnerGroupEnum | null;
  isNavigateApUsersToDashboardEnabled?: boolean;
};

export const GuestPaymentRouter = withLocalization(
  ({
    partnerName,
    partnerGroup,
    useResetAccessToken,
    WithAnonymousInitialData,
    isNavigateApUsersToDashboardEnabled,
  }: GuestPaymentRouterProps) => {
    const queryClient = useMelioQueryClient();
    const resetAccessToken = useResetAccessToken();
    const onLoggedIn = (...args: Parameters<ResetAccessTokenFunction>) => {
      resetAccessToken(...args);
      void queryClient.invalidateQueries('AccountsApi');
      return new Promise<void>((resolve) => setTimeout(resolve, 1));
    };

    const { Paths, goToUpdatedLink } = useRouter();

    return (
      <Routes>
        <Route element={<WithAnonymousInitialData />}>
          <Route
            path={Paths.PaymentRequest}
            element={
              <RouteElement
                component={PaymentRequestFlow}
                pathToProps={{ id: 'paymentRequestLink' }}
                partnerName={partnerName}
                partnerGroup={partnerGroup}
                onLoggedIn={onLoggedIn}
                isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
                onUpdatedLink={(id: string) => goToUpdatedLink(id, { replace: true })}
              />
            }
          />
        </Route>
      </Routes>
    );
  }
);
GuestPaymentRouter.displayName = 'GuestPaymentRouter';

const useRouter = () => {
  enum Paths {
    PaymentRequest = 'pay/r/:id',
  }

  const navigate = useNavigate<Paths>();
  type Options = Parameters<typeof navigate>[1];

  const goToUpdatedLink = (paymentRequestLink: string, options?: Options) =>
    navigate(Paths.PaymentRequest, { ...options, pathParams: { id: paymentRequestLink } });

  return {
    Paths: addWildcardToRoutes(Paths),
    goToUpdatedLink,
  };
};
