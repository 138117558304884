import type { PillProps, RadioOption } from '@melio/penny';
import {
  DeliveryPreference,
  DeliveryPreferenceType,
  FundingSource,
  UnsupportedDeliveryPreferenceReason,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { useSubscriptionFeature } from '@melio/subscriptions';

import { isDeliveryPreferenceTypeCheck, isDeliveryPreferenceTypeFast } from '../../functions';
import { useRelativeDateText } from '../date/useRelativeDateText';
import { usePaymentSchedulingPreference } from '../payments';

type FundingType = 'ach' | 'credit' | 'debit' | 'flex' | 'paypal-balance';
type BadgeProps = Pick<PillProps, 'status' | 'label'>;

export const getFastestDeliveryPreferenceOption = (deliveryPreferences: DeliveryPreference[]) => {
  const rtp = deliveryPreferences.find((preference) => preference.type === DeliveryPreferenceType.Rtp);
  if (
    rtp?.supported ||
    (rtp && !rtp.supported && rtp.unsupportedReason === UnsupportedDeliveryPreferenceReason.NoFutureDateScheduling)
  ) {
    return rtp;
  }
  const fastAch = deliveryPreferences.find((preference) => preference.type === DeliveryPreferenceType.ExpeditedAch);
  return fastAch;
};

const getKey = (fundingSource: FundingSource): FundingType => {
  if (fundingSource.type === 'bank-account') {
    return 'ach';
  }
  if (fundingSource.type === 'flex-account') {
    return 'flex';
  }

  if (fundingSource.type === 'paypal-balance') {
    return 'paypal-balance';
  }

  if (fundingSource.details.type === 'credit') {
    return 'credit';
  }
  return 'debit';
};

const useDeliveryPreferenceMessages = (fundingSource: FundingSource) => {
  const { formatMessage, formatCurrency, formatDateTimeRange, formatDate } = useMelioIntl();
  const { formatRelativeDate } = useRelativeDateText();
  const { isByDeliveryDate, isByScheduleDate } = usePaymentSchedulingPreference();
  const { isEligible: isAchTPlusTwoEligible } = useSubscriptionFeature({ featureName: 'achTPlusTwo' });
  const [eoyCheckEnabled] = usePartnerFeature('eoyCheck', false);
  const fundingType = getKey(fundingSource);

  const getLabelTextByDeliveryDate = (type: DeliveryPreferenceType, deliveryDate: string) => {
    switch (type) {
      case DeliveryPreferenceType.Rtp:
        return formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.rtp.label`);
      default:
        return formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.label`, {
          date: deliveryDate,
        });
    }
  };

  const getRtpDisabled = ({ supported, unsupportedReason }: DeliveryPreference) => {
    if (supported) {
      return null;
    }

    switch (unsupportedReason) {
      case UnsupportedDeliveryPreferenceReason.NoFutureDateScheduling: {
        return {
          isDisabled: true,
          message: formatMessage('widgets.deliveryDate.deliveryPreferenceForm.rtp.disabledMessage.noFutureRtp'),
        };
      }
      case UnsupportedDeliveryPreferenceReason.OutsideManualReviewHours: {
        return {
          isDisabled: true,
          message: formatMessage('widgets.deliveryDate.deliveryPreferenceForm.rtp.disabledMessage.outsideWorkingHours'),
        };
      }
      case UnsupportedDeliveryPreferenceReason.RoutingNumberNotCovered: {
        return {
          isDisabled: true,
          message: formatMessage(
            'widgets.deliveryDate.deliveryPreferenceForm.rtp.disabledMessage.routingNumberNotCovered'
          ),
        };
      }
      case UnsupportedDeliveryPreferenceReason.PaymentRequiresApproval: {
        return {
          isDisabled: true,
          message: formatMessage(
            'widgets.deliveryDate.deliveryPreferenceForm.rtp.disabledMessage.paymentRequiresApproval'
          ),
        };
      }
    }
    return null;
  };

  return {
    getDescription: ({
      type,
      minDeliveryDate,
      maxDeliveryDate,
      effectiveScheduleDate,
      fee,
    }: DeliveryPreference): RadioOption['descriptionProps'] => {
      const deliveryDates = formatDateTimeRange(minDeliveryDate, maxDeliveryDate, { dateStyle: 'medium' });
      const debitDate = formatDate(effectiveScheduleDate, { dateStyle: 'medium' });

      if (isByScheduleDate) {
        if (fee?.amount) {
          return {
            label: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.${type}.descriptionWithFee`, {
              fee: formatCurrency(fee.amount),
              date: deliveryDates,
            }),
          };
        } else {
          return {
            label: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.${type}.description`, {
              date: deliveryDates,
            }),
          };
        }
      } else {
        if (fee?.amount) {
          return {
            label: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.descriptionWithFee`, {
              fee: formatCurrency(fee.amount),
              date: debitDate,
            }),
          };
        } else {
          return {
            label: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.description`, {
              date: debitDate,
            }),
          };
        }
      }
    },

    getMainLabel: (
      { type, minDeliveryDate, totalBusinessDays }: DeliveryPreference,
      shouldHaveBadge: boolean
    ): NonNullable<RadioOption['mainLabelProps']> => {
      const badgeLabel = formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.${type}.badge`);
      const badge = badgeLabel && shouldHaveBadge ? { status: 'brand', label: badgeLabel } : undefined;
      const deliveryDate = formatRelativeDate({
        date: minDeliveryDate,
        options: {
          weekday: 'short',
          month: 'short',
          year: 'numeric',
          day: 'numeric',
        },
      });
      const tooltipProps =
        eoyCheckEnabled && type === 'check'
          ? {
              variant: 'warning',
              content: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.check.tooltipEOYText`),
            }
          : undefined;
      const isCheck = isDeliveryPreferenceTypeCheck(type);
      const isFast = isDeliveryPreferenceTypeFast(type);
      const shouldShowSecondaryLabel = isByDeliveryDate && isCheck;

      let label = '';

      if (isAchTPlusTwoEligible && type === DeliveryPreferenceType.Ach && fundingType === 'ach') {
        label = formatMessage('widgets.deliveryDate.deliveryPreferenceForm.ach.from.ach.label.tPlusTwo', {
          totalBusinessDays,
        });
      } else {
        label = isByScheduleDate
          ? formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.${type}.from.${fundingType}.label`)
          : getLabelTextByDeliveryDate(type, deliveryDate);
      }

      const secondaryLabel = formatMessage(
        isFast
          ? `widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.expressCheck`
          : `widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.check`,
        { date: deliveryDate }
      );
      return {
        label,
        pillProps: badge as BadgeProps,
        tooltipProps,
        ...(shouldShowSecondaryLabel ? { secondaryLabel } : {}),
      };
    },

    getDisabled: (deliveryPreference: DeliveryPreference) => {
      if (deliveryPreference.type === 'rtp') {
        return getRtpDisabled(deliveryPreference);
      }

      return null;
    },
  };
};

export const useDeliveryPreferenceOption = (fundingSource: FundingSource) => {
  const { getDescription, getMainLabel, getDisabled } = useDeliveryPreferenceMessages(fundingSource);
  const getOption = (d: DeliveryPreference, fastestOption?: DeliveryPreference) => {
    const disabled = getDisabled(d);
    const shouldHaveBadge = !!(fastestOption && d.type === fastestOption.type);
    const option: RadioOption = {
      value: d.type as string,
      descriptionProps: getDescription(d),
      mainLabelProps: getMainLabel(d, shouldHaveBadge),
    };
    if (disabled) {
      option.disabled = disabled;
    }
    return option;
  };

  return { getOption };
};
