import { CatalogItem } from '@melio/ar-domain';
import { Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import {
  CatalogItemsEmptyState,
  CatalogItemsTable,
  CatalogItemsTableToolbar,
  CatalogItemsZeroState,
} from '../components';

export type CatalogItemsTableScreenProps = {
  searchTerm?: string;
  setSearchTerm: (value: string) => unknown;
  isLoading?: boolean;
  catalogItems: CatalogItem[];
  onEditItem: (itemId: string) => unknown;
  onDeleteItem: (itemId: string) => unknown;
  onAddItem: VoidFunction;
  onImportCatalogItems: VoidFunction;
};

export const CatalogItemsTableScreen = forwardRef<CatalogItemsTableScreenProps>(
  (
    {
      setSearchTerm,
      onImportCatalogItems,
      searchTerm = '',
      catalogItems,
      isLoading = false,
      onEditItem,
      onDeleteItem,
      onAddItem,
      ...props
    },
    ref
  ) => {
    const { track } = useAnalytics();
    const isZeroState = !isLoading && catalogItems.length === 0 && searchTerm.length === 0;
    const isEmptyState = !isLoading && catalogItems.length === 0 && searchTerm.length > 0;

    if (isZeroState) {
      return <CatalogItemsZeroState onAddItem={onAddItem} onImportCatalogItems={onImportCatalogItems} />;
    }

    return (
      <Group variant="vertical" {...props} ref={ref}>
        <CatalogItemsTableToolbar
          onImportCatalogItems={() => {
            track('Settings', 'Click', {
              ProductName: 'ar',
              PageName: 'invoice-items',
              TabName: 'items',
              Flow: 'manage-invoice-items',
              Intent: 'upload-catalog-items',
              Cta: 'upload',
            });
            onImportCatalogItems();
          }}
          onAddItem={onAddItem}
          isLoading={isLoading}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        {isEmptyState ? (
          <CatalogItemsEmptyState searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        ) : (
          <CatalogItemsTable
            catalogItems={catalogItems}
            onDeleteItem={onDeleteItem}
            onEditItem={onEditItem}
            isLoading={isLoading}
          />
        )}
      </Group>
    );
  }
);
