import { merge } from 'lodash';

import { SettingsCardIdEnum } from '../cl/components/SettingsCard/SettingsCard.component';
import { SettingsGroupEnum } from '../partnersConfig.types';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';
import { standardProviderConfigBase } from './standardProvider.config';

export const gustoByEnvConfig: PartnerConfigByEnv = merge({}, standardProviderConfigBase, {
  default: {
    translationsSrc: 'https://platform-static.meliopayments.com/assets/gusto/messages.json',
    settings: {
      embeddedExperienceInsideScroll: true,
      showFooterInEmbeddedExperience: true,
      hideHeaderNavigation: true,
      showRetryButtonOnFieldValidation: false,
      settings: {
        options: [
          {
            type: SettingsGroupEnum.COMPANY,
            items: [
              SettingsCardIdEnum.ProfileSettings,
              SettingsCardIdEnum.CompanySettings,
              SettingsCardIdEnum.CollaboratorsSetting,
              SettingsCardIdEnum.NotificationPreferences,
              SettingsCardIdEnum.AccountingSoftwareSync,
            ],
          },
          {
            type: SettingsGroupEnum.PAYMENTS,
            items: [
              SettingsCardIdEnum.PaymentMethodsSetting,
              SettingsCardIdEnum.SubscriptionPlansSettings,
              SettingsCardIdEnum.BillingSetting,
            ],
          },
          {
            type: SettingsGroupEnum.SUPPORT,
            items: [SettingsCardIdEnum.SupportSetting],
          },
        ],
      },
      supportEmail: 'support@gustobillpay.melio.com',
      accountingSoftwareSuccessReturnUrl: 'https://app.gusto-demo.com/gusto-money/bill-pay',
      accountingSoftwareErrorReturnUrl: 'https://app.gusto-demo.com/gusto-money/bill-pay',
    },
    zendesk: {
      useNewWidgetApi: true,
    },
    services: {
      zendeskKey: 'a12b65fb-6c77-4e8f-928e-db79662ce203',
    },
    links: {
      'widgets.companySettings.header.sectionBanner.linkHref': 'https://app.gusto.com/help/contact',
    },
  },
  dev: {},
  prod: {
    settings: {
      accountingSoftwareSuccessReturnUrl: 'https://app.gusto.com/gusto-money/bill-pay',
      accountingSoftwareErrorReturnUrl: 'https://app.gusto.com/gusto-money/bill-pay',
    },
  },
});

export const gustoConfig = extendDefaultConfig(gustoByEnvConfig);
