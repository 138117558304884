import { PaymentStatusEnum, usePayments } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

export const useShowPaymentsMightBeAffectedBanner = ({ vendorId }: { vendorId?: string }) => {
  const {
    settings: {
      vendor: { warnEditingVendorWithFuturePayments },
    },
  } = useConfig();

  const { data } = usePayments({
    params: {
      search: {
        'vendor.id': vendorId,
        'payment.status': [PaymentStatusEnum.Scheduled],
      },
      limit: 1,
    },
    enabled: !!vendorId,
  });

  const vendorMightHaveFuturePayments = !data || data.length > 0;

  return {
    showPaymentsMightBeAffectedBanner: warnEditingVendorWithFuturePayments && vendorMightHaveFuturePayments,
  };
};
