import { useTaxPayerInfoForm, W9SubmissionMethod } from '@melio/ap-widgets';
import { useTaxPayerInfo as useTaxPayerInfoPdf, useTaxPayerInfoDetailsRequest, useVendors } from '@melio/platform-api';
import { useMonitoring } from '@melio/platform-monitoring';
import { useState } from 'react';

import { MonitoredAction } from '../../../../../../monitoring';

type CreateVendorReturnType = ReturnType<ReturnType<typeof useVendors>['create']>;

export const useTaxPayerInfo = () => {
  const { create, isMutating: isTaxPayerInfoDetailsRequestMutating } = useTaxPayerInfoDetailsRequest({
    vendorId: '',
  });

  const { startAction, endAction } = useMonitoring<MonitoredAction>();

  const { create: uploadW9File, isMutating: isTaxPayerInfoPdfMutating } = useTaxPayerInfoPdf({
    vendorId: '',
    taxpayerInfoId: '',
    enabled: false,
  });

  const [isContractor, setIsContractor] = useState(false);
  const [w9File, setW9File] = useState<File | null>(null);

  const taxPayerInfoFormProps = useTaxPayerInfoForm({});

  const selectedW9SubmissionMethod = taxPayerInfoFormProps.watch('W9SubmissionMethod');
  const shouldSendRequestToContractor = isContractor && selectedW9SubmissionMethod === W9SubmissionMethod.Email;

  const handleFileUpload = (file: File) => {
    setW9File(file);
  };

  const handleDeleteInvalidFile = () => {
    setW9File(null);
  };

  const createRequest = async (vendor: Awaited<CreateVendorReturnType>) => {
    const email = vendor.contact.email;
    if (email) {
      await create({ vendorId: vendor.id, email });
    }
  };

  const uploadPdf = async (vendor: Awaited<CreateVendorReturnType>) => {
    if (w9File) {
      startAction('w9_upload_at_vendor_add');
      await uploadW9File({ file: w9File, vendorId: vendor.id });
      endAction('w9_upload_at_vendor_add');
    }
  };

  const submitTaxPayerInfoForm = async (vendor: Awaited<CreateVendorReturnType>) => {
    if (!isContractor) {
      return;
    }

    if (selectedW9SubmissionMethod === W9SubmissionMethod.Email) {
      return createRequest(vendor);
    }

    if (selectedW9SubmissionMethod === W9SubmissionMethod.Pdf) {
      return uploadPdf(vendor);
    }
  };

  return {
    shouldSendRequestToContractor,
    taxPayerInfoFormProps,
    setIsContractor,
    isContractor,
    submitTaxPayerInfoForm,
    isMutating: isTaxPayerInfoDetailsRequestMutating || isTaxPayerInfoPdfMutating,
    handleFileUpload,
    handleDeleteInvalidFile,
    selectedW9SubmissionMethod,
    w9File,
  };
};
