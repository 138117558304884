import { Route, Routes } from 'react-router-dom';

import {
  AccountingFirmClientMandatoryPlanRestriction,
  AccountingFirmClientMandatoryPlanRestrictionProps,
  ConfirmDowngradeOwnerScreen,
  DowngradeFlowNonOwnerScreen,
} from '../ui';

type SubscriptionDowngradeRouteProps = AccountingFirmClientMandatoryPlanRestrictionProps;

export const SubscriptionDowngradeRoute = ({
  goToAssignClientPlan,
  switchOrganization,
}: SubscriptionDowngradeRouteProps) => (
  <Routes>
    <Route path="/confirm-downgrade" element={<ConfirmDowngradeOwnerScreen />} />
    <Route path="/action-required" element={<DowngradeFlowNonOwnerScreen />} />
    <Route
      path="/client-basic-plan"
      element={
        <AccountingFirmClientMandatoryPlanRestriction
          goToAssignClientPlan={goToAssignClientPlan}
          switchOrganization={switchOrganization}
        />
      }
    />
  </Routes>
);
