import { Icon, SelectOption } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BusinessResultItem, useAccount, useBusinesses, useVendor, useVendors, Vendor } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useDebounceCallback } from '@melio/platform-utils';
import { identity } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { BUSINESS_SEARCH_DEBOUNCE } from '../../utils';
import { useVendorSelectFilterQuery } from './useVendorSelectFilterQuery';

type UseVendorSelectOptionsProps = { eventContextName?: string; isViewMode?: boolean; selectedOptionValue?: string };

export const useVendorSelectOptions = ({
  eventContextName,
  isViewMode,
  selectedOptionValue,
}: UseVendorSelectOptionsProps = {}) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { settings } = useConfig();

  const { data: account } = useAccount({ id: 'me' });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const params = useVendorSelectFilterQuery({ searchTerm, account, isViewMode });
  const [isExternalVendorLogoEnabled] = useDevFeature<boolean>(FeatureFlags.ExternalVendorLogoEnabled, false);

  const [vendors, setVendors] = useState<Vendor[]>();
  const [vendorsOptions, setVendorsOptions] = useState<SelectOption[]>();
  const [businessesOptions, setBusinessesOptions] = useState<SelectOption[]>();

  const shouldLoadSelectedVendor = useMemo(
    () => !!selectedOptionValue && !vendors?.some((vendor) => vendor.id === selectedOptionValue),
    [selectedOptionValue, vendors]
  );
  const { data: selectedVendor, isFetched: isSelectedVendorFetched } = useVendor({
    id: selectedOptionValue,
    enabled: shouldLoadSelectedVendor,
  });

  const {
    data: unfilteredMyVendors,
    isFetched: isMyVendorsFetched,
    isMutating: isCreatingVendor,
    create: createVendor,
  } = useVendors({
    params,
    enabled: !!account,
  });

  const myVendors = useMemo(
    () =>
      unfilteredMyVendors?.filter((vendor) => {
        const query = (searchTerm || '').toLowerCase();
        const vendorName = vendor.name.toLowerCase();
        const vendorNickname = vendor.nickname?.toLowerCase();
        const vendorAccountNumber = vendor.accountNumber?.toLowerCase();

        return vendorName.includes(query) || vendorNickname?.includes(query) || vendorAccountNumber?.includes(query);
      }),
    [unfilteredMyVendors, searchTerm]
  );

  const [isInitialFetchDone, setIsInitialFetchDone] = useState<boolean>(!!myVendors);

  const [isSearchBusinessesInDirectoriesSupported] = useDevFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );
  const { data: directoryBusinesses, isLoading: isLoadingBusinesses } = useBusinesses({
    params: { searchTerm },
    enabled: isSearchBusinessesInDirectoriesSupported && searchTerm.length > 2,
  });

  const handleSearch = useDebounceCallback((query: string) => {
    setSearchTerm(query);
  }, BUSINESS_SEARCH_DEBOUNCE);

  useEffect(() => {
    const vendorToOption = (vendor: Vendor) => {
      const description = [vendor.nickname, vendor.accountNumber].filter(identity).join(' | ');
      const externalVendorLogoUrl = isExternalVendorLogoEnabled ? vendor.externalLogoUrl : undefined;

      const badgeProps =
        vendor.isManaged && settings.showCheckMarkIconForManagedVendor
          ? {
              badge: <Icon type="verified" size="extra-small" color="brand" />,
            }
          : undefined;

      return {
        label: vendor.name,
        value: vendor.id,
        description,
        avatarProps: { name: vendor.name, src: externalVendorLogoUrl, ...badgeProps },
      };
    };

    if (myVendors && (!shouldLoadSelectedVendor || isSelectedVendorFetched)) {
      const mergedVendors = myVendors ? [...myVendors] : [];
      if (selectedVendor && !mergedVendors.some((vendor) => vendor.id === selectedVendor.id)) {
        mergedVendors.push(selectedVendor);
      }

      setVendorsOptions(mergedVendors.map(vendorToOption));
      setVendors(mergedVendors);
      setIsInitialFetchDone(true); // TODO: we can remove it after fixing https://meliorisk.atlassian.net/browse/ME-43953
    }
  }, [
    shouldLoadSelectedVendor,
    isSelectedVendorFetched,
    selectedVendor,
    myVendors,
    formatMessage,
    isExternalVendorLogoEnabled,
    settings.showCheckMarkIconForManagedVendor,
  ]);

  useEffect(() => {
    const _businessesOptions =
      directoryBusinesses?.map(({ business }) => ({
        label: business.name,
        value: business.id,
        avatarProps: { name: business.name, src: business.logoUri },
      })) || [];
    setBusinessesOptions(_businessesOptions);
  }, [directoryBusinesses, formatMessage]);

  const isMyVendor = (selectedOptionValue: string) => vendors?.some((vendor) => vendor.id === selectedOptionValue);

  const getBusinessById = (id: string) => directoryBusinesses?.find(({ business }) => business.id === id);

  const sections = [
    {
      metadata: { label: formatMessage('form.vendorSelect.myVendors.title') },
      options: vendorsOptions ?? [],
    },
    {
      metadata: {
        label: formatMessage('form.vendorSelect.businesses.title'),
        icon: settings.showCheckMarkIconForManagedVendor ? ('verified' as const) : undefined,
      },
      options: businessesOptions ?? [],
    },
  ];

  const getHasUnmanagedLocalVendorWithTheSameName = (name: string) =>
    vendors?.some(
      (localVendor) => !localVendor.isManaged && localVendor.name.toLocaleLowerCase() === name.toLocaleLowerCase()
    );

  type GetSelectedItemAnalyticsDataParams =
    | { vendorType: 'directory'; selectedBusiness: BusinessResultItem }
    | { vendorType: 'local'; vendorId: Vendor['id'] }
    | { vendorType: 'new-local' };
  const getSelectedItemAnalyticsData = (params: GetSelectedItemAnalyticsDataParams) => {
    let itemPosition: number | null;
    let cta: string;
    if (params.vendorType === 'new-local') {
      itemPosition = null;
      cta = 'add-new';
    } else {
      const value = params.vendorType === 'local' ? params.vendorId : params.selectedBusiness.business.id;
      itemPosition = sections.flatMap(({ options }) => options).findIndex((option) => option.value === value) + 1;
      cta = params.vendorType === 'local' ? params.vendorId : params.selectedBusiness.business.name;
    }
    const fiservDirectoryItems = (directoryBusinesses || []).filter((option) => option.directoryName === 'Fiserv');
    return {
      SearchValue: searchTerm,
      VendorType: params.vendorType,
      CountResults: (vendorsOptions || []).length + (directoryBusinesses || []).length,
      BillersCount: fiservDirectoryItems.length,
      LocalVendorCount: (vendorsOptions || []).length,
      ItemPosition: itemPosition,
      Cta: cta,
    };
  };
  const trackView = () => {
    if (eventContextName) {
      track(eventContextName, 'View', { intent: 'choose-a-vendor' });
    }
  };
  const trackClickOption = (params: GetSelectedItemAnalyticsDataParams) => {
    if (eventContextName) {
      // the same for the rest. move to fucntion
      track(eventContextName, 'Click', {
        ...getSelectedItemAnalyticsData(params),
        intent: 'choose-a-vendor',
      });
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  // TODO: we can replace the whole use effect with "isLoading="!isMyVendorsFetched || isLoadingBusinesses" after fixing https://meliorisk.atlassian.net/browse/ME-43953
  useEffect(() => {
    // We can turn off `isLoading` only after the options are ready.
    // don't move it out of the use effect  -
    // we need this side effect to prevent displaying the vendor id instead of vendor label (isLoading become false too eralier). we can remove it after merging https://github.com/melio/penny/pull/3289/files
    setIsLoading(
      !isMyVendorsFetched ||
        !vendorsOptions ||
        isLoadingBusinesses ||
        !businessesOptions ||
        !isInitialFetchDone ||
        (shouldLoadSelectedVendor && !isSelectedVendorFetched)
    );
  }, [
    businessesOptions,
    isLoadingBusinesses,
    isMyVendorsFetched,
    vendorsOptions,
    isInitialFetchDone,
    shouldLoadSelectedVendor,
    isSelectedVendorFetched,
  ]);

  // https://github.com/melio/platform-app/pull/10559
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  useEffect(() => {
    if (!isLoading && isFirstLoading) {
      setIsFirstLoading(false);
    }
  }, [isLoading, isFirstLoading]);

  // We can remove this when Penny implements the ability to set value and isLoading in any order
  const [isUpdatingVendorsInProgress, setIsUpdatingVendorsInProgress] = useState(false);
  useEffect(() => {
    if (isUpdatingVendorsInProgress && !isLoading) {
      setIsUpdatingVendorsInProgress(false);
    }
  }, [isUpdatingVendorsInProgress, isLoading]);

  const isSelectedVendorPresented = (shouldLoadSelectedVendor && !!selectedVendor) || !shouldLoadSelectedVendor;
  const areAllOptionsPresented = !!myVendors?.length && isSelectedVendorPresented && !!directoryBusinesses?.length;

  const isDisabled = !isInitialFetchDone || isCreatingVendor || (shouldLoadSelectedVendor && !isSelectedVendorFetched);

  return {
    getSelectedItemAnalyticsData,
    trackView,
    trackClickOption,
    selectedVendor,
    isLoading,
    isFirstLoading,
    isDisabled,
    sections,
    createVendor,
    isCreatingVendor,
    handleSearch,
    areAllOptionsPresented,
    isMyVendor,
    getBusinessById,
    canSearchBusinesses: isSearchBusinessesInDirectoriesSupported,
    isSelectedOptionManaged: selectedVendor?.isManaged && settings.showCheckMarkIconForManagedVendor,
    getHasUnmanagedLocalVendorWithTheSameName,
    isUpdatingVendorsInProgress,
    setIsUpdatingVendorsInProgress,
  };
};
