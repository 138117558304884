import { ExternalLayout } from '@melio/penny';
import {
  BankAccountDeliveryMethod,
  useVirtualCardAcceptance,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Navigate, Route, Routes } from 'react-router-dom';

import { BaseErrorScreen, BaseSuccessScreen } from '../base-screens';
import { SuvcDetailsActivity } from './SuvcDetails.activity';
import { SuvcExpiredActivity } from './SuvcExpired.activity';

const trackSuccessScreen = { name: 'suvc-acceptance-error' };
const trackBaseError = { name: 'suvc-acceptance-error' };

type SuvcAcceptanceActivityType = {
  isTokenizedCardDetailsEnabledForCurrentCard: boolean;
  virtualCardId: string;
};

export const SuvcAcceptanceActivity: React.VFC<SuvcAcceptanceActivityType> = ({
  isTokenizedCardDetailsEnabledForCurrentCard,
  virtualCardId,
}) => {
  const { isLoading, data: suvcData } = useVirtualCardAcceptance(virtualCardId);
  const { formatMessage, formatCurrency } = useMelioIntl();

  if (isLoading) {
    return <ExternalLayout isLoading />;
  }

  if (!suvcData) {
    return <BaseErrorScreen trackBaseError={trackBaseError} description={formatMessage('vex.generalServerError')} />;
  }

  const { deliveryMethod, payment, organization, ...virtualCard } = suvcData.data;

  return (
    <Routes>
      <Route index element={<SuvcAcceptanceNavigator virtualCardId={virtualCardId} />} />
      <Route
        path="accept"
        element={
          <SuvcDetailsActivity
            isTokenizedCardDetailsEnabledForCurrentCard={isTokenizedCardDetailsEnabledForCurrentCard}
            virtualCardId={virtualCardId}
            suvcData={suvcData}
          />
        }
      />
      <Route path="card-expired" element={<SuvcExpiredActivity virtualCardId={virtualCardId} />} />
      <Route
        path="card-cleared"
        element={
          <BaseSuccessScreen
            data-testid="suvc-payment-proceed-screen"
            title={formatMessage('vex.screen.suvcAcceptance.paymentCleared.title')}
            description={formatMessage('vex.screen.suvcAcceptance.paymentCleared.description', {
              amount: formatCurrency(Number(suvcData.data.amount)),
              payorCompanyName: organization.companyName,
              clearedDate: virtualCard.clearedDate?.toDateString(),
            })}
            paymentDetailsTitle={formatMessage('vex.screen.suvcAcceptance.paymentCleared.paymentDetailsTitle')}
            deliveryMethod={deliveryMethod as VirtualCardAccountDeliveryMethod}
            estimatedDelivery={payment.deliveryEta as Date}
            maxEstimatedDelivery={payment.maxDeliveryEta as Date}
            trackSuccessScreen={trackSuccessScreen}
          />
        }
      />
      <Route
        path="new-card-already-issued"
        element={
          <BaseSuccessScreen
            data-testid="suvc-card-already-issued-screen"
            title={formatMessage('vex.screen.suvcAcceptance.newCardAlreadyIssued.title')}
            description={formatMessage('vex.screen.suvcAcceptance.newCardAlreadyIssued.description', {
              amount: formatCurrency(Number(virtualCard.amount)),
              payorCompanyName: organization.companyName,
            })}
            paymentDetailsTitle={formatMessage('vex.screen.suvcAcceptance.paymentCleared.paymentDetailsTitle')}
            deliveryMethod={deliveryMethod as VirtualCardAccountDeliveryMethod}
            estimatedDelivery={payment.deliveryEta as Date}
            maxEstimatedDelivery={payment.maxDeliveryEta as Date}
            trackSuccessScreen={trackSuccessScreen}
          />
        }
      />
      <Route
        path="shifted-to-bank-account"
        element={
          <BaseSuccessScreen
            data-testid="suvc-payment-shifted-screen"
            title={formatMessage('vex.screen.suvcAcceptance.shiftAlreadyRequested.title')}
            description={formatMessage('vex.screen.suvcAcceptance.shiftAlreadyRequested.description', {
              paymentEta: payment.deliveryEta?.toDateString(),
            })}
            paymentDetailsTitle={formatMessage('vex.screen.suvcAcceptance.paymentCleared.paymentDetailsTitle')}
            deliveryMethod={deliveryMethod as BankAccountDeliveryMethod}
            estimatedDelivery={payment.deliveryEta as Date}
            maxEstimatedDelivery={payment.maxDeliveryEta as Date}
            trackSuccessScreen={trackSuccessScreen}
          />
        }
      />
      <Route
        path="card-canceled"
        element={
          <BaseErrorScreen
            data-testid="suvc-card-canceled-screen"
            trackBaseError={trackBaseError}
            description={formatMessage('vex.error.suvc.cardCanceled.description')}
            title={formatMessage('vex.error.suvc.cardCanceled.title')}
          />
        }
      />
    </Routes>
  );
};

const SuvcAcceptanceNavigator: React.VFC<{ virtualCardId: string }> = ({ virtualCardId }) => {
  const { data: suvcData } = useVirtualCardAcceptance(virtualCardId);
  if (!suvcData) {
    return <Navigate to="./error" />;
  }

  const { status, isShiftedToBankAccount, isLatestCard, isClearedByVendor } = suvcData.data;
  if (isClearedByVendor) {
    return <Navigate to="./card-cleared" />;
  }

  if (isShiftedToBankAccount) {
    return <Navigate to="./shifted-to-bank-account" />;
  }

  if (!isLatestCard) {
    return <Navigate to="./new-card-already-issued" />;
  }

  switch (status) {
    case 'active':
      return <Navigate to="./accept" />;
    case 'expired':
      return <Navigate to="./card-expired" />;
    case 'canceled':
      return <Navigate to="./card-canceled" />;
    default:
      return <Navigate to="./error" />;
  }
};
