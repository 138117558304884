import { Box } from '@chakra-ui/react';
import {
  Container,
  Group,
  GroupProps,
  Layout,
  LayoutProps,
  StatusIconSolid,
  Text,
  Tooltip,
  Tracker,
  useBreakpointValue,
} from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ReactElement } from 'react';

import { useOnboardingStepsTracker } from '../components';
import { ScreenId } from '../utils';

export type OnboardingLayoutProps = Pick<
  LayoutProps,
  'isLoading' | 'paddingContent' | 'backgroundColor' | 'children'
> & {
  headerContent: string;
  isStickyFooter: boolean;
  footerContent?: ReactElement;
  showTracker?: boolean;
  spacing?: GroupProps['spacing'];
  screenId?: ScreenId;
};

export const OnboardingLayout = forwardRef<OnboardingLayoutProps>(
  (
    {
      headerContent,
      footerContent,
      isLoading,
      backgroundColor,
      spacing,
      children,
      isStickyFooter,
      screenId,
      showTracker = true,
      ...props
    },
    ref
  ) => {
    const { steps, currentStepIndex } = useOnboardingStepsTracker({ screenId });

    const isActive = (index: number) =>
      Boolean(
        (currentStepIndex && index === currentStepIndex) || (currentStepIndex === 0 && index === currentStepIndex)
      );
    const isCompleted = (index: number) => Boolean(currentStepIndex && index < currentStepIndex);

    return (
      <Box height="100%">
        <Layout
          backgroundColor={backgroundColor}
          footer={
            footerContent && {
              content: footerContent,
              isSticky: true,
            }
          }
          isLoading={isLoading}
          ref={ref}
          {...props}
        >
          <Group variant="vertical" spacing={useBreakpointValue({ xs: 'm', s: 'l', xl: 'xl' })}>
            <Container>
              <Text as="h1" textStyle="heading1Semi">
                {headerContent}
              </Text>
            </Container>
            <Group variant="horizontal" width="full" spacing="xxxl">
              {showTracker && (
                <Container width="fit-content" paddingRight="xxxl">
                  <Tracker width="fit-content" variant="vertical" data-testid="onboarding-tracker">
                    {steps.map((step, index) => (
                      <Tracker.Step
                        key={`tracker-step-${index}`}
                        data-testid={`tracker-step-${step.title}`}
                        isActive={isActive(index)}
                        isCompleted={isCompleted(index)}
                      >
                        <Tracker.StepTitle label={step.title} />
                        {step.invalid && (
                          <Tooltip content={step.invalid}>
                            <StatusIconSolid variant="warning" size="small" data-testid="icon-indicator" />
                          </Tooltip>
                        )}
                      </Tracker.Step>
                    ))}
                  </Tracker>{' '}
                </Container>
              )}
              {children}
            </Group>
          </Group>
        </Layout>
      </Box>
    );
  }
);

OnboardingLayout.displayName = 'OnboardingLayout';
