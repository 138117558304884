import { CreateInternationalAccountDeliveryMethod, InternationalAccountCreateParams } from '@melio/platform-api';
import { isEmpty, mapValues } from 'lodash';

import { FxVendorAccountFieldsFormFields } from './fxBankDetailsForm';

const transformEmptyStringsToUndefined = <T extends object>(obj: T): T =>
  mapValues(obj, (value) => (isEmpty(value) ? undefined : value)) as T;

export const createFxDmFormToRequest = (
  data: FxVendorAccountFieldsFormFields,
  payeeAccountType: string
): CreateInternationalAccountDeliveryMethod => ({
  type: 'international-account',
  details: {
    currency: data.foreignCurrency,
    companyName: data.legalBusinessName,
    address: {
      city: data.city,
      countryCode: data.country,
      line1: data.line1,
      postalCode: data.postalCode,
      state: data.state,
    },
    identifierDetails: {
      requiredFields: transformEmptyStringsToUndefined({
        accountHolderName: data.accountHolderName,
        accountNumber: data.accountNumber,
        accountType: data.accountType,
        bankCode: data.bankCode,
        branchCode: data.branchCode,
        bic: data.bic,
        bsbCode: data.bsbCode,
        clabe: data.clabe,
        email: data.email,
        iban: data.iban,
        cnpj: data.cnpj,
        ifscCode: data.ifscCode,
        institutionNumber: data.institutionNumber,
        sortCode: data.sortCode,
        transitNumber: data.transitNumber,
      }),
      payeeAccountType,
      bankCountryCode: data.fxCountrySelection,
    },
  } as InternationalAccountCreateParams,
});
