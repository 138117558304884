import { ServiceChargeCatalog, useMelioIntl } from '@melio/ar-domain';
import { Table, TableColumnDef, useTable } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useMemo } from 'react';

import { ServiceChargeActionCell } from './ServiceChargeActionCell';
import { ServiceChargesTableListItem } from './ServiceChargesTableListItem.mobile';

export type ServiceChargesTableProps = {
  isLoading: boolean;
  serviceCharges: ServiceChargeCatalog[];
  onEdit: (serviceChargeId: string) => unknown;
  onDelete: (serviceChargeId: string) => unknown;
};

export const ServiceChargesTable = forwardRef<ServiceChargesTableProps>(
  ({ serviceCharges, onEdit, onDelete, isLoading, ...props }, ref) => {
    const { formatMessage, formatCurrency, formatPercents } = useMelioIntl();
    const mobileRowRenderer = useCallback(
      (row: ServiceChargeCatalog) => (
        <ServiceChargesTableListItem serviceCharge={row} onEdit={onEdit} onDelete={onDelete} />
      ),
      [onDelete, onEdit]
    );

    const columns = useMemo<TableColumnDef<ServiceChargeCatalog>[]>(
      () => [
        {
          id: 'name',
          size: 'l',
          header: formatMessage(
            'ar.settings.activities.serviceChagresTable.components.serviceChagresTable.columns.name.header'
          ),
          cell: ({ row }) => <Table.Cell data-testid="service-charge-name">{row.name}</Table.Cell>,
        },
        {
          id: 'amount',
          size: 's',
          header: formatMessage(
            'ar.settings.activities.serviceChagresTable.components.serviceChagresTable.columns.amount.header'
          ),
          cell: ({ row }) => (
            <Table.Cell data-testid="service-charge-amount">
              {row.type === 'percentage' ? formatPercents(row.value) : formatCurrency(row.value)}
            </Table.Cell>
          ),
        },
        {
          id: 'actions',
          cell: ({ row }) => <ServiceChargeActionCell serviceCharge={row} onEdit={onEdit} onDelete={onDelete} />,
          size: 'xs',
        },
      ],
      [formatPercents, formatMessage, formatCurrency, onEdit, onDelete]
    );

    const table = useTable<ServiceChargeCatalog>({
      data: serviceCharges,
      isLoading,
      columns,
      headerVariant: 'dark',
      mobileRowRenderer,
      hideHeaderWhileLoading: true,
    });
    return <Table {...table} {...props} ref={ref} />;
  }
);
ServiceChargesTable.displayName = 'ServiceChargesTable';
