import type { PartnerIcons } from '../../../partnersConfig.types';

export const FiservIcons: PartnerIcons = {
  Favicon: 'https://platform-static.meliopayments.com/assets/fiserv/firstFinancialFavIcon.svg',
  MelioIcon: {
    small: 'https://platform-static.meliopayments.com/assets/fiserv/melio.icon.svg',
  },
  LogoIcon: {
    small: 'https://platform-static.meliopayments.com/assets/fiserv/firstFinancial.icon.svg',
  },
  ErrorIcon: {
    small: 'https://platform-static.meliopayments.com/assets/fiserv/error.icon.svg',
  },
  EmptyBillsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/fiserv/emptyBills.icon.svg',
  },
  EmptyVendorsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/fiserv/emptyVendors.icon.svg',
  },
  NoBillsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/fiserv/noBillsFound.icon.svg',
  },
  NoVendorsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/fiserv/noVendorsFound.icon.svg',
  },
  VerifyEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/fiserv/verifyEmail.icon.svg',
  },
  TaxSummaryIcon: {
    small: 'https://platform-static.meliopayments.com/assets/default/taxSummary.icon.svg',
  },
  ExpiredSessionIcon: {
    small: 'https://platform-static.meliopayments.com/assets/fiserv/expiredSession.icon.svg',
  },
  BackToPartnerIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  StartNewExperienceIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  TryDifferentEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  AddBillIcon: {
    small: 'https://platform-static.meliopayments.com/assets/fiserv/addBill.icon.svg',
  },
};
