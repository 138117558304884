import {
  useGuestPayorFundingSource,
  useGuestPayorPayment,
  useGuestPayorPaymentRequestDetails,
  useMonitoring,
} from '@melio/ar-domain';
import { useAnalyticsView } from '@melio/platform-analytics';
import { convertDateToStringRepresentation, forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import {
  CreatingPaymentScreen,
  PayByBankReviewAndConfirmScreen,
  PayByBankReviewAndConfirmScreenProps,
} from './screens';

export type PayByBankReviewAndConfirmActivityProps = Pick<PayByBankReviewAndConfirmScreenProps, 'onViewInvoice'> & {
  fundingSourceId: string;
  paymentRequestLink: string;
  onDone: (paymentId: string) => void;
  onError?: ARErrorFunction;
  onClose?: VoidFunction;
};

export const PayByBankReviewAndConfirmActivity = forwardRef<PayByBankReviewAndConfirmActivityProps>(
  ({ paymentRequestLink, onDone, onError, onClose, fundingSourceId, onViewInvoice, ...props }, ref) => {
    const [paymentId, setPaymentId] = useState<string>();

    const paymentRequestDetailsModel = useGuestPayorPaymentRequestDetails({ paymentRequestLink, onError });
    const paymentRequestDetails = paymentRequestDetailsModel.data;
    const fundingSourceModel = useGuestPayorFundingSource({ id: fundingSourceId });
    const paymentModel = useGuestPayorPayment({ id: paymentId, enabled: false, onError });

    useAnalyticsView('PaymentRequest', true, true, { Intent: 'pay-invoice' });

    const { startAction } = useMonitoring();

    const [isSaving, setIsSaving] = useState(false);

    const handleOnSubmit = async ({ scheduledDate }: { scheduledDate: Date }) => {
      try {
        setIsSaving(true);
        startAction('pay');
        const { paymentId } = await paymentModel.createPayment({
          fundingSourceId,
          paymentRequestLink,
          scheduledDate: convertDateToStringRepresentation(scheduledDate),
        });
        setPaymentId(paymentId);
        await Promise.all([paymentRequestDetailsModel.refetch(), paymentModel.refetch()]);
        onDone(paymentId);
      } catch (error) {
        onClose?.();
        onError?.(error as ARPlatformError);
      } finally {
        setIsSaving(false);
      }
    };

    if (isSaving) {
      return (
        <CreatingPaymentScreen
          backgroundColor={paymentRequestDetails?.payeeDetails.brandColor ?? undefined}
          {...props}
          ref={ref}
        />
      );
    }

    return (
      <PayByBankReviewAndConfirmScreen
        isLoading={fundingSourceModel.isLoading}
        paymentRequestDetails={paymentRequestDetails}
        onSubmit={handleOnSubmit}
        fundingSourceName={fundingSourceModel.data?.displayName}
        logo={fundingSourceModel.data?.logo}
        onViewInvoice={onViewInvoice}
        {...props}
        ref={ref}
      />
    );
  }
);
PayByBankReviewAndConfirmActivity.displayName = 'PayByBankReviewAndConfirmActivity';
