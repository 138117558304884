import { object, SchemaOf, string } from 'yup';
import { validateInvalidChars } from '@melio/ap-widgets';
import { useUniqueNameSchema } from '@melio/ap-widgets/src/components/Vendors/VendorDetailsBaseSchema';

import { usePlatformIntl } from '@/translations/Intl';
import { VendorDetailsFormFields } from '@/types/vendors.types';

export type VendorDetailsMangedByFiservFormFields = Pick<
  VendorDetailsFormFields,
  'companyName' | 'nickname' | 'accountNumber' | 'uniqueName'
>;

export const useFormSchema = ({
  showUniqueNameField,
}: {
  showUniqueNameField?: boolean;
}): SchemaOf<VendorDetailsMangedByFiservFormFields> => {
  const { formatMessage } = usePlatformIntl();
  const uniqueNameSchema = useUniqueNameSchema({ showUniqueNameField, isManaged: false });

  return object()
    .shape({
      companyName: string()
        .trim()
        .required(formatMessage('widgets.vendorDetails.form.companyName.validation.required')),
      nickname: string()
        .nullable()
        .max(30, formatMessage('widgets.vendorDetails.form.nickname.validation.maxLength'))
        .test(
          'validate-invalid-chars',
          '',
          validateInvalidChars(/[ ,.\-0-9A-Za-z\r\n]*/, (invalidChars) =>
            formatMessage('widgets.vendorDetails.form.nickname.validation.format', {
              invalidChars,
            }),
          ),
        ),
      accountNumber: string()
        .nullable()
        .required(formatMessage('widgets.vendorDetails.form.accountNumber.validation.required'))
        .max(32, formatMessage('widgets.vendorDetails.form.accountNumber.validation.maxLength'))
        .test(
          'validate-invalid-chars',
          '',
          validateInvalidChars(/[!"#$%&\-0-9A-Za-z]*/, (invalidChars) =>
            formatMessage('widgets.vendorDetails.form.accountNumber.validation.format', {
              invalidChars,
            }),
          ),
        ),
    })
    .concat(uniqueNameSchema) as SchemaOf<VendorDetailsMangedByFiservFormFields>;
};
