import { useIsSubscriptionsEnabled, usePlansTiers } from '../../../hooks';
import { SubscriptionCancelModalActivity } from '../SubscriptionCancel';
import { SubscriptionCancelModalActivityProps } from '../SubscriptionCancel/SubscriptionCancel.modal-activity';
import { SubscriptionDowngradeModalActivity } from '../SubscriptionDowngrade';

export type CancelOrDowngradeFlowProps = {
  isOpen: boolean;
  newPlanId: string;
  origin: SubscriptionCancelModalActivityProps['origin'];
  onClose: () => void;
};

export const CancelOrDowngradeFlowActivity = ({ isOpen, newPlanId, origin, onClose }: CancelOrDowngradeFlowProps) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

  const { getIsFreePlan } = usePlansTiers();

  const isCancel = getIsFreePlan(newPlanId);

  if (!isSubscriptionsEnabled) {
    return null;
  }

  return isCancel ? (
    <SubscriptionCancelModalActivity isOpen={isOpen} newPlanId={newPlanId} origin={origin} onClose={onClose} />
  ) : (
    <SubscriptionDowngradeModalActivity isOpen={isOpen} onClose={onClose} newPlanId={newPlanId} />
  );
};
