import { ApiKeys, DeliveryMethodsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery, UseQueryOptions } from 'react-query';

type Props = Pick<UseQueryOptions, 'enabled'> & {
  deliveryMethodId?: string;
};

export const useGetDeliveryMethodUnmaskedAccountNumberQuery = ({ deliveryMethodId, enabled }: Props) =>
  useQuery({
    enabled: !!deliveryMethodId && enabled,
    queryKey: [ApiKeys.DeliveryMethodsApi, 'unmasked-account-number', deliveryMethodId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => DeliveryMethodsApiClient.getDeliveryMethodUnmaskedAccountNumber(deliveryMethodId!),
    select: (response) => response.data.data.accountNumber,
  });
