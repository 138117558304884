import { useRecurringEndDateRestrictionYears } from '@melio/ap-domain';
import {
  Bill,
  BillSubscription,
  BillSubscriptionEndPolicyEnum,
  BillSubscriptionIntervalTypeEnum,
  DeliveryPreferenceType,
  Payment,
  PaymentRestrictions,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { isHoliday } from '@melio/platform-utils';
import { getDay, isAfter, isPast } from 'date-fns';
import * as yup from 'yup';
import { date, mixed, object, SchemaOf, string, StringSchema, TestContext, TestFunction } from 'yup';

import { MAX_ALLOWED_OCCURRENCES } from '../../../../add-bill/AddBillV2Form/utils';
import { PaymentFlowFormFields } from '../../../types';
import { useAmountSchemas } from './useAmountSchemas';

export const usePaymentFlowFormSchema = (options?: {
  bill: Bill | undefined;
  payment: Payment | undefined;
  billSubscription: BillSubscription | undefined;
  paymentRestrictions: PaymentRestrictions | undefined;
}): SchemaOf<PaymentFlowFormFields> => {
  const { formatMessage } = useMelioIntl();
  const { bill, payment, billSubscription, paymentRestrictions } = options || {};
  const { amountToPaySchema, lastAmountSchema } = useAmountSchemas({ bill, payment, paymentRestrictions });
  const dateTest = createDateTest({
    allowHoliday: false,
    messages: {
      holiday: formatMessage('activities.paymentFlow.form.content.invalidDate.holiday'),
      past: formatMessage('activities.paymentFlow.form.content.invalidDate.past'),
    },
  });
  const { getRecurringEndDateRestrictionYears, restrictionYears } = useRecurringEndDateRestrictionYears();
  const {
    settings: {
      payment: {
        memoToVendor: { memoRegex, maxLength: memoToVendorMaxLength },
      },
    },
  } = useConfig();
  const memoErrorMsg = formatMessage('widgets.memoToVendorForm.fields.memo.validation.invalid.character');
  return object().shape({
    amountToPay: amountToPaySchema,
    vendorId: string().nullable().required(formatMessage('activities.paymentFlow.form.content.vendor.required')),
    deliveryMethodId: string().required(formatMessage('activities.paymentFlow.form.content.deliveryMethod.required')),
    fundingSourceId: string().required(formatMessage('activities.paymentFlow.form.content.fundingSource.required')),
    /**
     * This was added when we thought the form needs to support both schedule and delivery date as fields. We need to refactor this.
     */
    scheduleDate: date().nullable().required(),
    deliveryDate: date().when(['recurrenceType', 'vendorId', 'deliveryMethodId'], {
      is: (recurrenceType: string, vendorId: string, deliveryMethodId: string) =>
        recurrenceType === 'one_time' && !!vendorId && !!deliveryMethodId,
      then: validDate(dateTest)
        .required(formatMessage('activities.paymentFlow.form.content.deliveryDate.required'))
        .test(
          'hasScheduleDate',
          formatMessage('activities.paymentFlow.form.content.deliveryDate.invalid'),
          dateHasMatchingScheduleDateTest
        ),
      otherwise: date().nullable().notRequired(),
    }),
    deliveryPreference: new StringSchema<DeliveryPreferenceType>(),
    noteToVendor: string()
      .optional()
      .nullable()
      .matches(new RegExp(memoRegex.pattern, memoRegex.flags), memoErrorMsg)
      .max(
        memoToVendorMaxLength,
        formatMessage('widgets.memoToVendorForm.fields.memo.validation.maxLength', { num: memoToVendorMaxLength })
      ),
    vendorEmail: string()
      .email(formatMessage('activities.paymentFlow.form.content.vendorEmail.validation.format'))
      .optional()
      .nullable(),

    recurrenceType: new StringSchema<'one_time' | 'recurring'>().oneOf(['one_time', 'recurring']).required(),
    intervalType: mixed().when('recurrenceType', {
      is: 'recurring',
      then: new StringSchema<BillSubscriptionIntervalTypeEnum>().required(
        formatMessage('activities.paymentFlow.form.content.recurring.frequency.required')
      ),
      otherwise: new StringSchema<BillSubscriptionIntervalTypeEnum>().notRequired(),
    }),
    startDate: mixed().when('recurrenceType', {
      is: 'recurring',
      then: validDate(dateTest)
        .required(
          billSubscription
            ? formatMessage('activities.paymentFlow.form.content.recurring.nextPaymentDeliverBy.required')
            : formatMessage('activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.required')
        )
        .test(
          'hasScheduleDate',
          formatMessage('activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.invalid'),
          dateHasMatchingScheduleDateTest
        ),
      otherwise: validDate(dateTest).notRequired(),
    }),
    endPolicy: mixed().when('recurrenceType', {
      is: 'recurring',
      then: new StringSchema<BillSubscriptionEndPolicyEnum>().required(
        formatMessage('activities.paymentFlow.form.content.recurring.paymentDuration.required')
      ),
      otherwise: new StringSchema<BillSubscriptionEndPolicyEnum>().notRequired(),
    }),
    endDate: mixed().when('endPolicy', {
      is: BillSubscriptionEndPolicyEnum.EndDate,
      then: validDate(dateTest)
        .test('isAfterStartDate', (endDate, context) => {
          const { startDate } = context.parent as PaymentFlowFormFields;
          if (!startDate || !endDate) {
            return true;
          }
          return (
            isAfter(endDate, startDate) ||
            context.createError({
              message: formatMessage('activities.paymentFlow.form.content.recurring.endDate.invalid.before-start-date'),
            })
          );
        })
        .test('isEndDateGreaterThanStartDatePlus5Years', (endDate, context) => {
          const { startDate } = context.parent as PaymentFlowFormFields;
          if (!startDate || !endDate) {
            return true;
          }
          const recurringEndDateRestrictionYears = getRecurringEndDateRestrictionYears(startDate);
          const isValid = recurringEndDateRestrictionYears && endDate <= recurringEndDateRestrictionYears;
          return (
            isValid ||
            context.createError({
              message: formatMessage(
                'activities.paymentFlow.form.content.recurring.endDate.invalid.isEndDateWithinAllowedRange',
                {
                  RESTRICTION_YEARS: restrictionYears,
                }
              ),
            })
          );
        })
        .required(formatMessage('activities.paymentFlow.form.content.recurring.endDate.required')),
      otherwise: validDate(dateTest).notRequired(),
    }),
    numOfOccurrences: string()
      .nullable()
      .when(['endPolicy'], {
        is: BillSubscriptionEndPolicyEnum.NumOfOccurrences,
        then: (schema) =>
          schema
            .required(formatMessage('activities.paymentFlow.form.content.recurring.occurrences.required'))
            .when('intervalType', (intervalType: BillSubscriptionIntervalTypeEnum, schema: yup.BaseSchema) =>
              schema.test(
                'not-exceed',
                formatMessage('activities.addBillV2.billForm.recurringOccurrences.validation.exceededLimit', {
                  maxNumOfPayments: MAX_ALLOWED_OCCURRENCES[intervalType],
                }),
                (value) => Boolean(value && Number(value) <= MAX_ALLOWED_OCCURRENCES[intervalType])
              )
            ),
        otherwise: (schema) => schema.notRequired(),
      }),
    lastAmount: lastAmountSchema,
    quoteId: string().optional().nullable(),
  });
};

const validDate = (test: TestFunction<Date | null | undefined>) => date().nullable().test('isValidDate', test);

const createDateTest =
  ({
    allowHoliday,
    messages,
    validWeekDays = [1, 2, 3, 4, 5],
  }: {
    allowHoliday: boolean;
    messages: { holiday: string; past: string };
    validWeekDays?: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
  }) =>
  (date: Date | null | undefined, context: yup.TestContext) => {
    if (!date) {
      return true;
    }

    if ((!allowHoliday && isHoliday(date)) || !validWeekDays.includes(getDay(date))) {
      return context.createError({
        message: messages.holiday,
      });
    }

    if (isPast(date)) {
      return context.createError({
        message: messages.past,
      });
    }

    return true;
  };

const dateHasMatchingScheduleDateTest = (date: Date | null | undefined, context: TestContext) => {
  const { scheduleDate } = context.parent as PaymentFlowFormFields;
  if (!date) {
    return true;
  }
  return !!scheduleDate;
};
