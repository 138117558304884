import { FundingSourceIconOrImage } from '@melio/ap-widgets';
import { Group, ListItem } from '@melio/penny';
import { FeeCatalog, FundingSource } from '@melio/platform-api';

import { useFundingSourceCardProps } from './useFundingSourceCardProps';

export const FundingSourceCard = ({
  fundingSource,
  feeCatalog,
  isDisabled,
}: {
  fundingSource: FundingSource;
  feeCatalog?: FeeCatalog[];
  isDisabled?: boolean;
}) => {
  const { title, line1, line2, pillProps } = useFundingSourceCardProps({ fundingSource, feeCatalog });

  return (
    <Group alignItems="center" spacing="s" data-testid="funding-source-card">
      <FundingSourceIconOrImage fundingSource={fundingSource} />
      <ListItem
        isDisabled={isDisabled}
        labelProps={line1 ? { label: line1 } : undefined}
        mainLabelProps={{
          label: title,
          variant: 'bold',
          pillProps,
        }}
        descriptionProps={line2 ? { label: line2 } : undefined}
      />
    </Group>
  );
};
