import { isFunction } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';

import { Analytics } from '../Analytics';
import { AnalyticsContext } from '../AnalyticsProvider';
import { Action, EventProperties, Traits, ViewContext } from '../types';
import { parsePropertiesForAnalytics } from './parsePropertiesForAnalytics';

export const useBaseAnalytics = () => {
  const {
    identify,
    ready,
    onUserIdentified,
    track: _track,
    trackAsync: _trackAsync,
    trackMarketing: _trackMarketing,
    setViewContext: trackForChangedContext,
    disableSalesIntegrations,
  } = useMemo(() => Analytics.getInstance(), []);
  const { getGlobalProperties } = useContext(AnalyticsContext);

  const applyGlobalProperties = useCallback(
    (args: Parameters<typeof _track>) => {
      const globalProps = getGlobalProperties();
      return [
        ...args.slice(0, 2),
        parsePropertiesForAnalytics({ ...globalProps, ...args[2] }),
        ...args.slice(3),
      ] as Parameters<typeof _track>;
    },
    [getGlobalProperties]
  );

  const track: typeof _track = useCallback(
    (...args) => _track(...applyGlobalProperties(args)),
    [applyGlobalProperties, _track]
  );

  const trackAsync: typeof _trackAsync = useCallback(
    (...args) => _trackAsync(...applyGlobalProperties(args)),
    [applyGlobalProperties, _trackAsync]
  );

  const trackMarketing: typeof _trackMarketing = useCallback(
    (...args) => {
      const globalProps = getGlobalProperties();
      args.splice(2, 1, parsePropertiesForAnalytics({ ...globalProps, ...args[2] }));
      return _trackMarketing(...args);
    },
    [getGlobalProperties, _trackMarketing]
  );

  const setViewContext = (viewContext: ViewContext, isNewAction: boolean, properties?: EventProperties) => {
    const globalProps = getGlobalProperties();
    const contextValue = properties?.PageName || globalProps?.PageName || viewContext;
    trackForChangedContext(contextValue, () => track(viewContext, isNewAction ? 'View' : 'Viewed', properties));
  };

  return {
    identify,
    track,
    trackMarketing,
    setViewContext,
    disableSalesIntegrations,
    ready,
    onUserIdentified,
    trackAsync,
  };
};

export const useAnalytics = () => {
  const { identify, track, trackAsync, trackMarketing, disableSalesIntegrations, ready, onUserIdentified } =
    useBaseAnalytics();

  const setTraits = (traits?: Traits) => {
    identify(traits);
  };

  type Params = Parameters<typeof track>;
  const createTrackHandler =
    <T extends EventProperties>(
      viewContext: Params[0],
      action: Params[1],
      defaultProperties?: Partial<T>,
      defaultCallback?: VoidFunction
    ) =>
    (properties?: Partial<T>, callback?: VoidFunction | unknown) => {
      track(viewContext, action, { ...defaultProperties, ...properties });
      defaultCallback?.();
      if (isFunction(callback)) {
        callback();
      }
    };

  const createTrackHandlerWithContext =
    <T extends EventProperties>(
      viewContext: Params[0],
      defaultProperties?: Partial<T>,
      defaultCallback?: VoidFunction
    ) =>
    (action: Action, properties?: Partial<T>, callback?: VoidFunction | unknown) => {
      track(viewContext, action, { ...defaultProperties, ...properties });
      defaultCallback?.();
      if (isFunction(callback)) {
        callback();
      }
    };

  return {
    identify,
    onUserIdentified,
    ready,
    track,
    trackAsync,
    trackMarketing,
    setTraits,
    createTrackHandler,
    createTrackHandlerWithContext,
    disableSalesIntegrations,
  };
};
