import { useMelioIntl } from '@melio/ar-domain';
import { Badge, Button, Checkbox, Container, FloatingMenu, Group, Icon, StatusIndicator, Text } from '@melio/penny';
import { useEffect, useState } from 'react';

import { Filter } from '../types';

type InvoiceStatusFilterProps = {
  onApply: (filter?: Filter[]) => void;
  selectedStatusFilters?: Filter[];
};

const getSelectedStatusFilterState = (selectedStatusFilters?: Filter[]) =>
  selectedStatusFilters ? selectedStatusFilters.reduce((acc, filter) => ({ ...acc, [filter]: true }), {}) : {};

export const InvoiceStatusFilter = ({ selectedStatusFilters, onApply }: InvoiceStatusFilterProps) => {
  const filters: Filter[] = ['draft', 'paid', 'overdue', 'open', 'canceled'];

  const [isOpen, setIsOpen] = useState(false);
  const [invoiceSelectedFilters, setInvoiceSelectedFilters] =
    useState<Partial<Record<Filter, boolean>>>(getSelectedStatusFilterState);

  useEffect(() => {
    const initialSelectedStatuses = getSelectedStatusFilterState(selectedStatusFilters);
    setInvoiceSelectedFilters(initialSelectedStatuses);
  }, [selectedStatusFilters]);

  const { formatMessage } = useMelioIntl();
  const label = selectedStatusFilters?.length
    ? formatMessage('ar.dashboard.activities.invoiceTable.filters.status.plural.label', {
        count: selectedStatusFilters.length,
      })
    : formatMessage('ar.dashboard.activities.invoiceTable.filters.status.single.label');

  const getStatusLabel = (status: Filter) => {
    switch (status) {
      case 'draft':
        return formatMessage('ar.dashboard.activities.invoiceTable.filters.status.draft.label');
      case 'paid':
        return formatMessage('ar.dashboard.activities.invoiceTable.filters.status.paid.label');
      case 'overdue':
        return formatMessage('ar.dashboard.activities.invoiceTable.filters.status.overdue.label');
      case 'open':
        return formatMessage('ar.dashboard.activities.invoiceTable.filters.status.open.label');
      case 'canceled':
        return formatMessage('ar.dashboard.activities.invoiceTable.filters.status.canceled.label');
    }
  };
  const triggerElement = (
    <Badge
      mark={selectedStatusFilters?.length ? <StatusIndicator status="success" /> : undefined}
      placement="top-right"
    >
      <Button
        data-testid="ar-dashboard-filter-menu-invoiceStatus"
        variant="tertiary"
        label={label}
        rightElement={<Icon size="small" type={isOpen ? 'caret-up' : 'caret-down'} color="inherit" aria-hidden />}
      />
    </Badge>
  );

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    if (!isOpen) {
      const initialSelectedStatuses = getSelectedStatusFilterState(selectedStatusFilters);
      setInvoiceSelectedFilters(initialSelectedStatuses);
    }
  };

  return (
    <FloatingMenu
      maxHeight={320}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      trigger={triggerElement}
      content={
        <Container paddingY="xs">
          <Group variant="vertical" spacing="none">
            {filters.map((filter) => (
              <Container paddingX="s" paddingY="xs-s" key={filter}>
                <Group spacing="xs-s" alignItems="center">
                  <Checkbox
                    data-testid={`ar-dashboard-filter-${filter}`}
                    isChecked={!!invoiceSelectedFilters[filter]}
                    onChange={() =>
                      setInvoiceSelectedFilters((prevState) => {
                        const newState = { ...prevState };
                        if (filter in newState) {
                          delete newState[filter];
                        } else {
                          newState[filter] = true;
                        }
                        return newState;
                      })
                    }
                  />
                  <Text textStyle="body3">{getStatusLabel(filter)}</Text>
                </Group>
              </Container>
            ))}
          </Group>
        </Container>
      }
      footer={
        <Container paddingY="s">
          <Group variant="horizontal" spacing="s" justifyContent="center">
            <Button
              label={formatMessage('ar.dashboard.activities.invoiceTable.filters.status.footer.cancel')}
              size="small"
              variant="tertiary"
              onClick={() => handleOpenChange(false)}
              data-testid="ar-dashboard-filter-menu-invoiceStatus-cancel"
            />
            <Button
              label={formatMessage('ar.dashboard.activities.invoiceTable.filters.status.footer.apply')}
              size="small"
              onClick={() => {
                setIsOpen(false);
                onApply(Object.keys(invoiceSelectedFilters) as Filter[]);
              }}
              data-testid="ar-dashboard-filter-menu-invoiceStatus-apply"
            />
          </Group>
        </Container>
      }
    />
  );
};
