import { useEffect } from 'react';
import { useAnalytics } from '@melio/platform-analytics';

import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { ErrorPage } from '@/widgets/error-page/ErrorPage.widget';

export const Import1099ContractorsError = () => {
  const { formatMessage } = usePlatformIntl();
  const { goToSettingsTaxAndReports } = useRouter();
  const { track } = useAnalytics();

  useEffect(() => {
    track('ErrorScreen', 'Viewed', {
      errorText: formatMessage('screens.Import1099Contractors.serverError.title'),
      errorCode: 500,
      errorType: 'ServerPageError',
    });
  }, [track, formatMessage]);

  return (
    <FullScreenContainer centered>
      <ErrorPage
        data-testid="server-error-page"
        title={formatMessage('screens.Import1099Contractors.serverError.title')}
        message={formatMessage('screens.Import1099Contractors.serverError.message')}
        primaryButton={{
          text: formatMessage('screens.Import1099Contractors.serverError.button'),
          onClick: () => {
            goToSettingsTaxAndReports();
          },
        }}
      />
    </FullScreenContainer>
  );
};
