import { useNavigate } from 'react-router-dom';
import { DeliveryMethod, FundingSource } from '@melio/platform-api';

import { useRouter } from '@/hooks/router.hooks';
import { useActiveFlowRoute } from '@/hooks/useActiveFlowRoute.hooks';

export const usePaymentMethodActivityEvents = (returnUrl?: string | null) => {
  const { navigateToActiveFlow } = useActiveFlowRoute();
  const { goBack } = useRouter();
  const navigate = useNavigate();
  const onBack = () => {
    goBack();
  };

  const onClose = () => {
    if (returnUrl) {
      return navigate(returnUrl, { state: { fromReturnUrl: true } });
    }
    navigateToActiveFlow();
  };

  const onDone = (data: DeliveryMethod | FundingSource, shouldAssignUsers?: boolean) => {
    if (returnUrl) {
      const url = new URL(returnUrl, 'https://melio.com');
      url.searchParams.set('fundingSourceId', data.id);
      if (shouldAssignUsers) {
        url.searchParams.set('fundingSourcesAssignUsers', 'true');
      }
      navigate(`${url.pathname}?${url.searchParams.toString()}`, { state: { fromReturnUrl: true } });
      return;
    }

    let queryParams = `fundingSourceId=${data.id}`;
    if (shouldAssignUsers) {
      queryParams += '&fundingSourcesAssignUsers=true';
    }
    navigateToActiveFlow(queryParams);
  };

  return {
    onDone,
    onBack,
    onClose,
  };
};
