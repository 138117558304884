import { useEffect, useMemo } from 'react';
import { useSendMessage } from '@melio/partner-bridge';
import { useAnalytics } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { useOneTimeEffect } from '@melio/platform-utils';

import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { usePlatformIntl } from '@/translations/Intl';
import {
  errorToOnboardingError,
  joinWith,
  OnboardingErrorType,
  onboardingErrorTypeToPathValues,
  validationKeyToFormatKey,
} from '@/utils/onboarding.utils';
import { ErrorPage } from '@/widgets/error-page/ErrorPage.widget';

type OnboardingErrorProps = {
  error?: unknown;
  backToPartnerUrl: string;
  isBackToPartner?: boolean;
  productName: string;
};

export const OnboardingError = ({ error, backToPartnerUrl, isBackToPartner, productName }: OnboardingErrorProps) => {
  const { formatMessage } = usePlatformIntl();
  const { type, missingFields, reason } = useMemo(() => errorToOnboardingError(error), [error]);
  const { title, message } = onboardingErrorTypeToPathValues(type);
  const { track } = useAnalytics();
  const sendMessage = useSendMessage();
  const {
    settings: { showRetryButtonOnFieldValidation },
  } = useConfig();

  const onClick = () => {
    track('ExitFromPlatformBackButton', 'Chose');
    window.location.href = backToPartnerUrl;
  };

  useOneTimeEffect(
    () => {
      track('ErrorScreen', 'View', {
        ErrorType: type,
        ErrorFields: missingFields,
      });
    },
    () => !!track,
    [track],
  );
  useEffect(() => {
    if (reason) {
      sendMessage('AUTHENTICATION_ERROR', { reason, missingFields });
    }
  }, [reason, sendMessage, missingFields]);
  const primaryButtonProps = {
    onClick,
    text: isBackToPartner
      ? type === OnboardingErrorType.NonCompliance
        ? formatMessage(`widgets.onboarding.errorPage.button.back.gotIt`)
        : formatMessage(`widgets.onboarding.errorPage.button.back`)
      : formatMessage(`widgets.onboarding.errorPage.button`),
  };

  const formattedMissingFields = missingFields?.map((fieldName) => {
    const formatKey = validationKeyToFormatKey[fieldName as keyof typeof validationKeyToFormatKey];

    if (formatKey) {
      return formatMessage(formatKey);
    }

    return fieldName;
  });

  const formattedMissingFieldsMessage =
    formattedMissingFields &&
    joinWith(formattedMissingFields, ',', formatMessage('widgets.onboarding.errorPage.missingFields.lastDelimiter'));
  return (
    <FullScreenContainer centered>
      <ErrorPage
        title={formatMessage(title)}
        message={formatMessage(message, {
          ...(formattedMissingFieldsMessage && {
            missingFields: formattedMissingFieldsMessage,
            productName,
          }),
        })}
        primaryButton={
          showRetryButtonOnFieldValidation && type !== OnboardingErrorType.Forbidden ? primaryButtonProps : undefined
        }
      />
    </FullScreenContainer>
  );
};
