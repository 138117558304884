import { useOrganizationPreference } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

export const useContractors1099SyncStatus = () => {
  const [isImport1099ContractorsEnabled] = usePartnerFeature('Import1099Contractors', false);
  const { data: lastContractors1099SyncDate, isLoading } = useOrganizationPreference({
    enabled: isImport1099ContractorsEnabled,
    id: 'last1099ContractorsSyncDate',
    staleTime: 0,
  });

  const isSyncedWithZenwork = !!lastContractors1099SyncDate;

  return {
    isImport1099ContractorsEnabled,
    isSyncedWithZenwork,
    lastContractors1099SyncDate,
    isLoadingZenworkSyncStatus: isLoading,
  };
};
