import { Container, Group, Link, NakedButton, SectionBanner, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import React from 'react';

import { FileAttachmentWidget } from '../FileAttachment';
import { ScannedInvoiceDetailsForm } from './ScannedInvoiceDetailsForm/ScannedInvoiceDetailsForm';
import { ScannedInvoiceDetailsWidgetProps } from './types';

export const ScannedInvoiceDetailsWidget = ({
  scannedInvoice,
  onReviewScannedInvoice,
  ...props
}: ScannedInvoiceDetailsWidgetProps) => {
  const [isPreviewInvoiceEnabled] = usePartnerFeature('PreviewInvoice', false);
  const { isExtraSmallScreen } = useBreakpoint();
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const formDefaultValues = React.useMemo(
    () => ({
      vendorName: scannedInvoice.vendorName || '',
      amount: scannedInvoice.amount ? scannedInvoice.amount : undefined,
      history: scannedInvoice.history,
      dueDate: scannedInvoice.dueDate ? scannedInvoice.dueDate : null,
      invoiceNumber: scannedInvoice.invoiceNumber || '',
      noteToSelf: scannedInvoice.note || '',
    }),
    [scannedInvoice]
  );
  const { invoiceNumber, dueDate, amount, vendorName } = scannedInvoice;
  const missingDetails = !invoiceNumber || !dueDate || !amount || !vendorName;

  const hasInboxEmailId = !!scannedInvoice.inboxEmailId;
  const sectiontitle = !hasInboxEmailId
    ? formatMessage('widgets.scannedInvoiceDetails.manuallyAdded.sectionBanner.title')
    : undefined;
  const sectionDescritpion = hasInboxEmailId
    ? 'widgets.scannedInvoiceDetails.sectionBanner.description'
    : 'widgets.scannedInvoiceDetails.manuallyAdded.sectionBanner.description';

  const onClickReviewTheBill = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onReviewScannedInvoice?.();
  };

  return (
    <Container data-component="ScannedInvoiceDetailsWidget" {...props}>
      <Group spacing="m" variant="vertical">
        <Text textStyle="heading3Semi">
          {formatMessage(
            isPreviewInvoiceEnabled
              ? 'widgets.scannedInvoiceDetails.previewInvoice.title'
              : 'widgets.scannedInvoiceDetails.title'
          )}
        </Text>
        <SectionBanner
          data-testid="scanned-invoice-section-banner"
          variant={missingDetails ? 'warning' : 'informative'}
          title={
            missingDetails
              ? formatMessage('widgets.scannedInvoiceDetails.sectionBanner.missingDetails.title')
              : sectiontitle
          }
          description={formatMessage(
            missingDetails
              ? 'widgets.scannedInvoiceDetails.sectionBanner.missingDetails.description'
              : sectionDescritpion,
            {
              payorMail: scannedInvoice.inboxEmail && (
                <Link
                  href={`mailto:${scannedInvoice.inboxEmail.sender}`}
                  label={scannedInvoice.inboxEmail.sender}
                  newTab
                />
              ),
              reviewTheBill: (
                <NakedButton
                  variant="secondary"
                  onClick={() => onReviewScannedInvoice?.()}
                  label={formatMessage('widgets.scannedInvoiceDetails.sectionBanner.reviewTheBill')}
                />
              ),
            }
          )}
          action={
            missingDetails ? (
              <Link
                isBold
                variant="standalone"
                color="secondary"
                size="medium"
                href="#"
                onClick={(e) => onClickReviewTheBill(e)}
                label={formatMessage('widgets.scannedInvoiceDetails.sectionBanner.reviewTheBill')}
              />
            ) : undefined
          }
        />
        <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'} spacing="l" alignItems="flex-start">
          <ScannedInvoiceDetailsForm scannedInvoice={formDefaultValues} />
          {!isPreviewInvoiceEnabled && (
            <FileAttachmentWidget
              fileId={scannedInvoice.fileId || undefined}
              aria-label={scannedInvoice.fileId ? formatMessage('widgets.fileAttachment.downloadAriaLabel') : undefined}
              isViewMode
              viewModeIcon="download"
              onViewModeClick={(fileUrl) => {
                if (fileUrl) {
                  track('ScannedInvoice', 'Click', { Intent: 'download-file', Cta: 'download-file' });
                  window.open(fileUrl);
                }
              }}
            />
          )}
        </Group>
      </Group>
    </Container>
  );
};

ScannedInvoiceDetailsWidget.displayName = 'ScannedInvoiceDetailsWidget';
