import { Box } from '@chakra-ui/react';
import { Arrows } from '@melio/ap-widgets/src/components/BillsDetailsCarousel/components/Arrows';
import { useArrowsStateMachine } from '@melio/ap-widgets/src/components/BillsDetailsCarousel/components/UseArrowsStateMachine';
import { Container, Group, Illustration, LargeFileUpload, Text } from '@melio/penny';
import { FileInfo, useFile } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { XOR } from '@melio/platform-utils';
import { useMemo } from 'react';

import { useUploadBillFile } from '../../../../utils/useUploadBillFile';
import { useIsMobile } from '../../../../utils/viewport-utils/useIsMobile';

type Props = { onNextFileClick?: () => void; onPreviousFileClick?: () => void } & XOR<
  { filesIds?: (string | null | undefined)[] },
  { files?: FileInfo[] }
>;
export const PreviewInvoice = ({ filesIds, files, onNextFileClick, onPreviousFileClick }: Props) => {
  const numberOfFiles = filesIds?.length || files?.length || 0;

  const { formatMessage } = useMelioIntl();
  const { currentElementIndex, handlePrevClick, handleNextClick } = useArrowsStateMachine(numberOfFiles);
  const isMobile = useIsMobile();

  const { data, isLoading: isLoadingFileInfo } = useFile({
    id: filesIds?.[currentElementIndex] || undefined,
    enabled: !!filesIds?.length,
  });

  const handleNextArrowClick = () => {
    onNextFileClick?.();
    handleNextClick();
  };

  const handlePreviousArrowClick = () => {
    onPreviousFileClick?.();
    handlePrevClick();
  };

  const fileInfo = useMemo(() => (files ? files[currentElementIndex] : data), [currentElementIndex, data, files]);

  const { fileValue, isLoading: isFileLoading } = useUploadBillFile({ initialFileInfo: fileInfo });

  const isLoading = isFileLoading || isLoadingFileInfo;

  return (
    <Group variant="vertical" height="full" data-testid="bill-preview-invoice">
      <Group variant="horizontal" spacing="l" justifyContent="space-between" alignItems="center">
        <Text as="h2" textStyle="heading3Semi">
          {formatMessage('activities.payDashboard.drawer.header.bill.file.title')}
        </Text>
        <Arrows
          handleNextClick={handleNextArrowClick}
          handlePrevClick={handlePreviousArrowClick}
          tooltipLeftButtonLabel={formatMessage('activities.payDashboard.drawer.header.bill.file.prevButton')}
          tooltipRightButtonLabel={formatMessage('activities.payDashboard.drawer.header.bill.file.nextButton')}
          currentElementIndex={currentElementIndex}
          totalElements={numberOfFiles}
          isCyclical={false}
          testId="preview-invoice"
        />
      </Group>
      {(fileInfo?.viewUrl && fileInfo.mimeType) || fileValue || isLoading ? (
        <Box
          maxHeight={fileInfo?.mimeType === 'application/pdf' ? '100%' : undefined}
          height={fileInfo?.mimeType === 'application/pdf' ? (isMobile ? '460px' : '100%') : undefined}
        >
          <LargeFileUpload
            value={
              fileInfo?.viewUrl && fileInfo.mimeType
                ? {
                    url: fileInfo.viewUrl,
                    type: fileInfo.mimeType,
                    name: fileInfo.fileName ?? 'file',
                  }
                : fileValue
            }
            acceptTypes={['pdf', 'png', 'jpg']}
            isReadOnly
            isLoading={isLoading}
            data-testid="large-file-preview-invoice"
            previewFileAriaLabel={formatMessage('activities.addBillV2.billForm.fileUpload.filePreviewAriaLabel')}
            placeholder={formatMessage('activities.addBillV2.billForm.fileUpload.uploadFileButtonAriaLabel')}
          />
        </Box>
      ) : (
        <Container paddingTop="l" data-testid="preview-invoice-empty-state">
          <Group variant="vertical" alignItems="center">
            <Illustration data-testid="preview-invoice-empty-state-illustration" type="no-items" size="large" />
            <Text textStyle="body3">{formatMessage('activities.payDashboard.drawer.header.bill.file.noFile')}</Text>
          </Group>
        </Container>
      )}
    </Group>
  );
};
