import { InternationalAddressInput } from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { useEffect, useMemo } from 'react';

import { getVendorMissingDetailsList } from '../../../BatchPayments/screens/PaymentIntentsTable/PaymentIntentsTable.screen.analytics-utils';
import { useRestrictedCountry } from './hooks/useRestrictedCountry.hook';
import { useSchema } from './hooks/useSchema.hook';
import { VendorDetailsFormFields, VendorDetailsFormProps } from './types';
import { VendorMccFields } from './VendorMccFields';

export const VendorDetailsForm = ({
  onSubmit,
  onSubmissionStateChange,
  isSaving,
  mccIndustryList,
  requiredFormFields,
  cardNetwork,
}: VendorDetailsFormProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { restrictedCountry } = useRestrictedCountry(cardNetwork);
  const requiredFields = useMemo(
    () => ({
      mcc: requiredFormFields.includes('mcc'),
      address: requiredFormFields.includes('address'),
      email: requiredFormFields.includes('email'),
      phone: requiredFormFields.includes('phone'),
    }),
    [requiredFormFields]
  );
  const mccGroupsSupported = useMemo(() => mccIndustryList.some((x) => Boolean(x.category)), [mccIndustryList]);

  const form = useMelioForm<VendorDetailsFormFields>({
    onSubmit,
    schema: useSchema(requiredFields, mccGroupsSupported),
    defaultValues: {
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        countryCode: '',
        postalCode: '',
      },
    },
    isSaving,
    onSubmissionStateChange,
  });

  useEffect(() => {
    if (Object.values(form.formState.errors).length) {
      track('PaymentMethodOptions', 'Status', {
        PageName: 'add-vendor-details',
        DetailsToFill: getVendorMissingDetailsList(requiredFormFields),
        CardNetwork: cardNetwork,
        Intent: 'add-vendor-business-details',
        ErrorType: 'invalid-values',
        Status: 'failure',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.errors]);

  return (
    <Form data-component="VendorDetailsForm" columns={2} size="small" {...form.formProps}>
      {requiredFields.mcc && (
        <VendorMccFields form={form} mccIndustryList={mccIndustryList} mccGroupsSupported={mccGroupsSupported} />
      )}
      {requiredFields.email && (
        <Form.TextField
          size="small"
          colSpan={2}
          {...form.registerField('email')}
          labelProps={{ label: formatMessage('activities.fundingSources.vendorDetailsForm.fields.email.label') }}
          placeholder={formatMessage('activities.fundingSources.vendorDetailsForm.fields.email.placeholder')}
          data-testid="vendor-details-email-input"
        />
      )}
      {requiredFields.phone && (
        <Form.PhoneField
          size="small"
          colSpan={2}
          {...form.registerField('phone')}
          labelProps={{ label: formatMessage('activities.fundingSources.vendorDetailsForm.fields.phone.label') }}
          placeholder={formatMessage('activities.fundingSources.vendorDetailsForm.fields.phone.placeholder')}
          data-testid="vendor-details-phone-input"
        />
      )}
      {requiredFields.address && (
        <>
          <InternationalAddressInput
            colSpan={2}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error*
            form={form}
            restrictedCountry={restrictedCountry}
            addressLine1FieldName="address.line1"
            cityFieldName="address.city"
            countryCodeFieldName="address.countryCode"
            stateFieldName="address.state"
            postalCodeFieldName="address.postalCode"
            label="activities.fundingSources.vendorDetailsForm.fields.line1.label"
          />
        </>
      )}
    </Form>
  );
};
