import { Container, Group, SectionBanner, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { SystemMessageDisplay } from '@melio/platform-utils';

import { BillAmountLine } from '../BillDetailsAccessibleNew/BillAmountLine/BillAmountLine';
import { FileAttachmentWidget } from '../FileAttachment';
import { PaymentRequestDetailsForm } from './PaymentRequestDetailsForm';
import { PaymentRequestDetailsWidgetProps } from './types';

export const PaymentRequestDetailsWidget = ({
  paymentRequest,
  showOpenBalance,
  ...props
}: PaymentRequestDetailsWidgetProps) => {
  const { isExtraSmallScreen } = useBreakpoint();
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const [isPreviewInvoiceEnabled] = usePartnerFeature('PreviewInvoice', false);

  return (
    <Container data-component="PaymentRequestDetailsWidget" {...props}>
      <Group hasDivider variant="vertical" spacing="m">
        <Container>
          <Group variant="vertical" spacing="m">
            <Container data-component="PaymentRequestDetailsHeader">
              <Text textStyle="heading3Semi">
                {formatMessage(
                  isPreviewInvoiceEnabled
                    ? 'widgets.paymentRequestDetails.header.previewInvoice.title'
                    : 'widgets.paymentRequestDetails.header.title'
                )}
              </Text>
            </Container>
            <SystemMessageDisplay data-testid="payment-request-notification" />
            <SectionBanner
              description={formatMessage('widgets.paymentRequestDetails.banner.description', {
                vendorName: paymentRequest.vendor?.name ?? '',
              })}
            />
            <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'} alignItems="flex-start">
              <PaymentRequestDetailsForm paymentRequest={paymentRequest} />
              {!isPreviewInvoiceEnabled && (
                <FileAttachmentWidget
                  file={paymentRequest.file}
                  aria-label={
                    paymentRequest.file ? formatMessage('widgets.fileAttachment.downloadAriaLabel') : undefined
                  }
                  isViewMode
                  onViewModeClick={(fileUrl) => {
                    if (fileUrl) {
                      track('PaymentRequest', 'Click', { Intent: 'download-file', Cta: 'download-file' });
                      window.open(fileUrl);
                    }
                  }}
                />
              )}
            </Group>
          </Group>
        </Container>
        {showOpenBalance && (
          <BillAmountLine
            amount={paymentRequest.totalAmount}
            size="small"
            type="total-amount"
            data-testid="total-bill-amount"
            currency={paymentRequest.currency}
          />
        )}
      </Group>
    </Container>
  );
};

PaymentRequestDetailsWidget.displayName = 'PaymentRequestDetailsWidget';
