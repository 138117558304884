import {
  CountryInternationalDeliveryDetails,
  InternationalAccountBicSwift,
  InternationalAccountDeliveryMethod,
  InternationalAccountIban,
  Vendor,
} from '@melio/platform-api';

import { Subset } from './types';

const isIbanData = (
  deliveryMethodCreateData: Subset<InternationalAccountIban> | Subset<InternationalAccountBicSwift>
): deliveryMethodCreateData is Subset<InternationalAccountIban> => deliveryMethodCreateData?.identifierType === 'iban';

const isBicSwiftData = (
  deliveryMethodCreateData: Subset<InternationalAccountIban> | Subset<InternationalAccountBicSwift>
): deliveryMethodCreateData is Subset<InternationalAccountBicSwift> =>
  deliveryMethodCreateData?.identifierType === 'bicSwift';

export const getAddInternationalDeliveryMethodDefaultValues = ({
  deliveryMethodCreateData,
  defaultCountryDetails,
  vendorCountryName,
}: {
  deliveryMethodCreateData: Subset<InternationalAccountIban> | Subset<InternationalAccountBicSwift>;
  defaultCountryDetails?: CountryInternationalDeliveryDetails;
  vendorCountryName?: string;
}) => {
  const bankDetailsDefaultValues = {
    selectedInternationalCountries: defaultCountryDetails,
    bankName: deliveryMethodCreateData.identifierDetails?.bankName,
    ...(isIbanData(deliveryMethodCreateData)
      ? {
          ibanCode: deliveryMethodCreateData.identifierDetails?.iban,
        }
      : isBicSwiftData(deliveryMethodCreateData)
      ? {
          swiftCode: deliveryMethodCreateData.identifierDetails?.bicSwift,
          accountNumber: deliveryMethodCreateData.identifierDetails?.accountNumber,
        }
      : {}),
  };

  const vendorDetailsDefaultValues = {
    ...deliveryMethodCreateData?.address,
    legalBusinessName: deliveryMethodCreateData?.companyName,
    fullName: deliveryMethodCreateData?.identifierDetails?.bankAccountHolderName,
    country: vendorCountryName,
  };

  return {
    bankDetailsDefaultValues,
    vendorDetailsDefaultValues,
  };
};

export const getCurrencyCloudInternationalDeliveryMethod = (vendor: Vendor) =>
  vendor.deliveryMethods.find(
    (dm): dm is InternationalAccountDeliveryMethod =>
      dm.type === 'international-account' && dm.details.identifierType !== 'v2'
  );
