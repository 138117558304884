import { useMelioIntl } from '@melio/ar-domain';
import { Typography } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export const LandingPageDescription = forwardRef((_props, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Typography.ParagraphList
      list={[
        {
          title: formatMessage('ar.onboarding.components.landingPage.description.title1'),
          content: formatMessage('ar.onboarding.components.landingPage.description.text1'),
          icon: 'checked-mini',
        },
        {
          title: formatMessage('ar.onboarding.components.landingPage.description.title2'),
          content: formatMessage('ar.onboarding.components.landingPage.description.text2'),
          icon: 'checked-mini',
        },
        {
          title: formatMessage('ar.onboarding.components.landingPage.description.title3'),
          content: formatMessage('ar.onboarding.components.landingPage.description.text3'),
          icon: 'checked-mini',
        },
      ]}
      type="unordered"
      size="small"
      ref={ref}
    />
  );
});
