import { Button } from '@melio/penny';

import { Plan } from '../../../../../api';

export type PlanCardButtonProps = {
  planTier: Plan['tier'];
  isSaving?: boolean;
  isDisabled?: boolean;
  label: string;
  variant: 'primary' | 'tertiary';
  onClick: () => void;
};

export const PlanCardButton = ({ planTier, isSaving, isDisabled, label, onClick, variant }: PlanCardButtonProps) => (
  <Button
    data-testid={`subscription-${planTier}-plan-card-button`}
    label={label}
    isDisabled={isDisabled}
    isLoading={isSaving}
    variant={variant}
    onClick={onClick}
  />
);
