import { FundingSource } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

type Props = {
  fundingSource?: FundingSource;
  billTotal: number;
};

export const useShowInsufficientCreditBanner = ({ fundingSource, billTotal }: Props): boolean => {
  const [featureEnabled] = usePartnerFeature('showInsufficientCreditBanner', false);
  if (
    !featureEnabled ||
    fundingSource?.type != 'card' ||
    fundingSource.details.type !== 'credit' ||
    !fundingSource?.availableBalance
  ) {
    return false;
  }

  return fundingSource.availableBalance <= billTotal;
};
