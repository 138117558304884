import { useAccountMeLimitationsV2 } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

export type AccountLimitations = {
  isOrgOwesUsMoney: boolean;
};

export const useGetAccountLimitations = (stage: 'dashboard' | 'payment', isInitiator?: boolean): AccountLimitations => {
  const limitations: AccountLimitations = {
    isOrgOwesUsMoney: false,
  };

  const [isAccountLimitationsV2Enabled] = useDevFeature(FeatureFlags.AccountLimitationsV2Enabled, false);
  const { data: accountLimitations } = useAccountMeLimitationsV2(stage, isInitiator, isAccountLimitationsV2Enabled);

  if (accountLimitations) {
    limitations.isOrgOwesUsMoney = accountLimitations?.some(
      (accountLimitation) => accountLimitation.name === 'orgOwesUsMoney'
    );
  }

  return limitations;
};
