import type { PartnerIcons } from '../../../partnersConfig.types';

export const WafedIcons: PartnerIcons = {
  Favicon: 'https://platform-static.meliopayments.com/assets/wafed/fav.icon.svg',
  MelioIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wafed/melio.icon.svg',
  },
  LogoIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wafed/logo.icon.svg',
  },
  ErrorIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wafed/error.icon.svg',
  },
  EmptyBillsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wafed/emptyBills.icon.svg',
  },
  EmptyVendorsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wafed/emptyVendors.icon.svg',
  },
  NoBillsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wafed/noBillsFound.icon.svg',
  },
  NoVendorsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wafed/noVendorsFound.icon.svg',
  },
  VerifyEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wafed/verifyEmail.icon.svg',
  },
  TaxSummaryIcon: {
    small: 'https://platform-static.meliopayments.com/assets/default/taxSummary.icon.svg',
  },
  ExpiredSessionIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wafed/expiredSession.icon.svg',
  },
  BackToPartnerIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  StartNewExperienceIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  TryDifferentEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  AddBillIcon: {
    small: 'https://platform-static.meliopayments.com/assets/wafed/addBill.icon.svg',
  },
};
