import { Box } from '@chakra-ui/react';
import { useMtlFormValues, useMtlMessages } from '@melio/ap-domain';
import { IndustryTypeSelectWidget } from '@melio/form-controls';
import { Form, SectionBanner, SelectOption, useMelioForm } from '@melio/penny';
import { useTrackAnalyticsOnFailedFormSubmit } from '@melio/platform-analytics';
import { Company, LegalInfoField, LegalInfoFields as CompleteLegalInfoFormWidgetFields } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { defaults } from 'lodash';
import React, { useEffect } from 'react';

import { FormWidgetProps } from '../../../types';
import { AddressSearchWidget } from '../../form-controls';
import { CompleteLegalInfoFormWidgetProps as CompleteLegalInfoFormWidgetPropsV2 } from '../types';
import { useCompleteLegalInfoFormSchema } from './useCompleteLegalInfoFormSchema';

export type CompleteLegalInfoFormWidgetProps = FormWidgetProps<CompleteLegalInfoFormWidgetFields> &
  Pick<CompleteLegalInfoFormWidgetPropsV2, 'missingLegalInfoFields' | 'taxIdType'>;
const buildMaskedTaxIdValue = (taxIdentifier?: string) =>
  taxIdentifier
    ?.split('')
    .map((c: string) => (isNaN(Number(c)) ? '•' : c))
    .join('');

const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; // i.e 12/30/1995

const resetLegalAddressIfMissingDetails = (missingLegalInfoFields: LegalInfoField[]) => {
  if (missingLegalInfoFields.includes('legalAddress')) {
    return {
      legalAddress: undefined,
    };
  }
  return {};
};
export const CompleteLegalInfoFormWidget = forwardRef<Omit<CompleteLegalInfoFormWidgetProps, 'companyName'>, 'form'>(
  (
    {
      onSubmit,
      missingLegalInfoFields,
      defaultValues: _defaultValues,
      isSaving,
      onSubmissionStateChange,
      taxIdType,
      ...props
    },
    ref
  ) => {
    const { getBusinessTypeOptions } = useMtlFormValues();
    const {
      placeholders,
      emptyState,
      labels: { company: companyLabels },
    } = useMtlMessages();

    const defaultValues = defaults(
      {
        ..._defaultValues,
        taxInfoIdentifier: buildMaskedTaxIdValue(_defaultValues?.taxInfoIdentifier),
        ...resetLegalAddressIfMissingDetails(missingLegalInfoFields),
      },
      {
        taxInfoType: '',
        taxInfoIdentifier: '',
        legalCompanyName: '',
      }
    );

    const { formatMessage } = useMelioIntl();

    const { settings } = useConfig();
    const schema = useCompleteLegalInfoFormSchema(missingLegalInfoFields);
    const { formProps, formState, registerField, watch, setValue } = useMelioForm<CompleteLegalInfoFormWidgetFields>({
      onSubmit,
      schema,
      defaultValues,
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    useTrackAnalyticsOnFailedFormSubmit(formState, 'Organization', 'Status');

    const shouldCollectBusinessType = missingLegalInfoFields.includes('businessType');
    const shouldCollectTaxInfoType = missingLegalInfoFields.includes('taxInfoType');
    const shouldShowTaxInfoBanner = shouldCollectTaxInfoType && !!defaultValues.taxInfoIdentifier;
    const watchTaxInfoType = watch('taxInfoType');
    const watchBusinessType = watch('businessType');

    const handleIndustryCreatable = (option: SelectOption) => {
      setValue('industry', option.value as unknown as NonNullable<Company['industry']>, { shouldValidate: true });
    };

    const isDateOfBirthHidden = () =>
      !missingLegalInfoFields.includes('dateOfBirth') ||
      (shouldCollectTaxInfoType && (!watchTaxInfoType || watchTaxInfoType === 'EIN')) ||
      taxIdType === 'EIN';

    const businessTypeOptions = getBusinessTypeOptions(
      shouldCollectTaxInfoType ? watchTaxInfoType : defaultValues.taxInfoType
    );
    const isSelectedBusinessTypeDisplayed = businessTypeOptions.find((option) => option.value === watchBusinessType);

    useEffect(() => {
      if (shouldCollectBusinessType && (!watchTaxInfoType || !isSelectedBusinessTypeDisplayed)) {
        setValue('businessType', null);
      }
    }, [watchTaxInfoType, setValue, isSelectedBusinessTypeDisplayed, shouldCollectBusinessType]);

    const taxInfoTypeTooltip = formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.tooltip');
    const isBusinessTypeDisabled = shouldCollectTaxInfoType && !watchTaxInfoType;

    const businessTypeTooltip =
      shouldCollectBusinessType &&
      !isBusinessTypeDisabled &&
      formatMessage(
        `_deprecated.widgets.completeLegalInfo.businessType.${
          shouldCollectTaxInfoType ? 'withoutInitialTaxIdType' : 'withInitialTaxIdType'
        }.tooltip`
      );

    const taxInfoIdentifierHelperText = formatMessage(
      '_deprecated.widgets.completeLegalInfo.taxInfo.identifier.helper'
    );

    return (
      <Form data-component="CompleteLegalInfoFormWidget" ref={ref} {...props} {...formProps}>
        {shouldShowTaxInfoBanner && (
          <SectionBanner
            description={formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.banner', {
              partnerProduct: settings.partnerDisplayName,
            })}
            variant="informative"
            data-testid="taxInfoBanner"
          />
        )}
        <Form.TextField
          isHidden={!missingLegalInfoFields.includes('legalCompanyName')}
          labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.legalCompanyName.label') }}
          placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.legalCompanyName.placeholder')}
          helperTextProps={{
            label: formatMessage('_deprecated.widgets.completeLegalInfo.legalCompanyName.helperText'),
          }}
          {...registerField('legalCompanyName')}
        />
        <Form.PhoneField
          isHidden={!missingLegalInfoFields.includes('phoneNumber')}
          labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.phoneNumber.label') }}
          placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.phoneNumber.placeholder')}
          {...registerField('phoneNumber')}
        />
        <AddressSearchWidget
          isHidden={!missingLegalInfoFields.includes('legalAddress')}
          {...registerField('legalAddress')}
          labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.legalAddress.label') }}
          placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.legalAddress.placeholder')}
        />
        <Form.RadioGroup
          isHidden={!shouldCollectTaxInfoType}
          isReadOnly={!shouldCollectTaxInfoType}
          labelProps={{
            label: formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.label'),
            ...(taxInfoTypeTooltip && { tooltipProps: { content: taxInfoTypeTooltip } }),
          }}
          options={[
            {
              mainLabelProps: { label: formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.ein.label') },
              value: 'EIN',
            },
            {
              mainLabelProps: { label: formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.ssn.label') },
              value: 'SSN',
            },
            {
              mainLabelProps: { label: formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.type.itin.label') },
              value: 'ITIN',
            },
          ]}
          {...registerField('taxInfoType')}
        />
        <Box hidden={!missingLegalInfoFields.includes('taxInfoIdentifier') && !shouldCollectTaxInfoType}>
          <Form.SecuredTextField
            maxLength={9}
            labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.identifier.label') }}
            placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.taxInfo.identifier.placeholder')}
            {...registerField('taxInfoIdentifier')}
            isReadOnly={!!defaultValues.taxInfoIdentifier}
            isTextVisible={!!defaultValues.taxInfoIdentifier}
            data-private
            helperTextProps={taxInfoIdentifierHelperText ? { label: taxInfoIdentifierHelperText } : undefined}
          />
        </Box>
        <Box hidden={!shouldCollectBusinessType}>
          <Form.SelectNew
            {...registerField('businessType')}
            labelProps={{
              label: companyLabels.businessType,
              ...(businessTypeTooltip && { tooltipProps: { content: businessTypeTooltip } }),
            }}
            options={businessTypeOptions}
            placeholder={placeholders.businessType}
            emptyState={{ label: emptyState.businessType }}
            isDisabled={isBusinessTypeDisabled}
          />
        </Box>
        <Box hidden={!missingLegalInfoFields.includes('industry')}>
          <IndustryTypeSelectWidget
            {...registerField('industry')}
            onCreateOption={handleIndustryCreatable}
            labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.industry.label') }}
            placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.industry.placeholder')}
          />
        </Box>
        <Box hidden={isDateOfBirthHidden()}>
          <Form.TextField
            {...registerField('dateOfBirth')}
            maskProps={{
              mask: DATE_MASK,
              keepCharPositions: false,
              guide: true,
              placeholderChar: ' ',
            }}
            labelProps={{ label: formatMessage('_deprecated.widgets.completeLegalInfo.dateOfBirth.label') }}
            placeholder={formatMessage('_deprecated.widgets.completeLegalInfo.dateOfBirth.placeholder')}
          />
        </Box>
      </Form>
    );
  }
);

CompleteLegalInfoFormWidget.displayName = 'CompleteLegalInfoFormWidget';
