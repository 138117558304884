import { DeliveryPreferenceType, PaymentsExtendedStatusesEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import compact from 'lodash/compact';
import { useMemo } from 'react';

import { FilterQueryParams, TabFilter } from '../Filters/types';
import { PaymentsTabFrequencyFilter } from './types';

export const supportedSearchParamKeys = ['payment.status', 'payment.frequency', 'payment.deliveryMethod'] as const;
export type SupportedSearchParamKeysType = (typeof supportedSearchParamKeys)[number];

export const useNewPaymentFilters = () => {
  const { formatMessage } = useMelioIntl();
  const [isPaymentsTabNewFiltersPhase2Enabled] = usePartnerFeature('PaymentsTabFiltersPhase2', false);

  return useMemo(() => {
    const paymentsNewFilters: TabFilter<SupportedSearchParamKeysType>[] = compact([
      {
        key: 'payment.status',
        label: formatMessage('activities.payDashboard.paymentsTab.filters.status.title'),
        type: 'multi',
        calcQueryParams: (values: string[]): FilterQueryParams => {
          const statusValues = values.filter((value) =>
            Object.values(PaymentsExtendedStatusesEnum).includes(value as PaymentsExtendedStatusesEnum)
          );
          if (statusValues.length) {
            return { 'payment.extendedStatus': statusValues };
          }
          return {};
        },
        options: [
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.scheduled.label'),
            value: PaymentsExtendedStatusesEnum.Scheduled,
            backgroundColor: 'informative',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.pending.label'),
            value: PaymentsExtendedStatusesEnum.Pending,
            backgroundColor: 'warning',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.inProgress.label'),
            value: PaymentsExtendedStatusesEnum.InProgress,
            backgroundColor: 'success',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.paid.label'),
            value: PaymentsExtendedStatusesEnum.Paid,
            backgroundColor: 'success',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.markedAsPaid.label'),
            value: PaymentsExtendedStatusesEnum.MarkedAsPaid,
            backgroundColor: 'success',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.refunded.label'),
            value: PaymentsExtendedStatusesEnum.Refunded,
            backgroundColor: 'success',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.failed.label'),
            value: PaymentsExtendedStatusesEnum.Failed,
            backgroundColor: 'critical',
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.declined.label'),
            value: PaymentsExtendedStatusesEnum.Declined,
            backgroundColor: 'critical',
          },
        ],
      },
      {
        key: 'payment.frequency',
        label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.title'),
        type: 'single',
        calcQueryParams: (values: string[]): FilterQueryParams => {
          const billSubOccKey = 'billSubscriptionOccurrence.id';
          const paymentMethodKey = 'payment.createMethod';
          if (values.includes(PaymentsTabFrequencyFilter.Recurring)) {
            return {
              $or: [{ [billSubOccKey]: { $ne: null } }, { [paymentMethodKey]: 'auto_payment' }],
            };
          } else if (values.includes(PaymentsTabFrequencyFilter.OneTime)) {
            return { [billSubOccKey]: null, [paymentMethodKey]: { $ne: 'auto_payment' } };
          }
          return {};
        },
        options: [
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.all.label'),
            value: PaymentsTabFrequencyFilter.All,
            selectWhenNothingSelected: true,
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.oneTime.label'),
            value: PaymentsTabFrequencyFilter.OneTime,
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.recurring.label'),
            value: PaymentsTabFrequencyFilter.Recurring,
          },
        ],
      },
      isPaymentsTabNewFiltersPhase2Enabled
        ? {
            key: 'payment.deliveryMethod',
            label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.title'),
            type: 'multi',
            calcQueryParams: (values: string[]): FilterQueryParams => {
              const deliveryMethodTypesValues = values.filter((value) =>
                Object.values(DeliveryPreferenceType).includes(value as DeliveryPreferenceType)
              );
              const managedConditions = deliveryMethodTypesValues.filter(
                (value) => value === DeliveryPreferenceType.Check || value === DeliveryPreferenceType.Ach
              );
              if (deliveryMethodTypesValues.length) {
                return {
                  $or: {
                    $or: [{ 'deliveryMethod.deliveryType': deliveryMethodTypesValues }],
                    ...(managedConditions.length
                      ? {
                          $and: [
                            { 'deliveryMethod.deliveryType': 'managed' },
                            { 'managedAccount.deliveryType': managedConditions },
                          ],
                        }
                      : {}),
                  },
                };
              }
              return {};
            },
            options: [
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.bankTransfer.label'),
                value: DeliveryPreferenceType.Ach,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.paperCheck.label'),
                value: DeliveryPreferenceType.Check,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.international.label'),
                value: DeliveryPreferenceType.International,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.virtualCard.label'),
                value: DeliveryPreferenceType.VirtualCard,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.wireTransfer.label'),
                value: DeliveryPreferenceType.DomesticWire,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.card.label'),
                value: DeliveryPreferenceType.Card,
              },
            ],
          }
        : undefined,
    ]);
    return paymentsNewFilters;
  }, [formatMessage, isPaymentsTabNewFiltersPhase2Enabled]);
};
