import { useInternationalFxFeature } from '@melio/ap-domain';
import { Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  Account,
  DeliveryMethodByPayor,
  FreeChecksData,
  FundingSource,
  SupportedDeliveryMethodTypeOption,
  Vendor,
} from '@melio/platform-api';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { AddDeliveryMethodCard, DeliveryMethodCard } from '../cards';
import { PaperCheckMethodCard } from './components/PaperCheckMethodCard/PaperCheckMethodCard';
import { InternationalAddDmCard } from './InternationalAddDmCard';

export type DeliveryMethodListProps = {
  account?: Account;
  data?: DeliveryMethodByPayor[];
  selectedId?: DeliveryMethodByPayor['id'];
  vendor?: Vendor;
  fundingSource?: FundingSource;
  freeChecks?: FreeChecksData;
  deliveryMethodTypeOptions: SupportedDeliveryMethodTypeOption[];
  onSelect?: (deliveryMethodId: DeliveryMethodByPayor['id']) => void;
  onEdit?: (type: Omit<DeliveryMethodByPayor['type'], 'virtual-account'>) => void;
  onAdd: (onAddArgs: { type: DeliveryMethodByPayor['type']; isWise?: boolean }) => void;
};

export const DeliveryMethodList = forwardRef<DeliveryMethodListProps>(
  (
    {
      fundingSource,
      freeChecks,
      data,
      vendor,
      selectedId,
      deliveryMethodTypeOptions,
      onSelect,
      onEdit,
      onAdd,
      account,
      ...props
    },
    ref
  ) => {
    const { track } = useAnalytics();
    const {
      settings: { deliveryMethodTypeOrder },
    } = useConfig();
    const isInternationalFxEnabled = useInternationalFxFeature();
    const [enabledInternationalUSDWise] = usePartnerFeature('EnableInternationalUSDWise', false);

    const getAddDeliveryMethodCard = (
      type: DeliveryMethodByPayor['type'],
      supported: boolean,
      reason?: SupportedDeliveryMethodTypeOption['reason']
    ) => (
      <AddDeliveryMethodCard
        type={type}
        supported={supported}
        reason={reason}
        key={type}
        fundingSourceType={fundingSource?.type}
        freeChecks={freeChecks}
        onClick={() => {
          track('DeliveryMethod', 'Click', {
            DeliveryMethodChosen: type,
            PageName: 'choose-delivery-method',
          });
          onAdd({ type });
        }}
      />
    );

    return (
      <Group variant="vertical" spacing="s" data-component="DeliveryMethodList" {...props} ref={ref}>
        {deliveryMethodTypeOptions
          .sort(({ type: a }, { type: b }) => deliveryMethodTypeOrder[a] - deliveryMethodTypeOrder[b])
          .map(({ type, supported, reason }): JSX.Element | JSX.Element[] => {
            const deliveryMethod = data?.find((deliveryMethod) => deliveryMethod.type === type);

            if (deliveryMethod) {
              switch (deliveryMethod.type) {
                case 'paper-check':
                  return (
                    <PaperCheckMethodCard
                      deliveryMethod={deliveryMethod}
                      fundingSource={fundingSource}
                      freeChecks={freeChecks}
                      isSelected={deliveryMethod.id == selectedId}
                      ref={ref}
                      key={deliveryMethod.type}
                      onSelect={onSelect}
                      onEdit={onEdit}
                      isDisabled={!supported}
                    />
                  );
                default:
                  return (
                    <DeliveryMethodCard
                      key={deliveryMethod.type}
                      vendorName={vendor?.name || ''}
                      deliveryMethod={deliveryMethod}
                      fundingSource={fundingSource}
                      freeChecks={freeChecks}
                      onClick={() => {
                        track('DeliveryMethod', 'Chose', { DeliveryMethodChosen: deliveryMethod.type });
                        onSelect?.(deliveryMethod.id);
                      }}
                      isSelected={deliveryMethod.id == selectedId}
                      onEditClick={
                        onEdit && deliveryMethod.type !== 'virtual-account'
                          ? () => {
                              track('EditDeliveryMethod', 'Chose', { DeliveryMethodChosen: deliveryMethod.type });
                              onEdit(deliveryMethod.type);
                            }
                          : undefined
                      }
                      isDisabled={!supported}
                      disabledReason={reason}
                    />
                  );
              }
            }

            if (type === 'international-account') {
              return (
                <InternationalAddDmCard
                  supported={supported}
                  reason={reason}
                  fundingSource={fundingSource}
                  vendorCurrency={vendor?.currency}
                  businessType={account?.company.businessType}
                  enabledInternationalUSDWise={enabledInternationalUSDWise}
                  isInternationalFxEnabled={isInternationalFxEnabled}
                  onAdd={(isWise) => onAdd({ type, isWise })}
                  key={type}
                />
              );
            }
            return getAddDeliveryMethodCard(type, supported, reason);
          })
          .flat()}
      </Group>
    );
  }
);

DeliveryMethodList.displayName = 'DeliveryMethodList';
