import { useRecoilValue } from 'recoil';
import { LocalStorageKeys } from '@melio/local-storage';
import { useIsAccountingFirm } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';

import { CompaniesIcon, TeamIcon } from '@/cl/icons/system';
import { LeftLayoutIconLink } from '@/cl/layouts/app-layout/leftLayout.component';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { ScreensEnum } from '@/store/app/app.types';
import { isAdmin } from '@/utils/user.utils';
import { appSelectedActiveScreenSelector } from '../store/app/app.model';
import { usePlatformIntl } from '../translations/Intl';
import { useRouter } from './router.hooks';

export const useAccountingFirmTabLinks = (): LeftLayoutIconLink[] => {
  const { generateMelioTeamLink, generateMelioClientsLink } = useRouter();
  const { actorRole } = usePermissions();
  const { formatMessage } = usePlatformIntl();
  const activeScreen = useRecoilValue(appSelectedActiveScreenSelector);
  const localStorage = usePartnerLocalStorage();
  const accessToken = localStorage.getItem(LocalStorageKeys.accessToken) as string;

  const isAccountingFirm = useIsAccountingFirm();

  if (isAccountingFirm) {
    return [
      {
        id: 'Clients',
        Icon: CompaniesIcon,
        href: generateMelioClientsLink(),
        linkText: formatMessage('app.navigation.clients'),
        isActive: activeScreen === ScreensEnum.Clients,
      },
      {
        id: 'Team',
        Icon: TeamIcon,
        href: generateMelioTeamLink(accessToken) as string,
        linkText: formatMessage('app.navigation.team'),
        isActive: activeScreen === ScreensEnum.Team,
        isHidden: actorRole ? !isAdmin(actorRole) : true,
      },
    ];
  }

  return [];
};
