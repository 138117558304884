import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { usePaymentRequestStatus } from '../../hooks';
import { BaseLayout } from '../../layout';
import { useRedirectToPayDashboard } from './hooks';

export type PaymentOptionsTriageActivityProps = {
  paymentRequestLink: string;
  onDone: (next: 'card' | 'bank') => void;
  onError: VoidFunction;
  isNavigateApUsersToDashboardEnabled?: boolean;
  currentRoute?: 'Card' | 'Bank';
};

export const PaymentOptionsTriageActivity = forwardRef<PaymentOptionsTriageActivityProps>(
  (
    {
      paymentRequestLink,
      onDone,
      onError,
      isNavigateApUsersToDashboardEnabled,
      currentRoute,
      children = <Outlet />,
      ...props
    },
    ref
  ) => {
    const paymentRequestModel = useGuestPayorPaymentRequestDetails({ paymentRequestLink });
    const redirectModel = useRedirectToPayDashboard(paymentRequestLink, isNavigateApUsersToDashboardEnabled);
    const paymentRequestStatusModel = usePaymentRequestStatus(paymentRequestLink);

    const { isAchAllowed, isCardAllowed } = paymentRequestModel.data?.invoice.paymentOptions ?? {};
    const isLoading = paymentRequestModel.isLoading || redirectModel.isLoading || paymentRequestStatusModel.isLoading;

    function reRoute() {
      if (isCardAllowed) onDone('card');
      else if (isAchAllowed) onDone('bank');
    }

    useEffect(() => {
      if (isLoading || redirectModel.isRedirecting) return;
      if (paymentRequestStatusModel.status != 'unpaid') return onError();
      else if (currentRoute == 'Card' && !isCardAllowed) return reRoute();
      else if (currentRoute == 'Bank' && !isAchAllowed) return reRoute();
      else if (!currentRoute) return reRoute();
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading || redirectModel.isRedirecting) {
      return (
        <BaseLayout
          data-testid="payment-options-triage-activity"
          isLoading
          backgroundColor={undefined}
          {...props}
          ref={ref}
        />
      );
    }
    return <>{children}</>;
  }
);
PaymentOptionsTriageActivity.displayName = 'PaymentOptionsTriageActivity';
