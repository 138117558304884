import {
  getFundingSourceIconType,
  getFundingSourceImageProps,
  PaymentReviewLineItem,
  useGetFundingSourceLabel,
} from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { addBusinessDays } from '@melio/platform-utils';

import { SectionBlock } from './SectionBlock';

type Props = { fundingSource: FundingSource; initiatedDate?: Date; scheduledDate: Date };
export const PayFromSectionRefund = ({ fundingSource, initiatedDate, scheduledDate }: Props) => {
  const { formatMessage, formatDate, formatDateTimeRange } = useMelioIntl();
  const { displayName, nickname, type: fundingSourceType } = fundingSource;

  const fundingSourceImage = getFundingSourceImageProps(fundingSource);
  const fundingSourceIconType = getFundingSourceIconType(fundingSource);
  const fundingSourceLabel = useGetFundingSourceLabel(fundingSource);
  const minDeliveryDate = addBusinessDays(initiatedDate as Date, 3);
  const maxDeliveryDate = addBusinessDays(initiatedDate as Date, 5);

  return (
    <Group data-testid="pay-from-section-refund" variant="vertical" spacing="m">
      <PaymentReviewLineItem
        labelProps={{ label: formatMessage('widgets.paymentDetails.payFrom.scheduledDate.label') }}
        mainLabelProps={{
          label: formatDate(scheduledDate, { dateStyle: 'medium' }),
        }}
        data-testid="pay-from-section-refund-scheduled-date"
        icon={{ type: 'scheduled' }}
      />

      <SectionBlock.Title
        label={formatMessage('widgets.paymentDetails.payFrom.refundTitle')}
        testId="pay-from-section-refund-title"
      />

      <PaymentReviewLineItem
        labelProps={{ label: fundingSourceLabel }}
        mainLabelProps={{ label: nickname || displayName }}
        {...(fundingSourceImage ? { imageIcon: fundingSourceImage } : { icon: { type: fundingSourceIconType } })}
        data-testid="pay-from-section-refund-funding-source"
      />

      {initiatedDate ? (
        <>
          <PaymentReviewLineItem
            labelProps={{ label: formatMessage('widgets.paymentDetails.payFrom.initiatedDate.label') }}
            mainLabelProps={{ label: formatDate(initiatedDate, { dateStyle: 'medium' }) }}
            icon={{ type: 'scheduled' }}
            data-testid="pay-from-section-refund-initiated-date"
          />
          <PaymentReviewLineItem
            labelProps={{ label: formatMessage('widgets.paymentDetails.payFrom.refundEta.label') }}
            data-testid="pay-from-section-refund-eta"
            icon={{ type: 'calendar-move' }}
            descriptionProps={
              fundingSourceType === 'bank-account'
                ? { label: formatMessage('widgets.deliveryDateListItemByPayee.bankHelperText') }
                : undefined
            }
            mainLabelProps={{
              label: formatDateTimeRange(minDeliveryDate, maxDeliveryDate, {
                dateStyle: 'medium',
              }),
            }}
          />
        </>
      ) : null}
    </Group>
  );
};
