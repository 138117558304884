import { Button, Group, useBreakpointValue, useFormSubmissionController } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const EditVendorDrawerFooter = ({
  isLoading = false,
  submitButtonProps,
  cancelButtonProps,
  onClose,
}: {
  isLoading?: boolean;
  submitButtonProps: ReturnType<typeof useFormSubmissionController>['submitButtonProps'];
  cancelButtonProps: ReturnType<typeof useFormSubmissionController>['cancelButtonProps'];
  onClose: VoidFunction;
}) => {
  const { formatMessage } = useMelioIntl();
  const isMobile = useBreakpointValue({ xs: true, s: false });

  return (
    <Group width="full" justifyContent="space-between" spacing="s">
      <Button
        aria-label="Cancel"
        variant="tertiary"
        label={formatMessage('widgets.confirmation.singleVendor.secondary')}
        onClick={onClose}
        data-testid="edit-vendor-drawer-footer-cancel-button"
        isFullWidth={isMobile}
        {...cancelButtonProps}
      />
      <Button
        isLoading={isLoading}
        aria-label="Save"
        variant="primary"
        data-testid="edit-vendor-drawer-footer-submit-button"
        isFullWidth={isMobile}
        label={formatMessage('widgets.confirmation.singleVendor.primary')}
        {...submitButtonProps}
      />
    </Group>
  );
};
