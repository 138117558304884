import { Box } from '@chakra-ui/react';
import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { useCallback } from 'react';

export const WarningBanner = () => {
  const { formatMessage } = useMelioIntl();
  const title = formatMessage(
    'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.warning.title'
  );
  const description = formatMessage(
    'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.warning.description'
  );

  const onMessageShown = useCallback((ref: HTMLDivElement | null): void => {
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth' });
      ref.focus();
    }
  }, []);

  return (
    <Box pt="m">
      <section aria-label={formatMessage('app.notifications.ariaLabel')}>
        <SectionBanner
          ref={onMessageShown}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tabIndex={-1}
          title={title}
          description={description}
          variant="warning"
          data-testid="reset-connection-warning-banner"
        />
      </section>
    </Box>
  );
};
