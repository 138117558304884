import { useTbt } from '@melio/form-controls';
import { useToast } from '@melio/penny';
import { useAuthorizeVirtualCardSession } from '@melio/platform-api';
import { FeatureFlags, useAnonymousFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { useEffect, useState } from 'react';

import { SUVC_BASIS_THEORY_ELEMENT_ID } from '../consts';
import { BasisTheoryRetrievedTokenData, BasisTheoryWithCreateElement } from './types';

const basisTheoryCopyIconStyles = { successColor: 'white', color: 'white', size: '18px' };
const basisTheoryElementProps = {
  style: { base: { padding: '2px', color: 'white', fontSize: '16px' } },
  placeholder: '-',
  readOnly: true,
};

const createBasisTheoryElements = (bt: BasisTheoryWithCreateElement) => {
  const cardNumberElement = bt.createElement('cardNumber', {
    targetId: SUVC_BASIS_THEORY_ELEMENT_ID.cardNumber,
    ...basisTheoryElementProps,
    copyIconStyles: basisTheoryCopyIconStyles,
    iconPosition: 'none',
    enableCopy: true,
  });
  const cardExpirationDateElement = bt.createElement('cardExpirationDate', {
    targetId: SUVC_BASIS_THEORY_ELEMENT_ID.cardExpirationDate,
    ...basisTheoryElementProps,
  });
  const cardVerificationCodeElement = bt.createElement('cardVerificationCode', {
    targetId: SUVC_BASIS_THEORY_ELEMENT_ID.cardVerificationCode,
    ...basisTheoryElementProps,
  });

  return { cardNumberElement, cardExpirationDateElement, cardVerificationCodeElement };
};

export const useTokenizedSUVCDetails = ({
  isTokenizedCardDetailsEnabledForCurrentCard,
  virtualCardId,
}: {
  isTokenizedCardDetailsEnabledForCurrentCard: boolean;
  virtualCardId: string;
}) => {
  const { toast } = useToast();
  const { services } = useConfig();
  const bt = useTbt().bt as BasisTheoryWithCreateElement;
  const { mutateAsync: authorizeSession } = useAuthorizeVirtualCardSession(virtualCardId);
  const [{ isInitializing, isDone }, setState] = useState({ isInitializing: false, isDone: false });
  const [isTokenizedCardDetailsFeatureEnabled] = useAnonymousFeature(
    FeatureFlags.EnableTokenizedCardDetailsOnSuvcAcceptanceFlow,
    false
  );

  const shouldUseTokenizedSUVCDetails =
    isTokenizedCardDetailsFeatureEnabled && isTokenizedCardDetailsEnabledForCurrentCard;

  const handleFail = (error: PlatformError) => toast({ type: 'error', title: error.message });

  const handleRevealTokenizedSUVCDetails = async () => {
    try {
      if (!bt?.createElement) {
        throw new Error('Basis Theory client is not initialized');
      }

      setState({ isInitializing: true, isDone: false });

      const { cardNumberElement, cardExpirationDateElement, cardVerificationCodeElement } =
        createBasisTheoryElements(bt);

      await Promise.all([
        cardNumberElement.mount(`#${SUVC_BASIS_THEORY_ELEMENT_ID.cardNumber}`),
        cardExpirationDateElement.mount(`#${SUVC_BASIS_THEORY_ELEMENT_ID.cardExpirationDate}`),
        cardVerificationCodeElement.mount(`#${SUVC_BASIS_THEORY_ELEMENT_ID.cardVerificationCode}`),
      ]);

      const session = await bt.sessions.create({ apiKey: services.tbt.revealVirtualCardApiKey });
      const { data: authorizedSessionData } = await authorizeSession({ nonce: session.nonce });
      const token = await bt.tokens.retrieve(authorizedSessionData.tokenId, { apiKey: session.sessionKey });
      const { number, expiration_year, expiration_month, cvc } = token.data as BasisTheoryRetrievedTokenData;

      cardNumberElement.setValue(number);
      cardExpirationDateElement.setValue({ year: expiration_year, month: expiration_month });
      cardVerificationCodeElement.setValue(cvc);

      setState({ isInitializing: false, isDone: true });
    } catch (error) {
      setState({ isInitializing: false, isDone: true });
      handleFail(error as PlatformError);
    }
  };

  useEffect(() => {
    if (bt && shouldUseTokenizedSUVCDetails && !isInitializing && !isDone) {
      void handleRevealTokenizedSUVCDetails();
    }
  }, [bt, shouldUseTokenizedSUVCDetails, isInitializing, isDone]); // eslint-disable-line react-hooks/exhaustive-deps

  return { shouldUseTokenizedSUVCDetails, isInitializingTokenizedSUVC: isInitializing };
};
