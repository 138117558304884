import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { QueryKey, useQueryClient } from 'react-query';

import { ApiQueryKeyV2, UseInvalidateQueryQueryPropsV2 } from './types';

type DataUpdateFunction<TInput, TOutput> = (input: TInput) => TOutput;
type Updater<TInput, TOutput> = TOutput | DataUpdateFunction<TInput, TOutput>;

type MelioQueryClientV2 = {
  invalidateQueries: (...queryKey: UseInvalidateQueryQueryPropsV2) => Promise<void>;
  removeQueries: (...queryKey: UseInvalidateQueryQueryPropsV2) => void;
  setQueriesData: <T>(queryKey: UseInvalidateQueryQueryPropsV2, data: Updater<T | undefined, T>) => [QueryKey, T][];
  setQueryData: <T>(queryKey: ApiQueryKeyV2, data: Updater<T | undefined, T>) => T;
  getQueryData: <T>(queryKey: ApiQueryKeyV2) => T | undefined;
  resetQueries: (...queryKey: UseInvalidateQueryQueryPropsV2) => Promise<void>;
};

export const useMelioQueryClientV2 = (): MelioQueryClientV2 => {
  const queryClient = useQueryClient();

  const invalidateQueries = (...queryKey: UseInvalidateQueryQueryPropsV2) =>
    queryClient.invalidateQueries(queryKey, { exact: false });

  const removeQueries = (...queryKey: UseInvalidateQueryQueryPropsV2) =>
    queryClient.removeQueries(queryKey, { exact: false });

  const setQueriesData = <T>(queryKey: UseInvalidateQueryQueryPropsV2, data: T | Updater<T | undefined, T>) =>
    queryClient.setQueriesData<T>({ queryKey, exact: false }, data);

  const setQueryData = <T>(queryKey: ApiQueryKeyV2, data: T | Updater<T | undefined, T>) =>
    queryClient.setQueryData<T>(queryKey, data);

  const getQueryData = <T>(queryKey: ApiQueryKeyV2) => queryClient.getQueryData<T>(queryKey, { exact: false });

  const resetQueries = (...queryKey: UseInvalidateQueryQueryPropsV2) =>
    queryClient.resetQueries(queryKey, { exact: false });

  return useMemo(
    () => ({ invalidateQueries, removeQueries, setQueriesData, setQueryData, getQueryData, resetQueries }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
};
