import { Button, Container, Group, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePlanInfo, useSubscription } from '../../../../hooks';
import { SETTINGS_SUBSCRIPTION_PLANS_ROUTE } from '../../../../router/routes.consts';
import { useSubscriptionRouter } from '../../../utils';

export const FreePlanCardHeaderFiserv = () => {
  const { goToSubscriptionPlans } = useSubscriptionRouter();
  const { formatMessage } = useMelioIntl();
  const subscription = useSubscription();
  const { planName } = usePlanInfo(subscription?.planId);

  const { track } = useAnalytics();

  const onManagePlanClick = (e: React.MouseEvent) => {
    e.preventDefault();
    track('Settings', 'Click', {
      Intent: 'manage-subscription',
      Cta: 'manage-subscription',
    });

    goToSubscriptionPlans({ returnUrl: SETTINGS_SUBSCRIPTION_PLANS_ROUTE });
  };

  return (
    <Group width="full" justifyContent="space-between" alignItems="center" spacing="s">
      <Container alignItems="center">
        <Group variant="vertical" spacing="xxxs">
          <Text textStyle="body2Semi" as="h4" data-testid="current-plan-name">
            {planName}
          </Text>
        </Group>
      </Container>

      <Button
        variant="primary"
        data-testid="subscription-basic-plan-upgrade"
        label={formatMessage('activities.settings.plans.upgradePlan')}
        onClick={onManagePlanClick}
        link={{
          href: '#',
        }}
      />
    </Group>
  );
};
