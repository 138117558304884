import { useConfig } from '@melio/platform-provider';

import { CaratAddCardFundingSourceActivity } from './CaratAddCardFundingSource.activity';
import { TabapayAddCardFundingSourceActivity } from './TabapayAddCardFundingSource.activity';
import { TBTAddCardFundingSourceActivity } from './TBTAddCardFundingSource.activity';
import { AddCardFundingSourceActivityProps } from './types';

export const AddCardFundingSourceActivity: React.VFC<AddCardFundingSourceActivityProps> = (props) => {
  const { settings } = useConfig();

  if (settings.tokenProvider === 'tbt') {
    return <TBTAddCardFundingSourceActivity {...props} />;
  } else if (settings.tokenProvider === 'carat') {
    return <CaratAddCardFundingSourceActivity {...props} />;
  }
  return <TabapayAddCardFundingSourceActivity {...props} />;
};
