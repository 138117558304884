import { Box } from '@chakra-ui/react';
import {
  Button,
  Container,
  Counter,
  Divider,
  FloatingMenu,
  Group,
  Icon,
  IconButton,
  Menu,
  StatusIndicator,
  Text,
} from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useMemo, useRef, useState } from 'react';

import { FilterMenuOptionsMulti } from './FilterMenuOptionsMulti';
import { FilterMenuOptionsSingle } from './FilterMenuOptionsSingle';
import { NestedFiltersProps } from './types';
import { getFilterByKey, getSelectedOptionsFromActiveFilter } from './utils';

export const NestedFiltersMobile = <T extends string>({
  filters,
  activeFilters,
  title,
  onChange,
}: NestedFiltersProps<T>) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [currentFilterOptions, setCurrentFilterOptions] = useState<string[]>([]);
  const [visibleFilterKey, setVisibleFilterKey] = useState<T | undefined>();
  const label = formatMessage('filter.button.text');
  const firstFiletMenuRef = useRef<HTMLDivElement>(null);
  const firstOptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visibleFilterKey) {
      const visibleFilter = getFilterByKey(filters, visibleFilterKey);
      const selectedOptions = getSelectedOptionsFromActiveFilter(visibleFilter, activeFilters);
      setCurrentFilterOptions(selectedOptions);
    } else {
      setCurrentFilterOptions([]);
    }
  }, [filters, visibleFilterKey, activeFilters]);

  useEffect(() => {
    if (visibleFilterKey) {
      const activeFilter = getFilterByKey(filters, visibleFilterKey);
      if (activeFilter) {
        firstOptionRef.current?.focus();
      }
    } else {
      firstFiletMenuRef.current?.focus();
    }
  }, [filters, visibleFilterKey]);

  const hasActiveFilters = useMemo(() => {
    if (!activeFilters) {
      return false;
    }
    const keys = Object.keys(activeFilters);
    return keys.filter((key) => activeFilters[key] && activeFilters[key]).length > 0;
  }, [activeFilters]);

  const onApply = () => {
    if (visibleFilterKey) {
      onChange(visibleFilterKey, currentFilterOptions);
    }
    handleOpenMenuChange(false);
  };

  const handleOpenMenuChange = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
    if (!isOpen) {
      cleanState();
    }
  };

  const onToggleOptionWithMultipleChoises = (optionValue: string) => {
    if (currentFilterOptions.includes(optionValue)) {
      setCurrentFilterOptions(currentFilterOptions.filter((cur) => cur !== optionValue));
    } else {
      setCurrentFilterOptions([...currentFilterOptions, optionValue]);
    }
  };

  const cleanState = () => {
    setVisibleFilterKey(undefined);
    setCurrentFilterOptions([]);
  };

  const renderMenuOptions = () => {
    if (!visibleFilterKey) {
      return null;
    }
    const visibleFilter = getFilterByKey(filters, visibleFilterKey);
    if (!visibleFilter) {
      return null;
    }
    if (visibleFilter.type === 'multi') {
      return (
        <FilterMenuOptionsMulti
          options={visibleFilter.options}
          selected={currentFilterOptions}
          onChange={onToggleOptionWithMultipleChoises}
          firstOptionRef={firstOptionRef}
        />
      );
    }
    return (
      <FilterMenuOptionsSingle
        options={visibleFilter.options}
        selected={currentFilterOptions}
        onChange={(value) => (value ? setCurrentFilterOptions([value]) : setCurrentFilterOptions([]))}
        firstOptionRef={firstOptionRef}
      />
    );
  };

  const renderMenuFilters = () => (
    <Group variant="vertical" spacing="xxs">
      {filters.map((filter, index) => {
        const selectedOptionsCount = activeFilters?.[filter.key]?.length ?? 0;
        return (
          <FloatingMenu.Item
            key={filter.key}
            onClick={() => setVisibleFilterKey(filter.key)}
            data-testid={`filter-menu-item-${filter.key}`}
            {...(index === 0 && { ref: firstFiletMenuRef })}
          >
            <Group justifyContent="space-between" width="full">
              <Group spacing="xxs" alignItems="center">
                <Text textStyle="body3Semi">{filter.label}</Text>
                {selectedOptionsCount ? (
                  <Counter
                    number={selectedOptionsCount}
                    status="brand"
                    data-testid={`filter-menu-item-counter-${filter.key}`}
                  />
                ) : null}
              </Group>
              <Icon type="chevron-right" />
            </Group>
          </FloatingMenu.Item>
        );
      })}
    </Group>
  );

  const renderCurrentFilterOrOption = () => {
    if (visibleFilterKey) {
      return renderMenuOptions();
    }
    return renderMenuFilters();
  };

  const renderTrigger = () => {
    const iconButton = (
      <IconButton icon="filter" variant="primary" size="large" aria-label={label} data-testid="filter-trigger" />
    );

    if (hasActiveFilters) {
      // We need the Box wrapper so the trigger will work properly
      return (
        <Box>
          <StatusIndicator status="informative">{iconButton}</StatusIndicator>
        </Box>
      );
    }

    return iconButton;
  };

  const renderMenuFooter = () => {
    if (!visibleFilterKey) {
      return null;
    }
    return (
      <>
        <Divider />
        <Container paddingX="s" paddingY="s">
          <Button
            label={formatMessage('activities.payDashboard.filter.apply.text')}
            variant="primary"
            data-testid="apply-filter"
            onClick={onApply}
            isFullWidth
          />
        </Container>
      </>
    );
  };

  const renderTitle = () => {
    if (visibleFilterKey) {
      return (
        <Group alignItems="center">
          <IconButton
            icon="arrow-left"
            onClick={cleanState}
            size="small"
            variant="naked"
            data-testid="filter-back"
          ></IconButton>
          <Text textStyle="body3Semi" color="semantic.text.secondary">
            {formatMessage('filter.nested.inner.options.title', {
              filterBy: filters.find((f) => f.key === visibleFilterKey)?.label?.toLowerCase(),
            })}
          </Text>
        </Group>
      );
    }
    return title;
  };

  return (
    <Menu
      data-testid="nested-filter-menu"
      trigger={renderTrigger()}
      onOpenChange={handleOpenMenuChange}
      isOpen={isMenuOpen}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore this is temp until penny will implement it
      title={renderTitle()}
    >
      <>
        {renderCurrentFilterOrOption()}
        {renderMenuFooter()}
      </>
    </Menu>
  );
};
