import { useModelApi, UseModelApiProps } from '@melio/api-client';
import { ApiGetRequest, ApiKeys, MelioPromise, VendorsApiClient } from '@melio/platform-api-axios-client';

export type TaxpayerItem = {
  forms: unknown[];
};

export type TaxpayerOverview = {
  taxYear: string;
  items: TaxpayerItem[];
};

export const useVendors1099ReportableContractors = (props: UseModelApiProps<ApiGetRequest, TaxpayerOverview>) => {
  const { data, isFetching, isLoading, ...query } = useModelApi({
    ...props,
    id: 'vendors1099ReportableContractors',
    staleTime: 0,
    queryKey: [ApiKeys.VendorsApi, 'model', 'reportable-contractors'],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    queryFn: () => VendorsApiClient.getVendorsImportContractorsValidate() as unknown as MelioPromise<void>,
  });

  const hasContractorsToReport = !!(data?.items?.length && data?.items[0]?.forms?.length);

  return {
    ...query,
    data,
    isLoading: isLoading || isFetching,
    hasContractorsToReport,
  };
};
