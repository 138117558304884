import { Box } from '@chakra-ui/react';
import { Button, ButtonProps, Group, Illustration, Layout, Text, useBreakpoint } from '@melio/penny';
import { LazyAnimation } from '@melio/platform-utils';

export type ButtonPropsWithTestId = ButtonProps & {
  'data-testid'?: string;
};

export type PayDashboardEmptyStateProps = {
  title?: string;
  description: string;
  descriptionCTA?: React.ReactNode;
  footer?: string;
  buttonProps?: ButtonPropsWithTestId[];
  customCTAComponent?: React.ReactNode;
  animationSrc?: string;
  hidden?: boolean;
  illustrationSrc?: string;
  notice?: string;
  dataTestId?: string;
};

export const PayDashboardEmptyState = ({
  title,
  description,
  descriptionCTA,
  footer,
  buttonProps,
  animationSrc,
  customCTAComponent,
  hidden,
  illustrationSrc,
  notice,
  dataTestId,
}: PayDashboardEmptyStateProps) => {
  const { isExtraSmallScreen, isSmallScreen } = useBreakpoint();

  return (
    <Layout
      maxWidth="600px"
      paddingContent={isExtraSmallScreen ? 'm' : 'xs'}
      data-testid={dataTestId || 'pay-dashboard-empty-state'}
    >
      {hidden ? null : (
        <Group spacing="none" alignItems="center" variant="vertical" textAlign="center">
          {animationSrc && (
            <LazyAnimation
              id="pay-dashboard-empty-state-animation"
              src={animationSrc}
              loop
              height="100%"
              width={isExtraSmallScreen || isSmallScreen ? '100%' : undefined}
            />
          )}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {illustrationSrc && <Illustration size="small" type={illustrationSrc} />}
          <Group spacing="m" alignItems="center" variant="vertical">
            {title && (
              <Text textStyle="heading3Semi" data-testid="pay-dashboard-empty-state-title">
                {title}
              </Text>
            )}
            <Box>
              <Text data-testid="pay-dashboard-empty-state-description">{description}</Text>
              {descriptionCTA}
            </Box>
            {footer && (
              <Text data-testid="pay-dashboard-empty-state-footer" textStyle="body3">
                {footer}
              </Text>
            )}
            {buttonProps && buttonProps.length > 0 && (
              <Group>
                {customCTAComponent}
                {buttonProps.map((buttonProp, index) => (
                  <Button key={index} variant="primary" isFullWidth={isExtraSmallScreen} {...buttonProp} />
                ))}
              </Group>
            )}
            {notice && (
              <Group>
                <Text data-testid="pay-dashboard-empty-state-notice">{notice}</Text>
              </Group>
            )}
          </Group>
        </Group>
      )}
    </Layout>
  );
};
