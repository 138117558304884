import { Button, Group, Modal, useBreakpoint, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { usePaginatedVendors, useUpdateVendorGroup, useVendorGroups } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';

import { usePayDashboardPagination } from '../../PayDashboard/components/Pagination';
import { VendorsGroupForm, VendorsGroupFormFields } from '../vendors-group-form';
import { EditVendorsGroupModalProps } from './types';

export const EditVendorsGroupModal = withAnalyticsContext<EditVendorsGroupModalProps>(
  ({ isOpen, onClose, vendorsGroupId, onDeleteVendorsGroupClick, onSuccess, setAnalyticsProperties }) => {
    const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<VendorsGroupFormFields>();
    const { formatMessage } = useMelioIntl();
    const { showMessage } = useSystemMessage();
    const { track } = useAnalytics();
    const { mutateAsync: updateVendorsGroup, isLoading: isUpdatedingVendorsGroup } = useUpdateVendorGroup();
    const { data: vendorGroups, isLoading: isLoadingVendorsGroup } = useVendorGroups();
    const { isExtraSmallScreen } = useBreakpoint();
    const { pageSize } = usePayDashboardPagination();

    const { isLoading: isLoadingVendors, data: vendorsResult } = usePaginatedVendors({
      cacheTime: 0,
      params: {
        limit: pageSize,
        sort: [{ field: 'name', order: 'asc' }],
        expand: 'groups',
      },
    });
    const vendorsGroup = vendorGroups?.find(({ id }) => id === vendorsGroupId);

    setAnalyticsProperties({
      PageName: 'edit-group',
      Intent: 'edit-vendor-groups',
      Flow: 'vendor-groups',
    });

    useEffect(() => {
      if (isLoadingVendors) {
        return;
      }
      track('Vendor', 'View', { CountInTab: vendorsResult?.data.length });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingVendors]);

    const handleSubmit = async (vendorsGroup: VendorsGroupFormFields) => {
      try {
        track('Vendor', 'Click', { Cta: 'create', CountInTab: vendorsGroup.vendorIds.length });

        await updateVendorsGroup({ vendorGroupId: vendorsGroupId, fields: vendorsGroup });
        showMessage({
          title: formatMessage('activities.editVendorsGroupModal.toasts.success', { name: vendorsGroup.name }),
          type: 'success',
          dataTestId: 'edit-vendors-group-success',
        });
        onSuccess?.();
        onClose();
      } catch (e) {
        showMessage({
          title: formatMessage('activities.editVendorsGroupModal.toasts.error', { name: vendorsGroup.name }),
          type: 'error',
          dataTestId: 'edit-vendors-group-error',
        });
      }
    };

    const handleClose = () => {
      track('Vendor', 'Click', { Cta: 'exit' });
      onClose();
    };

    const handleCancel = () => {
      track('Vendor', 'Click', { Cta: 'cancel' });
      onClose();
    };

    const handleDelete = () => {
      track('Vendor', 'Click', { Cta: 'delete-group' });
      onDeleteVendorsGroupClick(vendorsGroupId);
    };

    const buttonProps = isExtraSmallScreen ? { padding: 'xs' } : {};

    return (
      <Modal
        isOpen={isOpen}
        isLoading={isLoadingVendorsGroup || isLoadingVendors}
        onClose={handleClose}
        data-testid="edit-vendors-group-modal"
        header={formatMessage('activities.editVendorsGroupModal.header')}
        footer={
          <Group justifyContent="space-between">
            <Button
              variant="secondary"
              onClick={handleCancel}
              label={formatMessage('activities.editVendorsGroupModal.buttons.secondary')}
              data-testid="modal-btn-cancel"
              {...buttonProps}
            />
            <Group>
              <Button
                variant="critical-secondary"
                label={formatMessage('activities.editVendorsGroupModal.buttons.delete')}
                data-testid="modal-btn-delete"
                onClick={handleDelete}
                {...buttonProps}
              />
              <Button
                {...submitButtonProps}
                variant="primary"
                label={formatMessage('activities.editVendorsGroupModal.buttons.primary')}
                data-testid="modal-btn-save"
                {...buttonProps}
              />
            </Group>
          </Group>
        }
      >
        <VendorsGroupForm
          onSubmissionStateChange={onSubmissionStateChange}
          handleSubmit={handleSubmit}
          isSaving={isUpdatedingVendorsGroup}
          vendorsGroup={vendorsGroup}
          isEditMode
        />
      </Modal>
    );
  }
);
