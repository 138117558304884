import { FormattedMessage, Navigate, useLocation } from '@melio/ar-domain';
import { Container, Group, Illustration, Text } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useIsMissingKycComplianceInformation } from '@melio/platform-kyc';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { TabsLayout } from '../../layouts';
import { CustomersTableFlow } from './CustomersTable.flow';
import { InvoicesTableFlow } from './InvoicesTable.flow';
import { useArDashboardTabs, useDashboardFlowNavigation } from './utils';

export type DashboardFlowProps = {
  onError?: ARErrorFunction;
  onBack?: VoidFunction;
  onClose?: VoidFunction;
  onCreateInvoice: (props?: { customerId?: string }) => unknown;
  onPreviewInvoicePDF: (id: string) => unknown;
  onEditInvoice: (id: string) => unknown;
  onSendReminder: (id: string) => unknown;
  createdInvoiceId?: string;
  onVisitSupportSettingsPage: VoidFunction;
  onCreateCustomer: VoidFunction;
  isMtlKycUpliftEnabled?: boolean;
  onCompleteKycDetails?: (returnUrl?: string) => unknown;
};

export const DashboardFlow = withAnalyticsContext<DashboardFlowProps>(
  forwardRef(
    (
      {
        onVisitSupportSettingsPage,
        onEditInvoice,
        onError,
        onCreateInvoice,
        onPreviewInvoicePDF,
        onCreateCustomer,
        onSendReminder,
        createdInvoiceId,
        isMtlKycUpliftEnabled,
        onCompleteKycDetails: _onCompleteKycDetails,
        setAnalyticsProperties,
        ...props
      },
      ref
    ) => {
      const { tabs, activeTab, handleTabChange } = useArDashboardTabs();
      const { pathname } = useLocation();

      const { Paths, DefaultPath, goToInvoicesDashboard } = useDashboardFlowNavigation();

      const { isMissingKycComplianceInformation, isVerificationFailed } = useIsMissingKycComplianceInformation({
        enabled: isMtlKycUpliftEnabled,
      });

      const onCompleteKycDetails = _onCompleteKycDetails && (() => _onCompleteKycDetails(pathname));

      const kycBannerVariation = isMissingKycComplianceInformation
        ? 'complete-details'
        : isVerificationFailed
        ? 'verification-failed'
        : undefined;

      setAnalyticsProperties({
        PageName: 'dashboard',
        Flow: 'dashboard',
        TabName: activeTab,
      });

      return (
        <Routes>
          <Route
            element={
              <TabsLayout
                onVisitSupportSettingsPage={onVisitSupportSettingsPage}
                onCreateInvoice={onCreateInvoice}
                onCreateCustomer={onCreateCustomer}
                tabs={tabs}
                activeTab={activeTab}
                onChange={handleTabChange}
                kycBannerVariation={kycBannerVariation}
                onCompleteKycDetails={onCompleteKycDetails}
              />
            }
          >
            <Route index element={<Navigate to={DefaultPath} withSearchparams replace closeToast={false} />} />

            <Route
              path={Paths.Invoices}
              element={
                <InvoicesTableFlow
                  onPreviewInvoicePDF={onPreviewInvoicePDF}
                  createdInvoiceId={createdInvoiceId}
                  onEditInvoice={onEditInvoice}
                  onSendReminder={onSendReminder}
                  onError={onError}
                  {...props}
                  ref={ref}
                />
              }
            />

            <Route
              path={Paths.Customers}
              element={
                <CustomersTableFlow
                  onCreateInvoice={(customerId: string) => onCreateInvoice({ customerId })}
                  onDisplayCustomerInvoices={goToInvoicesDashboard}
                  onError={onError}
                  {...props}
                  ref={ref}
                />
              }
            />

            <Route path={Paths.Estimates} element={<TempEstimatesComp />} />
          </Route>
        </Routes>
      );
    }
  )
);

const TempEstimatesComp = () => (
  <Container data-testid="pay-dashboard-no-search-result" paddingY="xxxl">
    <Group spacing="s" alignItems="center" justifyContent="center" variant="vertical">
      <Illustration type="no-items" size="small" />
      <Text>
        <FormattedMessage id="ar.dashboard.activities.invoiceTable.tabs.estimates.comingSoon" />
      </Text>
    </Group>
  </Container>
);
