import { useEffect } from 'react';

import { PostMessageEventType } from './events';
import { partnerBridgeService, PostMessageEventFunction } from './PartnerBridgeService';

export function useInitPartnerBridge() {
  useEffect(() => {
    const cleanListeners = partnerBridgeService.init();
    return cleanListeners;
  }, []);
}

export function useListenToEvent(event: PostMessageEventType, cb: PostMessageEventFunction) {
  useEffect(() => {
    const unlisten = partnerBridgeService.listenToMessage(event, cb);
    return unlisten;
  }, [event, cb]);
}

export function useSendMessage() {
  return partnerBridgeService.sendMessage;
}
