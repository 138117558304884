import { TabFilter } from '../components/Filters/types';
import { NestedFiltersProps } from './types';

export const getSelectedOptionsFromActiveFilter = <T extends string>(
  filter?: TabFilter<T>,
  activeFilters?: NestedFiltersProps<T>['activeFilters']
) => {
  if (!filter || !activeFilters) {
    return [];
  }
  return filter.options
    .filter((option) => activeFilters[filter.key]?.includes(option.value))
    .map((option) => option.value);
};

export const getFilterByKey = <T extends string>(filters: TabFilter<T>[], key: string) =>
  filters.find((f) => f.key === key);
