/* eslint-disable no-console */

import { PostMessageEvents, PostMessageEventType } from './events';

export type PostMessageEventFunction = <Event extends PostMessageEventType>(
  type: Event,
  payload: PostMessageEvents[Event]
) => void;

function getMessageId() {
  if ('undefined' != typeof crypto && 'function' == typeof crypto?.randomUUID) {
    return crypto.randomUUID();
  } else {
    // eslint-disable-next-line no-restricted-syntax
    return `${new Date().getTime()}-${Math.random().toString().substr(2, 10)}`;
  }
}
class PartnerBridgeService {
  private callbacks = {} as Record<string, Array<PostMessageEventFunction>>;
  public sendMessage: PostMessageEventFunction = (type, payload) => {
    const message = {
      ...payload,
      messageId: getMessageId(),
      type,
    };
    console.log(`Posting message to parent - type: ${type}, messageId: ${message.messageId}`, { payload });
    window.parent.postMessage(message, '*');
  };
  listenToMessage = (type: PostMessageEventType, cb: PostMessageEventFunction) => {
    this.callbacks[type] = (this.callbacks[type] || []).concat(cb);
    return () => {
      this.callbacks[type] = (this.callbacks[type] || []).filter((c) => c !== cb);
    };
  };

  init = () => {
    const listenerCallback = (event: MessageEvent) => {
      if (event.source === window.parent && window !== window.parent) {
        if (typeof event.data === 'object' && 'type' in event.data) {
          const { type, ...payload } = event.data as { type: PostMessageEventType } & Record<string, unknown>;
          const callbacks = this.callbacks[type];
          if (!callbacks) {
            console.error(`Unknown post message type from parent - ${type}`, event.data);
          } else {
            console.log(`Received postMessage from parent of type - ${type}`, { payload });
            callbacks.forEach((cb) => cb(type, payload as PostMessageEvents[typeof type]));
          }
        } else {
          console.error('Unknown post message format from parent', event.data);
        }
      }
    };
    window.addEventListener('message', listenerCallback);
    return () => window.removeEventListener('message', listenerCallback);
  };
}

export const partnerBridgeService = new PartnerBridgeService();
