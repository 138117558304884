import { ReconciliationForm, ReconciliationFormFields, useAccountingPlatformName } from '@melio/ap-widgets';
import { Group, LoadingContainer, Modal, ModalProps, Text, useFormSubmissionController } from '@melio/penny';
import { AccountingPlatform, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { SystemMessageDisplay, useSystemMessage } from '@melio/platform-utils';
import { useCallback, useEffect, useState } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { FundingSourceFlatContainerCard } from '../FundingSourceFlatContainerCard/FundingSourceFlatContainerCard.widget';

export type ReconciliationModalScreenProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  onLinkFundingSource?: (accountingPlatformPaymentAccountId: string) => Promise<void>;
  onCreateNewPaymentAccount?: (newPaymentAccountName: string) => Promise<void>;
  fundingSource?: FundingSource;
  activeAccountingPlatform?: AccountingPlatform;
  isLoading?: boolean;
  isSaving?: boolean;
};

export const ReconciliationModalScreen = (props: ReconciliationModalScreenProps) => {
  const {
    fundingSource,
    activeAccountingPlatform,
    isLoading,
    isSaving,
    onLinkFundingSource,
    onCreateNewPaymentAccount,
    ...rest
  } = props;

  const { formatMessage } = useMelioIntl();
  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
  const [shouldCreateNewPaymentAccount, setCreateNewPaymentAccount] = useState(false);
  const newPaymentAccountName = fundingSource?.displayName || '';
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const { showMessage } = useSystemMessage();
  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
    endAction('goods_received_flow');
    endAction('vendor_missing_details_flow');
    endAction('mcc_flow');
  }, [endAction]);

  const onSubmit = ({ accountingPlatformPaymentAccountId }: { accountingPlatformPaymentAccountId: string }) => {
    startAction('reconciliation_flow');

    if (shouldCreateNewPaymentAccount) {
      onCreateNewPaymentAccount?.(newPaymentAccountName);
    } else {
      onLinkFundingSource?.(accountingPlatformPaymentAccountId);
    }
  };

  useEffect(() => {
    if (!props.isOpen) {
      setCreateNewPaymentAccount(false);
    }
  }, [props.isOpen]);

  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<ReconciliationFormFields>();

  const accountTypeText =
    fundingSource?.type === 'bank-account'
      ? 'bank account'
      : fundingSource?.type === 'card'
      ? `${fundingSource?.details?.type} card`
      : 'account';

  return (
    <Modal
      ref={endActionMonitoring}
      {...rest}
      data-testid="funding-source-selection-activity-reconciliation-selection-modal-screen"
      header={formatMessage('activities.reconciliationModalScreen.header', {
        accountingPlatform: accountingPlatformName,
        accountType: accountTypeText,
      })}
      primaryButton={{
        ...submitButtonProps,
        variant: 'primary',
        label:
          fundingSource?.type === 'bank-account'
            ? formatMessage('activities.reconciliationModalScreen.continue.bankAccount')
            : formatMessage('activities.reconciliationModalScreen.continue.card'),
      }}
    >
      <Group variant="vertical">
        <SystemMessageDisplay />
        <Text>
          {formatMessage('activities.reconciliationModalScreen.description', {
            accountingPlatform: accountingPlatformName,
          })}
        </Text>
        <LoadingContainer isLoading={isLoading}>
          <Group variant="vertical" spacing="s">
            {fundingSource && <FundingSourceFlatContainerCard fundingSource={fundingSource} />}
            <ReconciliationForm
              fundingSourceType={fundingSource?.type}
              newPaymentAccountName={newPaymentAccountName}
              onCreateOption={() => {
                setCreateNewPaymentAccount(true);
                showMessage({
                  type: 'informative',
                  title: formatMessage('activities.reconciliationModalScreen.infoBanner', {
                    fundingSourceDisplayName: newPaymentAccountName,
                    accountingPlatform: accountingPlatformName,
                  }),
                });
              }}
              onSubmit={onSubmit}
              isSaving={isSaving}
              onSubmissionStateChange={onSubmissionStateChange}
              defaultValues={{ accountingPlatformPaymentAccountId: fundingSource?.accountingPlatformPaymentAccountId }}
            />
          </Group>
        </LoadingContainer>
      </Group>
    </Modal>
  );
};

ReconciliationModalScreen.displayName = 'FundingSourceSelectionActivity.ReconciliationModalScreen';
