// https://meliorisk.atlassian.net/browse/ME-67423
// this is a copy paste of `packages/ap/domain/src/hooks/funding-source/useFundingSourceIconOrImage.tsx`, consider extracting to a shared package

import { BrandSymbol, BrandSymbolKey, getBrandSymbolsMap, Icon, IconKey, Image } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';

const verifyValidBase64Logo = (logo: string) => {
  const splitRes = logo.split(',');

  return splitRes.length === 2 && splitRes[1] !== 'default';
};

export const getFundingSourceIconOrImage = (fundingSource: FundingSource) => {
  const imageProps = getFundingSourceImageProps(fundingSource);
  if (imageProps) {
    return <Image width={8} height={8} {...imageProps} />;
  }

  const iconType = getFundingSourceIconType(fundingSource);

  const isBrandSymbol = Object.keys(getBrandSymbolsMap('')).includes(iconType as BrandSymbolKey);

  return isBrandSymbol ? (
    <BrandSymbol type={iconType as BrandSymbolKey} />
  ) : (
    <Icon type={iconType as IconKey} color="inherit" aria-hidden="true" />
  );
};

export const useFundingSourceIconOrImage = (fundingSource: FundingSource) => getFundingSourceIconOrImage(fundingSource);

export const getFundingSourceImageProps = (fundingSource: FundingSource) => {
  if (fundingSource.type === 'bank-account' && fundingSource.logo && verifyValidBase64Logo(fundingSource.logo)) {
    return { src: fundingSource.logo, alt: 'Account provider logo' };
  }
  return undefined;
};

export const getFundingSourceIconType = (fundingSource: FundingSource): IconKey | BrandSymbolKey => {
  const { type: fundingSourceType } = fundingSource;

  if (fundingSourceType === 'bank-account') {
    return 'bank';
  }

  if (fundingSourceType === 'flex-account') {
    return 'flex';
  }

  if (fundingSourceType === 'paypal-balance') {
    return 'bank';
  }

  const { network, type: cardType } = fundingSource.details;
  if (network) {
    const supportedCardCompanies = ['visa', 'amex-logo', 'mastercard', 'discover'] as BrandSymbolKey[];

    return supportedCardCompanies.find((item) => network.includes(item)) || 'credit-card';
  } else {
    return cardType == 'debit' ? 'debit-card' : 'credit-card';
  }
};
