import { forwardRef } from '@melio/platform-utils';

import { GetStartedScreen } from './screens';

export type GetStartedActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
};

export const GetStartedActivity = forwardRef<GetStartedActivityProps>(({ onDone, onClose }, ref) => (
  <GetStartedScreen ref={ref} onDone={onDone} onClose={onClose} />
));

GetStartedActivity.displayName = 'GetStartedActivity';
