import { Box } from '@chakra-ui/react';
import { useMelioIntl } from '@melio/ar-domain';
import { ActionBar } from '@melio/penny';

export type InvoiceSummaryFooterProps = {
  totalCount: number;
  totalAmount: number;
  isOpen: boolean;
  isLoading?: boolean;
};

export const InvoiceSummaryFooter = ({ totalCount, totalAmount, isOpen, isLoading }: InvoiceSummaryFooterProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <>
      {/* Fills space on the DOM equals to gap required by pagination controls in order for ActionBar footer to respect it due to it's fixed position  */}
      {isOpen ? <Box height="70px" /> : null}
      <ActionBar
        id="invoice-summary-footer"
        maxWidth={1400}
        isLoading={isLoading}
        isOpen={isOpen}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        position="fixed"
        summaryItems={[
          {
            label: formatMessage('ar.dashboard.activities.invoiceTable.footer.totalCount.label'),
            value: totalCount.toString(),
            testId: 'invoice-summary-footer-total-count',
          },
          {
            label: formatMessage('ar.dashboard.activities.invoiceTable.footer.totalAmount.label'),
            value: totalAmount.toString(),
            testId: 'invoice-summary-footer-total-amount',
          },
        ]}
      />
    </>
  );
};
