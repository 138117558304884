import { UnilateralRequestMethodCard } from '@melio/ap-widgets';
import { useUnilateralRequests, Vendor } from '@melio/platform-api';

type UnilateralRequestDetailsProps = {
  vendor: Vendor;
};
export const UnilateralRequestDetails = ({ vendor }: UnilateralRequestDetailsProps) => {
  const { data: unilateralRequests } = useUnilateralRequests({ vendorId: vendor.id });
  return <UnilateralRequestMethodCard vendor={vendor} unilateralRequests={unilateralRequests} />;
};
