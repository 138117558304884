import {
  BillsSyncConnectResponseData,
  BillsSyncStatus,
  BillsSyncStatusEnum,
  PostBillsSyncRequest,
} from '@melio/platform-api-axios-client';

type ProviderConnect = (data: PostBillsSyncRequest) => Promise<BillsSyncConnectResponseData>;

export function makeConnect(providerConnect: ProviderConnect): (returnUrl: string) => Promise<void> {
  return async (returnUrl: string): Promise<void> => {
    const request: PostBillsSyncRequest = {
      continueUrl: returnUrl,
    };

    const syncConnectResponse = await providerConnect(request);

    if (window.top && window.self !== window.top) {
      window.top.location.href = syncConnectResponse.authorizationUri;
    } else {
      window.location.href = syncConnectResponse.authorizationUri;
    }
  };
}

export function isConnected(status: BillsSyncStatus | undefined): boolean {
  return (
    [BillsSyncStatusEnum.InProgress, BillsSyncStatusEnum.NotStarted, BillsSyncStatusEnum.Done] as (
      | BillsSyncStatusEnum
      | undefined
    )[]
  ).includes(status?.syncStatus);
}
