import { getInternationalFormattedIdentifierData } from '@melio/ap-domain';
import { BrandSymbolKey, FlagIcon, FlagKey, IconKey } from '@melio/penny';
import {
  BankAccountDeliveryMethod,
  Card,
  DeliveryMethod,
  DeliveryMethodType,
  formatAddress,
  PaymentTimelineItem,
  PaymentTimelineItemFlowEnum,
  PaymentTimelineItemStatusEnum,
  Vendor,
} from '@melio/platform-api';

import { FormattedMessage, usePlatformIntl } from '@/translations/Intl';

export const getDeliveryMethodIconKey = (
  deliveryMethod: DeliveryMethod,
  icons: { [key: string]: BrandSymbolKey },
): IconKey | BrandSymbolKey => {
  switch (deliveryMethod.type) {
    case 'bank-account':
      return 'bank';
    case 'paper-check':
      return 'paper-check';
    case 'domestic-wire-account':
      return 'bank';
    case 'virtual-account':
      return 'email';
    case 'virtual-card':
      return icons['virtualCard'];
    case 'biller-account':
    case 'managed-account':
      return 'directory-payment';
    case 'international-account':
      return 'international';
    case 'card': {
      return (deliveryMethod.details as Card).type === 'debit' ? 'debit-card' : 'credit-card';
    }
  }
};

export const useGetDeliveryMethodLabelText = (
  deliveryMethod: DeliveryMethod,
  timeline: Array<PaymentTimelineItem>,
): string => {
  const { formatMessage } = usePlatformIntl();

  switch (deliveryMethod.type) {
    case 'bank-account':
      return formatMessage('utils.deliveryMethod.bankAccount.label');
    case 'paper-check': {
      const tracingNumber = extractTracingNumber(timeline);
      if (tracingNumber) {
        return formatMessage('utils.deliveryMethod.paperCheck.labelScheduled', { tracingNumber });
      }
      return formatMessage('utils.deliveryMethod.paperCheck.label');
    }
    case 'domestic-wire-account':
      return formatMessage('utils.deliveryMethod.domesticWireAccount.label');
    case 'virtual-account':
      return formatMessage('utils.deliveryMethod.unilateral.label');
    case 'card':
      return formatMessage(
        `utils.deliveryMethod.${(deliveryMethod.details as Card).type === 'debit' ? 'debitCard' : 'creditCard'}.label`,
      );
    case 'virtual-card':
      return formatMessage('utils.deliveryMethod.virtualCard.label');
    case 'biller-account':
    case 'managed-account':
      return formatMessage('utils.deliveryMethod.billerAccount.label');
    case 'international-account':
      return formatMessage('utils.deliveryMethod.internationalAccount.label');
    default:
      return 'unsupported';
  }
};

export const useGetDeliveryMethodDisplayText = (deliveryMethod: DeliveryMethod, vendorName: string): string => {
  const { formatMessage } = usePlatformIntl();

  switch (deliveryMethod.type) {
    case 'bank-account': {
      if (deliveryMethod.isManaged) {
        return formatMessage('utils.deliveryMethod.bankAccount.managedDescription');
      }
      return formatMessage('utils.deliveryMethod.bankAccount.description', {
        accountNumber: deliveryMethod.details.accountNumber.slice(-4) || '',
        accountName: deliveryMethod.details.accountType,
      });
    }
    case 'paper-check':
      return formatMessage('utils.deliveryMethod.paperCheck.description', {
        address: formatAddress(deliveryMethod.details.address),
      });
    case 'domestic-wire-account':
      return formatMessage('utils.deliveryMethod.domesticWireAccount.description', {
        accountName: vendorName,
        accountNumber: deliveryMethod.details.accountNumber.slice(-4) || '',
      });
    case 'card':
      return formatMessage(
        `utils.deliveryMethod.${
          (deliveryMethod.details as Card).type === 'debit' ? 'debitCard' : 'creditCard'
        }.description`,
        { vendorName },
      );

    case 'virtual-account':
      return formatMessage('utils.deliveryMethod.unilateral.description', { email: deliveryMethod.details.email });
    case 'virtual-card':
      return formatMessage('utils.deliveryMethod.virtualCard.description', {
        email: deliveryMethod.details.accountEmail,
      });
    case 'biller-account':
    case 'managed-account':
      return formatMessage('utils.deliveryMethod.billerAccount.description');
    case 'international-account': {
      return formatMessage(
        'utils.deliveryMethod.internationalAccount.description',
        getInternationalFormattedIdentifierData(deliveryMethod),
      );
    }
    default:
      return '';
  }
};

export const useGetDeliveryMethodHelperText = (
  deliveryMethod: DeliveryMethod,
  timeline: Array<PaymentTimelineItem>,
  countryDisplayName?: string,
): string => {
  const { formatMessage } = usePlatformIntl();

  switch (deliveryMethod.type) {
    case 'card':
      return formatMessage(
        `utils.deliveryMethod.${
          (deliveryMethod.details as Card).type === 'debit' ? 'debitCard' : 'creditCard'
        }.helperText`,
      );
    case 'bank-account': {
      const tracingNumber = extractTracingNumber(timeline);
      if (tracingNumber) {
        return formatMessage('utils.deliveryMethod.bankAccount.trace', {
          tracingNumber,
        });
      }
      return '';
    }
    case 'international-account': {
      return formatMessage('utils.deliveryMethod.internationalAccount.helperText', {
        country: countryDisplayName || deliveryMethod.details.identifierDetails.bankCountryCode,
        flag: (
          <FlagIcon countryCode={deliveryMethod.details.identifierDetails.bankCountryCode as FlagKey} size="small" />
        ),
      });
    }
    default:
      return '';
  }
};

export const getDeliveryEtaHelperText = (deliveryMethod: DeliveryMethod) => {
  switch (deliveryMethod.type) {
    case DeliveryMethodType.BankAccount:
      return <FormattedMessage id="utils.deliveryMethod.bankAccount.etaHelperText" />;
    case DeliveryMethodType.VirtualAccount:
      return <FormattedMessage id="utils.deliveryMethod.unilateral.etaHelperText" />;
    default:
      return '';
  }
};

/**
 * if vendor has a method of provided ype, return it
 * @param vendor
 * @param type
 */
export const existingMethod = (vendor: Vendor | undefined, type: DeliveryMethodType) =>
  vendor?.deliveryMethods?.find((dm: DeliveryMethod) => dm.type === type);

export const isAchDeliveryMethod = (dm: DeliveryMethod | undefined): dm is BankAccountDeliveryMethod =>
  dm?.type === DeliveryMethodType.BankAccount;

export const getManagedDeliveryMethod = (deliveryMethods: DeliveryMethod[]) =>
  deliveryMethods?.find((deliveryMethod) => deliveryMethod.isManaged);

const extractTracingNumber = (timeline: Array<PaymentTimelineItem>) =>
  timeline.find(
    (item) =>
      item.flow === PaymentTimelineItemFlowEnum.Deliver &&
      item.tracingNumber &&
      item.status !== PaymentTimelineItemStatusEnum.Failed,
  )?.tracingNumber;
