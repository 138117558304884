import { RoleUniqueNames } from '@melio/platform-api';
import { PartialMelioConfig } from '@melio/platform-provider';

import { melioLinks } from '../links';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

const defaultMelioConfig: PartialMelioConfig = {
  tabTitle: 'Melio',
  settings: {
    Brand: { color: { enabled: true, flagName: 'organization-brand-color-selection' }, logo: { enabled: true } },
    supportEmail: 'support@melio.com',
    collaborator: {
      rolesOrderMapping: {
        [RoleUniqueNames.MelioOwner]: 1,
        [RoleUniqueNames.MelioAdmin]: 2,
        [RoleUniqueNames.MelioAccountant]: 3,
        [RoleUniqueNames.MelioContributor]: 4,
      },
    },
    fundingSourcePolicy: {
      'flex-account': {
        order: 0,
        fundingSourceGroup: 'flex-account',
        read: true,
        write: true,
      },
      'bank-account': {
        order: 1,
        fundingSourceGroup: 'bank-account',
        read: true,
        write: true,
      },
      credit: {
        order: 2,
        fundingSourceGroup: 'card',
        read: true,
        write: true,
      },
      debit: {
        order: 3,
        fundingSourceGroup: 'card',
        read: true,
        write: true,
      },
    },
    vex: {
      unilateral: {
        isEmailLoginEnabled: true,
        shouldSuggestJoinMelio: true,
        isNewUnilateral: true,
        suvcInformationLink: 'https://meliopayments.com/guides/single-use-virtual-cards/',
      },
    },
    subscriptions: {
      isGracePeriodSupported: true,
      claimSuggestedPlan: true,
      links: {
        seeAllFeatures: 'https://meliopayments.com/pricing/',
        learnMore: {
          smb: 'https://go.meliopayments.com/learnmore/smb',
          accountingClients: 'https://go.meliopayments.com/learnmore/clients',
          accountingFirmsV2: 'https://go.meliopayments.com/learnmore/accountants-new',
          accountingFirmsV1: 'https://go.meliopayments.com/learnmore/accountants',
        },
      },
      isPaymentFeeFailedGracePeriodEnabled: true,
    },
    settings: {
      options: [
        {
          type: 'company',
          items: [
            'profile-settings',
            'company-settings',
            'collaborators-setting',
            'notification-preferences',
            'workflows-setting',
            'accounting-software-sync',
            'tax-and-reports',
          ],
        },
        {
          type: 'payments',
          items: [
            'payment-methods-setting',
            'subscription-plans-setting',
            'billing-setting',
            'receiving-methods-settings',
          ],
        },
        {
          type: 'ar',
          items: ['receiving-methods-settings', 'invoice-items', 'invoice-settings', 'invoice-email-notifications'],
        },
        {
          type: 'support',
          items: ['support-setting'],
        },
      ],
    },
    guestPaymentFlow: {
      shouldGenerateDeepLinkToPaymentRequestPayDashboard: true,
    },
    InternationalFx: {
      enabled: true,
    },
    UploadVendorsCsv: {
      enabled: true,
      flagName: 'platform-upload-vendors-csv',
    },
    EnableInternationalUSDWise: {
      enabled: true,
      flagName: 'enable-international-usd-wise',
    },
    MarkAsPaid: {
      enabled: true,
    },
    File1099SettingsTaxAndReports: {
      flagName: 'platform-feature-1099-tax-and-reports-settings',
      enabled: true,
      assets: {
        zenworkTax1099Logo:
          'https://s3.us-east-1.amazonaws.com/meliopayments.com-static-assets/emails/zenwork-tax1099-logo.png',
        zenwork1099SeasonBanner:
          'https://s3.us-east-1.amazonaws.com/meliopayments.com-static-assets/emails/1099-season-banner.svg',
        zenworkSyncWithTax1099Banner:
          'https://s3.us-east-1.amazonaws.com/meliopayments.com-static-assets/emails/sync-with-tax-1099-banner.png',
      },
    },
    Import1099Contractors: {
      flagName: '1099-import-contractors',
      enabled: true,
    },
    File1099PreSeasonBanner: {
      flagName: '1099-pre-season-banners-dashboard',
      enabled: true,
    },
    OutdatedSessionModal: {
      enabled: true,
      flagName: 'platform-outdated-session-modal',
    },
    BillingFeeSettingsForExternalAccountants: {
      flagName: 'enable-billing-fee-settings-for-external-accountant',
      enabled: true,
    },
    ExternalAccountantMandatoryPlanRestriction: {
      flagName: 'enable-external-accountant-mandatory-plan-restriction',
      enabled: true,
    },
    eoyCheck: {
      enabled: true,
      flagName: 'us-holidays-checks-partners',
      promoteFastCheck: true,
      link: 'https://melio.zendesk.com/hc/en-us/articles/6548872564508-USPS-potential-check-delivery-delays-during-the-holidays',
    },
    DeliveryMethodPublishNewEvents: {
      flagName: 'publish-delivery-method-new-events',
      enabled: true,
    },
    DisplayCancelPaymentsBannerInCancelFlowForClients: {
      flagName: 'display-cancel-payments-banner-in-cancel-flow-for-clients',
      enabled: true,
    },
    IsOrgOwesUsMoneyBannerEnabled: {
      flagName: 'is-org-owes-us-money-banner-enabled',
      enabled: true,
    },
  },
  services: {
    forethoughtApiKey: '',
  },
  zendesk: {
    useNewWidgetApi: true,
  },
  links: melioLinks,
  translationsSrc: 'https://platform-static.meliopayments.com/assets/melio/messages.json',
  feedback: {
    triggers: {
      payment_success: {
        enabled: true,
        userSnapProjectId: 'aa15a60f-b879-43f4-8c8e-cd56bb1e1143',
      },
    },
  },
  requestCallback: {
    isEnabled: true,
    notes: { after: ['callRecorded', 'callFrom'] },
  },
};

const prodMelioConfig: PartialMelioConfig = {
  services: {
    zendeskKey: '51fdfad6-7341-4cb2-8eb8-88ccece82bbc',
    zenwork: {
      clientId: 'UGNH8O92LSGG2GF2ZCQP8U4UX4Z6MD8A',
      melioRedirectUrl: 'https://app.melio.com/melio/import-contractors',
      formsDashboardUrl: 'https://web.tax1099.com/#/forms/listing/form1099',
      identityServiceUrl: 'https://identityservice.tax1099.com/connect/authorize',
    },
  },
};

export const melioPartialConfig: PartnerConfigByEnv = {
  default: defaultMelioConfig,
  dev: {},
  prod: prodMelioConfig,
};

export const melioConfig = extendDefaultConfig(melioPartialConfig);
