import { EmptyState, FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type CatalogItemsZeroStateProps = {
  onAddItem: VoidFunction;
  onImportCatalogItems: VoidFunction;
};

export const CatalogItemsZeroState = forwardRef<CatalogItemsZeroStateProps>(
  ({ onImportCatalogItems, onAddItem }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <EmptyState
        ref={ref}
        illustrationType="no-items"
        data-testid="catalog-items-zero-state"
        content={
          <Container textAlign="center">
            <Text textStyle="body3" color="semantic.text.secondary">
              <FormattedMessage id="ar.settings.activities.catalogItemsTable.components.catalogItemsTable.zeroState.message.text" />
            </Text>
          </Container>
        }
        footer={
          <Group>
            <Button
              variant="tertiary"
              data-testid="catalog-items-zero-state-import-items-button"
              label={formatMessage(
                'ar.settings.activities.catalogItemsTable.components.catalogItemsTableToolbar.zeroState.actions.importItems.label'
              )}
              onClick={onImportCatalogItems}
            />
            <Button
              variant="secondary"
              data-testid="catalog-items-zero-state-add-catalog-item-button"
              label={formatMessage(
                'ar.settings.activities.catalogItemsTable.components.catalogItemsTableToolbar.zeroState.actions.addItem.label'
              )}
              onClick={onAddItem}
            />
          </Group>
        }
      />
    );
  }
);
CatalogItemsZeroState.displayName = 'CatalogItemsZeroState';
