import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { ApiKeys, DeliveryMethodTypeOptionsApiClient, Vendor } from '@melio/platform-api-axios-client';

export type UseVendorDeliveryMethodTypeOptionsProps = UseCollectionApiProps<
  typeof DeliveryMethodTypeOptionsApiClient.getVendorsVendorIdDeliveryMethodTypeOptions
> & {
  vendorId: Vendor['id'];
};

export const useVendorDeliveryMethodTypeOptions = ({ vendorId, ...props }: UseVendorDeliveryMethodTypeOptionsProps) => {
  const query = useCollectionApi<typeof DeliveryMethodTypeOptionsApiClient.getVendorsVendorIdDeliveryMethodTypeOptions>(
    {
      ...props,
      queryKey: [ApiKeys.DeliveryMethodTypeOptionsApi, vendorId],
      queryFn: () => DeliveryMethodTypeOptionsApiClient.getVendorsVendorIdDeliveryMethodTypeOptions(vendorId),
    }
  );

  return { ...query };
};
