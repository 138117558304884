import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { Organization } from '@melio/platform-api';

import { SelectCompanyScreen } from './screens/SelectCompany.screen';

type PaymentRequestSelectCompanyActivityProps = {
  organizations: Organization[];
  vendorName: string;
  selectOrganization: (orgId: string) => void;
  paymentRequestRef: string;
};

export const PaymentRequestSelectCompanyActivity = withAnalyticsContext<PaymentRequestSelectCompanyActivityProps>(
  ({ organizations, vendorName, selectOrganization, paymentRequestRef, setAnalyticsProperties }) => {
    const { track } = useAnalytics();

    setAnalyticsProperties({
      PaymentRequestLink: paymentRequestRef,
      ProductName: 'ar',
      PageName: 'pick-organization',
      Flow: 'payment-request',
      Intent: 'multi-org-pick-organization',
    });

    useAnalyticsView('User', true, true);

    const handleSelectOrganization = (organizationId: string) => {
      track('User', 'Click', { Cta: 'continue' });

      return selectOrganization(organizationId);
    };

    return (
      <SelectCompanyScreen
        organizations={organizations}
        vendorName={vendorName}
        selectOrganization={handleSelectOrganization}
      />
    );
  }
);

PaymentRequestSelectCompanyActivity.displayName = 'PaymentRequestSelectCompanyActivity';
