import {
  AccountingPlatformConnectionStatus,
  AccountingPlatformVerifyQuickBooksDesktopConnectionResponseData,
  VerifyQuickBooksDesktopConnectionStatus,
} from '@melio/platform-api';

export const isQuickBooksDesktopUnavailable = (
  data?: AccountingPlatformVerifyQuickBooksDesktopConnectionResponseData
) => data?.connectionStatus === VerifyQuickBooksDesktopConnectionStatus.NotAvailable;

export const isAccountingPlatformConnected = (connectionStatus?: AccountingPlatformConnectionStatus) =>
  connectionStatus === AccountingPlatformConnectionStatus.Connected;
