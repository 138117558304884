import { useMelioIntl } from '@melio/platform-i18n';

export const useFormatExpirationDate = () => {
  const { formatDate } = useMelioIntl();

  const formatExpirationDate = ({ month, year }: { month: string; year: string }) => {
    const expirationDateStr = `${year}-${month}-15T12:00:00.000Z`;

    return formatDate(expirationDateStr, {
      month: 'numeric',
      year: '2-digit',
    });
  };

  return { formatExpirationDate };
};
