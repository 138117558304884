import { Container, Group } from '@melio/penny';
import { Loan, Payment } from '@melio/platform-api';

import { InstallmentsSection } from '../sections/InstallmentsSection';
import { LoanFailedSectionBanner } from '../sections/LoanFailedSectionBanner';
import { LoanProviderSection } from '../sections/LoanProviderSection';
import { PayBySection } from '../sections/PayBySection';
import { RepaymentSection } from '../sections/RepaymentSection';
import { SectionWrapper } from '../sections/SectionWrapper';

export const LoanFailed = ({ payment }: { payment: Override<Payment, { loan: Loan }> }) => (
  <>
    <Container paddingY="m">
      <Group variant="vertical" hasDivider spacing="m">
        <SectionWrapper>
          <Group variant="vertical" spacing="m">
            <LoanFailedSectionBanner payment={payment} />
            <RepaymentSection payment={payment} />
          </Group>
        </SectionWrapper>
        <SectionWrapper>
          <InstallmentsSection loan={payment.loan} />
        </SectionWrapper>
        <SectionWrapper>
          <PayBySection payment={payment} />
        </SectionWrapper>
        <SectionWrapper>
          <LoanProviderSection />
        </SectionWrapper>
      </Group>
    </Container>
  </>
);
