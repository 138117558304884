import { Route, Routes } from 'react-router-dom';

import {
  SubscriptionSelectFundingSource,
  SubscriptionsSelectFundingSourceError,
  SubscriptionsSelectFundingSourceSuccess,
} from '../ui';

export const SubscriptionPaymentMethodRoute = () => (
  <Routes>
    <Route path="/select" element={<SubscriptionSelectFundingSource />} />
    <Route path="/select/success" element={<SubscriptionsSelectFundingSourceSuccess />} />
    <Route path="/select/error" element={<SubscriptionsSelectFundingSourceError />} />
  </Routes>
);
