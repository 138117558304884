import { MethodCard } from '@melio/ap-widgets';
import { BrandSymbol, Link } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodType, Vendor, VirtualCardAccount } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import React from 'react';

import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';

type ManagedVirtualCardDeliveryMethodCardProps = {
  vendor: Vendor;
  virtualCard: DeliveryMethod;
};

const useManagedVirtualCardMessages = ({ vendor, virtualCard }: ManagedVirtualCardDeliveryMethodCardProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const {
    settings: { virtualCardLearnMoreLink },
  } = useConfig();

  const title = formatMessage('widgets.addOrUpdateDeliveryMethod.managedVirtualCard.title');
  const helperText = formatMessage('widgets.addOrUpdateDeliveryMethod.managedVirtualCard.feeText');
  const additionalInfo = formatMessage('widgets.addOrUpdateDeliveryMethod.managedVirtualCard.info.additionalInfo', {
    accountEmail: (virtualCard.details as VirtualCardAccount).accountEmail,
  });
  const secondaryAdditionalInfo = formatMessage(
    'widgets.addOrUpdateDeliveryMethod.managedVirtualCard.info.secondaryAdditionalInfo',
    {
      vendorName: vendor.name,
      creationDate: formatDate(virtualCard.history.createdAt, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
      learnMoreLink: (
        <Link
          href={virtualCardLearnMoreLink}
          label={formatMessage('widgets.addOrUpdateDeliveryMethod.managedVirtualCard.info.learnMoreText')}
          newTab
        />
      ),
    }
  );

  return { title, helperText, additionalInfo, secondaryAdditionalInfo };
};

export const ManagedVirtualCardDeliveryMethodCard = ({
  vendor,
  virtualCard,
}: ManagedVirtualCardDeliveryMethodCardProps) => {
  const { title, helperText, additionalInfo, secondaryAdditionalInfo } = useManagedVirtualCardMessages({
    vendor,
    virtualCard,
  });
  return (
    <MethodCard
      icon={<BrandSymbol type="mastercard" />}
      displayName={title}
      helperText={helperText}
      data-component="ManagedVirtualCardDeliveryMethodCard"
      testIds={{
        root: `MethodCard-${DeliveryMethodType.VirtualCard}`,
        header: `method-card-type-${DeliveryMethodType.VirtualCard}`,
      }}
    >
      <DeliveryMethodCaption primary={additionalInfo} secondary={secondaryAdditionalInfo} />
    </MethodCard>
  );
};
