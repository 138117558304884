import { Box } from '@chakra-ui/react';
import { IconButton, IconButtonProps, Tooltip, TooltipProps } from '@melio/penny';

type SyncNowIconButtonProps = {
  onClick: VoidFunction;
  isDisabled: boolean;
  ariaLabel: string;
  variant?: IconButtonProps['variant'];
  tooltipTestId?: string;
  tooltipTitle?: string;
  tooltipLabel?: string;
  placement?: TooltipProps['placement'];
};

export const SyncNowIconButton = ({
  onClick,
  isDisabled,
  ariaLabel,
  tooltipTestId,
  tooltipTitle,
  placement,
  tooltipLabel,
  variant = 'primary',
}: SyncNowIconButtonProps) => {
  const handleOnClick = () => {
    if (isDisabled) {
      return;
    }
    onClick();
  };

  // it's a workarround ADA fix for Android devices
  const tooltipContentForAndroid =
    tooltipTitle || tooltipLabel ? (
      <Box display="none" id="sync-now-button-tooltip-content">
        {tooltipTitle}
        {tooltipLabel}
      </Box>
    ) : null;

  return tooltipTitle || tooltipLabel ? (
    <>
      <Tooltip
        data-testid={tooltipTestId}
        content={
          <>
            {tooltipTitle ? (
              <Box as="span" display="inline-flex" textStyle="body4Semi">
                {tooltipTitle}
              </Box>
            ) : null}
            {tooltipLabel || ''}
          </>
        }
        placement={placement}
        dontDescribeChild
      >
        {/* isDisabled is not presented here since we need to have this button focusable during the sync process running */}
        <IconButton
          aria-label={ariaLabel}
          icon="refresh"
          onClick={handleOnClick}
          size={variant === 'naked' || variant === 'nakedInverse' ? 'medium' : 'large'}
          variant={variant}
          data-testid="sync-now-button"
          data-tourid="sync-now-button"
          aria-disabled={isDisabled ? 'true' : 'false'}
          aria-describedby="sync-now-button-tooltip-content"
        />
      </Tooltip>
      {tooltipContentForAndroid}
    </>
  ) : (
    <IconButton
      aria-label={ariaLabel}
      icon="refresh"
      onClick={onClick}
      size={variant === 'naked' || variant === 'nakedInverse' ? 'medium' : 'large'}
      variant={variant}
      isDisabled={isDisabled}
      data-testid="sync-now-button"
      data-tourid="sync-now-button"
    />
  );
};
