import {
  useEstimatedDeliveryDateLabel as useEstimatedDeliveryDate,
  useFastDeliveryBadge,
  useSectionBadge,
} from '@melio/ap-domain';
import { DeliveryPreference, FundingSource, PaymentFee } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { isAfter, isSameDay } from 'date-fns';
import { compact } from 'lodash';

import { PaymentReviewLineItem, PaymentReviewLineItemProps } from '../PaymentReviewLineItem';
import { RecurringPaymentProps } from '../ReviewAndConfirm';
import { useEstimatedDeliveryDateHelperText, useEstimatedDeliveryDateLabel } from './utils';

export type DeliveryDateListItemByPayorWidgetProps = {
  onEdit?: VoidFunction;
  paymentFees: PaymentFee[];
  deliveryPreference: DeliveryPreference;
  fundingSource: FundingSource;
  updatedFields?: string[];
  children?: never;
  dueDate?: Date;
  enableLateTag: boolean;
  recurringPayment?: RecurringPaymentProps;
};

export const DeliveryDateListItemByPayorWidget = forwardRef<DeliveryDateListItemByPayorWidgetProps, 'div'>(
  (
    {
      onEdit,
      paymentFees,
      deliveryPreference,
      fundingSource,
      updatedFields,
      dueDate,
      enableLateTag,
      recurringPayment,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const [eoyCheckEnabled] = usePartnerFeature('eoyCheck', false);
    const fastBadge = useFastDeliveryBadge(paymentFees);
    const updateBadge = useSectionBadge({
      isShowUpdatedBadge: !!updatedFields?.includes('effectiveDeliveryDate'),
      isDueDatePast: enableLateTag,
    });

    const mainBadges = compact([fastBadge, updateBadge]);

    const helperText = useEstimatedDeliveryDateHelperText(deliveryPreference, fundingSource, dueDate, enableLateTag);
    const mainLabel = useEstimatedDeliveryDateLabel(deliveryPreference.type);
    const { dateLabel } = useEstimatedDeliveryDate({
      minDeliveryDate: deliveryPreference.minDeliveryDate,
      maxDeliveryDate: deliveryPreference.maxDeliveryDate,
    });

    const isEndOfYearCheck = eoyCheckEnabled && deliveryPreference.type === 'check';
    const tooltipProps: PaymentReviewLineItemProps['mainLabelProps']['tooltipProps'] =
      !isEndOfYearCheck &&
      !!recurringPayment &&
      recurringPayment.firstPaymentDeliveryDate &&
      !isSameDay(recurringPayment.firstPaymentDeliveryDate, recurringPayment.startDate) &&
      isAfter(recurringPayment.firstPaymentDeliveryDate, recurringPayment.startDate)
        ? {
            content: formatMessage('widgets.deliveryDateListItemByPayor.laterDelivery.tooltip'),
            triggerStatus: 'warning',
          }
        : undefined;

    return (
      <PaymentReviewLineItem
        data-testid="delivery-date-list-item-by-payor-widget"
        data-component="DeliveryDateListItemByPayorWidget"
        labelProps={{ label: mainLabel }}
        mainLabelProps={{
          label: dateLabel,
          pillProps: mainBadges,
          ...(isEndOfYearCheck ? { statusIcon: 'warning' } : { tooltipProps }),
        }}
        {...(helperText && { descriptionProps: { label: helperText } })}
        icon={{ type: 'calendar-move' }}
        {...(onEdit && {
          onEdit,
          editText: formatMessage('widgets.deliveryDateListItemByPayor.edit'),
          editTestId: 'delivery-date-list-item-by-payor-edit-button',
        })}
        contentTestId="delivery-date-list-item-by-payor"
        ref={ref}
        {...props}
      />
    );
  }
);

DeliveryDateListItemByPayorWidget.displayName = 'DeliveryDateListItemByPayorWidget';
