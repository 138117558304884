import { useOrganizationPreferences } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

export const useIsArEnabled = (defaultValue = false) => {
  const [arDashboardEnabled, isLoading] = useDevFeature(FeatureFlags.ARDashboard, defaultValue);
  const { data: orgPreferences, isLoading: isOrgPreferencesLoading } = useOrganizationPreferences();
  const isArOrgPrefEnabled = orgPreferences?.find((item) => item.key === 'isArEnabled')?.value === 'true';
  const orgPrefValue = isOrgPreferencesLoading ? defaultValue : isArOrgPrefEnabled;

  return { isArEnabled: arDashboardEnabled || orgPrefValue, isLoading: isLoading || isOrgPreferencesLoading };
};
