import {
  calculateFundingSourcePermissionForGroup,
  getGroupedFundingSources,
  useCardWritePermissions,
} from '@melio/ap-domain';
import { Group } from '@melio/penny';
import { FundingSource, RepaymentTerms } from '@melio/platform-api';
import { FundingSourceGroupName, useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { FundingSourceTypesOption } from '../../../utils';
import { FinancingCard } from '../FinancingCard/FinancingCard.widget';
import { FundingSourceCardListContainer } from '../FundingSourceCardList/FundingSourceCardListContainer.widget';
import { getCardType, getFundingType } from './FundingSourceCardListOfLists.utils';

type BaseProps = {
  fundingSourceTypesOptions: FundingSourceTypesOption[];
  onAdd: (type: FundingSourceGroupName) => void;
  repaymentTerms?: RepaymentTerms;
  userId?: string;
  disableUpdatingFundingSource?: boolean;
};

type SelectFundingSourceProps = {
  selectedId?: FundingSource['id'];
  data?: FundingSource[];
  onSelect: (id: FundingSource['id']) => void;
  onVerify: (id: FundingSource['id']) => void;
  shouldAllowFinancingOption?: boolean;
  onSelectFinancingOption?: VoidFunction;
  isFinancingSelected?: boolean;
  isLoadingRepaymentTerms?: boolean;
  isRepaymentTermsError?: boolean;
};

export type FundingSourceCardListOfListsProps = StrictUnion<BaseProps | (BaseProps & SelectFundingSourceProps)>;

export const FundingSourceCardListOfLists = forwardRef<FundingSourceCardListOfListsProps>((props, ref) => {
  const {
    selectedId,
    data,
    onAdd,
    onSelect,
    onVerify,
    fundingSourceTypesOptions,
    shouldAllowFinancingOption,
    repaymentTerms,
    userId,
    onSelectFinancingOption,
    isFinancingSelected,
    isLoadingRepaymentTerms,
    isRepaymentTermsError,
    disableUpdatingFundingSource,
    ...rest
  } = props;
  const { fundingSourcePolicy = {} } = useConfig().settings;

  const groupedFundingSources = getGroupedFundingSources({
    fundingSources: data ?? [],
    fundingSourcesConfig: fundingSourcePolicy,
  });

  const shouldEnableCardWrite = useCardWritePermissions();

  const isFundingSourceTypeOptionExist = (type: FundingSourceGroupName) => {
    switch (type) {
      case 'bank-account':
      case 'flex-account':
      case 'paypal-balance':
        return fundingSourceTypesOptions.find((it) => it.type === type);
      case 'card':
      case 'credit':
      case 'debit':
        return fundingSourceTypesOptions.find((it) => it.type === 'card');
    }
  };
  return (
    <Group
      variant="vertical"
      data-testid="funding-source-card-list-of-lists"
      data-component="FundingSourceCardListOfLists"
      spacing="m"
      {...rest}
      ref={ref}
    >
      <FinancingCard
        repaymentTerms={repaymentTerms}
        shouldAllowFinancingOption={shouldAllowFinancingOption}
        onClick={onSelectFinancingOption}
        isSelected={isFinancingSelected}
        isLoading={isLoadingRepaymentTerms}
        isError={isRepaymentTermsError}
      />
      {props.data
        ? groupedFundingSources.map(({ type, fundingSources }) => {
            if (!isFundingSourceTypeOptionExist(type)) {
              return;
            }

            const { isWriteAllowed } = calculateFundingSourcePermissionForGroup({
              groupName: type,
              fundingSourceConfig: fundingSourcePolicy,
            });
            const fundingType = getFundingType(type);
            const getIsWriteAllowed = () => {
              if (fundingType === 'card' && !shouldEnableCardWrite) {
                return false;
              }
              return !!isWriteAllowed;
            };
            return (
              <FundingSourceCardListContainer
                data-testid={`funding-source-card-list-container-${type}`}
                key={type}
                fundingSources={fundingSources}
                selectedId={isFinancingSelected ? undefined : selectedId}
                onSelect={props.onSelect}
                fundingType={fundingType}
                cardType={getCardType(type)}
                onAdd={() => onAdd(type)}
                canAdd={getIsWriteAllowed()}
                onVerify={props.onVerify}
                fundingSourceTypesOptions={fundingSourceTypesOptions}
                userId={userId}
                disableUpdatingFundingSource={disableUpdatingFundingSource}
              />
            );
          })
        : groupedFundingSources.map((group) => {
            if (!isFundingSourceTypeOptionExist(group.type)) {
              return;
            }

            const { isWriteAllowed } = calculateFundingSourcePermissionForGroup({
              groupName: group.type,
              fundingSourceConfig: fundingSourcePolicy,
            });
            return (
              <FundingSourceCardListContainer
                key={group.type}
                data-testid={`funding-source-card-list-container-${group.type}`}
                fundingType={getFundingType(group.type)}
                cardType={getCardType(group.type)}
                onAdd={() => onAdd(group.type)}
                canAdd={!!isWriteAllowed}
                fundingSourceTypesOptions={fundingSourceTypesOptions}
                disableUpdatingFundingSource={disableUpdatingFundingSource}
              />
            );
          })}
    </Group>
  );
});

FundingSourceCardListOfLists.displayName = 'FundingSourceCardListOfLists';
