import { useEffect, useMemo } from 'react';
import { Image } from '@chakra-ui/react';
import { isEmpty, merge } from 'lodash';
import { set } from 'lodash/fp';
import { LocalStorageKeys } from '@melio/local-storage';
import { PartnerName } from '@melio/platform-api';
import { CONFIG_OVERRIDES_KEY } from '@melio/platform-provider';

import { PartnersConfiguration } from '@/partnersConfig';

export const usePartnerConfigOverrides = (partner?: PartnerName) => {
  const partnerConfig = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const themePartnerName =
      queryParams.get('theme') || sessionStorage.getItem(LocalStorageKeys.themeOverridePartnerName);

    if (themePartnerName && PartnersConfiguration[themePartnerName]) {
      const { theme, icons } = PartnersConfiguration[themePartnerName];
      return { theme, icons };
    }

    const customOverride =
      partner === PartnerName.Melio
        ? (JSON.parse(window.atob(queryParams.get('override') || '') || '{}') as Record<
            string,
            Record<string, unknown> | string
          >)
        : {};

    if (!isEmpty(customOverride)) {
      const baseConfig = PartnersConfiguration[partner!];
      const icons = customOverride?.icons
        ? Object.keys(customOverride.icons).reduce((result, key) => {
            const src = customOverride.icons[key] as string;
            return src ? { ...result, [key]: () => <Image src={src} alt={key} /> } : result;
          }, {})
        : {};

      return {
        ...(customOverride?.icons ? { icons: merge(baseConfig.icons, icons) } : {}),
        ...(customOverride?.theme ? { theme: merge(baseConfig.theme, customOverride.theme) } : {}),
        ...(customOverride?.displayName ? { displayName: customOverride.displayName } : {}),
      };
    }

    return {};
  }, [partner]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const themePartnerName = queryParams.get('theme');

    if (themePartnerName && PartnersConfiguration[themePartnerName]) {
      sessionStorage.setItem(LocalStorageKeys.themeOverridePartnerName, themePartnerName);
    }
  }, []);

  return partnerConfig;
};

export const getSettingsOverrides = () => {
  try {
    const settingsOverridesString = window.sessionStorage.getItem(CONFIG_OVERRIDES_KEY) || '{}';
    const settingsOverridesParams = JSON.parse(settingsOverridesString);
    const settingsOverrides = Object.entries(settingsOverridesParams).reduce(
      (acc, [pathInSettings, value]) => set(pathInSettings, value, acc),
      {},
    );
    return { settingsOverrides, settingsOverridesString };
  } catch (e) {
    return {};
  }
};
