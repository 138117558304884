import { BankAccountFormModel, useAchValidationSchema } from '@melio/ap-widgets';
import { useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  BankAccountDeliveryMethod,
  useDeliveryMethods,
  useGetDeliveryMethodUnmaskedAccountNumberQuery,
  Vendor,
} from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

import { useConfirmationModal } from '../useConfirmationModal';
import { useShowPaymentsMightBeAffectedBanner } from '../useShowPaymentsMightBeAffectedBanner';

export function useBankAccountDetailsFormModal({
  vendor,
  onDone,
}: {
  vendor?: Vendor;
  onDone: (deliveryMethod: BankAccountDeliveryMethod) => void;
}) {
  const { track } = useAnalytics();
  const {
    create,
    confirm,
    isMutating,
    _mutations: {
      create: { error: createError },
    },
  } = useDeliveryMethods({ enabled: false, vendorId: vendor?.id });
  const {
    settings: { unmaskVendorAccountNumber: shouldUnmaskAccountNumber },
  } = useConfig();
  const bankAccount = vendor?.deliveryMethods.find((method) => method.type === 'bank-account') as
    | BankAccountDeliveryMethod
    | undefined;

  const { data: bankAccountUnmaskedAccountNumber } = useGetDeliveryMethodUnmaskedAccountNumberQuery({
    deliveryMethodId: bankAccount?.id,
    enabled: shouldUnmaskAccountNumber,
  });

  const unmaskedAccountNumber = shouldUnmaskAccountNumber
    ? bankAccountUnmaskedAccountNumber
    : bankAccount?.details.accountNumber;

  const confirmationModal = useConfirmationModal({
    deliveryMethod: bankAccount,
  });

  const { showPaymentsMightBeAffectedBanner } = useShowPaymentsMightBeAffectedBanner({ vendorId: vendor?.id });

  const bankModalForm = useMelioForm<BankAccountFormModel>({
    schema: useAchValidationSchema(),
    onSubmit: async (data) => {
      if (
        !bankAccount ||
        unmaskedAccountNumber !== data.accountNumber ||
        bankAccount.details.routingNumber !== data.routingNumber
      ) {
        const newBankAccount = await create({
          type: 'bank-account',
          details: { ...data, accountType: 'checking' },
          requireConfirmationForPayment: false,
        });
        track('DeliveryMethod', 'Click', {
          PageName: 'edit-ach-details',
          Intent: 'edit-ach-details',
          Cta: 'save',
          DeliveryMethodId: newBankAccount.id,
        });
        onDone(newBankAccount as BankAccountDeliveryMethod);
      } else {
        track('DeliveryMethod', 'Click', {
          PageName: 'confirm-ach-details',
          Intent: 'confirm-ach-details',
          Cta: 'confirm',
          DeliveryMethodId: bankAccount.id,
        });
        await confirm(bankAccount.id);
        onDone(bankAccount);
      }

      confirmationModal.close();
    },
    defaultValues:
      bankAccount?.details && unmaskedAccountNumber
        ? {
            accountNumber: unmaskedAccountNumber,
            routingNumber: bankAccount.details.routingNumber,
          }
        : {
            accountNumber: '',
            routingNumber: '',
          },
    subscribeToDefaultValuesChanges: true,
  });

  return {
    ...confirmationModal,
    ...bankModalForm,
    vendor,
    isError: !!createError,
    isSaving: isMutating,
    routingNumber: bankAccount?.details.routingNumber,
    showPaymentsMightBeAffectedBanner,
    unmaskedAccountNumber,
  };
}
