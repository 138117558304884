import { ColorPickerInput } from './color-picker';
export type { CatalogItemSelectInputProps } from './CatalogItemSelect.input';
import { CatalogItemSelectInput } from './CatalogItemSelect.input';
export type { CustomerSelectInputProps } from './CustomersSelect.input';
import { CustomerSelectInput } from './CustomersSelect.input';
import { MultipleAttachmentsSelectInput } from './MultipleAttachmentsSelect.input';
export type { NumberFieldInputProps } from './NumberField.input';
import { NumberFieldInput } from './NumberField.input';
export type { PercentageFieldInputProps } from './PercentageField.input';
import { AmountFieldInput } from './AmountField.input';
import { PercentageFieldInput } from './PercentageField.input';
export type { SelectInputProps } from './Select.input';
import { BankAccountSelectInput } from './BankAccountSelect.input';
import { SelectInput } from './Select.input';

export const FormInputs = {
  CatalogItemSelect: CatalogItemSelectInput,
  CustomerSelect: CustomerSelectInput,
  PercentageField: PercentageFieldInput,
  NumberField: NumberFieldInput,
  AmountField: AmountFieldInput,
  Select: SelectInput,
  BankAccountSelect: BankAccountSelectInput,
  MultipleAttachmentSelect: MultipleAttachmentsSelectInput,
  ColorPicker: ColorPickerInput,
};
