import { Icon, IconProps } from '@chakra-ui/react';

export const Season1099Icon = (props: IconProps) => (
  <Icon width="96px" height="83px" viewBox="0 0 96 84" fill="currentColor" {...props}>
    <svg width="96" height="83" viewBox="0 0 96 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 74H86V43.5L47 13H8V74Z" fill="#BDBDFF" />
      <path d="M81.3001 39.0322H63.9751V60.3226H81.3001V39.0322Z" fill="#FFE814" />
      <path
        d="M12 70H89V27.4194C89 17.7419 82.7438 10 73.6 10H12V70Z"
        fill="#F6F2FD"
        stroke="#212124"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8994 18.0251H20V39.0912H63.8994V18.0251Z"
        fill="#BDBDFF"
        stroke="#212124"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M29.8955 23.848H31.2475V33H29.5835V26.812H27.7115V25.564C28.8555 25.564 29.6615 24.758 29.8955 23.848ZM36.5487 23.744C38.7197 23.744 39.9807 25.746 39.9807 28.424C39.9807 31.102 38.7197 33.104 36.5487 33.104C34.3777 33.104 33.1167 31.102 33.1167 28.424C33.1167 25.746 34.3777 23.744 36.5487 23.744ZM36.5487 31.544C37.6667 31.544 38.3167 30.257 38.3167 28.424C38.3167 26.591 37.6667 25.304 36.5487 25.304C35.4307 25.304 34.7807 26.591 34.7807 28.424C34.7807 30.257 35.4307 31.544 36.5487 31.544ZM47.885 27.709C47.885 30.946 46.897 33.104 44.414 33.104C42.776 33.104 41.541 31.999 41.437 30.608H43.075C43.192 31.115 43.582 31.544 44.401 31.544C45.727 31.544 46.104 30.335 46.156 29.399C45.792 29.763 45.129 30.101 44.154 30.101C42.412 30.101 41.229 28.775 41.229 27.02C41.229 25.148 42.555 23.744 44.505 23.744C46.468 23.744 47.885 25.005 47.885 27.709ZM46.221 26.968C46.221 25.993 45.545 25.304 44.557 25.304C43.569 25.304 42.893 25.993 42.893 26.968C42.893 27.943 43.569 28.736 44.557 28.736C45.545 28.736 46.221 27.943 46.221 26.968ZM55.7942 27.709C55.7942 30.946 54.8062 33.104 52.3232 33.104C50.6852 33.104 49.4502 31.999 49.3462 30.608H50.9842C51.1012 31.115 51.4912 31.544 52.3102 31.544C53.6362 31.544 54.0132 30.335 54.0652 29.399C53.7012 29.763 53.0382 30.101 52.0632 30.101C50.3212 30.101 49.1382 28.775 49.1382 27.02C49.1382 25.148 50.4642 23.744 52.4142 23.744C54.3772 23.744 55.7942 25.005 55.7942 27.709ZM54.1302 26.968C54.1302 25.993 53.4542 25.304 52.4662 25.304C51.4782 25.304 50.8022 25.993 50.8022 26.968C50.8022 27.943 51.4782 28.736 52.4662 28.736C53.4542 28.736 54.1302 27.943 54.1302 26.968Z"
        fill="#212124"
      />
      <path d="M64 18H20V61H64V18Z" stroke="#212124" strokeWidth="1.8" strokeMiterlimit="10" />
      <path d="M79 39H20V61H79V39Z" stroke="#212124" strokeWidth="1.8" strokeMiterlimit="10" />
      <line x1="54" y1="49.9" x2="26.0875" y2="49.9" stroke="#212124" strokeWidth="1.8" />
      <line x1="37" y1="44.9" x2="26.4125" y2="44.9" stroke="#212124" strokeWidth="1.8" />
      <line x1="44" y1="54.9" x2="26" y2="54.9" stroke="#212124" strokeWidth="1.8" />
      <path
        d="M80.0205 39H64.0571V61.0692H80.0205V39Z"
        fill="#FFE814"
        stroke="#212124"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M72.6091 54.9327V56.3807H71.3299V54.9747C69.455 54.8068 68.6577 53.7575 68.5 52.1312H70.0508C70.1822 53.128 70.6553 53.5267 71.8293 53.5267C73.1172 53.5267 73.678 53.0231 73.678 52.1836C73.6867 51.4282 73.3801 50.935 72.2411 50.7462L71.0671 50.5573C69.5952 50.3265 68.8329 49.4766 68.8329 47.8292C68.8329 46.4022 69.6215 45.2795 71.3299 45.0592V43.8H72.6091V45.0487C74.1073 45.227 74.9046 46.0455 75.0886 47.5669H73.5553C73.3976 46.7904 72.9858 46.4547 71.9783 46.4547C70.8042 46.4547 70.3662 47.0108 70.3662 47.6823C70.3662 48.4483 70.6378 48.8155 71.5577 48.9729L72.7317 49.1618C74.3614 49.4241 75.2375 50.2845 75.2375 52.0472C75.2375 53.7051 74.3088 54.7019 72.6179 54.9222L72.6091 54.9327Z"
        fill="#212124"
      />
      <path
        d="M72.6375 10C84.1875 10 80.2 24.5161 80.2 24.5161C80.2 24.5161 89 21.2903 89 30.3226"
        stroke="#212124"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
    </svg>
  </Icon>
);
