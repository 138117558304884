import { QueryKeyEnum, useMelioQueryClient } from '@melio/api-client';
import { useEffect, VFC } from 'react';

type InvalidateQueryCacheProps = {
  queryKeys: QueryKeyEnum[];
};

export const InvalidateQueryCache: VFC<InvalidateQueryCacheProps> = ({ queryKeys }) => {
  const { invalidateQueries } = useMelioQueryClient();
  useEffect(
    () => queryKeys.forEach((queryKey) => invalidateQueries(queryKey)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invalidateQueries, JSON.stringify(queryKeys.sort())]
  );
  return null;
};
