import { Icon } from '@melio/penny';
import { DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { MethodCard } from '../../../../MethodCard/MethodCard.widget';
import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';

export const ManagedAchDeliveryMethodCard = () => {
  const { formatMessage } = useMelioIntl();

  const displayName = formatMessage('widgets.addOrUpdateDeliveryMethod.managedAch.title');
  const helperText = formatMessage('widgets.addOrUpdateDeliveryMethod.managedAch.feeText');
  const additionalInfo = formatMessage('widgets.addOrUpdateDeliveryMethod.managedAch.info.additionalInfo');

  return (
    <MethodCard
      testIds={{
        root: `MethodCard-${DeliveryMethodType.BankAccount}`,
        header: `method-card-type-${DeliveryMethodType.ManagedAccount}`,
      }}
      icon={<Icon type="bank-out" />}
      displayName={displayName}
      helperText={helperText}
      data-component="ManagedAchDeliveryMethodCard"
    >
      <DeliveryMethodCaption secondary={additionalInfo} />
    </MethodCard>
  );
};
