import { useCallback } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useInternationalCountryDisplayName } from '@melio/ap-domain';
import {
  AchDeliveryMethodCard,
  DomesticWireDeliveryMethodCard,
  PaperCheckDeliveryMethodCard,
  PaypalBalanceDeliveryMethodCard,
  UnmanagedVirtualCardDeliveryMethodCard,
} from '@melio/ap-widgets';
import { Container, Group, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import {
  DeliveryMethodType,
  PaypalBalanceAccountDeliveryMethod,
  useAccount,
  useVendorDeliveryMethodTypeOptions,
  Vendor,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { DeliveryMethodNavigationStateParams } from '@/types/deliveryMethod.types';
import { DataComponentEnum } from '@/types/vendors.types';
import { ManagedDeliveryMethod } from '@/widgets/vendorsDashboard/DeliveryMethod/ManagedDeliveryMethod';
import { UnilateralRequestDetails } from '@/widgets/vendorsDashboard/UnilateralRequestDetails/UnilateralRequestDetails.widget';
import { InternationalDeliveryMethodCards } from './InternationalDeliveryMethodCards';

export interface VendorDrawerDeliveryMethodsProps {
  vendor: Vendor;
}

export const VendorDrawerDeliveryMethods = withAnalyticsContext(({ vendor }: VendorDrawerDeliveryMethodsProps) => {
  const [isNetworkVendorSupportEnabled] = useDevFeature(FeatureFlags.NetworkVendorSupport, false);
  const { pathname, search } = useLocation();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const router = useRouter();
  const { deliveryMethods } = vendor;
  const [paypalBalanceMethodEnabled] = usePartnerFeature('PaypalBalanceDeliveryMethodEnabled', false);
  const {
    settings: { isUnilateralDeliveryMethodSupported },
  } = useConfig();

  const { formatMessage } = usePlatformIntl();

  useAnalyticsContext({
    globalProperties: {
      PageName: 'vendor-details',
      Intent: 'add-delivery-method',
      VendorId: vendor.id,
    },
  });
  const { data: account, isFetching: isAccountLoading } = useAccount({ id: 'me' });
  const { data: deliveryMethodTypeOptions, isFetching: isDeliveryMethodTypeOptionsLoading } =
    useVendorDeliveryMethodTypeOptions({ vendorId: vendor.id });

  const isManagedVendor = isNetworkVendorSupportEnabled && vendor.isManaged;

  const supportedDeliveryMethodByType = deliveryMethodTypeOptions?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: curr.supported,
    }),
    {} as Record<DeliveryMethodType, boolean>,
  );

  const getDeliveryMethodByType = useCallback(
    (deliveryMethodType: DeliveryMethodType) => {
      if (!deliveryMethods) {
        return null;
      }
      return deliveryMethods.find((deliveryMethod) => deliveryMethod.type === deliveryMethodType) || null;
    },
    [deliveryMethods],
  );

  const { countryDisplayName, isCountriesDetailsLoading } = useInternationalCountryDisplayName(
    getDeliveryMethodByType('international-account') || undefined,
  );

  const isBankAccountEnabled = supportedDeliveryMethodByType?.['bank-account'];
  const isPaperCheckEnabled = supportedDeliveryMethodByType?.['paper-check'];

  const isDomesticWireEnabled = supportedDeliveryMethodByType?.['domestic-wire-account'];
  const isVirtualCardEnabled = supportedDeliveryMethodByType?.['virtual-card'];
  const isUnilateralDeliveryMethodEnabled =
    isUnilateralDeliveryMethodSupported && supportedDeliveryMethodByType?.['virtual-account'];

  const isPaypalBalanceEnabled = paypalBalanceMethodEnabled && supportedDeliveryMethodByType?.['paypal-balance'];

  const navigationStateParams: DeliveryMethodNavigationStateParams = {
    id: vendor?.id,
    returnUrl: `${pathname}?${createSearchParams(search).toString()}`,
  };

  // TODO: check whether "by you" is a constant text
  // TODO: use toLocaleDateString() for the date format
  return (
    <WithLoading isLoading={isDeliveryMethodTypeOptionsLoading || isAccountLoading || isCountriesDetailsLoading}>
      <Container data-component={DataComponentEnum.DELIVERY_METHODS} data-testid="vendor-delivery-methods">
        <Text as="h3" textStyle="heading3Semi">
          {formatMessage('widgets.vendorDeliveryMethods.title')}
        </Text>
      </Container>
      <Box paddingX="1">
        <Group variant="vertical" spacing="m">
          {isManagedVendor ? (
            <ManagedDeliveryMethod vendor={vendor} deliveryMethods={deliveryMethods} />
          ) : (
            <>
              {isPaypalBalanceEnabled && (
                <PaypalBalanceDeliveryMethodCard
                  paypalBalanceAccount={getDeliveryMethodByType('paypal-balance') as PaypalBalanceAccountDeliveryMethod}
                  onClick={() => {
                    const { path, options } = router.generateVendorPaypalBalanceLink(navigationStateParams);
                    navigate(path, options);
                  }}
                />
              )}
              {isUnilateralDeliveryMethodEnabled ? <UnilateralRequestDetails vendor={vendor} /> : null}
              {isBankAccountEnabled ? (
                <AchDeliveryMethodCard
                  vendor={vendor}
                  ach={getDeliveryMethodByType('bank-account')}
                  onClick={() => {
                    track(
                      getDeliveryMethodByType('bank-account') ? 'VendorsEditACHMethod' : 'VendorsAddACHMethod',
                      'Chose',
                    );
                    const { path, options } = router.generateVendorBankAccountLink(navigationStateParams);
                    navigate(path, options);
                  }}
                />
              ) : null}
              {isDomesticWireEnabled ? (
                <DomesticWireDeliveryMethodCard
                  vendor={vendor}
                  domesticWire={getDeliveryMethodByType('domestic-wire-account')}
                  onClick={() => {
                    const { path, options } = router.generateVendorDomesticAccountLink(navigationStateParams);
                    navigate(path, options);
                  }}
                />
              ) : null}
              {isPaperCheckEnabled ? (
                <PaperCheckDeliveryMethodCard
                  check={getDeliveryMethodByType('paper-check')}
                  onClick={() => {
                    const viewContext = getDeliveryMethodByType('paper-check')
                      ? 'VendorsEditChecksMethod'
                      : 'VendorsAddCheckMethod';
                    track(viewContext, 'Chose');
                    const { path, options } = router.generateVendorPaperCheckLink(navigationStateParams);
                    navigate(path, options);
                  }}
                />
              ) : null}
              <InternationalDeliveryMethodCards
                vendorCurrency={vendor.currency}
                businessType={account?.company.businessType}
                deliveryMethodTypeOptions={deliveryMethodTypeOptions}
                deliveryMethods={deliveryMethods}
                countryDisplayName={countryDisplayName}
                navigationStateParams={navigationStateParams}
              />
              {isVirtualCardEnabled ? (
                <UnmanagedVirtualCardDeliveryMethodCard
                  unmanagedVirtualCard={
                    (getDeliveryMethodByType('virtual-card') as VirtualCardAccountDeliveryMethod) || null
                  }
                  onClick={() => {
                    const unmanagedVirtualCard = getDeliveryMethodByType('virtual-card');
                    const viewContext = unmanagedVirtualCard
                      ? 'VendorsEditVirtualCardMethod'
                      : 'VendorsAddVirtualCardMethod';
                    track(viewContext, 'Chose');
                    const { path, options } = router.generateVendorVirtualCardLink(navigationStateParams);
                    navigate(path, options);
                  }}
                />
              ) : null}
            </>
          )}
        </Group>
      </Box>
    </WithLoading>
  );
});
