import { BrandSymbol, Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePlanInfo, useSubscription } from '../../../hooks';
import { SectionSettingCard } from '../SectionSettingCard';

export const VipPlanCard = () => {
  const { formatMessage } = useMelioIntl();
  const subscription = useSubscription();
  const { planName, planDescription } = usePlanInfo(subscription?.planId);

  return (
    <SectionSettingCard data-testid="subscription-vip-plan">
      <Group width="full" justifyContent="space-between" alignItems="center" spacing="s">
        <Container alignItems="center">
          <Group variant="vertical" spacing="xxxs">
            <Group spacing="s" alignItems="center">
              <BrandSymbol type="melio" size="extra-large" />
              <Group variant="vertical" spacing="xxxs">
                <Text textStyle="body2Semi" as="h4" data-testid="current-plan-name">
                  {formatMessage('activities.settings.plans.planName', { planName })}
                </Text>
                <Text textStyle="body2" color="global.neutral.800">
                  {planDescription}
                </Text>
              </Group>
            </Group>
          </Group>
        </Container>
      </Group>
    </SectionSettingCard>
  );
};
