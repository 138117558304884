import { EmptyState, FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group, NakedButton, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

export type EmptyCustomersTableStateProps = {
  onCreateCustomer?: VoidFunction;
  onClearSearch?: VoidFunction;
  variant?: 'no-data' | 'no-search-results';
};

export const EmptyTableState = forwardRef<EmptyCustomersTableStateProps>(
  ({ variant, onCreateCustomer, onClearSearch, ...props }, ref) =>
    variant === 'no-search-results' ? (
      <NoSearchResultsEmptyState onClearSearch={onClearSearch} ref={ref} {...props} />
    ) : (
      <NoDataEmptyState onCreateCustomer={onCreateCustomer} ref={ref} {...props} />
    )
);

EmptyTableState.displayName = 'EmptyTableState';

type NoSearchResultsEmptyStateProps = {
  onClearSearch?: VoidFunction;
};

export const NoSearchResultsEmptyState = forwardRef<NoSearchResultsEmptyStateProps>(
  ({ onClearSearch, ...props }, ref) => (
    <EmptyState
      ref={ref}
      illustrationType="no-items"
      data-testid="no-search-results-empty-state"
      data-component={NoSearchResultsEmptyState.displayName}
      content={
        <Container textAlign="center">
          <Text textStyle="body4">
            <FormattedMessage id="ar.dashboard.activities.customersTable.emptyState.search.text" />
          </Text>
        </Container>
      }
      footer={<NakedButton data-testid="clean-search-button" label="Clear all" onClick={onClearSearch} />}
      {...props}
    />
  )
);

NoSearchResultsEmptyState.displayName = 'NoSearchResultsEmptyState';

type NoDataEmptyStateProps = {
  onCreateCustomer?: VoidFunction;
};

export const NoDataEmptyState = forwardRef<NoDataEmptyStateProps>(({ onCreateCustomer, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const handleCreateCustomer = () => {
    track('Dashboard', 'Click', {
      Intent: 'create-customer',
      Cta: 'create-customer',
      Flow: 'add-customer',
    });

    onCreateCustomer?.();
  };

  return (
    <EmptyState
      ref={ref}
      illustrationType="customer-add"
      data-testid="no-data-empty-state"
      data-component={NoDataEmptyState.displayName}
      content={
        <Group spacing="xxxs" alignItems="center" justifyContent="center" variant="vertical">
          <Text textStyle="body3Semi">
            <FormattedMessage id="ar.dashboard.activities.customersTable.emptyState.no-data.primary.label" />
          </Text>
          <Text textStyle="body4">
            <FormattedMessage id="ar.dashboard.activities.customersTable.emptyState.no-data.secondary.label" />
          </Text>
        </Group>
      }
      footer={
        <Button
          label={formatMessage('ar.dashboard.activities.customersTable.emptyState.create.button.label')}
          variant="primary"
          data-testid="create-customer-button"
          onClick={handleCreateCustomer}
        />
      }
      {...props}
    />
  );
});

NoDataEmptyState.displayName = 'NoDataEmptyState';
