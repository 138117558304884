import { VendorDetailsFormType } from '@melio/platform-provider';

import { SettingsCardIdEnum } from '../cl/components/SettingsCard/SettingsCard.component';
import { SettingsGroupEnum } from '../partnersConfig.types';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';
export const paymentEnvConfig: PartnerConfigByEnv = {
  default: {
    translationsSrc: 'https://platform-static.meliopayments.com/assets/paypal/messages.json',
    settings: {
      payment: {
        newPaymentFlow: {
          enabled: true,
        },
        supportedFundingSourceTypes: {
          'paypal-balance': true,
        },
      },
      isEmbeddedExperience: true,
      hideHeaderNavigation: true,
      newBillExperience: {
        isRecurringEnabled: false,
      },
      fundingSourcePolicy: {
        'paypal-balance': {
          write: false,
          order: 0,
        },
        'bank-account': {
          write: true,
          creationHosting: 'iframe',
          order: 1,
        },
        card: {
          write: true,
          creationHosting: 'iframe',
          order: 2,
        },
      },
      deliveryMethodTypeOrder: {
        'paypal-balance': 1,
        'bank-account': 2,
        'paper-check': 3,
        'domestic-wire-account': 4,
        'virtual-card': 5,
        'virtual-account': 6,
        'international-account': 7,
      },
      settings: {
        options: [
          {
            type: SettingsGroupEnum.COMPANY,
            items: [
              SettingsCardIdEnum.ProfileSettings,
              SettingsCardIdEnum.CompanySettings,
              SettingsCardIdEnum.CollaboratorsSetting,
              SettingsCardIdEnum.NotificationPreferences,
              SettingsCardIdEnum.AccountingSoftwareSync,
            ],
          },
          {
            type: SettingsGroupEnum.PAYMENTS,
            items: [
              SettingsCardIdEnum.PaymentMethodsSetting,
              SettingsCardIdEnum.SubscriptionPlansSettings,
              SettingsCardIdEnum.BillingSetting,
            ],
          },
          {
            type: SettingsGroupEnum.SUPPORT,
            items: [SettingsCardIdEnum.SupportSetting],
          },
        ],
      },
      PaypalBalanceDeliveryMethodEnabled: {
        flagName: 'paypal-balance-delivery-method',
        enabled: true,
      },
      ShowTotalPaymentIncludingFees: {
        enabled: true,
      },
      VendorDeliveryMethodCombinedExperience: {
        enabled: true,
      },
      vendor: {
        forms: {
          shouldRequireEmail: true,
          shouldDisableEmailEdit: true,
          shouldCollectCountry: true,
        },
        vendorSelect: {
          createVendor: {
            showVendorDetailsForm: true,
            vendorDetailsFormType: VendorDetailsFormType.Drawer,
          },
        },
      },
    },
  },
  prod: {},
  dev: {},
};

export const paypalConfig = extendDefaultConfig(paymentEnvConfig);
