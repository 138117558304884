import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const OrgOwesUsMoneyBanner = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <SectionBanner
      showCloseIcon={false}
      data-testid="org-owes-us-moeny-dashboard-section-banner"
      title={formatMessage('widget.orgOwesUsMoney.warning.banner.title')}
      variant="warning"
      description={formatMessage('widget.orgOwesUsMoney.warning.banner.text')}
    />
  );
};
