import { useIsExternalAccountant } from '@melio/platform-api';
import { Tier } from '@melio/platform-api-axios-client';
import { usePermissions } from '@melio/platform-permissions';
import { usePartnerFeature } from '@melio/platform-provider';
import { useLocation } from '@melio/platform-utils';
import { Fragment } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useConfirmSubscriptionDowngradeRedirectRoute } from '../hooks/useConfirmSubscriptionDowngradeRedirectRoute';
import { useGracePeriodEndPaywall } from '../hooks/useGracePeriodEndPaywall';
import { useIsSubscriptionsEnabled } from '../hooks/useIsSubscriptionsEnabled';
import { useShouldConfirmSubscriptionDowngrade } from '../hooks/useShouldConfirmSubscriptionDowngrade';
import { useSubscriptionOrganizationFailureState } from '../hooks/useSubscriptionOrganizationFailureState';
import { useSubscriptionPlan } from '../hooks/useSubscriptionPlan';
import {
  ADD_NEW_COMPANY_ROUTE,
  SUBSCRIPTION_ACCOUNTING_CLIENT_PLAN_SELECTED_ROUTE,
  SUBSCRIPTION_DOWNGRADE_CLIENT_BASIC_PLAN_ROUTE,
  SUBSCRIPTION_OWNER_SHOULD_SETUP_ROUTE,
} from '../router/routes.consts';
import {
  ContextualPaywallModalActivity,
  GracePeriodModalActivity,
  isRouteAllowedForNotSubscribedOrg,
  isRouteAllowedWhenDowngradeConfirmationRequired,
  OnboardingPaywallModalActivity,
  SubscriptionPaymentFeeFailedModal,
  VipWelcomeModalActivity,
} from '../ui';
import { useSubscriptionContext } from './SubscriptionContext';

export const SubscriptionAppFlows = () => {
  const { pathname } = useLocation();
  const {
    accountingClientSelectedSubscriptionPlan,
    subscription,
    plans,
    gracePeriod: {
      isEligibleForGracePeriod,
      isTodayInGracePeriod,
      isOrgCreatedBeforeGracePeriodStart,
      isUserCreatedBeforeGracePeriodStart,
      isEligibleAndGracePeriodEnded,
    },
  } = useSubscriptionContext();

  const currentPlan = plans.find(({ id: planId }) => planId === subscription?.planId);
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const isExternalAccountant = useIsExternalAccountant();
  const { failureState } = useSubscriptionOrganizationFailureState();
  const { isUpgradedPlan } = useSubscriptionPlan();
  const [isExternalAccountantMandatoryPlanRestrictionEnabled] = usePartnerFeature(
    'ExternalAccountantMandatoryPlanRestriction',
    false
  );

  const { can } = usePermissions();

  const canCreateSubscription = can({
    subject: 'subscriptions',
    action: 'create',
  });
  const canUpdateSubscription = can({
    subject: 'subscriptions:me',
    action: 'update',
  });

  const { graceEndRedirectRoute, shouldShowGracePeriodEndPaywall } = useGracePeriodEndPaywall();

  const shouldConfirmSubscriptionDowngrade = useShouldConfirmSubscriptionDowngrade();
  const subscriptionDowngradeConfirmRoute = useConfirmSubscriptionDowngradeRedirectRoute();

  const canHandleSubscriptionFailureState = failureState && canUpdateSubscription;

  const WithSubscriptionActivities = canHandleSubscriptionFailureState ? (
    <SubscriptionPaymentFeeFailedModal />
  ) : isOrgCreatedBeforeGracePeriodStart && isUserCreatedBeforeGracePeriodStart ? (
    <VipWelcomeModalActivity />
  ) : (
    <Fragment />
  );

  const WithoutSubscriptionActivities =
    isEligibleForGracePeriod && isTodayInGracePeriod ? (
      <GracePeriodModalActivity />
    ) : (
      <>
        {isExternalAccountant ? (
          <Navigate to="/subscription/grace/select-client-plan" replace />
        ) : (
          <OnboardingPaywallModalActivity />
        )}
      </>
    );

  const isNonOwnerAndNoGraceEligibilitySetupRequired =
    isSubscriptionsEnabled && !subscription && !canCreateSubscription && !isEligibleForGracePeriod;
  const isAccountingClientPlanSelectedByFirm =
    accountingClientSelectedSubscriptionPlan &&
    (!subscription || isUpgradedPlan(accountingClientSelectedSubscriptionPlan.planId));

  const shouldRenderModals =
    isSubscriptionsEnabled &&
    !isAccountingClientPlanSelectedByFirm &&
    !isEligibleAndGracePeriodEnded &&
    !isNonOwnerAndNoGraceEligibilitySetupRequired;

  const isAddCompanyRoute = pathname.startsWith(ADD_NEW_COMPANY_ROUTE);

  if (
    isExternalAccountantMandatoryPlanRestrictionEnabled &&
    isExternalAccountant &&
    !isAccountingClientPlanSelectedByFirm &&
    currentPlan?.tier === Tier.MelioBasic &&
    !SUBSCRIPTION_DOWNGRADE_CLIENT_BASIC_PLAN_ROUTE.startsWith(pathname)
  ) {
    return <Navigate to="/subscription/downgrade/client-basic-plan" replace />;
  }

  if (
    shouldConfirmSubscriptionDowngrade &&
    !subscriptionDowngradeConfirmRoute.isLoading &&
    subscriptionDowngradeConfirmRoute.redirectUrl &&
    !isRouteAllowedWhenDowngradeConfirmationRequired(pathname)
  ) {
    return <Navigate to={subscriptionDowngradeConfirmRoute.redirectUrl} />;
  }

  if (!isRouteAllowedForNotSubscribedOrg(pathname)) {
    if (isNonOwnerAndNoGraceEligibilitySetupRequired && !isAddCompanyRoute) {
      return <Navigate to={SUBSCRIPTION_OWNER_SHOULD_SETUP_ROUTE} />;
    }
    if (isAccountingClientPlanSelectedByFirm) {
      return <Navigate to={SUBSCRIPTION_ACCOUNTING_CLIENT_PLAN_SELECTED_ROUTE} />;
    }
    if (isSubscriptionsEnabled && shouldShowGracePeriodEndPaywall) {
      return <Navigate to={graceEndRedirectRoute} replace />;
    }
  }

  return (
    <>
      <Outlet />
      {shouldRenderModals && (
        <>
          <ContextualPaywallModalActivity />
          {subscription ? WithSubscriptionActivities : WithoutSubscriptionActivities}
        </>
      )}
    </>
  );
};
