import { Divider, Group, Modal, SectionBanner, Text, useMelioForm } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { SubscriptionBillingCycleEnum } from '../../../../api';
import { usePlanInfo, usePlansTiers, useSubscription } from '../../../../hooks';
import { useBillingFundingSources } from '../../../utils';
import { PaymentMethodsDropdown } from '../components/PaymentMethodsDropdown';
import { BillingAccountFormValues, PlanUpgradeProps } from '../types';
import { useUpgradeSubscription } from '../useUpgradeSubscription';
import { getSelectedFundingSourceId } from '../utils';

type SubscriptionUpgradeWithoutTrialFiserModalScreenProps = {
  isOpen: boolean;
  onDismiss?: () => void;
  onClose: () => void;
  planUpgradeProps: PlanUpgradeProps;
};

export const SubscriptionUpgradeWithoutTrialFiservModalScreen = ({
  isOpen,
  onClose,
  onDismiss,
  planUpgradeProps,
}: SubscriptionUpgradeWithoutTrialFiserModalScreenProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const subscription = useSubscription();

  useAnalyticsView('Organization', isOpen, true);

  const { fundingSources = [] } = useBillingFundingSources();

  const { billingCycle, planId } = planUpgradeProps;

  const { upgradeError, isUpgradingSubscription, upgradeSubscription, dismiss } = useUpgradeSubscription({
    ...planUpgradeProps,
    onClose,
    onDismiss,
  });

  const selectedFundingSource = getSelectedFundingSourceId({
    subscriptionFundingSourceId: subscription?.fundingSourceId,
    fundingSources,
  });

  const { registerField, watch } = useMelioForm<BillingAccountFormValues>({
    defaultValues: {
      fundingSourceId: selectedFundingSource?.id,
    },
    onSubmit: () => null,
  });

  const fundingSourceField = watch('fundingSourceId');

  const { planName } = usePlanInfo(planId);
  const { getPlan } = usePlansTiers();

  const upgradePlan = planId ? getPlan(planId) : undefined;
  const currentPlan = subscription?.planId ? getPlan(subscription.planId) : undefined;

  const currencyFormatter = (value: number) =>
    formatCurrency(value, undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });
  const planUpgradePrice =
    billingCycle === SubscriptionBillingCycleEnum.Monthly
      ? upgradePlan?.cycles?.monthly?.basePrice || 0
      : upgradePlan?.cycles?.annual?.basePrice || 0;

  const currentPlanPrice =
    subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly
      ? currentPlan?.cycles?.monthly?.basePrice || 0
      : currentPlan?.cycles?.annual?.basePrice || 0;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => dismiss('exit')}
      header={formatMessage('activities.subscription.upgradeModal.title', { planName })}
      primaryButton={{
        isLoading: isUpgradingSubscription,
        label: formatMessage('activities.subscription.upgradeModal.primaryButton'),
        onClick: () => upgradeSubscription(fundingSourceField),
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('activities.subscription.upgradeModal.secondaryButton'),
        onClick: () => dismiss('cancel'),
        variant: 'tertiary',
      }}
      data-testid="subscription-upgrade-modal-activity"
    >
      <Group variant="vertical" spacing="m">
        <Group role="status">
          {upgradeError && (
            <SectionBanner
              description={formatMessage('activities.subscription.upgradeModal.error')}
              variant="critical"
              data-testid="subscription-upgrade-modal-error"
              data-role="alert"
            />
          )}
        </Group>

        <Group variant="vertical" spacing="m" as="ul">
          <Group variant="horizontal" spacing="s" justifyContent="space-between" as="li">
            <Group variant="vertical" spacing="none" textAlign="start">
              <Text textStyle="body2Semi">{planName}</Text>
            </Group>
            <Group variant="vertical" spacing="none" textAlign="end">
              <Text textStyle="body2Semi">{currencyFormatter(planUpgradePrice)}</Text>
            </Group>
          </Group>

          <Group as="li" aria-hidden="true">
            <Divider />
          </Group>

          <Group variant="horizontal" spacing="s" justifyContent="space-between" as="li">
            <Group variant="vertical" spacing="none" textAlign="start">
              <Text textStyle="body2Semi">{formatMessage('activities.subscription.upgradeModal.total')}</Text>
              <Text textStyle="body4" color="global.neutral.800">
                {formatMessage('activities.subscription.upgradeModal.addToMonthlyBilling')}
              </Text>
            </Group>
            <Group variant="vertical" spacing="none" textAlign="end">
              <Text textStyle="body1Semi">{currencyFormatter(planUpgradePrice - currentPlanPrice)}</Text>
            </Group>
          </Group>
        </Group>

        <PaymentMethodsDropdown fundingSources={fundingSources} registerField={registerField} />
      </Group>
    </Modal>
  );
};
