import {
  Form,
  Group,
  OnSubmissionStateChange,
  Table,
  TableRowSelectionState,
  Text,
  useMelioForm,
  useTable,
} from '@melio/penny';
import { usePaginatedVendors, useVendorGroups, Vendor, VendorGroup } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { SystemMessageDisplay, SystemMessageProvider } from '@melio/platform-utils';
import { uniq } from 'lodash';
import { useCallback, useState } from 'react';

import {
  PayDashboardPagination,
  PayDashboardPaginationProvider,
  usePayDashboardPagination,
} from '../../PayDashboard/components/Pagination';
import { useLoadingState } from '../../PayDashboard/hooks/useLoadingState';
import { useVendorsGroupFormSchema } from './schema';
import { VendorsGroupFormFields } from './types';
import { useVendorsGroupTableColumns } from './useVendorsGroupTableColumns';
import { VendorsGroupSearchBar } from './VendorsGroupSearchBar';

type Props = {
  onSubmissionStateChange: OnSubmissionStateChange<VendorsGroupFormFields>;
  handleSubmit: (vendorsGroup: VendorsGroupFormFields) => void;
  isSaving: boolean;
  vendorsGroup?: VendorGroup;
  isEditMode?: boolean;
};

const VendorsGroupFormComponent = ({
  onSubmissionStateChange,
  handleSubmit,
  isSaving,
  vendorsGroup,
  isEditMode,
}: Props) => {
  const { pageSize, resetToFirstPage } = usePayDashboardPagination();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { formatMessage } = useMelioIntl();

  const paginationResponse = usePaginatedVendors({
    cacheTime: 0,
    params: {
      limit: pageSize,
      ...(searchTerm ? { searchTerm } : {}),
      sort: [{ field: 'name', order: 'asc' }],
      expand: 'groups',
    },
  });
  const { data: vendorsResult, isLoading: isLoadingVendors, isFetching, isPreviousData } = paginationResponse;
  const { data: vendors = [], pagination } = vendorsResult ?? {};
  const currentPageVendorIds = vendors.map(({ id }) => id);
  const { data: vendorGroups } = useVendorGroups();

  const [isVendorLimitedToSingleVendorGroup] = usePartnerFeature('isVendorLimitedToSingleVendorGroup', false);

  const existingVendorGroupNamesExceptCurrent =
    vendorGroups?.filter(({ id }) => id !== vendorsGroup?.id).map(({ name }) => name) ?? [];
  const schema = useVendorsGroupFormSchema(existingVendorGroupNamesExceptCurrent);
  const { formProps, registerField, watch, setValue } = useMelioForm<VendorsGroupFormFields>({
    onSubmit: handleSubmit,
    onSubmissionStateChange,
    defaultValues: {
      name: vendorsGroup?.name ?? '',
      vendorIds: vendorsGroup?.vendorIds ?? [],
    },
    schema,
    isSaving,
  });
  const selectedVendorIds = watch('vendorIds');

  const { isTableLoading, isInitialLoading, shouldShowPaginationControls } = useLoadingState({
    isLoading: isLoadingVendors,
    searchTerm,
    items: vendors,
    paginationConfig: {
      isFetching,
      isPreviousData,
      totalCount: pagination?.totalCount,
    },
  });
  const columns = useVendorsGroupTableColumns();

  const handleSearchSubmitted = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    resetToFirstPage();
  };

  const handleRowSelectionChange = useCallback(
    (selectedRow: TableRowSelectionState<Vendor>) => {
      const {
        isSelected,
        rowData: { id: selectedRowId },
      } = selectedRow;

      isSelected
        ? setValue('vendorIds', [...selectedVendorIds, selectedRowId])
        : setValue(
            'vendorIds',
            selectedVendorIds.filter((id) => id !== selectedRowId)
          );
    },
    [selectedVendorIds, setValue]
  );

  const shouldDisableRowSelection = useCallback(
    (vendor: Vendor) => {
      const isVendorInCurrentGroup = vendorsGroup?.vendorIds.includes(vendor.id);

      if (isVendorLimitedToSingleVendorGroup && vendor.groups) {
        return vendor.groups.length > 0 && !isVendorInCurrentGroup;
      }

      return false;
    },
    [isVendorLimitedToSingleVendorGroup, vendorsGroup]
  );

  const handleAllRowsSelectionChange = useCallback(
    (areAllSelected: boolean) => {
      if (areAllSelected) {
        const currentPageEnabledVendorIds = vendors
          .filter((vendor) => currentPageVendorIds.includes(vendor.id) && !shouldDisableRowSelection(vendor))
          .map((vendor) => vendor.id);

        setValue('vendorIds', uniq([...selectedVendorIds, ...currentPageEnabledVendorIds]));
      } else {
        setValue(
          'vendorIds',
          selectedVendorIds.filter((id) => !currentPageVendorIds.includes(id))
        );
      }
    },
    [currentPageVendorIds, selectedVendorIds, setValue, shouldDisableRowSelection, vendors]
  );

  const tableProps = useTable({
    isLoading: isTableLoading,
    data: vendors,
    columns,
    headerVariant: 'dark',
    hideHeaderWhileLoading: isInitialLoading,
    disableRowSelection: shouldDisableRowSelection,
    onRowSelectionChange: handleRowSelectionChange,
    onAllRowsSelectionChange: handleAllRowsSelectionChange,
    getRowSelectionAriaLabel: (vendor) =>
      formatMessage('activities.vendorsGroupForm.table.rowSelectionAriaLabel', {
        vendorName: vendor.name,
        accountNumber: vendor.accountNumber,
      }),
    selectedRows: selectedVendorIds.reduce<Record<string, boolean>>(
      (selectedRows, vendorId) => ({ ...selectedRows, [vendorId]: true }),
      {}
    ),
    allRowsSelectionAriaLabel: formatMessage('activities.vendorsGroupForm.table.selectAll'),
    meta: {
      isVendorSelectionDisabled: shouldDisableRowSelection,
    },
  });

  return (
    <Group variant="vertical" spacing="m">
      <SystemMessageDisplay data-testid="vendors-group-form-message" />
      {!isEditMode && (
        <Text textStyle="body3" as="h2" data-testid="vendors-group-form-title-description">
          {formatMessage('activities.vendorsGroupForm.title.description')}
        </Text>
      )}
      <Form {...formProps}>
        <Form.TextField
          {...registerField('name')}
          labelProps={{ label: formatMessage('activities.vendorsGroupForm.fields.name.label') }}
          isRequired
        />
      </Form>
      <Text textStyle="body3">
        {formatMessage('activities.vendorsGroupForm.description', { vendorsCount: selectedVendorIds.length })}
      </Text>
      <Group variant="vertical" spacing="xs">
        <Text textStyle="body3Semi" color="global.neutral.800">
          {formatMessage('activities.vendorsGroupForm.search.title')}
        </Text>
        <VendorsGroupSearchBar onSearchSubmitted={handleSearchSubmitted} />
        <Text textStyle="body3" color="global.neutral.800">
          {formatMessage('activities.vendorsGroupForm.search.description')}
        </Text>
      </Group>
      <Table {...tableProps} />
      <PayDashboardPagination paginatedCollection={paginationResponse} isVisible={shouldShowPaginationControls} />
    </Group>
  );
};

export const VendorsGroupForm = (props: Props) => (
  <PayDashboardPaginationProvider>
    <SystemMessageProvider>
      <VendorsGroupFormComponent {...props} />
    </SystemMessageProvider>
  </PayDashboardPaginationProvider>
);
