import { DeliveryMethodType } from '@melio/platform-api';

import { DeliveryMethodSectionProps } from '../types';
import { SelectedDeliveryMethodCard } from './components/DeliveryMethodCard/SelectedDeliveryMethodCard';
import { BankAccountFormModal } from './components/modals/BankAccountFormModal/BankAccountFormModal';
import { useBankAccountDetailsFormModal } from './components/modals/BankAccountFormModal/useBankAccountDetailsFormModal';
import { PaperCheckFormModal } from './components/modals/PaperCheckFormModal/PaperCheckFormModal';
import { usePaperCheckFormModal } from './components/modals/PaperCheckFormModal/usePaperCheckFormModal';
import { NotSelected } from './components/NotSelected';

export function DeliveryMethodSectionWithModals({
  vendor,
  deliveryMethodId,
  isDisabled,
  isLoading,
  onDeliveryMethodChange,
  paymentSettings,
}: DeliveryMethodSectionProps) {
  const bankAccountModal = useBankAccountDetailsFormModal({
    vendor,
    onDone: onDeliveryMethodChange,
  });
  const paperCheckModal = usePaperCheckFormModal({ vendor, onDone: onDeliveryMethodChange });
  const deliveryMethodTypeOptions = paymentSettings?.deliveryMethodTypeOptions;
  const onEditDeliveryMethod = (type: DeliveryMethodType) => {
    const dmTypeToAction: Partial<Record<DeliveryMethodType, VoidFunction>> = {
      'bank-account': bankAccountModal.open,
      'paper-check': paperCheckModal.open,
    };
    dmTypeToAction[type]?.();
  };

  const emptyState = !deliveryMethodId && !isLoading;

  return (
    <>
      {emptyState ? (
        <NotSelected
          onBankClick={() => onEditDeliveryMethod('bank-account')}
          onPaperCheckClick={() => onEditDeliveryMethod('paper-check')}
        />
      ) : (
        <SelectedDeliveryMethodCard
          vendorId={vendor?.id}
          deliveryMethodId={deliveryMethodId}
          isLoading={isLoading}
          isDisabled={isDisabled}
          onDeliveryMethodChange={onDeliveryMethodChange}
          onEditDeliveryMethod={onEditDeliveryMethod}
          deliveryMethodTypeOptions={deliveryMethodTypeOptions}
        />
      )}
      <BankAccountFormModal onClose={bankAccountModal.close} {...bankAccountModal} />
      <PaperCheckFormModal onClose={paperCheckModal.close} {...paperCheckModal} />
    </>
  );
}
