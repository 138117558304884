import { useFundingSources } from '@melio/platform-api/src/entities-v2';

import { usePartnerGroups } from '../../hooks';

export const useBillingFundingSources = () => {
  const { data: fundingSources, isFetching: isFetchingFundingSources } = useFundingSources();
  const { isFiservPartner } = usePartnerGroups();

  if (isFiservPartner) {
    const bankFundingSources = fundingSources?.filter((fundingSource) => fundingSource.type === 'bank-account');
    return { isFetching: isFetchingFundingSources, fundingSources: bankFundingSources };
  }

  return { isFetching: isFetchingFundingSources, fundingSources };
};
