import { VisuallyHidden } from '@chakra-ui/react';
import { Table, TableColumnDef, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import _compact from 'lodash/compact';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../../utils';
import { useTableColumnsSort } from '../../hooks/useTableColumnsSort';
import { usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { BillsTabActionButtonCell } from './components/BillsTabActionButtonCell';
import { BillsTabAmountCell } from './components/BillsTabAmountCell';
import { BillsTabInvoiceCell } from './components/BillsTabInvoiceCell';
import { BillsTabMoreActionsCell } from './components/BillsTabMoreActionsCell';
import { BillsTabOriginCell } from './components/BillsTabOriginCell';
import { BillsTabStatusCell } from './components/BillsTabStatusCell';
import { BillsTabVendorNameCell } from './components/BillsTabVendorNameCell';
import { BillsTabItem, BillsTableMeta, BillsTabSortableCells, CELLS_IDS } from './types';
import { useBillsTabSortableColumns } from './useBillsTabSortableColumns';

export const useBillsTableColumns = ({ onSortChange }: { onSortChange: VoidFunction }) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const sorting = usePayDashboardSorting();
  const { getSortableHeader } = useTableColumnsSort<BillsTabSortableCells>({
    sorting,
    onSortChange,
  });
  const { sortableColumns } = useBillsTabSortableColumns();
  const { navigateToSchedulePayment } = useActivitiesNavigate();
  const { pathname } = useLocation();
  const { breakpoint } = useBreakpoint();
  const [isPreviewInvoiceEnabled] = usePartnerFeature('PreviewInvoice', false);
  const isLargeScreen = ['l', 'xl'].includes(breakpoint);

  return useMemo(() => {
    const vendorColumn: TableColumnDef<BillsTabItem, BillsTableMeta> = {
      id: CELLS_IDS.VENDOR_NAME,
      isPinnedToLeft: true,
      size: 'm',
      header: getSortableHeader(sortableColumns[CELLS_IDS.VENDOR_NAME]),
      cell: ({ row, meta }) =>
        meta ? <BillsTabVendorNameCell billsTabItem={row} rowSelectedCount={meta.rowSelections.length} /> : null,
    };
    const invoiceColumn: TableColumnDef<BillsTabItem, BillsTableMeta> = {
      id: CELLS_IDS.INVOICE,
      size: 'xs',
      cell: ({ row }) => <BillsTabInvoiceCell billsTabItem={row} />,
    };
    const billColumn: TableColumnDef<BillsTabItem, BillsTableMeta> = {
      id: CELLS_IDS.BILL,
      size: 's',
      header: getSortableHeader(sortableColumns[CELLS_IDS.BILL]),
      cell: ({ row }) => <BillsTabOriginCell billsTabItem={row} />,
    };
    const creationDateColumn: TableColumnDef<BillsTabItem, BillsTableMeta> = {
      id: CELLS_IDS.CREATED_AT,
      size: 's',
      header: getSortableHeader(sortableColumns[CELLS_IDS.CREATED_AT]),
      cell: ({ row }) => (
        <Table.Cell
          placeholder={formatMessage('activities.payDashboard.billsTab.table.cells.dueDate.missing')}
          data-testid={`bills-tab-row-${row.payload.id}-due-date-cell`}
        >
          {formatDate(row.payload.history.createdAt ?? undefined, {
            dateStyle: 'medium',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })}
        </Table.Cell>
      ),
    };
    const dueDateColumn: TableColumnDef<BillsTabItem, BillsTableMeta> = {
      id: CELLS_IDS.DUE_DATE,
      size: 's',
      header: getSortableHeader(sortableColumns[CELLS_IDS.DUE_DATE]),
      cell: ({ row }) => (
        <Table.Cell
          placeholder={formatMessage('activities.payDashboard.billsTab.table.cells.dueDate.missing')}
          data-testid={`bills-tab-row-${row.payload.id}-due-date-cell`}
        >
          {formatDate(row.payload.dueDate ?? undefined, {
            dateStyle: 'medium',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })}
        </Table.Cell>
      ),
    };
    const statusColumn: TableColumnDef<BillsTabItem, BillsTableMeta> = {
      id: CELLS_IDS.STATUS,
      size: 140,
      header: formatMessage('activities.payDashboard.billsTab.table.headers.status'),
      cell: ({ row }) => <BillsTabStatusCell billsTabItem={row} />,
    };
    const amountColumn: TableColumnDef<BillsTabItem, BillsTableMeta> = {
      id: CELLS_IDS.AMOUNT,
      isPinnedToRight: isLargeScreen,
      size: 180,
      header: getSortableHeader({
        textAlign: 'end',
        ...sortableColumns[CELLS_IDS.AMOUNT],
      }),
      cell: ({ row }) => <BillsTabAmountCell billsTabItem={row} />,
    };
    const actionButtonColumn: TableColumnDef<BillsTabItem, BillsTableMeta> = {
      id: CELLS_IDS.ACTION_BUTTON,
      isPinnedToRight: isLargeScreen,
      size: 80,
      header: formatMessage('activities.payDashboard.table.headers.action'),
      textAlign: 'end',
      cell: ({ row, meta }) =>
        meta ? (
          <BillsTabActionButtonCell
            isDisabled={!!meta.rowSelections.length}
            billsTabItem={row}
            onPayBillClick={({ billId, vendorId }: { billId?: string; vendorId?: string }) =>
              navigateToSchedulePayment({ billId, vendorId, returnUrl: pathname })
            }
            onPayPaymentRequest={meta.onPayPaymentRequest}
            onReviewScannedInvoiceClick={meta.onReviewScannedInvoiceClick}
            onViewItemClick={meta.onViewItemClick}
          />
        ) : null,
    };
    const moreActionsColumn: TableColumnDef<BillsTabItem, BillsTableMeta> = {
      id: CELLS_IDS.MORE_ACTIONS,
      size: 'xs',
      isPinnedToRight: isLargeScreen,
      header: <VisuallyHidden>{formatMessage('activities.payDashboard.table.headers.options')}</VisuallyHidden>,
      cell: ({ row, meta }) =>
        meta ? (
          <BillsTabMoreActionsCell
            isDisabled={!!meta.rowSelections.length}
            billsTabItem={row}
            onDeleteClick={meta.onDeleteBillItemClick}
            onEditClick={meta.onEditItemClick}
            onRejectClick={meta.onRejectClick}
            onViewClick={meta.onViewItemClick}
            onMarkAsPaidClick={meta.onMarkAsPaid}
          />
        ) : null,
    };

    return _compact([
      vendorColumn,
      isPreviewInvoiceEnabled ? invoiceColumn : null,
      billColumn,
      creationDateColumn,
      dueDateColumn,
      statusColumn,
      amountColumn,
      actionButtonColumn,
      moreActionsColumn,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage, sorting?.sortingState, isLargeScreen]);
};
