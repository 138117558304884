import { EditVendorFormWidgetProps } from '../types';
import { EditUnmanagedVendorFormMinimal } from './EditUnmanagedVendorForm';

export const EditVendorFormWidget = ({
  isEmailRequired,
  emailRequiredMessage,
  ...props
}: EditVendorFormWidgetProps) => (
  <EditUnmanagedVendorFormMinimal
    {...props}
    isEmailRequired={isEmailRequired}
    emailRequiredMessage={emailRequiredMessage}
  />
);

EditVendorFormWidget.displayName = 'EditVendorFormWidget';
