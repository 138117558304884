import { useAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryPreferenceType, Payment, PaymentStatusEnum } from '@melio/platform-api';
import { useDateUtils } from '@melio/platform-utils';
import { isAfter } from 'date-fns';

import { getEligibilityType } from '../../utils/analytics/paymentAnalytics';
import { isPartialPayment } from '../../utils/pay-flow/Payment.utils';

const getFailedDestination = (payment: Payment) => {
  switch (payment.status) {
    case PaymentStatusEnum.Failed:
      if (payment.paymentFailure?.failureType === 'collectFailure') {
        return 'collect-failure';
      }
      if (payment.paymentFailure?.failureType === 'deliveryFailure') {
        return 'deliver-failure';
      }
      return null;
    default:
      return null;
  }
};

const getIsPastDueDate = (payment: Payment, now: Date) => {
  if (!payment.bills?.[0]?.dueDate) {
    return undefined;
  }
  return isAfter(now, payment.bills[0].dueDate);
};

const getSpecialDeliveryMethod = (payment: Payment) => {
  if (payment.deliveryPreferenceType === DeliveryPreferenceType.ExpeditedAch) {
    return 'expedited-ach';
  }
  if (payment.deliveryPreferenceType === DeliveryPreferenceType.ExpressCheck) {
    return 'express-check';
  }
  return null;
};

export const usePaymentDrawerAnalytics = ({ payment }: { payment?: Payment }) => {
  const { createDate } = useDateUtils();
  useAnalyticsContext({
    globalProperties: !payment
      ? {
          PageName: 'payment-summary',
        }
      : {
          PageName: 'payment-summary',
          PaymentId: payment.id,
          VendorId: payment.vendorId,
          PaymentAmount: payment.amount,
          PaymentStatus: payment.status,
          DeliveryMethodType: payment.deliveryMethod?.type,
          IsPartial: isPartialPayment(payment),
          Reason: payment.paymentFailure?.failureReason,
          FailedDestination: getFailedDestination(payment),
          EligibilityType: getEligibilityType(payment),
          PaymentMethodType: payment.fundingSource?.type,
          CardNetwork: payment.fundingSource?.type === 'card' ? payment.fundingSource.details.network : undefined,
          IsPastDueDate: getIsPastDueDate(payment, createDate()),
          IsCombined: payment.bills && payment.bills.length > 1,
          SpecialDeliveryMethod: getSpecialDeliveryMethod(payment),
          BillIds: payment.bills?.map((bill) => bill.id),
          FileAttached: payment.bills?.some((bill) => bill.invoice.fileId),
          IsFinancing: payment.isFinanced,
          ApprovalDecisionStatus: payment.approvalDecisionStatus,
          IsRecurring: !!payment.subscriptionOccurrence,
          PaymentFrequency: payment.subscriptionOccurrence?.billSubscription?.intervalType,
          IsAutoPay: payment.isAutoPayment,
          Currency: payment.currency,
          UserMessage:
            payment.fundingSource && !payment.fundingSource.isVerified ? 'verify-your-bank-account' : undefined,
        },
  });
};
