import { Icon, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

export const CardPaymentPromotionBanner = ({ onDismiss }: { onDismiss: () => void }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <SectionBanner
      showCloseIcon
      onClose={onDismiss}
      leftElement={<Icon type="promote" />}
      variant="informative"
      title={formatMessage('activities.batchPayments.components.banners.promoteCardPayment.title')}
      description={formatMessage('activities.batchPayments.components.banners.promoteCardPayment.description')}
    />
  );
};
