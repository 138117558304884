import { Navigate, Route, Routes } from 'react-router-dom';

import { useIsSubscriptionsEnabled } from '../hooks';
import {
  AccountingClientSubscriptionPlanSelectedActivity,
  AccountingFirmClientMandatoryPlanRestrictionProps,
  OwnerShouldSetUpSubscriptionActivity,
} from '../ui';
import { SubscriptionDowngradeRoute } from './SubscriptionDowngrade.route';
import { SubscriptionGraceRoute } from './SubscriptionGrace.route';
import { SubscriptionPaymentMethodRoute } from './SubscriptionPaymentMethod.route';
import { SubscriptionSelectPlanRoute } from './SubscriptionSelectPlan.route';

type SubscriptionsRouterProps = {
  FullScreenLayout: React.FC<{ showOrgSwitcher: boolean }>;
} & AccountingFirmClientMandatoryPlanRestrictionProps;

export const SubscriptionsRouter = ({
  FullScreenLayout,
  switchOrganization,
  goToAssignClientPlan,
}: SubscriptionsRouterProps) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

  if (!isSubscriptionsEnabled) {
    return <Navigate to="/error" />;
  }

  return (
    <Routes>
      <Route index element={<Navigate to="plans" replace />} />
      <Route path="/*" element={<SubscriptionSelectPlanRoute />} />
      <Route path="payment-method/*" element={<SubscriptionPaymentMethodRoute />} />
      <Route element={<FullScreenLayout showOrgSwitcher />}>
        <Route path="accounting-client-plan-selected" element={<AccountingClientSubscriptionPlanSelectedActivity />} />
        <Route path="owner-need-to-finish-setting-up" element={<OwnerShouldSetUpSubscriptionActivity />} />
        <Route
          path="downgrade/*"
          element={
            <SubscriptionDowngradeRoute
              switchOrganization={switchOrganization}
              goToAssignClientPlan={goToAssignClientPlan}
            />
          }
        />
        <Route
          path="grace/*"
          element={
            <SubscriptionGraceRoute
              switchOrganization={switchOrganization}
              goToAssignClientPlan={goToAssignClientPlan}
            />
          }
        />
      </Route>
    </Routes>
  );
};
