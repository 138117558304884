import { BusinessSearchWidget, BusinessSearchWidgetProps } from '@melio/ap-widgets/src/components';
import { Form, FormSize, useBreakpointValue, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { noop } from 'lodash';
import { useEffect, useRef } from 'react';
import { object, SchemaOf, string } from 'yup';

export type BusinessSearchFormProps = Pick<
  BusinessSearchWidgetProps,
  'onSelectOption' | 'onCreateOption' | 'onClearField'
> & {
  stepCompleted: boolean;
};

export type BusinessSearchFormFields = { companyName: string };
const useSchema = (): SchemaOf<BusinessSearchFormFields> =>
  object().shape({
    companyName: string().required(),
  });

export const BusinessSearchForm = forwardRef<BusinessSearchFormProps, 'form'>(
  ({ onSelectOption, onCreateOption, onClearField, stepCompleted, ...props }, ref) => {
    const formSize = useBreakpointValue<FormSize | undefined>({ xs: 'small', s: 'large' });
    const { formProps, registerField } = useMelioForm<BusinessSearchFormFields>({
      onSubmit: noop,
      schema: useSchema(),
    });

    const searchInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (stepCompleted) {
        searchInputRef.current?.focus();
      }
    }, [stepCompleted]);

    return (
      <Form data-component="BusinessSearchForm" {...props} {...formProps} ref={ref} size={formSize}>
        <BusinessSearchWidget
          ref={searchInputRef}
          shouldUseModalOnMobile
          shouldShowModalOnMount={stepCompleted}
          onCreateOption={onCreateOption}
          onSelectOption={onSelectOption}
          onClearField={onClearField}
          {...registerField('companyName')}
        />
      </Form>
    );
  }
);

BusinessSearchForm.displayName = 'BusinessSearchForm';
