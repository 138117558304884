import { useMonitoring } from '@melio/platform-monitoring';
import { SystemMessage, SystemMessageProvider, useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';

import { MonitoredAction } from '../../monitoring';
import { AddVendor } from './AddVendor';
import { AddVendorActivityProps } from './types';

export const AddVendorActivity = ({ onDone, ...props }: AddVendorActivityProps) => {
  const { showMessage } = useSystemMessage();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();

  useEffect(() => {
    startAction('vendor_add');
  }, [startAction]);

  const handleCreateVendorSuccess = (message?: SystemMessage) => {
    if (message) {
      showMessage(message);
    }
  };

  const endMonitoringActionAndOnDone = (...args: Parameters<typeof onDone>) => {
    endAction('vendor_add');
    onDone(...args);
  };

  return (
    <SystemMessageProvider>
      <AddVendor {...props} onDone={endMonitoringActionAndOnDone} onCreateVendorSuccess={handleCreateVendorSuccess} />
    </SystemMessageProvider>
  );
};
