import {
  ApiError,
  BillSubscriptionEndPolicyEnum,
  PaymentCreateMethodEnum,
  PostBillSubscriptionsRequest,
  useBillSubscription,
  usePayment,
} from '@melio/platform-api';

import { PaymentFlowSubmitFields } from '../types';

export const useCreatePaymentSubmit = ({
  billId,
  onError,
}: {
  billId: string | undefined;
  onError?: (error: ApiError<string>, payload: { fundingSourceId: string }) => void;
}) => {
  const {
    _mutations: {
      create: {
        data: payment,
        mutateAsync: createPayment,
        isLoading: isCreatingPayment,
        error: paymentError,
        reset: resetPayment,
      },
    },
  } = usePayment({
    params: {
      expand: 'none',
    },
    enabled: false,
    id: 'useCreatePaymentSubmitPaymentId',
    onCreateError: onError,
  });

  const {
    _mutations: {
      create: {
        data: billSubscription,
        mutateAsync: createBillSubscription,
        isLoading: isCreatingBillSubscription,
        error: billSubscriptionError,
        reset: resetBillSubscription,
      },
    },
  } = useBillSubscription({ enabled: false, id: 'useCreatePaymentSubmitBillSubscriptionId', onCreateError: onError });

  const onSubmit = (formFields: PaymentFlowSubmitFields) => {
    resetPayment();
    resetBillSubscription();
    if (formFields.recurrenceType === 'recurring') {
      const billSubscriptionFormValues = getBillSubscriptionFormValues(formFields);
      return createBillSubscription(billSubscriptionFormValues);
    } else {
      const paymentFormValues = getPaymentFormValues(billId, formFields);
      return createPayment(paymentFormValues);
    }
  };

  return {
    result: payment || billSubscription,
    error: paymentError || billSubscriptionError,
    onSubmit,
    isSubmitting: isCreatingPayment || isCreatingBillSubscription,
  };
};

export const getBillSubscriptionFormValues = ({
  vendorId,
  amountToPay,
  fundingSourceId,
  deliveryMethodId,
  intervalType,
  startDate,
  endPolicy,
  endDate,
  scheduleDate,
  numOfOccurrences,
  noteToVendor,
  lastAmount: _lastAmount,
}: PaymentFlowSubmitFields): PostBillSubscriptionsRequest => {
  if (
    !vendorId ||
    !deliveryMethodId ||
    !fundingSourceId ||
    !scheduleDate ||
    !amountToPay ||
    !startDate ||
    !intervalType ||
    !endPolicy
  ) {
    throw 'missing required fields';
  }

  const lastAmount = _lastAmount ? Number(_lastAmount) : undefined;

  const commonFields = {
    vendorId,
    amount: Number(amountToPay),
    currency: 'USD',
    fundingSourceId,
    deliveryMethodId,
    memoToVendor: noteToVendor,
    intervalType,
    startDate,
    debitDate: scheduleDate,
  };

  switch (endPolicy) {
    case BillSubscriptionEndPolicyEnum.EndDate: {
      if (!endDate) {
        throw 'missing endDate';
      }
      return {
        endPolicy: BillSubscriptionEndPolicyEnum.EndDate,
        endDate,
        lastAmount,
        ...commonFields,
      };
    }
    case BillSubscriptionEndPolicyEnum.NumOfOccurrences: {
      if (!numOfOccurrences) {
        throw 'missing numOfOccurrences';
      }
      return {
        endPolicy: BillSubscriptionEndPolicyEnum.NumOfOccurrences,
        numOfOccurrences: Number(numOfOccurrences),
        lastAmount,
        ...commonFields,
      };
    }
    case BillSubscriptionEndPolicyEnum.NoEndDate:
      return {
        endPolicy: BillSubscriptionEndPolicyEnum.NoEndDate,
        ...commonFields,
      };
    default:
      throw 'unknown endPolicy type';
  }
};

export const getPaymentFormValues = (
  billId: string | undefined,
  {
    vendorId,
    deliveryMethodId,
    fundingSourceId,
    scheduleDate,
    amountToPay,
    deliveryPreference,
    etaDetails,
    deliveryDate,
    noteToVendor,
    quoteId,
  }: PaymentFlowSubmitFields
) => {
  if (
    !amountToPay ||
    !vendorId ||
    !deliveryMethodId ||
    !fundingSourceId ||
    !scheduleDate ||
    !amountToPay ||
    !deliveryPreference ||
    !deliveryDate
  ) {
    throw new Error('missing required fields');
  }

  return {
    paymentBillsInfo: [
      {
        id: billId,
        paymentAmount: Number(amountToPay),
      },
    ],
    vendorId,
    fundingSourceId,
    deliveryMethodId,
    deliveryPreference,
    deliveryDate: deliveryDate.toISOString(),
    scheduledDate: scheduleDate.toISOString(),
    createMethod: billId ? PaymentCreateMethodEnum.Single : PaymentCreateMethodEnum.JustPay,
    ...(quoteId && { payorFeeQuoteId: quoteId }),
    ...(noteToVendor && { noteToVendor }),
    ...(etaDetails && { etaDetails }),
  };
};
