import { Container, ContainerProps, TextProps, useBreakpointValue } from '@melio/penny';
import { PageTitle } from '@melio/platform-utils';

type TitleProps = Pick<TextProps, 'as' | 'textStyle' | 'children'> &
  Pick<ContainerProps, 'textAlign' | 'paddingTop'> & {
    autoFocus?: boolean;
  };

export const Title = ({ children, autoFocus = true, ...props }: TitleProps) => {
  const headingTextStyle = useBreakpointValue({
    xs: 'heading2Semi',
    m: 'heading1Semi',
  });
  const textAlign = useBreakpointValue<ContainerProps['textAlign']>({ xs: 'start', s: 'center' });

  return (
    <Container textAlign={textAlign} {...props}>
      <PageTitle
        autoFocus={autoFocus}
        data-component="NewSinglePaymentStepLayout.Title"
        data-testid="layout-title"
        textStyle={headingTextStyle}
      >
        {children}
      </PageTitle>
    </Container>
  );
};

Title.displayName = 'NewSinglePaymentStepLayout.Title';
