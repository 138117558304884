import {
  FormInputs,
  FormWidgetProps,
  maxEntityNameLength,
  maxPercentageValue,
  maxTotalAmount,
  minAmountValue,
  minPercentageValue,
  ServiceChargeCatalog,
  ServiceChargeTypeEnum,
  useMelioIntl,
} from '@melio/ar-domain';
import { Form, SelectOption, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import { number, object, SchemaOf, string } from 'yup';

import { ServiceChargeSelectWidget } from './ServiceChargeSelect.widget';

export type ServiceChargeFormFields = {
  id: string;
  type: ServiceChargeCatalog['type'];
  value: number;
};

export type ServiceChargeFormProps = FormWidgetProps<ServiceChargeFormFields> & {
  selectOptions: SelectOption[];
  selectedServiceChargeData?: Partial<ServiceChargeCatalog>;
  onChangeSelectedServiceCharge: (selectedId: string) => void;
  onCreateServiceCharge: (name: string) => string;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    id: string()
      .nullable()
      .required(formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.id.required.label')),
    type: string()
      .default(ServiceChargeTypeEnum.Percentage)
      .oneOf(Object.values(ServiceChargeTypeEnum))
      .required(formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.type.required.label')),
    value: number()
      .nullable()
      .typeError(formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.value.required.label'))
      .required(formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.value.required.label'))
      .when('type', {
        is: 'percentage',
        then: (schema) =>
          schema
            .lessThan(
              maxPercentageValue,
              formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.value.validation.percentage.max')
            )
            .moreThan(
              minPercentageValue,
              formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.value.validation.percentage.min')
            ),
      })
      .when('type', {
        is: 'amount',
        then: (schema) =>
          schema
            .lessThan(
              maxTotalAmount,
              formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.value.validation.amount.max')
            )
            .moreThan(
              minAmountValue,
              formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.value.validation.amount.min')
            ),
      }),
  }) as SchemaOf<ServiceChargeFormFields>;
};

export const ServiceChargeForm = forwardRef<ServiceChargeFormProps, 'form'>(
  (
    {
      onSubmit,
      onSubmissionStateChange,
      isSaving,
      selectOptions,
      selectedServiceChargeData,
      onChangeSelectedServiceCharge,
      onCreateServiceCharge,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { formProps, registerField, watch, setValue, setError, clearErrors } = useMelioForm<ServiceChargeFormFields>({
      onSubmit,
      schema: useSchema(),
      defaultValues: {
        id: selectedServiceChargeData?.id || '',
        type: selectedServiceChargeData?.type ?? ServiceChargeTypeEnum.Percentage,
        value: selectedServiceChargeData?.value || ('' as never),
      },
      isSaving,
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });

    const handleCreateServiceCharge = (name: string) => {
      if (name.length > maxEntityNameLength) {
        setError('id', {
          message: formatMessage(
            'ar.invoiceLifecycle.activities.createInvoice.serviceChargeCatalog.modal.form.fields.name.validation.max',
            {
              maxLength: maxEntityNameLength,
            }
          ),
        });
        return '';
      }
      clearErrors('id');
      return onCreateServiceCharge(name);
    };

    const getFieldLabel = useGetFormFieldLabel();
    const [currentServiceChargeId, type, value] = watch(['id', 'type', 'value']);
    const isTypePercentage = type === ServiceChargeTypeEnum.Percentage;
    const isTypeChanged = type !== selectedServiceChargeData?.type;
    const isValueChanged = value !== selectedServiceChargeData?.value;
    const isUpdated = isTypeChanged || isValueChanged;
    const radioGroupOptions = [
      {
        mainLabelProps: { label: getFieldLabel('percentage') },
        value: ServiceChargeTypeEnum.Percentage,
      },
      {
        mainLabelProps: { label: getFieldLabel('fixedAmount') },
        value: ServiceChargeTypeEnum.Amount,
      },
    ];

    useEffect(() => {
      if (!isUpdated) {
        clearErrors('value');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
      const isEditing = currentServiceChargeId === selectedServiceChargeData?.id;
      const isCurrentType = isEditing && type === selectedServiceChargeData.type;
      const currentValue = selectedServiceChargeData?.value;
      const targetValue = (isCurrentType && currentValue ? currentValue : '') as never;
      setValue('value', targetValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTypeChanged, currentServiceChargeId]);

    return (
      <Form
        data-component={ServiceChargeForm.displayName}
        data-testid="form-service-charge-catalog"
        {...props}
        {...formProps}
        columns={2}
        ref={ref}
      >
        <ServiceChargeSelectWidget
          labelProps={{ label: getFieldLabel('name') }}
          colSpan={2}
          isRequired
          onCreateOption={handleCreateServiceCharge}
          onChangeOption={onChangeSelectedServiceCharge}
          selectOptions={selectOptions}
          {...registerField('id')}
        />
        <Form.RadioGroup
          colSpan={2}
          labelProps={{
            label: getFieldLabel('type'),
          }}
          {...registerField('type')}
          variant="horizontal"
          options={radioGroupOptions}
        />
        {isTypePercentage ? (
          <FormInputs.PercentageField
            colSpan={2}
            {...registerField('value')}
            labelProps={{ label: getFieldLabel('amount') }}
            placeholder="0%"
          />
        ) : (
          <FormInputs.AmountField
            colSpan={2}
            {...registerField('value')}
            labelProps={{ label: getFieldLabel('amount') }}
            placeholder="$0.00"
          />
        )}
      </Form>
    );
  }
);

const useGetFormFieldLabel = () => {
  const { formatMessage } = useMelioIntl();
  return (value: 'name' | 'type' | 'percentage' | 'fixedAmount' | 'amount') => {
    let labelText: string;
    switch (value) {
      case 'name':
        labelText = formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.inputs.name.title.label');
        break;
      case 'type':
        labelText = formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.inputs.type.title.label');
        break;
      case 'percentage':
        labelText = formatMessage(
          'ar.invoiceLifecycle.activities.serviceChargeCatalog.form.inputs.type.options.percentage.label'
        );
        break;
      case 'fixedAmount':
        labelText = formatMessage(
          'ar.invoiceLifecycle.activities.serviceChargeCatalog.form.inputs.type.options.fixedAmount.label'
        );
        break;
      case 'amount':
        labelText = formatMessage('ar.invoiceLifecycle.activities.serviceChargeCatalog.form.inputs.amount.title.label');
        break;
    }
    return labelText;
  };
};

ServiceChargeForm.displayName = 'ServiceChargeForm';
