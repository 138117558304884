import { Form, FormSearchProps, SearchOption } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Account, useIndustryTypes } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { identity } from 'lodash';
import { useEffect, useState } from 'react';

type Industry = Account['company']['industry'];

export type IndustryTypeSelectWidgetProps = Omit<FormSearchProps, 'options' | 'emptyState'> & {
  onCreateOption?: (option: SearchOption) => void;
  sixDigitNaicsOnly?: boolean;
};

// Use `_createFormFieldInput` to wrap the field with label and helper text (in the future widgets could wrap `Form.Search`)
export const IndustryTypeSelectWidget = forwardRef<IndustryTypeSelectWidgetProps, 'input'>(
  ({ onCreateOption, sixDigitNaicsOnly, ...props }, ref) => {
    useDevFeature(FeatureFlags.PlatformIndustryFuzzySearch, false, { shouldTrack: true });
    const { track } = useAnalytics();
    const [industryOptions, setIndustryOptions] = useState<SearchOption[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { isLoading, data } = useIndustryTypes({
      enabled: true,
      params: { q: searchTerm, sixDigitNaicsOnly: !!sixDigitNaicsOnly },
    });
    const { formatMessage } = useMelioIntl();

    useEffect(() => {
      if (!data) {
        return;
      }

      const options = data.map(
        (option): SearchOption => ({
          label: option.title,
          value: { name: option.title, naicsCode: option.naicsCode },
          description: option.subtitle,
        })
      );

      setIndustryOptions(options);
    }, [data]);

    const debouncedSearch = (value: string) => {
      track('EditIndustry', 'Type', {
        Intent: 'find-industry-values`',
        Flow: 'edit-business-industry',
        value,
      });
      setSearchTerm(value);
    };

    const handleCreateOption = (inputValue: string) => {
      track('EditIndustry', 'Click', {
        Intent: 'create-new-option',
        Flow: 'edit-business-industry',
        value: inputValue,
      });
      const customOption: SearchOption = {
        label: inputValue,
        value: { name: inputValue, naicsCode: null },
      };
      setIndustryOptions((prevOptions) => [...prevOptions, customOption]);
      onCreateOption?.(customOption);
    };

    return (
      // The ariaLabel and labelProps props are mutually exclusive, meaning that either labelProps or ariaLabel exists, never both simultaneously.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      <Form.Search
        data-component="IndustryTypeSelectWidget"
        ref={ref}
        debounce={300}
        options={industryOptions}
        onInputChange={debouncedSearch}
        isLoading={isLoading}
        emptyState={{
          label: formatMessage('form.industryType.emptyState'),
        }}
        formatSelectedValue={(option) => (option.value as unknown as Industry)?.name ?? option.label}
        creatableOption={{
          label: (value) => formatMessage('form.industryTypeSelect.createLabel', { value }),
          onClick: handleCreateOption,
        }}
        {...props}
        data-sixdigitnaicsonly={!!sixDigitNaicsOnly}
        filterOptions={identity}
      />
    );
  }
);

IndustryTypeSelectWidget.displayName = 'IndustryTypeSelectWidget';
