import {
  CustomersDashboardSortByEnum,
  CustomersDashboardSortQueryParam,
  DashboardCustomer,
  SortableHeaderCell,
  useMelioIntl,
} from '@melio/ar-domain';
import { Avatar, Group, Table, TableColumnDef, Text } from '@melio/penny';
import { useMemo } from 'react';

import { useCustomersTableSortableColumns } from '../../hooks';
import { CustomerActions, OnSort, SortField } from '../../types';
import { AmountWithInvoicesCountCell, CustomerActionCell, InvoicesCell } from '../table-cells';

type UseCustomersTableColumnsProps = {
  sortParams?: CustomersDashboardSortQueryParam;
  onSort: OnSort;
};

export const useCustomersTableColumns = ({ sortParams, onSort }: UseCustomersTableColumnsProps) => {
  const { formatMessage } = useMelioIntl();
  const sortableColumns = useCustomersTableSortableColumns();

  const sortableHeaderProps = useMemo(
    () => ({ order: sortParams?.order, selectedField: sortParams?.field, onClick: onSort }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify([sortParams?.order, sortParams?.field])]
  );

  const columns = useMemo<TableColumnDef<DashboardCustomer, CustomerActions>[]>(
    () => [
      {
        id: 'customerName',
        size: 'm',
        isPinnedToLeft: true,
        header: (
          <SortableHeaderCell<SortField>
            label={sortableColumns[CustomersDashboardSortByEnum.CompanyName].label}
            field="companyName"
            {...sortableHeaderProps}
          />
        ),
        cell: ({ row }) => (
          <Table.Cell data-testid="customer-name">
            <Group spacing="xs" alignItems="center">
              <Avatar aria-hidden name={row.companyName} />
              <Group spacing="xxs" variant="vertical">
                {row.companyName}
                <Text textStyle="body4" color="semantic.text.secondary">
                  {row.email}
                </Text>
              </Group>
            </Group>
          </Table.Cell>
        ),
      },
      {
        id: 'invoices',
        header: formatMessage('ar.dashboard.activities.customersTable.columns.invoices.label'),
        cell: ({ row, meta }) => (
          <InvoicesCell
            customer={row}
            onClick={() => {
              meta?.onDisplayInvoices?.({ customerId: row.id, searchTerm: row.companyName });
            }}
          />
        ),
        size: 's',
      },
      {
        id: 'paid',
        header: formatMessage('ar.dashboard.activities.customersTable.columns.paid.label'),
        cell: ({ row }) => <AmountWithInvoicesCountCell overview={row.invoicesOverview.paid} fieldName="paid" />,
        size: 's',
      },
      {
        id: 'openBalance',
        header: formatMessage('ar.dashboard.activities.customersTable.columns.openBalance.label'),
        cell: ({ row }) => <AmountWithInvoicesCountCell overview={row.invoicesOverview.open} fieldName="openBalance" />,
        size: 's',
      },
      {
        id: 'actions',
        cell: CustomerActionCell,
        size: 'xs',
      },
    ],
    [formatMessage, sortableHeaderProps, sortableColumns]
  );

  return columns;
};
