import { Form, useMelioForm } from '@melio/penny';
import { FundingSourceType, useAccountingPlatforms } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { AccountingPlatformAccountSelectWidget } from '../../form-controls';
import { ReconciliationFormFields, ReconciliationFormProps } from './reconciliationForm.widget.types';
import { useAccountingPlatformName, useSchema } from './reconciliationForm.widget.utils';

const fsTypeToLabelKey = (fsType?: FundingSourceType): MessageKey => {
  switch (fsType) {
    case FundingSourceType.Card:
      return 'widgets.fundingSources.reconciliationForm.fields.accountingPlatformPaymentAccountId.card.label';
    case FundingSourceType.BankAccount:
    default:
      return 'widgets.fundingSources.reconciliationForm.fields.accountingPlatformPaymentAccountId.bank.label';
  }
};
export const ReconciliationForm = forwardRef<ReconciliationFormProps, 'form'>(
  (
    {
      defaultValues,
      onSubmit,
      newPaymentAccountName,
      onSubmissionStateChange,
      isSaving,
      onCreateOption,
      disabledNewOption,
      fundingSourceType,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { activeAccountingPlatform } = useAccountingPlatforms();
    const activeAccountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
    const labelKey = fsTypeToLabelKey(fundingSourceType);

    const { formProps, registerField } = useMelioForm<ReconciliationFormFields>({
      onSubmit,
      schema: useSchema(activeAccountingPlatformName, disabledNewOption),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
    });
    return (
      <Form size="large" data-component="ReconciliationForm" {...props} {...formProps} ref={ref}>
        <AccountingPlatformAccountSelectWidget
          onCreateOption={onCreateOption}
          newPaymentAccountName={newPaymentAccountName}
          labelProps={{
            label: formatMessage(labelKey, { accountingPlatform: activeAccountingPlatformName }),
          }}
          placeholder={formatMessage(
            'widgets.fundingSources.reconciliationForm.fields.accountingPlatformPaymentAccountId.placeholder'
          )}
          data-testid="reconciliation-select"
          isRequired
          disabledNewOption={disabledNewOption}
          {...registerField('accountingPlatformPaymentAccountId')}
        />
      </Form>
    );
  }
);

ReconciliationForm.displayName = 'ReconciliationForm';
