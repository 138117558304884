import { addWildcardToRoutes, Navigate, useFlowRouting, withLocalization } from '@melio/ar-domain';
import { Route, Routes } from 'react-router-dom';

import {
  BusinessDetailsActivity,
  ConfirmReceivingAccountActivity,
  FinancialInformationActivity,
  GetStartedActivity,
  LandingPageActivity,
  OwnershipDetailsActivity,
  ReviewAndApproveActivity,
} from '../activities';
import { SubscriptionOnboardingFlow } from './SubscriptionOnboarding.flow';

type OnboardingFlowProps = {
  onCreateInvoice: VoidFunction;
};

export const OnboardingFlow = withLocalization(({ onCreateInvoice }: OnboardingFlowProps) => {
  const {
    Paths,
    goToBusinessDetails,
    goToOwnershipsDetails,
    goToGetStarted,
    goToFinancialInformation,
    goToConfirmReceivingAccount,
    goToReviewAndApproveLDD,
    DefaultPath,
    goToLangingPage,
  } = useOnboardingFlow();

  return (
    <Routes>
      <Route index path="*" element={<Navigate to={DefaultPath} replace />} />
      <Route
        path={Paths.LandingPage}
        element={<LandingPageActivity onClose={() => null} onDone={goToGetStarted} onCreateInvoice={onCreateInvoice} />}
      />
      <Route
        path={Paths.GetStarted}
        element={<GetStartedActivity onClose={goToLangingPage} onDone={goToBusinessDetails} />}
      />
      <Route
        path={Paths.BusinessDetails}
        element={<BusinessDetailsActivity onBack={goToGetStarted} onDone={goToFinancialInformation} />}
      />
      <Route
        path={Paths.FinancialInformation}
        element={<FinancialInformationActivity onBack={goToBusinessDetails} onDone={goToOwnershipsDetails} />}
      />
      <Route
        path={Paths.OwnershipDetails}
        element={
          <OwnershipDetailsActivity
            onBack={goToBusinessDetails}
            onDone={goToConfirmReceivingAccount}
            onClose={goToGetStarted}
          />
        }
      />
      <Route
        path={Paths.ConfirmReceivingAccount}
        element={
          <ConfirmReceivingAccountActivity
            onBack={goToOwnershipsDetails}
            onDone={goToReviewAndApproveLDD}
            onClose={goToGetStarted}
          />
        }
      />
      <Route
        path={Paths.ReviewAndApproveLDD}
        element={<ReviewAndApproveActivity onBack={goToConfirmReceivingAccount} onClose={goToGetStarted} />}
      />
      <Route
        path={Paths.Subscription}
        element={<SubscriptionOnboardingFlow onBack={goToReviewAndApproveLDD} onClose={goToGetStarted} />}
      />
    </Routes>
  );
});
OnboardingFlow.displayName = 'OnboardingFlow';

const useOnboardingFlow = () => {
  enum Paths {
    GetStarted = 'get-started',
    BusinessDetails = 'business-details',
    FinancialInformation = 'financial-information',
    OwnershipDetails = 'ownership-details',
    ReviewAndApproveLDD = 'review-and-approve',
    ConfirmReceivingAccount = 'receiving-account',
    Subscription = 'subscription',
    SubmitBusinessDetails = 'submit',
    LandingPage = 'landing-page',
  }

  const { createCallback } = useFlowRouting<Paths>({ withSearchparams: true });

  return {
    Paths: addWildcardToRoutes(Paths),
    DefaultPath: Paths.LandingPage,
    goToGetStarted: createCallback(Paths.GetStarted),
    goToBusinessDetails: createCallback(Paths.BusinessDetails),
    goToOwnershipsDetails: createCallback(Paths.OwnershipDetails),
    goToReviewAndApproveLDD: createCallback(Paths.ReviewAndApproveLDD),
    goToSubscription: createCallback(Paths.Subscription),
    goToFinancialInformation: createCallback(Paths.FinancialInformation),
    goToConfirmReceivingAccount: createCallback(Paths.ConfirmReceivingAccount),
    goToLangingPage: createCallback(Paths.LandingPage),
  };
};
