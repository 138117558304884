import { useAnalytics } from '@melio/platform-analytics';
import {
  CreateInternationalAccountDeliveryMethod,
  DeliveryMethod,
  InternationalAccountCreateParams,
  useDeliveryMethods,
} from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useOneTimeEffect } from '@melio/platform-utils';

import { FxVendorAccountFieldsFormFields } from './fxBankDetailsForm';

type UseMigrateInternationalDeliveryMethodProps = {
  vendorId?: string;
  onCreateDeliveryMethod: (data: DeliveryMethod) => void;
  fxBankDetailsDefaultValues: Partial<FxVendorAccountFieldsFormFields>;
  onCreateError: (error: { message: string; code?: string }) => void;
};

export const useMigrateInternationalDeliveryMethod = ({
  vendorId,
  onCreateDeliveryMethod,
  onCreateError,
  fxBankDetailsDefaultValues,
}: UseMigrateInternationalDeliveryMethodProps) => {
  const { track } = useAnalytics();
  const _onCreateDeliveryMethod = (data: DeliveryMethod) => {
    track('Vendor', 'Status', {
      Status: 'success',
      PageName: 'delivery-method-migration',
      Intent: 'set-vendor-details',
    });
    onCreateDeliveryMethod(data);
  };
  const [enabledInternationalUSDWise] = usePartnerFeature('EnableInternationalUSDWise', false);
  const { create: createDeliveryMethod, isCreating: isCreatingDM } = useDeliveryMethods({
    vendorId,
    onCreate: _onCreateDeliveryMethod,
    enabled: false,
    onCreateError,
  });

  const createInternationalUsdRequest = (
    fxBankDetailsDefaultValues: Partial<FxVendorAccountFieldsFormFields>
  ): CreateInternationalAccountDeliveryMethod => ({
    type: 'international-account',
    details: {
      currency: 'USD',
      companyName: fxBankDetailsDefaultValues.legalBusinessName,
      address: {
        city: fxBankDetailsDefaultValues.city,
        countryCode: fxBankDetailsDefaultValues.country,
        line1: fxBankDetailsDefaultValues.line1,
        postalCode: fxBankDetailsDefaultValues.postalCode,
        state: fxBankDetailsDefaultValues.state,
      },
      identifierDetails: {
        requiredFields: {
          accountHolderName: fxBankDetailsDefaultValues.accountHolderName,
          accountNumber: fxBankDetailsDefaultValues.accountNumber,
          bic: fxBankDetailsDefaultValues.bic,
        },
        payeeAccountType: 'swift_code',
        bankCountryCode: fxBankDetailsDefaultValues.fxCountrySelection,
      },
    } as InternationalAccountCreateParams,
  });

  useOneTimeEffect(
    () => {
      const request = createInternationalUsdRequest(fxBankDetailsDefaultValues);
      createDeliveryMethod(request);
    },
    () =>
      !!(
        enabledInternationalUSDWise &&
        fxBankDetailsDefaultValues.foreignCurrency === 'USD' &&
        fxBankDetailsDefaultValues.bic &&
        fxBankDetailsDefaultValues.accountNumber
      ),
    [createDeliveryMethod, enabledInternationalUSDWise, fxBankDetailsDefaultValues]
  );

  return { isMigrationLoading: isCreatingDM };
};
