import { CatalogItem, useMelioIntl } from '@melio/ar-domain';
import { Container, Icon, Table, TableColumnDef, Text, useTable } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useMemo } from 'react';

import { CatalogItemActionCell } from './CatalogItemActionCell';
import { CatalogItemsTableListItem } from './CatalogItemsTableListItem.mobile';

export type CatalogItemsTableProps = {
  isLoading?: boolean;
  catalogItems: CatalogItem[];
  onEditItem: (itemId: string) => unknown;
  onDeleteItem: (itemId: string) => unknown;
};

export const CatalogItemsTable = forwardRef<CatalogItemsTableProps>(
  ({ catalogItems, isLoading, onEditItem, onDeleteItem, ...props }, ref) => {
    const { formatCurrency, formatMessage } = useMelioIntl();
    const mobileRowRenderer = useCallback(
      (row: CatalogItem) => (
        <CatalogItemsTableListItem catalogItem={row} onEditItem={onEditItem} onDeleteItem={onDeleteItem} />
      ),
      [onEditItem, onDeleteItem]
    );

    const columns = useMemo<TableColumnDef<CatalogItem>[]>(
      () => [
        {
          id: 'name',
          size: 'l',
          header: formatMessage(
            'ar.settings.activities.catalogItemsTable.components.catalogItemsTable.columns.name.header'
          ),
          cell: ({ row }) => <Table.Cell data-testid="catalog-item-name">{row.name}</Table.Cell>,
        },
        {
          id: 'price',
          size: 'm',
          header: formatMessage(
            'ar.settings.activities.catalogItemsTable.components.catalogItemsTable.columns.price.header'
          ),
          cell: ({ row }) => <Table.Cell data-testid="catalog-item-price">{formatCurrency(row.price)}</Table.Cell>,
        },
        {
          id: 'taxable',
          size: 's',
          header: (
            <Table.HeaderCell>
              <Container justifyContent="center">
                {formatMessage(
                  'ar.settings.activities.catalogItemsTable.components.catalogItemsTable.columns.taxable.header'
                )}
              </Container>
            </Table.HeaderCell>
          ),
          cell: ({ row }) => (
            <Table.Cell data-testid="catalog-item-taxable" data-checked={row.taxable}>
              {row.taxable && (
                <Container justifyContent="center">
                  <Text color="global.success.700">
                    <Icon type="checked-circle" color="inherit" aria-label="is taxable" />
                  </Text>
                </Container>
              )}
            </Table.Cell>
          ),
        },
        {
          id: 'actions',
          size: 'xs',
          cell: ({ row }) => (
            <CatalogItemActionCell catalogItem={row} onEditItem={onEditItem} onDeleteItem={onDeleteItem} />
          ),
        },
      ],
      [formatCurrency, formatMessage, onDeleteItem, onEditItem]
    );

    const table = useTable({
      data: catalogItems,
      isLoading,
      columns,
      mobileRowRenderer,
      headerVariant: 'dark',
      hideHeaderWhileLoading: true,
    });
    return <Table {...table} {...props} ref={ref} />;
  }
);
