import { Group, Pill } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const PremiumBadge = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group width="fit-content" spacing="none" justifyContent="center" alignItems="center" data-testid="premium-badge">
      <Pill
        label={formatMessage('widgets.taxAndReportsSettings.taxFormSection.eFilling.badge')}
        status="brand"
        type="secondary"
      />
    </Group>
  );
};
