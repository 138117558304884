import { Button, Container, Form, Group, VisuallyHidden } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PaymentCalendarCalculation } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';
import { isSameDay } from 'date-fns';
import { ComponentProps, useState } from 'react';

type DateFieldProps = Omit<
  ComponentProps<typeof Form.DateField>,
  'footer' | 'isOpen' | 'selectedDate' | 'onSelectDate' | 'onOpenChange' | 'isTypable'
> & { paymentCalendarDates?: PaymentCalendarCalculation['dates'] };

const DateFieldHelperText = ({
  name,
  format = 'MM/dd/yyyy',
  helperTextProps,
}: Pick<DateFieldProps, 'name' | 'format' | 'helperTextProps'>) => {
  const { formatMessage } = useMelioIntl();

  return (
    <>
      <VisuallyHidden data-testid={`${name}-date-field-helper-text-format`}>
        {formatMessage('activities.paymentFlow.form.content.deliveryDate.format.sr-only', {
          dateFormat: format,
        })}
      </VisuallyHidden>
      {helperTextProps && (
        <Container data-testid={`${name}-date-field-helper-text-value`}>{helperTextProps?.label}</Container>
      )}
    </>
  );
};

export function DateField({ value, paymentCalendarDates, onChange, ...props }: DateFieldProps) {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const [selectedDate, setSelectedDate] = useState(value);
  const [isCalendarOpen, calendar] = useBoolean(false);

  const dates = selectedDate
    ? paymentCalendarDates?.find((d) => isSameDay(d.minDeliveryDate, selectedDate))
    : undefined;

  const fieldLabel = props.labelProps?.label;

  return (
    <Form.DateField
      {...props}
      helperTextProps={{
        label: <DateFieldHelperText name={props.name} format={props.format} helperTextProps={props.helperTextProps} />,
      }}
      toggleDatePickerAriaLabel={formatMessage('activities.paymentFlow.form.content.deliveryDate.calendarIcon.label', {
        fieldLabel,
      })}
      value={value}
      secondarySelectedDate={dates?.scheduleDate}
      disableYearSelection
      onChange={(date) => {
        if (!date || !value || !isSameDay(date, value)) {
          track('Payment', 'Click', {
            Intent: `set-payment-${props.name}`,
            PageName: 'date-picker',
            Cta: props.name,
          });
          setSelectedDate(date);
          onChange?.(date);
        }
      }}
      isOpen={isCalendarOpen}
      onOpenChange={(isOpen) => {
        if (isOpen) {
          track('Payment', 'View', {
            Intent: `set-payment-${props.name}`,
            PageName: 'date-picker',
          });
          setSelectedDate(value);
          calendar.on();
        }
      }}
      onSelectDate={(value) => setSelectedDate(value)}
      selectedDate={selectedDate}
      isTypable
      footer={
        <Container paddingX="s" paddingY="s" data-testid="deliveryDate-calendar-footer">
          <Group variant="horizontal" justifyContent="space-between">
            <Button
              data-testid="deliveryDate-calendar-footer-close"
              variant="tertiary"
              size="small"
              label={formatMessage('activities.paymentFlow.form.content.deliveryDate.footer.close')}
              onClick={() => {
                track('Payment', 'Click', {
                  Intent: `set-payment-${props.name}`,
                  PageName: 'date-picker',
                  Cta: 'close',
                });
                calendar.off();
              }}
            />
            <Button
              data-testid="deliveryDate-calendar-footer-apply"
              variant="primary"
              size="small"
              label={formatMessage('activities.paymentFlow.form.content.deliveryDate.footer.apply')}
              onClick={() => {
                if (!selectedDate) {
                  return;
                }
                track('Payment', 'Click', {
                  Intent: `set-payment-${props.name}`,
                  PageName: 'date-picker',
                  Cta: 'apply',
                });
                onChange?.(selectedDate);
                calendar.off();
              }}
            />
          </Group>
        </Container>
      }
    />
  );
}
