import { ARInvoice, useCustomer } from '@melio/ar-domain';
import { Avatar, Group, Loader, Table, Text } from '@melio/penny';
import { VFC } from 'react';

export type CustomerCellProps = {
  row: ARInvoice;
};
export const CustomerCell: VFC<CustomerCellProps> = ({ row, ...props }) => {
  const { data, isLoading } = useCustomer({ id: row.customerId });
  return (
    <Table.Cell data-component={CustomerCell.displayName} data-testid="invoice-customer-name" {...props}>
      {isLoading ? (
        <Loader />
      ) : (
        <Group spacing="xs" alignItems="center">
          <Avatar aria-hidden name={data?.companyName || ''} size="medium" variant="circle" />
          <Group spacing="xxs" variant="vertical">
            {data?.companyName}
            <Text textStyle="body4" color="semantic.text.secondary">
              {data?.email}
            </Text>
          </Group>
        </Group>
      )}
    </Table.Cell>
  );
};
CustomerCell.displayName = 'CustomerCell';
