// eslint-disable-next-line import/no-deprecated
import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { useUpdatedInvoiceSystemMessage, useUserState } from '../../../hooks';
import { AddBankAccountDetailsScreen, AddBankAccountDetailsScreenProps } from './screens';

export type AddBankAccountDetailsActivityProps = Pick<
  AddBankAccountDetailsScreenProps,
  'onViewInvoice' | 'onSelectFundingSource' | 'isDisabled'
> & {
  paymentRequestLink: string;
  isNavigateApUsersToDashboardEnabled?: boolean;
  onDone: (email: string) => void;
  onError?: ARErrorFunction;
};

export const AddBankAccountDetailsActivity = withAnalyticsContext<AddBankAccountDetailsActivityProps>(
  forwardRef(
    (
      {
        onError,
        paymentRequestLink,
        isNavigateApUsersToDashboardEnabled,
        isDisabled,
        onDone,
        setAnalyticsProperties,
        ...props
      },
      ref
    ) => {
      const [currentUserEmail, setCurrentUserEmail] = useState<string>();
      const userState = useUserState({ email: currentUserEmail, paymentRequestLink });
      const { data: paymentRequestDetails, isLoading } = useGuestPayorPaymentRequestDetails({
        paymentRequestLink,
        onError,
      });

      const isPayable = paymentRequestDetails?.isPayable ?? true;

      useUpdatedInvoiceSystemMessage(paymentRequestLink);

      const { track } = useAnalytics();

      useEffect(() => {
        if (userState.userType) {
          track('Connection', 'Status', { UserType: userState.userType });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [userState.userType]);

      setAnalyticsProperties({
        UserType: userState.userType,
        Email: currentUserEmail || undefined,
        PaymentMethodType: 'ach',
      });

      return (
        <AddBankAccountDetailsScreen
          paymentRequestDetails={paymentRequestDetails}
          onSubmit={(data) => onDone(data.email)}
          isSaving={isDisabled}
          onEmailChanged={setCurrentUserEmail}
          isLoading={isLoading}
          isCheckingEmail={userState.isCheckingEmail}
          signInUrl={
            (isNavigateApUsersToDashboardEnabled && isPayable && userState.isApUser && userState.signInUrl) || undefined
          }
          isPayable={isPayable}
          {...props}
          ref={ref}
        />
      );
    }
  )
);
AddBankAccountDetailsActivity.displayName = 'AddBankAccountDetailsActivity';
