import { capOneLinksOverrides } from './data/CapOneLinksOverrides';
import { cloverLinksOverrides } from './data/CloverLinksOverrides';
import { defaultLinks as _defaultLinks } from './data/DefaultLinks';
import { fiservLinksOverrides } from './data/FiservLinksOverrides';
import { melioLinksOverrides } from './data/MelioLinksOverrides';
import { sbbLinksOverrides } from './data/SbbLinksOverrides';
import { LinkConstants, LinkOverrides } from './types';

export { defaultLinks } from './data/DefaultLinks';
export type { LinkConstants } from './types';

const mergePartnerLinks = (partnerLinkOverrides: LinkOverrides): LinkConstants => {
  return Object.assign({}, _defaultLinks, partnerLinkOverrides);
};

export const capOneLinks = mergePartnerLinks(capOneLinksOverrides);
export const cloverLinks = mergePartnerLinks(cloverLinksOverrides);
export const melioLinks = mergePartnerLinks(melioLinksOverrides);
export const fiservLinks = mergePartnerLinks(fiservLinksOverrides);
export const sbbLinks = mergePartnerLinks(sbbLinksOverrides);
