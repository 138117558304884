import { identity, isEmpty, pickBy } from 'lodash';

import { AddVendorFormFields } from '../../../../types';

export const getCreateVendorPayload = (data: AddVendorFormFields) => {
  const {
    companyName,
    email,
    phone,
    accountNumber,
    nickname,
    line1,
    line2,
    city,
    state,
    postalCode,
    bankAccountNumber,
    bankRoutingNumber,
    uniqueName,
  } = data;

  const bankAccount =
    bankAccountNumber && bankRoutingNumber
      ? {
          accountNumber: bankAccountNumber,
          routingNumber: bankRoutingNumber,
        }
      : undefined;

  const contact = pickBy(
    {
      email,
      phoneNumber: phone,
      ...(line1 ? { address: { line1, line2, city, state, postalCode } } : null),
    },
    identity
  );

  return {
    name: companyName ?? '',
    uniqueName: !isEmpty(uniqueName) ? uniqueName : undefined,
    contact,
    accountNumber: accountNumber ?? undefined,
    ...(nickname ? { nickname } : {}),
    ...(bankAccount ? { bankAccount } : {}),
  };
};
