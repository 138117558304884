import { Container, Group, Text } from '@melio/penny';
import { BillSubscription, Vendor } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum } from '@/types/vendors.types';
import { RecurringPaymentCard } from '@/widgets/vendorsDashboard/RecurringPayments/RecurringPaymentCard.widget';

type RecurringPaymentsCardProps = {
  billSubscriptions: WithRequiredProperty<BillSubscription, 'nextOccurrence'>[];
  vendor: Vendor;
  onEditBillSubscription: ({ id }: { id: string }) => void;
  onEditNextPaymentClick: (id: string) => void;
};
export const RecurringPayments = ({
  billSubscriptions,
  vendor,
  onEditBillSubscription,
  onEditNextPaymentClick,
}: RecurringPaymentsCardProps) => {
  const { formatMessage } = usePlatformIntl();

  return (
    <>
      <Container data-component={DataComponentEnum.RECURRING_PAYMENTS} data-testid="recurring-payments">
        <Text as="h3" textStyle="heading3Semi">
          {formatMessage('widgets.recurringPayments.title')}
        </Text>
      </Container>
      <Group variant="vertical" spacing="m">
        {billSubscriptions
          .sort(
            (a, b) =>
              (a.nextOccurrence!.deliveryDate?.getTime() || a.nextOccurrence!.dueDate.getTime()) -
              (b.nextOccurrence!.deliveryDate?.getTime() || b.nextOccurrence!.dueDate.getTime()),
          )
          .map((billSubscription) => (
            <RecurringPaymentCard
              key={billSubscription.id}
              billSubscription={billSubscription}
              vendor={vendor}
              onEditBillSubscription={onEditBillSubscription}
              onEditNextPaymentClick={onEditNextPaymentClick}
            />
          ))}
      </Group>
    </>
  );
};

RecurringPayments.displayName = 'RecurringPayments';
