import { Box } from '@chakra-ui/react';
import { Button, Container, Divider, FloatingMenu, Group, Icon, Menu, NavigationItem } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { FilterMenuOptionsMulti } from './FilterMenuOptionsMulti';
import { FilterMenuOptionsSingle } from './FilterMenuOptionsSingle';
import { NestedFiltersProps } from './types';
import { getFilterByKey } from './utils';

export const NestedFiltersDesktop = <T extends string>({ filters, activeFilters, onChange }: NestedFiltersProps<T>) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [visibleFilterKey, setVisibleFilterKey] = useState<T | undefined>();

  const handleOpenMenuChange = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
    if (isOpen) {
      setVisibleFilterKey(filters[0]?.key);
    } else {
      cleanState();
    }
  };

  const cleanState = () => {
    setVisibleFilterKey(undefined);
  };

  const apply = (filterKey: T, values: string[]) => {
    onChange(filterKey, values);
  };

  const handleSingleItemClick = (filterKey: T, optionValue?: string) => {
    apply(filterKey, optionValue ? [optionValue] : []);
  };

  const handleMultiSelectionItemClick = (filterKey: T, optionValue: string) => {
    const filterValues = activeFilters?.[filterKey];
    if (filterValues?.includes(optionValue)) {
      apply(filterKey, filterValues.filter((option) => option !== optionValue) ?? []);
    } else {
      apply(filterKey, [...(filterValues ?? []), optionValue]);
    }
  };

  const renderTrigger = () => (
    <Button
      variant="tertiary"
      size="medium"
      label={formatMessage('filter.button.trigger.text')}
      leftElement={<Icon size="small" type="filter" aria-hidden />}
      data-testid="filter-trigger"
    />
  );

  const renderMenuItems = () => (
    <Container paddingX="s" paddingY="s">
      <Group variant="vertical" spacing="none">
        {filters.map((filter) => {
          const isSelected = visibleFilterKey === filter.key;
          return (
            <FloatingMenu.Item
              key={filter.key}
              data-testid={`filter-menu-item-${filter.key}`}
              onClick={() => setVisibleFilterKey(filter.key)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              paddingX="none"
              paddingY="none"
              minHeight={36}
            >
              <NavigationItem isSelected={isSelected} isFullWidth>
                {filter.label}
              </NavigationItem>
            </FloatingMenu.Item>
          );
        })}
      </Group>
    </Container>
  );

  const renderMenuItemOptions = () => {
    if (!visibleFilterKey) {
      return null;
    }
    const visibleFilter = getFilterByKey(filters, visibleFilterKey);
    if (!visibleFilter) {
      return null;
    }
    if (visibleFilter.type === 'multi') {
      return (
        <FilterMenuOptionsMulti
          options={visibleFilter.options}
          selected={activeFilters?.[visibleFilter.key] ?? []}
          onChange={(value: string) => handleMultiSelectionItemClick(visibleFilter.key, value)}
        />
      );
    }
    return (
      <FilterMenuOptionsSingle
        options={visibleFilter.options}
        selected={activeFilters?.[visibleFilter.key] ?? []}
        onChange={(value) => handleSingleItemClick(visibleFilter.key, value)}
      />
    );
  };

  return (
    <Menu
      isOpen={isMenuOpen}
      onOpenChange={handleOpenMenuChange}
      trigger={renderTrigger()}
      maxHeight={420}
      data-testid="nested-filter-menu"
    >
      <Container paddingX="none" paddingY="none">
        <Group variant="horizontal" spacing="none" height="full">
          <Box width={184}>{renderMenuItems()}</Box>
          <Divider variant="vertical" />
          <Box width={280}>{renderMenuItemOptions()}</Box>
        </Group>
      </Container>
    </Menu>
  );
};
