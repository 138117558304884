import {
  ARCustomerTypeEnum,
  ARErrorCode,
  FormDialog,
  FormDialogProps,
  FormDialogWidgetProps,
  useMelioIntl,
  useRiskMtlSchemaValidations,
} from '@melio/ar-domain';
import { Form, Icon, useMelioForm, VisuallyHidden } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useMemo } from 'react';
import { object, SchemaOf, string } from 'yup';
export type CustomerFormFields = {
  customerName: string;
  email: string;
  customerType: ARCustomerTypeEnum;
  customerFirstName: string;
  customerLastName: string;
  phone: string;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { customerName } = useRiskMtlSchemaValidations({ customerName: true });

  return object().shape({
    customerName: customerName(),
    email: string()
      .email(formatMessage('ar.modals.activities.customerManagement.form.email.validation.general.label'))
      .required(formatMessage('ar.modals.activities.customerManagement.form.email.required.label')),
    customerType: string()
      .oneOf(Object.values(ARCustomerTypeEnum))
      .required(formatMessage('ar.modals.activities.customerManagement.form.customerType.required.label')),
    phone: string()
      .required(formatMessage('ar.modals.activities.customerManagement.form.phone.validation.format'))
      .test(
        'valid-phone',
        formatMessage('ar.modals.activities.customerManagement.form.phone.validation.format'),
        (phone = '') => phone.length === 10
      ),
    customerFirstName: string()
      .required(formatMessage('ar.modals.activities.customerManagement.form.firstName.required.text'))
      .min(2, formatMessage('ar.modals.activities.customerManagement.form.firstName.minChars.text'))
      .matches(
        /^[a-zA-Z\s'-]*$/,
        formatMessage('ar.modals.activities.customerManagement.form.firstName.invalidChars.text')
      ),
    customerLastName: string()
      .required(formatMessage('ar.modals.activities.customerManagement.form.lastName.required.text'))
      .min(2, formatMessage('ar.modals.activities.customerManagement.form.lastName.minChars.text'))
      .matches(
        /^[a-zA-Z\s'-]*$/,
        formatMessage('ar.modals.activities.customerManagement.form.lastName.invalidChars.text')
      ),
  }) as SchemaOf<CustomerFormFields>;
};

export type CustomerFormDialogProps = FormDialogWidgetProps<
  CustomerFormFields,
  {
    header: string;
    primaryButtonLabel: string;
    secondaryButtonLabel: string;
    error?: ARPlatformError;
  }
>;

export const CustomerFormDialog = forwardRef<CustomerFormDialogProps>(
  (
    { onSubmit, defaultValues: _defaultValues, isSaving, primaryButtonLabel, secondaryButtonLabel, error, ...props },
    ref
  ) => {
    const defaultValues = useMemo<NonNullable<CustomerFormDialogProps['defaultValues']>>(
      () => ({
        customerType: 'business',
        customerName: '',
        email: '',
        phone: '',
        customerFirstName: '',
        customerLastName: '',
        ..._defaultValues,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify(_defaultValues)]
    );

    const { registerField, watch, setError, ...useFormResults } = useMelioForm({
      schema: useSchema(),
      onSubmit,
      defaultValues,
      isSaving,
      subscribeToDefaultValuesChanges: true,
    });
    const { formatMessage } = useMelioIntl();
    const customerType = watch('customerType');
    const isConsumerType = customerType === ARCustomerTypeEnum.Consumer;

    useEffect(() => {
      if (error?.errorCode === ARErrorCode.CustomerEmailNotUnique) {
        setError('email', {
          message: formatMessage('ar.modals.activities.customerManagement.form.email.validation.nonUniqueEmail.label'),
        });
      }
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    const bannerProps = useMemo<FormDialogProps['banner']>(
      () => ({
        leftElement: <Icon type="info-fill" aria-hidden />,
        variant: 'neutral',
        description: formatMessage('ar.modals.activities.customerManagement.form.required.email.banner.label', {
          customerName: defaultValues.customerName,
        }),
      }),
      [defaultValues.customerName, formatMessage]
    );

    return (
      <FormDialog
        columns={2}
        useFormResults={useFormResults}
        size="small"
        primaryButton={{ label: primaryButtonLabel }}
        secondaryButton={{ label: secondaryButtonLabel }}
        banner={defaultValues.customerName && !defaultValues.email ? bannerProps : undefined}
        closeButtonAriaLabel={formatMessage('ar.modals.activities.customerManagement.modal.closeButtonAriaLabel')}
        {...props}
        ref={ref}
      >
        <Form.RadioGroup
          colSpan={2}
          {...registerField('customerType')}
          labelProps={{
            label: formatMessage('ar.modals.activities.customerManagement.form.customerType.label'),
            tooltipProps: {
              content: (
                <>
                  <VisuallyHidden>
                    {formatMessage('ar.modals.activities.customerManagement.form.customerType.tooltip.label')} tooltip
                  </VisuallyHidden>
                  {formatMessage('ar.modals.activities.customerManagement.form.customerType.tooltip.label')}
                </>
              ),
            },
          }}
          options={[
            {
              value: ARCustomerTypeEnum.Business,
              mainLabelProps: {
                label: formatMessage('ar.modals.activities.customerManagement.form.customerType.option.business.label'),
              },
            },
            {
              value: ARCustomerTypeEnum.Consumer,
              mainLabelProps: {
                label: formatMessage('ar.modals.activities.customerManagement.form.customerType.option.consumer.label'),
              },
            },
          ]}
        />
        <Form.TextField
          colSpan={2}
          {...registerField('customerName')}
          labelProps={{ label: formatMessage('ar.modals.activities.customerManagement.form.customerName.label') }}
          helperTextProps={{
            label: formatMessage(
              isConsumerType
                ? 'ar.modals.activities.customerManagement.form.customerName.customerType.helperText'
                : 'ar.modals.activities.customerManagement.form.customerName.businessType.helperText'
            ),
          }}
        />
        <Form.TextField
          colSpan={1}
          labelProps={{
            label: formatMessage(
              isConsumerType
                ? 'ar.modals.activities.customerManagement.form.firstName.customerType.label'
                : 'ar.modals.activities.customerManagement.form.firstName.businessType.label'
            ),
          }}
          {...registerField('customerFirstName')}
        />
        <Form.TextField
          colSpan={1}
          labelProps={{
            label: formatMessage(
              isConsumerType
                ? 'ar.modals.activities.customerManagement.form.lastName.customerType.label'
                : 'ar.modals.activities.customerManagement.form.lastName.businessType.label'
            ),
          }}
          {...registerField('customerLastName')}
        />
        <Form.TextField
          colSpan={2}
          {...registerField('email')}
          labelProps={{ label: formatMessage('ar.modals.activities.customerManagement.form.email.label') }}
        />
        <Form.PhoneField
          colSpan={2}
          {...registerField('phone')}
          labelProps={{ label: formatMessage('ar.modals.activities.customerManagement.form.phone.label') }}
        />
      </FormDialog>
    );
  }
);
