import { Container, Group, SectionBanner } from '@melio/penny';
import { Loan, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { InstallmentsSection } from '../sections/InstallmentsSection';
import { LoanProviderSection } from '../sections/LoanProviderSection';
import { PayBySection } from '../sections/PayBySection';
import { RepaymentSection } from '../sections/RepaymentSection';
import { SectionWrapper } from '../sections/SectionWrapper';

export const LoanCompleted = ({ payment }: { payment: Override<Payment, { loan: Loan }> }) => {
  const { formatMessage } = useMelioIntl();
  const { loan } = payment;

  return (
    <>
      <Container paddingY="m">
        <Group variant="vertical" hasDivider spacing="m">
          <SectionWrapper>
            <Group variant="vertical" spacing="m">
              <SectionBanner
                variant="success"
                title={formatMessage('activities.payDashboard.drawer.body.loan.completed.title')}
              />
              <RepaymentSection payment={payment} />
            </Group>
          </SectionWrapper>
          <SectionWrapper>
            <InstallmentsSection loan={loan} />
          </SectionWrapper>
          <SectionWrapper>
            <PayBySection payment={payment} />
          </SectionWrapper>
          <SectionWrapper>
            <LoanProviderSection />
          </SectionWrapper>
        </Group>
      </Container>
    </>
  );
};
