import { useNavigate } from 'react-router-dom';
import {
  InternationalCurrencyCloudDeliveryMethodCard,
  InternationalDeliveryMethodCard,
  SupportedInternationalType,
} from '@melio/ap-widgets';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethod, DeliveryMethodTypeOption, OrganizationBusinessType, Vendor } from '@melio/platform-api';

import { useRouter } from '../../../../hooks/router.hooks';
import { DeliveryMethodNavigationStateParams } from '../../../../types/deliveryMethod.types';
import { useInternationalDeliveryMethodCardsToShow } from './useInternationalDeliveryMethodCardsToShow';

export interface InternationalDeliveryMethodCardsProps {
  vendorCurrency?: Vendor['currency'];
  businessType?: OrganizationBusinessType | null;
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[];
  deliveryMethods: DeliveryMethod[];
  countryDisplayName: string | undefined;
  navigationStateParams: DeliveryMethodNavigationStateParams;
}

export const InternationalDeliveryMethodCards = ({
  vendorCurrency,
  businessType,
  deliveryMethodTypeOptions,
  deliveryMethods,
  countryDisplayName,
  navigationStateParams,
}: InternationalDeliveryMethodCardsProps) => {
  const { track } = useAnalytics();
  const router = useRouter();
  const navigate = useNavigate();

  const { showCurrencyCloud, showWiseCard, deliveryMethod, wiseSupportedInternationalType } =
    useInternationalDeliveryMethodCardsToShow({
      vendorCurrency,
      businessType,
      deliveryMethodTypeOptions,
      deliveryMethods,
    });

  const trackOnClick = (supportedInternationalType: SupportedInternationalType) => {
    if (!deliveryMethod) {
      track('DeliveryMethod', 'Click', {
        DeliveryMethodType: supportedInternationalType,
        Cta: 'add-method',
      });
    }
  };

  return (
    <>
      {showCurrencyCloud ? (
        <InternationalCurrencyCloudDeliveryMethodCard
          deliveryMethod={deliveryMethod}
          onClick={() => {
            trackOnClick('international-usd');
            const { path, options } = router.generateVendorInternationalAccountLink(navigationStateParams);
            navigate(path, options);
          }}
        />
      ) : null}

      {showWiseCard ? (
        <InternationalDeliveryMethodCard
          deliveryMethod={deliveryMethod}
          supportedInternationalType={wiseSupportedInternationalType}
          countryDisplayName={countryDisplayName}
          onClick={() => {
            trackOnClick(wiseSupportedInternationalType);
            const { path, options } = router.generateVendorInternationalFXAccountLink(navigationStateParams);
            navigate(path, options);
          }}
        />
      ) : null}
    </>
  );
};
