import { MonitoringProvider } from '@melio/platform-monitoring';
import { SystemMessageProvider } from '@melio/platform-utils';

import { PartnerApp } from '@/cl/components/PartnerApp/PartnerApp.component';
import { usePartnerAppInit } from '@/hooks/usePartnerAppInit';
import { useNotifyOnMissingPartner } from '@/utils/onboarding.utils';
import version from '@/version';
import { UnderMaintenanceBoundary } from './hoc/underMaintenanceBoundary';
import { getViteEnvironment } from './utils/error-tracking';

import './App.css';
import './Fonts.css';

const App = () => {
  const partnerName = usePartnerAppInit();
  const environment = getViteEnvironment();
  useNotifyOnMissingPartner(partnerName);

  return partnerName ? (
    <MonitoringProvider
      config={{
        version,
        environment,
      }}
    >
      <UnderMaintenanceBoundary partnerName={partnerName}>
        <SystemMessageProvider>
          <PartnerApp partnerName={partnerName} />
        </SystemMessageProvider>
      </UnderMaintenanceBoundary>
    </MonitoringProvider>
  ) : (
    <></>
  );
};

export default App;
