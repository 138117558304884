import { forwardRef } from '@melio/platform-utils';

import { LandingPageScreen } from './screens';

export type LandingPageActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  onCreateInvoice: VoidFunction;
};

export const LandingPageActivity = forwardRef<LandingPageActivityProps>(({ onDone, onCreateInvoice }, ref) => (
  <LandingPageScreen ref={ref} onDone={onDone} onCreateInvoice={onCreateInvoice} />
));

LandingPageActivity.displayName = 'LandingPageActivity';
