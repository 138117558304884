import {
  InternationalIdentifierType,
  isIdentifierTypeInternational,
  useInternationalFxFeature,
} from '@melio/ap-domain';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { InternationalPaymentPurposeFormFields, PaymentPurposeForm } from './payment-purpose-form';

type InternationalPaymentPurposeScreenProps = {
  isSaving?: boolean;
  isLoading?: boolean;
  step?: number;
  totalSteps?: number;
  defaultValues?: Partial<InternationalPaymentPurposeFormFields>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (data: InternationalPaymentPurposeFormFields) => void;
  shouldShowInvoice: boolean;
  currency?: string;
  identifierType?: InternationalIdentifierType;
  shouldShowDescription?: boolean;
  shouldShowPaymentPurpose?: boolean;
};

export const InternationalPaymentPurposeScreen = ({
  shouldShowInvoice,
  shouldShowDescription = true,
  shouldShowPaymentPurpose = true,
  defaultValues,
  onBack,
  onClose,
  onDone,
  isLoading,
  isSaving,
  step,
  totalSteps,
  currency,
  identifierType,
  ...props
}: InternationalPaymentPurposeScreenProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const isInternationalFxEnabled = useInternationalFxFeature();
  const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
    useFormSubmissionController<InternationalPaymentPurposeFormFields>();

  const isFx = isInternationalFxEnabled && identifierType && isIdentifierTypeInternational(identifierType);
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
    endAction('goods_received_flow');
    endAction('vendor_missing_details_flow');
    endAction('mcc_flow');
    endAction('reconciliation_flow');
    endAction('delivery_method_selection');
  }, [endAction]);

  const trackClick = (cta: string) =>
    track(`Payment`, 'Click', {
      Cta: cta,
    });

  const getTitle = (): MessageKey => {
    if (!isFx) {
      return 'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.title';
    }

    if (shouldShowInvoice) {
      if (shouldShowPaymentPurpose) {
        return 'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.fx.title';
      }
      return 'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.fx.invoiceOnly.title';
    }

    return 'activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.title';
  };

  return (
    <NewSinglePaymentStepLayout
      data-component="InternationalPaymentPurposeActivity.InternationalPaymentPurposeScreen"
      data-testid="international-payment-purpose-activity-international-payment-purpose-screen"
      isLoading={isLoading}
      ref={endActionMonitoring}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton
            onClick={() => {
              trackClick('exit');
              onClose();
            }}
          />
          {step != undefined && totalSteps && (
            <NewSinglePaymentStepLayout.ProgressBar currentStep={step} totalSteps={totalSteps} />
          )}
          <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            {...submitButtonProps}
            onClick={() => {
              trackClick('continue');
              submitButtonProps?.onClick();
            }}
            label={formatMessage('activities.internationalPaymentPurpose.screens.internationalPaymentPurpose.continue')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <NewSinglePaymentStepLayout.Title>{formatMessage(getTitle())}</NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Content>
        <PaymentPurposeForm
          defaultValues={defaultValues}
          onSubmissionStateChange={onSubmissionStateChange}
          onSubmit={(data) => {
            startAction('payment_purpose');
            onDone(data);
          }}
          shouldShowInvoice={shouldShowInvoice}
          shouldShowDescription={shouldShowDescription}
          shouldShowPaymentPurpose={shouldShowPaymentPurpose}
          isSaving={isSaving}
          identifierType={identifierType}
          currency={currency}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};
InternationalPaymentPurposeScreen.displayName = 'InternationalPaymentPurposeActivity.InternationalPaymentPurposeScreen';
