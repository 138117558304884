import { usePaymentProcessingInfo } from '@melio/ap-domain';
import { isDeliveryPreferenceTypeFast, ScheduleDateWidget } from '@melio/ap-widgets';
import { Group } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  ApprovalRequirementStatus,
  DeliveryPreference,
  DeliveryPreferenceType,
  FundingSource,
} from '@melio/platform-api';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { isBefore, isSameDay } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { EndOfYearCheckBanner } from '../EndOfYearCheckBanner';

export type ScheduleDateScreenProps = {
  dueDate: Date;
  selectedDate: Date;
  onSelectDate: (date: Date) => void;
  minDate: Date;
  isLoading?: boolean;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onError?: ErrorFunction;
  fundingSource: FundingSource;
  deliveryPreferences: DeliveryPreference[];
  selectedDeliveryPreferenceType: DeliveryPreference['type'];
  step: number;
  totalSteps: number;
  onDone: (type: DeliveryPreference['type'], date: Date) => unknown;
  vendorEmail: string;
  approvalRequirementStatus?: ApprovalRequirementStatus;
};

export const ScheduleDateScreen = ({
  dueDate,
  onBack,
  onClose,
  onSelectDate,
  selectedDate,
  isLoading,
  minDate,
  fundingSource,
  deliveryPreferences,
  selectedDeliveryPreferenceType,
  step,
  totalSteps,
  onDone,
  vendorEmail,
  approvalRequirementStatus,
  ...props
}: ScheduleDateScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const [deliveryPreferenceType, setDeliveryPreferenceType] = useState(selectedDeliveryPreferenceType);
  const { track } = useAnalytics();
  const { settings } = useConfig();
  const { virtualCardExpiryPeriodInDays } = usePaymentProcessingInfo();
  const [eoyCheckEnabled] = usePartnerFeature('eoyCheck', false);

  const isDeliveryPreferencesCheck = deliveryPreferences.some(
    (preference) => preference.type === 'check' || preference.type === 'express-check'
  );

  const fastDeliveryPreferencesTypes = deliveryPreferences
    .filter(({ type, supported }) => isDeliveryPreferenceTypeFast(type) && supported)
    .map(({ type }) => type);

  const isFastCheck = deliveryPreferences.find((preference) => preference.type === 'express-check') !== undefined;
  const isVirtualCard = selectedDeliveryPreferenceType === DeliveryPreferenceType.VirtualCard;
  const messageKey = isVirtualCard ? 'VirtualCard' : '';

  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
    endAction('goods_received_flow');
    endAction('vendor_missing_details_flow');
    endAction('mcc_flow');
    endAction('reconciliation_flow');
    endAction('delivery_method_selection');
    endAction('payment_purpose');
  }, [endAction]);

  useEffect(() => {
    track('PaymentDeductionDate', 'Viewed', { FastDeliveryPreference: fastDeliveryPreferencesTypes });
  }, [fastDeliveryPreferencesTypes, track]);

  const handleDone: typeof onDone = (type, date) => {
    startAction('delivery_date_selection');

    track('PaymentDeductionDate', 'Submitted', {
      Date: date,
      DeliveryPreference: type,
      SpecialDeliveryType: isDeliveryPreferenceTypeFast(type) ? type : null,
      IsSpecialDelivery: isDeliveryPreferenceTypeFast(type),
    });
    return onDone(type, date);
  };

  const handleSetDeliveryPreferenceType = (selectedType: DeliveryPreference['type']) => {
    const selectedPreference = deliveryPreferences.find(({ type }) => type === selectedType);
    const minScheduleDate = selectedPreference?.minScheduleDate;
    const isRtpWithDifferentDate =
      minScheduleDate && selectedType === DeliveryPreferenceType.Rtp && !isSameDay(selectedDate, minScheduleDate);
    const isDateBeforeMinDate = minScheduleDate && isBefore(selectedDate, minScheduleDate);

    if (isRtpWithDifferentDate || isDateBeforeMinDate) {
      onSelectDate(minScheduleDate);
    }
    setDeliveryPreferenceType(selectedType);
  };

  return (
    <NewSinglePaymentStepLayout
      data-component="DeliveryDateActivity.ScheduleDateScreen"
      data-testid="delivery-date-activity-schedule-date-screen"
      ref={endActionMonitoring}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          <NewSinglePaymentStepLayout.ProgressBar currentStep={step} totalSteps={totalSteps} />
          <NewSinglePaymentStepLayout.BackButton onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            onClick={() => handleDone(deliveryPreferenceType, selectedDate)}
            isLoading={isLoading}
            label={formatMessage('activities.deliveryDate.screens.scheduleDate.continue')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        <FormattedMessage id={`activities.deliveryDate.screens.scheduleDate.title${messageKey}`} />
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        <FormattedMessage
          id={`activities.deliveryDate.screens.scheduleDate.description${messageKey}`}
          values={{
            vendorEmail,
            daysToExpire: virtualCardExpiryPeriodInDays,
          }}
        />
      </NewSinglePaymentStepLayout.Description>

      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" alignItems="center">
          {eoyCheckEnabled && settings.eoyCheck.enabled && isDeliveryPreferencesCheck ? (
            <EndOfYearCheckBanner
              link={settings.eoyCheck.link}
              isFastCheck={isFastCheck}
              promoteFastCheck={settings.eoyCheck.promoteFastCheck}
            />
          ) : null}
          <ScheduleDateWidget
            dueDate={dueDate}
            onSelectDate={onSelectDate}
            selectedDate={selectedDate}
            minDate={minDate}
            isDisabled={isLoading}
            fundingSource={fundingSource}
            deliveryPreferences={deliveryPreferences}
            selectedDeliveryPreferenceType={deliveryPreferenceType}
            onSelectDeliveryPreference={handleSetDeliveryPreferenceType}
            approvalRequirementStatus={approvalRequirementStatus}
          />
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};
ScheduleDateScreen.displayName = 'DeliveryDateActivity.ScheduleDateScreen';
