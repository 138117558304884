import { ActionsDropdownMenu, NakedButton } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { ComponentProps, useState } from 'react';

export function DeliveryMethodActionsMenu({
  actions,
}: {
  actions: ComponentProps<typeof ActionsDropdownMenu>['items'];
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { formatMessage } = useMelioIntl();

  return (
    <ActionsDropdownMenu
      size="small"
      label={formatMessage('widgets.addOrUpdateDeliveryMethod.actionsMenu.label')}
      items={actions}
      data-testid="delivery-method-menu"
      isOpen={isMenuOpen}
      onOpenChange={setIsMenuOpen}
    />
  );
}

export function AddDeliveryMethodActionButton(
  props: Pick<ComponentProps<typeof NakedButton>, 'onClick' | 'aria-label' | 'data-testid'>
) {
  const { formatMessage } = useMelioIntl();
  return (
    <NakedButton
      variant="secondary"
      onClick={props.onClick}
      label={formatMessage('widgets.addOrUpdateDeliveryMethod.link')}
      aria-label={props['aria-label']}
      data-testid={props['data-testid']}
    />
  );
}
