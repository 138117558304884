import { useVendorNameConstraints } from '@melio/ap-domain';
import { Form, Group, SectionBanner, VisuallyHidden } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useEffect, useState } from 'react';

import { CountrySelect } from '../../../../../../fields';
import { useVendorForm } from '../../../../../../useVendorForm';
import {
  UnmanagedVendorFormMinimalFormFields,
  useUnmanagedVendorFormMinimalSchema,
} from '../../../../../../VendorDetailsBaseSchema';
import { AddVendorFormWidgetProps } from '../../../../types';
import { CompanyField } from '../../../CompanyField';
import { getCreateVendorPayload } from './utils';

export const AddUnmanagedVendorFormMinimal = ({
  onClearCompanyNameSearchField,
  inlineApiErrorCodes,
  bannerApiErrorCodes,
  onSelectCompanyName,
  onSubmit,
  onSubmissionStateChange,
  shouldHideCompanyNameField,
  defaultValues,
  isSaving,
  titlesAs,
  isEmailRequired,
  emailRequiredMessage,
  ...props
}: AddVendorFormWidgetProps) => {
  const { formatMessage } = useMelioIntl();
  const isManaged = !!props.managed;
  const [isCompanyNameBlurred, setIsCompanyNameBlurred] = useState(false);
  const [isSearchBusinessesInDirectoriesSupported] = useDevFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );

  const {
    settings: {
      vendor: {
        createVendor: { shouldCollectAccountNumberForUnmanagedVendor },
        forms: { shouldRequireEmail, shouldCollectCountry },
      },
    },
  } = useConfig();

  const { formProps, registerField, watch, setValue, reset, formState } =
    useVendorForm<UnmanagedVendorFormMinimalFormFields>({
      inlineApiErrorCodes,
      bannerApiErrorCodes,
      onSubmit: (data, _e, target) => onSubmit(getCreateVendorPayload(data), target),
      schema: useUnmanagedVendorFormMinimalSchema({
        showCompanyField: !shouldHideCompanyNameField,
        isManaged,
        isEmailRequired: shouldRequireEmail || isEmailRequired,
        emailRequiredMessage,
      }),
      isSaving,
      defaultValues: {
        ...defaultValues,
        ...(shouldCollectCountry ? { countryCode: 'US' } : {}),
      },
      onSubmissionStateChange,
    });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const companyNameInputProps = registerField('companyName');

  const { vendorNameBannerDescription, vendorNameConstraintType } = useVendorNameConstraints({
    vendorName: watch('companyName') ?? undefined,
    vendorNameFieldErrorType: companyNameInputProps?.error?.type,
    vendorNameFreeTextInputProps: isSearchBusinessesInDirectoriesSupported
      ? undefined
      : { isBlurred: isCompanyNameBlurred },
    isManaged,
    isFormSubmitted: formState.isSubmitted,
  });

  return (
    <Form rowGap="m" data-component="AddVendorFormWidget" {...props} {...formProps}>
      {!shouldHideCompanyNameField && (
        <Form.ContentBox>
          <Group spacing="xs" variant="vertical">
            <CompanyField
              onSelectCompanyName={onSelectCompanyName}
              onClearSearchField={() => {
                setValue('companyName', null);
                onClearCompanyNameSearchField?.();
              }}
              onBlur={() => setIsCompanyNameBlurred(true)}
              {...companyNameInputProps}
              autoFocus
            />
            {vendorNameBannerDescription && (
              <SectionBanner
                data-testid={`company-name-suggestion-${vendorNameConstraintType}`}
                description={vendorNameBannerDescription}
              />
            )}
          </Group>
        </Form.ContentBox>
      )}
      <Form.TextField
        {...registerField('fullName')}
        labelProps={{ label: formatMessage('widgets.vendors.fullName.label') }}
        placeholder={formatMessage('widgets.vendors.fullName.placeholder')}
        autoFocus={shouldHideCompanyNameField}
      />
      {shouldCollectAccountNumberForUnmanagedVendor && (
        <Form.TextField
          data-private
          {...registerField('accountNumber')}
          labelProps={{
            label: formatMessage('widgets.vendors.accountNumber.label'),
            tooltipProps: {
              content: (
                <>
                  <VisuallyHidden>
                    {formatMessage('widgets.vendors.accountNumber.optional.labelTooltip')} tooltip
                  </VisuallyHidden>
                  {formatMessage('widgets.vendors.accountNumber.optional.labelTooltip')}
                </>
              ),
            },
          }}
          autoFocus={shouldHideCompanyNameField}
        />
      )}
      <Form.TextField
        {...registerField('email')}
        labelProps={{ label: formatMessage('widgets.vendors.email.label') }}
        placeholder={formatMessage('widgets.vendors.email.placeholder')}
      />
      <Form.PhoneField
        {...registerField('phone')}
        labelProps={{ label: formatMessage('widgets.vendors.phone.label') }}
        placeholder={formatMessage('widgets.vendors.phone.placeholder')}
      />
      {shouldCollectCountry ? <CountrySelect {...registerField('countryCode')} /> : null}
    </Form>
  );
};

AddUnmanagedVendorFormMinimal.displayName = 'AddVendorFormWidget';
