import { _createFormFieldInput, FileUpload, FileUploadProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useState } from 'react';

import { useFiles } from '../api-hooks';
import { InvoiceFormValuesAttachments } from '../types';

export type FileAttachmentSelectInputProps = Override<
  FileUploadProps,
  {
    onChangeState: (file: InvoiceFormValuesAttachments | null, isDeletedByUser?: boolean) => void;
    value?: InvoiceFormValuesAttachments | null;
    onError?: (type: 'general' | 'sizeExceeded') => void;
    maxFilesSize?: number;
    currentFilesSize?: number;
  }
>;

export const MultipleAttachmentsSelectInput = _createFormFieldInput(
  forwardRef<FileAttachmentSelectInputProps, 'input'>(
    ({ maxFilesSize, currentFilesSize, onError, isReadOnly, onChangeState, value, onChange, ...props }, ref) => {
      const { create: createFile, isMutating } = useFiles({ enabled: false });
      const [file, setFile] = useState<InvoiceFormValuesAttachments | null>(value || null);
      const [isUploadingFile, setIsUploadingFile] = useState(false);

      const handleFileChange = useCallback(
        async (file: File | null) => {
          if (file) {
            const isFilesSizeExceeded = maxFilesSize && (currentFilesSize ?? 0) + file.size > maxFilesSize;

            if (isFilesSizeExceeded) {
              onChangeState(null);
              onError?.('sizeExceeded');

              return;
            }

            setIsUploadingFile(true);
            try {
              const uploadedFile = (await createFile(file)) as InvoiceFormValuesAttachments;
              uploadedFile.size = file.size;

              setFile(uploadedFile);
              onChange?.(uploadedFile as never);
              onChangeState(uploadedFile);
            } catch (e) {
              setFile(null);
              onChangeState(null);
              onError?.('general');
            } finally {
              setIsUploadingFile(false);
            }
          } else {
            onChangeState(null, true);
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [file, onChangeState, onChange]
      );

      return (
        <FileUpload
          {...props}
          ref={ref}
          onPreview={() => {
            if (file?.url) {
              const anchor = document.createElement('a');
              anchor.href = file.url;
              anchor.download = file.fileName || 'attachment.pdf';
              anchor.target = '_blank';
              anchor.click();
            }
          }}
          value={file?.fileName ? { name: file.fileName || '', type: '', url: '' } : null}
          isLoading={isUploadingFile}
          isReadOnly={isMutating || isReadOnly}
          replaceFileLabel=""
          onChange={handleFileChange}
        />
      );
    }
  )
);

MultipleAttachmentsSelectInput.displayName = 'FileAttachmentSelectInput';
