import { useSendMessage } from '@melio/partner-bridge';
import { FundingSource } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { noop } from 'lodash';

import { FundingSourceSectionProps } from '../FundingSourceSection';

export function useAddFundingSourceButtonProps(
  selectedType: FundingSource['type']
): FundingSourceSectionProps['selector']['addButton'] {
  const {
    settings: { fundingSourcePolicy },
  } = useConfig();
  const { formatMessage } = useMelioIntl();
  const sendMessage = useSendMessage();
  const fsTypePolicy = fundingSourcePolicy?.[selectedType];

  const defaults: FundingSourceSectionProps['selector']['addButton'] = {
    variant: 'secondary',
    label: formatMessage(`activities.paymentFlow.form.content.fundingSourceCard.${selectedType}.add.label`),
    onClick: noop,
  };

  if (!fsTypePolicy?.write) {
    return;
  }

  if (fsTypePolicy?.creationHosting === 'app') {
    return defaults;
  }

  if (fsTypePolicy?.creationHosting === 'iframe') {
    return {
      ...defaults,
      onClick: () =>
        sendMessage('ADD_FUNDING_SOURCE_CLICK', {
          fundingSourceType: selectedType,
          returnUrl: window.location.href,
        }),
    };
  }

  return defaults;
}
