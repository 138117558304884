import { useAccountingPlatform } from '@melio/platform-api';
import { useOneTimeEffect } from '@melio/platform-utils';

import { AccountingPlatformSyncConnectionErrorEnum } from '../types';

type Params = {
  authError?: AccountingPlatformSyncConnectionErrorEnum;
  authParams?: Record<string, unknown>;
  isQuickBooksOnline?: boolean;
  isQuickBooksDesktop?: boolean;
  accountingPlatformId?: string;
  onError: (error: AccountingPlatformSyncConnectionErrorEnum) => void;
  onQuickBooksOnlineDone: () => void;
  onOrganizationsToConnectFound: (isQuickBooksDesktop: boolean, organizationsCount: number) => void;
};

export const useInitialStep = ({
  authError,
  authParams,
  isQuickBooksOnline,
  isQuickBooksDesktop,
  accountingPlatformId,
  onError,
  onQuickBooksOnlineDone,
  onOrganizationsToConnectFound,
}: Params) => {
  const {
    registerOrganization,
    parseAuthParams,
    availableExternalOrganizations = [],
  } = useAccountingPlatform({
    id: accountingPlatformId,
    enabled: false,
  });
  const registrableExternalOrganizations = availableExternalOrganizations.filter((company) => company.isRegistrable);

  useOneTimeEffect(
    () => {
      const handleInitialData = async () => {
        if (authError) {
          onError(authError);
          return;
        }

        if (isQuickBooksOnline) {
          onQuickBooksOnlineDone();
          return;
        }

        if (isQuickBooksDesktop) {
          onOrganizationsToConnectFound(true, 1);
          return;
        }

        try {
          const { companies = [] } = await parseAuthParams({ authParams });
          const registrableOrganizations = companies.filter((company) => company.isRegistrable);

          if (registrableOrganizations.length === 0) {
            onError(AccountingPlatformSyncConnectionErrorEnum.GeneralError);
            return;
          }

          onOrganizationsToConnectFound(false, registrableOrganizations.length);
        } catch (error) {
          onError(AccountingPlatformSyncConnectionErrorEnum.GeneralError);
        }
      };

      handleInitialData();
    },
    () => authError !== undefined || !!accountingPlatformId || !!isQuickBooksOnline,
    [authError, parseAuthParams, registerOrganization, authParams, isQuickBooksOnline, isQuickBooksDesktop]
  );

  return { registrableExternalOrganizations };
};
