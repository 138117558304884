import { Button, Container, Group } from '@melio/penny';
import { SubscriptionBillingCycleEnum } from '@melio/subscriptions/src/api';
import React from 'react';

import { SubscriptionBillingPayor } from '../../../../types';
import {
  FirmClientSubscriptionReviewAndConfirmActivity,
  PreviewState,
} from '../../../FirmClientSubscriptionReviewAndConfirm';
import { StepFormProps } from '../../types';
import { useNewFirmClientFormFieldsFormContext } from '../NewClientForm/NewFirmClientFormFieldsFormContext';

export const NewClientPlanReviewAndConfirmStep: React.FC<StepFormProps> = ({ submitLabel, onContinue, isSaving }) => {
  const { watch, submitButtonProps, setValue, getFieldState } = useNewFirmClientFormFieldsFormContext();
  const [previewState, setPreviewState] = React.useState<PreviewState | null>(null);

  const planId = watch('subscriptionPlanId');
  const promoCode = watch('subscriptionPromoCode');
  const isFirmBilled = watch('whoPays') === SubscriptionBillingPayor.AccountingFirm;

  if (!planId) {
    return <></>;
  }

  const handlePromoCodeChange = (value?: string) => {
    setValue('subscriptionPromoCode', value);
  };

  const shouldPrefetchPromoCode = !!promoCode && !getFieldState('subscriptionPromoCode').isDirty;

  return (
    <Group spacing="m" variant="vertical" width="full">
      <FirmClientSubscriptionReviewAndConfirmActivity
        planId={planId}
        seatsQuantity={0}
        fundingSourceId={isFirmBilled ? watch('fundingSourceId') : null}
        planCycle={SubscriptionBillingCycleEnum.Monthly}
        onPreviewStateChange={setPreviewState}
        promoCode={promoCode}
        onPromoCodeChange={handlePromoCodeChange}
        shouldPrefetchPromoCode={shouldPrefetchPromoCode}
      />
      <Container justifyContent="flex-end">
        <Button
          variant="primary"
          size="medium"
          label={submitLabel}
          {...submitButtonProps}
          onClick={onContinue}
          isLoading={previewState === 'loading' || isSaving}
          isDisabled={previewState === 'error'}
        />
      </Container>
    </Group>
  );
};
