import { UseMelioFormResults } from '@melio/penny';
import { FundingSource, PaymentCalendarCalculation } from '@melio/platform-api';
import { isSameDay } from 'date-fns';
import { useEffect } from 'react';

import { PaymentFlowFormFields } from '../types';

export function usePaymentCalendarChangeEffect(props: {
  paymentCalendar: PaymentCalendarCalculation | undefined;
  selectedFundingSource: FundingSource | undefined;
  form: UseMelioFormResults<PaymentFlowFormFields>;
}) {
  const { paymentCalendar, form, selectedFundingSource } = props;
  const { getFieldState, getValues, setValue } = form;
  const isRecurring = getValues('recurrenceType') === 'recurring';

  useEffect(() => {
    if (!paymentCalendar) {
      return;
    }

    const dateKey = isRecurring ? 'startDate' : 'deliveryDate';
    const selectedDate = getValues(dateKey);
    const isDateTouched = getFieldState(dateKey).isTouched;

    const matchingDates = selectedDate
      ? paymentCalendar.dates.find((dates) => isSameDay(dates.minDeliveryDate, selectedDate))
      : undefined;
    const eap = paymentCalendar.dates[0];

    const isCardPayment = selectedFundingSource?.type === 'card';

    if (isDateTouched && !isCardPayment) {
      setValue('scheduleDate', matchingDates ? matchingDates.scheduleDate : undefined);
      if (matchingDates) {
        setValue(dateKey, matchingDates.minDeliveryDate);
      }
      return;
    }

    const datesOrEap = matchingDates || eap;

    if (datesOrEap) {
      setValue(dateKey, datesOrEap.minDeliveryDate);
      setValue('scheduleDate', datesOrEap.scheduleDate);
    }
  }, [paymentCalendar, getFieldState, getValues, isRecurring, setValue, selectedFundingSource]);
}
