import { Icon, Table, Tooltip } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const PaymentsTabInvoiceCell = ({ payment }: { payment: Payment }) => {
  const { formatMessage } = useMelioIntl();
  if (!payment.bills || payment.bills.length === 0) {
    return null;
  }
  const fileIds = payment.bills.map((bill) => bill.invoice.filesIds).flat();

  return (
    <Table.Cell data-testid={`table-row-${payment.id}-invoice-cell`}>
      {fileIds.length ? (
        <Tooltip
          data-testid={`table-row-${payment.id}-invoice-cell-tooltip`}
          content={formatMessage('activities.payDashboard.invoice.tooltip', {
            fileCount: fileIds.length,
          })}
          shouldAddTriggerFocus
        >
          <Icon type="invoices" size="small" color="inherit" aria-hidden="true" isReadOnly />
        </Tooltip>
      ) : null}
    </Table.Cell>
  );
};
