import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AddVendorActivity, useActivitiesNavigate } from '@melio/ap-activities';
import { OriginFlow } from '@melio/platform-analytics';
import { DeliveryMethod, useVendorDeliveryMethodTypeOptions, Vendor } from '@melio/platform-api';
import { useNavigate } from '@melio/platform-utils';

import { useRouter } from '@/hooks/router.hooks';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';

export const AddVendor = () => {
  const [_vendorId, setVendorId] = useState<string>('');
  const { goToVendor, goToVendors } = useRouter();
  const { navigateToScheduleNewPayment } = useActivitiesNavigate();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { returnUrl } = (state as { returnUrl?: string }) || {};
  const { refetch } = useVendorDeliveryMethodTypeOptions({ vendorId: _vendorId, enabled: false });

  const onDone = (vendorId: Vendor['id'], deliveryMethodId?: DeliveryMethod['id'], target?: 'continue' | 'close') => {
    setVendorId(vendorId);

    if (deliveryMethodId) {
      emitAppEvent(APP_EVENTS.VENDOR_DELIVERY_METHODS_UPDATED, { vendorId });
    }
    if (target === 'continue') {
      return navigateToScheduleNewPayment({
        vendorId,
        deliveryMethodId,
        origin: OriginFlow.VendorsFlow,
        returnUrl,
        keepToast: true,
      });
    }

    if (returnUrl) {
      return navigate(returnUrl, { state: { keepToast: true } });
    }
    goToVendor(vendorId);
  };

  const handleClose = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }
    goToVendors();
  };

  // This is for refetching the delivery method type options when returning from new vendor creation
  useEffect(() => {
    if (_vendorId) {
      refetch();
    }
  }, [refetch, _vendorId]);

  return <AddVendorActivity onClose={handleClose} onDone={onDone} />;
};
