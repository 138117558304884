import { useOrganizationPreferences } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useMemo } from 'react';

import { useIsSubscriptionsEnabled } from './useIsSubscriptionsEnabled';

type FirmClientSubscriptionPlanSelectedType = {
  planId: string;
  firmName: string;
};

const OrganizationPreferenceFirmClientSubscriptionPlanSelectedKey = 'firmClientSubscriptionPlanSelected';

export const useAccountingClientSubscriptionSelectedPlan = () => {
  const {
    settings: { subscriptions },
  } = useConfig();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const isEnabled = isSubscriptionsEnabled && subscriptions?.claimSuggestedPlan;
  const { data: organizationPreferences } = useOrganizationPreferences({
    enabled: isEnabled,
  });

  const firmClientSubscriptionPlanSelected = organizationPreferences?.find(
    (preference) => preference.key === OrganizationPreferenceFirmClientSubscriptionPlanSelectedKey
  );

  return useMemo(() => {
    if (isEnabled && firmClientSubscriptionPlanSelected) {
      try {
        return JSON.parse(firmClientSubscriptionPlanSelected?.value) as FirmClientSubscriptionPlanSelectedType;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error parsing firmClientSubscriptionPlanSelected');
      }
    }
    return;
  }, [firmClientSubscriptionPlanSelected, isEnabled]);
};
