import { InvoiceLineItem } from '@melio/ar-domain';

type LineItem = Pick<InvoiceLineItem, 'catalogItemId' | 'quantity' | 'price' | 'taxable'>;

export type InvoiceFormValuesLineItem = LineItem & {
  _current?: InvoiceLineItem;
};

type EditableEntity = {
  id?: string;
  onEdit?: VoidFunction;
  onRemove?: VoidFunction;
};

type UneditableEntities = {
  onAdd?: VoidFunction;
  onRemove?: (index: number) => void;
};

export type TaxRateProps = EditableEntity;
export type DiscountProps = EditableEntity;
export type ServiceChargesProps = UneditableEntities;

export const SendEmailOptions = { Yes: 'yes', No: 'no' } as const;
export type SendEmailOptions = (typeof SendEmailOptions)[keyof typeof SendEmailOptions];

export const DueDatePreset = {
  Today: 'today',
  SevenDays: '7-days',
  FourteenDays: '14-days',
  ThirtyDays: '30-days',
  Custom: 'custom',
} as const;
export type DueDatePreset = (typeof DueDatePreset)[keyof typeof DueDatePreset];
export type CreateInvoiceSubmitTarget = 'saveAsDraft' | 'saveAndContinue' | 'exitSaveAsDraft';
export type EditInvoiceSubmitTarget = 'save-and-close' | 'save-and-continue';

export const InvoiceFormMode = {
  Create: 'create',
  Edit: 'edit',
} as const;
export type InvoiceFormMode = (typeof InvoiceFormMode)[keyof typeof InvoiceFormMode];

export const trackInvoiceCreationProperties = {
  saveAndContinue: {
    Cta: 'preview-and-send',
  },
  exitSaveAsDraft: {
    Cta: 'save-as-draft',
    Intent: 'exit-flow',
  },
  saveAsDraft: {
    Cta: 'save-as-draft',
  },
};

export type ErrorDisplayDetails = {
  title: string;
  description: string;
};

export type InvoiceTotalAmountLimits = {
  min: number;
  max: number;
};

export const MAX_FILE_ATTACHMENTS = 10;

export type FeeAbsorptionSettingsFormFields = { feesPaidBy: 'business' | 'customers' };
