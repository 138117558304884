import { Link, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const UsHolidayChecksBanner = (props: { eoyCheckLink: string }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <SectionBanner
      data-testid="batch-payments-holiday-checks-banner"
      title={formatMessage('activities.batchPayments.components.banners.endOfYearCheck.title')}
      description={formatMessage('activities.batchPayments.components.banners.endOfYearCheck.description', {
        learnMoreLink: (
          <Link
            href={props.eoyCheckLink}
            label={formatMessage('activities.batchPayments.components.banners.endOfYearCheck.learnMoreLink')}
            newTab
          />
        ),
      })}
    />
  );
};
