import { useState } from 'react';
import { LocalStorageKeys } from '@melio/local-storage';
import { useConfig } from '@melio/platform-provider';

import { generateCodeVerifierAndChallenge } from '@/widgets/settings-page/TaxAndReportsPage/hooks/hooks.utils';
import { usePartnerLocalStorage } from './partners/usePartnerLocalStorage';

export const useContractors1099Sync = () => {
  const [isLoading, setIsLoading] = useState(false);
  const localStorage = usePartnerLocalStorage();
  const {
    services: { zenwork },
  } = useConfig();

  const createURI = (codeChallenge: string) => {
    return `${zenwork?.identityServiceUrl}?response_type=code&client_id=${zenwork?.clientId}&scope=openid&redirect_uri=${zenwork?.melioRedirectUrl}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
  };

  const redirectToZenworkLoginPage = async () => {
    setIsLoading(true);
    const { codeChallenge, codeVerifier } = await generateCodeVerifierAndChallenge();
    localStorage.setItem(LocalStorageKeys.zenworkCodeVerifier, codeVerifier);
    const redirectURL = createURI(codeChallenge);
    window.location.href = redirectURL;
  };

  return { redirectToZenworkLoginPage, isRedirecting: isLoading };
};
