import { ApiError, useModelApi, UseModelApiProps, useUpdateMutation } from '@melio/api-client';
import {
  CreatePaymentParameters,
  PaymentsApiClient,
  PostPaymentsPaymentIdCancelRequest,
  UpdatePaymentPayload,
} from '@melio/platform-api-axios-client';
import { invokeFunction } from '@melio/platform-utils';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import { useMelioQueryClient } from '../../core';
import { CreateFn, DeleteFn, Expand, GetFn, Payment, UpdateFn } from './types';
import { convertPaymentCentsToDollars, prepareCreateParams, prepareUpdateParams } from './utils';

export type UsePaymentProps = UseModelApiProps<typeof PaymentsApiClient.getPaymentsPaymentId, Payment> & {
  params?: {
    expand?: Expand;
  };
  convertCurrency?: boolean;
  onUpdate?: (data: Payment) => void;
  onCreateError?: (error: ApiError<string>, payload: CreatePaymentParameters) => void;
  onUpdateError?: (error: ApiError<string>, payload: UpdatePaymentPayload) => void;
};

export const usePayment = ({
  params,
  convertCurrency = true,
  onCreateError,
  onUpdateError,
  ...props
}: UsePaymentProps = {}) => {
  const queryClient = useMelioQueryClient();

  const query = useModelApi<GetFn, UpdateFn, DeleteFn, CreateFn, Payment>({
    ...props,
    queryKey: ['PaymentsApi', params],

    // data handling
    queryFn: (id: string) => invokeFunction(PaymentsApiClient.getPaymentsPaymentId, [id, params?.expand]),
    select: convertCurrency ? convertPaymentCentsToDollars : undefined,

    // create handling
    createFn: (data) => PaymentsApiClient.postPayment(data, undefined, undefined, params?.expand),
    prepareCreateParams: (data) => prepareCreateParams(data, convertCurrency),
    onCreateError,
    onCreate: () => queryClient.invalidateQueries('BillsApi'),

    // update handling
    updateFn: PaymentsApiClient.updatePaymentsPaymentId,
    prepareUpdateParams,
    onUpdateError,

    // delete handling
    deleteFn: PaymentsApiClient.deletePaymentsPaymentId,
    onDelete: () => queryClient.invalidateQueries('InboxItemsApi'),
  });

  const retryMutation = useUpdateMutation(PaymentsApiClient.retryPaymentWithDetails, query.queryKey);
  const retryCollectMutation = useUpdateMutation(PaymentsApiClient.retryCollectPaymentWithDetails, query.queryKey);
  const retryDeliverMutation = useUpdateMutation(PaymentsApiClient.retryDeliverPaymentWithDetails, query.queryKey);
  const markAsPaidMutation = useUpdateMutation(PaymentsApiClient.postPaymentsPaymentIdMarkAsPaid, query.queryKey);
  const refundMutation = useUpdateMutation(PaymentsApiClient.refundPayment, query.queryKey);
  const voidAndRefundMutation = useUpdateMutation(PaymentsApiClient.voidAndRefundPayment, query.queryKey);
  const voidAndResendMutation = useUpdateMutation(PaymentsApiClient.voidAndResendPayment, query.queryKey);
  const downloadPaymentConfirmation = PaymentsApiClient.getPaymentConfirmationDocument;
  const cancelMutation = useMutation(
    query.queryKey,
    (data?: PostPaymentsPaymentIdCancelRequest) =>
      PaymentsApiClient.postPaymentsPaymentIdCancel(props.id as string, data),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('InboxItemsApi');
        void queryClient.invalidateQueries('PaymentsApi');
        void queryClient.invalidateQueries('BillsApi');
      },
    }
  );

  return {
    ...query,
    retry: retryMutation.createCallback(props.id as string),
    retryCollect: retryCollectMutation.createCallback(props.id as string),
    retryDeliver: retryDeliverMutation.createCallback(props.id as string),
    markAsPaid: markAsPaidMutation.createCallback(props.id as string),
    refund: refundMutation.createCallback(props.id as string),
    voidAndRefund: voidAndRefundMutation.createCallback(props.id as string),
    voidAndResend: voidAndResendMutation.createCallback(props.id as string),
    downloadPaymentConfirmation,
    cancel: (data?: PostPaymentsPaymentIdCancelRequest) => cancelMutation.mutateAsync(data),
    _mutations: {
      ...query._mutations,
      retry: retryMutation,
      retryCollect: retryCollectMutation,
      retryDeliver: retryDeliverMutation,
      markAsPaid: markAsPaidMutation,
      refund: refundMutation,
      voidAndRefund: voidAndRefundMutation,
      cancel: cancelMutation,
    },
  };
};

export type PaymentModel = ReturnType<typeof usePayment>;
