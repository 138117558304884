import { GoogleAddressInput, InternationalAddress } from '@melio/form-controls';
import {
  CountryCode,
  getStateOrProvinceInfo,
  StateOrProvinceInfo,
  StateValue,
} from '@melio/international-payments-utils';
import { Form, Text, UseMelioFormResults } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';
import { ComponentProps, useCallback, useState } from 'react';

import { FxVendorAccountFieldsFormFields } from '../fxBankDetailsForm';
import { getSelectedState } from './VendorLegalDetailsForm.utils';

export type VendorLegalDetailsFormProps = {
  form: UseMelioFormResults<FxVendorAccountFieldsFormFields>;
  defaultValues?: Partial<FxVendorAccountFieldsFormFields>;
};

export const VendorLegalDetailsForm = ({ form, defaultValues }: VendorLegalDetailsFormProps) => {
  const { registerField, setValue } = form;
  const { formatMessage } = useMelioIntl();
  const [showFullAddress, setShowFullAddress] = useBoolean(!!defaultValues?.line1);
  const [stateOrProvinceInfo, setStateOrProvinceInfo] = useState<StateOrProvinceInfo>();

  const clearState = useCallback(() => {
    setValue('state', '', { shouldValidate: true });
  }, [setValue]);

  const stateDetailsToOptions = () =>
    stateOrProvinceInfo?.values?.map((option) => ({
      value: option.key,
      label: option.name,
      testId: `state-${option.key}`,
    }));

  const setSelectedState = useCallback(
    (values?: StateValue[], state?: string) => {
      if (!state || !values) {
        return;
      }
      const selectedState = getSelectedState(values, state);
      if (selectedState) {
        setValue('state', selectedState.key);
      }
    },
    [setValue]
  );

  const setValuesToAddress = useCallback(
    (address: InternationalAddress) => {
      setValue('line1', address.line1, { shouldValidate: true });
      setValue('city', address.city.name, { shouldValidate: true });
      setValue('country', address.country.code, { shouldValidate: true });
      setValue('postalCode', address.postalCode, { shouldValidate: true });
      const info = getStateOrProvinceInfo(address.country.code as CountryCode);
      setStateOrProvinceInfo(info);
      if (info.required) {
        setSelectedState(info.values, address.state.name);
      } else {
        clearState();
      }
      setShowFullAddress.on();
    },
    [clearState, setSelectedState, setShowFullAddress, setValue]
  );

  useAnalyticsView('Vendor', true, true, {
    PageName: 'international-account-details',
    Flow: 'add-vendor',
    Intent: 'set-vendor-details',
  });

  const handleAddressChange: ComponentProps<typeof GoogleAddressInput>['onChange'] = ({ target: { value } }) => {
    setValuesToAddress(value as unknown as InternationalAddress);
  };

  const getStateOrProvinceLabel = (type: string): MessageKey =>
    type === 'province'
      ? 'activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.province.label'
      : 'activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.state.label';

  return (
    <Form data-component="VendorLegalDetailsForm" columns={2}>
      <Text textStyle="body4Semi" color="global.neutral.900">
        {formatMessage('activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.title')}
      </Text>
      <Form.TextField
        colSpan={2}
        labelProps={{
          label: formatMessage(
            `activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.legalBusinessName.label`
          ),
        }}
        helperTextProps={{
          label: formatMessage(
            `activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.legalBusinessName.helperText`
          ),
        }}
        {...registerField('legalBusinessName')}
      />
      <GoogleAddressInput
        colSpan={2}
        labelProps={{
          label: formatMessage(`activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.line1.label`),
        }}
        placeholder={formatMessage(
          `activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.line1.placeholder`
        )}
        onChange={handleAddressChange}
        {...registerField('line1')}
      />
      {showFullAddress ? (
        <>
          <Form.TextField
            colSpan={2}
            labelProps={{
              label: formatMessage(`activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.line2.label`),
            }}
            placeholder={formatMessage(
              `activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.line2.placeholder`
            )}
            {...registerField('line2')}
          />
          <Form.TextField
            colSpan={2}
            labelProps={{
              label: formatMessage(`activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.city.label`),
            }}
            placeholder={formatMessage(
              `activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.city.placeholder`
            )}
            {...registerField('city')}
          />
          {stateOrProvinceInfo?.required && stateOrProvinceInfo?.type && (
            <Form.SelectNew
              colSpan={2}
              options={stateDetailsToOptions() || []}
              labelProps={{
                label: formatMessage(getStateOrProvinceLabel(stateOrProvinceInfo.type)),
              }}
              {...registerField('state')}
              isRequired
            />
          )}
          <Form.TextField
            labelProps={{
              label: formatMessage(`activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.country.label`),
            }}
            {...registerField('country')}
          />
          <Form.TextField
            labelProps={{
              label: formatMessage(
                `activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.postalCode.label`
              ),
            }}
            placeholder={formatMessage(
              `activities.fxDeliveryMethodActivity.screens.VendorLegalDetailsForm.postalCode.placeholder`
            )}
            {...registerField('postalCode')}
          />
        </>
      ) : null}
    </Form>
  );
};

VendorLegalDetailsForm.displayName = 'VendorLegalDetailsForm';
