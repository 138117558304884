import { FormDialog, useMelioIntl } from '@melio/ar-domain';
import { Form, Group, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React, { useMemo } from 'react';
import { date, object, SchemaOf } from 'yup';

import { DateRange, DateRangeDates } from '../types';

const useSchema = () =>
  object().shape({
    start: date(),
    end: date(),
  }) as SchemaOf<DateRangeDates>;

export type DateRangePickerModalProps = {
  onClose: VoidFunction;
  selectedDateRange?: DateRange;
  onSubmit: (dateRange: DateRangeDates) => void;
  isOpen: boolean;
};

export const DateRangePickerModal = forwardRef<DateRangePickerModalProps>(
  ({ onClose, onSubmit, selectedDateRange, ...props }, ref) => {
    const selectedValues = useMemo(
      () => ({
        // convert hyphens to slashes in order to treat the date as absolute date and ignore timezone
        // eslint-disable-next-line no-restricted-syntax
        start: selectedDateRange?.start ? new Date(selectedDateRange.start.replace(/-/g, '/')) : undefined,
        // eslint-disable-next-line no-restricted-syntax
        end: selectedDateRange?.end ? new Date(selectedDateRange.end.replace(/-/g, '/')) : undefined,
      }),
      [selectedDateRange?.start, selectedDateRange?.end]
    );

    const { reset, registerField, watch, ...rest } = useMelioForm<DateRangeDates>({
      onSubmit,
      schema: useSchema(),
      values: selectedValues,
      resetOptions: { keepValues: false },
    });
    const { formatMessage } = useMelioIntl();

    const watchEndDate = watch('end');
    const watchStartDate = watch('start');

    const handleClose = () => {
      reset();
      onClose();
    };

    return (
      <FormDialog
        data-testid="date-range-picker-modal-screen"
        data-component={DateRangePickerModal.displayName}
        onClose={handleClose}
        header={formatMessage('ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.custom.modal.title')}
        useFormResults={rest}
        primaryButton={{
          label: formatMessage(
            'ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.custom.modal.primary.label'
          ),
          variant: 'primary',
        }}
        secondaryButton={{
          label: formatMessage(
            'ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.custom.modal.secondary.label'
          ),
          variant: 'tertiary',
        }}
        {...props}
        ref={ref}
        description={formatMessage(
          'ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.custom.modal.description'
        )}
      >
        <Group>
          <Form.DateField
            {...registerField('start')}
            maxDate={watchEndDate}
            labelProps={{
              label: formatMessage(
                'ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.custom.modal.start.label'
              ),
            }}
          />
          <Form.DateField
            {...registerField('end')}
            minDate={watchStartDate}
            labelProps={{
              label: formatMessage(
                'ar.dashboard.activities.invoiceTable.filters.advanced.dateRanges.custom.modal.end.label'
              ),
            }}
          />
        </Group>
      </FormDialog>
    );
  }
);
DateRangePickerModal.displayName = 'DateRangePickerModal';
