import { useCallback } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { PayDashboardActivity, SyncFlowEnum } from '@melio/ap-activities';
import { usePayDashboardTabs } from '@melio/ap-activities/src/components/PayDashboard/hooks/usePayDashboardTabs';
import { PayDashboardActivityBanner } from '@melio/ap-activities/src/components/PayDashboard/payDashboardActivityPropType';
import {
  getVendorNameForNotificationMessage,
  usePreSeason1099SectionBanner,
  useSeason1099SectionBanner,
} from '@melio/ap-widgets';
import { LocalStorageKeys } from '@melio/local-storage';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { AccountingPlatformSlug, useAccountingPlatforms, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import {
  calculateGracePeriodStatus,
  useGetAccountLimitations,
  useIsMissingKycComplianceInformation,
} from '@melio/platform-kyc';
import { usePartnerFeature } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';

import { usePartnerConfig } from '@/hooks/partners';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { useContractors1099Sync } from '@/hooks/useContractors1099Sync';
import { ActiveFlowEnum, ScreensEnum } from '@/store/app/app.types';
import { useContractors1099SyncStatus } from '@/widgets/settings-page/TaxAndReportsPage/hooks/useContractors1099SyncStatus';
import { VendorDrawer } from '@/widgets/VendorDrawer/VendorDrawer.widget';
import {
  useAccountingPlatformConnect,
  useIntuitConnect,
  useQuickBooksDesktopConnect,
} from '../../hooks/accountingPlatforms.hooks';
import {
  getQboAccountingId,
  getQuickBooksDesktopAccountingPlatform,
  getXeroAccountingPlatform,
} from '../../utils/accountingPlatform.utils';

export const NewPayDashboardRoute = () => {
  const navigate = useNavigate();
  const localStorage = usePartnerLocalStorage();
  const { partnerConfig } = usePartnerConfig();
  useActiveScreen(ScreensEnum.payDashboard, ActiveFlowEnum.payDashboard);
  const {
    goToAddNewBill,
    goToReviewScannedInvoice,
    goToEditPayment,
    goToEditBill,
    generateNPEDashboardLink,
    goToRetryFailedToCollectPayment,
    goToRetryFailedToDeliverPayment,
    goToRefundPayment,
    goToVoidAndRefundPayment,
    goToVoidAndResendPayment,
    goToSettingsAccountSoftware,
    goToApprovePaymentRequest,
    goToEditBillSubscription,
    goToAddNewBillManual,
    goToAddNewBillUpload,
    goToCompleteRequiredDetails,
  } = useRouter();
  const { track } = useAnalytics();
  const { pathname } = useLocation();
  const {
    isMissingKycComplianceInformation,
    isVerificationFailed,
    isGracePeriodEnded,
    isGracePeriodStarted,
    numberOfPaymentsLeftInGracePeriod,
    isFetching: isFetchingComplianceInformation,
  } = useIsMissingKycComplianceInformation();

  const { isOrgOwesUsMoney } = useGetAccountLimitations('dashboard');
  const [isOrgOwesUsMoneyBannerEnabled] = usePartnerFeature('IsOrgOwesUsMoneyBannerEnabled', false);

  const onOpenFreeAchPromotionModal = useCallback(() => {
    if (localStorage.getItem(LocalStorageKeys.freeAchPromotionModalSeen)) {
      return false;
    }
    localStorage.setItem(LocalStorageKeys.freeAchPromotionModalSeen, 'true');
    return true;
  }, [localStorage]);

  const handlePayPaymentRequest = (id: string) => {
    goToApprovePaymentRequest({ paymentRequestId: id, returnUrl: pathname });
  };

  const handleAddNewBill = ({
    returnUrl,
    vendorId,
    originFlow,
    amount,
  }: {
    returnUrl: string;
    vendorId?: string;
    originFlow?: OriginFlow;
    amount?: string;
  }) => {
    if (vendorId) {
      goToAddNewBill({ originFlow: OriginFlow.VendorsFlow, returnUrl, vendorId, amount });
    } else if (originFlow) {
      goToAddNewBill({ originFlow, returnUrl });
    } else {
      goToAddNewBill({ originFlow: OriginFlow.BillPage, returnUrl });
    }
  };

  const handleAddNewBillManual = ({ returnUrl, vendorId }: { returnUrl: string; vendorId?: string }) => {
    if (vendorId) {
      goToAddNewBillManual({ originFlow: OriginFlow.VendorsFlow, returnUrl, vendorId });
    } else {
      goToAddNewBillManual({ originFlow: OriginFlow.BillPage, returnUrl });
    }
  };

  const handleAddNewBillUpload = ({
    returnUrl,
    vendorId,
    files,
  }: {
    returnUrl: string;
    vendorId?: string;
    files?: File[];
  }) => {
    if (vendorId) {
      goToAddNewBillUpload({ originFlow: OriginFlow.VendorsFlow, returnUrl, vendorId, files });
    } else {
      goToAddNewBillUpload({ originFlow: OriginFlow.BillPage, returnUrl, files });
    }
  };

  const handleOnCloseDrawer = (tabName: string) => {
    const params = new URLSearchParams(window.location.search);

    const link = generateNPEDashboardLink(tabName);

    navigate(
      {
        pathname: link,
        search: params.toString(),
      },
      { state: { keepToast: true } },
    );
  };

  const handleViewPayment = ({ paymentId }: { paymentId?: string }) => {
    const link = `${generateNPEDashboardLink('payments')}/${paymentId}`;

    navigate({
      pathname: link,
    });
  };

  const { data: accountingPlatformData } = useAccountingPlatforms();
  const { selectedTab } = usePayDashboardTabs();
  const qboAccountingId = getQboAccountingId(accountingPlatformData);
  const xeroAccountingPlatform = getXeroAccountingPlatform(accountingPlatformData);
  const quickBooksDesktopAccountingPlatform = getQuickBooksDesktopAccountingPlatform(accountingPlatformData);
  const { loginToQuickBooks, isLoading: isQuickbooksConnecting } = useIntuitConnect(
    SyncFlowEnum.Setting,
    qboAccountingId,
    { redirectMethod: 'replace' },
  );
  const { isLoading: isXeroConnecting, connectToAccountingPlatform: onConnectXero } = useAccountingPlatformConnect({
    flowToReturn: SyncFlowEnum.NewBill,
    accountingPlatformId: xeroAccountingPlatform?.id,
  });
  const { isLoading: isQbdtConnecting, connectToAccountingPlatform: onConnectQuickBooksDesktop } =
    useQuickBooksDesktopConnect({
      flowToReturn: SyncFlowEnum.NewBill,
      accountingPlatformId: quickBooksDesktopAccountingPlatform?.id,
    });
  const isAccountingPlatformConnecting = isQuickbooksConnecting || isXeroConnecting || isQbdtConnecting;
  const onConnectAccountingPlatform = (accountingPlatformSlug: AccountingPlatformSlug) => {
    if (accountingPlatformSlug === AccountingPlatformSlug.QuickBooksOnline) {
      loginToQuickBooks();
    } else if (accountingPlatformSlug === AccountingPlatformSlug.QuickBooksDesktop) {
      onConnectQuickBooksDesktop();
    } else if (accountingPlatformSlug === AccountingPlatformSlug.Xero) {
      onConnectXero();
    }
  };

  const { isSyncedWithZenwork, isImport1099ContractorsEnabled } = useContractors1099SyncStatus();

  const { isBannerVisible: shouldDisplayPreSeason1099SectionBanner } = usePreSeason1099SectionBanner();
  const { isBannerVisible: shouldDisplaySeason1099SectionBanner } = useSeason1099SectionBanner({
    isImport1099ContractorsEnabled,
  });
  const shouldDisplayComplainantWarningBanner = !isFetchingComplianceInformation && isMissingKycComplianceInformation;
  const shouldDisplayComplianceVerificationFailedBanner = !isFetchingComplianceInformation && isVerificationFailed;
  const shouldDisplayOrgOwesUsMoneyBanner = isOrgOwesUsMoneyBannerEnabled && isOrgOwesUsMoney;

  const handleCloseCompleteDetailsDialog = () => {
    track('Organization', 'Click', {
      PageName: `dashboard-${selectedTab}`,
      Flow: 'dashboard',
      Intent: 'submit-details',
      GracePeriodStatus: calculateGracePeriodStatus(isGracePeriodStarted, isGracePeriodEnded),
      // GracePeriodDaysRemain: '', // TODO: should complete on phase 2
      GracePeriodPaymentsRemain: numberOfPaymentsLeftInGracePeriod,
      BannerType: 'kyc-complete-details',
      Cta: 'complete-details',
    });
    goToCompleteRequiredDetails();
  };

  const getBannerToDisplay = (): PayDashboardActivityBanner | undefined => {
    if (shouldDisplayComplainantWarningBanner) {
      return PayDashboardActivityBanner.KYCCompleteDetails;
    }
    if (shouldDisplayComplianceVerificationFailedBanner) {
      return PayDashboardActivityBanner.KYCVerificationFailed;
    }
    if (shouldDisplaySeason1099SectionBanner) {
      return PayDashboardActivityBanner.Season1099Banner;
    }
    if (shouldDisplayPreSeason1099SectionBanner) {
      return PayDashboardActivityBanner.PreSeason1099Banner;
    }
    if (shouldDisplayOrgOwesUsMoneyBanner) {
      return PayDashboardActivityBanner.OrgOwesUsMoneyBanner;
    }
    return undefined;
  };

  const { redirectToZenworkLoginPage, isRedirecting: isRedirectingToZenwork } = useContractors1099Sync();

  return (
    <>
      <PayDashboardActivity
        onAddNewBill={handleAddNewBill}
        onAddNewBillManual={handleAddNewBillManual}
        onAddNewBillUpload={handleAddNewBillUpload}
        onEditBill={goToEditBill}
        onPayPaymentRequest={handlePayPaymentRequest}
        onReviewScannedInvoice={({ id, returnUrl, originFlow }) =>
          goToReviewScannedInvoice({ scannedInvoiceId: id, returnUrl, originFlow })
        }
        onEditPayment={goToEditPayment}
        onRetryFailedToDeliverPayment={goToRetryFailedToDeliverPayment}
        onRetryFailedToCollectPayment={goToRetryFailedToCollectPayment}
        onRefundPayment={goToRefundPayment}
        onVoidAndRefundPayment={goToVoidAndRefundPayment}
        onVoidAndResendPayment={goToVoidAndResendPayment}
        goToSettingsAccountSoftware={goToSettingsAccountSoftware}
        onConnectAccountingPlatform={onConnectAccountingPlatform}
        onViewPayment={({ id: paymentId }) => {
          handleViewPayment({ paymentId });
        }}
        onOpenFreeAchPromotionModal={onOpenFreeAchPromotionModal}
        isSyncedWithZenwork={isSyncedWithZenwork}
        onEditBillSubscription={goToEditBillSubscription}
        tabsVariant={partnerConfig.tabsVariant || 'default'}
        isAccountingPlatformConnecting={isAccountingPlatformConnecting}
        bannerToDisplay={getBannerToDisplay()}
        onCloseCompleteDetailsDialog={handleCloseCompleteDetailsDialog}
        redirectToZenworkLoginPage={redirectToZenworkLoginPage}
        isRedirectingToZenwork={isRedirectingToZenwork}
      />
      <Routes>
        <Route
          path="/:tab/vendor/:vendorId"
          element={
            <VendorDrawerRoute onClose={handleOnCloseDrawer} onEditBillSubscription={goToEditBillSubscription} />
          }
        />
      </Routes>
    </>
  );
};

const VendorDrawerRoute = ({
  onClose,
  onEditBillSubscription,
}: {
  onClose: (tabName: string) => void;
  onEditBillSubscription: ({ id, returnUrl }: { id: string; returnUrl: string }) => void;
}) => {
  const { tab } = useParams();
  const { vendorId } = useParams();
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();

  if (!vendorId) {
    throw new Error(`could not get vendorId from route params.`);
  }

  const { pathname: returnUrl } = useResolvedPath('');

  const onEditBillSubscriptionWithReturnUrl = ({ id }: { id: string }) => {
    onEditBillSubscription({ id, returnUrl });
  };

  const onDeleteSuccess = (vendor: Vendor) => {
    const vendorName = getVendorNameForNotificationMessage(vendor);

    showMessage({
      type: 'informative',
      title: formatMessage('activities.payDashboard.deleteVendorModal.toast.success', { vendorName }),
      dataTestId: 'vendor-deleted',
    });
  };

  return (
    <VendorDrawer
      vendorId={vendorId}
      onClose={() => {
        if (tab) onClose(tab);
      }}
      onEditBillSubscription={onEditBillSubscriptionWithReturnUrl}
      onDeleteSuccess={onDeleteSuccess}
      showExternalMessage={showMessage}
    />
  );
};
