import {
  ADD_NEW_COMPANY_ROUTE,
  COMPLETE_DETAILS_ROUTE,
  PAYMENT_METHOD_BANK_SELECT_URL_ROUTE,
  SUBSCRIPTION_ACCOUNTING_CLIENT_PLAN_SELECTED_ROUTE,
  SUBSCRIPTION_CHECKOUT_ROUTE,
  SUBSCRIPTION_DOWNGRADE_ACTION_REQUIRED_ROUTE,
  SUBSCRIPTION_DOWNGRADE_CONFIRM_DOWNGRADE_ROUTE,
  SUBSCRIPTION_GRACE_PLAN_IS_REQUIRED_ROUTE,
  SUBSCRIPTION_GRACE_SELECT_CLIENT_PLAN_ROUTE,
  SUBSCRIPTION_GRACE_SELECT_YOUR_PLAN_ROUTE,
  SUBSCRIPTION_OWNER_SHOULD_SETUP_ROUTE,
  SUBSCRIPTION_PLANS_ROUTE,
} from '../../router/routes.consts';

const COMMON_ALLOWED_ROUTES = [
  PAYMENT_METHOD_BANK_SELECT_URL_ROUTE,
  SUBSCRIPTION_PLANS_ROUTE,
  SUBSCRIPTION_CHECKOUT_ROUTE,
  SUBSCRIPTION_ACCOUNTING_CLIENT_PLAN_SELECTED_ROUTE,
  SUBSCRIPTION_OWNER_SHOULD_SETUP_ROUTE,
  COMPLETE_DETAILS_ROUTE,
  ADD_NEW_COMPANY_ROUTE,
];

const NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES = [
  ...COMMON_ALLOWED_ROUTES,
  SUBSCRIPTION_GRACE_SELECT_YOUR_PLAN_ROUTE,
  SUBSCRIPTION_GRACE_PLAN_IS_REQUIRED_ROUTE,
  SUBSCRIPTION_GRACE_SELECT_CLIENT_PLAN_ROUTE,
  '/accountants/client/.*/assign-plan',
];

const SUBSCRIPTION_DOWNGRADE_CONFIRMATION_REQUIRED_ALLOWED_ROUTES = [
  ...COMMON_ALLOWED_ROUTES,
  SUBSCRIPTION_DOWNGRADE_CONFIRM_DOWNGRADE_ROUTE,
  SUBSCRIPTION_DOWNGRADE_ACTION_REQUIRED_ROUTE,
];

export const isRouteAllowedForNotSubscribedOrg = (pathname: string) =>
  isRouteAllowed(NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES, pathname);

export const isRouteAllowedWhenDowngradeConfirmationRequired = (pathname: string) =>
  isRouteAllowed(SUBSCRIPTION_DOWNGRADE_CONFIRMATION_REQUIRED_ALLOWED_ROUTES, pathname);

const isRouteAllowed = (whiteList: string[], pathname: string) =>
  whiteList.some((route) => new RegExp(`^${route}(/.*)?$`).test(pathname));
