import { Box } from '@chakra-ui/react';
import {
  BaseVirtualCard,
  Brand,
  BrandKey,
  BrandSymbol,
  BrandSymbolProps,
  Currency,
  Group,
  GroupProps,
  Skeleton,
  Text,
  useBreakpointValue,
} from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

import { SUVC_BASIS_THEORY_ELEMENT_ID } from '../consts';

const BasisTheoryVirtualCardDetails = ({
  label,
  isLoading,
  elementId,
}: {
  label: string;
  isLoading: boolean;
  elementId: string;
}) => (
  <Group variant="vertical" spacing="xxs">
    <Box display="inline-flex" width="max-content">
      <Text shouldSupportEllipsis textStyle="body4Semi" color="global.neutral.100">
        {label}
      </Text>
    </Box>
    <Box height="24px" minWidth="60px">
      {isLoading && (
        <Box alignContent="center" height="full" maxW="200px">
          <Skeleton height="12px" />
        </Box>
      )}
      <div data-private id={elementId} {...(isLoading && { style: { display: 'none' } })} />
    </Box>
  </Group>
);

export const BasisTheoryVirtualCard = ({
  amount,
  brandType,
  cardNetworkType,
  isLoading,
}: {
  amount: number;
  brandType: BrandKey;
  cardNetworkType: BrandSymbolProps['type'];
  isLoading: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const spacing = useBreakpointValue<GroupProps['spacing']>({ xs: 'xxs', s: 's' });

  return (
    <BaseVirtualCard data-private data-testid="virtual-card-acceptance-basis-theory-card-details" variant="brand">
      <Group variant="vertical" height="full" justifyContent="space-evenly" spacing={spacing}>
        <Group justifyContent="space-between" alignItems="center">
          <Currency value={amount} variant="inverse" />
          <BrandSymbol type={cardNetworkType} variant="default" size="extra-large" />
        </Group>
        <BasisTheoryVirtualCardDetails
          isLoading={isLoading}
          label={formatMessage('vex.screen.suvcAcceptance.cardDetails.cardNumber')}
          elementId={SUVC_BASIS_THEORY_ELEMENT_ID.cardNumber}
        />
        <Group justifyContent="space-between" alignItems="flex-end">
          <Group spacing="m">
            <BasisTheoryVirtualCardDetails
              isLoading={isLoading}
              label={formatMessage('vex.screen.suvcAcceptance.cardDetails.expiryDate')}
              elementId={SUVC_BASIS_THEORY_ELEMENT_ID.cardExpirationDate}
            />
            <BasisTheoryVirtualCardDetails
              isLoading={isLoading}
              label={formatMessage('vex.screen.suvcAcceptance.cardDetails.cvv')}
              elementId={SUVC_BASIS_THEORY_ELEMENT_ID.cardVerificationCode}
            />
          </Group>
          <Box display="inline-flex" width="80px" justifyContent="flex-end">
            <Brand type={brandType} variant="inverse" />
          </Box>
        </Group>
      </Group>
    </BaseVirtualCard>
  );
};
