import { RoleUniqueNames } from '@melio/platform-api';

import { capOneLinks } from '../links';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

export const capOneEnvConfig: PartnerConfigByEnv = {
  default: {
    translationsSrc: 'https://platform-static.meliopayments.com/assets/capital-one/messages.json',
    tabTitle: 'Accounts Payable',
    settings: {
      Brand: { color: { enabled: false }, logo: { enabled: true } },
      settings: {
        options: [
          {
            type: 'company',
            items: [
              'profile-settings',
              'company-settings',
              'collaborators-setting',
              'notification-preferences',
              'workflows-setting',
              'accounting-software-sync',
            ],
          },
          {
            type: 'payments',
            items: [
              'payment-methods-setting',
              'subscription-plans-setting',
              'billing-setting',
              'receiving-methods-settings',
            ],
          },
          {
            type: 'ar',
            items: ['receiving-methods-settings', 'invoice-items', 'invoice-settings', 'invoice-email-notifications'],
          },
          {
            type: 'support',
            items: ['support-setting'],
          },
        ],
      },
      collaborator: {
        rolesOrderMapping: {
          [RoleUniqueNames.CapitalOnePrimary]: 1,
          [RoleUniqueNames.CapitalOneAm]: 2,
        },
      },
      payment: {
        showCreatedByIndication: true,
        memoToVendor: {
          deliveryMethodBlackList: ['biller-account', 'managed-account'],
        },
      },
      partnerProductName: 'Accounts Payable',
      fundingSourcePolicy: {
        fundingSourceCard: {
          showCreatedBy: false,
        },
        'bank-account': {
          fundingSourceGroup: 'bank-account',
          read: true,
          write: true,
          order: 1,
        },
        credit: {
          fundingSourceGroup: 'credit',
          read: true,
          write: true,
          order: 0,
        },
        debit: {
          fundingSourceGroup: 'debit',
          read: true,
          write: true,
          order: 2,
        },
      },
      newPayExperience: {
        firstTimeExperience: {
          assetType: 'image',
          imageUrl: 'https://platform-static.meliopayments.com/assets/capital-one/first-time-experience.png',
          mobileImageUrl: 'https://platform-static.meliopayments.com/assets/capital-one/first-time-experience.png',
          videoLink: '',
        },
      },
      trustedPartners: ['Mastercard', 'Quickbooks', 'Visa'],
      supportEmail: 'capitaloneaccountspayable@melio.com',
      eoyCheck: {
        enabled: true,
        flagName: 'us-holidays-checks-partners',
        promoteFastCheck: true,
        link: 'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/6501485721372',
      },
      helpCenterUrl: 'https://capitalonevendorpayments.zendesk.com/hc/en-us',
      icons: {
        deliveryMethod: {
          virtualCard: 'capital-one',
        },
      },
      assets: {
        virtualCardInfoModalDesktopImg:
          'https://platform-static.meliopayments.com/assets/capital-one/virtual_card_info_modal_desktop_image.jpg',
        virtualCardInfoModalMobileImg:
          'https://platform-static.meliopayments.com/assets/capital-one/virtual_card_info_modal_mobile_image.jpg',
      },
      virtualCardExpiryPeriodInDays: 14,
      deliveryMethodTypeOptionDisabledReasons: {
        'virtual-card': ['fundingSourceNotSupported', 'vendorIsBank'],
      },
      MarkAsPaid: {
        enabled: false,
      },
      MtlKycUpliftCompanyDetails: {
        enabled: true,
        flagName: 'platform-mtl-kyc-uplift-company-details',
      },
      verifyFundingSourceOnlyByCreator: {
        enabled: true,
      },
      showInsufficientCreditBanner: {
        flagName: 'platform-show-insufficient-credit-card-banner',
        enabled: true,
      },
      showNoCreditCardBanner: {
        enabled: true,
        flagName: 'platform-show-no-credit-card-banner',
      },
    },
    services: {
      forethoughtApiKey: '',
      zendeskKey: '47280545-6ce4-4671-94b6-0a29879cfbfc',
      zendeskWorkflow: 'Cap One',
      tbt: {
        supportedCardProviders: {
          'american-express': false,
        },
      },
    },
    tutorialVideo: 'https://player.vimeo.com/video/896873143',
    zendesk: {
      useNewWidgetApi: true,
      headerColor: '#C3C4C2',
      resultListsColor: '#C3C4C2',
      themeColor: '#C3C4C2',
      logo: 'https://platform-static.meliopayments.com/assets/capital-one/capOneAvatarLogo.svg',
      title: 'Accounts Payable Support',
    },
    links: capOneLinks,
  },
  prod: {
    services: {
      tabapay: {
        card: {
          url: 'https://sso.tabapay.com/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        credit: {
          url: 'https://sso.tabapay.com/SSOMelioCap1.html?cards=visa,mastercard',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        debit: {
          url: 'https://sso.tabapay.com/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
      },
      zendeskKey: '47280545-6ce4-4671-94b6-0a29879cfbfc',
    },
    links: {
      backButtonUrl: 'https://myaccounts.capitalone.com/Card/{BACK_BUTTON_REF_ID}/payVendor',
      backButtonUrlFallback: 'https://myaccounts.capitalone.com/accountSummary',
      portalLink: 'https://myaccounts.capitalone.com/Card/{BACK_BUTTON_REF_ID}/payVendors',
      failedToCollectSupport:
        'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/5040345921820-All-the-reasons-why-your-payment-could-fail',
    },
  },
  dev: {
    services: {
      tabapay: {
        card: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        credit: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioCap1.html?cards=visa,mastercard',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        debit: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
      },
    },
    links: {
      backButtonUrl: `https://ease-reg.clouddqtext.capitalone.com/Card/{BACK_BUTTON_REF_ID}/payVendor`,
      backButtonUrlFallback: 'https://myaccounts.capitalone.com/accountSummary',
      portalLink: `https://ease-reg.clouddqtext.capitalone.com/Card/{BACK_BUTTON_REF_ID}/payVendors`,
      failedToCollectSupport:
        'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/5040345921820-All-the-reasons-why-your-payment-could-fail',
    },
  },
};
export const capitalOneConfig = extendDefaultConfig(capOneEnvConfig);
