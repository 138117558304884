import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { useSystemMessage } from '@melio/platform-utils/system-message';
import { useState } from 'react';

import {
  useFreeTrialPeriod,
  useIsSubscriptionBillingPayorIsFirm,
  useIsSubscriptionsEnabled,
  usePartnerGroups,
  usePlanInfo,
  useRedirects,
  useSubscription,
} from '../../../hooks';
import {
  SubscriptionCancelApprovalModalScreen,
  SubscriptionCancelFeedbackModalScreen,
  SubscriptionCancelFreeTrialGoingSoSoonModalScreen,
  SubscriptionCancelPlanManagedByFirmWarningModalScreen,
  SubscriptionCancelSuccessModalScreen,
} from './screens';

export type SubscriptionCancelModalActivityProps = {
  isOpen: boolean;
  newPlanId: string;
  origin: 'settings' | 'plans';
  onClose: () => void;
};

enum Step {
  FreeTrialGoingSoSoon = 'FreeTrialGoingSoSoon',
  CancelPlanManagedByFirmWarning = 'CancelPlanManagedByFirmWarning',
  CancelApproval = 'CancelApproval',
  CancelFeedback = 'CancelFeedback',
  CancelSuccess = 'CancelSuccess',
}

const useSteps = () => {
  const subscription = useSubscription();
  const isSubscriptionPaidByFirm = useIsSubscriptionBillingPayorIsFirm();
  const isFreeTrial = subscription?.isFreeTrial;

  const { createDate } = useDateUtils();
  const { getIsReminderDatePassed } = useFreeTrialPeriod();

  const isGoingSoSoon =
    isFreeTrial && subscription?.nextBillingDate && !getIsReminderDatePassed(createDate(subscription.nextBillingDate));

  const [steps] = useState(
    [
      { step: Step.FreeTrialGoingSoSoon, isShown: isGoingSoSoon },
      { step: Step.CancelPlanManagedByFirmWarning, isShown: isSubscriptionPaidByFirm },
      { step: Step.CancelApproval, isShown: true },
      { step: Step.CancelFeedback, isShown: true },
      { step: Step.CancelSuccess, isShown: true },
    ]
      .filter(({ isShown }) => isShown)
      .map(({ step }) => step)
  );

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const goToNextStep = () => {
    setCurrentStepIndex((current) => current + 1);
  };

  const resetSteps = () => {
    setCurrentStepIndex(0);
  };

  return { currentStep: steps[currentStepIndex], goToNextStep, resetSteps };
};

export const SubscriptionCancelModalActivity = ({
  isOpen,
  newPlanId,
  origin,
  onClose,
}: SubscriptionCancelModalActivityProps) => {
  const { currentStep, goToNextStep, resetSteps } = useSteps();

  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();

  const { planName: newPlanName } = usePlanInfo(newPlanId);
  const { goToDashboard, goToSettingsPage } = useRedirects();
  const { isFiservPartner } = usePartnerGroups();

  const onDoneApprovalModal = () => {
    if (isFiservPartner) {
      onClose();

      const redirectAfterCancel = isFiservPartner ? goToSettingsPage : goToDashboard;

      redirectAfterCancel();
      showMessage({
        type: 'success',
        title: formatMessage('activities.subscription.cancelOrDowngradeFlow.systemMessage.success', {
          planName: newPlanName,
        }),
      });
    } else {
      goToNextStep();
    }
  };

  const onDoneSuccessModal = () => {
    handleClose();

    if (origin !== 'settings') {
      goToSettingsPage();
    }
  };

  if (!isSubscriptionsEnabled) {
    return null;
  }

  const handleClose = () => {
    onClose();
    resetSteps();
  };

  return (
    <>
      <SubscriptionCancelFreeTrialGoingSoSoonModalScreen
        isOpen={isOpen && currentStep === Step.FreeTrialGoingSoSoon}
        onClose={handleClose}
        onProceedToCancel={goToNextStep}
      />
      <SubscriptionCancelPlanManagedByFirmWarningModalScreen
        isOpen={isOpen && currentStep === Step.CancelPlanManagedByFirmWarning}
        onClose={handleClose}
        onProceedToCancel={goToNextStep}
      />
      <SubscriptionCancelApprovalModalScreen
        isOpen={isOpen && currentStep === Step.CancelApproval}
        onClose={handleClose}
        onSuccess={onDoneApprovalModal}
        newPlanId={newPlanId}
      />
      <SubscriptionCancelFeedbackModalScreen
        isOpen={isOpen && currentStep === Step.CancelFeedback}
        onClose={goToNextStep}
        onSuccess={goToNextStep}
      />
      <SubscriptionCancelSuccessModalScreen
        isOpen={isOpen && currentStep === Step.CancelSuccess}
        onClose={onDoneSuccessModal}
      />
    </>
  );
};
