// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box } from '@chakra-ui/react';
import { FloatingPortal } from '@floating-ui/react';

export type MelioWrapperProps = {
  children?: React.ReactNode;
};

export const MelioWrapper: React.VFC<MelioWrapperProps> = ({ children }) => (
  <FloatingPortal>
    <melio-wrapper>
      <Box>{children}</Box>
    </melio-wrapper>
  </FloatingPortal>
);
