import { getVendorAccountNumber } from '@melio/ap-widgets';
import { Address, BankAccount, Vendor } from '@melio/platform-api';
import { identity, pickBy } from 'lodash';

import { EditVendorFormFields } from '../types';

export const getUpdateVendorPayload = (data: EditVendorFormFields) => {
  const { companyName, email, phone, fullName, accountNumber, countryCode, line1, city, state, postalCode } = data;

  const address: Address | null = countryCode
    ? {
        line1: line1 || '',
        city: city || '',
        state: state || '',
        postalCode: postalCode || '',
        countryCode,
      }
    : null;

  const contact = pickBy(
    {
      name: fullName,
      email,
      phoneNumber: phone,
      address,
    },
    identity
  );

  return {
    name: companyName ?? '',
    contact,
    accountNumber: accountNumber || undefined,
  };
};

export const getVendorDefaultFormValues = (vendor?: Vendor) => {
  const bankAccount = vendor?.deliveryMethods.find((method) => method.type === 'bank-account')?.details as BankAccount;

  return {
    companyName: vendor?.name || '',
    accountNumber: (vendor && getVendorAccountNumber(vendor)) || '',
    address: vendor?.contact.address || null,
    fullName: vendor?.contact.name || '',
    email: vendor?.contact.email || '',
    phone: vendor?.contact.phoneNumber || '',
    nickname: vendor?.nickname || '',
    postalCode: vendor?.contact.address?.postalCode || '',
    city: vendor?.contact.address?.city || '',
    state: vendor?.contact.address?.state || '',
    line1: vendor?.contact.address?.line1 || '',
    line2: vendor?.contact.address?.line2 || '',
    bankRoutingNumber: bankAccount?.routingNumber || '',
    bankAccountNumber: bankAccount?.accountNumber || '',
    uniqueName: vendor?.uniqueName || '',
    countryCode: vendor?.contact.address?.countryCode || 'US',
  };
};
