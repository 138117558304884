import { FeatureFlags, useAnonymousFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';
import { useConfig } from '@melio/platform-provider';

export const useCardWritePermissions = () => {
  const { settings } = useConfig();

  const [allowCaratIntegration] = useAnonymousFeature<boolean>(FeatureFlags.AllowCaratIntegration, false);
  const isCaratTokenProvider = settings.tokenProvider === 'carat';

  const { can } = usePermissions();
  const canCreateCard = can({ action: 'create', subject: 'fundingSource', subjectData: { fundingType: 'card' } });

  if (isCaratTokenProvider && !allowCaratIntegration) {
    return false;
  }

  return canCreateCard;
};
