/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useShowVendorUniqueNameField, useVendorForm, useVendorUniqueNameEnabled } from '@melio/ap-widgets';
import { Form, GridItem, Group, SimpleGrid, Text, useBreakpoint } from '@melio/penny';
import { useVendor } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum } from '@/types/vendors.types';
import { VendorDetailsFormInnerProps } from '../types';
import { preventFormSubmissionOnEnter } from '../utils';
import { getUpdateVendorPayload } from './utils';
import { useFormSchema, VendorDetailsMangedByFiservFormFields } from './validation';

export const VendorDetailsMangedByFiservForm = forwardRef<VendorDetailsFormInnerProps, 'form'>(
  (
    { vendorId, defaultValues, isSaving, onSubmit, onSubmissionStateChange, isEditable, inlineApiErrorCodes, ...props },
    ref,
  ) => {
    const { formatMessage } = usePlatformIntl();
    const isVendorUniqueNameEnabled = useVendorUniqueNameEnabled();
    const { isExtraSmallScreen } = useBreakpoint();
    const showUniqueNameField = useShowVendorUniqueNameField({ defaultValues, isVendorUniqueNameEnabled });

    const [unmaskedAccountNumber, setUnmaskedAccountNumber] = useState<string>();

    const { getVendorUnmaskedAccountNumber } = useVendor({ id: vendorId, enabled: false });

    const { formProps, registerField, reset } = useVendorForm<VendorDetailsMangedByFiservFormFields>({
      onSubmit: (data) => onSubmit(getUpdateVendorPayload(data)),
      schema: useFormSchema({ showUniqueNameField }),
      isSaving,
      defaultValues,
      inlineApiErrorCodes,
      onSubmissionStateChange,
    });

    useEffect(() => {
      getVendorUnmaskedAccountNumber().then(({ accountNumber }) => setUnmaskedAccountNumber(accountNumber));
    }, [getVendorUnmaskedAccountNumber, defaultValues?.accountNumber]);

    useEffect(() => {
      reset({ ...defaultValues, accountNumber: isEditable ? unmaskedAccountNumber : defaultValues?.accountNumber });
    }, [defaultValues, isEditable, unmaskedAccountNumber, reset]);

    return (
      <Group variant="vertical" spacing="m">
        <Group variant="vertical" spacing="xs">
          {isEditable && (
            <Text color="global.neutral.900" textStyle="body4">
              {formatMessage('widgets.vendorDetails.form.business.requiredFields')}
            </Text>
          )}
          <Text as="h3" textStyle="heading3Semi" id="widgets-vendorDetails-form-business-title">
            {formatMessage('widgets.vendorDetails.form.business.title')}
          </Text>
        </Group>
        <Form
          data-component={DataComponentEnum.VENDOR_DETAILS_MANAGED_BY_FISERV_FORM}
          {...props}
          {...formProps}
          ref={ref}
          isViewMode={!isEditable}
          {...preventFormSubmissionOnEnter()}
        >
          <Form.TextField
            {...registerField('companyName')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.companyName.label') }}
            placeholder={formatMessage('widgets.vendorDetails.form.companyName.placeholder')}
            isTruncated={!isEditable}
            isDisabled={isEditable}
          />
          <Form.TextField
            {...registerField('nickname')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.nickname.label') }}
            viewModePlaceholder={formatMessage('widgets.vendorDetails.form.nickname.viewModePlaceholder')}
            autoFocus={isEditable}
            isTruncated={!isEditable}
          />
          <Form.TextField
            data-private
            {...registerField('accountNumber')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.accountNumber.label') }}
            isTruncated={!isEditable}
          />
          {showUniqueNameField ? (
            /* @ts-ignore Form.ContentBox should be change to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */
            <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
              <SimpleGrid columns={isExtraSmallScreen ? 1 : 2} spacing="m">
                <GridItem colSpan={isExtraSmallScreen ? 1 : 2} paddingTop="m">
                  <Text as="h3" textStyle="heading3Semi" id="widgets-vendorDetails-form-accounting-software-title">
                    {formatMessage('widgets.vendorDetails.form.accountingSoftware.title')}
                  </Text>
                </GridItem>
                <Form.TextField
                  colSpan={2}
                  {...registerField('uniqueName')}
                  labelProps={{ label: formatMessage('widgets.vendorDetails.form.uniqueName.label') }}
                  viewModePlaceholder={formatMessage('widgets.vendorDetails.form.uniqueName.viewModePlaceholder')}
                  isTruncated={!isEditable}
                />
              </SimpleGrid>
            </Form.ContentBox>
          ) : null}
        </Form>
      </Group>
    );
  },
);
