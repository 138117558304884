import { FundingSourceType } from '@melio/platform-api';
import { MelioConfig } from '@melio/platform-provider';
import { first } from 'lodash';

export function getDefaultSelectedType(
  fundingSourcePolicy: MelioConfig['settings']['fundingSourcePolicy']
): FundingSourceType {
  if (!fundingSourcePolicy) {
    return 'bank-account';
  }

  type WithOrder = { order: number };

  const typesByOrder = (Object.entries(fundingSourcePolicy) as Array<[FundingSourceType, Partial<WithOrder>]>)
    .filter(([type]) => Object.values(FundingSourceType).includes(type))
    .filter(([, policy]) => policy.order !== undefined)
    .sort(([, policy1], [, policy2]) => (policy1 as WithOrder).order - (policy2 as WithOrder).order)
    .map(([fsType]) => fsType);

  const firstFoundType = first(typesByOrder);
  return firstFoundType || 'bank-account';
}
