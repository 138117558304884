import { Flex } from '@chakra-ui/react';
import { Container, Group, Illustration, IllustrationProps, useBreakpointValue } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useLocation, useNavigate } from '@melio/platform-utils';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../NewSinglePaymentStepLayout';
import { useApplicationApprovalContent } from './hooks/useApplicationApprovalContent.hook';
import { ApplicationStatus } from './types';

type FinancingApplicationApprovalStatusActivityProps = {
  status: ApplicationStatus;
};

export const FinancingApplicationApprovalStatusActivity = ({
  status,
}: FinancingApplicationApprovalStatusActivityProps) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { state } = useLocation<{ amount?: number }>();
  const amount = state?.amount || 0;
  const analyticsApplicationStatus = `application-${status.toLocaleLowerCase()}`;

  useEffect(() => {
    track('PaymentMethod', 'View', {
      PageName: analyticsApplicationStatus,
      Flow: 'financing',
      Intent: analyticsApplicationStatus,
      FinancingExperience: 'application',
      ...(status === ApplicationStatus.Approved ? { AmountApproved: amount } : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onBack = () => {
    track('PaymentMethod', 'View', {
      PageName: analyticsApplicationStatus,
      Flow: 'financing',
      Intent: 'back-to-dashboard',
      FinancingExperience: 'application',
      Cta: 'back-to-pay',
    });

    navigate('/pay-dashboard/vendors');
  };

  const { title, description, illustration, bottomLink, actions } = useApplicationApprovalContent(status, {
    onBack,
    amount,
  });

  const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });

  return (
    <Container paddingTop="xxl">
      <NewSinglePaymentStepLayout
        data-testid={`financing-application-approval-activity-${status}`}
        footerContent={
          <Flex direction="column" alignItems="center">
            <NewSinglePaymentStepLayout.Actions>
              <Flex direction="column" w="full">
                {actions}
              </Flex>
            </NewSinglePaymentStepLayout.Actions>
            {bottomLink && (
              <Container textAlign="center" data-testid="financing-application-approval-bottom-link" paddingTop="xl">
                {bottomLink}
              </Container>
            )}
          </Flex>
        }
      >
        {illustration && (
          <Group justifyContent="center">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Illustration type={illustration} size={illustrationSize} />
          </Group>
        )}
        <NewSinglePaymentStepLayout.Title data-testid="financing-application-approval-title">
          {title}
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description data-testid="financing-application-approval-description">
          {description}
        </NewSinglePaymentStepLayout.Description>
      </NewSinglePaymentStepLayout>
    </Container>
  );
};
