import { useUserPreference } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useState } from 'react';

import { SchedulePaymentIntent } from '../../../../types';

const BATCH_PAYMENTS_CARD_PROMOTION_PREFERENCE_ID = 'dismissedBatchPaymentCardPromotionBanner';
const DISMISS_VALUE = 'true';

export const useCardPaymentPromotionBanner = (paymentIntents: SchedulePaymentIntent[]) => {
  const [isEnabled] = usePartnerFeature('PromoteCardFundingSource', false);
  const [wasDismissedInSession, setWasDismissedInSession] = useState(false);
  const onlyUnmanagedVendors = paymentIntents.every(({ vendor }) => !vendor.isManaged);

  const { data: { value: preference } = {}, create } = useUserPreference({
    id: BATCH_PAYMENTS_CARD_PROMOTION_PREFERENCE_ID,
    enabled: isEnabled,
  });

  const wasBannerDismissed = wasDismissedInSession || preference === DISMISS_VALUE;

  const dismissCardPromotionBanner = () => {
    setWasDismissedInSession(true);
    create({ userPreference: { key: BATCH_PAYMENTS_CARD_PROMOTION_PREFERENCE_ID, value: DISMISS_VALUE } });
  };

  return {
    isCardPromotionActive: onlyUnmanagedVendors && isEnabled && !wasBannerDismissed,
    dismissCardPromotionBanner,
  };
};
