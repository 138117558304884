import { Group, SectionBanner, Text, useMelioForm } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { DeliveryMethod, InternationalAccountDeliveryMethod, useDeliveryMethods } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { SystemMessageDisplay, useBoolean, useSystemMessage } from '@melio/platform-utils';
import { defaults } from 'lodash';

import { MonitoredAction } from '../../../../../monitoring';
import { DeliveryMethodFlowOrigin } from '../../../../delivery-methods/types';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { SubmissionTargetType } from '../../types';
import { FxBankDetailsForm, useVendorAccountFormSchema } from './fxBankDetailsForm';
import { getCountriesOptions, getRequireFieldsForBankDetails } from './fxBankDetailsForm/fxBankDetailsForm.utils';
import { FxVendorAccountFieldsFormFields } from './fxBankDetailsForm/types';
import { useMigrateInternationalDeliveryMethod } from './useMigrateInternationalDeliveryMethod';
import { useRequireFields } from './useRequireFields';
import { createFxDmFormToRequest } from './utils';
import { VendorLegalDetailsForm } from './vendorLegalDetails/VendorLegalDetailsForm';

type FxVendorAccountActivityProps = {
  onClose: VoidFunction;
  onBack: VoidFunction;
  vendorId: string;
  fxBankDetailsDefaultValues: Partial<FxVendorAccountFieldsFormFields>;
  onDone: (data: InternationalAccountDeliveryMethod, target?: SubmissionTargetType) => void;
  vendorName?: string;
  origin?: DeliveryMethodFlowOrigin;
};

export const FxVendorAccountActivity = ({
  onBack,
  onClose,
  vendorId,
  onDone,
  vendorName,
  fxBankDetailsDefaultValues,
  origin,
}: FxVendorAccountActivityProps) => {
  const { startAction, endAction } = useMonitoring<MonitoredAction>();

  const onCreateDeliveryMethod = (data: DeliveryMethod) => {
    onDone(data as InternationalAccountDeliveryMethod);
  };

  const { track } = useAnalytics();
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const [showFailedTitle, setShowFailedTitle] = useBoolean(false);
  const [showFailedCreationBanner, setShowFailedCreationBanner] = useBoolean(false);
  const getOnCreateErrorToastMessage = (message: string, code: number) => {
    if (code === 400) {
      return (
        message ||
        formatMessage('activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.commons.errors.400-error')
      );
    }
    return formatMessage('activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.commons.errors.general-error');
  };

  const onCreateError = ({ message, code }: { message: string; code?: string }) => {
    const title = getOnCreateErrorToastMessage(message, Number(code));
    track('Vendor', 'Status', {
      Status: 'failure',
      PageName: 'add-international-account-details',
      Intent: 'set-vendor-details',
      ErrorType: `some-thing-went-wrong`,
    });
    showMessage({
      dataTestId: 'fx-vendor-account-creation-failed-toast',
      type: 'error',
      title,
    });
  };

  const onMigrationCreateError = (error: { message: string; code?: string }) => {
    track('Vendor', 'Status', {
      Status: 'failure',
      PageName: 'add-international-account-details',
      Intent: 'set-vendor-details',
      ErrorType: `migration-failed`,
      preFilledData: fxBankDetailsDefaultValues,
      errorMessage: error.message,
    });
    setShowFailedCreationBanner.on();
    setShowFailedTitle.on();
  };

  const { create: createDeliveryMethod, isCreating: isCreatingDM } = useDeliveryMethods({
    vendorId,
    enabled: false,
    onCreateError,
  });

  const { isMigrationLoading } = useMigrateInternationalDeliveryMethod({
    vendorId,
    onCreateError: onMigrationCreateError,
    onCreateDeliveryMethod,
    fxBankDetailsDefaultValues,
  });

  const getDefaultCountry = () => {
    if (fxBankDetailsDefaultValues?.foreignCurrency) {
      const countries = getCountriesOptions(fxBankDetailsDefaultValues.foreignCurrency);
      if (countries.length === 1) {
        return countries[0]?.value;
      }
    }
    return;
  };

  const defaultValues = defaults(fxBankDetailsDefaultValues, {
    legalBusinessName: '',
    line1: '',
    line2: '',
    city: '',
    country: '',
    state: '',
    postalCode: '',
    fxCountrySelection: getDefaultCountry(),
  });

  const onVendorAccountSubmit = (data: FxVendorAccountFieldsFormFields, _event: unknown, target?: string) => {
    setShowFailedCreationBanner.off();

    startAction('fx_account_details_submit');
    track('DeliveryMethod', 'Click', {
      PageName: 'add-international-account-details',
      Intent: 'set-vendor-country',
      Cta: 'Continue',
      Flow: 'add-vendor',
      Country: data.fxCountrySelection,
      Currency: data.foreignCurrency,
      AccountHolder: data.accountHolderName,
      LegalBusinessName: data.legalBusinessName,
      CompanyAddress: data.line1,
      City: data.city,
      CompanyCountry: data.city,
      PostalCode: data.postalCode,
    });
    const requiredFieldsForBankDetails = getRequireFieldsForBankDetails(requiredFields);
    const postRequest = createFxDmFormToRequest(data, requiredFieldsForBankDetails.payeeAccountType);
    createDeliveryMethod(postRequest).then((data) => {
      onDone(data as InternationalAccountDeliveryMethod, target as SubmissionTargetType);
      endAction('fx_account_details_submit');
    });
  };

  const form = useMelioForm<FxVendorAccountFieldsFormFields>({
    schema: useVendorAccountFormSchema(),
    onSubmit: onVendorAccountSubmit,
    defaultValues,
    subscribeToDefaultValuesChanges: true,
  });
  const { cancelButtonProps, submitButtonProps, watch, handleSubmit } = form;

  const onCloseMigrationFailedBanner = () => setShowFailedCreationBanner.off();

  useAnalyticsView('DeliveryMethod', true, true, {
    PageName: 'add-international-account-details',
    Flow: 'add-vendor',
    Intent: 'set-vendor-details',
    IsFxVendor: true,
  });

  const { foreignCurrency: currency } = watch();
  const { requiredFields } = useRequireFields({ currency });

  return (
    <NewSinglePaymentStepLayout
      isLoading={isMigrationLoading}
      data-component="InternationalBankDetailsActivity.FxBankDetailsScreen"
      data-testid="international-bank-details-activity-fx-bank-details-screen"
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton
            onClick={() => {
              onClose();
            }}
          />
          <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        origin === 'vendor' ? (
          <Group variant="vertical" spacing="m" width="full">
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              onClick={handleSubmit?.('continue')}
              label={formatMessage(
                'activities.addVendorBankDetails.screens.fxVendorAccountActivity.actions.vendorOrigin.continue'
              )}
            />
            <NewSinglePaymentStepLayout.SecondaryNextButton
              {...submitButtonProps}
              onClick={handleSubmit?.('close')}
              variant="tertiary"
              label={formatMessage(
                'activities.addVendorBankDetails.screens.fxVendorAccountActivity.actions.vendorOrigin.close'
              )}
            />
          </Group>
        ) : (
          <NewSinglePaymentStepLayout.Actions>
            <NewSinglePaymentStepLayout.NextButton
              {...submitButtonProps}
              isLoading={isCreatingDM}
              label={formatMessage('activities.fxDeliveryMethodActivity.screens.fxVendorAccountForm.continue')}
              data-testid="activities-fx-bankDetails-continue"
            />
          </NewSinglePaymentStepLayout.Actions>
        )
      }
    >
      <SystemMessageDisplay data-testid="fx-vendor-account-creation-failed-toast" />
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.fxDeliveryMethodActivity.screens.fxVendorAccountForm.title', {
          vendorName,
        })}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Content>
        <Group spacing="l" variant="vertical" width="full" alignItems="center">
          {showFailedTitle ? (
            <Text
              data-testid="fx-vendor-account-subTitle-migrationFailed"
              textStyle="body2"
              color="global.neutral.1000"
            >
              {formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxVendorAccountForm.migrationFailed.subTitle'
              )}
            </Text>
          ) : (
            <Text data-testid="fx-vendor-account-subTitle" textStyle="body2" color="global.neutral.1000">
              {formatMessage('activities.fxDeliveryMethodActivity.screens.fxVendorAccountForm.subTitle')}
            </Text>
          )}
          {showFailedCreationBanner && (
            <SectionBanner
              onClose={onCloseMigrationFailedBanner}
              variant="warning"
              showCloseIcon
              data-testid="fx-vendor-account-creation-failed-banner"
              title={formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxVendorAccountForm.failedToCreateBanner.title'
              )}
              description={formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxVendorAccountForm.failedToCreateBanner.description'
              )}
            />
          )}
          <FxBankDetailsForm form={form} defaultValues={defaultValues} />
          <VendorLegalDetailsForm form={form} defaultValues={defaultValues} />
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

FxVendorAccountActivity.displayName = 'FxVendorAccountActivity';
