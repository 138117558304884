import { useAccountWithLogoUrl, useOrganizationsWithLogoUrl } from '@melio/platform-api';

export const useOrganizationSelectWidgetData = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const { data: accountWithLogo, isLoading: isAccountWithLogoLoading } = useAccountWithLogoUrl({ id: 'me', enabled });
  const { data: organizations = [], isLoading: isOrganizationsLoading } = useOrganizationsWithLogoUrl({ enabled });
  return {
    accountWithLogo,
    isLoading: isAccountWithLogoLoading || isOrganizationsLoading,
    organizations,
  };
};
