import { noop } from 'lodash';
import { createContext, useContext, useState } from 'react';

const defaultPageSize = 20;

const Context = createContext<{
  pageSize: number;
  currentPage: number;
  lastVisitedPage: number | null;
  setPreviousPage: VoidFunction;
  setNextPage: VoidFunction;
  resetToFirstPage: VoidFunction;
}>({
  pageSize: defaultPageSize,
  currentPage: 1,
  lastVisitedPage: null,
  setPreviousPage: noop,
  setNextPage: noop,
  resetToFirstPage: noop,
});

export const usePayDashboardPagination = () => useContext(Context);

export const PayDashboardPaginationProvider = ({
  pageSize = defaultPageSize,
  children,
}: {
  pageSize?: number;
  children: React.ReactNode;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisitedPage, setLastVisitedPage] = useState<number | null>(null);

  const setPreviousPage = () =>
    void setCurrentPage((currentPage) => {
      setLastVisitedPage(currentPage);
      return currentPage - 1;
    });
  const setNextPage = () =>
    void setCurrentPage((currentPage) => {
      setLastVisitedPage(currentPage);
      return currentPage + 1;
    });
  const resetToFirstPage = () => {
    setLastVisitedPage(null);
    setCurrentPage(1);
  };

  return (
    <Context.Provider
      value={{
        pageSize,
        currentPage,
        lastVisitedPage,
        setPreviousPage,
        setNextPage,
        resetToFirstPage,
      }}
    >
      {children}
    </Context.Provider>
  );
};
