import { Payment, PaymentStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { usePartnerFeature } from '@melio/platform-provider';

import { PaymentActions } from './types';

const defaultPaymentActions: PaymentActions = {
  edit: false,
  delete: false,
  cancel: false,
  cancelBillSubscription: false,
  isEditBillSubscriptionAllowed: false,
  markAsPaid: false,
  markAsUnpaid: false,
  resolveFailedToCollect: false,
  resolveFailedToDeliver: false,
  resolveRefund: false,
  resolveVoidAndRefund: false,
  resolveVoidAndResend: false,
  approvalDecision: false,
};

export const usePaymentActions = (payment?: Payment): { type: PaymentStatusEnum | null; actions: PaymentActions } => {
  const [isCancelPaymentEnabled] = useDevFeature(FeatureFlags.CancelPayment, false);
  const [isMarkAsPaidEnabled] = usePartnerFeature('MarkAsPaid', false);
  const [isRobinhoodRefundFlowEnabled] = useDevFeature(FeatureFlags.RobinhoodRefundFlow, false);

  if (!payment?.paymentActions) {
    return { type: null, actions: defaultPaymentActions };
  }

  const {
    edit: { eligible: eligibleForEdit },
    delete: { eligible: eligibleForDelete },
    cancel: { eligible: eligibleForCancel },
    cancelBillSubscription: { eligible: eligibleForCancelBillSubscription },
    editBillSubscription: { eligible: eligibleForEditBillSubscription },
    markAsPaid: { eligible: eligibleForMarkAsPaid },
    markAsUnpaid: { eligible: eligibleForMarkAsUnpaid },
    retryCollect: { eligible: eligibleForRetryCollect },
    retryDeliver: { eligible: eligibleForRetryDeliver },
    refund: { eligible: eligibleForRefund },
    voidAndRefund: { eligible: eligibleForVoidAndRefund },
    voidAndResend: { eligible: eligibleForVoidAndResend },
    approvalDecision: { eligible: eligibleForApprovalDecision },
  } = payment.paymentActions;

  return {
    type: payment.status,
    actions: {
      edit: eligibleForEdit,
      delete: eligibleForDelete,
      cancel: eligibleForCancel && isCancelPaymentEnabled,
      cancelBillSubscription: eligibleForCancelBillSubscription && (eligibleForDelete || eligibleForCancel),
      isEditBillSubscriptionAllowed: eligibleForEditBillSubscription,
      markAsPaid: eligibleForMarkAsPaid && isMarkAsPaidEnabled,
      markAsUnpaid: eligibleForMarkAsUnpaid && isMarkAsPaidEnabled,
      resolveFailedToCollect: eligibleForRetryCollect,
      resolveFailedToDeliver: eligibleForRetryDeliver,
      resolveRefund: eligibleForRefund && isRobinhoodRefundFlowEnabled,
      resolveVoidAndRefund: eligibleForVoidAndRefund,
      resolveVoidAndResend: eligibleForVoidAndResend,
      approvalDecision: eligibleForApprovalDecision,
    },
  };
};
