import { InvoiceSummary, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { Footer, InvoiceFormLayout } from '../../../layouts';
import { InvoiceForm, InvoiceFormHeader, InvoiceFormMobileHeader, InvoiceFormProps } from '../components';
import {
  CreateInvoiceSubmitTarget,
  DiscountProps,
  InvoiceTotalAmountLimits,
  ServiceChargesProps,
  TaxRateProps,
} from '../types';
import { InvoiceFormState, InvoiceFormValues } from '../utils';

export type CreateInvoiceScreenProps = {
  onClose: VoidFunction;
  onSubmit: (
    data: InvoiceFormValues,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: CreateInvoiceSubmitTarget
  ) => unknown;
  taxRate?: TaxRateProps;
  discount?: DiscountProps;
  serviceCharges?: ServiceChargesProps;
  isSaving?: CreateInvoiceSubmitTarget;
  isDisabled?: boolean;
  isLoading?: boolean;
  isLoadingSummary?: boolean;
  defaultValues: InvoiceFormProps['defaultValues'];
  error?: ARPlatformError;
  invoiceSummary?: InvoiceSummary;
  onLineItemsChange?: (lineItems: InvoiceFormValues['lineItems']) => unknown;
  onFormStateChange?: (state: InvoiceFormState) => void;
  onCreateCustomer: (value: string) => void;
  onUpdateCustomer: (customerId: string) => void;
  customerId?: string;
  totalAmountLimits?: InvoiceTotalAmountLimits;
};

export const CreateInvoiceScreen = forwardRef<CreateInvoiceScreenProps>(
  (
    {
      defaultValues,
      isSaving,
      isLoading: isLoadingExternalData,
      isLoadingSummary,
      discount = { onEdit: undefined, id: undefined, onRemove: undefined },
      taxRate = { onEdit: undefined, id: undefined },
      serviceCharges = { onAdd: undefined, onRemove: undefined },
      onSubmit,
      error,
      invoiceSummary,
      onLineItemsChange,
      onClose,
      onFormStateChange,
      onCreateCustomer,
      onUpdateCustomer,
      customerId,
      totalAmountLimits,
      isDisabled: _isDisabled,
      ...props
    },
    ref
  ) => {
    const { onSubmissionStateChange, handleSubmit, submitButtonProps } =
      useFormSubmissionController<InvoiceFormValues>();
    const isDisabled = _isDisabled || submitButtonProps?.isDisabled || isLoadingSummary || isLoadingExternalData;

    const { formatMessage } = useMelioIntl();
    const isMobile = useIsMobile();

    useAnalyticsView('Invoice', true, true, {
      ProductName: 'ar',
      PageName: 'create-invoice',
      Flow: 'create-invoice',
      Intent: 'create-invoice',
    });

    return (
      <InvoiceFormLayout
        backgroundColor="white"
        maxWidth="1200px"
        isLoading={isLoadingExternalData}
        onClose={onClose}
        footer={
          <Footer
            primaryButtonProps={{
              label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.buttons.preview.label'),
              isLoading: isSaving == 'saveAndContinue',
              onClick: handleSubmit?.('saveAndContinue'),
              isDisabled: isDisabled || (!!isSaving && isSaving !== 'saveAndContinue'),
            }}
            secondaryButtonProps={{
              label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.buttons.saveAsDraft.label'),
              isLoading: isSaving == 'saveAsDraft',
              onClick: handleSubmit?.('saveAsDraft'),
              isDisabled: isDisabled || (!!isSaving && isSaving !== 'saveAsDraft'),
            }}
            tertiaryButtonProps={
              !isMobile
                ? {
                    label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.buttons.cancel.label'),
                    onClick: onClose,
                    isDisabled: isLoadingExternalData || !!isSaving,
                  }
                : undefined
            }
          />
        }
        data-component={CreateInvoiceScreen.displayName}
        data-testid="create-invoice-screen"
        {...props}
        ref={ref}
        header={
          isMobile ? (
            <InvoiceFormMobileHeader
              headerText={formatMessage('ar.invoiceLifecycle.activities.createInvoice.screens.createForm.header')}
              onBack={onClose}
            />
          ) : (
            <InvoiceFormHeader
              headerText={formatMessage('ar.invoiceLifecycle.activities.createInvoice.screens.createForm.header')}
            />
          )
        }
      >
        <InvoiceForm
          invoiceSummary={invoiceSummary}
          discount={discount}
          taxRate={taxRate}
          serviceCharges={serviceCharges}
          onSubmissionStateChange={onSubmissionStateChange}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          isSaving={!!isSaving}
          isLoading={isLoadingExternalData}
          isLoadingSummary={isLoadingSummary}
          onLineItemsChange={onLineItemsChange}
          onFormStateChange={onFormStateChange}
          error={error}
          onCreateCustomer={onCreateCustomer}
          onUpdateCustomer={onUpdateCustomer}
          customerId={customerId}
          totalAmountLimits={totalAmountLimits}
          mode="create"
        />
      </InvoiceFormLayout>
    );
  }
);
CreateInvoiceScreen.displayName = 'CreateInvoiceScreen';
