import { useFundingSourceIconKey } from '@melio/ap-widgets';
import {
  BrandSymbol,
  BrandSymbolKey,
  Container,
  getBrandSymbolsMap,
  Group,
  Icon,
  IconKey,
  ListItem,
} from '@melio/penny';
import { FundingSource, FundingSourceType } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

export type FundingSourceFlatContainerCardProps = {
  fundingSource: FundingSource;
};

const fsTypeToItemLabel = (fundingSource: FundingSource): MessageKey => {
  switch (fundingSource.type) {
    case FundingSourceType.Card:
      return 'activities.reconciliationModalScreen.cardAccount.label';
    case FundingSourceType.BankAccount:
    default:
      return 'activities.reconciliationModalScreen.bankAccount.label';
  }
};

export const FundingSourceFlatContainerCard = forwardRef<FundingSourceFlatContainerCardProps, 'div'>(
  ({ fundingSource, ...props }, ref) => {
    const fundingSourceIconType = useFundingSourceIconKey(fundingSource);
    const { formatMessage } = useMelioIntl();
    const {
      settings: { partnerProductName },
    } = useConfig();

    // TODO: replace with utility from @melio/platform-api - https://meliorisk.atlassian.net/browse/ME-24864
    const isBrandSymbol = Object.keys(getBrandSymbolsMap('')).includes(fundingSourceIconType as BrandSymbolKey);

    const icon = isBrandSymbol ? (
      <BrandSymbol type={fundingSourceIconType as BrandSymbolKey} />
    ) : (
      <Icon type={fundingSourceIconType as IconKey} />
    );

    const labelKey = fsTypeToItemLabel(fundingSource);
    return (
      <Container
        border="regular"
        paddingX="s"
        paddingY="s"
        data-component="FundingSourceFlatContainerCard"
        data-testid={`funding-source-card-flat-container-${fundingSource.id}`}
        {...props}
        ref={ref}
      >
        <Group alignItems="center">
          {icon}
          <ListItem
            data-testid={`funding-source-card-flat-container-${fundingSource.id}-list-item`}
            mainLabelProps={{
              label: fundingSource.displayName,
              variant: 'bold',
            }}
            labelProps={{
              label: formatMessage(labelKey, {
                productName: partnerProductName,
              }),
            }}
          />
        </Group>
      </Container>
    );
  }
);

FundingSourceFlatContainerCard.displayName = 'FundingSourceFlatContainerCard';
