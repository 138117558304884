import {
  useGetFundingSourceContent,
  useGetFundingSourceHelperText,
  useGetFundingSourceLabel,
  usePaymentProcessingInfo,
  usePaymentSchedulingPreference,
  useSectionBadge,
} from '@melio/ap-domain';
import { Group, Text } from '@melio/penny';
import { ApprovalRequirementStatus, DeliveryMethod, FundingSource } from '@melio/platform-api';
import { useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { useFundingSourceIconKey } from '../../../../hooks/fundingSource.hooks';
import { isCreditCardFundingSource } from '../../../../utils';
import { PaymentReviewLineItem } from '../../../PaymentReviewLineItem';
import { getFundingSourceImageProps } from '../../../utils';
import { getScheduleDateDescriptionLabel } from './utils';

export type ReviewAndConfirmPayFromSectionProps = {
  fundingSource: FundingSource;
  deliveryMethod: DeliveryMethod;
  onEditFundingSource?: VoidFunction;
  scheduledDate: Date;
  onEditScheduledDate?: VoidFunction;
  updatedFields?: string[];
  isRecurringPayment?: boolean;
  approvalRequirementStatus?: ApprovalRequirementStatus;
};

export const ReviewAndConfirmPayFromSection = forwardRef<ReviewAndConfirmPayFromSectionProps>(
  (
    {
      fundingSource,
      deliveryMethod,
      scheduledDate,
      onEditFundingSource,
      onEditScheduledDate,
      updatedFields,
      isRecurringPayment = false,
      approvalRequirementStatus,
      ...props
    },
    ref
  ) => {
    const { formatMessage, formatDateTimeRange, formatDate } = useMelioIntl();
    const { isByScheduleDate } = usePaymentSchedulingPreference();
    const { isPaymentProcessedByCapitalOne, calculateExpiryDate } = usePaymentProcessingInfo();
    const [isFofEnabled] = usePartnerFeature('FofImprovements', false);
    const fsIcon = useFundingSourceIconKey(fundingSource);

    const fundingSourceImage = getFundingSourceImageProps(fundingSource);
    const descriptionLabel = getScheduleDateDescriptionLabel({
      deliveryMethodType: deliveryMethod.type,
      approvalRequirementStatus,
      isRecurringPayment,
      isFofEnabled,
    });

    const fundingSourceIconType = !fundingSourceImage && fsIcon;
    const lineItemIcon = fundingSourceImage
      ? { imageIcon: fundingSourceImage }
      : fundingSourceIconType
      ? { icon: { type: fundingSourceIconType } }
      : null;
    const scheduledDateLabel = isPaymentProcessedByCapitalOne(deliveryMethod?.type)
      ? formatDateTimeRange(scheduledDate, calculateExpiryDate(scheduledDate), {
          dateStyle: 'medium',
        })
      : formatDate(scheduledDate, { dateStyle: 'medium' });

    return (
      <Group
        variant="vertical"
        spacing="s"
        data-component="ReviewAndConfirmPayFromSection"
        data-testid="ReviewAndConfirmPayFromSection"
        {...props}
        ref={ref}
      >
        <Text textStyle="body4SemiUpper" color="global.neutral.900">
          {formatMessage('widgets.reviewAndConfirm.payFromSection.title')}
        </Text>
        <Group variant="vertical" spacing="m" hasDivider>
          <Group variant="vertical">
            <PaymentReviewLineItem
              labelProps={{ label: useGetFundingSourceLabel(fundingSource) }}
              mainLabelProps={{
                label: useGetFundingSourceContent(fundingSource),
                pillProps: useSectionBadge({
                  isShowUpdatedBadge: !!updatedFields?.includes('fundingSourceId'),
                  isDueDatePast: false,
                }),
              }}
              descriptionProps={{ label: useGetFundingSourceHelperText(fundingSource) }}
              {...lineItemIcon}
              onEdit={onEditFundingSource}
              editText={formatMessage('widgets.reviewAndConfirm.payFromSection.fundingSource.edit')}
              editTestId="review-and-confirm-funding-source-edit-button"
              contentTestId="review-and-confirm-funding-source"
            />
            <PaymentReviewLineItem
              labelProps={{
                label: formatMessage(
                  `widgets.reviewAndConfirm.payFromSection.scheduledDate.label.${
                    isCreditCardFundingSource(fundingSource) ? 'creditCardFundingType' : 'default'
                  }`
                ),
              }}
              mainLabelProps={{
                label: scheduledDateLabel,
                pillProps: useSectionBadge({
                  isShowUpdatedBadge: !!updatedFields?.includes('scheduledDate'),
                  isDueDatePast: false,
                }),
              }}
              descriptionProps={{
                label: formatMessage(descriptionLabel, {
                  scheduledDate: formatDate(scheduledDate, { month: 'short', day: 'numeric' }),
                }),
              }}
              icon={{ type: 'scheduled' }}
              {...(isByScheduleDate && {
                onEdit: onEditScheduledDate,
              })}
              editText={formatMessage('widgets.reviewAndConfirm.payFromSection.scheduledDate.edit')}
              editTestId="review-and-confirm-scheduled-date-edit-button"
              contentTestId="review-and-confirm-scheduled-date"
            />
          </Group>
        </Group>
      </Group>
    );
  }
);

ReviewAndConfirmPayFromSection.displayName = 'ReviewAndConfirmPayFromSection';
