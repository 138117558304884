import { Container, Group, NakedButton } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { TabFilter } from '../components/Filters/types';
import { PayDashboardFilterType } from '../types';
import { FilterChip } from './FilterChip';
import { FilterChipProps } from './FilterChip.types';

export const FilterChips = ({
  filterContext,
  tabFilters,
}: {
  filterContext?: PayDashboardFilterType;
  tabFilters: TabFilter<string>[];
}) => {
  const { formatMessage } = useMelioIntl();

  if (!filterContext?.filters || Object.keys(filterContext.filters).length === 0) {
    return null;
  }

  const filters: FilterChipProps[] = Object.entries(filterContext.filters).map(([filterKey, filterValue]) => {
    const tabFilter = tabFilters.find((f) => f.key === filterKey);
    const tabFilterOptions = tabFilter?.options ?? [];
    return {
      label: tabFilter?.label ?? '',
      id: tabFilter?.key ?? '',
      values:
        filterValue?.map(
          (value) => tabFilterOptions.find((tabFilterOption) => tabFilterOption.value === value)?.label ?? value
        ) ?? [],
      onDeleteFilter: () => {
        filterContext.applyFilter(filterKey, []);
      },
    };
  });

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore this is a temp implementation until Penny will do it
    <Group as="ul" spacing="xs" flexWrap="wrap">
      {filters.map((filter) => (
        <FilterChip {...filter} key={filter.id} />
      ))}
      <Group alignItems="center">
        <Container paddingX="xs">
          <NakedButton
            data-testid="clear-filters"
            size="medium"
            variant="secondary"
            label={formatMessage('activities.payDashboard.filterChip.clearFilters')}
            onClick={filterContext.resetFilters}
          />
        </Container>
      </Group>
    </Group>
  );
};
