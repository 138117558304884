import { merge } from 'lodash';

import { fiservConfigBase } from './fiserv.config';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

export const fiservCitizensConfigBase: PartnerConfigByEnv = merge<PartnerConfigByEnv>({}, fiservConfigBase, {
  default: {
    settings: {
      newPayExperience: {
        firstTimeExperience: {
          assetType: 'image',
          imageUrl: 'https://platform-static.meliopayments.com/assets/citizens/illustrations/first-time-experience.png',
          mobileImageUrl:
            'https://platform-static.meliopayments.com/assets/citizens/illustrations/first-time-experience.png',
          videoLink: '',
        },
      },
    },
  },
  dev: {},
  prod: {},
});

export const fiservCitizensConfig = extendDefaultConfig(fiservCitizensConfigBase);
