import { Group } from '@melio/penny';
import { Color, ColorArea, ColorSlider, ColorThumb, SliderTrack } from 'react-aria-components';

export const ColorPickerArea = ({
  isMobile,
  color,
  onChange,
  onChangeEnd,
}: {
  isMobile: boolean;
  color: Color;
  onChange: (value: Color) => void;
  onChangeEnd: (value: Color) => void;
}) => (
  <Group width="full" variant="vertical" height="full" spacing={isMobile ? 's' : undefined}>
    <ColorArea
      style={{
        width: '85%',
        height: '168px',
        margin: isMobile ? undefined : 'auto',
        borderRadius: '4px',
        maxWidth: '168px',
        minWidth: '130px',
      }}
      value={color}
      onChange={onChange}
      onChangeEnd={onChangeEnd}
      xChannel="saturation"
      yChannel="lightness"
    >
      <ColorThumb
        data-testid="color-area-thumb"
        style={{
          width: '20px',
          height: '20px',
          border: '2px solid white',
          boxShadow: '0 0 0 1px black, inset 0 0 0 1px black',
          borderRadius: '50%',
        }}
      />
    </ColorArea>
    <ColorSlider
      style={{ margin: isMobile ? undefined : 'auto', width: '85%', maxWidth: '168px', minWidth: '130px' }}
      channel="hue"
      value={color}
      onChange={onChange}
      onChangeEnd={onChangeEnd}
    >
      <SliderTrack style={{ height: '28px', borderRadius: '4px' }}>
        <ColorThumb
          style={{
            top: '50%',
            width: '20px',
            height: '20px',
            border: '2px solid white',
            boxShadow: '0 0 0 1px black, inset 0 0 0 1px black',
            borderRadius: '50%',
          }}
        />
      </SliderTrack>
    </ColorSlider>
  </Group>
);
