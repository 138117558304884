import {
  GetContractorsInfo1099Response,
  useOrganizationPreference,
  useVendorsContractorsInfo1099,
} from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { useDateUtils } from '@melio/platform-utils';

export const useSeason1099SectionBanner = ({
  isImport1099ContractorsEnabled,
}: {
  isImport1099ContractorsEnabled: boolean;
}) => {
  const { can } = usePermissions();
  const { createDate } = useDateUtils();
  const { data: contractorsInfo1099 = {}, isLoading } = useVendorsContractorsInfo1099({
    enabled: isImport1099ContractorsEnabled,
  });

  const { totalContractors1099Count = 0 }: GetContractorsInfo1099Response['data'] = contractorsInfo1099 || {};

  const userHasEligibleRole = can({
    subject: 'report:zenworkSync',
    action: 'create',
  });

  const { data: nextSeason1099StartDate, isLoading: isLoadingOrgPreferences } = useOrganizationPreference({
    enabled: isImport1099ContractorsEnabled,
    id: 'nextSeason1099StartDate',
    retryOnMount: false,
  });

  const isBannerVisible =
    isImport1099ContractorsEnabled &&
    !!totalContractors1099Count &&
    !!userHasEligibleRole &&
    !isLoadingOrgPreferences &&
    !isLoading &&
    (!nextSeason1099StartDate || nextSeason1099StartDate <= createDate().toISOString());

  return {
    isBannerVisible,
    isLoading: isLoadingOrgPreferences || isLoading,
  };
};
