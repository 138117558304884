import { Route, Routes } from 'react-router-dom';

import {
  AccountingFirmClientMandatoryPlanRestriction,
  AccountingFirmClientMandatoryPlanRestrictionProps,
  GraceEndPeriodNonOwner,
  GraceEndPeriodOwner,
} from '../ui';

type SubscriptionGraceRouteProps = AccountingFirmClientMandatoryPlanRestrictionProps;

export const SubscriptionGraceRoute = ({ goToAssignClientPlan, switchOrganization }: SubscriptionGraceRouteProps) => (
  <Routes>
    <Route path="/select-your-plan" element={<GraceEndPeriodOwner />} />
    <Route path="/plan-is-required" element={<GraceEndPeriodNonOwner />} />
    <Route
      path="/select-client-plan"
      element={
        <AccountingFirmClientMandatoryPlanRestriction
          goToAssignClientPlan={goToAssignClientPlan}
          switchOrganization={switchOrganization}
        />
      }
    />
  </Routes>
);
