import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';

import { RecurringPaymentDetails } from '../../types';

type UseScreenDescriptionProps = {
  vendor: Vendor;
  paymentAmount: number;
  currency?: string;
  recurringPaymentDetails?: RecurringPaymentDetails;
};

export const useScreenDescription = ({
  vendor,
  paymentAmount,
  currency,
  recurringPaymentDetails,
}: UseScreenDescriptionProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const [isFofImprovementsEnabled] = usePartnerFeature('FofImprovements', false);

  if (!recurringPaymentDetails) {
    // if this is the first time the user is making a payment to this vendor, show a different description
    if (isFofImprovementsEnabled && !vendor.lastPaymentDate) {
      return formatMessage('activities.fundingSourceSelection.screens.fundingSourceSelection.description.firstTime');
    }

    return formatMessage('activities.fundingSourceSelection.screens.fundingSourceSelection.description.general');
  }

  const frequency = formatMessage(
    `activities.fundingSourceSelection.screens.fundingSourceSelection.descriptionRecurring.frequency.${recurringPaymentDetails.paymentFrequency}`
  );

  const isEditingSingleRecurringPayment = recurringPaymentDetails.occurrenceNumber;
  const hasEnd = recurringPaymentDetails.numOfOccurrences;
  const isManagedBillSubscription = recurringPaymentDetails.managedBy;
  const isManagedVendor = vendor.isManaged;

  if (!isEditingSingleRecurringPayment) {
    return formatMessage(
      'activities.fundingSourceSelection.screens.fundingSourceSelection.descriptionCreateRecurring',
      {
        total: formatCurrency(paymentAmount, currency),
        name: vendor.name,
        frequency,
      }
    );
  }

  if (isManagedBillSubscription && isManagedVendor) {
    return formatMessage(
      'activities.fundingSourceSelection.screens.fundingSourceSelection.descriptionEditRecurringPreviousVersion'
    );
  }
  if (hasEnd) {
    return formatMessage(
      'activities.fundingSourceSelection.screens.fundingSourceSelection.descriptionEditRecurringWithEnd',
      {
        total: formatCurrency(paymentAmount, currency),
        name: vendor.name,
        occurrenceNumber: recurringPaymentDetails.occurrenceNumber,
        numOfOccurrences: recurringPaymentDetails.numOfOccurrences,
      }
    );
  }
  return formatMessage(
    'activities.fundingSourceSelection.screens.fundingSourceSelection.descriptionEditRecurringNoEnd',
    {
      total: formatCurrency(paymentAmount, currency),
      name: vendor.name,
    }
  );
};
