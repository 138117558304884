import { BrandSymbol, BrandSymbolKey, getBrandSymbolsMap, Icon, IconKey } from '@melio/penny';
import React from 'react';

export function DeliveryMethodIcon({ iconType }: { iconType: IconKey | BrandSymbolKey }) {
  return Object.keys(getBrandSymbolsMap('')).includes(iconType as BrandSymbolKey) ? (
    <BrandSymbol type={iconType as BrandSymbolKey} />
  ) : (
    <Icon type={iconType as IconKey} />
  );
}
