import { Route, Routes, useSearchParams } from 'react-router-dom';

import { Import1099ContractorsScreen } from '@/screens/import-1099-contractors/Import1099Contractors.screen';
import { Import1099ContractorsError } from '@/screens/import-1099-contractors/Import1099ContractorsError.screen';

export const Import1099ContractorsRoute = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  return (
    <Routes>
      <Route path="/" element={<Import1099ContractorsScreen authorizationCode={code} />} />
      <Route path="error" element={<Import1099ContractorsError />} />
    </Routes>
  );
};
