import { useAccountingPlatformName } from '@melio/ap-widgets/src/components/funding-sources';
import { accountingSoftwareSlugToBrandSymbolType } from '@melio/ap-widgets/src/components/SyncNow';
import { BrandSymbol, Link, SectionBanner } from '@melio/penny';
import { EventProperties, useAnalytics } from '@melio/platform-analytics';
import { useAccountingPlatforms, useUserHasPayments, useUserPreference } from '@melio/platform-api';
import { useConfig, useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { useState } from 'react';

import { useActivitiesNavigate } from '../../utils';

const SYNC_PROMOTION_PREFERENCE_ID = 'dismissedAccountingPlatformSyncPromotionBanner';
const DISMISS_VALUE = 'true';

const analyticsUserMessage = 'connect-your-quickbooks-online-account';

export const useShouldPromoteAccountingSoftwareSync = () => {
  const [isEnabled] = usePartnerFeature('PromoteAccountingSoftwareSync', false);

  return isEnabled;
};

export const usePromoteAccountingPlatformSlug = () => {
  const accountingSlug = useConfig().settings.PromoteAccountingSoftwareSync.accountingSoftwareSlug;
  const accountingPlatformName = useAccountingPlatformName(accountingSlug);

  return {
    accountingSlug,
    accountingPlatformName,
  };
};

export type PromoteAccountingSoftwareSyncBannerProps = {
  variant: (typeof VARIANT)[keyof typeof VARIANT];
  'data-testid'?: string;
  analyticsProps: PromoteAccountingSoftwareSyncAnalyticsProperties;
};

type PromoteAccountingSoftwareSyncAnalyticsProperties = {
  EntryPoint?: string;
  Flow?: string;
  PageName: string;
} & EventProperties;

const VARIANT = {
  DASHBOARD: 'dashboard',
  VENDOR: 'vendor',
  PAYMENT_SCHEDULED: 'payment-scheduled',
} as const;

export const useShowPromoteAccountingSoftwareSyncBanner = ({
  variant,
}: {
  variant: (typeof VARIANT)[keyof typeof VARIANT];
}) => {
  const isFeatureEnabled = useShouldPromoteAccountingSoftwareSync();

  const { hasAccountingPlatform } = useAccountingPlatforms({ enabled: isFeatureEnabled });

  const { data: { value: preference } = {} } = useUserPreference({
    id: SYNC_PROMOTION_PREFERENCE_ID,
    enabled: isFeatureEnabled,
  });
  const wasBannerDismissed = variant !== VARIANT.PAYMENT_SCHEDULED && preference === DISMISS_VALUE;

  const { userHasPayments } = useUserHasPayments({ enabled: isFeatureEnabled });
  const isEmptyState = variant === VARIANT.VENDOR ? false : !userHasPayments;

  const showBanner = !isEmptyState && isFeatureEnabled && !wasBannerDismissed && !hasAccountingPlatform;

  return {
    showBanner,
    viewAnalyticsProps: showBanner
      ? {
          UserMessage: analyticsUserMessage,
        }
      : {},
  };
};

const getDescriptionTextId = (variant: PromoteAccountingSoftwareSyncBannerProps['variant']) => {
  switch (variant) {
    case VARIANT.PAYMENT_SCHEDULED:
      return 'widgets.promoteAccountingSoftwareSyncBanner.description';
    case VARIANT.VENDOR:
      return 'widgets.promoteAccountingSoftwareSyncBanner.descriptionVendor';
    case VARIANT.DASHBOARD:
    default:
      return 'widgets.promoteAccountingSoftwareSyncBanner.descriptionAll';
  }
};

const getTitleTextId = (variant: PromoteAccountingSoftwareSyncBannerProps['variant']) => {
  switch (variant) {
    case VARIANT.PAYMENT_SCHEDULED:
      return 'widgets.promoteAccountingSoftwareSyncBanner.title';
    case VARIANT.VENDOR:
    case VARIANT.DASHBOARD:
    default:
      return 'widgets.promoteAccountingSoftwareSyncBanner.titleAll';
  }
};

export const PromoteAccountingSoftwareSyncBanner = ({
  variant,
  'data-testid': testId,
  analyticsProps,
}: PromoteAccountingSoftwareSyncBannerProps) => {
  const { showBanner } = useShowPromoteAccountingSoftwareSyncBanner({ variant });
  const { formatMessage } = useMelioIntl();
  const { accountingSlug, accountingPlatformName } = usePromoteAccountingPlatformSlug();
  const { track } = useAnalytics();
  const { navigateToSettingsAccountingPlatform } = useActivitiesNavigate();
  const { create } = useUserPreference({
    id: SYNC_PROMOTION_PREFERENCE_ID,
    enabled: showBanner,
  });
  const [isSavingUserPreference, setIsSavingUserPreference] = useState(false);

  const dismissBanner = async () => {
    track('SyncAccountingSoftware', 'Click', {
      Cta: 'disable-user-message',
      UserMessage: analyticsUserMessage,
      Intent: 'disable-accounting-software-sync-massage',
      ...analyticsProps,
    });
    setIsSavingUserPreference(true);
    await create({ userPreference: { key: SYNC_PROMOTION_PREFERENCE_ID, value: DISMISS_VALUE } });
  };

  const handleConnectAndSync = () => {
    track('SyncAccountingSoftware', 'Click', {
      Cta: 'connect-and-sync',
      UserMessage: analyticsUserMessage,
      Intent: 'accounting-software-sync',
      ...analyticsProps,
    });
    navigateToSettingsAccountingPlatform();
  };

  if (!showBanner || isSavingUserPreference || !accountingSlug) {
    return null;
  }

  const brandSymbolType = accountingSlug && accountingSoftwareSlugToBrandSymbolType[accountingSlug];

  return (
    <SectionBanner
      data-component="PromoteAccountingSoftwareSyncBanner"
      data-testid={testId ?? 'promote-accounting-software-sync-banner'}
      variant="secondary"
      title={formatMessage(getTitleTextId(variant), {
        accountingPlatformName,
      })}
      description={formatMessage(getDescriptionTextId(variant), {
        accountingPlatformName,
      })}
      leftElement={brandSymbolType && <BrandSymbol type={brandSymbolType} />}
      onClose={dismissBanner}
      showCloseIcon={variant !== VARIANT.PAYMENT_SCHEDULED}
      action={
        <Link
          href="#"
          onClick={handleConnectAndSync}
          label={formatMessage('widgets.promoteAccountingSoftwareSyncBanner.cta')}
          data-testid="promote-accounting-software-sync-banner-cta"
        />
      }
    />
  );
};

PromoteAccountingSoftwareSyncBanner.displayName = 'PromoteAccountingSoftwareSyncBanner';
