export const DEFAULT_DASHBOARD_ROUTE = '/pay-dashboard';

export const PAYMENT_METHOD_BANK_SELECT_URL_ROUTE = '/payment-methods/bank/select';

export const SETTINGS_SUBSCRIPTION_PLANS_ROUTE = '/settings/subscription-plans';
export const SETTINGS_COLLABORATORS_ROUTE = '/settings/collaborators';
export const SETTINGS_SUPPORT_ROUTE = '/settings/support';

export const SUBSCRIPTION_PAYMENT_METHOD_SELECT_ROUTE = `/subscription/payment-method/select`;
export const SUBSCRIPTION_PAYMENT_METHOD_SELECT_SUCCESS_ROUTE = `/subscription/payment-method/select/success`;
export const SUBSCRIPTION_PAYMENT_METHOD_SELECT_ERROR_ROUTE = `/subscription/payment-method/select/error`;

export const SUBSCRIPTION_ACCOUNTING_CLIENT_PLAN_SELECTED_ROUTE = `/subscription/accounting-client-plan-selected`;
export const SUBSCRIPTION_OWNER_SHOULD_SETUP_ROUTE = `/subscription/owner-need-to-finish-setting-up`;

export const SUBSCRIPTION_PLANS_ROUTE = `/subscription/plans`;
export const SUBSCRIPTION_CHECKOUT_ROUTE = `/subscription/checkout`;
export const SUBSCRIPTION_SUCCESS_ROUTE = `/subscription/success`;

export const SUBSCRIPTION_GRACE_SELECT_YOUR_PLAN_ROUTE = `/subscription/grace/select-your-plan`;
export const SUBSCRIPTION_GRACE_PLAN_IS_REQUIRED_ROUTE = `/subscription/grace/plan-is-required`;
export const SUBSCRIPTION_GRACE_SELECT_CLIENT_PLAN_ROUTE = `/subscription/grace/select-client-plan`;

export const SUBSCRIPTION_DOWNGRADE_CLIENT_BASIC_PLAN_ROUTE = `/subscription/downgrade/client-basic-plan`;
export const SUBSCRIPTION_DOWNGRADE_CONFIRM_DOWNGRADE_ROUTE = `/subscription/downgrade/confirm-downgrade`;
export const SUBSCRIPTION_DOWNGRADE_ACTION_REQUIRED_ROUTE = `/subscription/downgrade/action-required`;

export const ADD_NEW_COMPANY_ROUTE = '/companies/new-company';
export const COMPLETE_DETAILS_ROUTE = '/complete-details';
