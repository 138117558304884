import { Group, Link, Modal, Text, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';

export type CheckProtectionModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export const CheckProtectionModalWidget: React.VFC<CheckProtectionModalProps> = ({ isOpen, onClose }) => {
  const { formatMessage } = useMelioIntl();
  const { links } = useConfig();
  const [isFofEnabled] = usePartnerFeature('FofImprovements', false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      data-component="CheckProtectionModalWidget"
      data-testid="check-protection-modal-widget"
      header={formatMessage('activities.checkProtectionModal.header')}
      primaryButton={{
        onClick: onClose,
        variant: 'primary',
        label: formatMessage('activities.checkProtectionModal.close'),
      }}
    >
      <Group variant="vertical">
        <Text>{formatMessage('activities.checkProtectionModal.description')}</Text>
        <Typography.ParagraphList
          type="unordered"
          size="large"
          list={[
            {
              title: formatMessage('activities.checkProtectionModal.securing.title'),
              content: formatMessage('activities.checkProtectionModal.securing.content', {
                link: isFofEnabled ? (
                  <Link
                    data-testid="check-protection-modal-external-link"
                    href={links['activities.checkProtectionModal.securing.link']}
                    label={formatMessage('activities.checkProtectionModal.securing.linkText')}
                    newTab
                  />
                ) : undefined,
              }),
              icon: 'shield',
            },
            {
              title: formatMessage('activities.checkProtectionModal.monitoring.title'),
              content: formatMessage('activities.checkProtectionModal.monitoring.content'),
              icon: !isFofEnabled ? 'monitor' : 'pay',
            },
            {
              title: formatMessage('activities.checkProtectionModal.handling.title'),
              content: formatMessage('activities.checkProtectionModal.handling.content'),
              icon: 'path',
            },
          ]}
        />
      </Group>
    </Modal>
  );
};

CheckProtectionModalWidget.displayName = 'CheckProtectionModal';
