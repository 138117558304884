import { Box } from '@chakra-ui/react';
import { Button, Container, Form, Group, Link, SectionBanner } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Plan } from '@melio/subscriptions';
import { useGetOffer } from '@melio/subscriptions/src/api';
import { useLearnMoreLink, usePlansTiers } from '@melio/subscriptions/src/hooks';

import { AccountingClientPlanSelectionStepDiscountBanner } from '../../../../components/AccountingClientPlanSelectionStepDiscountBanner/AccountingClientPlanSelectionStepDiscountBanner';
import { FIRM_DISCOUNT_PERCENTAGE_DEFAULT } from '../../../../consts';
import { useFocusStepOnError } from '../../../../hooks/useFocusStepOnError';
import { FirmClientSubscriptionPlanSelectionActivity } from '../../../FirmClientSubscriptionPlanSelection';
import {
  AssignFirmClientPlanFormField,
  AssignFirmClientPlanFormFields,
  AssignFirmClientPlanFormStepComponent,
} from '../../types';
import { useAssignFirmClientPlanFormContext } from '../AssignFirmClientPlanForm/AssignFirmClientPlanFormFormContext';

const stepFields: Array<AssignFirmClientPlanFormField> = ['subscriptionPlanId'];

export const AssignFirmClientPlanSelectionStep: AssignFirmClientPlanFormStepComponent = ({
  submitLabel,
  focusErrorStep,
  onContinue,
  isSaving,
}) => {
  const { formState, setValue, watch, registerField, submitButtonProps } = useAssignFirmClientPlanFormContext();
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const learnMoreLink = useLearnMoreLink();

  const selectedPlanId = watch('subscriptionPlanId');
  const clientPlans = usePlansTiers().getClientPlans();
  const offedId = clientPlans.find((plan) => plan.id === selectedPlanId)?.offerId || clientPlans[0]?.offerId;
  const { data: activePlanOffer, isLoading: isActiveOfferLoading } = useGetOffer({
    id: offedId || '',
  });
  const planDiscountPercentage = activePlanOffer?.integratedDiscount || FIRM_DISCOUNT_PERCENTAGE_DEFAULT;

  useFocusStepOnError<AssignFirmClientPlanFormFields>({
    formState,
    stepFields,
    focusErrorStep,
  });

  const validationErrorMessage = formState.errors.subscriptionPlanId?.message;

  const verifyPlanSelection = () => {
    onContinue();
  };

  const handleSelectSubscriptionPlanId = ({ id: planId, tier }: Plan) => {
    track('Organization', 'Click', {
      Cta: 'subscribe',
      Intent: 'subscribe',
      PlanChosenId: planId,
      PlanChosen: tier,
      BillingCycle: 'monthly',
    });
    setValue('subscriptionPlanId', planId);
  };

  return (
    <Group spacing="m" variant="vertical" width="full">
      <Box hidden>
        <Form.TextField isRequired type="hidden" {...registerField('subscriptionPlanId')} />
      </Box>
      {validationErrorMessage && (
        <SectionBanner
          data-testid="plan-selection-validation-error"
          description={validationErrorMessage}
          variant="critical"
        />
      )}
      <AccountingClientPlanSelectionStepDiscountBanner
        discountPercent={planDiscountPercentage}
        isLoading={isActiveOfferLoading}
      />
      {!isActiveOfferLoading && (
        <FirmClientSubscriptionPlanSelectionActivity
          selectedSubscriptionPlanId={selectedPlanId}
          onSelect={handleSelectSubscriptionPlanId}
          isSaving={isSaving}
          discountPercentage={planDiscountPercentage}
        />
      )}
      <Container>
        <Link
          label={formatMessage('activities.accountants.newClient.form.plan.viewAllFeatures')}
          href={learnMoreLink}
          color="secondary"
          size="medium"
          newTab
        />
      </Container>
      <Container justifyContent="flex-end">
        <Button
          variant="primary"
          size="medium"
          label={submitLabel}
          data-testid="plan-selection-continue-button"
          {...submitButtonProps}
          onClick={verifyPlanSelection}
        />
      </Container>
    </Group>
  );
};
