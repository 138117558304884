import {
  _createFormFieldInput as createFormFieldInput,
  _CustomInputContainer as CustomInputContainer,
  Control,
} from '@melio/penny';
import { FieldError } from 'react-hook-form';

import { CaratFormWidgetFields } from './types';

const CaratFormField = createFormFieldInput(CustomInputContainer);

type CaratFormInputFieldProps = {
  control: Control<CaratFormWidgetFields>;
  name: string;
  id: string;
  'data-focus': boolean;
  labelProps: { label: string };
  placeholder: string;
  'data-invalid': boolean;
  error: FieldError | undefined;
  isRequired: boolean;
  colSpan: number;
};

export const CaratFormInputField = ({
  control,
  id,
  name,
  'data-focus': isFocus,
  labelProps,
  placeholder,
  'data-invalid': dataInvalid,
  error,
  isRequired,
  colSpan,
}: CaratFormInputFieldProps) => (
  <CaratFormField
    control={control}
    id={id}
    name={name}
    data-focus={isFocus || void 0}
    colSpan={colSpan}
    role="presentation"
    aria-describedby={undefined}
    style={{ paddingBottom: '0' }}
    labelProps={labelProps}
    placeholder={placeholder}
    data-invalid={dataInvalid || void 0}
    error={error}
    isRequired={isRequired}
  />
);
