import { Icon, Table, Tooltip } from '@melio/penny';
import { InboxItemBillTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isEmpty } from 'lodash';

import { BillsTabItem } from '../types';
import { getFileId } from './BillsTabOriginCell';

export const BillsTabInvoiceCell = ({ billsTabItem }: { billsTabItem: BillsTabItem }) => {
  const { formatMessage } = useMelioIntl();
  let filesIds: (string | null)[] = [];
  if (billsTabItem.type === InboxItemBillTypeEnum.Bill) {
    if (billsTabItem.payload.invoice.filesIds) {
      filesIds = billsTabItem.payload.invoice.filesIds;
    }
  } else {
    filesIds[0] = getFileId(billsTabItem);
  }

  return (
    <Table.Cell data-testid={`table-row-${billsTabItem.payload.id}-invoice-cell`}>
      {!isEmpty(filesIds) && (
        <Tooltip
          data-testid={`table-row-${billsTabItem.payload.id}-invoice-cell-tooltip`}
          content={formatMessage('activities.payDashboard.invoice.tooltip', {
            fileCount: filesIds.length || 1,
          })}
          shouldAddTriggerFocus
        >
          <Icon type="invoices" size="small" color="inherit" aria-hidden="true" isReadOnly />
        </Tooltip>
      )}
    </Table.Cell>
  );
};
