import { useAccount } from '@melio/platform-api';
import { useMemo } from 'react';

import {
  SUBSCRIPTION_DOWNGRADE_ACTION_REQUIRED_ROUTE,
  SUBSCRIPTION_DOWNGRADE_CONFIRM_DOWNGRADE_ROUTE,
} from '../router/routes.consts';

export const useConfirmSubscriptionDowngradeRedirectRoute = () => {
  const { data: dataAccountMe, isLoading: isAccountLoading } = useAccount({ id: 'me' });

  const confirmDowngradeRedirectRoute = useMemo(() => {
    if (isAccountLoading || !dataAccountMe) {
      return { isLoading: true, redirectUrl: null };
    }
    const isOwner = dataAccountMe.user.email === dataAccountMe.organizationOwner?.email;
    if (isOwner) {
      return { isLoading: false, redirectUrl: SUBSCRIPTION_DOWNGRADE_CONFIRM_DOWNGRADE_ROUTE };
    }
    return { isLoading: false, redirectUrl: SUBSCRIPTION_DOWNGRADE_ACTION_REQUIRED_ROUTE };
  }, [dataAccountMe, isAccountLoading]);

  return confirmDowngradeRedirectRoute;
};
