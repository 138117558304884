import type { PartnerIcons } from '../../../partnersConfig.types';

export const CloverIcons: PartnerIcons = {
  Favicon: 'https://platform-static.meliopayments.com/assets/clover/favicon.svg',
  MelioIcon: {
    small: 'https://platform-static.meliopayments.com/assets/clover/melio.icon.svg',
  },
  LogoIcon: {
    small: 'https://platform-static.meliopayments.com/assets/clover/clover-desktop.icon.svg',
    extraSmall: 'https://platform-static.meliopayments.com/assets/clover/clover-mobile.icon.svg',
  },
  ErrorIcon: {
    small: 'https://platform-static.meliopayments.com/assets/clover/error.icon.svg',
  },
  EmptyBillsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/clover/emptyBills.icon.svg',
  },
  EmptyVendorsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/clover/emptyVendors.icon.svg',
  },
  NoBillsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/clover/noBillsFound.icon.svg',
  },
  NoVendorsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/clover/noVendorsFound.icon.svg',
  },
  VerifyEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/clover/verifyEmail.icon.svg',
  },
  TaxSummaryIcon: {
    small: 'https://platform-static.meliopayments.com/assets/default/taxSummary.icon.svg',
  },
  ExpiredSessionIcon: {
    small: 'https://platform-static.meliopayments.com/assets/clover/expiredSession.icon.svg',
  },
  BackToPartnerIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  StartNewExperienceIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  TryDifferentEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  AddBillIcon: {
    small: 'https://platform-static.meliopayments.com/assets/clover/addBill.icon.svg',
  },
  ConsentHeader: {
    small: 'https://platform-static.meliopayments.com/assets/clover/consent-header.svg',
  },
  ConsentImage1: {
    small: 'https://platform-static.meliopayments.com/assets/clover/consent-1.svg',
  },
  ConsentImage2: {
    small: 'https://platform-static.meliopayments.com/assets/clover/consent-2.svg',
  },
};
