import { FieldValidationData, RequiredFields } from '@melio/international-payments-utils';
import { Form, RadioOption, UseMelioFormResults, VisuallyHidden } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';

import { getRequireFieldsForBankDetails } from './fxBankDetailsForm.utils';
import { FxVendorAccountFieldsFormFields } from './types';

const RequireField = ({
  field,
  form,
}: {
  field: FieldValidationData;
  form: UseMelioFormResults<FxVendorAccountFieldsFormFields>;
}) => {
  const { control, registerField } = form;
  const { formatMessage } = useMelioIntl();

  const label = formatMessage(
    `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${field.key}.label` as MessageKey
  );
  switch (field.fieldType) {
    case 'DROPDOWN': {
      const placeOrder = formatMessage(
        `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${field.key}.placeHolder` as MessageKey
      );
      return (
        <Form.SelectNew
          colSpan={2}
          {...registerField(field.key as keyof FxVendorAccountFieldsFormFields)}
          control={control}
          name={field.key}
          labelProps={{ label }}
          options={field.options?.map((x) => ({ value: x.key, label: x.name })) || []}
          isRequired
          placeholder={placeOrder}
        />
      );
    }
    case 'TEXT': {
      let tooltipMessage: string | undefined;
      switch (field.key) {
        case 'accountNumber':
        case 'iban':
        case 'ifscCode':
        case 'institutionNumber':
        case 'transitNumber':
          tooltipMessage = formatMessage(
            `activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.${field.key}.tooltip`
          );
          break;
        default:
          tooltipMessage = undefined;
          break;
      }
      return (
        <Form.TextField
          colSpan={2}
          {...registerField(field.key as keyof FxVendorAccountFieldsFormFields)}
          labelProps={{
            label,
            tooltipProps: tooltipMessage
              ? {
                  content: (
                    <>
                      <VisuallyHidden>{tooltipMessage} tooltip</VisuallyHidden>
                      {tooltipMessage}
                    </>
                  ),
                }
              : undefined,
          }}
          isRequired
          placeholder={field.placeholder}
          data-private
        />
      );
    }
    case 'RADIOBUTTON':
      return (
        <Form.RadioGroup
          colSpan={2}
          {...registerField(field.key as keyof FxVendorAccountFieldsFormFields)}
          labelProps={{
            label,
          }}
          isRequired
          options={
            field.options?.map(
              (option) =>
                ({
                  label: option.name,
                  value: option.key,
                } as RadioOption)
            ) || []
          }
        />
      );
    default:
      //TODO: not sure about it.
      throw new Error(`Field ${field.key} not supported ,type ${field.fieldType?.toString() || ''}`);
  }
};

export const RequiredFieldsComponent = ({
  requiredFields,
  form,
}: {
  requiredFields: RequiredFields[];
  form: UseMelioFormResults<FxVendorAccountFieldsFormFields>;
}) => (
  <>
    {getRequireFieldsForBankDetails(requiredFields)?.fields.map((field) => (
      <RequireField key={field.key} field={field} form={form} />
    ))}
  </>
);
