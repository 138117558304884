import { Modal, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { usePaginatedVendors, useVendorGroups } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';

import { usePayDashboardPagination } from '../../PayDashboard/components/Pagination';
import { VendorsGroupForm, VendorsGroupFormFields } from '../vendors-group-form';
import { CreateVendorsGroupModalProps } from './types';

export const CreateVendorsGroupModal = withAnalyticsContext<CreateVendorsGroupModalProps>(
  ({ isOpen, onClose, onSuccess, setAnalyticsProperties }) => {
    const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<VendorsGroupFormFields>();
    const { formatMessage } = useMelioIntl();
    const { showMessage } = useSystemMessage();
    const { track } = useAnalytics();
    const { create, isMutating } = useVendorGroups({ enabled: false });
    const { pageSize } = usePayDashboardPagination();

    const { isLoading, data: vendorsResult } = usePaginatedVendors({
      cacheTime: 0,
      params: {
        limit: pageSize,
        sort: [{ field: 'name', order: 'asc' }],
        expand: 'groups',
      },
    });

    setAnalyticsProperties({
      PageName: 'create-group',
      Intent: 'create-vendor-groups',
      Flow: 'vendor-groups',
    });

    useEffect(() => {
      if (isLoading) {
        return;
      }
      track('Vendor', 'View', { CountInTab: vendorsResult?.data.length });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const handleSubmit = async (vendorsGroup: VendorsGroupFormFields) => {
      try {
        track('Vendor', 'Click', { Cta: 'create', CountInTab: vendorsGroup.vendorIds.length });

        const { id: newVendorGroupId } = await create(vendorsGroup);
        showMessage({
          title: formatMessage('activities.createVendorsGroupModal.toasts.success', { name: vendorsGroup.name }),
          type: 'success',
          dataTestId: 'create-vendors-group-success',
        });
        onSuccess?.(newVendorGroupId);
        onClose();
      } catch (e) {
        showMessage({
          title: formatMessage('activities.createVendorsGroupModal.toasts.error', { name: vendorsGroup.name }),
          type: 'error',
          dataTestId: 'create-vendors-group-error',
        });
      }
    };

    const handleClose = () => {
      track('Vendor', 'Click', { Cta: 'exit' });
      onClose();
    };

    const handleCancel = () => {
      track('Vendor', 'Click', { Cta: 'cancel' });
      onClose();
    };

    return (
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        data-testid="create-vendors-group-modal"
        header={formatMessage('activities.createVendorsGroupModal.header')}
        isLoading={isLoading}
        primaryButton={{
          label: formatMessage('activities.createVendorsGroupModal.buttons.primary'),
          variant: 'primary',
          ...submitButtonProps,
        }}
        secondaryButton={{
          label: formatMessage('activities.createVendorsGroupModal.buttons.secondary'),
          variant: 'secondary',
          onClick: handleCancel,
        }}
      >
        <VendorsGroupForm
          onSubmissionStateChange={onSubmissionStateChange}
          handleSubmit={handleSubmit}
          isSaving={isMutating}
        />
      </Modal>
    );
  }
);
