import { Box } from '@chakra-ui/react';
import { ARInvoice, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenuItemProps, Table } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Big } from 'big.js';
import { useEffect, useRef } from 'react';

import {
  getActionLabel,
  getActionParameterValue,
  getActionVariant,
  getAnalyticsActionCta,
  getAnalyticsActionFlow,
  getAnalyticsActionIntent,
  getInvoiceAction,
  getInvoiceStatusActions,
  InvoiceActionsHandlers,
} from '../../../../utils';
import { useRowContextDescription } from '../../hooks';

type InvoiceActionCellProps = {
  row: ARInvoice;
  meta: InvoiceActionsHandlers;
};

const handleCellClick = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

export const InvoiceActionCell = ({ row: invoice, meta }: InvoiceActionCellProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const rowContextDecription = useRowContextDescription(invoice);
  const actions = getInvoiceStatusActions(invoice.displayStatus);
  const actionsCellRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (actionsCellRef.current) {
      actionsCellRef.current.onclick = () => {
        track('Dashboard', 'Click', {
          Flow: 'manage-invoice',
          Intent: 'invoice-action',
          Cta: 'action-menu',
          InvoiceId: invoice.id,
          InvoiceStatus: invoice.displayStatus,
          DueDate: invoice.dueDate,
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsCellRef.current, track]);

  if (actions.length == 0) {
    return null;
  }

  const actionItems = actions
    .map((action) => {
      const actionFn = getInvoiceAction(action, meta);
      const actionParameterValue = getActionParameterValue({ action, invoice });
      return actionFn
        ? {
            label: getActionLabel(action),
            variant: getActionVariant(action),
            onClick: () => {
              void actionFn(actionParameterValue);
              track('Invoice', 'Click', {
                ProductName: 'ar',
                PageName: 'dashboard',
                Flow: getAnalyticsActionFlow(action),
                Intent: getAnalyticsActionIntent(action),
                Cta: getAnalyticsActionCta(action),
                InvoiceId: invoice.id,
                InvoiceStatus: invoice.displayStatus,
                DueDate: invoice.dueDate,
                Amount: new Big(invoice.totalAmount).div(100).toNumber(),
              });
            },
          }
        : null;
    })
    .filter(Boolean) as ActionsDropdownMenuItemProps[];

  return (
    <Box onClick={handleCellClick} height="full">
      <Table.ActionsCell
        ref={actionsCellRef}
        tooltipLabel={formatMessage('ar.dashboard.activities.invoiceTable.columns.actions.tooltipLabel')}
        options={actionItems}
        data-testid={`invoice-actions-cell-${invoice.id}`}
        aria-label={rowContextDecription}
      />
    </Box>
  );
};
