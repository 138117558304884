import { Group, Icon, ListItem } from '@melio/penny';
import { DeliveryMethod, Vendor } from '@melio/platform-api';

import { useDeliveryMethodCardProps } from '../../utils/useDeliveryMethodCardProps';

type Props = {
  vendor?: Vendor;
  deliveryMethod: DeliveryMethod;
  isDisabled: boolean;
};

export const DeliveryMethodCardContents = ({ deliveryMethod, vendor, isDisabled }: Props) => {
  const { title, icon, description } = useDeliveryMethodCardProps({ vendor, deliveryMethod });
  return (
    <Group
      data-testid={`payment-flow-form-delivery-method-card-${deliveryMethod.id}`}
      width="full"
      variant="horizontal"
      alignItems="center"
    >
      <Icon type={icon} isDisabled={isDisabled} />
      <ListItem
        isDisabled={isDisabled}
        mainLabelProps={{
          label: title,
          variant: 'bold',
        }}
        descriptionProps={{ label: description }}
      />
    </Group>
  );
};
