import { CSSProperties } from 'react';

import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const AppLayoutTheme: ComponentThemeType<{
  isStickyHeader?: boolean;
  isEmbeddedExperience?: boolean;
  maxWidth?: CSSProperties['maxWidth'];
}> = (currentTheme, { isStickyHeader, maxWidth }) => ({
  baseStyle: {
    layoutContainer: {
      width: '100vw',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      maxWidth,
    },
    rightLayoutContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowX: 'hidden',
    },
    downLayoutContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      backgroundColor: currentTheme.colors.global.neutral['200'],
      overflowY: 'hidden',
    },
    appScreen: {
      flex: 1,
      position: 'relative',
      width: 'full',
      backgroundColor: currentTheme.colors.global.neutral['100'],
      ...(!isStickyHeader && { overflowX: 'hidden' }),
    },
  },
});
