import { Container, IconButton, Text, TextField, TextFieldProps, useTheme } from '@melio/penny';
import { ColorSwatch } from 'react-aria-components';

const colorColorSwatch = (borderColor: string) => ({
  borderRadius: '50%',
  borderColor,
  borderStyle: 'solid',
  borderWidth: '1px',
});

const smallColorPickerStyle = (borderColor: string) => ({
  ...colorColorSwatch(borderColor),
  margin: '9px',
  width: '20px',
  height: '20px',
  minWidth: '20px',
});

const largeColorPickerStyle = (borderColor: string) => ({
  ...colorColorSwatch(borderColor),
  margin: '11px',
  width: '24px',
  height: '24px',
  minWidth: '24px',
});

type ColorPickerTextFieldProps = Omit<TextFieldProps, 'value'> & {
  onEditClick: () => void;
  value: string;
};

export const ColorPickerTextField = ({
  onEditClick,
  value,
  isDisabled,
  isReadOnly,
  size,
  onChange,
  ...inputProps
}: ColorPickerTextFieldProps) => {
  const theme = useTheme();
  return (
    <TextField
      {...inputProps}
      pointerEvents="none"
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      as="div"
      size={size}
      rightElement={
        <Container paddingRight="xs-s">
          <IconButton
            data-testid="edit-color-button"
            icon="edit"
            aria-label="edit color"
            size="small"
            variant="naked"
            onClick={() => onEditClick()}
            isDisabled={isDisabled || isReadOnly}
          />
        </Container>
      }
      leftElement={
        <ColorSwatch
          color={`#${value}`}
          style={{
            ...(size && size === 'small'
              ? smallColorPickerStyle(theme.colors.semantic.border.static)
              : largeColorPickerStyle(theme.colors.semantic.border.static)),
          }}
        />
      }
      // Fixing console error from penny
      onChange={() => null}
    >
      <Container data-testid="color-picker-text-field-value" height="full" alignItems="center">
        <Text textStyle="body2" color="semantic.text.secondary">
          #{value}
        </Text>
      </Container>
    </TextField>
  );
};
