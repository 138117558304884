export function base64UrlEncode(arrayBuffer: ArrayBuffer) {
  const uint8Array = new Uint8Array(arrayBuffer);
  const numberArray: number[] = Array.from(uint8Array);
  let base64 = btoa(String.fromCharCode.apply(null, numberArray));

  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export async function generateCodeVerifierAndChallenge() {
  const randomBytes = new Uint8Array(43);
  crypto.getRandomValues(randomBytes);

  const codeVerifier = base64UrlEncode(randomBytes);

  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const codeChallenge = base64UrlEncode(new Uint8Array(hashBuffer));

  return { codeVerifier, codeChallenge };
}
