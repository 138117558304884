import { usePartnerFeature } from '@melio/platform-provider';

import { DeliveryMethodSectionWithDrawer } from './DeliveryMethodSectionWithDrawer/DeliveryMethodSectionWithDrawer';
import { DeliveryMethodSectionWithModals } from './DeliveryMethodSectionWithModals/DeliveryMethodSectionWithModals';
import { DeliveryMethodSectionProps } from './types';

export const DeliveryMethodSection = (props: DeliveryMethodSectionProps) => {
  const [vendorDeliveryMethodsFeature] = usePartnerFeature('VendorDeliveryMethodCombinedExperience', false);

  if (vendorDeliveryMethodsFeature) {
    return <DeliveryMethodSectionWithDrawer {...props} />;
  }

  return <DeliveryMethodSectionWithModals {...props} />;
};
