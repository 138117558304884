/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import { getInternationalDmDetails, isEbill } from '@melio/ap-domain';
import { availableBalanceUpdatedAtFormat, getFundingSourceBalance, useAccountingPlatformName } from '@melio/ap-widgets';
import {
  Button,
  IconProps,
  PopoverProps,
  StatusIconSolidProps,
  TableSelectCellProps,
  TooltipProps,
} from '@melio/penny';
import {
  AccountingPlatform,
  Bill,
  CardNetwork,
  DeliveryMethod,
  DeliveryMethodType,
  FundingSource,
  FundingSourceType,
  PaymentIntent,
  useDeliveryMethod,
  useInternationalCountriesDetails,
  usePayments,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { partition, sumBy, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';

import { getIsReconciliationNeeded, useReconciliation } from '../../../funding-sources';
import { useVendorDetailsModal } from '../../../funding-sources/FundingSourceSelection/useVendorDetailsModal.hook';
import {
  isPaymentIntentsRequireInvoice,
  usePaymentPurposeShowFields,
} from '../../../international-payment/payment-purpose/PaymentPurpose.utils';
import { SchedulePaymentIntent } from '../../types';
import { CellError } from '../../useSubmitBatchPaymentsErrors/types';

export const getIsMissingPaymentPurpose = (deliveryMethod?: DeliveryMethod, paymentPurpose?: string) =>
  deliveryMethod?.type === DeliveryMethodType.InternationalAccount && (paymentPurpose === undefined || !paymentPurpose);

export const doesPaymentIntentsContainAnEbill = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) =>
  paymentIntentsWithDerivatives.some(({ bills }) => bills.some((bill) => isEbill(bill)));

export const getNumberOfBills = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) =>
  sumBy(paymentIntentsWithDerivatives, ({ bills }) => bills.length);

type GetFundingSourcePopoverProps = {
  selectedFundingSource?: FundingSource;
  vendor: Vendor;
  activeAccountingPlatform?: AccountingPlatform;
  onOpenReconciliationModal: VoidFunction;
  onOpenVendorDetailsModal: VoidFunction;
  error?: CellError;
};

type PopoverOrTooltipProps =
  | {
      popoverProps: TableSelectCellProps['popoverProps'];
      tooltipProps?: never;
    }
  | {
      popoverProps?: never;
      tooltipProps: TableSelectCellProps['tooltipProps'];
    };
type StatusOrIconProps =
  | {
      statusIconSolidProps: StatusIconSolidProps & { 'data-testid'?: string };
      iconProps?: never;
    }
  | {
      statusIconSolidProps?: never;
      iconProps: IconProps & { 'data-testid'?: string };
    };
export type GetFundingSourceStatusIconResult = PopoverOrTooltipProps & StatusOrIconProps;

export const useGetFundingSourceStatusIconProps = ({
  selectedFundingSource,
  vendor,
  activeAccountingPlatform,
  onOpenReconciliationModal,
  onOpenVendorDetailsModal,
  error,
}: GetFundingSourcePopoverProps): GetFundingSourceStatusIconResult | undefined => {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();

  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
  const { isVendorHasMissingDetails } = useVendorDetailsModal();

  if (!selectedFundingSource) {
    return;
  }

  if (error) {
    return {
      statusIconSolidProps: { variant: error.variant, size: 'small' },
      tooltipProps: {
        content: error.label,
        shouldAddTriggerFocus: true,
      },
    };
  }

  const shouldShowReconciliationWarning = getIsReconciliationNeeded(activeAccountingPlatform, selectedFundingSource);
  const isVendorMissingDetails = isVendorHasMissingDetails({ vendor, selectedFundingSource });

  if (isVendorMissingDetails) {
    const descriptionMessage =
      selectedFundingSource.type === FundingSourceType.Card &&
      selectedFundingSource.details.network === CardNetwork.Amex
        ? 'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.missingVendorDetails.popoverDescription.amex'
        : 'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.missingVendorDetails.popoverDescription.visa';

    return {
      statusIconSolidProps: { variant: 'warning', size: 'small' },
      popoverProps: {
        description: formatMessage(descriptionMessage),
        actionRenderer: (({ onClose, ...props }) => (
          <Button
            {...props}
            size="small"
            variant="secondary-inverse"
            label={formatMessage(
              'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.missingVendorDetails.popoverActionLabel'
            )}
            onClick={() => {
              onClose();
              onOpenVendorDetailsModal();
            }}
          />
        )) as PopoverProps['actionRenderer'],
        'data-testid': `missing-vendor-details-funding-source-${
          (selectedFundingSource as unknown as FundingSource).id
        }-cell`,
      },
    };
  }

  if (shouldShowReconciliationWarning) {
    return {
      statusIconSolidProps: { variant: 'warning', size: 'small' },
      popoverProps: {
        description: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.reconciliationNeeded.popoverDescription',
          {
            accountingPlatform: accountingPlatformName,
          }
        ),
        actionRenderer: (({ onClose, ...props }) => (
          <Button
            {...props}
            size="small"
            variant="secondary-inverse"
            label={formatMessage(
              'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.reconciliationNeeded.popoverActionLabel'
            )}
            onClick={() => {
              onClose();
              onOpenReconciliationModal();
            }}
          />
        )) as PopoverProps['actionRenderer'],
        'data-testid': `reconciliation-needed-funding-source-${
          (selectedFundingSource as unknown as FundingSource).id
        }-cell`,
      },
    };
  }

  if (selectedFundingSource.type === 'bank-account') {
    const fundingSourceBalance = getFundingSourceBalance(selectedFundingSource);
    if (fundingSourceBalance) {
      return {
        iconProps: { type: 'info', size: 'small', 'data-testid': 'funding-source-select-balance-tooltip-icon' },
        tooltipProps: {
          content: formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.fundingSourceSelectCell.currentBalance.tooltip',
            {
              availableBalance: formatCurrency(fundingSourceBalance?.availableBalance),
              availableBalanceUpdatedAt: formatDate(
                selectedFundingSource.availableBalanceUpdatedAt,
                availableBalanceUpdatedAtFormat
              ),
            }
          ),
        },
      };
    }
  }

  return;
};

type UseGetDeliveryMethodWarningProps = {
  shouldDisplayStatus?: boolean;
  selectedDeliveryMethodId?: string;
  paymentIntent: PaymentIntent;
  shouldShowMissingVendorInfoWarning?: boolean;
  onOpenPaymentPurposeModal?: VoidFunction;
};

type UseInvoiceFileWarningProps = {
  bill: Bill;
  onOpenAttachInvoiceModal?: VoidFunction;
  deliveryMethod: DeliveryMethod;
};

type UseGetDeliveryMethodWarningReturnValue = {
  shouldShowWarning: boolean;
  onClick?: VoidFunction;
  tooltipProps?: Pick<TooltipProps, 'content'>;
  popoverProps?: Pick<PopoverProps, 'actionRenderer' | 'description' | 'data-testid'>;
};

export const useGetDeliveryMethodWarning = ({
  shouldDisplayStatus,
  selectedDeliveryMethodId,
  paymentIntent,
  shouldShowMissingVendorInfoWarning = false,
  onOpenPaymentPurposeModal,
}: UseGetDeliveryMethodWarningProps) => {
  const componentPrefix = 'activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell';

  const { formatMessage } = useMelioIntl();
  const [warningInfo, setWarningInfo] = useState<UseGetDeliveryMethodWarningReturnValue>({
    shouldShowWarning: false,
  });

  const {
    settings: {
      batchPayments: { showVendorVerifiedDeliveryMethodTooltip },
    },
  } = useConfig();

  const { data: deliveryMethod } = useDeliveryMethod({
    id: selectedDeliveryMethodId,
    enabled: selectedDeliveryMethodId !== undefined,
  });

  const internationalCountryCode =
    deliveryMethod?.type === 'international-account'
      ? deliveryMethod.details?.identifierDetails.bankCountryCode
      : undefined;

  const combinedBillsIntent = (paymentIntent?.billPayments?.length || 1) > 1;
  const { identifierType } = getInternationalDmDetails(paymentIntent.deliveryMethod);
  const { shouldShowInvoice } = usePaymentPurposeShowFields({
    vendorId: paymentIntent.billInfo.vendorId,
    hasInvoice: !!paymentIntent?.billInfo.invoice.fileId,
    internationalCountryCode,
    currency: paymentIntent.billInfo.currency,
    identifierType,
  });

  useEffect(() => {
    if (shouldShowMissingVendorInfoWarning) {
      setWarningInfo({
        shouldShowWarning: true,
        tooltipProps: {
          content: formatMessage(`${componentPrefix}.missingVendorDetailsTooltip`),
        },
      });
    } else if (shouldDisplayStatus && !selectedDeliveryMethodId) {
      setWarningInfo({
        shouldShowWarning: true,
        tooltipProps: {
          content: formatMessage(`${componentPrefix}.missingDeliveryMethodTooltip`),
        },
      });
    } else if (deliveryMethod?.isManaged && showVendorVerifiedDeliveryMethodTooltip) {
      setWarningInfo({
        shouldShowWarning: true,
        tooltipProps: {
          content: formatMessage(`${componentPrefix}.managedDeliveryMethodTooltip`),
        },
      });
    } else if (
      onOpenPaymentPurposeModal &&
      ((deliveryMethod?.type === DeliveryMethodType.InternationalAccount && !paymentIntent?.paymentPurpose) ||
        (!combinedBillsIntent && shouldShowInvoice))
    ) {
      setWarningInfo({
        shouldShowWarning: true,
        onClick: onOpenPaymentPurposeModal,
        popoverProps: {
          description: formatMessage(
            `${componentPrefix}.missingInternationalDeliveryMethodDetailsPopover.popoverDescription`
          ),
          actionRenderer: ({ onClose, ...props }) => (
            <Button
              {...props}
              size="small"
              variant="secondary-inverse"
              label={formatMessage(
                `${componentPrefix}.missingInternationalDeliveryMethodDetailsPopover.popoverActionLabel`
              )}
              onClick={() => {
                onClose();
                onOpenPaymentPurposeModal();
              }}
            />
          ),
          'data-testid': `paymentPurpose-needed-international-delivery-method-cell`,
        },
      });
    } else {
      setWarningInfo({
        shouldShowWarning: false,
      });
    }
  }, [shouldDisplayStatus, selectedDeliveryMethodId, deliveryMethod, paymentIntent]);

  return { warningInfo };
};

export const useUnreadyPaymentIntents = (
  paymentIntentsWithDerivatives: SchedulePaymentIntent[],
  fundingSources: FundingSource[]
) => {
  const { isReconciliationNeeded } = useReconciliation();
  const { isVendorHasMissingDetails } = useVendorDetailsModal();

  const [paymentIntentsWithMissingData, paymentIntentsWithoutMissingData] = partition(
    paymentIntentsWithDerivatives,
    ({ paymentIntent, vendor }) => {
      const selectedFundingSource = fundingSources.find((fs) => fs.id === paymentIntent.fundingSourceId);
      const selectedDeliveryMethod = vendor.deliveryMethods.find((dm) => dm.id === paymentIntent.deliveryMethodId);
      const isVendorMissingDetails = selectedFundingSource
        ? isVendorHasMissingDetails({ vendor, selectedFundingSource })
        : false;

      return (
        !paymentIntent.fundingSourceId ||
        !paymentIntent.deliveryMethodId ||
        (!paymentIntent.scheduledDate && !paymentIntent.deliveryDate) ||
        !paymentIntent.selectedDeliveryPreferenceType ||
        isReconciliationNeeded(selectedFundingSource) ||
        getIsMissingPaymentPurpose(selectedDeliveryMethod, paymentIntent.paymentPurpose) ||
        isVendorMissingDetails
      );
    }
  );

  const vendorsWithInternationalDeliveryMethod = paymentIntentsWithoutMissingData
    .filter(({ vendor, paymentIntent: { deliveryMethodId } }) => {
      const selectedDeliveryMethod = vendor.deliveryMethods.find((dm) => dm.id === deliveryMethodId);
      return selectedDeliveryMethod?.type === DeliveryMethodType.InternationalAccount;
    })
    .map(({ vendor }) => vendor);

  const internationalVendorsWithoutMissingData = uniqBy(vendorsWithInternationalDeliveryMethod, (vendor) => vendor.id);

  const { data: countryDetailsData } = useInternationalCountriesDetails({
    enabled: !!internationalVendorsWithoutMissingData.length,
  });

  const { data: payments = [] } = usePayments({
    params: {
      search: {
        'vendor.id': internationalVendorsWithoutMissingData.map(({ id }) => id),
      },
    },
    enabled: !!internationalVendorsWithoutMissingData.length,
  });

  return [
    ...paymentIntentsWithMissingData,
    ...paymentIntentsWithoutMissingData.filter(({ paymentIntent, vendor, bills }) => {
      const selectedDeliveryMethod = vendor.deliveryMethods.find((dm) => dm.id === paymentIntent.deliveryMethodId);

      return (
        selectedDeliveryMethod?.type === DeliveryMethodType.InternationalAccount &&
        isPaymentIntentsRequireInvoice({
          bills,
          payments,
          internationalCountryCode: selectedDeliveryMethod?.details?.identifierDetails.bankCountryCode,
          countryDetailsData,
          vendorId: vendor.id,
        })
      );
    }),
  ];
};

export const useInvoiceFileWarning = ({
  bill,
  deliveryMethod,
  onOpenAttachInvoiceModal,
}: UseInvoiceFileWarningProps) => {
  const componentPrefix =
    'activities.batchPayments.screens.paymentIntentsTable.deliveryMethodSubCell.missingInvoicePopover';

  const { formatMessage } = useMelioIntl();
  const [warningInfo, setWarningInfo] = useState<UseGetDeliveryMethodWarningReturnValue>({
    shouldShowWarning: false,
  });
  const { identifierType, currency, internationalCountryCode } = getInternationalDmDetails(deliveryMethod);

  const { shouldShowInvoice } = usePaymentPurposeShowFields({
    vendorId: bill.vendorId,
    hasInvoice: !!bill.invoice.fileId,
    internationalCountryCode,
    currency,
    identifierType,
  });

  useEffect(() => {
    if (shouldShowInvoice && onOpenAttachInvoiceModal) {
      setWarningInfo({
        shouldShowWarning: true,
        popoverProps: {
          description: formatMessage(`${componentPrefix}.popoverDescription`),
          actionRenderer: ({ onClose, ...props }) => (
            <Button
              {...props}
              size="small"
              variant="secondary-inverse"
              label={formatMessage(`${componentPrefix}.popoverActionLabel`)}
              onClick={() => {
                onClose();
                onOpenAttachInvoiceModal();
              }}
            />
          ),
          'data-testid': `invoice-needed-international-delivery-method-sub-cell`,
        },
      });
    } else {
      setWarningInfo({
        shouldShowWarning: false,
      });
    }
  }, [shouldShowInvoice]);

  return warningInfo;
};
