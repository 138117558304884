import { useBreakpoint } from '@melio/penny';

import { NestedFiltersDesktop } from './NestedFiltersDesktop';
import { NestedFiltersMobile } from './NestedFiltersMobile';
import { NestedFiltersProps } from './types';

export const NestedFilters = <T extends string>(props: NestedFiltersProps<T>) => {
  const { isExtraSmallScreen } = useBreakpoint();

  if (isExtraSmallScreen) {
    return <NestedFiltersMobile {...props} />;
  }

  return <NestedFiltersDesktop {...props} />;
};
