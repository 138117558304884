import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PAYMENT_METHOD_BANK_SELECT_URL_ROUTE,
  SETTINGS_SUBSCRIPTION_PLANS_ROUTE,
  SUBSCRIPTION_CHECKOUT_ROUTE,
  SUBSCRIPTION_PAYMENT_METHOD_SELECT_ERROR_ROUTE,
  SUBSCRIPTION_PAYMENT_METHOD_SELECT_ROUTE,
  SUBSCRIPTION_PAYMENT_METHOD_SELECT_SUCCESS_ROUTE,
  SUBSCRIPTION_PLANS_ROUTE,
} from '../../router/routes.consts';

export type SubscriptionPlansSettingsIntent = 'resolve-failed-fee-payment';

type GoToAddBankAccountParams = {
  returnUrl: string;
};
type GoToAddCardParams = {
  returnUrl: string;
};
type GoToSubscriptionPlansParams = {
  returnUrl?: string;
  promoCode?: string;
  analyticsEntryPoint?: string;
  analyticsFlow?: string;
};
type GoToSubscriptionPlansSettingsParams = {
  intent?: SubscriptionPlansSettingsIntent;
};

type GoToSubscriptionCheckoutParams = {
  returnUrl?: string;
  planId: string;
  promoCode?: string;
  billingCycle?: string;
  analyticsEntryPoint?: string;
  analyticsFlow?: string;
};

export const useSubscriptionRouter = () => {
  const navigate = useNavigate();

  return React.useMemo(
    () => ({
      goToAddBankAccount: ({ returnUrl }: GoToAddBankAccountParams) =>
        navigate(
          `${PAYMENT_METHOD_BANK_SELECT_URL_ROUTE}?integrationType=plaid&returnUrl=${encodeURIComponent(returnUrl)}`
        ),

      goToAddCard: ({ returnUrl }: GoToAddCardParams) => navigate('/payment-methods/card', { state: { returnUrl } }),

      goToSubscriptionPlans: ({
        returnUrl,
        promoCode,
        analyticsEntryPoint,
        analyticsFlow,
      }: GoToSubscriptionPlansParams = {}) =>
        navigate(SUBSCRIPTION_PLANS_ROUTE, {
          state: {
            returnUrl,
            utm_promo: promoCode,
            analyticsEntryPoint,
            analyticsFlow,
          },
        }),

      goToPaymentMethodSelect: () => navigate(SUBSCRIPTION_PAYMENT_METHOD_SELECT_ROUTE),

      goToSubscriptionPlansSettings: ({ intent }: GoToSubscriptionPlansSettingsParams = {}) =>
        navigate(SETTINGS_SUBSCRIPTION_PLANS_ROUTE, { state: { intent } }),

      goToSubscriptionCheckout: ({
        returnUrl,
        planId,
        billingCycle,
        promoCode,
        analyticsEntryPoint,
        analyticsFlow,
      }: GoToSubscriptionCheckoutParams) =>
        navigate(
          {
            pathname: SUBSCRIPTION_CHECKOUT_ROUTE,
            search: `plan=${planId}`,
          },
          {
            state: { returnUrl, utm_promo: promoCode, billingCycle, analyticsEntryPoint, analyticsFlow },
          }
        ),

      goToSubscriptionSelectFundingSourceSuccess: () => navigate(SUBSCRIPTION_PAYMENT_METHOD_SELECT_SUCCESS_ROUTE),

      goToSubscriptionSelectFundingSourceError: () => navigate(SUBSCRIPTION_PAYMENT_METHOD_SELECT_ERROR_ROUTE),
    }),
    [navigate]
  );
};
