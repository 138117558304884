import { Container, Text } from '@melio/penny';

export const ImportVendorsInitialScreen = () => (
  <Container width="fit-content" height="full" alignItems="center">
    <Container>
      <Text as="h1" data-testid="import-vendors-title">
        Import vendors
      </Text>
    </Container>
    <Container>
      <Text>Follow these two quick steps</Text>
    </Container>
  </Container>
);
