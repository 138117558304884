import { ApprovalRequirementStatus, DeliveryMethod } from '@melio/platform-api';

export const getScheduleDateDescriptionLabel = ({
  deliveryMethodType,
  approvalRequirementStatus,
  isRecurringPayment,
  isFofEnabled,
}: {
  deliveryMethodType: DeliveryMethod['type'];
  approvalRequirementStatus?: ApprovalRequirementStatus;
  isRecurringPayment?: boolean;
  isFofEnabled: boolean;
}) => {
  if (deliveryMethodType === 'virtual-card') {
    return `widgets.reviewAndConfirm.payFromSection.scheduledDate.description.virtualCard`;
  }

  if (approvalRequirementStatus === ApprovalRequirementStatus.Pending) {
    return isRecurringPayment
      ? 'widgets.reviewAndConfirm.payFromSection.scheduledDate.recurringPayment.description.approvalWorkflow'
      : `widgets.reviewAndConfirm.payFromSection.scheduledDate.description.approvalWorkflow`;
  }

  if (isFofEnabled) {
    return `widgets.reviewAndConfirm.payFromSection.scheduledDate.description.fof`;
  }

  return `widgets.reviewAndConfirm.payFromSection.scheduledDate.description.default`;
};
