import { useEffect } from 'react';
import { useInitPartnerBridge, useSendMessage } from '@melio/partner-bridge';

function frameSizeChangeHandler(sendMessage: ReturnType<typeof useSendMessage>) {
  const resizeObserver = new ResizeObserver((entries) => {
    const [entry] = entries;
    if (entry) {
      sendMessage('CONTENT_SIZE_CHANGE', {
        height: entry.target.clientHeight,
        width: entry.target.clientWidth,
      });
    }
  });
  resizeObserver.observe(document.body);
  return () => resizeObserver.disconnect();
}

export function usePartnerBridge() {
  useInitPartnerBridge();
  const sendMessage = useSendMessage();
  useEffect(() => {
    const unlisten = frameSizeChangeHandler(sendMessage);
    return unlisten;
  }, [sendMessage]);
}
