import { VirtualCardFormFields } from '@melio/ap-widgets';
import { Traits, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useDeliveryMethods, useVendor, VirtualCardAccountDeliveryMethod } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { AddVendorVirtualCardDetailsScreen } from './screens';
import { AddVendorVirtualCardDetailsActivityProps } from './types';

export const AddVendorVirtualCardDetailsActivity = withAnalyticsContext<AddVendorVirtualCardDetailsActivityProps>(
  ({ vendorId, onBack: onFirstStepBack, origin, onClose, onError, onDone, isEdit, setAnalyticsProperties }) => {
    const { create: createDeliveryMethod, isMutating: isDeliveryMethodsMutating } = useDeliveryMethods({
      vendorId,
      enabled: false,
    });
    const {
      data: vendor,
      update: updateVendor,
      isLoading: isVendorLoading,
      isMutating: isVendorMutating,
    } = useVendor({ id: vendorId });
    const { createTrackHandler, setTraits } = useAnalytics();
    const { showMessage } = useSystemMessage();
    setAnalyticsProperties({ VendorId: vendorId });

    const vendorName = vendor?.name || '';
    const vendorEmail = vendor?.contact?.email || '';
    const vendorHasVirtualCardDeliveryMethod = vendor?.deliveryMethods?.some(
      (method) => method.type === 'virtual-card'
    );
    const defaultValues = {
      email: vendorEmail,
      emailValidation: vendorHasVirtualCardDeliveryMethod ? vendorEmail : '',
    };

    const handleFail = (error: PlatformError) => {
      showMessage({ type: 'error', title: error.message });
      onError?.(error);
    };

    const submitHandler = createTrackHandler<{ Status: 'failed' | 'succeeded' }>(
      'AddVendorVirtualCardDeliveryDetails',
      'Saved'
    );
    const setDeliveryMethodTraits = () => {
      const traits: Traits = { added_delivery_method: true };

      setTraits(traits);
    };

    const onDoneHandler = async (
      { email }: VirtualCardFormFields,
      _event?: React.MouseEvent<HTMLButtonElement>,
      target?: 'continue' | 'close'
    ) => {
      await updateVendor({ contact: { email } });

      createDeliveryMethod({ type: 'virtual-card', details: { accountEmail: email } })
        .then((data) => {
          submitHandler({ Status: 'succeeded' });
          setDeliveryMethodTraits();
          onDone(data as VirtualCardAccountDeliveryMethod, target);
        })
        .catch((error) => submitHandler({ Status: 'failed' }, () => handleFail(error)));
    };

    return (
      <AddVendorVirtualCardDetailsScreen
        isLoading={isDeliveryMethodsMutating || isVendorLoading}
        onBack={onFirstStepBack}
        onClose={onClose}
        onDone={onDoneHandler}
        isSaving={isDeliveryMethodsMutating || isVendorMutating}
        defaultValues={defaultValues}
        vendorName={vendorName}
        origin={origin}
        isEdit={isEdit}
      />
    );
  }
);

AddVendorVirtualCardDetailsActivity.displayName = 'AddVendorVirtualCardDetailsActivity';
