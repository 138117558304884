import { EmptyState, FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type CatalogItemsEmptyStateProps = {
  setSearchTerm: (value: string) => unknown;
  searchTerm: string;
};

export const CatalogItemsEmptyState = forwardRef<CatalogItemsEmptyStateProps>(({ searchTerm, setSearchTerm }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <EmptyState
      ref={ref}
      illustrationType="no-items"
      data-testid="catalog-items-empty-state"
      content={
        <Container textAlign="center">
          <Text textStyle="body4">
            <FormattedMessage
              id="ar.settings.activities.catalogItemsTable.components.catalogItemsTable.emptyState.message.text"
              values={{ searchTerm }}
            />
          </Text>
        </Container>
      }
      footer={
        <Button
          variant="tertiary"
          data-testid="catalog-items-empty-state-clear-search-button"
          label={formatMessage(
            'ar.settings.activities.catalogItemsTable.components.catalogItemsTable.emptyState.cleanSearchButton.label'
          )}
          onClick={() => setSearchTerm('')}
        />
      }
    />
  );
});
CatalogItemsEmptyState.displayName = 'CatalogItemsEmptyState';
