import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { OnboardingLayout } from '../../../layouts';
import { LandingPageDescription, LandingPageScreenHeader } from '../components';

export type LandingPageScreenProps = {
  onDone: VoidFunction;
  onCreateInvoice: VoidFunction;
};

export const LandingPageScreen = forwardRef<LandingPageScreenProps>(({ onDone, onCreateInvoice }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <OnboardingLayout
      data-testid="landing-page-screen"
      data-component={LandingPageScreen.displayName}
      headerContent={formatMessage('ar.onboarding.layout.header.label')}
      showTracker={false}
      isStickyFooter
    >
      <Container ref={ref} paddingX="xxl" paddingY="xxl" border="regular">
        <Group variant="vertical" spacing="l">
          <Group variant="vertical" spacing="xl">
            <LandingPageScreenHeader />
            <LandingPageDescription />
          </Group>
          <Group spacing="s" variant="horizontal">
            <Button
              variant="secondary"
              label={formatMessage('ar.onboarding.components.landingPage.activate-button.label')}
              data-testid="activate-button"
              onClick={onDone}
            />
            <Button
              variant="primary"
              label={formatMessage('ar.onboarding.components.landingPage.create-test-invoice-button.label')}
              data-testid="create-test-invoice-button"
              onClick={onCreateInvoice}
            />
          </Group>
        </Group>
      </Container>
    </OnboardingLayout>
  );
});

LandingPageScreen.displayName = 'LandingPageScreen';
