import { Route, Routes, useLocation } from 'react-router-dom';
import { DeliveryMethodType } from '@melio/platform-api';

import { RouterComponentWrapper } from '@/hoc/routerComponentWrapper';
import { NewVendorDeliveryMethodSelection } from '@/screens/vendors/NewVendorDeliveryMethodSelection.screen';
import { VendorDeliveryMethodScreen } from '@/screens/vendors/VendorDeliveryMethod.screen';

export const VendorDeliveryMethodRoute = () => {
  const { state } = useLocation();
  const { returnUrl } = (state as { returnUrl?: string }) || {};

  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <RouterComponentWrapper
            Component={NewVendorDeliveryMethodSelection}
            componentProps={{ vendorId: { _pathParam: 'vendorId' } }}
          />
        }
      />
      <Route
        path={`/bank-account`}
        element={
          <RouterComponentWrapper
            Component={VendorDeliveryMethodScreen}
            componentProps={{
              vendorId: { _pathParam: 'vendorId' },
              deliveryMethodType: DeliveryMethodType.BankAccount,
              returnUrl,
            }}
          />
        }
      />
      <Route
        path={`/domestic-wire`}
        element={
          <RouterComponentWrapper
            Component={VendorDeliveryMethodScreen}
            componentProps={{
              vendorId: { _pathParam: 'vendorId' },
              deliveryMethodType: DeliveryMethodType.DomesticWireAccount,
              returnUrl,
            }}
          />
        }
      />
      <Route
        path={`/paper-check`}
        element={
          <RouterComponentWrapper
            Component={VendorDeliveryMethodScreen}
            componentProps={{
              vendorId: { _pathParam: 'vendorId' },
              deliveryMethodType: DeliveryMethodType.PaperCheck,
              returnUrl,
            }}
          />
        }
      />
      <Route
        path={`/virtual-card`}
        element={
          <RouterComponentWrapper
            Component={VendorDeliveryMethodScreen}
            componentProps={{
              vendorId: { _pathParam: 'vendorId' },
              deliveryMethodType: DeliveryMethodType.VirtualCard,
              returnUrl,
            }}
          />
        }
      />
      <Route
        path={`/international-account`}
        element={
          <RouterComponentWrapper
            Component={VendorDeliveryMethodScreen}
            componentProps={{
              vendorId: { _pathParam: 'vendorId' },
              deliveryMethodType: DeliveryMethodType.InternationalAccount,
              returnUrl,
            }}
          />
        }
      />
      <Route
        path={`/international-fx`}
        element={
          <RouterComponentWrapper
            Component={VendorDeliveryMethodScreen}
            componentProps={{
              isInternationalFx: true,
              vendorId: { _pathParam: 'vendorId' },
              deliveryMethodType: DeliveryMethodType.InternationalAccount,
              returnUrl,
            }}
          />
        }
      />
      <Route
        path={`/paypal-balance`}
        element={
          <RouterComponentWrapper
            Component={VendorDeliveryMethodScreen}
            componentProps={{
              vendorId: { _pathParam: 'vendorId' },
              deliveryMethodType: DeliveryMethodType.PaypalBalance,
              returnUrl,
            }}
          />
        }
      />
    </Routes>
  );
};
