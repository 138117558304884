import { Group, Text } from '@melio/penny';
import React from 'react';

type Props =
  | {
      primary: string;
      secondary?: string;
    }
  | {
      primary?: string;
      secondary: string;
    };

export function DeliveryMethodCaption(props: Props) {
  return (
    <Group variant="vertical" spacing="xs">
      {props.primary && (
        <Text data-testid="delivery-method-card-info1" textStyle="body4">
          {props.primary}
        </Text>
      )}
      {props.secondary && (
        <Text data-testid="delivery-method-card-info2" textStyle="body4" color="global.neutral.800">
          {props.secondary}
        </Text>
      )}
    </Group>
  );
}
