/* eslint-disable max-lines */
import { Box } from '@chakra-ui/react';
import { useSupportedFXData } from '@melio/ap-domain';
import {
  AccountingPlatformBillLabelSelectWidget,
  AccountingPlatformCategorySelectWidget,
  VendorSelectWidget,
} from '@melio/ap-widgets';
import { Control, Form, GridItem, Link, Text, Tooltip, useWatch, VisuallyHidden } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  AccountingPlatform,
  AccountingPlatformBillLabel,
  AccountingPlatformSlug,
  CreateVendorAnalyticsMetadataFlow,
  useInternationalRate,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { isEmpty } from 'lodash';

import { useVendorSelectSideEffects } from '../../../../../../../utils/useVendorSelectSideEffects';
import { isSinglePaymentFields } from '../../../../../AddBillV2/utils';
import { useGetAllowedFXBusinessType } from '../../../../hooks/useGetAllowedFXBusinessType';
import {
  AddBillV2FormFrequency,
  AddBillV2FormValues,
  FxCurrencyExchangeRate,
  RegisterFieldFn,
} from '../../../../types';
import { CurrencySwitcher } from '../../../CurrencySwitcher/CurrencySwitcher';
import { getCurrencyDetails } from '../../../CurrencySwitcher/utils';
import { RecurringBillFormFields } from './RecurringBillFormFields';
import { StpAutoPaymentProcessingBanner } from './StpAutoPaymentProcessingBanner';

type Props = {
  formControl: Control<AddBillV2FormValues>;
  registerField: RegisterFieldFn;
  allowRecurring?: boolean;
  shouldClearVendorIdField?: boolean;
  isFormHasLineItems?: boolean;
  activeAccountingPlatform?: AccountingPlatform;
  billLabel?: AccountingPlatformBillLabel;
  shouldShowAmountInUSDField?: boolean;
  currencyRate?: FxCurrencyExchangeRate;
  isLoadingCurrencyRates: boolean;
  selectedVendor?: Vendor;
  isInternationalFxEnabled?: boolean;
  amountForQuery?: string;
  onChangeBillCurrency: (currency?: string) => void;
  onAmountFieldBlur: (amount?: string) => void;
  onAmountChange: (value: string) => void;
  onCategoryIdChange: (value: string) => void;
  onPaymentFrequencyChange: (value?: AddBillV2FormFrequency) => void;
  statusMessageParentSelector?: string | undefined;
  isFileLoading?: boolean;
  isSubmitted: boolean;
  shouldShowCategoryField?: boolean;
};

export const BillDetailsForm = ({
  formControl,
  registerField,
  allowRecurring,
  shouldClearVendorIdField,
  currencyRate,
  shouldShowAmountInUSDField,
  isInternationalFxEnabled,
  selectedVendor,
  isLoadingCurrencyRates,
  isFormHasLineItems,
  activeAccountingPlatform,
  billLabel,
  amountForQuery,
  onChangeBillCurrency,
  onAmountChange,
  onCategoryIdChange,
  onAmountFieldBlur,
  onPaymentFrequencyChange,
  statusMessageParentSelector,
  isSubmitted,
  isFileLoading,
  shouldShowCategoryField,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const [frequency, amount, vendorId, currency, amountInUSD] = useWatch({
    control: formControl,
    name: ['frequency', 'amount', 'vendorId', 'currency', 'amountInUSD'],
  });
  const isRecurringFlow = !isSinglePaymentFields(frequency);
  const { isAllowedBusinessType, businessType } = useGetAllowedFXBusinessType();
  const {
    links,
    settings: {
      newBillExperience: { isRecurringPaymentImprovementsEnabled },
    },
  } = useConfig();
  const { createTrackHandler } = useAnalytics();
  const recurringFrequencies = isRecurringPaymentImprovementsEnabled
    ? Object.values(AddBillV2FormFrequency)
    : [AddBillV2FormFrequency.ONE_TIME, AddBillV2FormFrequency.WEEKLY, AddBillV2FormFrequency.MONTHLY];
  const recurringFrequenciesOptions = recurringFrequencies.map((frequency) => ({
    label: formatMessage(`activities.addBillV2.billForm.frequencyPicker.${frequency}`),
    value: frequency,
    testId: frequency,
  }));

  const { currencies } = useSupportedFXData({});

  const trackActionClick = createTrackHandler<{
    PageName: string;
    Cta: string;
    Field: string;
    Currency: string;
    Amount: number;
    UsdAmount: string;
  }>('Bill', 'Click');
  const trackActionHover = createTrackHandler<{
    StatusType: string;
    Field: string;
    Message: string;
  }>('Bill', 'Status');
  const trackActionCurrencyDropdownView = createTrackHandler<{
    PageName: string;
    Intent: string;
    CurrenciesShown: string[];
  }>('Bill', 'View');

  const trackClickLearnAboutRates = () => {
    trackActionClick({
      PageName: 'bill-details',
      Cta: 'learn-about-rates',
      Field: 'amount-in-usd',
      Currency: currency,
      Amount: Number(amount),
      UsdAmount: amountInUSD,
    });
  };

  const isAmountReadOnly = isFormHasLineItems && frequency === AddBillV2FormFrequency.ONE_TIME;
  const isFrequencyReadOnly = shouldShowAmountInUSDField;
  const isQuickBooksOnline = activeAccountingPlatform?.accountingSlug === AccountingPlatformSlug.QuickBooksOnline;
  const accountingPlatformCategoryLabel = isQuickBooksOnline
    ? formatMessage('activities.addBillV2.billForm.category.label.quickbooks')
    : formatMessage('activities.addBillV2.billForm.category.label.general');

  const disabledCurrencySwitcherTooltipLabel = isAllowedBusinessType
    ? formatMessage('activities.addBillV2.billForm.amount.currencySwitch.disabled')
    : formatMessage('activities.addBillV2.billForm.amount.currencySwitch.disabledByBusinessType', {
        businessType,
      });

  const analyticsPropertiesForVendorSelect = {
    Flow: 'vendor',
    PageName: 'bill-details',
    VendorCreatedOrigin: 'bill',
    Intent: 'add-a-vendor',
  };
  const { isSuvcOverStpVendor } = useVendorSelectSideEffects(vendorId || '');

  const { isLoading: isFetchingCurrencyRate } = useInternationalRate({
    foreignCurrency: currency,
    foreignAmount: amountForQuery ? Number(amountForQuery) : undefined,
    enabled: false,
  });

  const getAmountTooltip = () => {
    if (!isAmountReadOnly) {
      return;
    }
    return {
      content: (
        <>
          <VisuallyHidden>{formatMessage('activities.addBillV2.billForm.billAmount.tooltip')} tooltip</VisuallyHidden>
          {formatMessage('activities.addBillV2.billForm.billAmount.tooltip')}
        </>
      ),
    };
  };

  const shouldShowBillLabelSelect = !isEmpty(billLabel) && !isRecurringFlow;
  const isChangingVendorCurrencyOptionDisabled = selectedVendor?.currency != null || !isAllowedBusinessType;
  const shouldShowCurrencySwitcher = isInternationalFxEnabled && !isRecurringFlow;

  const trackDisabledCurrencyFieldAction = () => {
    isChangingVendorCurrencyOptionDisabled &&
      trackActionHover({
        StatusType: 'hover',
        Field: 'currency',
        Message: disabledCurrencySwitcherTooltipLabel,
      });
  };

  const trackCurrencySwitcherOpen = () => {
    trackActionCurrencyDropdownView({
      PageName: 'currency-dropdown',
      Intent: 'set-bill-currency',
      CurrenciesShown: currencies,
    });
  };

  const getAmountInUSDHelperLabel = () =>
    currencyRate
      ? {
          label: formatMessage('activities.addBillV2.billForm.usdToForeignRate.helperText', {
            currencyRate: parseFloat(currencyRate?.usdToForeignRate.toFixed(4)).toString(),
            currency: currencyRate?.foreignCurrency,
          }),
        }
      : undefined;

  return (
    <>
      <VendorSelectWidget
        {...registerField('vendorId')}
        data-testid="add-bill-v2-vendors-picker"
        placeholder={formatMessage('activities.addBillV2.billForm.vendorPicker.placeholder')}
        colSpan={16}
        labelProps={{ label: formatMessage('activities.addBillV2.billForm.vendorPicker.label') }}
        shouldClearField={shouldClearVendorIdField}
        analyticsProperties={analyticsPropertiesForVendorSelect}
        eventContextName="Bill"
        createVendorFlow={CreateVendorAnalyticsMetadataFlow.Bill}
        statusMessageParentSelector={statusMessageParentSelector}
        isSubmitted={isSubmitted}
      />
      <Form.AmountField
        {...registerField('amount')}
        isReadOnly={isAmountReadOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAmountChange(e.target.value)}
        data-testid="add-bill-v2-amount-field"
        currency={currency || 'USD'}
        endElement={
          shouldShowCurrencySwitcher ? (
            <CurrencySwitcher
              setSelectedCurrency={onChangeBillCurrency}
              isDisabled={isChangingVendorCurrencyOptionDisabled}
              selectedCurrency={getCurrencyDetails(currency)}
              onHover={trackDisabledCurrencyFieldAction}
              onOpen={trackCurrencySwitcherOpen}
              tooltipLabel={isChangingVendorCurrencyOptionDisabled ? disabledCurrencySwitcherTooltipLabel : undefined}
            />
          ) : undefined
        }
        onBlur={() => onAmountFieldBlur(amount)}
        colSpan={8}
        decimalScale={2}
        labelProps={{
          label: formatMessage('activities.addBillV2.billForm.billAmount.placeholder'),
          tooltipProps: getAmountTooltip(),
        }}
        isDisabled={isFileLoading}
      />
      {shouldShowAmountInUSDField && (
        <Box hidden>
          <Form.TextField {...registerField('currency')} />
        </Box>
      )}
      {shouldShowAmountInUSDField && (
        <Form.AmountField
          {...registerField('amountInUSD')}
          isReadOnly
          data-testid="add-bill-v2-amountInUSD-field"
          isLoading={isLoadingCurrencyRates || isFetchingCurrencyRate}
          colSpan={8}
          decimalScale={2}
          endElement={<CurrencySwitcher isDisabled />}
          isRequired
          labelProps={{
            tooltipProps: {
              content: (
                <>
                  <VisuallyHidden>
                    {formatMessage('activities.addBillV2.billForm.usdToForeignRate.tooltip', {
                      link: (
                        <Link
                          color="inverse"
                          href={links['widgets.learnAboutRates.href']}
                          onClick={trackClickLearnAboutRates}
                          label={formatMessage('activities.addBillV2.billForm.usdToForeignRate.tooltip.link')}
                          newTab
                        />
                      ),
                    })}{' '}
                    tooltip
                  </VisuallyHidden>
                  {formatMessage('activities.addBillV2.billForm.usdToForeignRate.tooltip', {
                    link: (
                      <Link
                        color="inverse"
                        href={links['widgets.learnAboutRates.href']}
                        onClick={trackClickLearnAboutRates}
                        label={formatMessage('activities.addBillV2.billForm.usdToForeignRate.tooltip.link')}
                        newTab
                      />
                    ),
                  })}
                </>
              ),
            },
            label: formatMessage('activities.addBillV2.billForm.usdToForeignRate.placeholder'),
          }}
          helperTextProps={getAmountInUSDHelperLabel()}
        />
      )}
      <Form.TextField
        {...registerField('invoiceNumber')}
        data-testid="add-bill-v2-invoice-number-field"
        labelProps={{ label: formatMessage('activities.addBillV2.billForm.invoiceNumber.placeholder') }}
        helperTextProps={{ label: formatMessage('activities.addBillV2.billForm.invoiceNumber.helperText') }}
        colSpan={shouldShowAmountInUSDField ? 16 : 8}
      />
      <GridItem colSpan={16}>
        <Box mt="xs">
          <Text as="h3" textStyle="heading3Semi" data-testid="additional-details-section-title">
            {formatMessage('activities.addBillV2.billForm.additionalDetails.title')}
          </Text>
        </Box>
      </GridItem>
      {allowRecurring ? (
        <Tooltip
          isEnabled={isFrequencyReadOnly}
          content={formatMessage('activities.addBillV2.billForm.frequencyPicker.disabled.tooltip')}
        >
          <Form.SelectNew
            {...registerField('frequency')}
            colSpan={16}
            isReadOnly={isFrequencyReadOnly}
            shouldHideClearButton
            emptyState={undefined}
            data-testid="add-bill-v2-frequency-picker"
            options={recurringFrequenciesOptions}
            labelProps={{ label: formatMessage('activities.addBillV2.billForm.frequencyPicker.placeholder') }}
            onChange={(e) => onPaymentFrequencyChange(e.target.value as AddBillV2FormFrequency)}
          />
        </Tooltip>
      ) : null}

      {!!isRecurringFlow && <RecurringBillFormFields formControl={formControl} registerField={registerField} />}

      {!isRecurringFlow && (
        <>
          <Form.DateField
            {...registerField('invoiceDate')}
            toggleDatePickerAriaLabel={formatMessage(
              'activities.addBillV2.billForm.invoiceDate.toggleDatePickerAriaLabel'
            )}
            data-testid="add-bill-v2-invoice-date-field"
            weekDays={[0, 1, 2, 3, 4, 5, 6]}
            excludeHolidays={false}
            labelProps={{ label: formatMessage('activities.addBillV2.billForm.invoiceDate.placeholder') }}
            colSpan={8}
            placeholder={formatMessage('activities.addBillV2.billForm.invoiceDate.field.placeholder')}
            clearDateAriaLabel={formatMessage('activities.addBillV2.billForm.invoiceDate.field.clearAriaLabel')}
            selectedDateAriaLabel={formatMessage('activities.addBillV2.billForm.invoiceDate.selectedAriaLabel')}
            legendItems={[
              {
                label: formatMessage('activities.addBillV2.billForm.calendar.legend.today'),
                variant: 'today',
              },
            ]}
            isTypable
          />
          <Form.DateField
            {...registerField('dueDate')}
            isRequired
            data-testid="add-bill-v2-due-date-field"
            labelProps={{ label: formatMessage('activities.addBillV2.billForm.dueDate.placeholder') }}
            toggleDatePickerAriaLabel={formatMessage('activities.addBillV2.billForm.dueDate.toggleDatePickerAriaLabel')}
            excludeHolidays={false}
            weekDays={[0, 1, 2, 3, 4, 5, 6]}
            colSpan={8}
            placeholder={formatMessage('activities.addBillV2.billForm.dueDate.field.placeholder')}
            clearDateAriaLabel={formatMessage('activities.addBillV2.billForm.dueDate.field.clearAriaLabel')}
            selectedDateAriaLabel={formatMessage('activities.addBillV2.billForm.dueDate.selectedAriaLabel')}
            legendItems={[
              {
                label: formatMessage('activities.addBillV2.billForm.calendar.legend.today'),
                variant: 'today',
              },
            ]}
            isTypable
          />
        </>
      )}
      {shouldShowCategoryField && (
        <AccountingPlatformCategorySelectWidget
          {...registerField('categoryId')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCategoryIdChange(e.target.value)}
          data-testid="add-bill-v2-category-picker"
          colSpan={8}
          labelProps={{ label: accountingPlatformCategoryLabel }}
        />
      )}
      {shouldShowBillLabelSelect && (
        <AccountingPlatformBillLabelSelectWidget
          {...registerField('externalLabelId')}
          billLabel={billLabel}
          colSpan={8}
          data-testid="add-bill-v2-bill-label-picker"
        />
      )}
      <Form.TextField
        {...registerField('noteToSelf')}
        data-testid="add-bill-v2-note-to-self-field"
        placeholder={formatMessage('activities.addBillV2.billForm.noteToSelf.placeholder')}
        labelProps={{ label: formatMessage('activities.addBillV2.billForm.noteToSelf.label') }}
        colSpan={shouldShowCategoryField === shouldShowBillLabelSelect ? 16 : 8}
        data-private
      />

      {isSuvcOverStpVendor && (
        <Form.ContentBox colSpan={16}>
          <StpAutoPaymentProcessingBanner />
        </Form.ContentBox>
      )}
    </>
  );
};
