import { Button } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethodType, UnilateralRequest, UnilateralRequestStatusEnum, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean, useSystemMessage } from '@melio/platform-utils';
import React from 'react';

import { MethodCard } from '../../../../MethodCard/MethodCard.widget';
import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';
import { UnilateralRequestDetailsModal } from './components/UnilateralRequestDetailsModal.widget';
import { useUnilateralRequestDetails } from './useUnilateralRequestDetails';

type UnilateralRequestMethodCardProps = {
  vendor: Vendor;
  unilateralRequests: UnilateralRequest[] | undefined;
};

export const UnilateralRequestMethodCard = ({ vendor, unilateralRequests }: UnilateralRequestMethodCardProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { track } = useAnalytics();
  const { showMessage } = useSystemMessage();

  const [isModalOpen, modalControls] = useBoolean(false);

  const {
    sendUnilateralRequest,
    createVirtualAccountAndSendUnilateralRequest,
    enableSendRequest: isValidForResend,
    vendorVirtualDeliveryMethod,
    isSaving,
  } = useUnilateralRequestDetails({ vendorId: vendor.id });

  const { firstSentUnilateralRequest, lastSentUnilateralRequest } =
    getFirstSentAndLastSentUnilateralRequest(unilateralRequests);

  const hasActiveUnilateralRequest = !!unilateralRequests && !!getActiveUnilateralRequests(unilateralRequests)?.length;

  const showSuccessToast = () =>
    showMessage({
      type: 'success',
      title: formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.toast.success'),
    });
  const showErrorToast = () =>
    showMessage({
      type: 'error',
      title: formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.toast.error'),
    });

  const handleResendClick = () => {
    if (!vendorVirtualDeliveryMethod) {
      showErrorToast();
      throw new Error('no virtual account exists');
    }

    sendUnilateralRequest(vendorVirtualDeliveryMethod.id)
      .then(() => {
        track('VendorsAddVirtualMethod', 'Chose');
        showSuccessToast();
      })
      .catch(showErrorToast);
  };

  const handleSendClick = () => {
    if (!vendor?.contact.email) {
      return modalControls.on();
    }

    (vendorVirtualDeliveryMethod
      ? sendUnilateralRequest(vendorVirtualDeliveryMethod.id)
      : createVirtualAccountAndSendUnilateralRequest({ email: vendor.contact.email })
    )
      .then(() => {
        track('VendorsAddVirtualMethod', 'Chose');
        showSuccessToast();
      })
      .catch(showErrorToast);
  };

  return (
    <>
      {hasActiveUnilateralRequest ? (
        <MethodCard
          testIds={{
            root: `MethodCard-${DeliveryMethodType.VirtualAccount}`,
            header: `method-card-type-${DeliveryMethodType.VirtualAccount}`,
          }}
          actionElement={
            <Button
              size="small"
              isDisabled={!isValidForResend}
              isLoading={isSaving}
              label={
                isValidForResend
                  ? formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.resendAction')
                  : formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.disabled')
              }
              onClick={handleResendClick}
            />
          }
          displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.secondaryTitle')}
          tooltip={
            isValidForResend
              ? undefined
              : { type: 'neutral', text: formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.tooltipText') }
          }
        >
          <DeliveryMethodCaption
            primary={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.info.info1', {
              createdDate: formatDate(firstSentUnilateralRequest?.history.createdAt, { dateStyle: 'medium' }),
            })}
            secondary={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.info.info2', {
              updatedDate: formatDate(lastSentUnilateralRequest?.history.createdAt, { dateStyle: 'medium' }),
            })}
          />
        </MethodCard>
      ) : (
        <MethodCard
          actionElement={
            <Button
              size="small"
              isLoading={isSaving}
              isDisabled={isSaving}
              onClick={handleSendClick}
              label={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.sendAction')}
              aria-label={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.sendAction.ariaLabel')}
            />
          }
          displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.title')}
          helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.virtual.comment')}
          testIds={{
            root: `MethodCard-${DeliveryMethodType.VirtualAccount}`,
            header: `method-card-type-${DeliveryMethodType.VirtualAccount}`,
          }}
        />
      )}
      <UnilateralRequestDetailsModal
        vendor={vendor}
        isOpen={isModalOpen}
        origin={OriginFlow.VendorsFlow}
        onClose={modalControls.off}
      />
    </>
  );
};

const getFirstSentAndLastSentUnilateralRequest = (
  unilateralRequests?: UnilateralRequest[]
): {
  firstSentUnilateralRequest: UnilateralRequest | undefined;
  lastSentUnilateralRequest: UnilateralRequest | undefined;
} => {
  const sortedUnilateralRequests =
    unilateralRequests && getActiveUnilateralRequestsSortedByCreatedDate(unilateralRequests);
  const lastSentUnilateralRequest = sortedUnilateralRequests?.[0];
  const firstSentUnilateralRequest = sortedUnilateralRequests?.[sortedUnilateralRequests.length - 1];
  return { firstSentUnilateralRequest, lastSentUnilateralRequest };
};

const getActiveUnilateralRequestsSortedByCreatedDate = (
  unilateralRequests: UnilateralRequest[]
): UnilateralRequest[] => {
  const activeUnilateralRequests = getActiveUnilateralRequests(unilateralRequests);
  return activeUnilateralRequests?.sort(
    (a: UnilateralRequest, b: UnilateralRequest) => b.history.createdAt.getTime() - a.history.createdAt.getTime()
  );
};

const getActiveUnilateralRequests = (unilateralRequests: UnilateralRequest[]): UnilateralRequest[] =>
  unilateralRequests.filter(
    (unilateralRequest) =>
      unilateralRequest.status === UnilateralRequestStatusEnum.Created ||
      unilateralRequest.status === UnilateralRequestStatusEnum.Sent
  );
