import { Box } from '@chakra-ui/react';
import {
  Button,
  ButtonProps,
  Container,
  Divider,
  FloatingMenu,
  Group,
  IconButton,
  Menu,
  Radio,
  StatusIndicator,
  Text,
  useBreakpoint,
} from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useRef, useState } from 'react';

import { FilterOption } from './types';

type FooterButton<T> = {
  label: string;
  onClick: (id: T) => void;
  variant: ButtonProps['variant'];
  disabledOnDefault?: boolean;
  getAriaLabel?: (selectedFilterLabel: string) => string;
  testId?: string;
};
type Props<T> = {
  options: FilterOption<T>[];
  activeFilter: T;
  defaultFilter: T;
  title: string;
  onChange: (id: T) => void;
  footerRightButton?: FooterButton<T>;
  footerLeftButton?: FooterButton<T>;
};

export const Filter = <T extends string>({
  options,
  activeFilter,
  defaultFilter,
  title,
  onChange,
  footerRightButton,
  footerLeftButton,
}: Props<T>) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<T>(defaultFilter);
  const label = formatMessage('filter.button.text');
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const { isExtraSmallScreen } = useBreakpoint();

  useEffect(() => {
    if (filter) {
      menuButtonRef.current?.focus();
    }
  }, [filter]);

  const onApply = () => {
    onChange(filter);
    handleOpenMenuChange(false);
  };

  const handleOpenMenuChange = (isOpen: boolean) => {
    setFilter(activeFilter);
    setIsMenuOpen(isOpen);
  };

  const renderOptions = () =>
    options.map(({ id, label, status }, index) => {
      const isSelected = activeFilter === id;
      return {
        label: (
          <Container
            data-testid={`filter-item-${id}`}
            key={`${id}.${index}`}
            paddingY="s"
            paddingLeft="xs"
            width="fit-content"
          >
            <Group spacing="xs">
              {status && !isSelected ? (
                <Group>
                  <StatusIndicator status={status}>
                    <Container paddingRight="xs">
                      <Text textStyle="body3Semi" color="global.neutral.1000">
                        {label}
                      </Text>
                    </Container>
                  </StatusIndicator>
                </Group>
              ) : (
                <Text textStyle="body3Semi" color="global.neutral.1000">
                  {label}
                </Text>
              )}
              {id === defaultFilter && (
                <Text textStyle="body3Semi" color="global.neutral.800">
                  {formatMessage('filter.item.default')}
                </Text>
              )}
            </Group>
          </Container>
        ),
        id,
        value: id,
        ariaLabel: label,
      };
    });

  const renderMenuOptions = () => {
    const options = renderOptions();
    return (
      <Group data-component="RadioGroup" variant="vertical" role="radiogroup" spacing="xxs">
        {options.map((option) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          <FloatingMenu.Item key={option.id} onClick={() => setFilter(option.id)} paddingY="none">
            <Radio
              {...option}
              key={option.id}
              value={option.value}
              onChange={() => setFilter(option.id)}
              isChecked={option.value === filter}
              id={option.id}
              data-testid={`radio-group-${option.id}`}
            />
          </FloatingMenu.Item>
        ))}
      </Group>
    );
  };

  const renderTrigger = () => {
    const iconButton = (
      <IconButton icon="filter" variant="primary" size="large" aria-label={label} data-testid="filter-trigger" />
    );

    if (defaultFilter !== activeFilter) {
      // We need the Box wrapper so the trigger will work properly
      return (
        <Box>
          <StatusIndicator status="informative">{iconButton}</StatusIndicator>
        </Box>
      );
    }

    return iconButton;
  };

  const renderMenuFooter = () => (
    <Container>
      <Divider />
      <Container data-testid="filter-footer-menu" paddingX="s" paddingY="s">
        <Group justifyContent={footerLeftButton ? 'space-between' : 'flex-end'} alignItems="center">
          {footerLeftButton ? (
            <Button
              label={footerLeftButton.label}
              variant={footerLeftButton.variant}
              aria-label={footerLeftButton.getAriaLabel?.(options.find(({ id }) => id === filter)?.label ?? '')}
              data-testid={footerLeftButton.testId}
              isDisabled={footerLeftButton.disabledOnDefault && filter === defaultFilter}
              onClick={() => footerLeftButton.onClick(filter)}
              {...(isExtraSmallScreen ? { padding: 'xs' } : {})}
            />
          ) : null}

          <Group>
            {footerRightButton ? (
              <Button
                label={footerRightButton.label}
                variant={footerRightButton.variant}
                aria-label={footerRightButton.getAriaLabel?.(options.find(({ id }) => id === filter)?.label ?? '')}
                data-testid={footerRightButton.testId}
                isDisabled={footerRightButton.disabledOnDefault && filter === defaultFilter}
                onClick={() => footerRightButton.onClick(filter)}
                {...(isExtraSmallScreen ? { padding: 'xs' } : {})}
              />
            ) : null}

            <Button
              label={formatMessage('filter.button.apply')}
              onClick={onApply}
              data-testid="filter-apply"
              {...(isExtraSmallScreen ? { padding: 'xs' } : {})}
            />
          </Group>
        </Group>
      </Container>
    </Container>
  );

  return (
    <Menu
      ref={menuButtonRef}
      data-testid="filter-menu"
      trigger={renderTrigger()}
      onOpenChange={handleOpenMenuChange}
      isOpen={isMenuOpen}
      title={title}
    >
      {renderMenuOptions()}
      {renderMenuFooter()}
    </Menu>
  );
};
