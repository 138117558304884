import {
  Button,
  Container,
  FormSelectNewOption,
  Group,
  Icon,
  RadioGroup,
  SelectNew,
  SelectNewOption,
} from '@melio/penny';
import { FeeCatalog, FundingSource, FundingSourceType } from '@melio/platform-api';
import { ComponentProps } from 'react';

import { RadioLabelWithTooltip } from '../util/RadioLabelWithTooltip';
import { FundingSourceCard } from './FundingSourceCard/FundingSourceCard';

export type FundingSourceSectionProps = {
  isLoading: boolean;
  feeCatalog?: FeeCatalog[];

  selectedType: FundingSourceType;
  selector: SelectorProps;
  radioToggle: RadioToggleProps;
};

type SelectorProps = Omit<ComponentProps<typeof SelectNew<FundingSource, SelectNewOption<FundingSource>>>, 'footer'> & {
  addButton?: Omit<ComponentProps<typeof Button>, 'isFullWidth'>;
};
type RadioToggleProps = Pick<ComponentProps<typeof RadioGroup>, 'onChange' | 'isReadOnly'> & {
  hide: boolean;
  options: {
    value: FundingSource['type'];
    label: ComponentProps<typeof RadioLabelWithTooltip>;
    ariaLabel: string;
    disabled: boolean;
  }[];
};

export const FundingSourceSection = ({
  selectedType,
  selector,
  radioToggle,

  isLoading,
  feeCatalog,
}: FundingSourceSectionProps) => {
  const { hide, ...radioProps } = radioToggle;
  const { addButton, ...selectorProps } = selector;
  return (
    <Group variant="vertical" spacing="xs">
      {!hide && (
        <RadioGroup
          {...radioProps}
          data-testid="funding-source-type"
          variant="horizontal"
          value={selectedType}
          options={radioToggle.options.map(({ label, ...option }) => ({
            ...option,
            id: createTypeId(option.value),
            label: <RadioLabelWithTooltip {...label} />,
            disabled: { isDisabled: option.disabled },
          }))}
        />
      )}

      <SelectNew
        {...selectorProps}
        footer={
          addButton ? (
            <SelectNew.Footer>
              <Button
                {...addButton}
                leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
                isFullWidth
              />
            </SelectNew.Footer>
          ) : undefined
        }
        aria-describedby={selectedType ? createTypeId(selectedType) : undefined}
        aria-labelledby="pay-from"
        data-testid="funding-source-dropdown"
        valueRenderer={(option) => <ValueRenderer {...option} feeCatalog={feeCatalog} />}
        optionRenderer={OptionRenderer}
        size="large"
        isLoading={isLoading}
        shouldHideClearButton
      />
    </Group>
  );
};

const createTypeId = (type: FundingSource['type']) => `${type}-option`;

type OptionValueRendererProps = FormSelectNewOption<FundingSource> & { feeCatalog?: FeeCatalog[] };

const ValueRenderer = (props: OptionValueRendererProps) => (
  <Container width="full" paddingY="s" data-testid={`funding-source-dropdown-selected-${props.value.id}`}>
    <FundingSourceCard
      fundingSource={props.value}
      feeCatalog={props.feeCatalog}
      isDisabled={!!props.disabled?.isDisabled}
    />
  </Container>
);
const OptionRenderer = (props: OptionValueRendererProps) => (
  <Container width="full" data-testid={`funding-source-dropdown-item-${props.value.id}`}>
    <FundingSourceCard fundingSource={props.value} isDisabled={!!props.disabled?.isDisabled} />
  </Container>
);
