import { Modal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

export type PaymentExceedsFinancingLimitModalProps = {
  onCancel: VoidFunction;
  onContinue: VoidFunction;
  isOpen: boolean;
};

const PAGE_NAME = 'payment-exceeds-$50K-limit';
export const PaymentExceedsFinancingLimitModalComponent = (props: PaymentExceedsFinancingLimitModalProps) => {
  const { onCancel, onContinue, isOpen } = props;
  const { formatMessage } = useMelioIntl();
  const { track, createTrackHandler } = useAnalytics();

  const trackAndHandleOnClick = createTrackHandler<{
    FinancingExperience: 'ftx';
    isEligibleToFinancing: true;
    PageName: string;
    Cta: 'apply-now' | 'cancel' | 'exit';
  }>('Payment', 'Click', {
    FinancingExperience: 'ftx',
    isEligibleToFinancing: true,
    PageName: PAGE_NAME,
  });

  useEffect(() => {
    if (props.isOpen) {
      track('Payment', 'View', {
        PageName: PAGE_NAME,
        FinancingExperience: 'ftx',
        isEligibleToFinancing: true,
      });
    }
  }, [track, props.isOpen]);
  return (
    <Modal
      data-testid="financing-payment-exceeds-limit-modal"
      onClose={() => trackAndHandleOnClick({ Cta: 'exit' }, onCancel)}
      header={formatMessage('widgets.fundingSources.financingExceedsLimitModal.title')}
      isOpen={isOpen}
      primaryButton={{
        label: formatMessage('widgets.fundingSources.financingExceedsLimitModal.primaryButton'),
        onClick: () => trackAndHandleOnClick({ Cta: 'apply-now' }, onContinue),
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('widgets.fundingSources.financingExceedsLimitModal.secondaryButton'),
        variant: 'tertiary',
        onClick: () => trackAndHandleOnClick({ Cta: 'cancel' }, onCancel),
      }}
    >
      <Text>{formatMessage('widgets.fundingSources.financingExceedsLimitModal.description')}</Text>
    </Modal>
  );
};
