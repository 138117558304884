import { SectionBanner } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';

import { usePreSeason1099SectionBanner } from './usePreSeason1099SectionBanner';

export const PreSeason1099SectionBanner = () => {
  const { onCloseBanner, bannerContent } = usePreSeason1099SectionBanner();
  useAnalyticsView('Dashboard', true, true, {
    UserMessage: 'tax-season-is-just-in-the-corner',
  });

  return (
    <SectionBanner
      showCloseIcon
      data-testid="pre-season-contractors-1099-dashboard-section-banner"
      onClose={onCloseBanner as VoidFunction}
      title={bannerContent.title}
      variant="informative"
      description={bannerContent.description}
    />
  );
};
