import { useNonEntityMutation } from '@melio/api-client';
import {
  ApiKeys,
  ApiRequest,
  GetVirtualCardAcceptanceResponse,
  PostVexAuthorizeVirtualCardSessionResponse,
  VexGuestApiClient,
  VexGuestApiInstance,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';

import { ApiError } from '../../core';

type CreateFunction = typeof VexGuestApiClient.postVexReActivateVirtualCard;
export const useVirtualCardAcceptance = (virtualCardId: string) =>
  useQuery<GetVirtualCardAcceptanceResponse, ApiError>({
    queryKey: ['VexGuestApiInstance', 'getVirtualCard', virtualCardId],
    queryFn: async () => {
      const { data } = await VexGuestApiInstance.getVirtualCard({ id: virtualCardId });
      return data;
    },
  });

export const useReActivateVirtualCard = () =>
  useNonEntityMutation<CreateFunction>(
    (virtualCardId) => VexGuestApiClient.postVexReActivateVirtualCard(virtualCardId),
    [ApiKeys.VexGuestApi, 'collection', 'default', 'postVexReActivateVirtualCard']
  );

export const useAuthorizeVirtualCardSession = (virtualCardId: string) =>
  useNonEntityMutation<
    ApiRequest<PostVexAuthorizeVirtualCardSessionResponse>,
    unknown,
    PostVexAuthorizeVirtualCardSessionResponse
  >(
    ({ nonce }: { nonce: string }) => VexGuestApiClient.postVexAuthorizeVirtualCardSession(virtualCardId, { nonce }),
    [ApiKeys.VexGuestApi, 'collection', 'default', 'postVexAuthorizeVirtualCardSession']
  );

export type { GetVirtualCardAcceptanceResponse };
