import { getInternationalFormattedIdentifierData, isInternationalDeliveryMethod } from '@melio/ap-domain';
import { Fee, MethodCard } from '@melio/ap-widgets';
import { DeliveryMethod, FeeType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { AddDeliveryMethodActionButton } from '../components/DeliveryMethodActions';
import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';
import { DeliveryMethodIcon } from '../components/DeliveryMethodIcon';

type Props = {
  deliveryMethod: DeliveryMethod | undefined;
  onClick?: VoidFunction;
};

export const InternationalCurrencyCloudDeliveryMethodCard = ({ deliveryMethod, onClick }: Props) => {
  const { formatMessage } = useMelioIntl();

  const identifierInfo =
    deliveryMethod?.details &&
    isInternationalDeliveryMethod(deliveryMethod) &&
    getInternationalFormattedIdentifierData(deliveryMethod);

  const caption = (() => {
    if (!identifierInfo) {
      return {
        secondary: formatMessage('widgets.addOrUpdateDeliveryMethod.internationalCurrencyCloud.comment'),
      };
    }

    return {
      primary: formatMessage('widgets.addOrUpdateDeliveryMethod.internationalCurrencyCloud.info', identifierInfo),
    };
  })();

  return (
    <MethodCard
      testIds={{ root: 'MethodCard-international-currency-cloud' }}
      displayName={formatMessage('widgets.addOrUpdateDeliveryMethod.internationalCurrencyCloud.title')}
      helperText={formatMessage('widgets.addOrUpdateDeliveryMethod.internationalCurrencyCloud.feeText', {
        internationalFee: <Fee feeType={FeeType.International} suffix="fee" />,
      })}
      icon={<DeliveryMethodIcon iconType="international" />}
      actionElement={
        !identifierInfo ? (
          <AddDeliveryMethodActionButton
            aria-label={formatMessage('widgets.addOrUpdateDeliveryMethod.internationalCurrencyCloud.add.ariaLabel', {
              fee: <Fee feeType={FeeType.International} />,
            })}
            data-testid="add-delivery-method-card-international-currency-cloud"
            onClick={onClick}
          />
        ) : undefined
      }
    >
      <DeliveryMethodCaption {...caption} />
    </MethodCard>
  );
};
