import { useMelioForm, UseMelioFormProps } from '@melio/penny';
import * as Yup from 'yup';

export type AddVendorPaypalAccountForm = {
  email: string;
};
const schema = Yup.object().shape({
  email: Yup.string().email().required(),
});
export type PaypalBalanceAccountFormProps = {
  onSubmit: UseMelioFormProps<AddVendorPaypalAccountForm>['onSubmit'];
  isLoading?: boolean;
  defaultValues?: AddVendorPaypalAccountForm;
};
export const usePaypalBalanceAccountForm = ({ onSubmit, defaultValues, isLoading }: PaypalBalanceAccountFormProps) =>
  useMelioForm<AddVendorPaypalAccountForm>({
    onSubmit,
    isLoading,
    schema,
    defaultValues,
    subscribeToDefaultValuesChanges: true,
  });
