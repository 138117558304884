import { Link, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';

import { CheckProtectionModalWidget } from '../CheckProtectionModal';

export type FundsDebitDateSectionProps = {
  daysToDebit: number;
  showTitle?: boolean;
  isDmCheck?: boolean;
  debitDate?: Date;
};

export const FundsDebitDateSection = ({ showTitle, isDmCheck, daysToDebit, debitDate }: FundsDebitDateSectionProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const [showCheckProtectionModal, { toggle: toggleShowCheckProtectionModal }] = useBoolean(false);

  const onClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    toggleShowCheckProtectionModal();
  };

  const getDescription = () => {
    if (isDmCheck) {
      return formatMessage('widgets.fundsDebitDateSection.description.check', {
        link: (
          <Link
            data-testid="funds-debit-date-section-link"
            onClick={onClick}
            label={formatMessage('widgets.fundsDebitDateSection.check.linkText')}
            href="#"
          />
        ),
      });
    }

    return formatMessage('widgets.fundsDebitDateSection.description', { daysToDebit });
  };

  return (
    <>
      <SectionBanner
        data-testid="funds-debit-date-section"
        title={
          showTitle && debitDate
            ? formatMessage('widgets.fundsDebitDateSection.title', {
                debitDate: formatDate(debitDate, { dateStyle: 'medium' }),
              })
            : undefined
        }
        description={getDescription()}
        variant="informative"
      />
      <CheckProtectionModalWidget isOpen={showCheckProtectionModal} onClose={toggleShowCheckProtectionModal} />
    </>
  );
};
