import { Icon } from '@melio/penny';
import { DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { MethodCard } from '../../../../MethodCard/MethodCard.widget';
import { DeliveryMethodCaption } from '../components/DeliveryMethodCaption';

export const BillerAccountDeliveryMethodCard = () => {
  const { formatMessage } = useMelioIntl();
  const displayName = formatMessage('widgets.addOrUpdateDeliveryMethod.billerAccount.title');
  const helperText = formatMessage('widgets.addOrUpdateDeliveryMethod.billerAccount.feeText');
  const additionalInfo = formatMessage('widgets.addOrUpdateDeliveryMethod.billerAccount.info.additionalInfo');

  return (
    <MethodCard
      icon={<Icon type="directory-payment" />}
      displayName={displayName}
      helperText={helperText}
      data-component="BillerAccountDeliveryMethodCard"
      testIds={{
        root: `MethodCard-${DeliveryMethodType.BillerAccount}`,
        header: `method-card-type-${DeliveryMethodType.BillerAccount}`,
      }}
    >
      <DeliveryMethodCaption primary={additionalInfo} />
    </MethodCard>
  );
};
