import { useVendorNotPermitted } from '@melio/ap-domain';
import { EbillCellBadge, LockedVendorIcon, VendorAvatar } from '@melio/ap-widgets';
import { Container, Group, NakedButton, Pill, Table, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Payment, VendorEBillStatusEnum } from '@melio/platform-api';
import { identity } from 'lodash';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../../../utils';
import { FocusSkipToComponent, FocusSkipToComponentProps } from '../../../FocusSkipToComponent';
import { getFooterHeight } from '../../ApprovalsTab/utils';
import { displayAccountNumber } from '../../VendorsTab/utils';

export type PaymentsTabVendorNameCellProps = {
  payment: Payment;
  focusProps?: FocusSkipToComponentProps;
  rowSelectedCount?: number;
};
export const PaymentsTabVendorNameCell = ({
  payment,
  focusProps,
  rowSelectedCount = 0,
}: PaymentsTabVendorNameCellProps) => {
  const navigate = useNavigate();
  const { navigateToEbillActivationForm, navigateToAutopayForm } = useActivitiesNavigate();
  const { isExtraSmallScreen } = useBreakpoint();
  const { track } = useAnalytics();
  const { search } = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const [mouseDown, setMouseDown] = useState(false);
  const { vendor } = payment;
  const description = [vendor?.nickname, displayAccountNumber(vendor?.accountNumber ?? '')]
    .filter(identity)
    .join(' | ');
  const isVendorNotPermitted = useVendorNotPermitted(payment.vendor);

  const onVendorNameClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    track('Dashboard', 'Click', {
      Intent: 'vendor-details',
      Cta: 'vendor-name-click',
    });

    return navigate({ pathname: `vendor/${payment.vendorId}`, search });
  };

  const vendorName = payment.vendor?.name ?? '';
  const vendorExternalLogoUrl = payment.vendor?.externalLogoUrl;
  const isManaged = payment.vendor?.isManaged;
  const isVendorDeleted = !!payment.vendor?.history.deletedAt;

  return (
    <Table.Cell data-testid={`payments-tab-row-${payment.id}-vendor-name-cell`} ref={ref}>
      <Group alignItems="center">
        {!isExtraSmallScreen && (
          <VendorAvatar name={vendorName || '?'} logoUrl={vendorExternalLogoUrl} isManaged={isManaged} />
        )}
        <Group variant="vertical" spacing="xxs">
          <Group alignItems="center" spacing="xxs">
            {isVendorNotPermitted ? <LockedVendorIcon /> : null}

            {isVendorDeleted ? (
              <Group alignItems="center" spacing="xs">
                <Text textStyle="body3" color="global.neutral.800">
                  {vendorName}
                </Text>
                <Pill type="secondary" label="Deleted" status="neutral" />
              </Group>
            ) : (
              <Container>
                <NakedButton
                  shouldSupportEllipsis
                  variant="secondary"
                  label={vendorName}
                  data-testid={`payments-tab-row-${payment.id}-vendor-name-cell-button`}
                  onClick={onVendorNameClick}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  onMouseDown={() => {
                    setMouseDown(true);
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  onMouseUp={() => {
                    setMouseDown(false);
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  onFocus={() => {
                    if (mouseDown) {
                      setMouseDown(false);
                      return;
                    }
                    if (ref.current && rowSelectedCount > 0) {
                      const bottom = ref.current.getBoundingClientRect().bottom;
                      if (bottom > window.innerHeight - getFooterHeight()) {
                        ref.current.scrollIntoView({ block: 'center' });
                      }
                    }
                  }}
                  aria-haspopup="dialog"
                />
              </Container>
            )}
            {!isVendorDeleted && payment.vendor && payment.vendor.eBillStatus !== VendorEBillStatusEnum.NotCapable && (
              <EbillCellBadge
                vendor={payment.vendor}
                onEBillSubscribeClick={(vendorId) => navigateToEbillActivationForm({ vendorId })}
                onAutoPayActivationClick={(vendorId) => navigateToAutopayForm({ vendorId })}
                isReadOnly={isVendorNotPermitted}
              />
            )}
          </Group>
          <Text textStyle="body4" color="global.neutral.800" shouldSupportEllipsis>
            {description}
          </Text>
          {focusProps && <FocusSkipToComponent {...focusProps} />}
        </Group>
      </Group>
    </Table.Cell>
  );
};
