import { useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { useRedirects, useWizardParams } from '../hooks';
import { SubscriptionCheckoutActivity, SubscriptionPlansActivity, SubscriptionSuccessActivity } from '../ui';

export const SubscriptionSelectPlanRoute = () => {
  const isFirstRouteInWizard = useIsFirstRouteInWizard();
  const { goToReturnUrlOrDashboard } = useRedirects();

  const { returnUrl, planId } = useWizardParams();

  const handleOnClose = () => {
    goToReturnUrlOrDashboard();
  };

  if (!planId) {
    return <Navigate to="/error" />;
  }

  return (
    <Routes>
      <Route path="/plans/*" element={<SubscriptionPlansActivity returnUrl={returnUrl} onBack={handleOnClose} />} />
      <Route
        path="/checkout/*"
        element={
          <SubscriptionCheckoutActivity
            returnUrl={returnUrl}
            planId={planId}
            onClose={handleOnClose}
            onBackOverride={isFirstRouteInWizard ? handleOnClose : undefined}
          />
        }
      />
      <Route path="/success/*" element={<SubscriptionSuccessActivity />} />
    </Routes>
  );
};

const useIsFirstRouteInWizard = () => {
  const location = useLocation();
  const prevLocationsRef = useRef<string[]>([]);
  useEffect(() => {
    if (prevLocationsRef.current.length <= 1) {
      prevLocationsRef.current.push(location.pathname);
    }
  }, [location]);

  return prevLocationsRef.current.length <= 1;
};
