import { Checkbox, Container, FloatingMenu, Group, Pill, Text } from '@melio/penny';
import { Ref } from 'react';

import { FilterOption } from '../components/Filters/types';

type Props = {
  options: FilterOption[];
  selected: string[];
  onChange: (value: string) => void;
  firstOptionRef?: Ref<HTMLDivElement>;
};

export const FilterMenuOptionsMulti = ({ options, selected, onChange, firstOptionRef }: Props) => (
  <Group data-component="CheckboxGroup" variant="vertical" spacing="xxs">
    {options.map((option, index) => (
      <FloatingMenu.Item
        {...(index === 0 && { ref: firstOptionRef })}
        data-testid={`option-menu-item-${option.value}`}
        key={option.value}
        onClick={() => onChange(option.value)}
      >
        <Checkbox
          key={option.value}
          data-testid={`checkbox-${option.value}`}
          size="large"
          isChecked={selected.includes(option.value)}
          onChange={() => onChange(option.value)}
        />
        <Container paddingLeft="xs" alignItems="center">
          {option.backgroundColor ? (
            <Pill status={option.backgroundColor} type="secondary" label={option.label} />
          ) : (
            <Text textStyle="body3">{option.label}</Text>
          )}
        </Container>
      </FloatingMenu.Item>
    ))}
  </Group>
);
