import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { DeliveryPreference, DeliveryPreferenceType, FundingSource } from '@melio/platform-api';
import { useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';
import { isSameDay } from 'date-fns';

import { useFundingSourceUnverifiedText } from '../../hooks/fundingSource.hooks';

export const useEstimatedDeliveryDateHelperText = (
  { type, effectiveScheduleDate }: DeliveryPreference,
  fundingSource: FundingSource,
  dueDate?: Date,
  isDueDatePassed?: boolean
) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { isByDeliveryDate, scheduleBy } = usePaymentSchedulingPreference();
  const { createDate } = useDateUtils();
  const [eoyCheckEnabled] = usePartnerFeature('eoyCheck', false);

  const unVerifiedFundingSourceText = useFundingSourceUnverifiedText({ fundingSource, effectiveScheduleDate });
  if (unVerifiedFundingSourceText) {
    return unVerifiedFundingSourceText;
  }

  const dueDatePastText = isDueDatePassed
    ? formatMessage('widgets.deliveryDateListItemByPayor.dueDatePast.helperText', {
        date: formatDate(dueDate, { dateStyle: 'medium' }),
      })
    : void 0;

  switch (type) {
    case 'check': {
      const helperText = isByDeliveryDate
        ? dueDatePastText ||
          formatMessage('widgets.deliveryDateListItemByPayor.check.estimatedDeliveryDate.byDeliveryDate.helperText')
        : dueDatePastText;

      return eoyCheckEnabled
        ? formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.check.tooltipEOYText`)
        : helperText;
    }
    case 'ach':
      return (
        dueDatePastText || formatMessage('widgets.deliveryDateListItemByPayor.ach.estimatedDeliveryDate.helperText')
      );
    case 'expedited-ach':
      return (
        dueDatePastText || formatMessage('widgets.deliveryDateListItemByPayor.ach.estimatedDeliveryDate.helperText')
      );
    case 'international':
      return dueDatePastText;
    case 'virtual': {
      const isDeductionToday = isSameDay(effectiveScheduleDate, createDate());
      const estimatedDeliveryDateMessage =
        isByDeliveryDate && isDeductionToday
          ? formatMessage(
              'widgets.deliveryDateListItemByPayor.unilateral.estimatedDeliveryDate.byDeliveryDate.schedulingExtensionHelperText'
            )
          : formatMessage(
              `widgets.deliveryDateListItemByPayor.unilateral.estimatedDeliveryDate.${scheduleBy}.helperText`,
              {
                date: formatDate(effectiveScheduleDate, { dateStyle: 'medium' }),
              }
            );

      return estimatedDeliveryDateMessage;
    }
    default:
      return void 0;
  }
};

export const useEstimatedDeliveryDateLabel = (deliveryPreferenceType: DeliveryPreferenceType) => {
  const { isByScheduleDate } = usePaymentSchedulingPreference();
  const { formatMessage } = useMelioIntl();

  if (isByScheduleDate) {
    return formatMessage('widgets.deliveryDateListItemByPayor.label.default');
  }

  switch (deliveryPreferenceType) {
    case 'check':
      return formatMessage('widgets.deliveryDateListItemByPayor.label.check');
    case 'virtual':
      return formatMessage('widgets.deliveryDateListItemByPayor.label.unilateral');
    default:
      return formatMessage('widgets.deliveryDateListItemByPayor.label.default');
  }
};
