import { useCreateMutation } from '@melio/api-client';
import { PostVendorsImportContractorsRequest, VendorsApiClient } from '@melio/platform-api-axios-client';

export const useVendorsImport1099Contractors = () => {
  const { mutateAsync, ...rest } = useCreateMutation(
    // eslint-disable-next-line
    // @ts-ignore
    (data: PostVendorsImportContractorsRequest['data']) => VendorsApiClient.postVendorsImportContractors({ data }),
    ['VendorsApi', 'model', 'importContractors', 'default']
  );

  return {
    importContractors: mutateAsync,
    ...rest,
  };
};
