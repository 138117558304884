import { useLocation } from 'react-router-dom';
import { DeliveryMethodSelectionActivity } from '@melio/ap-activities';
import { OriginFlow, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useVendorDeliveryMethodTypeOptions } from '@melio/platform-api';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { useRouter } from '@/hooks/router.hooks';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';

export const NewVendorDeliveryMethodSelection = withAnalyticsContext(({ vendorId }: { vendorId: string }) => {
  const { goToVendor, goToAddNewBill } = useRouter();
  const { state } = useLocation();
  const { returnUrl } = (state as { returnUrl?: string }) || {};
  useAnalyticsContext({ globalProperties: { Flow: 'vendor' } });

  const { data: deliveryMethodTypeOptions, isFetching, refetch } = useVendorDeliveryMethodTypeOptions({ vendorId });

  const backToVendorTab = (vendorId: string) => {
    refetch();
    goToVendor(vendorId);
  };

  const handleClose = () => {
    backToVendorTab(vendorId);
  };

  const handleContinue = (deliveryMethodId: string) => {
    goToAddNewBill({ vendorId, deliveryMethodId, originFlow: OriginFlow.VendorsFlow, returnUrl }, { keepToast: true });
  };

  const handleBack = () => {
    backToVendorTab(vendorId);
  };

  const handleSkip = () => {
    backToVendorTab(vendorId);
  };

  const handleDone = (deliveryMethodId: string, target?: 'continue' | 'close') => {
    emitAppEvent(APP_EVENTS.VENDOR_DELIVERY_METHODS_UPDATED, { vendorId });
    if (target === 'close') handleClose();
    else handleContinue(deliveryMethodId);
  };

  return (
    <WithLoading isLoading={isFetching}>
      <DeliveryMethodSelectionActivity
        vendorId={vendorId}
        onClose={handleClose}
        onDone={(deliveryMethod, target) => handleDone(deliveryMethod.id, target)}
        onBack={handleBack}
        onSkip={handleSkip}
        origin="vendor"
        deliveryMethodTypeOptions={deliveryMethodTypeOptions}
      />
    </WithLoading>
  );
});
