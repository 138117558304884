import { FeeCatalogValueTypeEnum, FeeType, useFeeCatalog } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

const roundFee = (fee: number) => Math.round(fee * 100) / 100;

type FeeProps = {
  feeType: FeeType;
  suffix?: string;
};

export const Fee = ({ feeType, suffix }: FeeProps) => {
  const { data: fees = [] } = useFeeCatalog();
  const { formatMessage, formatCurrency } = useMelioIntl();

  const catalogFee = fees.find((it) => it.feeType === feeType);
  if (!catalogFee) {
    return <>{formatMessage('app.noFee.label')}</>;
  }

  const feeValue =
    catalogFee.valueType === FeeCatalogValueTypeEnum.Fixed
      ? formatCurrency(catalogFee.value)
      : `${roundFee(catalogFee.value)}%`;

  return <>{`${feeValue}${suffix ? ` ${suffix}` : ''}`}</>;
};
