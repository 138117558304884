import { PropsWithChildren } from 'react';
import { Box } from '@chakra-ui/react';
import { useConfig } from '@melio/platform-provider';
import { useDevice } from '@melio/platform-utils';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePartnerConfig } from '@/hooks/partners';
import { useIsMobile as useSmallScreen } from '@/hooks/useIsMobile';
import { AccountCentricViewMessaging } from '@/widgets/AccountCentricViewMessaging/AccountCentricViewMessaging.widget';
import { AppLayoutTheme } from './appLayout.theme';
import { FooterLayout } from './footerLayout.component';
import { LeftLayout, LeftLayoutProps } from './leftLayout.component';
import { MiniTopNavLayout } from './mini-topnav.layout.component';
import { TopLayout } from './topnav.layout.component';
import { TopNavProps } from './topnav.layout.types';

type Props = PropsWithChildren<{
  leftLayout?: LeftLayoutProps;
  topNav?: TopNavProps;
  notificationHeader?: () => JSX.Element;
}>;

export const AppLayout = ({ children, leftLayout, topNav, notificationHeader }: Props) => {
  const isSmallScreen = useSmallScreen();
  const { isDesktop } = useDevice();
  const isStickyHeader = isDesktop && isSmallScreen;
  const {
    settings: { isEmbeddedExperience, showFooterInEmbeddedExperience },
    layout: { maxWidth },
  } = useConfig();
  const styles = useAppTheme(AppLayoutTheme, {
    isStickyHeader,
    maxWidth: isEmbeddedExperience && window.self !== window.top ? maxWidth : undefined,
  });
  const {
    partnerConfig: { hideFooter },
  } = usePartnerConfig();
  const shouldHideFooter = hideFooter || (isEmbeddedExperience && !showFooterInEmbeddedExperience);

  return (
    <Box data-component={'app-layout'} sx={styles['layoutContainer']}>
      {notificationHeader && notificationHeader()}
      {!isStickyHeader && topNav && !isEmbeddedExperience && (
        <>
          <AccountCentricViewMessaging />
          {isSmallScreen ? <MiniTopNavLayout {...topNav} /> : <TopLayout {...topNav} />}
        </>
      )}
      <Box data-component={'down-layout'} sx={styles['downLayoutContainer']}>
        {leftLayout && <LeftLayout {...leftLayout} />}
        <Box data-component={'right-layout'} sx={styles['rightLayoutContainer']}>
          {isStickyHeader && topNav && !isEmbeddedExperience && (
            <>
              <AccountCentricViewMessaging sticky />
              <MiniTopNavLayout {...topNav} sticky />
            </>
          )}
          <Box as="main" role="main" data-component={'app-screen'} sx={styles['appScreen']}>
            {children}
          </Box>
          {!shouldHideFooter && <FooterLayout />}
        </Box>
      </Box>
    </Box>
  );
};
