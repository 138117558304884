import { IconKey } from '@melio/penny';
import {
  DeliveryMethodType,
  FreeChecksData,
  FundingSource,
  SupportedDeliveryMethodTypeOption,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';

import { SupportedInternationalType, useDeliveryMethodFee } from '../../../utils';

export type AddDeliveryMethodUtilsProps = {
  link?: React.ReactNode;
  fundingSourceType?: FundingSource['type'];
  freeChecks?: FreeChecksData;
  suspense?: boolean;
  supportedInternationalType?: SupportedInternationalType;
} & SupportedDeliveryMethodTypeOption;

export const useAddDeliveryMethodUtils = ({
  type: deliveryMethodType,
  supported,
  link,
  fundingSourceType,
  freeChecks,
  reason,
  supportedInternationalType = 'international-usd',
  suspense,
}: AddDeliveryMethodUtilsProps) => {
  const { getFee, isFetched: isFeeFetched } = useDeliveryMethodFee(fundingSourceType || 'bank-account', {
    suspense,
  });
  const {
    settings: { promoteAchDeliveryMethod },
  } = useConfig();
  const { formatMessage } = useMelioIntl();
  const [eoyCheckEnabled] = usePartnerFeature('eoyCheck', false);
  const [isFofEnabled] = usePartnerFeature('FofImprovements', false);

  const [isCloverFreeAchPromotion] = useDevFeature<boolean>(FeatureFlags.CloverFreeAchPromotion, false);

  const isNewDeliveryMethodType = (deliveryMethodType: SupportedDeliveryMethodTypeOption['type']) =>
    supportedInternationalType !== 'international-usd' ||
    deliveryMethodType === DeliveryMethodType.VirtualCard ||
    (deliveryMethodType === DeliveryMethodType.BankAccount && promoteAchDeliveryMethod) ||
    (deliveryMethodType === DeliveryMethodType.VirtualAccount && isFofEnabled);

  const isFastestDeliveryMethodType = (deliveryMethodType: SupportedDeliveryMethodTypeOption['type']) =>
    deliveryMethodType === DeliveryMethodType.BankAccount && isFofEnabled;

  const getDisabledDescription = (): string | undefined => {
    if (supported) {
      return undefined;
    }
    const isUnsupportedCardFundingSourceInUse =
      fundingSourceType === 'card' && reason === 'cardFundingSourceNotSupported';
    const isDisabledDueToBankPayment = reason === 'vendorIsBank';

    if (isDisabledDueToBankPayment) {
      switch (deliveryMethodType) {
        case 'bank-account':
        case 'virtual-account':
        case 'virtual-card':
        case 'international-account':
          return formatMessage('widgets.deliveryMethods.selectDeliveryMethodCard.disabledReason.bankRestriction');
      }
    }

    if (isUnsupportedCardFundingSourceInUse) {
      switch (deliveryMethodType) {
        case 'international-account':
          return formatMessage(
            'widgets.deliveryMethods.addDeliveryMethodCard.internationalAccount.description.disabled.unsupportedCardFundingSource'
          );
      }
    }

    switch (deliveryMethodType) {
      case 'virtual-card':
        return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.virtualCard.description.disabled');
      default:
        return undefined;
    }
  };

  return {
    getFee,
    getIsDisabled: () => !supported,

    getBadgeLabel: (): string | undefined => {
      if (isFastestDeliveryMethodType(deliveryMethodType)) {
        return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.badge.fastest');
      }
      if (isNewDeliveryMethodType(deliveryMethodType)) {
        return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.badge.new');
      }
      return undefined;
    },

    getBadgeAriaLabel: (): string | undefined => {
      if (isFastestDeliveryMethodType(deliveryMethodType)) {
        return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.badge.fastest.ariaLabel');
      }
      if (isNewDeliveryMethodType(deliveryMethodType)) {
        return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.badge.new.ariaLabel');
      }
      return undefined;
    },

    getTooltipLabel: (): string | undefined =>
      eoyCheckEnabled && deliveryMethodType === 'paper-check'
        ? formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.check.eoyTooltipDescription')
        : undefined,

    getLabel: () => {
      switch (deliveryMethodType) {
        case 'bank-account':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.bankAccount.label');
        case 'paper-check':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.check.label');
        case 'domestic-wire-account':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.domesticWire.label');
        case 'virtual-account':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.unilateral.label');
        case 'virtual-card':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.virtualCard.label');
        case 'international-account': {
          switch (supportedInternationalType) {
            case 'international-fx':
              return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.internationalFX.label');
            case 'international-usd':
              return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.internationalAccount.label');
            case 'international':
          }
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.international.label');
        }
        case 'paypal-balance':
          return formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.paypal.label');
      }
    },

    getDescription: (): {
      description: string;
      actionLabel?: string;
    } => {
      const unsupportedReason = getDisabledDescription();
      if (unsupportedReason) {
        return { description: unsupportedReason };
      }
      const fee = getFee(deliveryMethodType);

      const fundingSourceMessagePart = fundingSourceType ? 'hasFundingSource' : 'withoutFundingSource';

      switch (deliveryMethodType) {
        case 'bank-account': {
          if (isCloverFreeAchPromotion) {
            return {
              description: formatMessage(
                'widgets.deliveryMethods.addDeliveryMethodCard.bankAccount.description.freeAchPromotion'
              ),
            };
          }
          return {
            description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.bankAccount.description'),
          };
        }
        case 'paper-check': {
          if (fee) {
            const freeChecksPromotionMessagePart = freeChecks?.total ? 'withChecksPromotion' : 'noChecksPromotion';

            return {
              description: formatMessage(
                `widgets.deliveryMethods.addDeliveryMethodCard.check.description.withFee.${fundingSourceMessagePart}.${freeChecksPromotionMessagePart}`,
                { fee, monthlyFreeChecksPromotion: freeChecks?.total, link }
              ),
            };
          } else {
            return {
              description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.check.description.noFee', {
                link,
              }),
            };
          }
        }
        case 'domestic-wire-account':
          if (fee) {
            return {
              description: formatMessage(
                'widgets.deliveryMethods.addDeliveryMethodCard.domesticWire.description.withFee',
                {
                  fee,
                }
              ),
            };
          } else {
            return {
              description: formatMessage(
                'widgets.deliveryMethods.addDeliveryMethodCard.domesticWire.description.noFee'
              ),
            };
          }
        case 'virtual-account':
          return {
            description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.unilateral.description'),
          };
        case 'virtual-card': {
          const actionLabel = formatMessage(
            'widgets.deliveryMethods.addDeliveryMethodCard.virtualCard.description.action'
          );

          return {
            description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.virtualCard.description'),
            actionLabel,
          };
        }
        case 'international-account':
          switch (supportedInternationalType) {
            case 'international-fx':
              return {
                description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.internationalFX.description'),
              };
            case 'international':
              return {
                description: formatMessage(
                  'widgets.deliveryMethods.addDeliveryMethodCard.internationalFX.description.new',
                  { fee }
                ),
              };
            case 'international-usd':
          }
          return fee
            ? {
                description: formatMessage(
                  'widgets.deliveryMethods.addDeliveryMethodCard.internationalAccount.description.withFee',
                  {
                    fee,
                  }
                ),
              }
            : {
                description: formatMessage(
                  'widgets.deliveryMethods.addDeliveryMethodCard.internationalAccount.description.noFee'
                ),
              };
        case 'paypal-balance':
          return { description: formatMessage('widgets.deliveryMethods.addDeliveryMethodCard.paypal.description') };
      }
    },

    getIconKey: (): IconKey => {
      switch (deliveryMethodType) {
        case 'bank-account':
          return 'bank';
        case 'paper-check':
          return 'paper-check';
        case 'domestic-wire-account':
          return 'bank';
        case 'virtual-account':
          return 'email';
        case 'international-account':
          return supportedInternationalType === 'international-fx' ? 'foreign-currency' : 'international';
        case 'virtual-card':
          return 'single-use-card';
        case 'paypal-balance':
          return 'paypal';
      }
    },

    isLoading: !isFeeFetched,
  };
};
