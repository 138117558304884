const modalVerticalPadding = '32px';

export const OnboardingPaywallModalBaseTheme = {
  drawerHeader: {
    justifyContent: 'start',
  },
  drawerContentWrapper: {
    borderTopRadius: 'global.400',
    background: 'white',
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: '88px',
    maxHeight: `calc(100dvh - 16px)`,
    overflow: 'auto',
  },
  drawerCloseButton: {
    position: 'absolute',
    top: 's',
    right: 's',
    zIndex: 1,
  },
  modalContentWrapper: {
    background: 'white',
    minHeight: '248px',
    top: `${modalVerticalPadding}`,
    maxHeight: `calc(100vh - 2*${modalVerticalPadding})`,
    justifyContent: 'center',
    padding: 'none',
    overflow: 'hidden',
    zIndex: 'modal',
    marginY: 'none',
    borderRadius: 'global.400',
    boxShadow: 500,
    maxWidth: '1100px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    maxHeight: 'inherit',
    minHeight: 'inherit',
    paddingBottom: 'm',
    justifyContent: 'inherit',
  },
  modalCloseButton: {
    position: 'absolute',
    top: 'm',
    right: 'm',
    zIndex: 'docked',
  },
};
