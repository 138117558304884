/* eslint-disable max-lines */
import { datadogRum } from '@datadog/browser-rum';
import { isFXCurrency } from '@melio/ap-domain';
import { fsTypesOptionsToFundingSourceTypesOptions, FundingSourceTypesOption } from '@melio/ap-widgets';
import { sanitizeStringForAnalytics, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  Card,
  DeliveryMethod,
  FinancingEligibilityStatus,
  FundingSource,
  FundingSourceType,
  useBill,
  useCollaborator,
  useFeeCatalog,
  useFinancingOrgEligibility,
  useFinancingRepaymentTerms,
  useFundingSources,
  useVendor,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { usePermissions } from '@melio/platform-permissions';
import { usePartnerFeature } from '@melio/platform-provider';
import { useBoolean, useSystemMessage, withSystemMessageProvider } from '@melio/platform-utils';
import { useEffect, useRef, useState } from 'react';

import { useIsMobile } from '../../../utils/viewport-utils/useIsMobile';
import { AddBankAccountActivity } from '../AddBankAccount';
import { AddCardFundingSourceActivity } from '../AddCardFundingSource';
import { getCreditCardFundingSourceNetwork, getFundingSourceTypeById } from '../fundingSources.utils';
import { MicroDepositsVerificationModalActivity } from '../MicroDepositsVerification';
import { ReconciliationModalActivity } from '../Reconciliation';
import { VendorDetailsModalActivity } from '../VendorDetails';
import { PaymentExceedsFinancingLimitModalComponent } from './components/PaymentExceedsFinancingLimitModalProps';
import { FundingSourceSelectionScreen, GoodsReceivedModalScreen, LoadingScreen } from './screens';
import { FundingSourceSelectionActivityProps } from './types';
import { useFundingSourceSelectionActivityStep } from './useFundingSourceSelectionActivityStep';
import { useFundingSourceSelectionModals } from './useFundingSourceSelectionModals';

const ALLOWED_ORG_FINANCING_STATUS: FinancingEligibilityStatus[] = [
  FinancingEligibilityStatus.Eligible,
  FinancingEligibilityStatus.Pending,
  FinancingEligibilityStatus.Suspended,
];
export const FundingSourceSelectionActivity = withSystemMessageProvider(
  withAnalyticsContext<FundingSourceSelectionActivityProps>(
    ({
      onBack: onFirstStepBack,
      onDone,
      onClose,
      onError,
      step,
      totalSteps,
      selectedId,
      paymentAmount: initialPaymentAmount,
      recurringPaymentDetails,
      vendorId,
      failedDate,
      originalFundingSourceId,
      billId,
      shouldAllowEditAmount,
      fundingSourceTypesOptions = [],
      shouldAllowFinancingOption: _shouldAllowFinancingOption,
      setAnalyticsProperties,
    }) => {
      const { currentStep, goToPreviousStep, goToStep } = useFundingSourceSelectionActivityStep({ onFirstStepBack });
      const [fundingSourceIdToVerify, setFundingSourceIdToVerify] = useState<FundingSource['id'] | void>();
      const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<string>();
      const [isFinancingSelected, setIsFinancingSelected] = useState<boolean>(false);
      const [paymentAmount, setPaymentAmount] = useState<number>(initialPaymentAmount);
      const [cardType, setCardType] = useState<Card['type']>();
      const [shouldShowMccErrorBanner, showMccErrorBanner] = useBoolean(false);
      const [fsTypesOptions, setFsTypesOptions] = useState<FundingSourceTypesOption[]>(fundingSourceTypesOptions);
      const [isPaymentAmountExceedsFinancingModalOpen, setiIsPaymentAmountExceedsFinancingModalOpen] = useState(false);
      const [isFinancingApplicationFlowFromPaymentEnabled] = useDevFeature(
        FeatureFlags.PlatformFinancingApplicationFlowFromPayment,
        false
      );
      const { showMessage } = useSystemMessage();
      const { formatMessage } = useMelioIntl();
      const autoNavToNextStep = useRef<boolean>(true);

      const [showInsufficientCreditBanner] = usePartnerFeature('showInsufficientCreditBanner', false);
      const fundingSourceParams = showInsufficientCreditBanner ? { params: { expand: 'availableBalance' } } : undefined;
      const { data: fundingSources, isLoading: isLoadingFundingSources } = useFundingSources(fundingSourceParams);
      const { data: vendor, isLoading: isLoadingVendor } = useVendor({ id: vendorId });
      const { data: actor, isLoading: isLoadingActor } = useCollaborator({ id: 'me' });
      const { data: bill, isLoading: isLoadingBill } = useBill({ id: billId });

      const isMobile = useIsMobile();

      const { can } = usePermissions();
      const isFinancingPermitted = can({ subject: 'financing:repaymentTerms', action: 'read' });

      const { data: orgEligibility, isLoading: isOrgEligibilityLoading } = useFinancingOrgEligibility({
        enabled: _shouldAllowFinancingOption && isFinancingPermitted,
      });

      const isFxPayment = isFXCurrency(vendor?.currency);
      const isEligibleForFinancing =
        orgEligibility?.status && ALLOWED_ORG_FINANCING_STATUS.includes(orgEligibility.status);
      const isFTXFinancing = !orgEligibility?.appliedToProvider;

      const shouldAllowFinancingOption =
        isFinancingPermitted &&
        _shouldAllowFinancingOption &&
        isEligibleForFinancing &&
        !isFxPayment &&
        !isMobile &&
        (isFTXFinancing ? isFinancingApplicationFlowFromPaymentEnabled : true);

      const {
        data: repaymentTerms,
        isFetching: isLoadingRepaymentTerms,
        isError: isRepaymentTermsError,
      } = useFinancingRepaymentTerms({
        vendorId,
        paymentAmount,
        enabled: !!shouldAllowFinancingOption,
      });

      const { track } = useAnalytics();

      // Prefetch of fee catalog for optimization purposes
      const { isLoading: isLoadingFees } = useFeeCatalog();
      const resetValues = () => {
        setSelectedFundingSourceId(undefined);
        setPaymentAmount(initialPaymentAmount);
      };

      const goToFinancingApplicationFlow = () => {
        // TODO: navigate to application flow

        // eslint-disable-next-line no-console
        console.log('Apply for financing', paymentAmount);
      };

      const onDoneSelectingFundingSource = (amount: number, selectedFundingSourceId?: string) => {
        if (!autoNavToNextStep.current) {
          return;
        }
        const fundingSourceType = getFundingSourceTypeById(fundingSources, selectedFundingSourceId);

        track('PaymentMethod', 'Click', {
          PaymentMethodId: selectedFundingSourceId,
          PaymentMethodType: fundingSourceType,
          Cta: sanitizeStringForAnalytics(
            formatMessage('activities.fundingSourceSelection.screens.fundingSourceSelection.continue')
          ),
        });
        onDone({
          fundingSourceId: selectedFundingSourceId,
          paymentAmount: amount,
          financingEligibilityToken: repaymentTerms?.eligibilityToken || undefined,
          options: {
            shouldCleanFinancing: !isFinancingSelected,
          },
        });
      };

      const onOpenReconciliationFlow = () => {
        autoNavToNextStep.current = false;
      };

      const {
        disableGoodsReceivedFundingSourcesTypes,
        goodsReceived,
        shouldShowReconciliationModal,
        goodsReceivedAmountThreshold,
        shouldOpenGoodsReceivedModal,
        handleCloseGoodsReceived,
        handleDoneGoodsReceived,
        onDoneReconciliationFlow,
        onCloseReconciliationFlow,
        vendorDetailsModalState,
        onCloseVendorDetailsModal,
      } = useFundingSourceSelectionModals({
        fundingSources,
        selectedFundingSourceId,
        vendor,
        onDone: onDoneSelectingFundingSource,
        paymentAmount,
        onCloseModal: resetValues,
        onOpenReconciliationFlow,
      });

      useEffect(() => {
        setFsTypesOptions(
          fundingSourceTypesOptions.map((option) => {
            if (
              option.type === FundingSourceType.Card &&
              option.supported &&
              disableGoodsReceivedFundingSourcesTypes?.includes(option.type)
            ) {
              return {
                ...option,
                supported: false,
                reason: 'goodNotReceived',
              };
            }
            return fsTypesOptionsToFundingSourceTypesOptions(option);
          })
        );
      }, [fundingSourceTypesOptions, disableGoodsReceivedFundingSourcesTypes, setFsTypesOptions]);

      const fundingTypes = fundingSources?.reduce<Record<string, number>>((memo, fs) => {
        const type =
          fs.type === 'bank-account' || fs.type === 'flex-account' || fs.type === 'paypal-balance'
            ? fs.type
            : fs.details.type;
        return { ...memo, [type]: (memo[type] ?? 0) + 1 };
      }, {});

      const fundingSourceIds = fundingSources?.map((fs) => fs.id) ?? [];

      setAnalyticsProperties({
        PageName: 'how-do-you-want-to-pay',
        Intent: 'choose-payment-method',
        FundingSourceShown: fundingTypes,
        FundingSourceIdShown: fundingSourceIds,
        Currency: vendor?.currency || undefined,
      });

      useEffect(() => {
        const cardNetwork = selectedId
          ? getCreditCardFundingSourceNetwork({ fundingSourceId: selectedId, fundingSources })
          : null;
        if (cardNetwork) {
          setAnalyticsProperties({
            CardNetwork: cardNetwork,
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [selectedId]);

      const handleAddFundingSource = (type: FundingSource['type'] | Card['type']) => {
        if (type === 'bank-account') {
          goToStep('ADD_BANK');

          return;
        }

        if (type === 'flex-account' || type === 'paypal-balance') {
          return;
        }

        if (type !== 'card') {
          setCardType(type);
        }

        goToStep('ADD_CARD');
      };

      const handleDoneAddingFundingSource = (fundingSource: FundingSource | DeliveryMethod) => {
        handleOnSelectFundingSource(fundingSource.id);
        goToStep('FUNDING_SOURCE_SELECTION');
      };

      const handleOnSelectFundingSource = (selectedFundingSourceId?: string, amount?: number) => {
        const fs = fundingSources?.find((fs) => fs.id === selectedFundingSourceId);
        if (fs) {
          autoNavToNextStep.current = true;
          setSelectedFundingSourceId(fs.id);

          if (amount) {
            setPaymentAmount(amount);
          }
        }
      };

      const onDoneSelectingFinancingFundingSource = () => {
        if (!orgEligibility?.appliedToProvider || orgEligibility?.balance === undefined) {
          return goToFinancingApplicationFlow();
        }
        if (paymentAmount > orgEligibility.balance) {
          setiIsPaymentAmountExceedsFinancingModalOpen(true);
          return;
        }

        return onDoneSelectingFundingSource(paymentAmount, selectedFundingSourceId);
      };

      const onSubmit = (selectedFundingSourceId?: string, amount?: number) => {
        datadogRum.addAction('funding_source_submit_clicked', {
          timestamp: Date.now(),
        });
        if (isFinancingSelected) {
          return onDoneSelectingFinancingFundingSource();
        }
        return handleOnSelectFundingSource(selectedFundingSourceId, amount);
      };

      const handleOnChangePaymentAmount = (newAmount: number) => {
        if (newAmount !== paymentAmount) {
          setPaymentAmount(newAmount);
        }
      };

      const handleOnCloseVendorDetailsModal = () => {
        resetValues();
        onCloseVendorDetailsModal();
      };

      const onReconciliationSuccess = (successMessage: string) => {
        setSelectedFundingSourceId(undefined);
        showMessage({ type: 'success', title: successMessage });
        autoNavToNextStep.current = true;
        onDoneReconciliationFlow();
      };

      const isLoading =
        isLoadingFundingSources ||
        isLoadingFees ||
        isLoadingVendor ||
        isLoadingBill ||
        isLoadingActor ||
        isOrgEligibilityLoading;

      const { routeReady } = useMonitoring();

      if (!vendor) {
        return <LoadingScreen />;
      }

      switch (currentStep) {
        case 'FUNDING_SOURCE_SELECTION':
        default:
          return (
            <>
              <FundingSourceSelectionScreen
                ref={routeReady}
                fundingSources={fundingSources || []}
                onDone={onSubmit}
                onClose={onClose}
                onAdd={handleAddFundingSource}
                isLoading={isLoading}
                isLoadingRepaymentTerms={isLoadingRepaymentTerms}
                onVerify={setFundingSourceIdToVerify}
                selectedId={selectedId}
                step={step}
                totalSteps={totalSteps}
                paymentAmount={paymentAmount}
                vendor={vendor}
                recurringPaymentDetails={recurringPaymentDetails}
                goodsReceived={goodsReceived}
                shouldShowMccErrorBanner={shouldShowMccErrorBanner}
                fundingSourceTypesOptions={fsTypesOptions}
                failedDate={failedDate}
                originalFundingSourceId={originalFundingSourceId}
                onChangePaymentAmount={handleOnChangePaymentAmount}
                bill={bill}
                shouldAllowEditAmount={shouldAllowEditAmount && !isFxPayment}
                shouldAllowFinancingOption={shouldAllowFinancingOption}
                repaymentTerms={repaymentTerms}
                isRepaymentTermsError={isRepaymentTermsError}
                userId={actor?.userId}
                onSelectFinancingOption={setIsFinancingSelected}
                isFinancingSelected={isFinancingSelected}
              />
              <MicroDepositsVerificationModalActivity
                onError={onError}
                isOpen={!!fundingSourceIdToVerify}
                onClose={() => setFundingSourceIdToVerify()}
                onDone={() => setFundingSourceIdToVerify()}
                fundingSourceId={fundingSourceIdToVerify as never}
              />
              <GoodsReceivedModalScreen
                amountThreshold={goodsReceivedAmountThreshold}
                isOpen={shouldOpenGoodsReceivedModal}
                onClose={handleCloseGoodsReceived}
                onDone={handleDoneGoodsReceived}
              />
              <VendorDetailsModalActivity
                vendorDetailsModalState={vendorDetailsModalState}
                vendorId={vendorId}
                onDone={onCloseVendorDetailsModal}
                onClose={handleOnCloseVendorDetailsModal}
                onError={onError}
                onLoadingVendorDetailsFailure={showMccErrorBanner.on}
              />

              {selectedFundingSourceId && (
                <ReconciliationModalActivity
                  selectedFundingSourceId={selectedFundingSourceId}
                  isOpen={shouldShowReconciliationModal}
                  onClose={onCloseReconciliationFlow}
                  onDone={onReconciliationSuccess}
                />
              )}
              <PaymentExceedsFinancingLimitModalComponent
                onCancel={() => setiIsPaymentAmountExceedsFinancingModalOpen(false)}
                onContinue={goToFinancingApplicationFlow}
                isOpen={isPaymentAmountExceedsFinancingModalOpen}
              />
            </>
          );

        case 'ADD_CARD':
          return (
            <AddCardFundingSourceActivity
              cardType={cardType}
              onDone={handleDoneAddingFundingSource}
              onError={onError}
              onClose={goToPreviousStep}
              onBack={() => null}
            />
          );

        case 'ADD_BANK':
          return (
            <AddBankAccountActivity
              onDone={handleDoneAddingFundingSource}
              onError={onError}
              onClose={goToPreviousStep}
              onBack={() => null}
            />
          );
      }
    }
  )
);

FundingSourceSelectionActivity.displayName = 'FundingSourceSelectionActivity';
