/* eslint-disable @typescript-eslint/restrict-template-expressions, no-console */
import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from 'axios';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { getPartnerLocalStorage, LocalStorageKeys } from '@melio/local-storage';
import { ServerConfig } from '@melio/platform-api';
// eslint-disable-next-line no-restricted-imports
import {
  AccountsApi,
  AuthApi,
  BaseAPI,
  BillsApi,
  BillSubscriptionsApi,
  DeliveryMethodsApi,
  FeeCatalogsApi,
  FeesApi,
  FilesApi,
  FundingSourcesApi,
  IndustriesApi,
  OrganizationPreferencesApi,
  PaymentIntentsApi,
  PaymentsApi,
  ReportsApi,
  ScannedInvoicesApi,
  ServerConfig as ApiAxiosClientServerConfig,
  SupportApi,
  VendorsApi,
} from '@melio/platform-api-axios-client';
import { convertDates } from '@melio/platform-utils';

import { getPartnerTokens, removePartnerTokens } from '@/api/utilities';
import { AppHistory } from '@/router/history.router';
import { appState } from '@/store/app/app.model';
import { getPartnerName } from '@/utils/partner.utils';
import { BASE_PATH } from './consts';

const axiosInstance = axios.create();

const refreshTokenAxiosAbortController = new AbortController();
const refreshTokenAxiosInstance = axios.create({
  signal: refreshTokenAxiosAbortController.signal,
});
let refreshTokenPromise: Promise<string | null> | null = null;

const axiosRequestAuthorizationInterceptor: Parameters<typeof axiosInstance.interceptors.request.use>[0] = async (
  config,
) => {
  const { accessToken } = getPartnerTokens();
  const serverConfigHeaders = await ServerConfig.getRequestHeaders();
  const serverHeaders = new AxiosHeaders(serverConfigHeaders);
  config.headers.setAuthorization(`Bearer ${accessToken}`);
  config.headers.set(serverHeaders);
  return config;
};

type RequestConfig = AxiosRequestConfig & { _retried?: boolean; noRedirectOnSessionExpired?: boolean };

const onUnauthorized = () => {
  removePartnerTokens();
  AppHistory.push('/expired-session');
};

const axiosRefreshTokenResponseErrorInterceptor: Parameters<typeof axiosInstance.interceptors.response.use>[1] = async (
  error: AxiosError,
) => {
  if (error?.response?.status === 401) {
    onUnauthorized();
  }
  return Promise.reject(error);
};

const axiosResponseErrorRetryInterceptor: Parameters<typeof axiosInstance.interceptors.response.use>[1] = async (
  error: AxiosError,
) => {
  const config = error.config as RequestConfig;
  const isUnauthorized = error.response?.status === 401;
  const { refreshToken } = getPartnerTokens();

  if (!isUnauthorized || config._retried || config.noRedirectOnSessionExpired) {
    return Promise.reject(error);
  }
  if (isUnauthorized && !refreshToken) {
    onUnauthorized();
    return Promise.reject(error);
  }

  const accessToken = await refreshAccessToken();
  config.headers = {
    ...config.headers,
    authorization: `Bearer ${accessToken || ''}`,
  };
  config._retried = true;
  return axiosInstance(config);
};

axiosInstance.interceptors.request.use(axiosRequestAuthorizationInterceptor);
axiosInstance.interceptors.response.use(convertDates, axiosResponseErrorRetryInterceptor);
refreshTokenAxiosInstance.interceptors.response.use((response) => response, axiosRefreshTokenResponseErrorInterceptor);
const GenericApiCreator = <T extends BaseAPI>(Api: typeof BaseAPI) => new Api(undefined, BASE_PATH, axiosInstance) as T;

export let accountsApiClient = GenericApiCreator<AccountsApi>(AccountsApi);
export let authApiClient = GenericApiCreator<AuthApi>(AuthApi);
export let authRefreshApiClient = new AuthApi(undefined, BASE_PATH, refreshTokenAxiosInstance);
export let deliveryMethodsApi = GenericApiCreator<DeliveryMethodsApi>(DeliveryMethodsApi);
export let paymentIntentApi = GenericApiCreator<PaymentIntentsApi>(PaymentIntentsApi);
export let paymentMethodsApi = GenericApiCreator<FundingSourcesApi>(FundingSourcesApi);
export let feeCatalogApi = GenericApiCreator<FeeCatalogsApi>(FeeCatalogsApi);
export let filesApi = GenericApiCreator<FilesApi>(FilesApi);
export let preferencesApi = GenericApiCreator<OrganizationPreferencesApi>(OrganizationPreferencesApi);
export let paymentApi = GenericApiCreator<PaymentsApi>(PaymentsApi);
export let vendorApi = GenericApiCreator<VendorsApi>(VendorsApi);
export let feesApi = GenericApiCreator<FeesApi>(FeesApi);
export let reportApi = GenericApiCreator<ReportsApi>(ReportsApi);
export let industriesApi = GenericApiCreator<IndustriesApi>(IndustriesApi);
export let scannedInvoicesApi = GenericApiCreator<ScannedInvoicesApi>(ScannedInvoicesApi);
export let billSubscriptionsApi = GenericApiCreator<BillSubscriptionsApi>(BillSubscriptionsApi);
export let billsApi = GenericApiCreator<BillsApi>(BillsApi);
export const supportApi = GenericApiCreator<SupportApi>(SupportApi);

export async function refreshAccessToken() {
  if (!refreshTokenPromise) {
    refreshTokenPromise = (async () => {
      const partnerName = getPartnerName();
      const localStorage = getPartnerLocalStorage({ partnerName });
      const { refreshToken: refreshTokenFromStorage } = getPartnerTokens();

      console.info(`on refreshAccessToken; refreshTokenFromStorage ${!!refreshTokenFromStorage}`);

      if (refreshTokenFromStorage) {
        try {
          const response = await authRefreshApiClient.postAuthTokenRefresh({
            refreshAccessTokenParameters: {
              refreshToken: refreshTokenFromStorage,
            },
          });

          const { accessToken, refreshToken } = response.data.data;

          localStorage.setItem(LocalStorageKeys.accessToken, accessToken);
          if (refreshToken) {
            localStorage.setItem(LocalStorageKeys.refreshToken, refreshToken);
          }
          ApiAxiosClientServerConfig.update({ accessToken });
          const state = getRecoil(appState);
          setRecoil(appState, { ...state, accessToken });
        } catch (e) {
          console.info(`postAuthTokenRefresh failed ${e}`);
        }
      }

      setTimeout(() => (refreshTokenPromise = null));
      return localStorage.getItem(LocalStorageKeys.accessToken);
    })();
  }
  return await refreshTokenPromise;
}

export const abortRefreshAccessToken = () => refreshTokenAxiosAbortController.abort();
