import {
  checkCountryRiskLevel,
  InternationalIdentifierType,
  isIdentifierTypeInternational,
  isTransferRequireForCurrency,
  useInternationalFxFeature,
} from '@melio/ap-domain';
import { Currency, getCountries } from '@melio/international-payments-utils';
import {
  Bill,
  CountryInternationalDeliveryDetails,
  Payment,
  useInternationalCountriesDetails,
  usePayments,
} from '@melio/platform-api';
import { useEffect, useMemo, useState } from 'react';

type UsePaymentPurposeShowFieldsProps = {
  internationalCountryCode?: string;
  vendorId: string;
  hasInvoice?: boolean;
  currency?: string;
  identifierType?: InternationalIdentifierType;
};

export const usePaymentPurposeShowFields = ({
  internationalCountryCode,
  vendorId,
  hasInvoice = false,
  identifierType,
  currency,
}: UsePaymentPurposeShowFieldsProps) => {
  const isInternationalFxEnabled = useInternationalFxFeature();
  const isFx = isInternationalFxEnabled && identifierType && isIdentifierTypeInternational(identifierType);

  const { isLoading: isCountryLoading, data: countryDetailsData } = useInternationalCountriesDetails({
    enabled: !isFx,
  });

  const [shouldShowInvoice, setShouldShowInvoice] = useState(false);

  const { data: paymentsData, isLoading: isPaymentsLoading } = usePayments({
    params: { search: { 'vendor.id': vendorId } },
  });

  const selectedCountryDetails = useMemo(() => {
    if (!isFx) {
      return countryDetailsData?.find((country) => country.code === internationalCountryCode);
    }
    return getCountries().find((country) => country.code === internationalCountryCode);
  }, [countryDetailsData, internationalCountryCode, isFx]);

  useEffect(() => {
    const result = checkCountryRiskLevel({ country: selectedCountryDetails, paymentsData, hasInvoice, vendorId });
    setShouldShowInvoice(result);
  }, [internationalCountryCode, hasInvoice, selectedCountryDetails, paymentsData, vendorId]);

  const isLoading = isCountryLoading || isPaymentsLoading;

  if (!isFx) {
    return {
      isLoading,
      shouldShowInvoice,
      selectedCountryDetails,
      shouldShowDescription: true,
      shouldShowPaymentPurpose: true,
    };
  }
  return {
    isLoading,
    shouldShowInvoice,
    selectedCountryDetails,
    shouldShowDescription: false,
    shouldShowPaymentPurpose: isTransferRequireForCurrency(currency as Currency),
  };
};

export const isPaymentIntentsRequireInvoice = ({
  bills,
  payments,
  internationalCountryCode,
  countryDetailsData,
  vendorId,
}: {
  bills: Bill[];
  payments: Payment[];
  internationalCountryCode: string | undefined;
  countryDetailsData: CountryInternationalDeliveryDetails[] | undefined;
  vendorId: string;
}) => {
  const selectedCountryDetails = countryDetailsData?.find(
    (countryDetails) => countryDetails.code === internationalCountryCode
  );

  return !!bills.find((bill: Bill) => {
    if (!internationalCountryCode || bill.invoice.fileId) {
      return false;
    }

    if (selectedCountryDetails?.risk === 'high') {
      return true;
    }
    if (selectedCountryDetails?.risk === 'medium') {
      return !payments?.some((payment) => payment.vendorId === vendorId);
    }

    return false;
  });
};
