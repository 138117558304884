import { IconProps } from '@melio/penny';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type WhatThisMeansListItem = {
  label: string;
  iconType: IconProps['type'];
};

export const useGracePeriodIntroductionListItems = () => {
  const { formatMessage } = useMelioIntl();
  const { data: accountMeData } = useAccount({ id: 'me' });

  const whatsThisMeansList: WhatThisMeansListItem[] = [
    {
      label: formatMessage('activities.subscription.gracePeriodIntroductionModal.whatsThisMeansList.item.until', {
        gracePeriodEndDate: 'December 31, 2024',
      }),
      iconType: 'favourite',
    },
    {
      label: formatMessage('activities.subscription.gracePeriodIntroductionModal.whatsThisMeansList.item.access'),
      iconType: 'multiple-approval',
    },
    {
      label: accountMeData?.isAccountingClient
        ? formatMessage(
            'activities.subscription.gracePeriodIntroductionModal.whatsThisMeansList.item.accountingFirmClient'
          )
        : formatMessage('activities.subscription.gracePeriodIntroductionModal.whatsThisMeansList.item.discount'),
      iconType: 'crown',
    },
  ];

  return { whatsThisMeansList };
};
