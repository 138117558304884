import { CardFundingSourceTypeOptionServer, FsTypeOption, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import { useCardExpired } from './useCardExpired';
import { useInternationalPaymentCardRestrictionsText } from './useInternationalPaymentCardRestrictionsText';

export type FundingSourceTypeOptionReasonClient = 'goodNotReceived';

export type UnsupportedFundingSourceTypeReasons =
  | CardFundingSourceTypeOptionServer['reason']
  | FundingSourceTypeOptionReasonClient;

const getFundingSourceSupportDataForPayment = (
  fundingSource: FundingSource,
  paymentFundingSourceTypesOptions: FsTypeOption[]
) => {
  const paymentFundingSourceData = paymentFundingSourceTypesOptions?.find((option) => {
    if (
      fundingSource.type === 'bank-account' ||
      fundingSource.type === 'flex-account' ||
      fundingSource.type === 'paypal-balance'
    ) {
      return option.type === fundingSource.type;
    }

    const network = fundingSource.details?.network;
    return option.type === fundingSource.type && option.subtype === network;
  });

  return {
    isSupported: paymentFundingSourceData?.supported,
    reason: paymentFundingSourceData?.reason,
  };
};

export const useInvalidFundingSources = (paymentFundingSourceTypesOptions?: FsTypeOption[]) => {
  const { formatMessage } = useMelioIntl();
  const { isCardExpired } = useCardExpired();
  const internationalPaymentRestrictionsLabelProvider = useInternationalPaymentCardRestrictionsText();

  const getIsValidFundingSource = useCallback(
    (fundingSource: FundingSource) => {
      const isValidFundingSourceEntity =
        !fundingSource.isBlocked && (fundingSource.type !== 'card' || !isCardExpired(fundingSource));

      if (paymentFundingSourceTypesOptions) {
        const { isSupported: isSupportedForPayment } = getFundingSourceSupportDataForPayment(
          fundingSource,
          paymentFundingSourceTypesOptions
        );

        return isValidFundingSourceEntity && isSupportedForPayment;
      }

      return isValidFundingSourceEntity;
    },
    [isCardExpired, paymentFundingSourceTypesOptions]
  );

  const getHasValidFundingSource = (fundingSources: FundingSource[]) => !!fundingSources.find(getIsValidFundingSource);

  const getFundingSourceTooltip = (fundingSource: FundingSource) => {
    if (fundingSource.isBlocked) {
      return formatMessage('activities.batchPayments.screens.paymentIntentsTable.fundingSourceTooltip.blocked');
    }
    if (fundingSource.type === 'card' && isCardExpired(fundingSource)) {
      return formatMessage('activities.batchPayments.screens.paymentIntentsTable.fundingSourceTooltip.expired');
    }

    if (paymentFundingSourceTypesOptions) {
      const supportDataForPayment = getFundingSourceSupportDataForPayment(
        fundingSource,
        paymentFundingSourceTypesOptions
      );

      switch (supportDataForPayment.reason) {
        case 'internationalPaymentRestrictions':
          return internationalPaymentRestrictionsLabelProvider(fundingSource);
        case 'cardsNotAllowedForManagedVendor':
          return formatMessage(
            'widgets.fundingSources.selectFundingSourceCard.card.disabledReason.cardsNotAllowedForManagedVendor'
          );
        case 'prohibitedMcc':
          return formatMessage('widgets.fundingSources.selectFundingSourceCard.card.disabledReason.prohibitedMcc');
      }
    }

    return;
  };
  return { getFundingSourceTooltip, getIsValidFundingSource, getHasValidFundingSource };
};
