import { ServiceChargeCatalog, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenu, Group, Icon, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { useServiceChargeActionsItems } from '../hooks';

export type ServiceChargesTableListItemProps = {
  serviceCharge: ServiceChargeCatalog;
  onEdit: (serviceChargeId: string) => unknown;
  onDelete: (serviceChargeId: string) => unknown;
};

export const ServiceChargesTableListItem = forwardRef<ServiceChargesTableListItemProps>(
  ({ serviceCharge, onEdit, onDelete, ...props }, ref) => {
    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
    const { formatMessage } = useMelioIntl();
    const actions = useServiceChargeActionsItems({ serviceCharge, onEdit, onDelete });

    return (
      <ActionsDropdownMenu
        isOpen={isActionsMenuOpen}
        onOpenChange={setIsActionsMenuOpen}
        trigger={<ServiceChargesTableListItemContent serviceCharge={serviceCharge} ref={ref} {...props} />}
        label={formatMessage('ar.settings.activities.serviceChagresTable.listItem.actionsMenu.label')}
        items={actions}
        ref={ref}
        {...props}
      />
    );
  }
);
ServiceChargesTableListItem.displayName = 'ServiceChargesTableListItem';

const ServiceChargesTableListItemContent = forwardRef<{ serviceCharge: ServiceChargeCatalog }>(
  ({ serviceCharge, ...props }, ref) => {
    const { formatCurrency, formatPercents } = useMelioIntl();
    return (
      <Group
        variant="horizontal"
        justifyContent="space-between"
        alignItems="center"
        spacing="xxs"
        {...props}
        data-component={ServiceChargesTableListItemContent.displayName}
        ref={ref}
        data-testid={`service-charge-list-item-${serviceCharge.id}`}
      >
        <Group justifyContent="space-between" width="full">
          <Text textStyle="body2Semi" data-testid="service-charge-name">
            {serviceCharge.name}
          </Text>
          <Text textStyle="body2Semi" data-testid="service-charge-value">
            {serviceCharge.type === 'percentage'
              ? formatPercents(serviceCharge.value)
              : formatCurrency(serviceCharge.value)}
          </Text>
        </Group>

        <Icon type="chevron-right" size="small" />
      </Group>
    );
  }
);
ServiceChargesTableListItemContent.displayName = 'ServiceChargesTableListItemContent';
