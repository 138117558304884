import { Container, IconButton, Text, Tooltip } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useCopyToClipboard } from '../useCopyToClipboard';

type SharePaymentLinkProps = {
  link: string;
  onCopyPaymentLink?: () => Promise<void>;
};

export const SharePaymentLink = forwardRef<SharePaymentLinkProps>(({ link, onCopyPaymentLink }, ref) => {
  const { copyLink, reset, content } = useCopyToClipboard(link, onCopyPaymentLink);

  return (
    <Container
      data-testid="share-payment-link-container"
      ref={ref}
      backgroundColor="light"
      paddingY="s"
      paddingX="s"
      border="regular"
      justifyContent="space-between"
    >
      <Text textStyle="body3" shouldSupportEllipsis>
        {link}
      </Text>
      <Tooltip content={content} data-testid="share-payment-link-popover">
        <IconButton
          icon="duplicate-flip"
          onClick={copyLink}
          onMouseEnter={reset}
          size="small"
          variant="naked"
          data-testid="copy-payment-link-button"
        />
      </Tooltip>
    </Container>
  );
});
