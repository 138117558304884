import { useAccount, useIsExternalAccountant } from '@melio/platform-api';
import { useLocation } from '@melio/platform-utils';
import { useMemo } from 'react';

import { useSubscriptionContext } from '../context/SubscriptionContext';
import { isRouteAllowedForNotSubscribedOrg } from '../ui/utils/blockedStatesAllowedRoutes.utils';
import { useSubscription } from './useSubscription';

export const useGracePeriodEndPaywall = () => {
  const subscription = useSubscription();
  const { data: dataAccountMe, isLoading: isAccountLoading } = useAccount({ id: 'me' });
  const isExternalAccountant = useIsExternalAccountant();

  const graceEndRedirectRoute = useMemo(() => {
    if (isAccountLoading || !dataAccountMe) {
      return '';
    }

    const isOwner = dataAccountMe.user.email === dataAccountMe.organizationOwner?.email;
    if (isExternalAccountant) {
      return '/subscription/grace/select-client-plan';
    } else if (isOwner) {
      return '/subscription/grace/select-your-plan';
    } else {
      return '/subscription/grace/plan-is-required';
    }
  }, [isAccountLoading, dataAccountMe, isExternalAccountant]);

  const {
    gracePeriod: { isEligibleAndGracePeriodEnded },
  } = useSubscriptionContext();
  const location = useLocation();

  const isRouteAllowed = isRouteAllowedForNotSubscribedOrg(location.pathname);
  const shouldShowGracePeriodEndPaywall = isEligibleAndGracePeriodEnded && !isRouteAllowed && !subscription;

  return {
    graceEndRedirectRoute,
    shouldShowGracePeriodEndPaywall,
  };
};
