import { LocalStorageKeys } from '@melio/local-storage';
import { PartnerName } from '@melio/platform-api';

import { partnerHostToPartnerName, tenantToPartnerConfiguration } from '@/partnersConfig.types';

const getBasePath = (uri) => {
  const path = uri.split('/')[1];

  return path || null;
};

export const getPartnerNameFromPath = (uri: string) => {
  const Partners = Object.values(PartnerName).concat(['gusto', 'optum-bank-demo', 'paypal']);

  const basePath = getBasePath(uri);

  return Partners.find((partner) => partner === basePath);
};

export const getPartnerNameFromHost = (hostname?: string) =>
  hostname && Object.keys(partnerHostToPartnerName).includes(hostname) ? partnerHostToPartnerName[hostname] : null;

export const getPartnerNameFromUrlParams = (querystring: string) => {
  const queryParams = new URLSearchParams(querystring);
  const tenant = queryParams.get('tenant');
  if (!tenant) {
    return null;
  }

  return tenantToPartnerConfiguration[tenant];
};

export const getPartnerNameFromLocalStorage = () => {
  return window.sessionStorage.getItem(LocalStorageKeys.partnerName) as string;
};

export const getPartnerNameFromUrl = () => {
  return (getPartnerNameFromPath(location.pathname) || getPartnerNameFromHost(location.hostname)) as string;
};

export const getPartnerName = () => {
  return getPartnerNameFromUrl() || getPartnerNameFromLocalStorage();
};

export const getPartnerUrlPrefixFromUrl = () => {
  const basePath = window.sessionStorage.getItem(LocalStorageKeys.basePath);
  return basePath ? `/${basePath}` : '';
};
