import { FormDialog, FormDialogWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, Group, Typography, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { object, SchemaOf, string } from 'yup';

import { FeeAbsorptionSettingsFormFields } from '../types';

export type FeeAbsorptionSettingsModalScreenProps = FormDialogWidgetProps<
  FeeAbsorptionSettingsFormFields,
  {
    isOpen: boolean;
    onClose: VoidFunction;
  }
>;

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    feesPaidBy: string().required(
      formatMessage(
        'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.feesPaidBy.required.label'
      )
    ),
  }) as SchemaOf<FeeAbsorptionSettingsFormFields>;
};

export const FeeAbsorptionSettingsModalScreen = forwardRef<FeeAbsorptionSettingsModalScreenProps>(
  ({ isOpen, onClose, onSubmit, defaultValues, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { registerField, watch, setError, ...useFormResults } = useMelioForm({
      schema: useSchema(),
      onSubmit,
      defaultValues,
    });

    return (
      <FormDialog
        data-testid="fee-absorption-settings-modal-screen"
        data-component={FeeAbsorptionSettingsModalScreen.displayName}
        isOpen={isOpen}
        size="large"
        modalSize="medium"
        onClose={onClose}
        header={formatMessage('ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.header')}
        primaryButton={{
          label: formatMessage(
            'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.buttons.save.label'
          ),
        }}
        secondaryButton={{
          label: formatMessage(
            'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.buttons.close.label'
          ),
          variant: 'tertiary',
        }}
        useFormResults={useFormResults}
        {...props}
        ref={ref}
      >
        <Group spacing="m" variant="vertical">
          <Form.RadioGroup
            variant="vertical"
            options={[
              {
                value: 'customers',
                mainLabelProps: {
                  label: formatMessage(
                    'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.feesPaidBy.customers.main.label'
                  ),
                },
                descriptionProps: {
                  label: formatMessage(
                    'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.feesPaidBy.customers.description.label'
                  ),
                },
              },
              {
                value: 'business',
                mainLabelProps: {
                  label: formatMessage(
                    'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.feesPaidBy.business.main.label'
                  ),
                },
                descriptionProps: {
                  label: formatMessage(
                    'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.feesPaidBy.business.description.label'
                  ),
                },
              },
            ]}
            {...registerField('feesPaidBy')}
          />
          <Typography.Description
            label={formatMessage('ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.note')}
          />
        </Group>
      </FormDialog>
    );
  }
);

FeeAbsorptionSettingsModalScreen.displayName = 'FeeAbsorptionSettingsModalScreen';
