import { isEbill } from '@melio/ap-domain';
import { Bill, Payment, PaymentRestrictions } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { string, TestContext } from 'yup';

import { getAvailableBalance, isJustPayPayment } from '../../util/amount';

export const useAmountSchemas = ({
  bill,
  payment,
  paymentRestrictions,
}: {
  bill?: Bill;
  payment?: Payment;
  paymentRestrictions?: PaymentRestrictions;
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const {
    settings: {
      payment: { scheduling: schedulingConfig },
    },
  } = useConfig();

  const isInRange = (value: number, min: number, max: number) => value >= min && value <= max;
  const createErrorMessage = (context: TestContext, min: number, max: number) =>
    context.createError({
      message: formatMessage('activities.paymentFlow.form.content.amountToPay.validBetweenMinimumAndMaximum.label', {
        minAmount: formatCurrency(min),
        maxAmount: formatCurrency(max),
      }),
    });

  const createRangeTest =
    (min = 0, max = Infinity) =>
    (value: number, context?: TestContext) => {
      const inRange = isInRange(value, min, max);

      if (!inRange) {
        return context ? createErrorMessage(context, min, max) : false;
      }

      return true;
    };

  const isValidAmount = (value?: string, context?: TestContext) => {
    const { minAmountUsd, maxAmountUsd } = paymentRestrictions || schedulingConfig;
    if (!minAmountUsd || !maxAmountUsd || !value) {
      return true;
    }

    return createRangeTest(minAmountUsd, maxAmountUsd)(Number(value), context);
  };

  return {
    amountToPaySchema: string()
      .required(formatMessage('activities.paymentFlow.form.content.amountToPay.required'))
      .test('validLessThanOrEqualBalance', '', (value, context) => {
        if (!bill) {
          return true;
        }

        if (isEbill(bill)) {
          return true;
        }

        if (
          payment?.subscriptionOccurrence?.billSubscription &&
          payment?.subscriptionOccurrence?.billSubscription?.status !== 'canceled'
        ) {
          return true;
        }

        if (isJustPayPayment(payment?.createMethod)) {
          return true;
        }

        const amount = Number(value);
        const billAvailableBalance = getAvailableBalance(bill, payment);

        return (
          amount <= billAvailableBalance.toNaturalUnit() ||
          context.createError({
            message: formatMessage(
              'activities.paymentFlow.form.content.amountToPay.validLessThanOrEqualBalance.label',
              {
                balance: formatCurrency(billAvailableBalance.toNaturalUnit()),
              }
            ),
          })
        );
      })
      .test('validAmount', '', isValidAmount),
    lastAmountSchema: string().test('lastAmountRange', '', isValidAmount).notRequired(),
    isValidAmount,
  };
};
