/* eslint-disable react-hooks/exhaustive-deps */
import { useDisclosure } from '@chakra-ui/react';
import { getEbillAmount, isEbill } from '@melio/ap-domain';
import { Container, Divider, Group, SectionBanner, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PaymentStatusEnum, useBillAttachments } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { forwardRef, SystemMessageDisplay } from '@melio/platform-utils';
import { Big } from 'big.js';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';

import { FileAttachmentWidget } from '../FileAttachment';
import { getBillPaidAmount } from '../utils/bill-utils';
import { BillAmountLine } from './BillAmountLine/BillAmountLine';
import { BillDetailsForm } from './BillDetailsForm';
import { BillDetailsHeader } from './BillDetailsHeader';
import { BillPaymentsOverview } from './BillPaymentsOverview/BillPaymentsOverview';
import { BillWillBeFullyPaidModal } from './BillWillBeFullyPaidModal.widget';
import { EBillAttachment } from './EBillAttachment/EBillAttachment';
import { BillDetailsFormFields, BillDetailsWidgetProps } from './types';

export const BillDetailsWidget = forwardRef<BillDetailsWidgetProps>(
  (
    {
      isActionable = false,
      showOpenBalance = false,
      defaultIsOpen = false,
      activeAccountingPlatform,
      isEditMode,
      onMarkAsPaid,
      onDelete,
      bill,
      isSaving,
      onSubmit,
      onSubmissionStateChange,
      onInvoiceChange,
      hasBorder,
      paymentVendor,
      hideHeaderTitle,
      hideRemainingAmountSection,
      customTitle,
      ...props
    },
    ref
  ) => {
    const [isPreviewInvoiceEnabled] = usePartnerFeature('PreviewInvoice', false);
    const [isEBillLegalDisclaimer] = usePartnerFeature('EBillLegalDisclaimer', false);
    const { track } = useAnalytics();
    const { isExtraSmallScreen } = useBreakpoint();
    const { formatMessage } = useMelioIntl();
    const [isViewMode, setIsViewMode] = useState(true);
    const [newBillData, setNewBillData] = useState<BillDetailsFormFields>();
    const [showActions, setshowActions] = useState(isActionable);
    const [isFileAttached, setIsFileAttached] = useState<boolean>(Boolean(bill?.invoice.fileId));
    const isQboSyncedBill = !!bill.originId && bill.originId !== '-1';
    const { data: attachments, isLoading: isLoadingBillAttachments } = useBillAttachments({
      id: bill.id,
      enabled: (!bill.invoice.fileId && isQboSyncedBill) || isEbill(bill),
    });

    const enableEditBill = () => {
      setIsViewMode(false);
      setshowActions(false);
    };

    useEffect(() => {
      setIsViewMode(!isEditMode);
    }, [isEditMode]);

    useEffect(() => {
      isActionable && setIsViewMode(!isSaving);
      isActionable && setshowActions(!isSaving);
    }, [isSaving]);

    const {
      isOpen: shouldOpenBillFullyPaidModal,
      onOpen: onOpenBillFullyPaidModal,
      onClose: onCloseBillFullyPaidModal,
    } = useDisclosure();

    const handleChange = (fileBlob?: File | null) => {
      if (!fileBlob) {
        track('Bill', 'Click', {
          Intent: 'delete-file',
          Cta: 'delete-file',
        });
      }
      setIsFileAttached(Boolean(fileBlob));
      onInvoiceChange(fileBlob);
    };

    const billHasPayments =
      !isNil(bill?.payments) &&
      bill?.payments.filter((payment) => payment.status !== PaymentStatusEnum.Canceled).length > 0;
    const hasRemainingAmount = bill?.balance > 0;
    const billHasMultiplePayments =
      !isNil(bill?.payments) &&
      bill?.payments.filter((payment) => payment.status !== PaymentStatusEnum.Canceled).length > 1;

    const handleSubmit = (data: BillDetailsFormFields) => {
      const paidAmount = bill ? getBillPaidAmount(bill) : 0;
      const newBalance = bill ? new Big(data.amount).minus(paidAmount).toNumber() : data.amount;
      if (newBalance === 0) {
        setNewBillData(data);
        onOpenBillFullyPaidModal();
      } else {
        onSubmit(data);
      }
    };

    const onConfirmNewAmount = () => {
      if (newBillData) {
        onSubmit(newBillData);
        onCloseBillFullyPaidModal();
      }
    };
    const onCancelBillNewAmount = () => {
      setNewBillData(undefined);
      onCloseBillFullyPaidModal();
    };
    const firstAttachmentUrl = attachments?.[0]?.url;

    return (
      <>
        <Container
          overflow="initial"
          ref={ref}
          border={hasBorder ? 'regular' : 'none'}
          data-component="BillDetailsWidget"
          paddingX={hasBorder ? 'l' : 'none'}
          paddingY={hasBorder ? 'l' : 'none'}
          {...props}
        >
          <Group hasDivider variant="vertical" spacing="m">
            <Container overflow="initial">
              <Group variant="vertical" spacing="l">
                <SystemMessageDisplay />
                {showActions || !hideHeaderTitle ? (
                  <BillDetailsHeader
                    shouldShowActions={showActions}
                    onMarkAsPaid={onMarkAsPaid}
                    enableEditBill={enableEditBill}
                    onDelete={onDelete}
                    defaultIsOpen={defaultIsOpen}
                    hideTitle={hideHeaderTitle}
                    customTitle={customTitle}
                  />
                ) : null}
                {isEbill(bill) && isEBillLegalDisclaimer && (
                  <SectionBanner
                    description={formatMessage('widgets.billDetails.eBill.banner.amount.description')}
                    variant="informative"
                    data-testid="ebill-amount-banner"
                  />
                )}
                <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'} spacing="l" alignItems="flex-start">
                  <BillDetailsForm
                    billInfo={bill}
                    isViewMode={isViewMode}
                    onSubmit={(data) => handleSubmit(data)}
                    onSubmissionStateChange={onSubmissionStateChange}
                    isFileAttached={Boolean(isFileAttached)}
                    isSaving={isSaving}
                    activeAccountingPlatform={activeAccountingPlatform}
                    paymentVendor={paymentVendor}
                    eBillUrl={firstAttachmentUrl}
                  />
                  {!isPreviewInvoiceEnabled ? (
                    isEbill(bill) ? (
                      <EBillAttachment isLoading={isLoadingBillAttachments} url={firstAttachmentUrl ?? ''} />
                    ) : (
                      <FileAttachmentWidget
                        fileId={bill.invoice.fileId}
                        aria-label={
                          isFileAttached ? formatMessage('widgets.fileAttachment.downloadAriaLabel') : undefined
                        }
                        externalFileUrl={firstAttachmentUrl}
                        isLoading={isLoadingBillAttachments}
                        isViewMode={isViewMode}
                        onChange={handleChange}
                        onViewModeClick={(fileUrl) => {
                          if (fileUrl) {
                            track('Bill', 'Click', { Intent: 'download-file', Cta: 'download-file' });
                            window.open(fileUrl);
                          }
                        }}
                        viewModeIcon={isFileAttached ? 'download' : undefined}
                        isReadOnly={!isFileAttached}
                      />
                    )
                  ) : undefined}
                </Group>
              </Group>
            </Container>
            {showOpenBalance && (bill?.amount || (isEbill(bill) && getEbillAmount(bill) != null)) && (
              <Container>
                {billHasPayments && !hideRemainingAmountSection ? (
                  <Group variant="vertical">
                    {hasRemainingAmount || billHasMultiplePayments ? (
                      <>
                        <BillAmountLine
                          amount={bill.balance}
                          size="small"
                          type="balance"
                          data-testid="remaining-bill-amount"
                          currency={bill.currency}
                        />
                        <Divider variant="horizontal" />
                        <BillPaymentsOverview payments={bill.payments ?? []} />
                      </>
                    ) : null}
                    <BillAmountLine
                      amount={bill.amount}
                      size="small"
                      type="total-amount"
                      data-testid="total-bill-amount-partial"
                      currency={bill.currency}
                    />
                  </Group>
                ) : (
                  <BillAmountLine
                    amount={isEbill(bill) ? getEbillAmount(bill) : bill.amount}
                    size="small"
                    type="total-amount"
                    data-testid="total-bill-amount"
                    currency={bill.currency}
                  />
                )}
              </Container>
            )}
          </Group>
        </Container>
        {newBillData && (
          <BillWillBeFullyPaidModal
            newAmount={+newBillData.amount}
            isOpen={shouldOpenBillFullyPaidModal}
            onClose={onCancelBillNewAmount}
            isSubmitting={isSaving}
            onSubmit={onConfirmNewAmount}
          />
        )}
      </>
    );
  }
);

BillDetailsWidget.displayName = 'BillDetailsWidget';
