import { Button, Container, Divider, Form, Group, IconButton, Layout, Text } from '@melio/penny';
import {
  DeliveryMethodType,
  PaypalBalanceAccountDeliveryMethod,
  useDeliveryMethods,
  useVendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { PageTitle } from '@melio/platform-utils';

import { usePaypalBalanceAccountForm } from '../hooks/usePaypalBalanceAccountForm';

type SubmissionTargetType = 'close' | 'continue';
export type AddVendorPaypalAccountActivityProps = {
  vendorId: string;
  onDone: (paypalAccount: PaypalBalanceAccountDeliveryMethod, target: SubmissionTargetType) => void;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onError?: ErrorFunction;
};

export const AddVendorPaypalBalanceAccountActivity = (props: AddVendorPaypalAccountActivityProps) => {
  const { formatMessage } = useMelioIntl();

  const { data: vendor, isLoading: isVendorLoading } = useVendor({ id: props.vendorId });
  const {
    create: createDelvieryMethod,
    isCreating,
    isLoading: isLoadingVendorDeliveryMethods,
  } = useDeliveryMethods({
    vendorId: props.vendorId,
    enabled: false,
  });

  const vendorPaypalDm = vendor?.deliveryMethods.find((dm) => dm.type === DeliveryMethodType.PaypalBalance) as
    | PaypalBalanceAccountDeliveryMethod
    | undefined;

  const { registerField, submitButtonProps, formProps, handleSubmit } = usePaypalBalanceAccountForm({
    onSubmit: async (values, _e, target) => {
      const res = await createDelvieryMethod({
        type: DeliveryMethodType.PaypalBalance,
        details: {
          paypalAccountEmail: values?.email,
        },
      });
      return props.onDone(res as PaypalBalanceAccountDeliveryMethod, target as SubmissionTargetType);
    },
    isLoading: isCreating,
    defaultValues: {
      email: vendorPaypalDm ? vendorPaypalDm.details.paypalAccountEmail : vendor?.contact.email ?? '',
    },
  });

  const isActivityLoading = isVendorLoading || isLoadingVendorDeliveryMethods;

  const submitAndContinue = handleSubmit('continue');
  const submitAndClose = handleSubmit('close');
  return (
    <Layout
      isLoading={isActivityLoading}
      maxWidth="600px"
      header={{
        content: (
          <>
            <Container paddingY="s" paddingX="m">
              <Group alignItems="center" justifyContent="space-between">
                <IconButton
                  icon="chevron-left"
                  size="large"
                  variant="naked"
                  onClick={props.onBack}
                  data-testid="back-button"
                />
                <IconButton icon="close" variant="naked" onClick={props.onClose} data-testid="close-button" />
              </Group>
            </Container>
            <Divider />
            <Container paddingTop="xl" maxWidth="600px">
              <Group variant="vertical">
                <PageTitle textStyle="heading1Semi" data-testid="page-title">
                  {formatMessage('activities.addVendorPaypalBalance.title', { vendorName: vendor?.name })}
                </PageTitle>
                <Text textStyle="body2" data-testid="page-subtitle">
                  {formatMessage('activities.addVendorPaypalBalance.subtitle')}
                </Text>
              </Group>
            </Container>
          </>
        ),
      }}
    >
      <Container maxWidth="600px">
        <Group variant="vertical" spacing="l">
          <Form {...formProps}>
            <Form.TextField
              {...registerField('email')}
              labelProps={{
                label: formatMessage('activities.addVendorPaypalBalance.form.emailField.label'),
              }}
            />
          </Form>
          <Group variant="vertical">
            <Button
              {...submitButtonProps}
              onClick={submitAndContinue}
              data-testid="submit-button"
              label={formatMessage('activities.addVendorPaypalBalance.form.submitButton.label')}
            />
            <Button
              data-testid="save-and-close-button"
              label={formatMessage('activities.addVendorPaypalBalance.form.saveAndClose.label')}
              variant="secondary"
              onClick={submitAndClose}
            />
          </Group>
        </Group>
      </Container>
    </Layout>
  );
};
