import { useWizardSimple, UseWizardSimpleArgs } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { ImportVendorsInitialScreen } from './ImportVendorsInitialScreen';

type Steps = 'initial' | 'feedback' | 'success';
const navigationSeq: Steps[] = ['initial', 'feedback', 'success'];

const locationsMap: UseWizardSimpleArgs<Steps>['locationsMap'] = {
  initial: 'initial',
  feedback: 'feedback',
  success: 'success',
};

export const ImportVendorsActivity = () => {
  useWizardSimple<Steps>({
    navigationSequence: navigationSeq,
    firstStep: 'initial',
    flowName: 'import-vendors',
    locationsMap,
    cancelUrlFallback: '/',
  });

  return (
    <Routes>
      <Route path={locationsMap.initial} element={<ImportVendorsInitialScreen />} />
    </Routes>
  );
};
