import { useCreateMutation, useMelioQueryClient, useModelApi, UseModelApiProps } from '@melio/api-client';
import { TaxpayerInfo, TaxpayerInfoApiClient, Vendor } from '@melio/platform-api-axios-client';

type Props = UseModelApiProps<typeof TaxpayerInfoApiClient.getTaxpayerInfoTaxpayerInfoId, TaxpayerInfo> & {
  vendorId: Vendor['id'];
  taxpayerInfoId: string;
};

export const useTaxPayerInfo = ({ vendorId, taxpayerInfoId, ...props }: Props) => {
  const queryClient = useMelioQueryClient();

  const query = useModelApi({
    ...props,
    id: vendorId,
    queryKey: ['TaxpayerInfoApi', vendorId, taxpayerInfoId],
    queryFn: () => TaxpayerInfoApiClient.getTaxpayerInfoTaxpayerInfoId(vendorId, taxpayerInfoId),
    deleteFn: () => TaxpayerInfoApiClient.deleteTaxpayerInfoTaxpayerInfoId(vendorId, taxpayerInfoId),
    onDelete: () => {
      void queryClient.invalidateQueries('VendorsApi', 'model', vendorId);
      void queryClient.resetQueries('TaxpayerInfoApi');
    },
  });

  const createTaxPayerInfoFile = useCreateMutation(
    ({ file, vendorId: vendorIdFromParams }: { vendorId?: string; file: File }) =>
      TaxpayerInfoApiClient.postVendorsVendorIdTaxpayerInfo(vendorIdFromParams ?? vendorId, file),
    query.queryKey,
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('VendorsApi', 'model', vendorId);
        void queryClient.invalidateQueries('VendorsApi', 'model', 'vendorsContractorsInfo1099');
      },
    }
  );

  return {
    ...query,
    create: createTaxPayerInfoFile.mutateAsync,
  };
};
