import { Vendor } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useEffect, useMemo, useState } from 'react';

import { getCurrencyCloudInternationalDeliveryMethod } from '../../international-payment/add-international-delivery-method/utils';
import { DeliveryMethodSelectionActivityProps, DeliveryMethodSelectionStep } from './types';

export const useDeliveryMethodSelectionActivityStep = ({
  onFirstStepBack,
  vendor,
  billCurrency,
}: {
  onFirstStepBack: DeliveryMethodSelectionActivityProps['onBack'];
  vendor?: Vendor;
  billCurrency?: string;
}) => {
  const [currentStep, goToStep] = useState<DeliveryMethodSelectionStep>();
  const [enabledInternationalUSDWise] = usePartnerFeature('EnableInternationalUSDWise', false);

  const isPaymentCurrencyNotUsd = useMemo(
    () => (vendor?.currency && vendor?.currency !== 'USD') || (billCurrency && billCurrency !== 'USD'),
    [billCurrency, vendor]
  );

  const isPaymentWithInternationalCCAccount = useMemo(() => {
    const internationalDeliveryMethod = vendor && getCurrencyCloudInternationalDeliveryMethod(vendor);
    return enabledInternationalUSDWise && internationalDeliveryMethod;
  }, [enabledInternationalUSDWise, vendor]);

  useEffect(() => {
    if (isPaymentCurrencyNotUsd) {
      goToStep('ADD_VENDOR_INTERNATIONAL_FX_DETAILS');
    } else if (isPaymentWithInternationalCCAccount) {
      goToStep('ADD_VENDOR_INTERNATIONAL_FX_DETAILS');
    } else {
      goToStep('DELIVERY_METHOD_SELECTION');
    }
  }, [enabledInternationalUSDWise, isPaymentCurrencyNotUsd, isPaymentWithInternationalCCAccount]);

  const goToPreviousStep = () => {
    if (currentStep === 'DELIVERY_METHOD_SELECTION' || isPaymentCurrencyNotUsd || isPaymentWithInternationalCCAccount) {
      onFirstStepBack();
    } else {
      goToStep('DELIVERY_METHOD_SELECTION');
    }
  };

  return { currentStep, goToStep, goToPreviousStep };
};
